import {Rolle} from '../../../clients/benutzerverwaltung';

export enum Datenquelle {
    Benutzerverwaltung,
    Vorlagen,
    Veranstaltung,
    Displaysteuerung,
    QsChecks
}

export interface RollenDividerGroup {
    titel: string;
    items: RollenCheckboxWrapper[];
}

export interface RollenCheckboxWrapper {
    selected: boolean;
    titel: string;
    titelFunktionsbereich: string;
    funktionsbereichId: string;
    rolle: Rolle;
}
