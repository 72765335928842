import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENT_BERECHTIGUNG = '[IncidentsModul] GetIncidentBerechtigung laden';
export const GET_INCIDENT_BERECHTIGUNG_AUSFUEHREN = '[IncidentsModul] GetIncidentBerechtigung ausführen';
export const GET_INCIDENT_BERECHTIGUNG_ERFOLGREICH = '[IncidentsModul] GetIncidentBerechtigung laden erfolgreich';
export const GET_INCIDENT_BERECHTIGUNG_FEHLER = '[IncidentsModul] GetIncidentBerechtigung Ladefehler';
export const GET_INCIDENT_BERECHTIGUNG_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentBerechtigung nicht ausgeführt (evtl. Offline)';



export class GetIncidentBerechtigungAction implements Action {
    readonly type = GET_INCIDENT_BERECHTIGUNG;
        constructor( public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentBerechtigungAusfuehrenAction implements Action {
    readonly type = GET_INCIDENT_BERECHTIGUNG_AUSFUEHREN;
        constructor( public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentBerechtigungErfolgreichAction implements Action {
    readonly type = GET_INCIDENT_BERECHTIGUNG_ERFOLGREICH;
        constructor(public payload: api.IncidentBerechtigung,  public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentBerechtigungFehlerAction implements Action {
    readonly type = GET_INCIDENT_BERECHTIGUNG_FEHLER;
        constructor(public payload: any,  public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentBerechtigungNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENT_BERECHTIGUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentBerechtigungActions =
    GetIncidentBerechtigungAction
    | GetIncidentBerechtigungAusfuehrenAction
    | GetIncidentBerechtigungErfolgreichAction
    | GetIncidentBerechtigungFehlerAction
    | GetIncidentBerechtigungNichtAusgefuehrtAction
    ;
