import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FUNKPROTOKOLL_EINTRAG_ENTFERNEN = '[VeranstaltungenModul] PostFunkprotokollEintragEntfernen laden';
export const POST_FUNKPROTOKOLL_EINTRAG_ENTFERNEN_AUSFUEHREN = '[VeranstaltungenModul] PostFunkprotokollEintragEntfernen ausführen';
export const POST_FUNKPROTOKOLL_EINTRAG_ENTFERNEN_ERFOLGREICH = '[VeranstaltungenModul] PostFunkprotokollEintragEntfernen laden erfolgreich';
export const POST_FUNKPROTOKOLL_EINTRAG_ENTFERNEN_FEHLER = '[VeranstaltungenModul] PostFunkprotokollEintragEntfernen Ladefehler';
export const POST_FUNKPROTOKOLL_EINTRAG_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFunkprotokollEintragEntfernen nicht ausgeführt (evtl. Offline)';



export class PostFunkprotokollEintragEntfernenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_EINTRAG_ENTFERNEN;
        constructor(public funkprotokollEintragRequest: api.FunkprotokollEintragRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollEintragEntfernenAusfuehrenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_EINTRAG_ENTFERNEN_AUSFUEHREN;
        constructor(public funkprotokollEintragRequest: api.FunkprotokollEintragRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollEintragEntfernenErfolgreichAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_EINTRAG_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funkprotokollEintragRequest: api.FunkprotokollEintragRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollEintragEntfernenFehlerAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_EINTRAG_ENTFERNEN_FEHLER;
        constructor(public payload: any, public funkprotokollEintragRequest: api.FunkprotokollEintragRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollEintragEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_EINTRAG_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFunkprotokollEintragEntfernenActions =
    PostFunkprotokollEintragEntfernenAction
    | PostFunkprotokollEintragEntfernenAusfuehrenAction
    | PostFunkprotokollEintragEntfernenErfolgreichAction
    | PostFunkprotokollEintragEntfernenFehlerAction
    | PostFunkprotokollEintragEntfernenNichtAusgefuehrtAction
    ;
