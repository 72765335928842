

import {Action} from '@ngrx/store';

export const GET_AUFGABE_FEHLER_BILD_SCALED = '[VeranstaltungenModul] GetAufgabeFehlerBildScaled laden';
export const GET_AUFGABE_FEHLER_BILD_SCALED_AUSFUEHREN = '[VeranstaltungenModul] GetAufgabeFehlerBildScaled ausführen';
export const GET_AUFGABE_FEHLER_BILD_SCALED_ERFOLGREICH = '[VeranstaltungenModul] GetAufgabeFehlerBildScaled laden erfolgreich';
export const GET_AUFGABE_FEHLER_BILD_SCALED_FEHLER = '[VeranstaltungenModul] GetAufgabeFehlerBildScaled Ladefehler';
export const GET_AUFGABE_FEHLER_BILD_SCALED_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetAufgabeFehlerBildScaled nicht ausgeführt (evtl. Offline)';



export class GetAufgabeFehlerBildScaledAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_SCALED;
        constructor( public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBildScaledAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_SCALED_AUSFUEHREN;
        constructor( public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBildScaledErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_SCALED_ERFOLGREICH;
        constructor( public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBildScaledFehlerAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_SCALED_FEHLER;
        constructor(public payload: any,  public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBildScaledNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_SCALED_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryAufgabeFehlerBildScaledActions =
    GetAufgabeFehlerBildScaledAction
    | GetAufgabeFehlerBildScaledAusfuehrenAction
    | GetAufgabeFehlerBildScaledErfolgreichAction
    | GetAufgabeFehlerBildScaledFehlerAction
    | GetAufgabeFehlerBildScaledNichtAusgefuehrtAction
    ;
