

import {Action} from '@ngrx/store';

export const GET_AUFGABE_BILD_SCALED = '[VeranstaltungenModul] GetAufgabeBildScaled laden';
export const GET_AUFGABE_BILD_SCALED_AUSFUEHREN = '[VeranstaltungenModul] GetAufgabeBildScaled ausführen';
export const GET_AUFGABE_BILD_SCALED_ERFOLGREICH = '[VeranstaltungenModul] GetAufgabeBildScaled laden erfolgreich';
export const GET_AUFGABE_BILD_SCALED_FEHLER = '[VeranstaltungenModul] GetAufgabeBildScaled Ladefehler';
export const GET_AUFGABE_BILD_SCALED_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetAufgabeBildScaled nicht ausgeführt (evtl. Offline)';



export class GetAufgabeBildScaledAction implements Action {
    readonly type = GET_AUFGABE_BILD_SCALED;
        constructor( public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetAufgabeBildScaledAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_BILD_SCALED_AUSFUEHREN;
        constructor( public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetAufgabeBildScaledErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_BILD_SCALED_ERFOLGREICH;
        constructor( public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetAufgabeBildScaledFehlerAction implements Action {
    readonly type = GET_AUFGABE_BILD_SCALED_FEHLER;
        constructor(public payload: any,  public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetAufgabeBildScaledNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_BILD_SCALED_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryAufgabeBildScaledActions =
    GetAufgabeBildScaledAction
    | GetAufgabeBildScaledAusfuehrenAction
    | GetAufgabeBildScaledErfolgreichAction
    | GetAufgabeBildScaledFehlerAction
    | GetAufgabeBildScaledNichtAusgefuehrtAction
    ;
