import {Action} from '@ngrx/store';
import {BenutzerZugriffUpdatedNotification, CommandFailedNotification, CommandSucceededNotification, MandantConfigUpdatedNotification, ReadModelUpdatedNotification} from '../clients/api';
import {
    AufgabeErinnerungNotification,
    AufgabeEskalationNotification,
    AufgabeNichtOkNotification,
    FunktionsbereichOkNotification,
    MeinDienstplanNotification
} from '../clients/veranstaltungen';
import {MitteilungNotification} from '../clients/kommunikation';
import { IncidentAbteilungNotification, IncidentDelegationNotification, IncidentZentraleNotification } from '../clients/incidents';

export const WS_READ_MODEL_UPDATED = '[Websocket] READ_MODEL_UPDATED';
export const WS_BENUTZER_ZUGRIFF_UPDATED = '[Websocket] BENUTZER_ZUGRIFF_UPDATED_NOTIFICATION';
export const WS_MANDANT_CONFIG_UPDATED = '[Websocket] MANDANT_CONFIG_UPDATED';
export const WS_COMMAND_FAILED = '[Websocket] COMMAND_FAILED';
export const WS_COMMAND_SUCCEEDED = '[Websocket] COMMAND_SUCCEEDED';
export const WS_AUFGABE_ERINNERUNG = '[Websocket] AUFGABE_ERINNERUNG';
export const WS_AUFGABE_ESKALATION = '[Websocket] AUFGABE_ESKALATION';
export const WS_AUFGABE_NICHT_OK = '[Websocket] AUFGABE_NICHT_OK';
export const WS_FUNKTIONSBEREICH_OK = '[Websocket] FUNKTIONSBEREICH_OK';
export const WS_MITTEILUNG = '[Websocket] MITTEILUNG';
export const WS_MEIN_DIENSTPLAN = '[Websocket] MEIN_DIENSTPLAN_UPDATE';
export const WS_INCIDENT_ZENTRALE = '[Websocket] INCMAN.ZENTRALE';
export const WS_INCIDENT_ABTEILUNG = '[Websocket] INCMAN.ABTEILUNG';
export const WS_INCIDENT_DELEGATION = '[Websocket] INCMAN.DELEGATION';

// MEIN_DIENSTPLAN_NOTIFICATION
export class WsReadModelUpdatedAction implements Action {
    readonly type = WS_READ_MODEL_UPDATED;

    constructor(public message: ReadModelUpdatedNotification) {
    }
}

export class WsBenutzerZugriffUpdatedAction implements Action {
    readonly type = WS_BENUTZER_ZUGRIFF_UPDATED;

    constructor(public message: BenutzerZugriffUpdatedNotification) {
    }
}

export class WsMandantConfigUpdatedAction implements Action {
    readonly type = WS_MANDANT_CONFIG_UPDATED;

    constructor(public message: MandantConfigUpdatedNotification) {
    }
}

export class WsCommandFailedAction implements Action {
    readonly type = WS_COMMAND_FAILED;

    constructor(public message: CommandFailedNotification) {
    }
}

export class WsCommandSucceededAction implements Action {
    readonly type = WS_COMMAND_SUCCEEDED;

    constructor(public message: CommandSucceededNotification) {
    }
}

export class WsAufgabeErinnerungAction implements Action {
    readonly type = WS_AUFGABE_ERINNERUNG;

    constructor(public message: AufgabeErinnerungNotification) {
    }
}

export class WsAufgabeEskalationAction implements Action {
    readonly type = WS_AUFGABE_ESKALATION;

    constructor(public message: AufgabeEskalationNotification) {
    }
}


export class WsAufgabeNichtOkAction implements Action {
    readonly type = WS_AUFGABE_NICHT_OK;

    constructor(public message: AufgabeNichtOkNotification) {
    }
}

export class WsFunktionsbereichOkAction implements Action {
    readonly type = WS_FUNKTIONSBEREICH_OK;

    constructor(public message: FunktionsbereichOkNotification) {
    }
}

export class WsMitteilungAction implements Action {
    readonly type = WS_MITTEILUNG;

    constructor(public message: MitteilungNotification) {
    }
}

export class WsMeinDienstplanAction implements Action {
  readonly type = WS_MEIN_DIENSTPLAN;

  constructor(public message: MeinDienstplanNotification) {
  }
}

export class WsIncidentManagementZentraleAction implements Action {
  readonly type = WS_INCIDENT_ZENTRALE;

  constructor(public message: IncidentZentraleNotification) {
  }
}

export class WsIncidentManagementAbteilungAction implements Action {
  readonly type = WS_INCIDENT_ABTEILUNG;

  constructor(public message: IncidentAbteilungNotification) {
  }
}

export class WsIncidentManagementDelegationAction implements Action {
  readonly type = WS_INCIDENT_DELEGATION;

  constructor(public message: IncidentDelegationNotification) {
  }
}


export type Actions =
    WsReadModelUpdatedAction
    | WsBenutzerZugriffUpdatedAction
    | WsMandantConfigUpdatedAction
    | WsCommandFailedAction
    | WsCommandSucceededAction
    | WsAufgabeErinnerungAction
    | WsAufgabeEskalationAction
    | WsMitteilungAction
    | WsAufgabeNichtOkAction
    | WsFunktionsbereichOkAction
    | WsMeinDienstplanAction
    | WsIncidentManagementZentraleAction
    | WsIncidentManagementAbteilungAction
    | WsIncidentManagementDelegationAction
    ;
