import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ALT = '[VeranstaltungenModul] GetVeranstaltungMitteilungBerechtigungALT laden';
export const GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ALT_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungMitteilungBerechtigungALT ausführen';
export const GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ALT_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungMitteilungBerechtigungALT laden erfolgreich';
export const GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ALT_FEHLER = '[VeranstaltungenModul] GetVeranstaltungMitteilungBerechtigungALT Ladefehler';
export const GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ALT_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungMitteilungBerechtigungALT nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungMitteilungBerechtigungALTAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ALT;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungMitteilungBerechtigungALTAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ALT_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungMitteilungBerechtigungALTErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ALT_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungMitteilungBerechtigung,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungMitteilungBerechtigungALTFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ALT_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungMitteilungBerechtigungALTNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ALT_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungMitteilungBerechtigungALTActions =
    GetVeranstaltungMitteilungBerechtigungALTAction
    | GetVeranstaltungMitteilungBerechtigungALTAusfuehrenAction
    | GetVeranstaltungMitteilungBerechtigungALTErfolgreichAction
    | GetVeranstaltungMitteilungBerechtigungALTFehlerAction
    | GetVeranstaltungMitteilungBerechtigungALTNichtAusgefuehrtAction
    ;
