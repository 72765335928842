import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'sopi-badge',
    templateUrl: './sopi-badge.component.html',
    styleUrls: ['./sopi-badge.component.scss'],
})
export class SopiBadgeComponent implements OnInit {

    @Input() colorValue: string = null;
    @Input() margin: string = null;
    @Input() backgroundColorValue: string = null;
    @Input() iconSvgValue: string = null;

    // SCHALTER
    @Input() istSpezial = false;
    @Input() showIcon = false;

    constructor() {
    }

    ngOnInit() {
    }

}
