import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_BILD_HINZUFUEGEN = '[IncidentsModul] PostIncidentBildHinzufuegen laden';
export const POST_INCIDENT_BILD_HINZUFUEGEN_AUSFUEHREN = '[IncidentsModul] PostIncidentBildHinzufuegen ausführen';
export const POST_INCIDENT_BILD_HINZUFUEGEN_ERFOLGREICH = '[IncidentsModul] PostIncidentBildHinzufuegen laden erfolgreich';
export const POST_INCIDENT_BILD_HINZUFUEGEN_FEHLER = '[IncidentsModul] PostIncidentBildHinzufuegen Ladefehler';
export const POST_INCIDENT_BILD_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentBildHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostIncidentBildHinzufuegenAction implements Action {
    readonly type = POST_INCIDENT_BILD_HINZUFUEGEN;
        constructor(public incidentBildRequest: api.IncidentBildRequest, public optPayload: any = null) {}
    }
export class PostIncidentBildHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_BILD_HINZUFUEGEN_AUSFUEHREN;
        constructor(public incidentBildRequest: api.IncidentBildRequest, public optPayload: any = null) {}
    }
export class PostIncidentBildHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_BILD_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentBildRequest: api.IncidentBildRequest, public optPayload: any = null) {}
    }
export class PostIncidentBildHinzufuegenFehlerAction implements Action {
    readonly type = POST_INCIDENT_BILD_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public incidentBildRequest: api.IncidentBildRequest, public optPayload: any = null) {}
    }
export class PostIncidentBildHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_BILD_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentBildHinzufuegenActions =
    PostIncidentBildHinzufuegenAction
    | PostIncidentBildHinzufuegenAusfuehrenAction
    | PostIncidentBildHinzufuegenErfolgreichAction
    | PostIncidentBildHinzufuegenFehlerAction
    | PostIncidentBildHinzufuegenNichtAusgefuehrtAction
    ;
