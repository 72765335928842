import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_KOMMUNIKATION_CONSTANTS = '[KommunikationModul] GetKommunikationConstants laden';
export const GET_KOMMUNIKATION_CONSTANTS_AUSFUEHREN = '[KommunikationModul] GetKommunikationConstants ausführen';
export const GET_KOMMUNIKATION_CONSTANTS_ERFOLGREICH = '[KommunikationModul] GetKommunikationConstants laden erfolgreich';
export const GET_KOMMUNIKATION_CONSTANTS_FEHLER = '[KommunikationModul] GetKommunikationConstants Ladefehler';
export const GET_KOMMUNIKATION_CONSTANTS_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetKommunikationConstants nicht ausgeführt (evtl. Offline)';



export class GetKommunikationConstantsAction implements Action {
    readonly type = GET_KOMMUNIKATION_CONSTANTS;
        constructor(public optPayload: any = null) {}
    }
export class GetKommunikationConstantsAusfuehrenAction implements Action {
    readonly type = GET_KOMMUNIKATION_CONSTANTS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetKommunikationConstantsErfolgreichAction implements Action {
    readonly type = GET_KOMMUNIKATION_CONSTANTS_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetKommunikationConstantsFehlerAction implements Action {
    readonly type = GET_KOMMUNIKATION_CONSTANTS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetKommunikationConstantsNichtAusgefuehrtAction implements Action {
    readonly type = GET_KOMMUNIKATION_CONSTANTS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryKommunikationConstantsActions =
    GetKommunikationConstantsAction
    | GetKommunikationConstantsAusfuehrenAction
    | GetKommunikationConstantsErfolgreichAction
    | GetKommunikationConstantsFehlerAction
    | GetKommunikationConstantsNichtAusgefuehrtAction
    ;
