import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_ROLLE_BENUTZER_ENTFERNEN = '[BenutzerverwaltungModul] PostRolleBenutzerEntfernen laden';
export const POST_ROLLE_BENUTZER_ENTFERNEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostRolleBenutzerEntfernen ausführen';
export const POST_ROLLE_BENUTZER_ENTFERNEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostRolleBenutzerEntfernen laden erfolgreich';
export const POST_ROLLE_BENUTZER_ENTFERNEN_FEHLER = '[BenutzerverwaltungModul] PostRolleBenutzerEntfernen Ladefehler';
export const POST_ROLLE_BENUTZER_ENTFERNEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostRolleBenutzerEntfernen nicht ausgeführt (evtl. Offline)';



export class PostRolleBenutzerEntfernenAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_ENTFERNEN;
        constructor(public rolleBenutzerRequest: api.RolleBenutzerRequest, public optPayload: any = null) {}
    }
export class PostRolleBenutzerEntfernenAusfuehrenAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_ENTFERNEN_AUSFUEHREN;
        constructor(public rolleBenutzerRequest: api.RolleBenutzerRequest, public optPayload: any = null) {}
    }
export class PostRolleBenutzerEntfernenErfolgreichAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public rolleBenutzerRequest: api.RolleBenutzerRequest, public optPayload: any = null) {}
    }
export class PostRolleBenutzerEntfernenFehlerAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_ENTFERNEN_FEHLER;
        constructor(public payload: any, public rolleBenutzerRequest: api.RolleBenutzerRequest, public optPayload: any = null) {}
    }
export class PostRolleBenutzerEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandRolleBenutzerEntfernenActions =
    PostRolleBenutzerEntfernenAction
    | PostRolleBenutzerEntfernenAusfuehrenAction
    | PostRolleBenutzerEntfernenErfolgreichAction
    | PostRolleBenutzerEntfernenFehlerAction
    | PostRolleBenutzerEntfernenNichtAusgefuehrtAction
    ;
