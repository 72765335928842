import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_CHECKLISTE_AUFGABEN = '[VorlagenModul] GetChecklisteAufgaben laden';
export const GET_CHECKLISTE_AUFGABEN_AUSFUEHREN = '[VorlagenModul] GetChecklisteAufgaben ausführen';
export const GET_CHECKLISTE_AUFGABEN_ERFOLGREICH = '[VorlagenModul] GetChecklisteAufgaben laden erfolgreich';
export const GET_CHECKLISTE_AUFGABEN_FEHLER = '[VorlagenModul] GetChecklisteAufgaben Ladefehler';
export const GET_CHECKLISTE_AUFGABEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetChecklisteAufgaben nicht ausgeführt (evtl. Offline)';



export class GetChecklisteAufgabenAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABEN;
        constructor( public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabenAusfuehrenAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABEN_AUSFUEHREN;
        constructor( public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabenErfolgreichAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABEN_ERFOLGREICH;
        constructor(public payload: Array<api.Aufgabe>,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabenFehlerAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABEN_FEHLER;
        constructor(public payload: any,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabenNichtAusgefuehrtAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryChecklisteAufgabenActions =
    GetChecklisteAufgabenAction
    | GetChecklisteAufgabenAusfuehrenAction
    | GetChecklisteAufgabenErfolgreichAction
    | GetChecklisteAufgabenFehlerAction
    | GetChecklisteAufgabenNichtAusgefuehrtAction
    ;
