import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFPLAN_PRUEFCHECKLISTE_ADMIN = '[QschecksModul] GetPruefplanPruefchecklisteAdmin laden';
export const GET_PRUEFPLAN_PRUEFCHECKLISTE_ADMIN_AUSFUEHREN = '[QschecksModul] GetPruefplanPruefchecklisteAdmin ausführen';
export const GET_PRUEFPLAN_PRUEFCHECKLISTE_ADMIN_ERFOLGREICH = '[QschecksModul] GetPruefplanPruefchecklisteAdmin laden erfolgreich';
export const GET_PRUEFPLAN_PRUEFCHECKLISTE_ADMIN_FEHLER = '[QschecksModul] GetPruefplanPruefchecklisteAdmin Ladefehler';
export const GET_PRUEFPLAN_PRUEFCHECKLISTE_ADMIN_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPruefplanPruefchecklisteAdmin nicht ausgeführt (evtl. Offline)';



export class GetPruefplanPruefchecklisteAdminAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFCHECKLISTE_ADMIN;
        constructor( public pruefplan_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefchecklisteAdminAusfuehrenAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFCHECKLISTE_ADMIN_AUSFUEHREN;
        constructor( public pruefplan_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefchecklisteAdminErfolgreichAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFCHECKLISTE_ADMIN_ERFOLGREICH;
        constructor(public payload: api.PruefchecklisteDetail,  public pruefplan_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefchecklisteAdminFehlerAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFCHECKLISTE_ADMIN_FEHLER;
        constructor(public payload: any,  public pruefplan_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefchecklisteAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFCHECKLISTE_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPruefplanPruefchecklisteAdminActions =
    GetPruefplanPruefchecklisteAdminAction
    | GetPruefplanPruefchecklisteAdminAusfuehrenAction
    | GetPruefplanPruefchecklisteAdminErfolgreichAction
    | GetPruefplanPruefchecklisteAdminFehlerAction
    | GetPruefplanPruefchecklisteAdminNichtAusgefuehrtAction
    ;
