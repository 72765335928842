import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

let instances = 0;

@Component({
    selector: 'sopi-scroll',
    templateUrl: './sopi-scroll.component.html',
    styleUrls: ['./sopi-scroll.component.scss']
})
export class SopiScrollComponent implements OnInit {
    isMobile = new BehaviorSubject(false);
    isMobile$ = this.isMobile.asObservable();
    id: number;

    constructor() {
        this.id = ++instances;
    }

    getIsMobile(): boolean {
        const w = document.documentElement.clientWidth;
        const breakpoint = 768;
        return w < breakpoint;
    }

    ngOnInit() {
        this.isMobile.next(this.getIsMobile());
        window.onresize = () => {
            this.isMobile.next(this.getIsMobile());
        };
    }

}
