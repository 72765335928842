import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const POST_UPLOAD_RAW = '[ApiModul] PostUploadRaw laden';
export const POST_UPLOAD_RAW_AUSFUEHREN = '[ApiModul] PostUploadRaw ausführen';
export const POST_UPLOAD_RAW_ERFOLGREICH = '[ApiModul] PostUploadRaw laden erfolgreich';
export const POST_UPLOAD_RAW_FEHLER = '[ApiModul] PostUploadRaw Ladefehler';
export const POST_UPLOAD_RAW_NICHT_AUSGEFUEHRT = '[ApiModul] PostUploadRaw nicht ausgeführt (evtl. Offline)';



export class PostUploadRawAction implements Action {
    readonly type = POST_UPLOAD_RAW;
        constructor(public uploadRequest: api.UploadRequest, public optPayload: any = null) {}
    }
export class PostUploadRawAusfuehrenAction implements Action {
    readonly type = POST_UPLOAD_RAW_AUSFUEHREN;
        constructor(public uploadRequest: api.UploadRequest, public optPayload: any = null) {}
    }
export class PostUploadRawErfolgreichAction implements Action {
    readonly type = POST_UPLOAD_RAW_ERFOLGREICH;
        constructor(public payload: api.UploadResponse, public uploadRequest: api.UploadRequest, public optPayload: any = null) {}
    }
export class PostUploadRawFehlerAction implements Action {
    readonly type = POST_UPLOAD_RAW_FEHLER;
        constructor(public payload: any, public uploadRequest: api.UploadRequest, public optPayload: any = null) {}
    }
export class PostUploadRawNichtAusgefuehrtAction implements Action {
    readonly type = POST_UPLOAD_RAW_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiCommandUploadRawActions =
    PostUploadRawAction
    | PostUploadRawAusfuehrenAction
    | PostUploadRawErfolgreichAction
    | PostUploadRawFehlerAction
    | PostUploadRawNichtAusgefuehrtAction
    ;
