import * as api from '../../../clients/einlasskontrolle'; 

import {Action} from '@ngrx/store';

export const POST_CORONA_CERT_VALIDATION = '[EinlasskontrolleModul] PostCoronaCertValidation laden';
export const POST_CORONA_CERT_VALIDATION_AUSFUEHREN = '[EinlasskontrolleModul] PostCoronaCertValidation ausführen';
export const POST_CORONA_CERT_VALIDATION_ERFOLGREICH = '[EinlasskontrolleModul] PostCoronaCertValidation laden erfolgreich';
export const POST_CORONA_CERT_VALIDATION_FEHLER = '[EinlasskontrolleModul] PostCoronaCertValidation Ladefehler';
export const POST_CORONA_CERT_VALIDATION_NICHT_AUSGEFUEHRT = '[EinlasskontrolleModul] PostCoronaCertValidation nicht ausgeführt (evtl. Offline)';



export class PostCoronaCertValidationAction implements Action {
    readonly type = POST_CORONA_CERT_VALIDATION;
        constructor(public coronaCertValidationRequest: api.CoronaCertValidationRequest, public optPayload: any = null) {}
    }
export class PostCoronaCertValidationAusfuehrenAction implements Action {
    readonly type = POST_CORONA_CERT_VALIDATION_AUSFUEHREN;
        constructor(public coronaCertValidationRequest: api.CoronaCertValidationRequest, public optPayload: any = null) {}
    }
export class PostCoronaCertValidationErfolgreichAction implements Action {
    readonly type = POST_CORONA_CERT_VALIDATION_ERFOLGREICH;
        constructor(public payload: api.CoronaCertValidationResult, public coronaCertValidationRequest: api.CoronaCertValidationRequest, public optPayload: any = null) {}
    }
export class PostCoronaCertValidationFehlerAction implements Action {
    readonly type = POST_CORONA_CERT_VALIDATION_FEHLER;
        constructor(public payload: any, public coronaCertValidationRequest: api.CoronaCertValidationRequest, public optPayload: any = null) {}
    }
export class PostCoronaCertValidationNichtAusgefuehrtAction implements Action {
    readonly type = POST_CORONA_CERT_VALIDATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type EinlasskontrolleCommandCoronaCertValidationActions =
    PostCoronaCertValidationAction
    | PostCoronaCertValidationAusfuehrenAction
    | PostCoronaCertValidationErfolgreichAction
    | PostCoronaCertValidationFehlerAction
    | PostCoronaCertValidationNichtAusgefuehrtAction
    ;
