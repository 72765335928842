import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_ZEITPUNKT_TEXTE = '[VorlagenModul] GetZeitpunktTexte laden';
export const GET_ZEITPUNKT_TEXTE_AUSFUEHREN = '[VorlagenModul] GetZeitpunktTexte ausführen';
export const GET_ZEITPUNKT_TEXTE_ERFOLGREICH = '[VorlagenModul] GetZeitpunktTexte laden erfolgreich';
export const GET_ZEITPUNKT_TEXTE_FEHLER = '[VorlagenModul] GetZeitpunktTexte Ladefehler';
export const GET_ZEITPUNKT_TEXTE_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetZeitpunktTexte nicht ausgeführt (evtl. Offline)';



export class GetZeitpunktTexteAction implements Action {
    readonly type = GET_ZEITPUNKT_TEXTE;
        constructor(public optPayload: any = null) {}
    }
export class GetZeitpunktTexteAusfuehrenAction implements Action {
    readonly type = GET_ZEITPUNKT_TEXTE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetZeitpunktTexteErfolgreichAction implements Action {
    readonly type = GET_ZEITPUNKT_TEXTE_ERFOLGREICH;
        constructor(public payload: api.ZeitpunktTexte, public optPayload: any = null) {}
    }
export class GetZeitpunktTexteFehlerAction implements Action {
    readonly type = GET_ZEITPUNKT_TEXTE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetZeitpunktTexteNichtAusgefuehrtAction implements Action {
    readonly type = GET_ZEITPUNKT_TEXTE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryZeitpunktTexteActions =
    GetZeitpunktTexteAction
    | GetZeitpunktTexteAusfuehrenAction
    | GetZeitpunktTexteErfolgreichAction
    | GetZeitpunktTexteFehlerAction
    | GetZeitpunktTexteNichtAusgefuehrtAction
    ;
