import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';
import * as actionsUi from './veranstaltungstyp-auswahl.actions.ui';
import {PageState} from './veranstaltungstyp-auswahl.reducer';
import {DatenquelleVeranstaltungsTypen} from './veranstaltungstyp-auswahl.model';
import {GetMitteilungsVorlageVeranstaltungsTypenAction} from '../../../client-ngrx/kommunikation';
import {GetVeranstaltungsTypenAdminAction} from '../../../client-ngrx/veranstaltungen';
import {GetVeranstaltungsTypenAktivAction} from '../../../client-ngrx/veranstaltungstypen';


@Injectable()
export class VeranstaltungstypAuswahlPageUiEffects {

    @Effect()
    pageResetKommunikation$: Observable<Action> = this.actions$.pipe(
        ofType(actionsUi.PAGE_STATE_RESET, actionsUi.REFRESH_DATA)
        , filter((x: actionsUi.PageStateResetAction | actionsUi.RefreshDataAction) => x.datenquelle === DatenquelleVeranstaltungsTypen.Kommunikation)
        , map((x) => {
            return new GetMitteilungsVorlageVeranstaltungsTypenAction();
        })
    );

    @Effect()
    pageResetVeranstaltungen$: Observable<Action> = this.actions$.pipe(
        ofType(actionsUi.PAGE_STATE_RESET, actionsUi.REFRESH_DATA)
        , filter((x: actionsUi.PageStateResetAction | actionsUi.RefreshDataAction) => x.datenquelle === DatenquelleVeranstaltungsTypen.Veranstaltungen)
        , map((x) => {
            return new GetVeranstaltungsTypenAdminAction();
        })
    );

    @Effect()
    pageResetVeranstaltungstypen$: Observable<Action> = this.actions$.pipe(
        ofType(actionsUi.PAGE_STATE_RESET, actionsUi.REFRESH_DATA)
        , filter((x: actionsUi.PageStateResetAction | actionsUi.RefreshDataAction) => x.datenquelle === DatenquelleVeranstaltungsTypen.VeranstaltungsTypen)
        , map((x) => {
            return new GetVeranstaltungsTypenAktivAction();
        })
    );

    constructor(private actions$: Actions,
                private store: Store<PageState>) {
    }
}
