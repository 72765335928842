import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import {datenReducer, DatenState} from './daten.reducer';

export const globalFeatureName = 'GlobalAppState';

export interface GlobalAppState {
    daten: DatenState;
}

export const reducers: ActionReducerMap<GlobalAppState> = {
    daten: datenReducer
};

export const getGlobalAppState = createFeatureSelector<GlobalAppState>(globalFeatureName);

export const getDaten = createSelector(getGlobalAppState, (state: GlobalAppState) => state.daten);
export const getBenutzer = createSelector(getDaten, (state: DatenState) => state.benutzer);
export const getBenutzerLaden = createSelector(getDaten, (state: DatenState) => state.benutzerLaden);
export const getBenutzerFehler = createSelector(getDaten, (state: DatenState) => state.benutzerFehler);

export const getPlatform = createSelector(getDaten, (state: DatenState) => state.platform);

export const getAktuelleVeranstaltungen = createSelector(getDaten, (state: DatenState) => state.aktuelleVeranstaltungen);
export const getAktuelleVeranstaltungenLaden = createSelector(getDaten, (state: DatenState) => state.aktuelleVeranstaltungenLaden);
export const getAktuelleVeranstaltungenFehler = createSelector(getDaten, (state: DatenState) => state.aktuelleVeranstaltungenFehler);

export const getVeranstaltungDeckblatt = createSelector(getDaten, (state: DatenState) => state.veranstaltungDeckblatt);
export const getVeranstaltungDeckblattLaden = createSelector(getDaten, (state: DatenState) => state.veranstaltungDeckblattLaden);
export const getVeranstaltungDeckblattFehler = createSelector(getDaten, (state: DatenState) => state.veranstaltungDeckblattFehler);

export const getVeranstaltungStatus = createSelector(getDaten, (state: DatenState) => state.veranstaltungStatus);
export const getVeranstaltungStatusLaden = createSelector(getDaten, (state: DatenState) => state.veranstaltungStatusLaden);
export const getVeranstaltungStatusFehler = createSelector(getDaten, (state: DatenState) => state.veranstaltungStatusFehler);

export const getVeranstaltungMitteilungBerechtigung = createSelector(getDaten, (state: DatenState) => state.veranstaltungMitteilungBerechtigung);
export const getVeranstaltungMitteilungBerechtigungLaden = createSelector(getDaten, (state: DatenState) => state.veranstaltungMitteilungBerechtigungLaden);
export const getVeranstaltungMitteilungBerechtigungFehler = createSelector(getDaten, (state: DatenState) => state.veranstaltungMitteilungBerechtigungFehler);

export const getVeranstaltungBerechtigung = createSelector(getDaten, (state: DatenState) => state.veranstaltungBerechtigung);
export const getVeranstaltungBerechtigungLaden = createSelector(getDaten, (state: DatenState) => state.veranstaltungBerechtigungLaden);
export const getVeranstaltungBerechtigungFehler = createSelector(getDaten, (state: DatenState) => state.veranstaltungBerechtigungFehler);

export const getUserDarfVeranstaltungMitteilungSenden = createSelector(getVeranstaltungMitteilungBerechtigung, getVeranstaltungBerechtigung, (veranstaltungMitteilungBerechtigung, veranstaltungBerechtigung) => (veranstaltungBerechtigung && veranstaltungBerechtigung.benutzer_ist_veranstaltungsleiter) || (veranstaltungMitteilungBerechtigung && veranstaltungMitteilungBerechtigung.darf_mitteilungen_senden));

export const getAktuelleVeranstaltungZuletztGewaehlt = createSelector(getDaten, (state: DatenState) => state.aktuelleVeranstaltung);

export const getAktuelleVeranstaltung = createSelector(getDaten, (state: DatenState) => {
    if (state.aktuelleVeranstaltung === null || state.aktuelleVeranstaltungen === null) {
        return null;
    }
    const resVeranstaltungenIndex = state.aktuelleVeranstaltungen.findIndex((x) => x.veranstaltung_id === state.aktuelleVeranstaltung.veranstaltung_id);
    if (resVeranstaltungenIndex === -1) {
        return null;
    }
    return state.aktuelleVeranstaltungen[resVeranstaltungenIndex];
});

export const getAusgewaehlteVeranstaltung = createSelector(getDaten, (state: DatenState) => state.aktuelleVeranstaltung);

export const getPushRegistrationId = createSelector(getDaten, (state: DatenState) => state.pushRegistrationId);

export const getAktuellerFunktionsbereich = createSelector(getDaten, (state: DatenState) => state.aktuellerFunktionsbereich);
export const getAktuelleCheckliste = createSelector(getDaten, (state: DatenState) => state.aktuelleCheckliste);
export const getAktuelleAufgabe = createSelector(getDaten, (state: DatenState) => state.aktuelleAufgabe);
