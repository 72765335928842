import {Component, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BildInfo} from './bildInfo';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {IonSlides, ModalController, NavParams} from '@ionic/angular';
import {QsFilter} from '../../../app/controlling/qschecks/qschecks-controlling-saison-filter/qschecks-controlling-saison-filter.model';


@Component({
    selector: 'bild-vorschau',
    templateUrl: './bild-vorschau.html'
})
export class BildVorschauComponent {
    @ViewChild('slides', {static: false}) slides: IonSlides;

    bilder$: Observable<BildInfo[]>;

    public slidesOpts: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public slidesOpts$: Observable<any>;

    titel: string = this.params.get('titel');

    showDeleteButton: boolean;
    deletedImages: Array<string> = [];

    constructor(private viewCtrl: ModalController, public params: NavParams, public domSanitizer: DomSanitizer) {
        this.slidesOpts$ = this.slidesOpts.asObservable();

    }

    ionViewWillEnter() {
        const bilder = this.params.get('bilder');
        const initialImage = this.params.get('initialImage');
        const showDeleteButton = this.params.get('showDeleteButton');

        if (bilder !== undefined) {
            if (bilder === [] || bilder.length > 0) {
                this.bilder$ = of(bilder);
            } else {
                this.bilder$ = bilder;
            }
        }

        if (initialImage != null) {
            // this.initialSlide$.next(initialImage);
            this.setSlideOps(initialImage);
        }

        if (showDeleteButton !== undefined && showDeleteButton !== null) {
            this.showDeleteButton = showDeleteButton;
        }
    }

    closeModal(cancel: boolean): void {
        let data = [];
        if (!cancel) {
            data = this.deletedImages;
        }
        this.viewCtrl.dismiss(data);
    }

    async setSlideOps(initialSlide: number) {
        const ops = {initialSlide};
        await this.slidesOpts.next(ops);
    }

    async slideChanged() {
    }

    deleteImage(bild: BildInfo) {
        if (bild) {
            this.bilder$.subscribe(bilder => {
                this.deletedImages.push(bild.bildId);
                const index = bilder.findIndex(x => x.bildId === bild.bildId);
                bilder.splice(index, 1);
            });
        }
    }
}
