import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNGEN_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_SETZEN = '[VeranstaltungenModul] PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzen laden';
export const POST_VERANSTALTUNGEN_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_SETZEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzen ausführen';
export const POST_VERANSTALTUNGEN_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_SETZEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzen laden erfolgreich';
export const POST_VERANSTALTUNGEN_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_SETZEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzen Ladefehler';
export const POST_VERANSTALTUNGEN_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_SETZEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenAction implements Action {
    readonly type = POST_VERANSTALTUNGEN_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_SETZEN;
        constructor(public veranstaltungBenutzerverwaltungRolleBerechtigungenRequest: api.VeranstaltungBenutzerverwaltungRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNGEN_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_SETZEN_AUSFUEHREN;
        constructor(public veranstaltungBenutzerverwaltungRolleBerechtigungenRequest: api.VeranstaltungBenutzerverwaltungRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNGEN_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungBenutzerverwaltungRolleBerechtigungenRequest: api.VeranstaltungBenutzerverwaltungRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNGEN_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_SETZEN_FEHLER;
        constructor(public payload: any, public veranstaltungBenutzerverwaltungRolleBerechtigungenRequest: api.VeranstaltungBenutzerverwaltungRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNGEN_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenActions =
    PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenAction
    | PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenAusfuehrenAction
    | PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenErfolgreichAction
    | PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenFehlerAction
    | PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenNichtAusgefuehrtAction
    ;
