import {Component, Input, OnInit} from '@angular/core';
import {ModalController, NavController, NavParams} from '@ionic/angular';

@Component({
  selector: 'app-rolle-loeschen',
  templateUrl: './rolle-loeschen.page.html',
  styleUrls: ['./rolle-loeschen.page.scss'],
})
export class RolleLoeschenPage implements OnInit {
  private aiName = 'RolleLoeschenPage';
  @Input() titel: string;

  constructor(public navCtrl: NavController,
              public navParams: NavParams,
              private modalCtrl: ModalController) {
  }

  ngOnInit() {
  }


  ok() {
    this.modalCtrl.dismiss('ok');
  }

  abbrechen() {
    this.modalCtrl.dismiss();
  }

}
