import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_AUFGABE_ESKALATION_VERSENDEN = '[VeranstaltungenModul] PostVeranstaltungAufgabeEskalationVersenden laden';
export const POST_VERANSTALTUNG_AUFGABE_ESKALATION_VERSENDEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungAufgabeEskalationVersenden ausführen';
export const POST_VERANSTALTUNG_AUFGABE_ESKALATION_VERSENDEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungAufgabeEskalationVersenden laden erfolgreich';
export const POST_VERANSTALTUNG_AUFGABE_ESKALATION_VERSENDEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungAufgabeEskalationVersenden Ladefehler';
export const POST_VERANSTALTUNG_AUFGABE_ESKALATION_VERSENDEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungAufgabeEskalationVersenden nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungAufgabeEskalationVersendenAction implements Action {
    readonly type = POST_VERANSTALTUNG_AUFGABE_ESKALATION_VERSENDEN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAufgabeEskalationVersendenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_AUFGABE_ESKALATION_VERSENDEN_AUSFUEHREN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAufgabeEskalationVersendenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_AUFGABE_ESKALATION_VERSENDEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAufgabeEskalationVersendenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_AUFGABE_ESKALATION_VERSENDEN_FEHLER;
        constructor(public payload: any, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAufgabeEskalationVersendenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_AUFGABE_ESKALATION_VERSENDEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungAufgabeEskalationVersendenActions =
    PostVeranstaltungAufgabeEskalationVersendenAction
    | PostVeranstaltungAufgabeEskalationVersendenAusfuehrenAction
    | PostVeranstaltungAufgabeEskalationVersendenErfolgreichAction
    | PostVeranstaltungAufgabeEskalationVersendenFehlerAction
    | PostVeranstaltungAufgabeEskalationVersendenNichtAusgefuehrtAction
    ;
