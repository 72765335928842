import {Component, ElementRef, Injector, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as fromAufgabebemerkungPage from './aufgabebemerkung.reducer';
import * as actionsUi from './aufgabebemerkung.actions.ui';
import {select, Store} from '@ngrx/store';
import * as  apiVeranstaltungen from '../../../clients/veranstaltungen';
import {Observable} from 'rxjs/Observable';
import {ImageService} from '@orga-app/providers/image.service';
import {Subscription} from 'rxjs/Subscription';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ResponseContentType} from '@angular/http';
import {BildVorschauComponent} from '../../../sopi/components/bild-vorschau/bild-vorschau';
import {UploadResponse, UploadService} from '../../../clients/api';
import * as util from '@orga-app/providers/utils';
import {SettingsProvider} from '@orga-app/settings/settings.service';
import {Settings} from '@orga-app/settings/settings.model';
import {ActivatedRoute, Router} from '@angular/router';
import {
    GetAufgabeDetailAction,
    PostAufgabeBemerkungErfassenAction, PostAufgabeBildEntfernenAction, PostAufgabeBildHinzufuegenAction
} from '../../../client-ngrx/veranstaltungen';
import {LoadingController, ModalController, Platform} from '@ionic/angular';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {catchError, filter, takeUntil, takeWhile} from 'rxjs/operators';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {of, Subject} from 'rxjs';
import {UuidService} from '@orga-app/providers/uuid.service';
import * as fromAppState from '../app-state/app-state.reducer';
import {getPlatform} from '../app-state/app-state.reducer';
import {Aufgabe} from '../../../clients/veranstaltungen';
import { BildInfo } from 'src/app/shared/models/bildInfo';


@Component({
    selector: 'app-aufgabebemerkung',
    templateUrl: './aufgabebemerkung.page.html',
})
export class AufgabebemerkungPage implements OnInit, OnDestroy {
    public platformState$: Observable<string>;
    public platformState: string;

    @ViewChild('autosize', {static: true}) autosize: HTMLTextAreaElement;
    @Input() aufgabe: Aufgabe;
    // aufgabe: apiVeranstaltungen.Aufgabe;
    aufgabe$: Observable<apiVeranstaltungen.Aufgabe>;
    checkliste: apiVeranstaltungen.Checkliste;
    aufgabebemerkung = '';
    bilder$: Observable<BildInfo[]>;
    istKameraSichtbar = true;
    settings$: Observable<Settings>;
    themeDefault$: Observable<Settings>;
    logoHeaderSVG$: Observable<SafeUrl>;
    aiName = 'AufgabebemerkungPage';
    aufgabeDetail$: Observable<apiVeranstaltungen.AufgabeDetail>;
    aufgabebemerkungGemeldet$: Observable<boolean>;
    uiZeigeAufgabebemerkungMeldenLaden$: Observable<boolean>;
    uiZeigeAufgabebemerkungMeldenLadefehler$: Observable<boolean>;
    editMode = false;
    editModeSub: Subject<boolean> = new Subject();
    editMode$: Observable<boolean>;
    bilder: Array<BildInfo> = new Array<BildInfo>();
    bildLoader: Subscription;
    keyboardHeight = '0px';
    isKeyboardSub: Subject<boolean> = new Subject();
    isKeyboardOn$: Observable<boolean>;
    unsubscibe$ = new Subject<void>();
    private unsubscribe$ = new Subject();
    private alive = true;
    camera: Camera;
    keyboard: Keyboard;

    constructor(
        public platform: Platform,
        public loadingCtrl: LoadingController,
        public injector: Injector,
        public domSanitizer: DomSanitizer,
        public modalCtrl: ModalController,
        private zone: NgZone,
        public element: ElementRef,
        private imageService: ImageService,
        private uploadCommandService: UploadService,
        private store: Store<fromAufgabebemerkungPage.PageState>,
        private storeVeranstaltung: Store<fromAppState.GlobalAppState>,
        private settingsProvider: SettingsProvider,
        public route: ActivatedRoute,
        public router: Router,
        private uuiService: UuidService)
         {
        this.themeDefault$ = this.settingsProvider.getThemeDefault();
        this.logoHeaderSVG$ = this.settingsProvider.getLogoHeaderSVG();
        this.settings$ = this.settingsProvider.getActiveTheme();
        this.editModeSub.next(this.editMode);
        this.editMode$ = this.editModeSub.asObservable();
        this.isKeyboardSub.next(false);
        this.isKeyboardOn$ = this.isKeyboardSub.asObservable();
    }

    ngOnInit() {
        // Platform from State
        this.platformState$ = this.storeVeranstaltung.pipe(select(getPlatform));

        this.aufgabebemerkungGemeldet$ = this.store.pipe(select(fromAufgabebemerkungPage.getAufgabebemerkungGemeldet));
        this.uiZeigeAufgabebemerkungMeldenLaden$ = this.store.pipe(select(fromAufgabebemerkungPage.getUiZeigeAufgabebemerkungMeldenLaden));
        this.uiZeigeAufgabebemerkungMeldenLadefehler$ = this.store.pipe(select(fromAufgabebemerkungPage.getUiZeigeAufgabebemerkungMeldenLadefehler));
        this.aufgabeDetail$ = this.store.pipe(select(fromAufgabebemerkungPage.getAufgabeDetail));
        this.aufgabe$ = this.store.pipe(select(fromAufgabebemerkungPage.getAktuelleAufgabe));

        this.platformState$.subscribe(x => {
            this.platformState = x;
            // console.log('PlatformState:', x);
            if (x === 'desktop') {
                // this.viewWillEnterPage().then();
            } else {
                this.getCamera();
                this.getKeyboard();

                this.keyboard.onKeyboardWillShow().pipe(takeUntil(this.unsubscribe$)).subscribe((e) => {
                    this.isKeyboardSub.next(true);
                    if (this.platform.is('android')) {
                        this.zone.run(() => this.keyboardHeight = e.keyboardHeight.toString() + 'px');
                    }
                });
                this.keyboard.onKeyboardWillHide().pipe(takeUntil(this.unsubscribe$)).subscribe((e) => {
                    this.isKeyboardSub.next(false);
                    if (this.platform.is('android')) {
                        this.zone.run(() => this.keyboardHeight = '0px');
                    }
                });
            }
        }).unsubscribe();
    }

    toggleEditMode() {
        this.editMode = !this.editMode;
        this.editModeSub.next(this.editMode);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ionViewWillEnter() {
        this.viewWillEnterPage().then();
    }

    async viewWillEnterPage() {
        this.unsubscibe$ = new Subject<void>();
        await this.store.dispatch(new actionsUi.PageStateResetAction());

        await this.route.paramMap.subscribe(async params => {
            const aufgabeId = params.get('aufgabeId');
            const checklisteId = params.get('checklisteId');
            const funktionsbereichId = params.get('funktionsbereichId');
            const veranstaltungId = params.get('veranstaltungId');
            if (this.platformState !== 'desktop') {
                this.aufgabe = <apiVeranstaltungen.Aufgabe>{
                    veranstaltung_id: veranstaltungId,
                    aufgabe_id: aufgabeId,
                    checkliste_id: checklisteId,
                    funktionsbereich_id: funktionsbereichId
                };
            }

            await this.store.dispatch(new actionsUi.AktuelleAufgabeSetzenAction(this.aufgabe));
            await this.store.dispatch(new GetAufgabeDetailAction(this.aufgabe.aufgabe_id));

            this.store.pipe(select(fromAufgabebemerkungPage.getAufgabeDetail), takeUntil(this.unsubscibe$)).subscribe((x) => {
                if (x != null && x.aufgabe !== undefined) {
                    this.aufgabebemerkung = x.aufgabe.bemerkung;
                    if (x.aufgabe.bilder_ids !== undefined && x.aufgabe.bilder_ids !== null && x.aufgabe.bilder_ids.length > 0) {
                        while (this.bilder.length > 0) {
                            this.bilder.pop();
                        }

                        x.aufgabe.bilder_ids.forEach(bildId => {

                            this.getAufgabeBild(x.aufgabe.aufgabe_id, bildId)
                                .pipe(
                                    takeWhile(() => this.alive)
                                    , filter(image => {
                                        return !!image;
                                    }),
                                    catchError((error: any) => {
                                        return of();
                                    }),
                                )
                                .subscribe((image: BlobPart) => {
                                        const imgtype = bildId.split('.')[1];
                                        if (image) {
                                            const blob = new Blob([image]);

                                            const reader = new FileReader();
                                            reader.onload = function () {
                                                const readerResult = reader.result.toString().replace('application/octet-stream', 'image/jpeg');
                                                const imgBase64JPEG = 'data:image/' + imgtype + ';base64,' + readerResult.split('base64,')[1];
                                                const bi = <BildInfo>{
                                                    bildId,
                                                    data: imgBase64JPEG,
                                                    dataTrusted: this.domSanitizer.bypassSecurityTrustUrl(imgBase64JPEG),
                                                    type: 'image/' + imgtype
                                                };

                                                this.zone.run(() => {
                                                    this.bilder.push(bi);
                                                    this.bilder$ = of(this.bilder);
                                                });

                                            }.bind(this);

                                            reader.readAsDataURL(blob);
                                        }
                                    },
                                    error => {

                                    });
                        });
                    }
                }
            });
        }).unsubscribe();
    }

    ionViewWillLeave() {
        this.unsubscibe$.next();
        this.unsubscibe$.complete();
    }

    // async keyboardHandler(e) {
    //     // if (this.platform.is('android')) {
    //     if (e.keyboardHeight === undefined) {
    //         // hide
    //         this.zone.run(() => this.keyboardHeight = '56px');
    //     } else {
    //         // show
    //         this.zone.run(() => this.keyboardHeight = e.keyboardHeight + 'px');
    //     }
    //     // }
    // }


    async aufgabebemerkungSenden() {
        const aufgabeRequest = <apiVeranstaltungen.AufgabeBemerkungRequest>{
            bemerkung: this.aufgabebemerkung,
            funktionsbereich_id: this.aufgabe.funktionsbereich_id,
            checkliste_id: this.aufgabe.checkliste_id,
            aufgabe_id: this.aufgabe.aufgabe_id,
            veranstaltung_id: this.aufgabe.veranstaltung_id,
            zeitpunkt_utc: new Date()
        };
        this.store.dispatch(new PostAufgabeBemerkungErfassenAction(aufgabeRequest));

        // TODO ROUTING
        // this.navCtrl.pop();
        if (this.platformState === 'desktop') {
            this.modalCtrl.dismiss('ok');
        } else {
            await this.router.navigate(['/veranstaltung', this.aufgabe.veranstaltung_id, 'funktionsbereiche-checklisten', 'funktionsbereich', this.aufgabe.funktionsbereich_id, 'checkliste', this.aufgabe.checkliste_id], {
                relativeTo: this.route,
                // queryParams: {returnUrl: this.router.url.split('?')[0]}
            });
        }
    }

    /* fehler_bearbeiten */
    async doCamera(sourceType) {
        const options: CameraOptions = {
            quality: 50,
            destinationType: this.camera.DestinationType.DATA_URL,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: this.camera.MediaType.PICTURE,
            sourceType,
            allowEdit: true,
            correctOrientation: true
        };

        this.camera.getPicture(options).then(async (imageData) => {
            const imgBase64JPEG = 'data:image/jpeg;base64,' + imageData;

            const loading = await this.loadingCtrl.create({
                spinner: 'dots',
                message: 'Das Bild wird hochgeladen',
                translucent: true,
                backdropDismiss: false,
                keyboardClose: true
            });
            await loading.present();

            const blob: Blob = new util.ImageDataConverter(imgBase64JPEG).dataURItoBlob();

            const uploadId = `${this.uuiService.generateUUID()}.jpeg`;
            this.uploadCommandService.postUploadForm(blob, `veranstaltungen/${this.aufgabe.veranstaltung_id}/aufgaben/${this.aufgabe.aufgabe_id}/bemerkung`, uploadId).subscribe((result: UploadResponse) => {
                const aufgabeBildRequest = <apiVeranstaltungen.AufgabeBildRequest>{
                    aufgabe_id: this.aufgabe.aufgabe_id,
                    checkliste_id: this.aufgabe.checkliste_id,
                    bild_id: result.upload_id,
                    funktionsbereich_id: this.aufgabe.funktionsbereich_id,
                    veranstaltung_id: this.aufgabe.veranstaltung_id,
                    zeitpunkt_utc: new Date()
                };
                this.store.dispatch(new PostAufgabeBildHinzufuegenAction(aufgabeBildRequest));

                const bi = <BildInfo>{
                    bildId: result.upload_id,
                    data: imgBase64JPEG,
                    dataTrusted: this.domSanitizer.bypassSecurityTrustUrl(imgBase64JPEG),
                    type: 'image/jpeg'
                };
                this.bilder.push(bi);
                this.bilder$ = of(this.bilder);

                loading.dismiss();
            }, error2 => {
                // this.log.logError(error2);
                loading.dismiss();
            });
        });
    }

    async fotoErstellen() {
        this.doCamera(this.camera.PictureSourceType.CAMERA);
    }

    async fotoAuswaehlen() {
        this.doCamera(this.camera.PictureSourceType.PHOTOLIBRARY);
    }

    getAufgabeBild(aufgabeId: string, bildId: string): Observable<any> {
        const requestOptions = {
            responseType: ResponseContentType.Blob
        };
        return this.imageService.getAufgabeBemerkungBildWithHttpInfo(aufgabeId, bildId, requestOptions);
    }

    async bilderAnzeigen(index = 0) {
        const imageModal = await this.modalCtrl.create({
            component: BildVorschauComponent, componentProps: {
                bilder: this.bilder$,
                initialImage: index
            }
        });
        imageModal.onDidDismiss().then(data => console.debug('FehlerPage:: bilderAnzeigen :  imageModal.onDidDismiss: ' + data));
        await imageModal.present();
    }

    async bildEntfernen(bildId: string) {
        const aufgabeBildRequest = <apiVeranstaltungen.AufgabeBildRequest> {
            aufgabe_id: this.aufgabe.aufgabe_id,
            checkliste_id: this.aufgabe.checkliste_id,
            bild_id: bildId,
            funktionsbereich_id: this.aufgabe.funktionsbereich_id,
            veranstaltung_id: this.aufgabe.veranstaltung_id,
            zeitpunkt_utc: new Date()
        };
        this.store.dispatch(new PostAufgabeBildEntfernenAction(aufgabeBildRequest));
    }

    async kameraVerstecken() {
        this.istKameraSichtbar = false;
        this.autoresize();
    }

    async kameraAnzeigen() {
        this.istKameraSichtbar = true;
        const textArea = this.element.nativeElement.getElementsByTagName('textarea')[0];
        textArea.style.overflow = 'auto';
        textArea.style.height = 'auto';
    }

    async autoresize($event = null) {
        const textArea = this.element.nativeElement.getElementsByTagName('textarea')[0];

        if (this.element.nativeElement.getElementsByTagName('textarea')[0].scrollHeight <= 200) {
            textArea.style.overflow = 'hidden';
            textArea.style.height = 'auto';
            textArea.style.height = this.element.nativeElement.getElementsByTagName('textarea')[0].scrollHeight + 'px';
        } else {
            textArea.style.overflow = 'auto';
            textArea.style.height = 'auto';
            textArea.style.height = '200px';
        }
    }

    private getCamera(): Camera {
        // console.debug('STARTING :: Websocket.init.effects :: getWebsocketService');
        if (this.camera == null) {
            this.camera = this.injector.get(Camera);
        }

        return this.camera;
    }

    private getKeyboard(): Keyboard {
        // console.debug('STARTING :: Websocket.init.effects :: getWebsocketService');
        if (this.keyboard == null) {
            this.keyboard = this.injector.get(Keyboard);
        }

        return this.keyboard;
    }

    verlasseModal() {
        this.modalCtrl.dismiss();
    }
}
