import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const POST_UPLOAD = '[ApiModul] PostUpload laden';
export const POST_UPLOAD_AUSFUEHREN = '[ApiModul] PostUpload ausführen';
export const POST_UPLOAD_ERFOLGREICH = '[ApiModul] PostUpload laden erfolgreich';
export const POST_UPLOAD_FEHLER = '[ApiModul] PostUpload Ladefehler';
export const POST_UPLOAD_NICHT_AUSGEFUEHRT = '[ApiModul] PostUpload nicht ausgeführt (evtl. Offline)';



export class PostUploadAction implements Action {
    readonly type = POST_UPLOAD;
        constructor( public file:Blob , public optPayload: any = null) {}
    }
export class PostUploadAusfuehrenAction implements Action {
    readonly type = POST_UPLOAD_AUSFUEHREN;
        constructor( public file:Blob , public optPayload: any = null) {}
    }
export class PostUploadErfolgreichAction implements Action {
    readonly type = POST_UPLOAD_ERFOLGREICH;
        constructor(public payload: api.UploadResponse,  public file:Blob , public optPayload: any = null) {}
    }
export class PostUploadFehlerAction implements Action {
    readonly type = POST_UPLOAD_FEHLER;
        constructor(public payload: any,  public file:Blob , public optPayload: any = null) {}
    }
export class PostUploadNichtAusgefuehrtAction implements Action {
    readonly type = POST_UPLOAD_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiCommandUploadActions =
    PostUploadAction
    | PostUploadAusfuehrenAction
    | PostUploadErfolgreichAction
    | PostUploadFehlerAction
    | PostUploadNichtAusgefuehrtAction
    ;
