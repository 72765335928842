import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_ABTEILUNG_SETZEN = '[IncidentsModul] PostFunktionsbereichAbteilungSetzen laden';
export const POST_FUNKTIONSBEREICH_ABTEILUNG_SETZEN_AUSFUEHREN = '[IncidentsModul] PostFunktionsbereichAbteilungSetzen ausführen';
export const POST_FUNKTIONSBEREICH_ABTEILUNG_SETZEN_ERFOLGREICH = '[IncidentsModul] PostFunktionsbereichAbteilungSetzen laden erfolgreich';
export const POST_FUNKTIONSBEREICH_ABTEILUNG_SETZEN_FEHLER = '[IncidentsModul] PostFunktionsbereichAbteilungSetzen Ladefehler';
export const POST_FUNKTIONSBEREICH_ABTEILUNG_SETZEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostFunktionsbereichAbteilungSetzen nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichAbteilungSetzenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ABTEILUNG_SETZEN;
        constructor(public incidentsFunktionsbereichAbteilungRequest: api.IncidentsFunktionsbereichAbteilungRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAbteilungSetzenAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ABTEILUNG_SETZEN_AUSFUEHREN;
        constructor(public incidentsFunktionsbereichAbteilungRequest: api.IncidentsFunktionsbereichAbteilungRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAbteilungSetzenErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ABTEILUNG_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentsFunktionsbereichAbteilungRequest: api.IncidentsFunktionsbereichAbteilungRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAbteilungSetzenFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ABTEILUNG_SETZEN_FEHLER;
        constructor(public payload: any, public incidentsFunktionsbereichAbteilungRequest: api.IncidentsFunktionsbereichAbteilungRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAbteilungSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ABTEILUNG_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandFunktionsbereichAbteilungSetzenActions =
    PostFunktionsbereichAbteilungSetzenAction
    | PostFunktionsbereichAbteilungSetzenAusfuehrenAction
    | PostFunktionsbereichAbteilungSetzenErfolgreichAction
    | PostFunktionsbereichAbteilungSetzenFehlerAction
    | PostFunktionsbereichAbteilungSetzenNichtAusgefuehrtAction
    ;
