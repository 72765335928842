import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_ANLEGEN = '[BenutzerverwaltungModul] PostFunktionsbereichAnlegen laden';
export const POST_FUNKTIONSBEREICH_ANLEGEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostFunktionsbereichAnlegen ausführen';
export const POST_FUNKTIONSBEREICH_ANLEGEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostFunktionsbereichAnlegen laden erfolgreich';
export const POST_FUNKTIONSBEREICH_ANLEGEN_FEHLER = '[BenutzerverwaltungModul] PostFunktionsbereichAnlegen Ladefehler';
export const POST_FUNKTIONSBEREICH_ANLEGEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostFunktionsbereichAnlegen nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichAnlegenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ANLEGEN;
        constructor(public funktionsbereichAnlegenRequest: api.FunktionsbereichAnlegenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAnlegenAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ANLEGEN_AUSFUEHREN;
        constructor(public funktionsbereichAnlegenRequest: api.FunktionsbereichAnlegenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAnlegenErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ANLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funktionsbereichAnlegenRequest: api.FunktionsbereichAnlegenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAnlegenFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ANLEGEN_FEHLER;
        constructor(public payload: any, public funktionsbereichAnlegenRequest: api.FunktionsbereichAnlegenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAnlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ANLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandFunktionsbereichAnlegenActions =
    PostFunktionsbereichAnlegenAction
    | PostFunktionsbereichAnlegenAusfuehrenAction
    | PostFunktionsbereichAnlegenErfolgreichAction
    | PostFunktionsbereichAnlegenFehlerAction
    | PostFunktionsbereichAnlegenNichtAusgefuehrtAction
    ;
