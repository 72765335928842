import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { FehlerbeschreibungPage } from './fehlerbeschreibung.page';
import {StoreModule} from '@ngrx/store';
import {reducers} from './fehlerbeschreibung.reducer';
import {EffectsModule} from '@ngrx/effects';
import {FehlerbeschreibungPageUiEffects} from './fehlerbeschreibung.effects.ui';
import {
  VeranstaltungenFehlerbeschreibungHinzufuegenEffects,
  VeranstaltungenFehlerbildEntfernenEffects,
  VeranstaltungenFehlerbildHinzufuegenEffects
} from '../../../client-ngrx/veranstaltungen';
import {SopiComponentsModule} from '../../shared/sopi-components/sopi-components.module';

const routes: Routes = [
  {
    path: '',
    component: FehlerbeschreibungPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('FehlerbeschreibungPage', reducers),
    EffectsModule.forFeature([
      FehlerbeschreibungPageUiEffects,
      VeranstaltungenFehlerbeschreibungHinzufuegenEffects,
      VeranstaltungenFehlerbildHinzufuegenEffects,
      VeranstaltungenFehlerbildEntfernenEffects
    ]),
    SopiComponentsModule.forRoot(),
  ],
  declarations: [FehlerbeschreibungPage]
})
export class FehlerbeschreibungPageModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: FehlerbeschreibungPageModule,
    };
  }
}
