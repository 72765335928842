import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {IsAuthenticatedGuard} from '@orga-app/auth';
import {LogGuard} from '@orga-app/auth/logGuard';
import {IsMandantAusgewaehltGuard} from '@orga-app/mandant';
import {HomeComponent} from './home/home.component';
import {KontaktverwaltungPageModule} from './verwaltung/kontakte/kontakte.module';


const routes: Routes = [
    {
        path: 'homex',
        canActivate: [],
        component: HomeComponent
    },
    {
        path: 'aufgabe',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/aufgabe/aufgabe.module').then(m => m.AufgabePageModule)
    },
    {
        path: 'incident',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./operativ/incident/incident.module').then(m => m.NeuenIncidentPageModule)
    },
    {
        path: 'benutzer-rollen',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/benutzer-rollen/benutzer-rollen.module').then(m => m.BenutzerRollenPageModule)
    },
    {
        path: 'benutzerauswahl',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/benutzerauswahl/benutzerauswahl.module').then(m => m.BenutzerauswahlPageModule)
    },
    {
        path: 'checkliste-entfernen',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/checkliste-entfernen/checkliste-entfernen.module').then(m => m.ChecklisteEntfernenPageModule)
    },
    {
        path: 'checkliste-loeschen',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/checkliste-loeschen/checkliste-loeschen.module').then(m => m.ChecklisteLoeschenPageModule)
    },
    {
        path: 'checklisten',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/checklisten/checklisten.module').then(m => m.ChecklistenPageModule)
    },
    {
        path: 'checklistenauswahl',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/checklistenauswahl/checklistenauswahl.module').then(m => m.ChecklistenauswahlPageModule)
    },
    {
        path: 'aufgaben-berechtigungen',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/aufgaben-berechtigungen/aufgaben-berechtigungen-home/aufgaben-berechtigungen-home.module').then(m => m.AufgabenBerechtigungenHomeModule)
    },
    {
        path: 'counterpruefung-loeschen',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/counterpruefung-loeschen/counterpruefung-loeschen.module').then(m => m.CounterpruefungLoeschenPageModule)
    },
    {
        path: 'dokument-loeschen',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/dokumentverwaltung/dokument-verwaltung/dokument-verwaltung.module').then(m => m.DokumentVerwaltungPageModule)
    },
    {
        path: 'einstellungen',
        canActivate: [IsAuthenticatedGuard],
        loadChildren: () => import('./einstellungen/einstellungen.module').then(m => m.EinstellungenPageModule)
    },
    {
        path: 'funktionsbereich-entfernen',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/funktionsbereich-entfernen/funktionsbereich-entfernen.module').then(m => m.FunktionsbereichEntfernenPageModule)
    },
    {
        path: 'funktionsbereich-loeschen',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/funktionsbereich-loeschen/funktionsbereich-loeschen.module').then(m => m.FunktionsbereichLoeschenPageModule)
    },
    {
        path: 'funktionsbereiche-rollen',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/funktionsbereiche-rollen/funktionsbereiche-rollen.module').then(m => m.FunktionsbereicheRollenPageModule)
    },
    {
        path: 'funktionsbereichsauswahl',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/funktionsbereichsauswahl/funktionsbereichsauswahl.module').then(m => m.FunktionsbereichsauswahlPageModule)
    },
    {
        path: 'veranstaltung-kopieren',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/veranstaltung-kopieren/veranstaltung-kopieren.module').then(m => m.VeranstaltungKopierenPageModule)
    },

    {
        path: 'veranstaltung-loeschen',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/veranstaltung-loeschen/veranstaltung-loeschen.module').then(m => m.VeranstaltungLoeschenPageModule)
    },
    {
        path: 'veranstaltungen',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/veranstaltungen2/veranstaltungen.module').then(m => m.Veranstaltungen2PageModule)
    },
    /*{
        path: 'veranstaltungen2',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/veranstaltungen2/veranstaltungen.module').then(m => m.Veranstaltungen2PageModule)
    },*/
    {
        path: 'qschecks',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/qs-checks/qs-checks.module').then(m => m.QsChecksPageModule)
    },
    {
        path: 'qschecks-controlling',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./controlling/qschecks/qschecks-controlling-home/qs-checks-controlling-home.module').then(m => m.QsChecksControllingHomePageModule)
    },
    {
        path: 'dokumentverwaltung',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/dokumentverwaltung/dokument-verwaltung/dokument-verwaltung.module').then(m => m.DokumentVerwaltungPageModule)
    },
    {
        path: 'funkprotokollierung',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./operativ/funkprotokollierung/funkprotokollierung.module').then(m => m.FunkprotokollierungPageModule)
    },
    {
        path: 'kontaktverwaltung',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/kontakte/kontakte.module').then(m => m.KontaktverwaltungPageModule)
    },
    // {
    //     path: 'modal-dokument-loeschen',
    //     loadChildren: () => import('./dokumentverwaltung/modal-dokument-loeschen/modal-dokument-loeschen.module').then(m => m.ModalDokumentLoeschenPageModule)
    // },
    {
        path: 'komponenten',
        canActivate: [IsAuthenticatedGuard],
        loadChildren: () => import('./komponenten/komponenten.module').then(m => m.KomponentenPageModule)
    },
    {
        path: 'mandanten-auswahl',
        canActivate: [IsAuthenticatedGuard],
        loadChildren: () => import('./mandant/mandanten-auswahl/mandanten-auswahl.module').then(m => m.MandantenAuswahlPageModule)
    },
    {
        path: 'mandantenverwaltung',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./mandantenverwaltung/mandantenverwaltung-main/mandantenverwaltung-main.module').then(m => m.MandantenverwaltungMainModule)
    },
    {
        path: 'page-not-found',
        loadChildren: () => import('./shared/page-not-found/page-not-found.module').then(m => m.PageNotFoundPageModule)
    },
    {
        path: 'no-access',
        loadChildren: () => import('./shared/no-access/no-access.module').then(m => m.NoAccessPageModule)
    },
    {
        path: 'login',
        canActivate: [LogGuard],
        loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'rollenselektion',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/rollenselektion/rollenselektion.module').then(m => m.RollenselektionPageModule)
    },
    {
        path: 'veranstaltung-auswaehlen',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./operativ/veranstaltung-auswaehlen/veranstaltung-auswaehlen.module').then(m => m.VeranstaltungAuswaehlenPageModule),
        outlet: 'primary'
    },
    {
        path: 'incidents-zentrale',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./operativ/incident-leitzentrale/incident-leitzentrale.module').then(m => m.IncidentLeitzentralePageModule),
        outlet: 'primary'
    },
    {
        path: 'incidents-abteilung',
        children: [
            {
                path: '',
                canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
                loadChildren: () => import('./operativ/incident-abteilungsleitstelle/incident-abteilungsleitstelle.module').then(m => m.IncidentAbteilungsleitstellePageModule)
            },
            {
                path: ':funktionsbereich_id',
                canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
                loadChildren: () => import('./operativ/incident-abteilungsleitstelle/incident-abteilungsleitstelle.module').then(m => m.IncidentAbteilungsleitstellePageModule)
            }
        ]
    },
    {
        path: 'incidents-meine-aufgaben',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./operativ/meine-aufgaben/meine-aufgaben.module').then(m => m.MeineAufgabenPageModule),
        outlet: 'primary'
    },
    {
        path: 'veranstaltung',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import( './operativ/veranstaltung/veranstaltung-main/veranstaltung-main.module').then(m => m.VeranstaltungMainModule),
        outlet: 'primary'
    },
    {
        path: 'displaysteuerung',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/displaysteuerung/displaysteuerung.module').then(m => m.DisplaysteuerungPageModule)
    },
    {
        path: 'triggerauswahl',
        canActivate: [IsAuthenticatedGuard, IsMandantAusgewaehltGuard],
        loadChildren: () => import('./verwaltung/displaysteuerung/triggerauswahl/triggerauswahl.module').then(m => m.TriggerauswahlPageModule)
    },
    {
        path: '',
        canActivate: [LogGuard],
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        canActivate: [LogGuard, IsAuthenticatedGuard],
        component: HomeComponent,
        // redirectTo: 'veranstaltungen',
        pathMatch: 'full'
    },
    {
        path: '**',
        canActivate: [LogGuard],
        redirectTo: 'page-not-found',
        pathMatch: 'full'
    }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            paramsInheritanceStrategy: 'always',
            // initialNavigation: false,
            onSameUrlNavigation: 'reload',
            // enableTracing: true
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
