import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_VORLAGEN_VERANSTALTUNGS_TYPEN = '[VorlagenModul] GetVorlagenVeranstaltungsTypen laden';
export const GET_VORLAGEN_VERANSTALTUNGS_TYPEN_AUSFUEHREN = '[VorlagenModul] GetVorlagenVeranstaltungsTypen ausführen';
export const GET_VORLAGEN_VERANSTALTUNGS_TYPEN_ERFOLGREICH = '[VorlagenModul] GetVorlagenVeranstaltungsTypen laden erfolgreich';
export const GET_VORLAGEN_VERANSTALTUNGS_TYPEN_FEHLER = '[VorlagenModul] GetVorlagenVeranstaltungsTypen Ladefehler';
export const GET_VORLAGEN_VERANSTALTUNGS_TYPEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetVorlagenVeranstaltungsTypen nicht ausgeführt (evtl. Offline)';



export class GetVorlagenVeranstaltungsTypenAction implements Action {
    readonly type = GET_VORLAGEN_VERANSTALTUNGS_TYPEN;
        constructor(public optPayload: any = null) {}
    }
export class GetVorlagenVeranstaltungsTypenAusfuehrenAction implements Action {
    readonly type = GET_VORLAGEN_VERANSTALTUNGS_TYPEN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVorlagenVeranstaltungsTypenErfolgreichAction implements Action {
    readonly type = GET_VORLAGEN_VERANSTALTUNGS_TYPEN_ERFOLGREICH;
        constructor(public payload: Array<api.VeranstaltungsTyp>, public optPayload: any = null) {}
    }
export class GetVorlagenVeranstaltungsTypenFehlerAction implements Action {
    readonly type = GET_VORLAGEN_VERANSTALTUNGS_TYPEN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVorlagenVeranstaltungsTypenNichtAusgefuehrtAction implements Action {
    readonly type = GET_VORLAGEN_VERANSTALTUNGS_TYPEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryVorlagenVeranstaltungsTypenActions =
    GetVorlagenVeranstaltungsTypenAction
    | GetVorlagenVeranstaltungsTypenAusfuehrenAction
    | GetVorlagenVeranstaltungsTypenErfolgreichAction
    | GetVorlagenVeranstaltungsTypenFehlerAction
    | GetVorlagenVeranstaltungsTypenNichtAusgefuehrtAction
    ;
