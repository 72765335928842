import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_DECKBLATT = '[VeranstaltungenModul] GetVeranstaltungDeckblatt laden';
export const GET_VERANSTALTUNG_DECKBLATT_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungDeckblatt ausführen';
export const GET_VERANSTALTUNG_DECKBLATT_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungDeckblatt laden erfolgreich';
export const GET_VERANSTALTUNG_DECKBLATT_FEHLER = '[VeranstaltungenModul] GetVeranstaltungDeckblatt Ladefehler';
export const GET_VERANSTALTUNG_DECKBLATT_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungDeckblatt nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungDeckblattAction implements Action {
    readonly type = GET_VERANSTALTUNG_DECKBLATT;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDeckblattAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_DECKBLATT_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDeckblattErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_DECKBLATT_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungDetail,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDeckblattFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_DECKBLATT_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDeckblattNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_DECKBLATT_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungDeckblattActions =
    GetVeranstaltungDeckblattAction
    | GetVeranstaltungDeckblattAusfuehrenAction
    | GetVeranstaltungDeckblattErfolgreichAction
    | GetVeranstaltungDeckblattFehlerAction
    | GetVeranstaltungDeckblattNichtAusgefuehrtAction
    ;
