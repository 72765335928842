import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const POST_MANDANT_CLEAR_CACHE = '[ApiModul] PostMandantClearCache laden';
export const POST_MANDANT_CLEAR_CACHE_AUSFUEHREN = '[ApiModul] PostMandantClearCache ausführen';
export const POST_MANDANT_CLEAR_CACHE_ERFOLGREICH = '[ApiModul] PostMandantClearCache laden erfolgreich';
export const POST_MANDANT_CLEAR_CACHE_FEHLER = '[ApiModul] PostMandantClearCache Ladefehler';
export const POST_MANDANT_CLEAR_CACHE_NICHT_AUSGEFUEHRT = '[ApiModul] PostMandantClearCache nicht ausgeführt (evtl. Offline)';



export class PostMandantClearCacheAction implements Action {
    readonly type = POST_MANDANT_CLEAR_CACHE;
        constructor(public optPayload: any = null) {}
    }
export class PostMandantClearCacheAusfuehrenAction implements Action {
    readonly type = POST_MANDANT_CLEAR_CACHE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class PostMandantClearCacheErfolgreichAction implements Action {
    readonly type = POST_MANDANT_CLEAR_CACHE_ERFOLGREICH;
        constructor(public payload: api.CacheClearInfo, public optPayload: any = null) {}
    }
export class PostMandantClearCacheFehlerAction implements Action {
    readonly type = POST_MANDANT_CLEAR_CACHE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class PostMandantClearCacheNichtAusgefuehrtAction implements Action {
    readonly type = POST_MANDANT_CLEAR_CACHE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiCommandMandantClearCacheActions =
    PostMandantClearCacheAction
    | PostMandantClearCacheAusfuehrenAction
    | PostMandantClearCacheErfolgreichAction
    | PostMandantClearCacheFehlerAction
    | PostMandantClearCacheNichtAusgefuehrtAction
    ;
