

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_PDF = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtPdf laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_PDF_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtPdf ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_PDF_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtPdf laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_PDF_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtPdf Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_PDF_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtPdf nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereichChecklistePruefberichtPdfAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_PDF;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichChecklistePruefberichtPdfAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_PDF_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichChecklistePruefberichtPdfErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_PDF_ERFOLGREICH;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichChecklistePruefberichtPdfFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_PDF_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public funktionsbereich_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichChecklistePruefberichtPdfNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_PDF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereichChecklistePruefberichtPdfActions =
    GetVeranstaltungFunktionsbereichChecklistePruefberichtPdfAction
    | GetVeranstaltungFunktionsbereichChecklistePruefberichtPdfAusfuehrenAction
    | GetVeranstaltungFunktionsbereichChecklistePruefberichtPdfErfolgreichAction
    | GetVeranstaltungFunktionsbereichChecklistePruefberichtPdfFehlerAction
    | GetVeranstaltungFunktionsbereichChecklistePruefberichtPdfNichtAusgefuehrtAction
    ;
