import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_REAKTIVIEREN = '[IncidentsModul] PostIncidentReaktivieren laden';
export const POST_INCIDENT_REAKTIVIEREN_AUSFUEHREN = '[IncidentsModul] PostIncidentReaktivieren ausführen';
export const POST_INCIDENT_REAKTIVIEREN_ERFOLGREICH = '[IncidentsModul] PostIncidentReaktivieren laden erfolgreich';
export const POST_INCIDENT_REAKTIVIEREN_FEHLER = '[IncidentsModul] PostIncidentReaktivieren Ladefehler';
export const POST_INCIDENT_REAKTIVIEREN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentReaktivieren nicht ausgeführt (evtl. Offline)';



export class PostIncidentReaktivierenAction implements Action {
    readonly type = POST_INCIDENT_REAKTIVIEREN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentReaktivierenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_REAKTIVIEREN_AUSFUEHREN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentReaktivierenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_REAKTIVIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentReaktivierenFehlerAction implements Action {
    readonly type = POST_INCIDENT_REAKTIVIEREN_FEHLER;
        constructor(public payload: any, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentReaktivierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_REAKTIVIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentReaktivierenActions =
    PostIncidentReaktivierenAction
    | PostIncidentReaktivierenAusfuehrenAction
    | PostIncidentReaktivierenErfolgreichAction
    | PostIncidentReaktivierenFehlerAction
    | PostIncidentReaktivierenNichtAusgefuehrtAction
    ;
