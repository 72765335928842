import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_VORLAGEN_BENUTZER_ZUGRIFF = '[VorlagenModul] GetVorlagenBenutzerZugriff laden';
export const GET_VORLAGEN_BENUTZER_ZUGRIFF_AUSFUEHREN = '[VorlagenModul] GetVorlagenBenutzerZugriff ausführen';
export const GET_VORLAGEN_BENUTZER_ZUGRIFF_ERFOLGREICH = '[VorlagenModul] GetVorlagenBenutzerZugriff laden erfolgreich';
export const GET_VORLAGEN_BENUTZER_ZUGRIFF_FEHLER = '[VorlagenModul] GetVorlagenBenutzerZugriff Ladefehler';
export const GET_VORLAGEN_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetVorlagenBenutzerZugriff nicht ausgeführt (evtl. Offline)';



export class GetVorlagenBenutzerZugriffAction implements Action {
    readonly type = GET_VORLAGEN_BENUTZER_ZUGRIFF;
        constructor(public optPayload: any = null) {}
    }
export class GetVorlagenBenutzerZugriffAusfuehrenAction implements Action {
    readonly type = GET_VORLAGEN_BENUTZER_ZUGRIFF_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVorlagenBenutzerZugriffErfolgreichAction implements Action {
    readonly type = GET_VORLAGEN_BENUTZER_ZUGRIFF_ERFOLGREICH;
        constructor(public payload: api.VorlagenBenutzerZugriff, public optPayload: any = null) {}
    }
export class GetVorlagenBenutzerZugriffFehlerAction implements Action {
    readonly type = GET_VORLAGEN_BENUTZER_ZUGRIFF_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVorlagenBenutzerZugriffNichtAusgefuehrtAction implements Action {
    readonly type = GET_VORLAGEN_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryVorlagenBenutzerZugriffActions =
    GetVorlagenBenutzerZugriffAction
    | GetVorlagenBenutzerZugriffAusfuehrenAction
    | GetVorlagenBenutzerZugriffErfolgreichAction
    | GetVorlagenBenutzerZugriffFehlerAction
    | GetVorlagenBenutzerZugriffNichtAusgefuehrtAction
    ;
