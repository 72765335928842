import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AlertController, LoadingController, ModalController, NavController, Platform} from '@ionic/angular';
import {select, Store} from '@ngrx/store';
import * as fromMandantModule from '@orga-app/mandant/reducer';
import {UuidService} from '@orga-app/providers/uuid.service';
import {Settings, SettingsProvider} from '@orga-app/settings';
import {getTheme} from '@orga-app/settings/reducer';
import * as actionsUi from './displaysteuerung.actions.ui';
import {Observable} from 'rxjs/Observable';
import {SafeUrl} from '@angular/platform-browser';
import {BereichDetail, BereichKopfdaten, BereichListItem, BereichLogoRequest, BereichTrigger, BereichTriggerReihenfolgeRequest, Rolle} from '../../../clients/displaysteuerung';
import {getBerechtigungRollen, getBereichDetail, getBereichDetailFehler, getBereichDetailLaden, getBereicheListe, getBereicheListeFehler, getBereicheListeLaden, getSelectedBereich, getSelectedRolle, getSelectedTrigger, getTriggerIds, getTriggerListe} from './displaysteuerung.selectors';
import {PageState} from './displaysteuerung.reducer';
import {
    AktuellenBereichGesetztAction, AktuellenTriggerAusloesenAction, AktuellenTriggerGesetztAction,
    AktuellenTriggerIconGesetztAction, AktuelleRolleBerechtigungSetzenAction, BereichBerechtigungRolleEntfernenAction, BereichBerechtigungRollenSetzenAction,
    BereichBeschreibungSetzenAction,
    BereicheListeLadenAction,
    BereichEntfernenAction,
    BereichExterneURLSetzenAction,
    BereichHinzufuegenAction,
    BereichIstExterneURLSetzenAction,
    BereichIstTriggerListeSetzenAction,
    BereichKopfdatenSpeichernAction,
    BereichTitelSetzenAction,
    BereichTriggerEntfernenAction,
    BereichTriggerHinzufuegenAction,
    BereichZusatzBeschreibungSetzenAction
} from './displaysteuerung.actions.ui';
import {UploadResponse, UploadService} from '../../../clients/api';
import {PostBereichLogoAktualisierenAction, PostBereichTriggerReihenfolgeSetzenAction} from '../../../client-ngrx/displaysteuerung';
import {ItemWrapper, ListInputItem} from '../../shared/sopi-list/sopi-list.model';
import {Datenquelle} from '../rollenselektion/rollenselektion.model';
import {RollenselektionPage} from '../rollenselektion/rollenselektion.page';
import {TriggerauswahlPage} from './triggerauswahl/triggerauswahl.page';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'page-displaysteuerung',
    templateUrl: 'displaysteuerung.page.html'
})

export class DisplaysteuerungPage implements OnInit {
    @ViewChild('logoFileInput', {static: false, read: ElementRef}) logoFileInput: ElementRef;

    public settings$: Observable<Settings>;
    themeDefault$: Observable<Settings>;
    logoHeaderSVG$: Observable<SafeUrl>;

    bereicheListe$: Observable<Array<ListInputItem>>;
    bereicheListeLaden$: Observable<boolean>;
    bereicheListeFehler$: Observable<boolean>;

    selectedBereich$: Observable<BereichListItem>;

    bereichDetail$: Observable<BereichDetail>;
    bereichDetailLaden$: Observable<boolean>;
    bereichDetailFehler$: Observable<boolean>;

    berechtigungRollen$: Observable<Array<ListInputItem>>;
    selectedRolle$: Observable<Rolle>;

    triggerListe$: Observable<Array<ListInputItem>>;
    selectedTrigger$: Observable<BereichTrigger>;

    triggerIds$: Observable<Array<string>>;

    aktuellerTab = 'Kopfdaten';
    image: any;

    constructor(public navCtrl: NavController,
                public modalCtrl: ModalController,
                private storeMandant: Store<fromMandantModule.MandantState>,
                public platform: Platform,
                private uuid: UuidService,
                private store: Store<PageState>,
                public settingsProvider: SettingsProvider,
                public loadingCtrl: LoadingController,
                private uploadCommandService: UploadService,
                private alertCtrl: AlertController
    ) {
        this.themeDefault$ = this.settingsProvider.getThemeDefault();
        this.logoHeaderSVG$ = this.settingsProvider.getLogoHeaderSVG();
        this.settings$ = this.store.pipe(select(getTheme));
    }

    ngOnInit() {
        this.store.dispatch(new actionsUi.PageStateResetAction());

        this.bereicheListe$ = this.store.pipe(select(getBereicheListe));
        this.bereicheListeLaden$ = this.store.pipe(select(getBereicheListeLaden));
        this.bereicheListeFehler$ = this.store.pipe(select(getBereicheListeFehler));

        this.selectedBereich$ = this.store.pipe(select(getSelectedBereich));

        this.bereichDetail$ = this.store.pipe(select(getBereichDetail));
        this.bereichDetailLaden$ = this.store.pipe(select(getBereichDetailLaden));
        this.bereichDetailFehler$ = this.store.pipe(select(getBereichDetailFehler));

        this.berechtigungRollen$ = this.store.pipe(select(getBerechtigungRollen));
        this.selectedRolle$ = this.store.pipe(select(getSelectedRolle));

        this.triggerListe$ = this.store.pipe(select(getTriggerListe));
        this.selectedTrigger$ = this.store.pipe(select(getSelectedTrigger));

        this.triggerIds$ = this.store.pipe(select(getTriggerIds));
    }

    async bereichEntfernen() {
        const message = 'Soll der Bereich wirklich gelöscht werden?';
        const prompt = await this.alertCtrl.create({
            header: 'ACHTUNG',
            message,
            buttons: [
                {
                    text: 'Ja',
                    handler: () => {
                        this.store.dispatch(new BereichEntfernenAction());
                    }
                },
                {
                    text: 'Nein',
                    handler: () => {
                    }
                }
            ],
            cssClass: 'alertCustomCss'
        });
        await prompt.present();

    }

    bereichHinzufuegen() {
        this.store.dispatch(new BereichHinzufuegenAction());
    }

    bereicheListeLaden() {
        this.store.dispatch(new BereicheListeLadenAction());
    }

    bereichAuswaehlen(bereich: BereichListItem) {
        this.store.dispatch(new AktuellenBereichGesetztAction(bereich));
    }

    kopfdatenSpeichern() {
        this.store.dispatch(new BereichKopfdatenSpeichernAction());
    }

    bereichTitleSetzen($event) {
        this.store.dispatch(new BereichTitelSetzenAction($event.target.value));
    }

    bereichBeschreibungSetzen($event) {
        this.store.dispatch(new BereichBeschreibungSetzenAction($event.target.value));
    }

    bereichZusatzBeschreibungSetzen($event) {
        this.store.dispatch(new BereichZusatzBeschreibungSetzenAction($event.target.value));
    }

    bildEntfernen() {

    }

    async bildAuswahlen() {
        this.logoFileInput.nativeElement.click();
    }

    async bildEinfuegen(bereich: BereichDetail) {
        const fi = this.logoFileInput.nativeElement;
        if (fi.files && fi.files[0]) {
            const fileToUpload = fi.files[0];

            const loading = await this.loadingCtrl.create({
                spinner: 'dots',
                message: 'Das Icon wird hochgeladen ...',
                translucent: true,
                backdropDismiss: false,
                keyboardClose: true
            });

            await loading.present();
            this.uploadCommandService.postUploadForm(fileToUpload, `displaysteuerung/logos`).subscribe((result: UploadResponse) => {
                const request = <BereichLogoRequest>{
                    logo_blob_id: result.upload_id,
                    bereich_id: bereich.kopfdaten.bereich_id,
                    zeitpunkt_utc: new Date()
                };
                loading.dismiss();
                this.store.dispatch(new PostBereichLogoAktualisierenAction(request));
            }, error2 => {
            });
        }
    }

    changeIstTriggerListe($event: CustomEvent, value: boolean) {
        if (value !== $event.detail.checked) {
            this.store.dispatch(new BereichIstTriggerListeSetzenAction($event.detail.checked));
        }
    }

    changeIstExterneUrl($event: CustomEvent, value: boolean) {
        this.store.dispatch(new BereichIstExterneURLSetzenAction($event.detail.checked));
    }

    bereichExterneUrlSetzen($event) {
        this.store.dispatch(new BereichExterneURLSetzenAction($event.target.value));
    }

    async testeUrl(kopfdaten: BereichKopfdaten) {
        window.open(kopfdaten.externe_url, '_blank');
    }

    async bereichBerechtigungRollenAuswahl(aktuellerBereich: BereichDetail) {
        if (aktuellerBereich != null) {
            const params = {
                titel: aktuellerBereich.kopfdaten.titel,
                nichtAnzuzeigendeRollenIds: aktuellerBereich.berechtigung_rollen.map(x => x.rolle_id),
                funktionsbereich: null,
                checkliste: null,
                untertitel: 'Displaysteuerung - Berechtigung',
                alleAnzeigenAuswahlAnzeigen: false,
                alleAnzeigen: true,
                datenquelle: Datenquelle.Displaysteuerung
            };
            const modal = await this.modalCtrl.create({component: RollenselektionPage, componentProps: params});
            modal.onDidDismiss().then((result: any) => {
                if (result != null && result.data) {
                    // console.log('REsult: ', result);
                    if (result.data.ausgewaehlteRollen !== null && result.data.ausgewaehlteRollen.length > 0) {
                        this.store.dispatch(new BereichBerechtigungRollenSetzenAction(result.data.ausgewaehlteRollen));
                    }
                }
            });

            await modal.present();
        }
    }

    bereichBerechtigungLoeschen() {
        this.store.dispatch(new BereichBerechtigungRolleEntfernenAction());
    }

    rollenSelectionChanged($event: Rolle) {
        // console.log('Rolle SElected: ', $event);
        this.store.dispatch(new AktuelleRolleBerechtigungSetzenAction($event));
    }

    triggerAuswaehlen($event: BereichTrigger) {
        this.store.dispatch(new AktuellenTriggerGesetztAction($event));
    }

    async triggerHinzufuegen(bereich: BereichDetail) {
        const params = {
            titel: bereich.kopfdaten.titel,
            aktuell: bereich.trigger.map(x => x.trigger_id)
        };

        const modal = await this.modalCtrl.create({
            component: TriggerauswahlPage,
            componentProps: params
        });

        modal.onDidDismiss().then((result: any) => {
            if (result !== null && result.data) {
                const data = result.data;

                this.store.dispatch(new BereichTriggerHinzufuegenAction(data.hinzugefuegte));
            }
        });

        await modal.present();
    }

    triggerEntfernen() {
        this.store.dispatch(new BereichTriggerEntfernenAction());
    }

    async changeTriggerIcon(icon: string) {
        this.store.dispatch(new AktuellenTriggerIconGesetztAction(icon));
    }

    triggerAusloesen() {
        this.store.dispatch(new AktuellenTriggerAusloesenAction());
    }

    async dropInTriggerListe($event: CdkDragDrop<any>) {
        await this.dropInTriggerListeNeu({event: $event, item: null});
    }

    async dropInTriggerListeNeu(data: {event: CdkDragDrop<any>, item: any}) {
        const $event = data.event;
        // console.log($event);
        this.triggerListe$.subscribe(items => {
            const trigger = [...items.map(x => x.item)];
            moveItemInArray(trigger, $event.previousIndex, $event.currentIndex);
            const triggerIds = trigger.map(trg => trg.trigger_id);
            const request = <BereichTriggerReihenfolgeRequest>{
                bereich_id: trigger[0].bereich_id,
                trigger_ids: triggerIds,
                zeitpunkt_utc: new Date()
            };
            this.store.dispatch(new PostBereichTriggerReihenfolgeSetzenAction(request));
        }).unsubscribe();
    }

    trackByTriggerItem(index, item: ItemWrapper) {
        return item.id + item.selected;
    }
}
