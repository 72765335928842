import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_KORRIGIEREN = '[QschecksModul] PostAufgabeKorrigieren laden';
export const POST_AUFGABE_KORRIGIEREN_AUSFUEHREN = '[QschecksModul] PostAufgabeKorrigieren ausführen';
export const POST_AUFGABE_KORRIGIEREN_ERFOLGREICH = '[QschecksModul] PostAufgabeKorrigieren laden erfolgreich';
export const POST_AUFGABE_KORRIGIEREN_FEHLER = '[QschecksModul] PostAufgabeKorrigieren Ladefehler';
export const POST_AUFGABE_KORRIGIEREN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostAufgabeKorrigieren nicht ausgeführt (evtl. Offline)';



export class PostAufgabeKorrigierenAction implements Action {
    readonly type = POST_AUFGABE_KORRIGIEREN;
        constructor(public prueflaufAufgabeAntwortRequest: api.PrueflaufAufgabeAntwortRequest, public optPayload: any = null) {}
    }
export class PostAufgabeKorrigierenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_KORRIGIEREN_AUSFUEHREN;
        constructor(public prueflaufAufgabeAntwortRequest: api.PrueflaufAufgabeAntwortRequest, public optPayload: any = null) {}
    }
export class PostAufgabeKorrigierenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_KORRIGIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public prueflaufAufgabeAntwortRequest: api.PrueflaufAufgabeAntwortRequest, public optPayload: any = null) {}
    }
export class PostAufgabeKorrigierenFehlerAction implements Action {
    readonly type = POST_AUFGABE_KORRIGIEREN_FEHLER;
        constructor(public payload: any, public prueflaufAufgabeAntwortRequest: api.PrueflaufAufgabeAntwortRequest, public optPayload: any = null) {}
    }
export class PostAufgabeKorrigierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_KORRIGIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandAufgabeKorrigierenActions =
    PostAufgabeKorrigierenAction
    | PostAufgabeKorrigierenAusfuehrenAction
    | PostAufgabeKorrigierenErfolgreichAction
    | PostAufgabeKorrigierenFehlerAction
    | PostAufgabeKorrigierenNichtAusgefuehrtAction
    ;
