

import {Action} from '@ngrx/store';

export const GET_INCIDENTS_REPORTING_XSD_TEMPLATE = '[IncidentsModul] GetIncidentsReportingXsdTemplate laden';
export const GET_INCIDENTS_REPORTING_XSD_TEMPLATE_AUSFUEHREN = '[IncidentsModul] GetIncidentsReportingXsdTemplate ausführen';
export const GET_INCIDENTS_REPORTING_XSD_TEMPLATE_ERFOLGREICH = '[IncidentsModul] GetIncidentsReportingXsdTemplate laden erfolgreich';
export const GET_INCIDENTS_REPORTING_XSD_TEMPLATE_FEHLER = '[IncidentsModul] GetIncidentsReportingXsdTemplate Ladefehler';
export const GET_INCIDENTS_REPORTING_XSD_TEMPLATE_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentsReportingXsdTemplate nicht ausgeführt (evtl. Offline)';



export class GetIncidentsReportingXsdTemplateAction implements Action {
    readonly type = GET_INCIDENTS_REPORTING_XSD_TEMPLATE;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsReportingXsdTemplateAusfuehrenAction implements Action {
    readonly type = GET_INCIDENTS_REPORTING_XSD_TEMPLATE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsReportingXsdTemplateErfolgreichAction implements Action {
    readonly type = GET_INCIDENTS_REPORTING_XSD_TEMPLATE_ERFOLGREICH;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsReportingXsdTemplateFehlerAction implements Action {
    readonly type = GET_INCIDENTS_REPORTING_XSD_TEMPLATE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetIncidentsReportingXsdTemplateNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENTS_REPORTING_XSD_TEMPLATE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentsReportingXsdTemplateActions =
    GetIncidentsReportingXsdTemplateAction
    | GetIncidentsReportingXsdTemplateAusfuehrenAction
    | GetIncidentsReportingXsdTemplateErfolgreichAction
    | GetIncidentsReportingXsdTemplateFehlerAction
    | GetIncidentsReportingXsdTemplateNichtAusgefuehrtAction
    ;
