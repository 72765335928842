export * from './kommunikationCommand.service';
import { KommunikationCommandService } from './kommunikationCommand.service';
export * from './kommunikationQuery.service';
import { KommunikationQueryService } from './kommunikationQuery.service';
export * from './kommunikationTemplates.service';
import { KommunikationTemplatesService } from './kommunikationTemplates.service';
export * from './mailCommand.service';
import { MailCommandService } from './mailCommand.service';
export * from './mitteilungenCommand.service';
import { MitteilungenCommandService } from './mitteilungenCommand.service';
export * from './mitteilungenQuery.service';
import { MitteilungenQueryService } from './mitteilungenQuery.service';
export * from './mitteilungsVorlageCommand.service';
import { MitteilungsVorlageCommandService } from './mitteilungsVorlageCommand.service';
export * from './mitteilungsVorlageQuery.service';
import { MitteilungsVorlageQueryService } from './mitteilungsVorlageQuery.service';
export * from './troubleTicketCommand.service';
import { TroubleTicketCommandService } from './troubleTicketCommand.service';
export * from './troubleTicketQuery.service';
import { TroubleTicketQueryService } from './troubleTicketQuery.service';
export const APIS = [KommunikationCommandService, KommunikationQueryService, KommunikationTemplatesService, MailCommandService, MitteilungenCommandService, MitteilungenQueryService, MitteilungsVorlageCommandService, MitteilungsVorlageQueryService, TroubleTicketCommandService, TroubleTicketQueryService];
