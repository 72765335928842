import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {AufgabenBerechtigungenModalPage} from './aufgaben-berechtigungen-modal.page';
import {StoreModule} from '@ngrx/store';
import {reducers} from './aufgaben-berechtigungen-modal.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AufgabenBerechtigungenModalPageUiEffects} from './aufgaben-berechtigungen-modal.effects.ui';
import {BenutzerverwaltungBenutzerListeEffects} from '../../../../client-ngrx/benutzerverwaltung';
import {SopiScrollModule} from '../../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {SopiComponentsModule} from '../../../shared/sopi-components/sopi-components.module';
import {VeranstaltungenVeranstaltungBenutzerListeAdminEffects} from '../../../../client-ngrx/veranstaltungen';

const routes: Routes = [
    {
        path: '',
        component: AufgabenBerechtigungenModalPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature('AufgabenBerechtigungenModal', reducers),
        EffectsModule.forFeature([
            AufgabenBerechtigungenModalPageUiEffects,
            BenutzerverwaltungBenutzerListeEffects,
            VeranstaltungenVeranstaltungBenutzerListeAdminEffects
        ]),
        SopiScrollModule,
        SopiComponentsModule.forRoot()
    ],
    declarations: [AufgabenBerechtigungenModalPage],
    entryComponents: [AufgabenBerechtigungenModalPage],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AufgabenBerechtigungenModalModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: AufgabenBerechtigungenModalModule,
            providers: []
        };
    }
}
