import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_VORLAGEN_CONFIG_TYPES = '[VorlagenModul] GetVorlagenConfigTypes laden';
export const GET_VORLAGEN_CONFIG_TYPES_AUSFUEHREN = '[VorlagenModul] GetVorlagenConfigTypes ausführen';
export const GET_VORLAGEN_CONFIG_TYPES_ERFOLGREICH = '[VorlagenModul] GetVorlagenConfigTypes laden erfolgreich';
export const GET_VORLAGEN_CONFIG_TYPES_FEHLER = '[VorlagenModul] GetVorlagenConfigTypes Ladefehler';
export const GET_VORLAGEN_CONFIG_TYPES_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetVorlagenConfigTypes nicht ausgeführt (evtl. Offline)';



export class GetVorlagenConfigTypesAction implements Action {
    readonly type = GET_VORLAGEN_CONFIG_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetVorlagenConfigTypesAusfuehrenAction implements Action {
    readonly type = GET_VORLAGEN_CONFIG_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVorlagenConfigTypesErfolgreichAction implements Action {
    readonly type = GET_VORLAGEN_CONFIG_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetVorlagenConfigTypesFehlerAction implements Action {
    readonly type = GET_VORLAGEN_CONFIG_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVorlagenConfigTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_VORLAGEN_CONFIG_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryVorlagenConfigTypesActions =
    GetVorlagenConfigTypesAction
    | GetVorlagenConfigTypesAusfuehrenAction
    | GetVorlagenConfigTypesErfolgreichAction
    | GetVorlagenConfigTypesFehlerAction
    | GetVorlagenConfigTypesNichtAusgefuehrtAction
    ;
