import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENT_ABTEILUNG_NOTIFICATION = '[IncidentsModul] GetIncidentAbteilungNotification laden';
export const GET_INCIDENT_ABTEILUNG_NOTIFICATION_AUSFUEHREN = '[IncidentsModul] GetIncidentAbteilungNotification ausführen';
export const GET_INCIDENT_ABTEILUNG_NOTIFICATION_ERFOLGREICH = '[IncidentsModul] GetIncidentAbteilungNotification laden erfolgreich';
export const GET_INCIDENT_ABTEILUNG_NOTIFICATION_FEHLER = '[IncidentsModul] GetIncidentAbteilungNotification Ladefehler';
export const GET_INCIDENT_ABTEILUNG_NOTIFICATION_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentAbteilungNotification nicht ausgeführt (evtl. Offline)';



export class GetIncidentAbteilungNotificationAction implements Action {
    readonly type = GET_INCIDENT_ABTEILUNG_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentAbteilungNotificationAusfuehrenAction implements Action {
    readonly type = GET_INCIDENT_ABTEILUNG_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentAbteilungNotificationErfolgreichAction implements Action {
    readonly type = GET_INCIDENT_ABTEILUNG_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.IncidentAbteilungNotification, public optPayload: any = null) {}
    }
export class GetIncidentAbteilungNotificationFehlerAction implements Action {
    readonly type = GET_INCIDENT_ABTEILUNG_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetIncidentAbteilungNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENT_ABTEILUNG_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentAbteilungNotificationActions =
    GetIncidentAbteilungNotificationAction
    | GetIncidentAbteilungNotificationAusfuehrenAction
    | GetIncidentAbteilungNotificationErfolgreichAction
    | GetIncidentAbteilungNotificationFehlerAction
    | GetIncidentAbteilungNotificationNichtAusgefuehrtAction
    ;
