import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_BILD_HINZUFUEGEN = '[QschecksModul] PostAufgabeBildHinzufuegen laden';
export const POST_AUFGABE_BILD_HINZUFUEGEN_AUSFUEHREN = '[QschecksModul] PostAufgabeBildHinzufuegen ausführen';
export const POST_AUFGABE_BILD_HINZUFUEGEN_ERFOLGREICH = '[QschecksModul] PostAufgabeBildHinzufuegen laden erfolgreich';
export const POST_AUFGABE_BILD_HINZUFUEGEN_FEHLER = '[QschecksModul] PostAufgabeBildHinzufuegen Ladefehler';
export const POST_AUFGABE_BILD_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostAufgabeBildHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostAufgabeBildHinzufuegenAction implements Action {
    readonly type = POST_AUFGABE_BILD_HINZUFUEGEN;
        constructor(public prueflaufAufgabeBildRequest: api.PrueflaufAufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_BILD_HINZUFUEGEN_AUSFUEHREN;
        constructor(public prueflaufAufgabeBildRequest: api.PrueflaufAufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_BILD_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public prueflaufAufgabeBildRequest: api.PrueflaufAufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildHinzufuegenFehlerAction implements Action {
    readonly type = POST_AUFGABE_BILD_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public prueflaufAufgabeBildRequest: api.PrueflaufAufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_BILD_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandAufgabeBildHinzufuegenActions =
    PostAufgabeBildHinzufuegenAction
    | PostAufgabeBildHinzufuegenAusfuehrenAction
    | PostAufgabeBildHinzufuegenErfolgreichAction
    | PostAufgabeBildHinzufuegenFehlerAction
    | PostAufgabeBildHinzufuegenNichtAusgefuehrtAction
    ;
