import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_ROLLE_IDENTIFIER = '[BenutzerverwaltungModul] GetRolleIdentifier laden';
export const GET_ROLLE_IDENTIFIER_AUSFUEHREN = '[BenutzerverwaltungModul] GetRolleIdentifier ausführen';
export const GET_ROLLE_IDENTIFIER_ERFOLGREICH = '[BenutzerverwaltungModul] GetRolleIdentifier laden erfolgreich';
export const GET_ROLLE_IDENTIFIER_FEHLER = '[BenutzerverwaltungModul] GetRolleIdentifier Ladefehler';
export const GET_ROLLE_IDENTIFIER_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetRolleIdentifier nicht ausgeführt (evtl. Offline)';



export class GetRolleIdentifierAction implements Action {
    readonly type = GET_ROLLE_IDENTIFIER;
        constructor(public optPayload: any = null) {}
    }
export class GetRolleIdentifierAusfuehrenAction implements Action {
    readonly type = GET_ROLLE_IDENTIFIER_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetRolleIdentifierErfolgreichAction implements Action {
    readonly type = GET_ROLLE_IDENTIFIER_ERFOLGREICH;
        constructor(public payload: api.RolleIdentifier, public optPayload: any = null) {}
    }
export class GetRolleIdentifierFehlerAction implements Action {
    readonly type = GET_ROLLE_IDENTIFIER_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetRolleIdentifierNichtAusgefuehrtAction implements Action {
    readonly type = GET_ROLLE_IDENTIFIER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryRolleIdentifierActions =
    GetRolleIdentifierAction
    | GetRolleIdentifierAusfuehrenAction
    | GetRolleIdentifierErfolgreichAction
    | GetRolleIdentifierFehlerAction
    | GetRolleIdentifierNichtAusgefuehrtAction
    ;
