import * as api from '../../../clients/einlasskontrolle'; 

import {Action} from '@ngrx/store';

export const GET_EINLASSKONTROLLE_ROLLE = '[EinlasskontrolleModul] GetEinlasskontrolleRolle laden';
export const GET_EINLASSKONTROLLE_ROLLE_AUSFUEHREN = '[EinlasskontrolleModul] GetEinlasskontrolleRolle ausführen';
export const GET_EINLASSKONTROLLE_ROLLE_ERFOLGREICH = '[EinlasskontrolleModul] GetEinlasskontrolleRolle laden erfolgreich';
export const GET_EINLASSKONTROLLE_ROLLE_FEHLER = '[EinlasskontrolleModul] GetEinlasskontrolleRolle Ladefehler';
export const GET_EINLASSKONTROLLE_ROLLE_NICHT_AUSGEFUEHRT = '[EinlasskontrolleModul] GetEinlasskontrolleRolle nicht ausgeführt (evtl. Offline)';



export class GetEinlasskontrolleRolleAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_ROLLE;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetEinlasskontrolleRolleAusfuehrenAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_ROLLE_AUSFUEHREN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetEinlasskontrolleRolleErfolgreichAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_ROLLE_ERFOLGREICH;
        constructor(public payload: api.EinlasskontrolleRolleBerechtigung,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetEinlasskontrolleRolleFehlerAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_ROLLE_FEHLER;
        constructor(public payload: any,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetEinlasskontrolleRolleNichtAusgefuehrtAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_ROLLE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type EinlasskontrolleQueryEinlasskontrolleRolleActions =
    GetEinlasskontrolleRolleAction
    | GetEinlasskontrolleRolleAusfuehrenAction
    | GetEinlasskontrolleRolleErfolgreichAction
    | GetEinlasskontrolleRolleFehlerAction
    | GetEinlasskontrolleRolleNichtAusgefuehrtAction
    ;
