import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {Aufgabe, Fehler} from '../../../clients/veranstaltungen';
import {getAktuelleAufgabe} from './fehlerbeschreibung.reducer';
import {filter, map, withLatestFrom} from 'rxjs/operators';
import {
    GetAufgabeFehlerAction,
    POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_ERFOLGREICH,
    PostFehlerbeschreibungHinzufuegenErfolgreichAction
} from '../../../client-ngrx/veranstaltungen';
import {FehlerbeschreibungErfolgreichVersendetAction} from './fehlerbeschreibung.actions.ui';
import {VeranstaltungenEntityTypes} from '../../../client-constants';
import {WS_READ_MODEL_UPDATED, WsReadModelUpdatedAction} from '../../websocket.actions';
import FEHLER_ENTITY = VeranstaltungenEntityTypes.FEHLER_ENTITY;

@Injectable()
export class FehlerbeschreibungPageUiEffects {

    @Effect()
    fehlerbeschreibungHinzufuegenErfolgreich$: Observable<Action> = this.actions$.pipe(
        ofType(POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_ERFOLGREICH),
        map((x: PostFehlerbeschreibungHinzufuegenErfolgreichAction) => {
                return new FehlerbeschreibungErfolgreichVersendetAction();
            }
        )
    );
    @Effect()
    wsFehler$: Observable<Action> = this.actions$.pipe(
        ofType<WsReadModelUpdatedAction>(WS_READ_MODEL_UPDATED),
        filter((x: WsReadModelUpdatedAction) => {
            return x.message.entity_type.toUpperCase() === FEHLER_ENTITY;
        }),
        map((x) => <Fehler>JSON.parse(x.message.entity)),
        withLatestFrom(this.store$.pipe(select(getAktuelleAufgabe))),
        filter(([x, aktuelleAufgabe]: [Fehler, Aufgabe]) => aktuelleAufgabe != null),
        filter(([x, aktuelleAufgabe]: [Fehler, Aufgabe]) => aktuelleAufgabe.aufgabe_id === x.aufgabe_id),
        map(([x, aktuelleAufgabe]: [Fehler, Aufgabe]) => {
                return new GetAufgabeFehlerAction(aktuelleAufgabe.aufgabe_id);
            }
        )
    );

    constructor(private actions$: Actions,
                private store$: Store<any>) {
    }
}
