import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFCHECKLISTE_HINZUFUEGEN = '[QschecksModul] PostPruefchecklisteHinzufuegen laden';
export const POST_PRUEFCHECKLISTE_HINZUFUEGEN_AUSFUEHREN = '[QschecksModul] PostPruefchecklisteHinzufuegen ausführen';
export const POST_PRUEFCHECKLISTE_HINZUFUEGEN_ERFOLGREICH = '[QschecksModul] PostPruefchecklisteHinzufuegen laden erfolgreich';
export const POST_PRUEFCHECKLISTE_HINZUFUEGEN_FEHLER = '[QschecksModul] PostPruefchecklisteHinzufuegen Ladefehler';
export const POST_PRUEFCHECKLISTE_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefchecklisteHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostPruefchecklisteHinzufuegenAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_HINZUFUEGEN;
        constructor(public pruefchecklisteModelRequest: api.PruefchecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_HINZUFUEGEN_AUSFUEHREN;
        constructor(public pruefchecklisteModelRequest: api.PruefchecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefchecklisteModelRequest: api.PruefchecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteHinzufuegenFehlerAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public pruefchecklisteModelRequest: api.PruefchecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefchecklisteHinzufuegenActions =
    PostPruefchecklisteHinzufuegenAction
    | PostPruefchecklisteHinzufuegenAusfuehrenAction
    | PostPruefchecklisteHinzufuegenErfolgreichAction
    | PostPruefchecklisteHinzufuegenFehlerAction
    | PostPruefchecklisteHinzufuegenNichtAusgefuehrtAction
    ;
