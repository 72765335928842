import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-veranstaltung-loeschen',
    templateUrl: './veranstaltung-loeschen.page.html'
})
export class VeranstaltungLoeschenPage implements OnInit {
    @Input() titel: string;

    private aiName = 'VeranstaltungLoeschenModal';

    constructor(private modalCtrl: ModalController) {
    }

    ngOnInit() {
    }

    ionViewDidLoad() {
    }


    async ok() {
        await this.modalCtrl.dismiss('ok');
    }

    async abbrechen() {
        await this.modalCtrl.dismiss();
    }

}
