import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_BILD_ENTFERNEN = '[QschecksModul] PostAufgabeBildEntfernen laden';
export const POST_AUFGABE_BILD_ENTFERNEN_AUSFUEHREN = '[QschecksModul] PostAufgabeBildEntfernen ausführen';
export const POST_AUFGABE_BILD_ENTFERNEN_ERFOLGREICH = '[QschecksModul] PostAufgabeBildEntfernen laden erfolgreich';
export const POST_AUFGABE_BILD_ENTFERNEN_FEHLER = '[QschecksModul] PostAufgabeBildEntfernen Ladefehler';
export const POST_AUFGABE_BILD_ENTFERNEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostAufgabeBildEntfernen nicht ausgeführt (evtl. Offline)';



export class PostAufgabeBildEntfernenAction implements Action {
    readonly type = POST_AUFGABE_BILD_ENTFERNEN;
        constructor(public prueflaufAufgabeBildRequest: api.PrueflaufAufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildEntfernenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_BILD_ENTFERNEN_AUSFUEHREN;
        constructor(public prueflaufAufgabeBildRequest: api.PrueflaufAufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildEntfernenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_BILD_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public prueflaufAufgabeBildRequest: api.PrueflaufAufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildEntfernenFehlerAction implements Action {
    readonly type = POST_AUFGABE_BILD_ENTFERNEN_FEHLER;
        constructor(public payload: any, public prueflaufAufgabeBildRequest: api.PrueflaufAufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_BILD_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandAufgabeBildEntfernenActions =
    PostAufgabeBildEntfernenAction
    | PostAufgabeBildEntfernenAusfuehrenAction
    | PostAufgabeBildEntfernenErfolgreichAction
    | PostAufgabeBildEntfernenFehlerAction
    | PostAufgabeBildEntfernenNichtAusgefuehrtAction
    ;
