import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_MITTEILUNGEN_ALLE = '[KommunikationModul] GetMitteilungenAlle laden';
export const GET_MITTEILUNGEN_ALLE_AUSFUEHREN = '[KommunikationModul] GetMitteilungenAlle ausführen';
export const GET_MITTEILUNGEN_ALLE_ERFOLGREICH = '[KommunikationModul] GetMitteilungenAlle laden erfolgreich';
export const GET_MITTEILUNGEN_ALLE_FEHLER = '[KommunikationModul] GetMitteilungenAlle Ladefehler';
export const GET_MITTEILUNGEN_ALLE_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetMitteilungenAlle nicht ausgeführt (evtl. Offline)';



export class GetMitteilungenAlleAction implements Action {
    readonly type = GET_MITTEILUNGEN_ALLE;
        constructor(public optPayload: any = null) {}
    }
export class GetMitteilungenAlleAusfuehrenAction implements Action {
    readonly type = GET_MITTEILUNGEN_ALLE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMitteilungenAlleErfolgreichAction implements Action {
    readonly type = GET_MITTEILUNGEN_ALLE_ERFOLGREICH;
        constructor(public payload: Array<api.Mitteilung>, public optPayload: any = null) {}
    }
export class GetMitteilungenAlleFehlerAction implements Action {
    readonly type = GET_MITTEILUNGEN_ALLE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMitteilungenAlleNichtAusgefuehrtAction implements Action {
    readonly type = GET_MITTEILUNGEN_ALLE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryMitteilungenAlleActions =
    GetMitteilungenAlleAction
    | GetMitteilungenAlleAusfuehrenAction
    | GetMitteilungenAlleErfolgreichAction
    | GetMitteilungenAlleFehlerAction
    | GetMitteilungenAlleNichtAusgefuehrtAction
    ;
