/**
 * orga app | kommunikation
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Benutzer } from '../model/benutzer';
import { DatumGruppeMitteilungen } from '../model/datumGruppeMitteilungen';
import { Mitteilung } from '../model/mitteilung';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MitteilungenQueryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetMitteilungEmpfaenger (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param mitteilung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMitteilungEmpfaenger(mitteilung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Benutzer>>;
    public getMitteilungEmpfaenger(mitteilung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Benutzer>>>;
    public getMitteilungEmpfaenger(mitteilung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Benutzer>>>;
    public getMitteilungEmpfaenger(mitteilung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (mitteilung_id === null || mitteilung_id === undefined) {
            throw new Error('Required parameter mitteilung_id was null or undefined when calling getMitteilungEmpfaenger.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Benutzer>>('get',`${this.basePath}/api/query/kommunikation/mitteilungen/details/${encodeURIComponent(String(mitteilung_id))}/empfaenger`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMitteilungen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param korrelation_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMitteilungen(korrelation_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Mitteilung>>;
    public getMitteilungen(korrelation_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Mitteilung>>>;
    public getMitteilungen(korrelation_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Mitteilung>>>;
    public getMitteilungen(korrelation_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (korrelation_id === null || korrelation_id === undefined) {
            throw new Error('Required parameter korrelation_id was null or undefined when calling getMitteilungen.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Mitteilung>>('get',`${this.basePath}/api/query/kommunikation/mitteilungen/${encodeURIComponent(String(korrelation_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMitteilungenAlle (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMitteilungenAlle(observe?: 'body', reportProgress?: boolean): Observable<Array<Mitteilung>>;
    public getMitteilungenAlle(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Mitteilung>>>;
    public getMitteilungenAlle(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Mitteilung>>>;
    public getMitteilungenAlle(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Mitteilung>>('get',`${this.basePath}/api/query/kommunikation/mitteilungen/alle`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMitteilungenGesendet (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param korrelation_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMitteilungenGesendet(korrelation_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Mitteilung>>;
    public getMitteilungenGesendet(korrelation_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Mitteilung>>>;
    public getMitteilungenGesendet(korrelation_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Mitteilung>>>;
    public getMitteilungenGesendet(korrelation_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (korrelation_id === null || korrelation_id === undefined) {
            throw new Error('Required parameter korrelation_id was null or undefined when calling getMitteilungenGesendet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Mitteilung>>('get',`${this.basePath}/api/query/kommunikation/mitteilungen/${encodeURIComponent(String(korrelation_id))}/gesendet`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMitteilungenGesendetGruppiert (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param korrelation_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMitteilungenGesendetGruppiert(korrelation_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DatumGruppeMitteilungen>>;
    public getMitteilungenGesendetGruppiert(korrelation_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DatumGruppeMitteilungen>>>;
    public getMitteilungenGesendetGruppiert(korrelation_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DatumGruppeMitteilungen>>>;
    public getMitteilungenGesendetGruppiert(korrelation_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (korrelation_id === null || korrelation_id === undefined) {
            throw new Error('Required parameter korrelation_id was null or undefined when calling getMitteilungenGesendetGruppiert.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DatumGruppeMitteilungen>>('get',`${this.basePath}/api/query/kommunikation/mitteilungen/${encodeURIComponent(String(korrelation_id))}/gesendet/gruppiert`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMitteilungenGruppiert (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param korrelation_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMitteilungenGruppiert(korrelation_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DatumGruppeMitteilungen>>;
    public getMitteilungenGruppiert(korrelation_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DatumGruppeMitteilungen>>>;
    public getMitteilungenGruppiert(korrelation_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DatumGruppeMitteilungen>>>;
    public getMitteilungenGruppiert(korrelation_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (korrelation_id === null || korrelation_id === undefined) {
            throw new Error('Required parameter korrelation_id was null or undefined when calling getMitteilungenGruppiert.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DatumGruppeMitteilungen>>('get',`${this.basePath}/api/query/kommunikation/mitteilungen/${encodeURIComponent(String(korrelation_id))}/gruppiert`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
