import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_SCHREIBE_SCANNERLOG = '[BenutzerverwaltungModul] PostSchreibeScannerlog laden';
export const POST_SCHREIBE_SCANNERLOG_AUSFUEHREN = '[BenutzerverwaltungModul] PostSchreibeScannerlog ausführen';
export const POST_SCHREIBE_SCANNERLOG_ERFOLGREICH = '[BenutzerverwaltungModul] PostSchreibeScannerlog laden erfolgreich';
export const POST_SCHREIBE_SCANNERLOG_FEHLER = '[BenutzerverwaltungModul] PostSchreibeScannerlog Ladefehler';
export const POST_SCHREIBE_SCANNERLOG_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostSchreibeScannerlog nicht ausgeführt (evtl. Offline)';



export class PostSchreibeScannerlogAction implements Action {
    readonly type = POST_SCHREIBE_SCANNERLOG;
        constructor(public scannerlogRequest: api.ScannerlogRequest, public optPayload: any = null) {}
    }
export class PostSchreibeScannerlogAusfuehrenAction implements Action {
    readonly type = POST_SCHREIBE_SCANNERLOG_AUSFUEHREN;
        constructor(public scannerlogRequest: api.ScannerlogRequest, public optPayload: any = null) {}
    }
export class PostSchreibeScannerlogErfolgreichAction implements Action {
    readonly type = POST_SCHREIBE_SCANNERLOG_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public scannerlogRequest: api.ScannerlogRequest, public optPayload: any = null) {}
    }
export class PostSchreibeScannerlogFehlerAction implements Action {
    readonly type = POST_SCHREIBE_SCANNERLOG_FEHLER;
        constructor(public payload: any, public scannerlogRequest: api.ScannerlogRequest, public optPayload: any = null) {}
    }
export class PostSchreibeScannerlogNichtAusgefuehrtAction implements Action {
    readonly type = POST_SCHREIBE_SCANNERLOG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandSchreibeScannerlogActions =
    PostSchreibeScannerlogAction
    | PostSchreibeScannerlogAusfuehrenAction
    | PostSchreibeScannerlogErfolgreichAction
    | PostSchreibeScannerlogFehlerAction
    | PostSchreibeScannerlogNichtAusgefuehrtAction
    ;
