import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const GET_BEREICH_DETAIL_ADMIN = '[DisplaysteuerungModul] GetBereichDetailAdmin laden';
export const GET_BEREICH_DETAIL_ADMIN_AUSFUEHREN = '[DisplaysteuerungModul] GetBereichDetailAdmin ausführen';
export const GET_BEREICH_DETAIL_ADMIN_ERFOLGREICH = '[DisplaysteuerungModul] GetBereichDetailAdmin laden erfolgreich';
export const GET_BEREICH_DETAIL_ADMIN_FEHLER = '[DisplaysteuerungModul] GetBereichDetailAdmin Ladefehler';
export const GET_BEREICH_DETAIL_ADMIN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] GetBereichDetailAdmin nicht ausgeführt (evtl. Offline)';



export class GetBereichDetailAdminAction implements Action {
    readonly type = GET_BEREICH_DETAIL_ADMIN;
        constructor( public bereich_id:string , public optPayload: any = null) {}
    }
export class GetBereichDetailAdminAusfuehrenAction implements Action {
    readonly type = GET_BEREICH_DETAIL_ADMIN_AUSFUEHREN;
        constructor( public bereich_id:string , public optPayload: any = null) {}
    }
export class GetBereichDetailAdminErfolgreichAction implements Action {
    readonly type = GET_BEREICH_DETAIL_ADMIN_ERFOLGREICH;
        constructor(public payload: api.BereichDetail,  public bereich_id:string , public optPayload: any = null) {}
    }
export class GetBereichDetailAdminFehlerAction implements Action {
    readonly type = GET_BEREICH_DETAIL_ADMIN_FEHLER;
        constructor(public payload: any,  public bereich_id:string , public optPayload: any = null) {}
    }
export class GetBereichDetailAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_BEREICH_DETAIL_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungQueryBereichDetailAdminActions =
    GetBereichDetailAdminAction
    | GetBereichDetailAdminAusfuehrenAction
    | GetBereichDetailAdminErfolgreichAction
    | GetBereichDetailAdminFehlerAction
    | GetBereichDetailAdminNichtAusgefuehrtAction
    ;
