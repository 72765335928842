import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_FEHLERFALL = '[BenutzerverwaltungModul] PostFunktionsbereichEMailBenachrichtigungFehlerfall laden';
export const POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_FEHLERFALL_AUSFUEHREN = '[BenutzerverwaltungModul] PostFunktionsbereichEMailBenachrichtigungFehlerfall ausführen';
export const POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_FEHLERFALL_ERFOLGREICH = '[BenutzerverwaltungModul] PostFunktionsbereichEMailBenachrichtigungFehlerfall laden erfolgreich';
export const POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_FEHLERFALL_FEHLER = '[BenutzerverwaltungModul] PostFunktionsbereichEMailBenachrichtigungFehlerfall Ladefehler';
export const POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_FEHLERFALL_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostFunktionsbereichEMailBenachrichtigungFehlerfall nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichEMailBenachrichtigungFehlerfallAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_FEHLERFALL;
        constructor(public funktionsbereichEmpfaengerRequest: api.FunktionsbereichEmpfaengerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichEMailBenachrichtigungFehlerfallAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_FEHLERFALL_AUSFUEHREN;
        constructor(public funktionsbereichEmpfaengerRequest: api.FunktionsbereichEmpfaengerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichEMailBenachrichtigungFehlerfallErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_FEHLERFALL_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funktionsbereichEmpfaengerRequest: api.FunktionsbereichEmpfaengerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichEMailBenachrichtigungFehlerfallFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_FEHLERFALL_FEHLER;
        constructor(public payload: any, public funktionsbereichEmpfaengerRequest: api.FunktionsbereichEmpfaengerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichEMailBenachrichtigungFehlerfallNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_FEHLERFALL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandFunktionsbereichEMailBenachrichtigungFehlerfallActions =
    PostFunktionsbereichEMailBenachrichtigungFehlerfallAction
    | PostFunktionsbereichEMailBenachrichtigungFehlerfallAusfuehrenAction
    | PostFunktionsbereichEMailBenachrichtigungFehlerfallErfolgreichAction
    | PostFunktionsbereichEMailBenachrichtigungFehlerfallFehlerAction
    | PostFunktionsbereichEMailBenachrichtigungFehlerfallNichtAusgefuehrtAction
    ;
