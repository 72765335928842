import * as api from '../../../clients/einlasskontrolle'; 

import {Action} from '@ngrx/store';

export const GET_EINLASSKONTROLLE_CONFIG_TYPES = '[EinlasskontrolleModul] GetEinlasskontrolleConfigTypes laden';
export const GET_EINLASSKONTROLLE_CONFIG_TYPES_AUSFUEHREN = '[EinlasskontrolleModul] GetEinlasskontrolleConfigTypes ausführen';
export const GET_EINLASSKONTROLLE_CONFIG_TYPES_ERFOLGREICH = '[EinlasskontrolleModul] GetEinlasskontrolleConfigTypes laden erfolgreich';
export const GET_EINLASSKONTROLLE_CONFIG_TYPES_FEHLER = '[EinlasskontrolleModul] GetEinlasskontrolleConfigTypes Ladefehler';
export const GET_EINLASSKONTROLLE_CONFIG_TYPES_NICHT_AUSGEFUEHRT = '[EinlasskontrolleModul] GetEinlasskontrolleConfigTypes nicht ausgeführt (evtl. Offline)';



export class GetEinlasskontrolleConfigTypesAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CONFIG_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetEinlasskontrolleConfigTypesAusfuehrenAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CONFIG_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetEinlasskontrolleConfigTypesErfolgreichAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CONFIG_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetEinlasskontrolleConfigTypesFehlerAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CONFIG_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetEinlasskontrolleConfigTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CONFIG_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type EinlasskontrolleQueryEinlasskontrolleConfigTypesActions =
    GetEinlasskontrolleConfigTypesAction
    | GetEinlasskontrolleConfigTypesAusfuehrenAction
    | GetEinlasskontrolleConfigTypesErfolgreichAction
    | GetEinlasskontrolleConfigTypesFehlerAction
    | GetEinlasskontrolleConfigTypesNichtAusgefuehrtAction
    ;
