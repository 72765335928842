import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_MANDANT_STAMM_OPTIONS = '[ApiModul] GetMandantStammOptions laden';
export const GET_MANDANT_STAMM_OPTIONS_AUSFUEHREN = '[ApiModul] GetMandantStammOptions ausführen';
export const GET_MANDANT_STAMM_OPTIONS_ERFOLGREICH = '[ApiModul] GetMandantStammOptions laden erfolgreich';
export const GET_MANDANT_STAMM_OPTIONS_FEHLER = '[ApiModul] GetMandantStammOptions Ladefehler';
export const GET_MANDANT_STAMM_OPTIONS_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantStammOptions nicht ausgeführt (evtl. Offline)';



export class GetMandantStammOptionsAction implements Action {
    readonly type = GET_MANDANT_STAMM_OPTIONS;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantStammOptionsAusfuehrenAction implements Action {
    readonly type = GET_MANDANT_STAMM_OPTIONS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantStammOptionsErfolgreichAction implements Action {
    readonly type = GET_MANDANT_STAMM_OPTIONS_ERFOLGREICH;
        constructor(public payload: api.MandantStammOptions, public optPayload: any = null) {}
    }
export class GetMandantStammOptionsFehlerAction implements Action {
    readonly type = GET_MANDANT_STAMM_OPTIONS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantStammOptionsNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANT_STAMM_OPTIONS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantStammOptionsActions =
    GetMandantStammOptionsAction
    | GetMandantStammOptionsAusfuehrenAction
    | GetMandantStammOptionsErfolgreichAction
    | GetMandantStammOptionsFehlerAction
    | GetMandantStammOptionsNichtAusgefuehrtAction
    ;
