import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import {Observable} from 'rxjs';

import {EnvironmentOptions} from '@orga-app/env';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {AuthService} from '@orga-app/auth/auth.service';

@Injectable()
export class Login401Interceptor implements HttpInterceptor {
    constructor(private router: Router, private envi: EnvironmentOptions, private svc: AuthService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(tap(() => {
            },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {

                    if (err.status !== 401) {
                        return;
                    }

                    if (this.svc.hasValidToken()) {
                        return;
                    }

                    console.warn('Login401Interceptor: navigate to login route');
                    this.router.navigate([this.envi.loginRoute]);
                }
            }));
    }


}
