/**
 * orga app | incidents
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { IncidentAktualisieren2Request } from '../model/incidentAktualisieren2Request';
import { IncidentAktualisierenRequest } from '../model/incidentAktualisierenRequest';
import { IncidentAnlegen2Request } from '../model/incidentAnlegen2Request';
import { IncidentBeschreibungRequest } from '../model/incidentBeschreibungRequest';
import { IncidentBildRequest } from '../model/incidentBildRequest';
import { IncidentDelegationRequest } from '../model/incidentDelegationRequest';
import { IncidentFunkprotokollRequest } from '../model/incidentFunkprotokollRequest';
import { IncidentMeldungRequest } from '../model/incidentMeldungRequest';
import { IncidentRequest } from '../model/incidentRequest';
import { IncidentsFunktionsbereichAbteilungRequest } from '../model/incidentsFunktionsbereichAbteilungRequest';
import { IncidentsRolleBerechtigungenRequest } from '../model/incidentsRolleBerechtigungenRequest';
import { IncidentsRolleOptionenRequest } from '../model/incidentsRolleOptionenRequest';
import { MessageResponse } from '../model/messageResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class IncidentsCommandService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * PostFunktionsbereichAbteilungSetzen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents * Mandant: IsAdmin oder Funktionsbereichs Admin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postFunktionsbereichAbteilungSetzen(body?: IncidentsFunktionsbereichAbteilungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postFunktionsbereichAbteilungSetzen(body?: IncidentsFunktionsbereichAbteilungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postFunktionsbereichAbteilungSetzen(body?: IncidentsFunktionsbereichAbteilungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postFunktionsbereichAbteilungSetzen(body?: IncidentsFunktionsbereichAbteilungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/funktionsbereich/abteilung`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentAbgeben (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentAbgeben(body?: IncidentRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentAbgeben(body?: IncidentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentAbgeben(body?: IncidentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentAbgeben(body?: IncidentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/abgeben`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentAbschliessen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentAbschliessen(body?: IncidentRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentAbschliessen(body?: IncidentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentAbschliessen(body?: IncidentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentAbschliessen(body?: IncidentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/abschliessen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentAktualisieren (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentAktualisieren(body?: IncidentAktualisierenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentAktualisieren(body?: IncidentAktualisierenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentAktualisieren(body?: IncidentAktualisierenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentAktualisieren(body?: IncidentAktualisierenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/aktualisieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentAktualisieren2 (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentAktualisieren2(body?: IncidentAktualisieren2Request, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentAktualisieren2(body?: IncidentAktualisieren2Request, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentAktualisieren2(body?: IncidentAktualisieren2Request, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentAktualisieren2(body?: IncidentAktualisieren2Request, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/aktualisieren2`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentAnlegen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentAnlegen(body?: IncidentBeschreibungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentAnlegen(body?: IncidentBeschreibungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentAnlegen(body?: IncidentBeschreibungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentAnlegen(body?: IncidentBeschreibungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/anlegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentAnlegen2 (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentAnlegen2(body?: IncidentAnlegen2Request, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentAnlegen2(body?: IncidentAnlegen2Request, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentAnlegen2(body?: IncidentAnlegen2Request, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentAnlegen2(body?: IncidentAnlegen2Request, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/anlegen2`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentAnlegenAusFunkprotokoll (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentAnlegenAusFunkprotokoll(body?: IncidentFunkprotokollRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentAnlegenAusFunkprotokoll(body?: IncidentFunkprotokollRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentAnlegenAusFunkprotokoll(body?: IncidentFunkprotokollRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentAnlegenAusFunkprotokoll(body?: IncidentFunkprotokollRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/anlegen/funkprotokoll`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentBildEntfernen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentBildEntfernen(body?: IncidentBildRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentBildEntfernen(body?: IncidentBildRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentBildEntfernen(body?: IncidentBildRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentBildEntfernen(body?: IncidentBildRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/bild/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentBildHinzufuegen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentBildHinzufuegen(body?: IncidentBildRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentBildHinzufuegen(body?: IncidentBildRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentBildHinzufuegen(body?: IncidentBildRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentBildHinzufuegen(body?: IncidentBildRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/bild/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentDelegieren (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentDelegieren(body?: IncidentDelegationRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentDelegieren(body?: IncidentDelegationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentDelegieren(body?: IncidentDelegationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentDelegieren(body?: IncidentDelegationRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/delegieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentMeldungAktualisieren (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentMeldungAktualisieren(body?: IncidentMeldungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentMeldungAktualisieren(body?: IncidentMeldungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentMeldungAktualisieren(body?: IncidentMeldungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentMeldungAktualisieren(body?: IncidentMeldungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/meldung/aktualisieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentMeldungEntfernen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentMeldungEntfernen(body?: IncidentMeldungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentMeldungEntfernen(body?: IncidentMeldungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentMeldungEntfernen(body?: IncidentMeldungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentMeldungEntfernen(body?: IncidentMeldungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/meldung/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentMeldungErfassen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentMeldungErfassen(body?: IncidentMeldungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentMeldungErfassen(body?: IncidentMeldungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentMeldungErfassen(body?: IncidentMeldungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentMeldungErfassen(body?: IncidentMeldungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/meldung/erfassen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentNichtOkErfassen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentNichtOkErfassen(body?: IncidentRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentNichtOkErfassen(body?: IncidentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentNichtOkErfassen(body?: IncidentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentNichtOkErfassen(body?: IncidentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/nok`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentOkErfassen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentOkErfassen(body?: IncidentRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentOkErfassen(body?: IncidentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentOkErfassen(body?: IncidentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentOkErfassen(body?: IncidentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/ok`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentPinnwandAktivieren (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentPinnwandAktivieren(body?: IncidentRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentPinnwandAktivieren(body?: IncidentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentPinnwandAktivieren(body?: IncidentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentPinnwandAktivieren(body?: IncidentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/pinnwand/aktivieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentPinnwandDeaktivieren (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentPinnwandDeaktivieren(body?: IncidentRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentPinnwandDeaktivieren(body?: IncidentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentPinnwandDeaktivieren(body?: IncidentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentPinnwandDeaktivieren(body?: IncidentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/pinnwand/deaktivieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentReaktivieren (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentReaktivieren(body?: IncidentRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentReaktivieren(body?: IncidentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentReaktivieren(body?: IncidentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentReaktivieren(body?: IncidentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/reaktivieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentTitelUndBeschreibungSetzen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentTitelUndBeschreibungSetzen(body?: IncidentBeschreibungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentTitelUndBeschreibungSetzen(body?: IncidentBeschreibungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentTitelUndBeschreibungSetzen(body?: IncidentBeschreibungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentTitelUndBeschreibungSetzen(body?: IncidentBeschreibungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/titel`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentUebernehmen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentUebernehmen(body?: IncidentRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentUebernehmen(body?: IncidentRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentUebernehmen(body?: IncidentRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentUebernehmen(body?: IncidentRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/incident/uebernehmen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostIncidentsRolleBerechtigungenSetzen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents * Mandant: Admin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postIncidentsRolleBerechtigungenSetzen(body?: IncidentsRolleBerechtigungenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postIncidentsRolleBerechtigungenSetzen(body?: IncidentsRolleBerechtigungenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postIncidentsRolleBerechtigungenSetzen(body?: IncidentsRolleBerechtigungenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postIncidentsRolleBerechtigungenSetzen(body?: IncidentsRolleBerechtigungenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/rolle/berechtigungen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostRolleAbteilungSetzen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents * Mandant: IsAdmin oder Funktionsbereichs Admin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postRolleAbteilungSetzen(body?: IncidentsRolleOptionenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postRolleAbteilungSetzen(body?: IncidentsRolleOptionenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postRolleAbteilungSetzen(body?: IncidentsRolleOptionenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postRolleAbteilungSetzen(body?: IncidentsRolleOptionenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/rolle/abteilung`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostRolleZentraleSetzen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: incidents * Mandant: Admin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postRolleZentraleSetzen(body?: IncidentsRolleOptionenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postRolleZentraleSetzen(body?: IncidentsRolleOptionenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postRolleZentraleSetzen(body?: IncidentsRolleOptionenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postRolleZentraleSetzen(body?: IncidentsRolleOptionenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/incidents/command/rolle/zentrale`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
