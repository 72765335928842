import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_DOKUMENT_IDENTIFIER = '[VorlagenModul] GetDokumentIdentifier laden';
export const GET_DOKUMENT_IDENTIFIER_AUSFUEHREN = '[VorlagenModul] GetDokumentIdentifier ausführen';
export const GET_DOKUMENT_IDENTIFIER_ERFOLGREICH = '[VorlagenModul] GetDokumentIdentifier laden erfolgreich';
export const GET_DOKUMENT_IDENTIFIER_FEHLER = '[VorlagenModul] GetDokumentIdentifier Ladefehler';
export const GET_DOKUMENT_IDENTIFIER_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetDokumentIdentifier nicht ausgeführt (evtl. Offline)';



export class GetDokumentIdentifierAction implements Action {
    readonly type = GET_DOKUMENT_IDENTIFIER;
        constructor(public optPayload: any = null) {}
    }
export class GetDokumentIdentifierAusfuehrenAction implements Action {
    readonly type = GET_DOKUMENT_IDENTIFIER_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetDokumentIdentifierErfolgreichAction implements Action {
    readonly type = GET_DOKUMENT_IDENTIFIER_ERFOLGREICH;
        constructor(public payload: api.DokumentIdentifier, public optPayload: any = null) {}
    }
export class GetDokumentIdentifierFehlerAction implements Action {
    readonly type = GET_DOKUMENT_IDENTIFIER_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetDokumentIdentifierNichtAusgefuehrtAction implements Action {
    readonly type = GET_DOKUMENT_IDENTIFIER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryDokumentIdentifierActions =
    GetDokumentIdentifierAction
    | GetDokumentIdentifierAusfuehrenAction
    | GetDokumentIdentifierErfolgreichAction
    | GetDokumentIdentifierFehlerAction
    | GetDokumentIdentifierNichtAusgefuehrtAction
    ;
