import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { WebsocketEffects } from './effects';
import { StoreModule } from '@ngrx/store';
import { StoreWebsocketFeature, websocketReducer } from './reducer';
import { WebsocketService } from './websocket.service';
import { WS_PATH } from './variables';

@NgModule({
    imports: [
        StoreModule.forFeature(StoreWebsocketFeature, websocketReducer),
        EffectsModule.forFeature([WebsocketEffects]),
    ],
    providers: [
        WebsocketService,      
    ]
})
export class WebsocketModule {
    public static forRoot(url: string): ModuleWithProviders {
        return {
            ngModule: WebsocketModule,
            providers: [             
                {
                    provide: WS_PATH,
                    useValue: url
                }
            ]
        };
    }
}
