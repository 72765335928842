import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_MANDANT_LOGO_BASE64 = '[ApiModul] GetMandantLogoBase64 laden';
export const GET_MANDANT_LOGO_BASE64_AUSFUEHREN = '[ApiModul] GetMandantLogoBase64 ausführen';
export const GET_MANDANT_LOGO_BASE64_ERFOLGREICH = '[ApiModul] GetMandantLogoBase64 laden erfolgreich';
export const GET_MANDANT_LOGO_BASE64_FEHLER = '[ApiModul] GetMandantLogoBase64 Ladefehler';
export const GET_MANDANT_LOGO_BASE64_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantLogoBase64 nicht ausgeführt (evtl. Offline)';



export class GetMandantLogoBase64Action implements Action {
    readonly type = GET_MANDANT_LOGO_BASE64;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantLogoBase64AusfuehrenAction implements Action {
    readonly type = GET_MANDANT_LOGO_BASE64_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantLogoBase64ErfolgreichAction implements Action {
    readonly type = GET_MANDANT_LOGO_BASE64_ERFOLGREICH;
        constructor(public payload: api.Bild, public optPayload: any = null) {}
    }
export class GetMandantLogoBase64FehlerAction implements Action {
    readonly type = GET_MANDANT_LOGO_BASE64_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantLogoBase64NichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANT_LOGO_BASE64_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantLogoBase64Actions =
    GetMandantLogoBase64Action
    | GetMandantLogoBase64AusfuehrenAction
    | GetMandantLogoBase64ErfolgreichAction
    | GetMandantLogoBase64FehlerAction
    | GetMandantLogoBase64NichtAusgefuehrtAction
    ;
