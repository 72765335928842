import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_MITTEILUNGEN_GESENDET_GRUPPIERT = '[VeranstaltungenModul] GetMitteilungenGesendetGruppiert laden';
export const GET_MITTEILUNGEN_GESENDET_GRUPPIERT_AUSFUEHREN = '[VeranstaltungenModul] GetMitteilungenGesendetGruppiert ausführen';
export const GET_MITTEILUNGEN_GESENDET_GRUPPIERT_ERFOLGREICH = '[VeranstaltungenModul] GetMitteilungenGesendetGruppiert laden erfolgreich';
export const GET_MITTEILUNGEN_GESENDET_GRUPPIERT_FEHLER = '[VeranstaltungenModul] GetMitteilungenGesendetGruppiert Ladefehler';
export const GET_MITTEILUNGEN_GESENDET_GRUPPIERT_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetMitteilungenGesendetGruppiert nicht ausgeführt (evtl. Offline)';



export class GetMitteilungenGesendetGruppiertAction implements Action {
    readonly type = GET_MITTEILUNGEN_GESENDET_GRUPPIERT;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGesendetGruppiertAusfuehrenAction implements Action {
    readonly type = GET_MITTEILUNGEN_GESENDET_GRUPPIERT_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGesendetGruppiertErfolgreichAction implements Action {
    readonly type = GET_MITTEILUNGEN_GESENDET_GRUPPIERT_ERFOLGREICH;
        constructor(public payload: Array<api.DatumGruppeMitteilungen>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGesendetGruppiertFehlerAction implements Action {
    readonly type = GET_MITTEILUNGEN_GESENDET_GRUPPIERT_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGesendetGruppiertNichtAusgefuehrtAction implements Action {
    readonly type = GET_MITTEILUNGEN_GESENDET_GRUPPIERT_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryMitteilungenGesendetGruppiertActions =
    GetMitteilungenGesendetGruppiertAction
    | GetMitteilungenGesendetGruppiertAusfuehrenAction
    | GetMitteilungenGesendetGruppiertErfolgreichAction
    | GetMitteilungenGesendetGruppiertFehlerAction
    | GetMitteilungenGesendetGruppiertNichtAusgefuehrtAction
    ;
