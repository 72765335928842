import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {StoreModule} from '@ngrx/store';
import {reducers} from './aufgabebemerkung.reducer';
import {EffectsModule} from '@ngrx/effects';
import {
    VeranstaltungenAufgabeBemerkungErfassenEffects,
    VeranstaltungenAufgabeBildEntfernenEffects, VeranstaltungenAufgabeBildHinzufuegenEffects
} from '../../../client-ngrx/veranstaltungen';
import {AufgabebemerkungPage} from './aufgabebemerkung.page';
import {AufgabebemerkungPageUiEffects} from './aufgabebemerkung.effects.ui';
import {SopiComponentsModule} from '../../shared/sopi-components/sopi-components.module';

const routes: Routes = [
    {
        path: '',
        component: AufgabebemerkungPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature('AufgabebemerkungPage', reducers),
        EffectsModule.forFeature([
            AufgabebemerkungPageUiEffects,
            VeranstaltungenAufgabeBemerkungErfassenEffects,
            VeranstaltungenAufgabeBildHinzufuegenEffects,
            VeranstaltungenAufgabeBildEntfernenEffects,
        ]),
        SopiComponentsModule.forRoot(),
    ],
    declarations: [AufgabebemerkungPage]
})
export class AufgabebemerkungPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: AufgabebemerkungPageModule,
        };
    }
}
