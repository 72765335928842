import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ROLLE_ABTEILUNG = '[IncidentsModul] GetRolleAbteilung laden';
export const GET_ROLLE_ABTEILUNG_AUSFUEHREN = '[IncidentsModul] GetRolleAbteilung ausführen';
export const GET_ROLLE_ABTEILUNG_ERFOLGREICH = '[IncidentsModul] GetRolleAbteilung laden erfolgreich';
export const GET_ROLLE_ABTEILUNG_FEHLER = '[IncidentsModul] GetRolleAbteilung Ladefehler';
export const GET_ROLLE_ABTEILUNG_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetRolleAbteilung nicht ausgeführt (evtl. Offline)';



export class GetRolleAbteilungAction implements Action {
    readonly type = GET_ROLLE_ABTEILUNG;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleAbteilungAusfuehrenAction implements Action {
    readonly type = GET_ROLLE_ABTEILUNG_AUSFUEHREN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleAbteilungErfolgreichAction implements Action {
    readonly type = GET_ROLLE_ABTEILUNG_ERFOLGREICH;
        constructor(public payload: api.RolleAbteilung,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleAbteilungFehlerAction implements Action {
    readonly type = GET_ROLLE_ABTEILUNG_FEHLER;
        constructor(public payload: any,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleAbteilungNichtAusgefuehrtAction implements Action {
    readonly type = GET_ROLLE_ABTEILUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryRolleAbteilungActions =
    GetRolleAbteilungAction
    | GetRolleAbteilungAusfuehrenAction
    | GetRolleAbteilungErfolgreichAction
    | GetRolleAbteilungFehlerAction
    | GetRolleAbteilungNichtAusgefuehrtAction
    ;
