import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ENTFERNEN = '[KommunikationModul] PostMitteilungsVorlageVeranstaltungsTypenEntfernen laden';
export const POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ENTFERNEN_AUSFUEHREN = '[KommunikationModul] PostMitteilungsVorlageVeranstaltungsTypenEntfernen ausführen';
export const POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ENTFERNEN_ERFOLGREICH = '[KommunikationModul] PostMitteilungsVorlageVeranstaltungsTypenEntfernen laden erfolgreich';
export const POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ENTFERNEN_FEHLER = '[KommunikationModul] PostMitteilungsVorlageVeranstaltungsTypenEntfernen Ladefehler';
export const POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ENTFERNEN_NICHT_AUSGEFUEHRT = '[KommunikationModul] PostMitteilungsVorlageVeranstaltungsTypenEntfernen nicht ausgeführt (evtl. Offline)';



export class PostMitteilungsVorlageVeranstaltungsTypenEntfernenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ENTFERNEN;
        constructor(public mitteilungsVorlageTypMultiRequest: api.MitteilungsVorlageTypMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageVeranstaltungsTypenEntfernenAusfuehrenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ENTFERNEN_AUSFUEHREN;
        constructor(public mitteilungsVorlageTypMultiRequest: api.MitteilungsVorlageTypMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageVeranstaltungsTypenEntfernenErfolgreichAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public mitteilungsVorlageTypMultiRequest: api.MitteilungsVorlageTypMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageVeranstaltungsTypenEntfernenFehlerAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ENTFERNEN_FEHLER;
        constructor(public payload: any, public mitteilungsVorlageTypMultiRequest: api.MitteilungsVorlageTypMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageVeranstaltungsTypenEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationCommandMitteilungsVorlageVeranstaltungsTypenEntfernenActions =
    PostMitteilungsVorlageVeranstaltungsTypenEntfernenAction
    | PostMitteilungsVorlageVeranstaltungsTypenEntfernenAusfuehrenAction
    | PostMitteilungsVorlageVeranstaltungsTypenEntfernenErfolgreichAction
    | PostMitteilungsVorlageVeranstaltungsTypenEntfernenFehlerAction
    | PostMitteilungsVorlageVeranstaltungsTypenEntfernenNichtAusgefuehrtAction
    ;
