import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_EXCEL = '[QschecksModul] GetVeranstaltungExcel laden';
export const GET_VERANSTALTUNG_EXCEL_AUSFUEHREN = '[QschecksModul] GetVeranstaltungExcel ausführen';
export const GET_VERANSTALTUNG_EXCEL_ERFOLGREICH = '[QschecksModul] GetVeranstaltungExcel laden erfolgreich';
export const GET_VERANSTALTUNG_EXCEL_FEHLER = '[QschecksModul] GetVeranstaltungExcel Ladefehler';
export const GET_VERANSTALTUNG_EXCEL_NICHT_AUSGEFUEHRT = '[QschecksModul] GetVeranstaltungExcel nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungExcelAction implements Action {
    readonly type = GET_VERANSTALTUNG_EXCEL;
        constructor(public veranstaltungUebersichtRequest: api.VeranstaltungUebersichtRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungExcelAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_EXCEL_AUSFUEHREN;
        constructor(public veranstaltungUebersichtRequest: api.VeranstaltungUebersichtRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungExcelErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_EXCEL_ERFOLGREICH;
        constructor(public veranstaltungUebersichtRequest: api.VeranstaltungUebersichtRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungExcelFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_EXCEL_FEHLER;
        constructor(public payload: any, public veranstaltungUebersichtRequest: api.VeranstaltungUebersichtRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungExcelNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_EXCEL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandVeranstaltungExcelActions =
    GetVeranstaltungExcelAction
    | GetVeranstaltungExcelAusfuehrenAction
    | GetVeranstaltungExcelErfolgreichAction
    | GetVeranstaltungExcelFehlerAction
    | GetVeranstaltungExcelNichtAusgefuehrtAction
    ;
