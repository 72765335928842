import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_BENUTZER_ZUM_DIENST_EINTEILEN = '[VeranstaltungenModul] PostBenutzerZumDienstEinteilen laden';
export const POST_BENUTZER_ZUM_DIENST_EINTEILEN_AUSFUEHREN = '[VeranstaltungenModul] PostBenutzerZumDienstEinteilen ausführen';
export const POST_BENUTZER_ZUM_DIENST_EINTEILEN_ERFOLGREICH = '[VeranstaltungenModul] PostBenutzerZumDienstEinteilen laden erfolgreich';
export const POST_BENUTZER_ZUM_DIENST_EINTEILEN_FEHLER = '[VeranstaltungenModul] PostBenutzerZumDienstEinteilen Ladefehler';
export const POST_BENUTZER_ZUM_DIENST_EINTEILEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostBenutzerZumDienstEinteilen nicht ausgeführt (evtl. Offline)';



export class PostBenutzerZumDienstEinteilenAction implements Action {
    readonly type = POST_BENUTZER_ZUM_DIENST_EINTEILEN;
        constructor(public benutzerZumDienstEinteilenRequest: api.BenutzerZumDienstEinteilenRequest, public optPayload: any = null) {}
    }
export class PostBenutzerZumDienstEinteilenAusfuehrenAction implements Action {
    readonly type = POST_BENUTZER_ZUM_DIENST_EINTEILEN_AUSFUEHREN;
        constructor(public benutzerZumDienstEinteilenRequest: api.BenutzerZumDienstEinteilenRequest, public optPayload: any = null) {}
    }
export class PostBenutzerZumDienstEinteilenErfolgreichAction implements Action {
    readonly type = POST_BENUTZER_ZUM_DIENST_EINTEILEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public benutzerZumDienstEinteilenRequest: api.BenutzerZumDienstEinteilenRequest, public optPayload: any = null) {}
    }
export class PostBenutzerZumDienstEinteilenFehlerAction implements Action {
    readonly type = POST_BENUTZER_ZUM_DIENST_EINTEILEN_FEHLER;
        constructor(public payload: any, public benutzerZumDienstEinteilenRequest: api.BenutzerZumDienstEinteilenRequest, public optPayload: any = null) {}
    }
export class PostBenutzerZumDienstEinteilenNichtAusgefuehrtAction implements Action {
    readonly type = POST_BENUTZER_ZUM_DIENST_EINTEILEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandBenutzerZumDienstEinteilenActions =
    PostBenutzerZumDienstEinteilenAction
    | PostBenutzerZumDienstEinteilenAusfuehrenAction
    | PostBenutzerZumDienstEinteilenErfolgreichAction
    | PostBenutzerZumDienstEinteilenFehlerAction
    | PostBenutzerZumDienstEinteilenNichtAusgefuehrtAction
    ;
