import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const POST_TROUBLE_TICKET_SENDEN = '[KommunikationModul] PostTroubleTicketSenden laden';
export const POST_TROUBLE_TICKET_SENDEN_AUSFUEHREN = '[KommunikationModul] PostTroubleTicketSenden ausführen';
export const POST_TROUBLE_TICKET_SENDEN_ERFOLGREICH = '[KommunikationModul] PostTroubleTicketSenden laden erfolgreich';
export const POST_TROUBLE_TICKET_SENDEN_FEHLER = '[KommunikationModul] PostTroubleTicketSenden Ladefehler';
export const POST_TROUBLE_TICKET_SENDEN_NICHT_AUSGEFUEHRT = '[KommunikationModul] PostTroubleTicketSenden nicht ausgeführt (evtl. Offline)';



export class PostTroubleTicketSendenAction implements Action {
    readonly type = POST_TROUBLE_TICKET_SENDEN;
        constructor(public troubleTicketSendenRequest: api.TroubleTicketSendenRequest, public optPayload: any = null) {}
    }
export class PostTroubleTicketSendenAusfuehrenAction implements Action {
    readonly type = POST_TROUBLE_TICKET_SENDEN_AUSFUEHREN;
        constructor(public troubleTicketSendenRequest: api.TroubleTicketSendenRequest, public optPayload: any = null) {}
    }
export class PostTroubleTicketSendenErfolgreichAction implements Action {
    readonly type = POST_TROUBLE_TICKET_SENDEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public troubleTicketSendenRequest: api.TroubleTicketSendenRequest, public optPayload: any = null) {}
    }
export class PostTroubleTicketSendenFehlerAction implements Action {
    readonly type = POST_TROUBLE_TICKET_SENDEN_FEHLER;
        constructor(public payload: any, public troubleTicketSendenRequest: api.TroubleTicketSendenRequest, public optPayload: any = null) {}
    }
export class PostTroubleTicketSendenNichtAusgefuehrtAction implements Action {
    readonly type = POST_TROUBLE_TICKET_SENDEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationCommandTroubleTicketSendenActions =
    PostTroubleTicketSendenAction
    | PostTroubleTicketSendenAusfuehrenAction
    | PostTroubleTicketSendenErfolgreichAction
    | PostTroubleTicketSendenFehlerAction
    | PostTroubleTicketSendenNichtAusgefuehrtAction
    ;
