import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const POST_BEREICH_ROLLE_ENTFERNEN = '[DisplaysteuerungModul] PostBereichRolleEntfernen laden';
export const POST_BEREICH_ROLLE_ENTFERNEN_AUSFUEHREN = '[DisplaysteuerungModul] PostBereichRolleEntfernen ausführen';
export const POST_BEREICH_ROLLE_ENTFERNEN_ERFOLGREICH = '[DisplaysteuerungModul] PostBereichRolleEntfernen laden erfolgreich';
export const POST_BEREICH_ROLLE_ENTFERNEN_FEHLER = '[DisplaysteuerungModul] PostBereichRolleEntfernen Ladefehler';
export const POST_BEREICH_ROLLE_ENTFERNEN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] PostBereichRolleEntfernen nicht ausgeführt (evtl. Offline)';



export class PostBereichRolleEntfernenAction implements Action {
    readonly type = POST_BEREICH_ROLLE_ENTFERNEN;
        constructor(public bereichRolleRequest: api.BereichRolleRequest, public optPayload: any = null) {}
    }
export class PostBereichRolleEntfernenAusfuehrenAction implements Action {
    readonly type = POST_BEREICH_ROLLE_ENTFERNEN_AUSFUEHREN;
        constructor(public bereichRolleRequest: api.BereichRolleRequest, public optPayload: any = null) {}
    }
export class PostBereichRolleEntfernenErfolgreichAction implements Action {
    readonly type = POST_BEREICH_ROLLE_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public bereichRolleRequest: api.BereichRolleRequest, public optPayload: any = null) {}
    }
export class PostBereichRolleEntfernenFehlerAction implements Action {
    readonly type = POST_BEREICH_ROLLE_ENTFERNEN_FEHLER;
        constructor(public payload: any, public bereichRolleRequest: api.BereichRolleRequest, public optPayload: any = null) {}
    }
export class PostBereichRolleEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_BEREICH_ROLLE_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungCommandBereichRolleEntfernenActions =
    PostBereichRolleEntfernenAction
    | PostBereichRolleEntfernenAusfuehrenAction
    | PostBereichRolleEntfernenErfolgreichAction
    | PostBereichRolleEntfernenFehlerAction
    | PostBereichRolleEntfernenNichtAusgefuehrtAction
    ;
