import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_CHECKLISTE_LOESCHEN = '[VorlagenModul] PostChecklisteLoeschen laden';
export const POST_CHECKLISTE_LOESCHEN_AUSFUEHREN = '[VorlagenModul] PostChecklisteLoeschen ausführen';
export const POST_CHECKLISTE_LOESCHEN_ERFOLGREICH = '[VorlagenModul] PostChecklisteLoeschen laden erfolgreich';
export const POST_CHECKLISTE_LOESCHEN_FEHLER = '[VorlagenModul] PostChecklisteLoeschen Ladefehler';
export const POST_CHECKLISTE_LOESCHEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostChecklisteLoeschen nicht ausgeführt (evtl. Offline)';



export class PostChecklisteLoeschenAction implements Action {
    readonly type = POST_CHECKLISTE_LOESCHEN;
        constructor(public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteLoeschenAusfuehrenAction implements Action {
    readonly type = POST_CHECKLISTE_LOESCHEN_AUSFUEHREN;
        constructor(public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteLoeschenErfolgreichAction implements Action {
    readonly type = POST_CHECKLISTE_LOESCHEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteLoeschenFehlerAction implements Action {
    readonly type = POST_CHECKLISTE_LOESCHEN_FEHLER;
        constructor(public payload: any, public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteLoeschenNichtAusgefuehrtAction implements Action {
    readonly type = POST_CHECKLISTE_LOESCHEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandChecklisteLoeschenActions =
    PostChecklisteLoeschenAction
    | PostChecklisteLoeschenAusfuehrenAction
    | PostChecklisteLoeschenErfolgreichAction
    | PostChecklisteLoeschenFehlerAction
    | PostChecklisteLoeschenNichtAusgefuehrtAction
    ;
