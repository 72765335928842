import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_PINNWAND_DEAKTIVIEREN = '[IncidentsModul] PostIncidentPinnwandDeaktivieren laden';
export const POST_INCIDENT_PINNWAND_DEAKTIVIEREN_AUSFUEHREN = '[IncidentsModul] PostIncidentPinnwandDeaktivieren ausführen';
export const POST_INCIDENT_PINNWAND_DEAKTIVIEREN_ERFOLGREICH = '[IncidentsModul] PostIncidentPinnwandDeaktivieren laden erfolgreich';
export const POST_INCIDENT_PINNWAND_DEAKTIVIEREN_FEHLER = '[IncidentsModul] PostIncidentPinnwandDeaktivieren Ladefehler';
export const POST_INCIDENT_PINNWAND_DEAKTIVIEREN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentPinnwandDeaktivieren nicht ausgeführt (evtl. Offline)';



export class PostIncidentPinnwandDeaktivierenAction implements Action {
    readonly type = POST_INCIDENT_PINNWAND_DEAKTIVIEREN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentPinnwandDeaktivierenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_PINNWAND_DEAKTIVIEREN_AUSFUEHREN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentPinnwandDeaktivierenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_PINNWAND_DEAKTIVIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentPinnwandDeaktivierenFehlerAction implements Action {
    readonly type = POST_INCIDENT_PINNWAND_DEAKTIVIEREN_FEHLER;
        constructor(public payload: any, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentPinnwandDeaktivierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_PINNWAND_DEAKTIVIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentPinnwandDeaktivierenActions =
    PostIncidentPinnwandDeaktivierenAction
    | PostIncidentPinnwandDeaktivierenAusfuehrenAction
    | PostIncidentPinnwandDeaktivierenErfolgreichAction
    | PostIncidentPinnwandDeaktivierenFehlerAction
    | PostIncidentPinnwandDeaktivierenNichtAusgefuehrtAction
    ;
