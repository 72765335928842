

import {Action} from '@ngrx/store';

export const GET_AUFGABE_BILD = '[VorlagenModul] GetAufgabeBild laden';
export const GET_AUFGABE_BILD_AUSFUEHREN = '[VorlagenModul] GetAufgabeBild ausführen';
export const GET_AUFGABE_BILD_ERFOLGREICH = '[VorlagenModul] GetAufgabeBild laden erfolgreich';
export const GET_AUFGABE_BILD_FEHLER = '[VorlagenModul] GetAufgabeBild Ladefehler';
export const GET_AUFGABE_BILD_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetAufgabeBild nicht ausgeführt (evtl. Offline)';



export class GetAufgabeBildAction implements Action {
    readonly type = GET_AUFGABE_BILD;
        constructor( public aufgabe_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeBildAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_BILD_AUSFUEHREN;
        constructor( public aufgabe_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeBildErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_BILD_ERFOLGREICH;
        constructor( public aufgabe_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeBildFehlerAction implements Action {
    readonly type = GET_AUFGABE_BILD_FEHLER;
        constructor(public payload: any,  public aufgabe_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeBildNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_BILD_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryAufgabeBildActions =
    GetAufgabeBildAction
    | GetAufgabeBildAusfuehrenAction
    | GetAufgabeBildErfolgreichAction
    | GetAufgabeBildFehlerAction
    | GetAufgabeBildNichtAusgefuehrtAction
    ;
