import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFPLAN_PRUEFAUFGABEN_ADMIN = '[QschecksModul] GetPruefplanPruefaufgabenAdmin laden';
export const GET_PRUEFPLAN_PRUEFAUFGABEN_ADMIN_AUSFUEHREN = '[QschecksModul] GetPruefplanPruefaufgabenAdmin ausführen';
export const GET_PRUEFPLAN_PRUEFAUFGABEN_ADMIN_ERFOLGREICH = '[QschecksModul] GetPruefplanPruefaufgabenAdmin laden erfolgreich';
export const GET_PRUEFPLAN_PRUEFAUFGABEN_ADMIN_FEHLER = '[QschecksModul] GetPruefplanPruefaufgabenAdmin Ladefehler';
export const GET_PRUEFPLAN_PRUEFAUFGABEN_ADMIN_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPruefplanPruefaufgabenAdmin nicht ausgeführt (evtl. Offline)';



export class GetPruefplanPruefaufgabenAdminAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFAUFGABEN_ADMIN;
        constructor( public pruefplan_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefaufgabenAdminAusfuehrenAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFAUFGABEN_ADMIN_AUSFUEHREN;
        constructor( public pruefplan_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefaufgabenAdminErfolgreichAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFAUFGABEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.Pruefaufgabe>,  public pruefplan_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefaufgabenAdminFehlerAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFAUFGABEN_ADMIN_FEHLER;
        constructor(public payload: any,  public pruefplan_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefaufgabenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFAUFGABEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPruefplanPruefaufgabenAdminActions =
    GetPruefplanPruefaufgabenAdminAction
    | GetPruefplanPruefaufgabenAdminAusfuehrenAction
    | GetPruefplanPruefaufgabenAdminErfolgreichAction
    | GetPruefplanPruefaufgabenAdminFehlerAction
    | GetPruefplanPruefaufgabenAdminNichtAusgefuehrtAction
    ;
