import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING = '[QschecksModul] GetFunktionsbereichePruefplaeneControlling laden';
export const GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING_AUSFUEHREN = '[QschecksModul] GetFunktionsbereichePruefplaeneControlling ausführen';
export const GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING_ERFOLGREICH = '[QschecksModul] GetFunktionsbereichePruefplaeneControlling laden erfolgreich';
export const GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING_FEHLER = '[QschecksModul] GetFunktionsbereichePruefplaeneControlling Ladefehler';
export const GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING_NICHT_AUSGEFUEHRT = '[QschecksModul] GetFunktionsbereichePruefplaeneControlling nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichePruefplaeneControllingAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereichePruefplaeneControllingAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereichePruefplaeneControllingErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichPruefplaene>, public optPayload: any = null) {}
    }
export class GetFunktionsbereichePruefplaeneControllingFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFunktionsbereichePruefplaeneControllingNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryFunktionsbereichePruefplaeneControllingActions =
    GetFunktionsbereichePruefplaeneControllingAction
    | GetFunktionsbereichePruefplaeneControllingAusfuehrenAction
    | GetFunktionsbereichePruefplaeneControllingErfolgreichAction
    | GetFunktionsbereichePruefplaeneControllingFehlerAction
    | GetFunktionsbereichePruefplaeneControllingNichtAusgefuehrtAction
    ;
