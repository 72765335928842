import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ZENTRALE_LISTE = '[IncidentsModul] GetZentraleListe laden';
export const GET_ZENTRALE_LISTE_AUSFUEHREN = '[IncidentsModul] GetZentraleListe ausführen';
export const GET_ZENTRALE_LISTE_ERFOLGREICH = '[IncidentsModul] GetZentraleListe laden erfolgreich';
export const GET_ZENTRALE_LISTE_FEHLER = '[IncidentsModul] GetZentraleListe Ladefehler';
export const GET_ZENTRALE_LISTE_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetZentraleListe nicht ausgeführt (evtl. Offline)';



export class GetZentraleListeAction implements Action {
    readonly type = GET_ZENTRALE_LISTE;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetZentraleListeAusfuehrenAction implements Action {
    readonly type = GET_ZENTRALE_LISTE_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetZentraleListeErfolgreichAction implements Action {
    readonly type = GET_ZENTRALE_LISTE_ERFOLGREICH;
        constructor(public payload: api.IncidentsListResponse,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetZentraleListeFehlerAction implements Action {
    readonly type = GET_ZENTRALE_LISTE_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetZentraleListeNichtAusgefuehrtAction implements Action {
    readonly type = GET_ZENTRALE_LISTE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryZentraleListeActions =
    GetZentraleListeAction
    | GetZentraleListeAusfuehrenAction
    | GetZentraleListeErfolgreichAction
    | GetZentraleListeFehlerAction
    | GetZentraleListeNichtAusgefuehrtAction
    ;
