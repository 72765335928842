import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_BENUTZER_IDENTIFIER = '[BenutzerverwaltungModul] GetBenutzerIdentifier laden';
export const GET_BENUTZER_IDENTIFIER_AUSFUEHREN = '[BenutzerverwaltungModul] GetBenutzerIdentifier ausführen';
export const GET_BENUTZER_IDENTIFIER_ERFOLGREICH = '[BenutzerverwaltungModul] GetBenutzerIdentifier laden erfolgreich';
export const GET_BENUTZER_IDENTIFIER_FEHLER = '[BenutzerverwaltungModul] GetBenutzerIdentifier Ladefehler';
export const GET_BENUTZER_IDENTIFIER_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetBenutzerIdentifier nicht ausgeführt (evtl. Offline)';



export class GetBenutzerIdentifierAction implements Action {
    readonly type = GET_BENUTZER_IDENTIFIER;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerIdentifierAusfuehrenAction implements Action {
    readonly type = GET_BENUTZER_IDENTIFIER_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerIdentifierErfolgreichAction implements Action {
    readonly type = GET_BENUTZER_IDENTIFIER_ERFOLGREICH;
        constructor(public payload: api.BenutzerIdentifier, public optPayload: any = null) {}
    }
export class GetBenutzerIdentifierFehlerAction implements Action {
    readonly type = GET_BENUTZER_IDENTIFIER_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetBenutzerIdentifierNichtAusgefuehrtAction implements Action {
    readonly type = GET_BENUTZER_IDENTIFIER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryBenutzerIdentifierActions =
    GetBenutzerIdentifierAction
    | GetBenutzerIdentifierAusfuehrenAction
    | GetBenutzerIdentifierErfolgreichAction
    | GetBenutzerIdentifierFehlerAction
    | GetBenutzerIdentifierNichtAusgefuehrtAction
    ;
