import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_BENUTZER_ZUGRIFF = '[VeranstaltungenModul] GetVeranstaltungBenutzerZugriff laden';
export const GET_VERANSTALTUNG_BENUTZER_ZUGRIFF_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungBenutzerZugriff ausführen';
export const GET_VERANSTALTUNG_BENUTZER_ZUGRIFF_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungBenutzerZugriff laden erfolgreich';
export const GET_VERANSTALTUNG_BENUTZER_ZUGRIFF_FEHLER = '[VeranstaltungenModul] GetVeranstaltungBenutzerZugriff Ladefehler';
export const GET_VERANSTALTUNG_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungBenutzerZugriff nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungBenutzerZugriffAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZER_ZUGRIFF;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungBenutzerZugriffAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZER_ZUGRIFF_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungBenutzerZugriffErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZER_ZUGRIFF_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungenBenutzerZugriff, public optPayload: any = null) {}
    }
export class GetVeranstaltungBenutzerZugriffFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZER_ZUGRIFF_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungBenutzerZugriffNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungBenutzerZugriffActions =
    GetVeranstaltungBenutzerZugriffAction
    | GetVeranstaltungBenutzerZugriffAusfuehrenAction
    | GetVeranstaltungBenutzerZugriffErfolgreichAction
    | GetVeranstaltungBenutzerZugriffFehlerAction
    | GetVeranstaltungBenutzerZugriffNichtAusgefuehrtAction
    ;
