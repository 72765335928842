import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_AKTUALISIEREN = '[VeranstaltungenModul] PostDokumentAktualisieren laden';
export const POST_DOKUMENT_AKTUALISIEREN_AUSFUEHREN = '[VeranstaltungenModul] PostDokumentAktualisieren ausführen';
export const POST_DOKUMENT_AKTUALISIEREN_ERFOLGREICH = '[VeranstaltungenModul] PostDokumentAktualisieren laden erfolgreich';
export const POST_DOKUMENT_AKTUALISIEREN_FEHLER = '[VeranstaltungenModul] PostDokumentAktualisieren Ladefehler';
export const POST_DOKUMENT_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostDokumentAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostDokumentAktualisierenAction implements Action {
    readonly type = POST_DOKUMENT_AKTUALISIEREN;
        constructor(public dokumentAktualisierenRequest: api.DokumentAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_AKTUALISIEREN_AUSFUEHREN;
        constructor(public dokumentAktualisierenRequest: api.DokumentAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentAktualisierenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentAktualisierenRequest: api.DokumentAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentAktualisierenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public dokumentAktualisierenRequest: api.DokumentAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandDokumentAktualisierenActions =
    PostDokumentAktualisierenAction
    | PostDokumentAktualisierenAusfuehrenAction
    | PostDokumentAktualisierenErfolgreichAction
    | PostDokumentAktualisierenFehlerAction
    | PostDokumentAktualisierenNichtAusgefuehrtAction
    ;
