import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFPLAN_ENTFERNEN = '[QschecksModul] PostPruefplanEntfernen laden';
export const POST_PRUEFPLAN_ENTFERNEN_AUSFUEHREN = '[QschecksModul] PostPruefplanEntfernen ausführen';
export const POST_PRUEFPLAN_ENTFERNEN_ERFOLGREICH = '[QschecksModul] PostPruefplanEntfernen laden erfolgreich';
export const POST_PRUEFPLAN_ENTFERNEN_FEHLER = '[QschecksModul] PostPruefplanEntfernen Ladefehler';
export const POST_PRUEFPLAN_ENTFERNEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefplanEntfernen nicht ausgeführt (evtl. Offline)';



export class PostPruefplanEntfernenAction implements Action {
    readonly type = POST_PRUEFPLAN_ENTFERNEN;
        constructor(public pruefplanRequest: api.PruefplanRequest, public optPayload: any = null) {}
    }
export class PostPruefplanEntfernenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFPLAN_ENTFERNEN_AUSFUEHREN;
        constructor(public pruefplanRequest: api.PruefplanRequest, public optPayload: any = null) {}
    }
export class PostPruefplanEntfernenErfolgreichAction implements Action {
    readonly type = POST_PRUEFPLAN_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefplanRequest: api.PruefplanRequest, public optPayload: any = null) {}
    }
export class PostPruefplanEntfernenFehlerAction implements Action {
    readonly type = POST_PRUEFPLAN_ENTFERNEN_FEHLER;
        constructor(public payload: any, public pruefplanRequest: api.PruefplanRequest, public optPayload: any = null) {}
    }
export class PostPruefplanEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFPLAN_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefplanEntfernenActions =
    PostPruefplanEntfernenAction
    | PostPruefplanEntfernenAusfuehrenAction
    | PostPruefplanEntfernenErfolgreichAction
    | PostPruefplanEntfernenFehlerAction
    | PostPruefplanEntfernenNichtAusgefuehrtAction
    ;
