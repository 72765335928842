import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.query.veranstaltung-info-kategorie-admin';
import * as api from '../../../clients/veranstaltungen';


    @Injectable()
    export class VeranstaltungenVeranstaltungInfoKategorieAdminEffects {

        @Effect()
            VeranstaltungInfoKategorieAdmin$ = this.actions$.pipe(
                ofType(ac.GET_VERANSTALTUNG_INFO_KATEGORIE_ADMIN),
                flatMap((x: ac.GetVeranstaltungInfoKategorieAdminAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().getVeranstaltungInfoKategorieAdmin(x.veranstaltung_id, x.kagetorieId).pipe(
                    map((result: any) => {
                        const nextAction = new ac.GetVeranstaltungInfoKategorieAdminErfolgreichAction(result, x.veranstaltung_id, x.kagetorieId,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.GetVeranstaltungInfoKategorieAdminFehlerAction(error, x.veranstaltung_id, x.kagetorieId,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.AdminQueryService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.AdminQueryService {
                if (this.service === null) {
                    this.service = this.injector.get(api.AdminQueryService);
                }
                return this.service;
            }
        
}
