export * from './account.service';
import { AccountService } from './account.service';
export * from './api.service';
import { ApiService } from './api.service';
export * from './commonTemplates.service';
import { CommonTemplatesService } from './commonTemplates.service';
export * from './mandantVerwaltung.service';
import { MandantVerwaltungService } from './mandantVerwaltung.service';
export * from './mandanten.service';
import { MandantenService } from './mandanten.service';
export * from './mandantenTemplates.service';
import { MandantenTemplatesService } from './mandantenTemplates.service';
export * from './upload.service';
import { UploadService } from './upload.service';
export const APIS = [AccountService, ApiService, CommonTemplatesService, MandantVerwaltungService, MandantenService, MandantenTemplatesService, UploadService];
