import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_OK_ERFASSEN = '[IncidentsModul] PostIncidentOkErfassen laden';
export const POST_INCIDENT_OK_ERFASSEN_AUSFUEHREN = '[IncidentsModul] PostIncidentOkErfassen ausführen';
export const POST_INCIDENT_OK_ERFASSEN_ERFOLGREICH = '[IncidentsModul] PostIncidentOkErfassen laden erfolgreich';
export const POST_INCIDENT_OK_ERFASSEN_FEHLER = '[IncidentsModul] PostIncidentOkErfassen Ladefehler';
export const POST_INCIDENT_OK_ERFASSEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentOkErfassen nicht ausgeführt (evtl. Offline)';



export class PostIncidentOkErfassenAction implements Action {
    readonly type = POST_INCIDENT_OK_ERFASSEN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentOkErfassenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_OK_ERFASSEN_AUSFUEHREN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentOkErfassenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_OK_ERFASSEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentOkErfassenFehlerAction implements Action {
    readonly type = POST_INCIDENT_OK_ERFASSEN_FEHLER;
        constructor(public payload: any, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentOkErfassenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_OK_ERFASSEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentOkErfassenActions =
    PostIncidentOkErfassenAction
    | PostIncidentOkErfassenAusfuehrenAction
    | PostIncidentOkErfassenErfolgreichAction
    | PostIncidentOkErfassenFehlerAction
    | PostIncidentOkErfassenNichtAusgefuehrtAction
    ;
