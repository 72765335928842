import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_MEINE_INCIDENTS_VERANSTALTUNG = '[IncidentsModul] GetMeineIncidentsVeranstaltung laden';
export const GET_MEINE_INCIDENTS_VERANSTALTUNG_AUSFUEHREN = '[IncidentsModul] GetMeineIncidentsVeranstaltung ausführen';
export const GET_MEINE_INCIDENTS_VERANSTALTUNG_ERFOLGREICH = '[IncidentsModul] GetMeineIncidentsVeranstaltung laden erfolgreich';
export const GET_MEINE_INCIDENTS_VERANSTALTUNG_FEHLER = '[IncidentsModul] GetMeineIncidentsVeranstaltung Ladefehler';
export const GET_MEINE_INCIDENTS_VERANSTALTUNG_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetMeineIncidentsVeranstaltung nicht ausgeführt (evtl. Offline)';



export class GetMeineIncidentsVeranstaltungAction implements Action {
    readonly type = GET_MEINE_INCIDENTS_VERANSTALTUNG;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetMeineIncidentsVeranstaltungAusfuehrenAction implements Action {
    readonly type = GET_MEINE_INCIDENTS_VERANSTALTUNG_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetMeineIncidentsVeranstaltungErfolgreichAction implements Action {
    readonly type = GET_MEINE_INCIDENTS_VERANSTALTUNG_ERFOLGREICH;
        constructor(public payload: api.IncidentsListResponse,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetMeineIncidentsVeranstaltungFehlerAction implements Action {
    readonly type = GET_MEINE_INCIDENTS_VERANSTALTUNG_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetMeineIncidentsVeranstaltungNichtAusgefuehrtAction implements Action {
    readonly type = GET_MEINE_INCIDENTS_VERANSTALTUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryMeineIncidentsVeranstaltungActions =
    GetMeineIncidentsVeranstaltungAction
    | GetMeineIncidentsVeranstaltungAusfuehrenAction
    | GetMeineIncidentsVeranstaltungErfolgreichAction
    | GetMeineIncidentsVeranstaltungFehlerAction
    | GetMeineIncidentsVeranstaltungNichtAusgefuehrtAction
    ;
