import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const GET_DISPLAYSTEUERUNG_ENTITY_TYPES = '[DisplaysteuerungModul] GetDisplaysteuerungEntityTypes laden';
export const GET_DISPLAYSTEUERUNG_ENTITY_TYPES_AUSFUEHREN = '[DisplaysteuerungModul] GetDisplaysteuerungEntityTypes ausführen';
export const GET_DISPLAYSTEUERUNG_ENTITY_TYPES_ERFOLGREICH = '[DisplaysteuerungModul] GetDisplaysteuerungEntityTypes laden erfolgreich';
export const GET_DISPLAYSTEUERUNG_ENTITY_TYPES_FEHLER = '[DisplaysteuerungModul] GetDisplaysteuerungEntityTypes Ladefehler';
export const GET_DISPLAYSTEUERUNG_ENTITY_TYPES_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] GetDisplaysteuerungEntityTypes nicht ausgeführt (evtl. Offline)';



export class GetDisplaysteuerungEntityTypesAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_ENTITY_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetDisplaysteuerungEntityTypesAusfuehrenAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_ENTITY_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetDisplaysteuerungEntityTypesErfolgreichAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_ENTITY_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.EntityTypeInfo>, public optPayload: any = null) {}
    }
export class GetDisplaysteuerungEntityTypesFehlerAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_ENTITY_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetDisplaysteuerungEntityTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_ENTITY_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungQueryDisplaysteuerungEntityTypesActions =
    GetDisplaysteuerungEntityTypesAction
    | GetDisplaysteuerungEntityTypesAusfuehrenAction
    | GetDisplaysteuerungEntityTypesErfolgreichAction
    | GetDisplaysteuerungEntityTypesFehlerAction
    | GetDisplaysteuerungEntityTypesNichtAusgefuehrtAction
    ;
