import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGEN_ENTITY_TYPES = '[VeranstaltungenModul] GetVeranstaltungenEntityTypes laden';
export const GET_VERANSTALTUNGEN_ENTITY_TYPES_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungenEntityTypes ausführen';
export const GET_VERANSTALTUNGEN_ENTITY_TYPES_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungenEntityTypes laden erfolgreich';
export const GET_VERANSTALTUNGEN_ENTITY_TYPES_FEHLER = '[VeranstaltungenModul] GetVeranstaltungenEntityTypes Ladefehler';
export const GET_VERANSTALTUNGEN_ENTITY_TYPES_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungenEntityTypes nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungenEntityTypesAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ENTITY_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenEntityTypesAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ENTITY_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenEntityTypesErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ENTITY_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.EntityTypeInfo>, public optPayload: any = null) {}
    }
export class GetVeranstaltungenEntityTypesFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ENTITY_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungenEntityTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ENTITY_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungenEntityTypesActions =
    GetVeranstaltungenEntityTypesAction
    | GetVeranstaltungenEntityTypesAusfuehrenAction
    | GetVeranstaltungenEntityTypesErfolgreichAction
    | GetVeranstaltungenEntityTypesFehlerAction
    | GetVeranstaltungenEntityTypesNichtAusgefuehrtAction
    ;
