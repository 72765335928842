import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const POST_BEREICH_ROLLE_HINZUFUEGEN = '[DisplaysteuerungModul] PostBereichRolleHinzufuegen laden';
export const POST_BEREICH_ROLLE_HINZUFUEGEN_AUSFUEHREN = '[DisplaysteuerungModul] PostBereichRolleHinzufuegen ausführen';
export const POST_BEREICH_ROLLE_HINZUFUEGEN_ERFOLGREICH = '[DisplaysteuerungModul] PostBereichRolleHinzufuegen laden erfolgreich';
export const POST_BEREICH_ROLLE_HINZUFUEGEN_FEHLER = '[DisplaysteuerungModul] PostBereichRolleHinzufuegen Ladefehler';
export const POST_BEREICH_ROLLE_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] PostBereichRolleHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostBereichRolleHinzufuegenAction implements Action {
    readonly type = POST_BEREICH_ROLLE_HINZUFUEGEN;
        constructor(public bereichRolleRequest: api.BereichRolleRequest, public optPayload: any = null) {}
    }
export class PostBereichRolleHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_BEREICH_ROLLE_HINZUFUEGEN_AUSFUEHREN;
        constructor(public bereichRolleRequest: api.BereichRolleRequest, public optPayload: any = null) {}
    }
export class PostBereichRolleHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_BEREICH_ROLLE_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public bereichRolleRequest: api.BereichRolleRequest, public optPayload: any = null) {}
    }
export class PostBereichRolleHinzufuegenFehlerAction implements Action {
    readonly type = POST_BEREICH_ROLLE_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public bereichRolleRequest: api.BereichRolleRequest, public optPayload: any = null) {}
    }
export class PostBereichRolleHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_BEREICH_ROLLE_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungCommandBereichRolleHinzufuegenActions =
    PostBereichRolleHinzufuegenAction
    | PostBereichRolleHinzufuegenAusfuehrenAction
    | PostBereichRolleHinzufuegenErfolgreichAction
    | PostBereichRolleHinzufuegenFehlerAction
    | PostBereichRolleHinzufuegenNichtAusgefuehrtAction
    ;
