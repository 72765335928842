import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_AKTIONEN_ADMIN = '[VeranstaltungenModul] GetVeranstaltungAktionenAdmin laden';
export const GET_VERANSTALTUNG_AKTIONEN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungAktionenAdmin ausführen';
export const GET_VERANSTALTUNG_AKTIONEN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungAktionenAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_AKTIONEN_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungAktionenAdmin Ladefehler';
export const GET_VERANSTALTUNG_AKTIONEN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungAktionenAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungAktionenAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_AKTIONEN_ADMIN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungAktionenAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_AKTIONEN_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungAktionenAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_AKTIONEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.Aktion>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungAktionenAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_AKTIONEN_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungAktionenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_AKTIONEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungAktionenAdminActions =
    GetVeranstaltungAktionenAdminAction
    | GetVeranstaltungAktionenAdminAusfuehrenAction
    | GetVeranstaltungAktionenAdminErfolgreichAction
    | GetVeranstaltungAktionenAdminFehlerAction
    | GetVeranstaltungAktionenAdminNichtAusgefuehrtAction
    ;
