import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_USER_INFO = '[ApiModul] GetUserInfo laden';
export const GET_USER_INFO_AUSFUEHREN = '[ApiModul] GetUserInfo ausführen';
export const GET_USER_INFO_ERFOLGREICH = '[ApiModul] GetUserInfo laden erfolgreich';
export const GET_USER_INFO_FEHLER = '[ApiModul] GetUserInfo Ladefehler';
export const GET_USER_INFO_NICHT_AUSGEFUEHRT = '[ApiModul] GetUserInfo nicht ausgeführt (evtl. Offline)';



export class GetUserInfoAction implements Action {
    readonly type = GET_USER_INFO;
        constructor(public optPayload: any = null) {}
    }
export class GetUserInfoAusfuehrenAction implements Action {
    readonly type = GET_USER_INFO_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetUserInfoErfolgreichAction implements Action {
    readonly type = GET_USER_INFO_ERFOLGREICH;
        constructor(public payload: api.Benutzer, public optPayload: any = null) {}
    }
export class GetUserInfoFehlerAction implements Action {
    readonly type = GET_USER_INFO_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetUserInfoNichtAusgefuehrtAction implements Action {
    readonly type = GET_USER_INFO_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryUserInfoActions =
    GetUserInfoAction
    | GetUserInfoAusfuehrenAction
    | GetUserInfoErfolgreichAction
    | GetUserInfoFehlerAction
    | GetUserInfoNichtAusgefuehrtAction
    ;
