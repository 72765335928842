import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENTS_ENTITY_TYPES = '[IncidentsModul] GetIncidentsEntityTypes laden';
export const GET_INCIDENTS_ENTITY_TYPES_AUSFUEHREN = '[IncidentsModul] GetIncidentsEntityTypes ausführen';
export const GET_INCIDENTS_ENTITY_TYPES_ERFOLGREICH = '[IncidentsModul] GetIncidentsEntityTypes laden erfolgreich';
export const GET_INCIDENTS_ENTITY_TYPES_FEHLER = '[IncidentsModul] GetIncidentsEntityTypes Ladefehler';
export const GET_INCIDENTS_ENTITY_TYPES_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentsEntityTypes nicht ausgeführt (evtl. Offline)';



export class GetIncidentsEntityTypesAction implements Action {
    readonly type = GET_INCIDENTS_ENTITY_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsEntityTypesAusfuehrenAction implements Action {
    readonly type = GET_INCIDENTS_ENTITY_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsEntityTypesErfolgreichAction implements Action {
    readonly type = GET_INCIDENTS_ENTITY_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.EntityTypeInfo>, public optPayload: any = null) {}
    }
export class GetIncidentsEntityTypesFehlerAction implements Action {
    readonly type = GET_INCIDENTS_ENTITY_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetIncidentsEntityTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENTS_ENTITY_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentsEntityTypesActions =
    GetIncidentsEntityTypesAction
    | GetIncidentsEntityTypesAusfuehrenAction
    | GetIncidentsEntityTypesErfolgreichAction
    | GetIncidentsEntityTypesFehlerAction
    | GetIncidentsEntityTypesNichtAusgefuehrtAction
    ;
