

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcel laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcel ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcel laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcel Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcel nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcelAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcelAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcelErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_ERFOLGREICH;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcelFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcelNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcelActions =
    GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcelAction
    | GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcelAusfuehrenAction
    | GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcelErfolgreichAction
    | GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcelFehlerAction
    | GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenExcelNichtAusgefuehrtAction
    ;
