import {Action} from '@ngrx/store';
import {Veranstaltung} from '../../../../clients/veranstaltungen';

export const PAGE_DATEN_LADEN = '[Veranstaltung] Daten Laden';
export const PAGE_STATE_RESET = '[Veranstaltung] State reset';
export const IGNORE_WS_ACTION = '[Veranstaltung] WS Action ignoriert';
export const AKTUELLE_VERANSTALTUNG_SETZEN = '[VeranstaltungPage] Aktuelle Veranstaltung Setzen';


// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;
}

// IGNORE WS ACTION
export class IgnoreWSAction implements Action {
    readonly type = IGNORE_WS_ACTION;

    constructor(public payload: any) {
    }
}

export class PageLoadDataAction implements Action {
    readonly type = PAGE_DATEN_LADEN;

    constructor() {
    }
}

export class AktuelleVeranstaltungSetzenAction implements Action {
    readonly type = AKTUELLE_VERANSTALTUNG_SETZEN;

    constructor(public payload: Veranstaltung) {
    }
}


export type Actions =
    PageStateResetAction
    | IgnoreWSAction
    | PageLoadDataAction
    | AktuelleVeranstaltungSetzenAction
    ;
