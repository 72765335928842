import { IAppConfig, IAzureAdConfig, IMobileConfig } from './model';

export const aadDev = <IAzureAdConfig>{
    clientId: '1df93e2b-5dad-4c54-aa3f-20569ff4de51',
    tenantId: 'common',
    resource: '1df93e2b-5dad-4c54-aa3f-20569ff4de51',
    discoveryDocumentV1: `https://login.microsoftonline.com/common/.well-known/openid-configuration`,
    clientId2: '8b43e44e-f836-41af-95e0-9f12ad64e81f',
    tenantMappings: {
      'orga-app-dev.sopidemo.de' : 'softwarepioniere-demo.de'
    //  'localhost' : 'softwarepioniere-demo.de'
    }
};

export const aadStag = <IAzureAdConfig>{
    clientId: '8ab20a21-5fa0-4d89-8392-125469e74cc1',
    tenantId: '29abe415-d035-4d18-9ccd-4f085365e073',
    resource: '8ab20a21-5fa0-4d89-8392-125469e74cc1',
    discoveryDocumentV1: `https://login.microsoftonline.com/common/.well-known/openid-configuration`    ,
    clientId2: '3e36ad54-daa6-4102-be25-891ede085a25',
    tenantMappings: {
      'orga-app-stag.sopidemo.de' : 'softwarepioniere-demo.de'
    }
};

export const aadProd = <IAzureAdConfig>{
    clientId: '77991b59-7577-4292-b4ec-bdf115bb872e',
    tenantId: '29abe415-d035-4d18-9ccd-4f085365e073',
    resource: '77991b59-7577-4292-b4ec-bdf115bb872e',
    discoveryDocumentV1: `https://login.microsoftonline.com/common/.well-known/openid-configuration`,
    clientId2: '77029f07-5963-4861-bc60-a500885c52dc',
    tenantMappings: {
      'orga-app.sopidemo.de' : 'softwarepioniere-demo.de',
      'orga-app.fcbayern.com': 'fcbayern.com'
    }
};

export const appConfigLocalHost = <IAppConfig>{
    apiUrl: 'http://localhost:7000',
    wsEndpoint: 'http://localhost:7000/api/websocket',
    appInsightsInstrumentationKey: null,
    themeUrl: 'https://storgaappdev2002.blob.core.windows.net/themes',
    themeType: '2021'
};

export const appConfigTbWorkstation = <IAppConfig>{
  apiUrl: 'http://192.168.178.130:7000',
  wsEndpoint: 'http://192.168.178.130:7000/api/websocket',
  appInsightsInstrumentationKey: null,
  themeUrl: 'https://storgaappdev2002.blob.core.windows.net/themes',
  themeType: '2021'
};

export const appConfigDev = <IAppConfig>{
    apiUrl: 'https://api-dev.orga-app.de',
    wsEndpoint: 'https://api-dev.orga-app.de/api/websocket',
    appInsightsInstrumentationKey: 'd5d4858d-c687-4875-a220-7b2e86dded1e',
    themeUrl: 'https://storgaappdev2002.blob.core.windows.net/themes',
    themeType: '2021'
};

export const appConfigStag = <IAppConfig>{
    apiUrl: 'https://api-stag.orga-app.de',
    wsEndpoint: 'https://api-stag.orga-app.de/api/websocket',
    appInsightsInstrumentationKey: '5000c51c-771d-402e-997e-2f47b87768d3',
    themeUrl: 'https://storgaappstag002.blob.core.windows.net/themes',
    themeType: '2021'
};

export const appConfigProd = <IAppConfig>{
    apiUrl: 'https://api.orga-app.de',
    wsEndpoint: 'https://api.orga-app.de/api/websocket',
    appInsightsInstrumentationKey: '42b4cd6d-ef6b-4065-ae30-244eee3ce556',
    themeUrl: 'https://storgaappprod002.blob.core.windows.net/themes',
    themeType: '2021'
};

export const emptyMobile = <IMobileConfig>{
  testModeCordovaSimulator: false,
  ionicAppId: '',
  ionicChannel: '',
  autoUpdate: false
};

export const mobileDev = <IMobileConfig>{
  testModeCordovaSimulator: false,
  ionicAppId: 'c6b424c7',
  ionicChannel: 'Development',
  autoUpdate: false
};

export const mobileStag = <IMobileConfig>{
  testModeCordovaSimulator: false,
  ionicAppId: 'c6b424c7',
  ionicChannel:  'Staging',
  autoUpdate: true
};

export const mobileProd = <IMobileConfig>{
  testModeCordovaSimulator: false,
  ionicAppId: 'c6b424c7',
  ionicChannel: 'Production',
  autoUpdate: true
};
