import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.command.benutzer-zum-dienst-einteilen-multi';
import * as api from '../../../clients/veranstaltungen';


    @Injectable()
    export class VeranstaltungenBenutzerZumDienstEinteilenMultiEffects {

        @Effect()
            BenutzerZumDienstEinteilenMulti$ = this.actions$.pipe(
                ofType(ac.POST_BENUTZER_ZUM_DIENST_EINTEILEN_MULTI),
                flatMap((x: ac.PostBenutzerZumDienstEinteilenMultiAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().postBenutzerZumDienstEinteilenMulti(x.benutzerZumDienstEinteilenMultiRequest).pipe(
                    map((result: any) => {
                        const nextAction = new ac.PostBenutzerZumDienstEinteilenMultiErfolgreichAction(result, x.benutzerZumDienstEinteilenMultiRequest,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.PostBenutzerZumDienstEinteilenMultiFehlerAction(error, x.benutzerZumDienstEinteilenMultiRequest,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.RollenCommandService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.RollenCommandService {
                if (this.service === null) {
                    this.service = this.injector.get(api.RollenCommandService);
                }
                return this.service;
            }
        
}
