import {Action} from '@ngrx/store';
import * as  apiVeranstaltungen from '../../../clients/veranstaltungen';

export const PAGE_STATE_RESET = '[FehlerbeschreibungPage] Page State reset';
export const FEHLERBESCHREIBUNG_ERFOLGREICH_HINZUGEFUEGT = '[FehlerbeschreibungPage] Fehlerbeschreibung erfolgreich hinzugefügt';
export const IGNORE_WS_ACTION = '[FehlerbeschreibungPage] WS Action ignoriert';
export const AKTUELLE_AUFGABE_SETZEN = '[FehlerbeschreibungPage] Aktuelle Aufgabe setzen';
export const SETZE_VERANSTALTUNG_ID = '[FehlerbeschreibungPage] Setze Veranstaltung Id';
export const SETZE_CHECKLISTE_ID = '[FehlerbeschreibungPage] Setze Checkliste Id';
export const SETZE_FUNKTIONSBEREICH_ID = '[FehlerbeschreibungPage] Setze Funktionsbereich Id';
export const SETZE_AUFGABE_ID = '[FehlerbeschreibungPage] Setze Aufgabe Id';


// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;
}

// IGNORE WS ACTION
export class IgnoreWSAction implements Action {
    readonly type = IGNORE_WS_ACTION;
}

export class SetzeVeranstaltungIdAction implements Action {
    readonly type = SETZE_VERANSTALTUNG_ID;

    constructor(public veranstaltungId: string) {
    }
}

export class SetzeFunktionsbereichIdAction implements Action {
    readonly type = SETZE_FUNKTIONSBEREICH_ID;

    constructor(public funktionsbereichId: string) {
    }
}

export class SetzeChecklisteIdAction implements Action {
    readonly type = SETZE_CHECKLISTE_ID;

    constructor(public checklisteId: string) {
    }
}

export class SetzeAufgabeIdAction implements Action {
    readonly type = SETZE_AUFGABE_ID;

    constructor(public aufgabeId: string) {
    }
}

export class FehlerbeschreibungErfolgreichVersendetAction implements Action {
    readonly type = FEHLERBESCHREIBUNG_ERFOLGREICH_HINZUGEFUEGT;

    constructor() {
    }
}

export class AktuelleAufgabeSetzenAction implements Action {
    readonly type = AKTUELLE_AUFGABE_SETZEN;

    constructor(public aufgabe: apiVeranstaltungen.Aufgabe) {
    }
}


/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
    FehlerbeschreibungErfolgreichVersendetAction
    | PageStateResetAction
    | IgnoreWSAction
    | AktuelleAufgabeSetzenAction
    | SetzeFunktionsbereichIdAction
    | SetzeChecklisteIdAction
    | SetzeAufgabeIdAction
    | SetzeVeranstaltungIdAction
    ;
