import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_ESKALATION_VERSENDEN = '[VeranstaltungenModul] PostAufgabeEskalationVersenden laden';
export const POST_AUFGABE_ESKALATION_VERSENDEN_AUSFUEHREN = '[VeranstaltungenModul] PostAufgabeEskalationVersenden ausführen';
export const POST_AUFGABE_ESKALATION_VERSENDEN_ERFOLGREICH = '[VeranstaltungenModul] PostAufgabeEskalationVersenden laden erfolgreich';
export const POST_AUFGABE_ESKALATION_VERSENDEN_FEHLER = '[VeranstaltungenModul] PostAufgabeEskalationVersenden Ladefehler';
export const POST_AUFGABE_ESKALATION_VERSENDEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostAufgabeEskalationVersenden nicht ausgeführt (evtl. Offline)';



export class PostAufgabeEskalationVersendenAction implements Action {
    readonly type = POST_AUFGABE_ESKALATION_VERSENDEN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeEskalationVersendenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_ESKALATION_VERSENDEN_AUSFUEHREN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeEskalationVersendenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_ESKALATION_VERSENDEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeEskalationVersendenFehlerAction implements Action {
    readonly type = POST_AUFGABE_ESKALATION_VERSENDEN_FEHLER;
        constructor(public payload: any, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeEskalationVersendenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_ESKALATION_VERSENDEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandAufgabeEskalationVersendenActions =
    PostAufgabeEskalationVersendenAction
    | PostAufgabeEskalationVersendenAusfuehrenAction
    | PostAufgabeEskalationVersendenErfolgreichAction
    | PostAufgabeEskalationVersendenFehlerAction
    | PostAufgabeEskalationVersendenNichtAusgefuehrtAction
    ;
