import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFLAUF_BERECHTIGUNG = '[QschecksModul] GetPrueflaufBerechtigung laden';
export const GET_PRUEFLAUF_BERECHTIGUNG_AUSFUEHREN = '[QschecksModul] GetPrueflaufBerechtigung ausführen';
export const GET_PRUEFLAUF_BERECHTIGUNG_ERFOLGREICH = '[QschecksModul] GetPrueflaufBerechtigung laden erfolgreich';
export const GET_PRUEFLAUF_BERECHTIGUNG_FEHLER = '[QschecksModul] GetPrueflaufBerechtigung Ladefehler';
export const GET_PRUEFLAUF_BERECHTIGUNG_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPrueflaufBerechtigung nicht ausgeführt (evtl. Offline)';



export class GetPrueflaufBerechtigungAction implements Action {
    readonly type = GET_PRUEFLAUF_BERECHTIGUNG;
        constructor(public prueflaufStatusRequest: api.PrueflaufStatusRequest, public optPayload: any = null) {}
    }
export class GetPrueflaufBerechtigungAusfuehrenAction implements Action {
    readonly type = GET_PRUEFLAUF_BERECHTIGUNG_AUSFUEHREN;
        constructor(public prueflaufStatusRequest: api.PrueflaufStatusRequest, public optPayload: any = null) {}
    }
export class GetPrueflaufBerechtigungErfolgreichAction implements Action {
    readonly type = GET_PRUEFLAUF_BERECHTIGUNG_ERFOLGREICH;
        constructor(public payload: api.PrueflaufBerechtigung, public prueflaufStatusRequest: api.PrueflaufStatusRequest, public optPayload: any = null) {}
    }
export class GetPrueflaufBerechtigungFehlerAction implements Action {
    readonly type = GET_PRUEFLAUF_BERECHTIGUNG_FEHLER;
        constructor(public payload: any, public prueflaufStatusRequest: api.PrueflaufStatusRequest, public optPayload: any = null) {}
    }
export class GetPrueflaufBerechtigungNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFLAUF_BERECHTIGUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPrueflaufBerechtigungActions =
    GetPrueflaufBerechtigungAction
    | GetPrueflaufBerechtigungAusfuehrenAction
    | GetPrueflaufBerechtigungErfolgreichAction
    | GetPrueflaufBerechtigungFehlerAction
    | GetPrueflaufBerechtigungNichtAusgefuehrtAction
    ;
