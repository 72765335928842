import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_AUFGABE_BILD_IDS = '[VeranstaltungenModul] GetAufgabeBildIds laden';
export const GET_AUFGABE_BILD_IDS_AUSFUEHREN = '[VeranstaltungenModul] GetAufgabeBildIds ausführen';
export const GET_AUFGABE_BILD_IDS_ERFOLGREICH = '[VeranstaltungenModul] GetAufgabeBildIds laden erfolgreich';
export const GET_AUFGABE_BILD_IDS_FEHLER = '[VeranstaltungenModul] GetAufgabeBildIds Ladefehler';
export const GET_AUFGABE_BILD_IDS_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetAufgabeBildIds nicht ausgeführt (evtl. Offline)';



export class GetAufgabeBildIdsAction implements Action {
    readonly type = GET_AUFGABE_BILD_IDS;
        constructor( public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeBildIdsAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_BILD_IDS_AUSFUEHREN;
        constructor( public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeBildIdsErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_BILD_IDS_ERFOLGREICH;
        constructor(public payload: api.AufgabeBildIds,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeBildIdsFehlerAction implements Action {
    readonly type = GET_AUFGABE_BILD_IDS_FEHLER;
        constructor(public payload: any,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeBildIdsNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_BILD_IDS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryAufgabeBildIdsActions =
    GetAufgabeBildIdsAction
    | GetAufgabeBildIdsAusfuehrenAction
    | GetAufgabeBildIdsErfolgreichAction
    | GetAufgabeBildIdsFehlerAction
    | GetAufgabeBildIdsNichtAusgefuehrtAction
    ;
