import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNGS_TYP_FELD_ANLEGEN = '[VeranstaltungstypenModul] PostVeranstaltungsTypFeldAnlegen laden';
export const POST_VERANSTALTUNGS_TYP_FELD_ANLEGEN_AUSFUEHREN = '[VeranstaltungstypenModul] PostVeranstaltungsTypFeldAnlegen ausführen';
export const POST_VERANSTALTUNGS_TYP_FELD_ANLEGEN_ERFOLGREICH = '[VeranstaltungstypenModul] PostVeranstaltungsTypFeldAnlegen laden erfolgreich';
export const POST_VERANSTALTUNGS_TYP_FELD_ANLEGEN_FEHLER = '[VeranstaltungstypenModul] PostVeranstaltungsTypFeldAnlegen Ladefehler';
export const POST_VERANSTALTUNGS_TYP_FELD_ANLEGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] PostVeranstaltungsTypFeldAnlegen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungsTypFeldAnlegenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELD_ANLEGEN;
        constructor(public veranstaltungsTypFeldRequest: api.VeranstaltungsTypFeldRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypFeldAnlegenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELD_ANLEGEN_AUSFUEHREN;
        constructor(public veranstaltungsTypFeldRequest: api.VeranstaltungsTypFeldRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypFeldAnlegenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELD_ANLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungsTypFeldRequest: api.VeranstaltungsTypFeldRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypFeldAnlegenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELD_ANLEGEN_FEHLER;
        constructor(public payload: any, public veranstaltungsTypFeldRequest: api.VeranstaltungsTypFeldRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypFeldAnlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELD_ANLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenCommandVeranstaltungsTypFeldAnlegenActions =
    PostVeranstaltungsTypFeldAnlegenAction
    | PostVeranstaltungsTypFeldAnlegenAusfuehrenAction
    | PostVeranstaltungsTypFeldAnlegenErfolgreichAction
    | PostVeranstaltungsTypFeldAnlegenFehlerAction
    | PostVeranstaltungsTypFeldAnlegenNichtAusgefuehrtAction
    ;
