import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_CHECKLISTE_DETAIL = '[VorlagenModul] GetChecklisteDetail laden';
export const GET_CHECKLISTE_DETAIL_AUSFUEHREN = '[VorlagenModul] GetChecklisteDetail ausführen';
export const GET_CHECKLISTE_DETAIL_ERFOLGREICH = '[VorlagenModul] GetChecklisteDetail laden erfolgreich';
export const GET_CHECKLISTE_DETAIL_FEHLER = '[VorlagenModul] GetChecklisteDetail Ladefehler';
export const GET_CHECKLISTE_DETAIL_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetChecklisteDetail nicht ausgeführt (evtl. Offline)';



export class GetChecklisteDetailAction implements Action {
    readonly type = GET_CHECKLISTE_DETAIL;
        constructor( public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteDetailAusfuehrenAction implements Action {
    readonly type = GET_CHECKLISTE_DETAIL_AUSFUEHREN;
        constructor( public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteDetailErfolgreichAction implements Action {
    readonly type = GET_CHECKLISTE_DETAIL_ERFOLGREICH;
        constructor(public payload: api.ChecklisteDetail,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteDetailFehlerAction implements Action {
    readonly type = GET_CHECKLISTE_DETAIL_FEHLER;
        constructor(public payload: any,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteDetailNichtAusgefuehrtAction implements Action {
    readonly type = GET_CHECKLISTE_DETAIL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryChecklisteDetailActions =
    GetChecklisteDetailAction
    | GetChecklisteDetailAusfuehrenAction
    | GetChecklisteDetailErfolgreichAction
    | GetChecklisteDetailFehlerAction
    | GetChecklisteDetailNichtAusgefuehrtAction
    ;
