import * as api from '../../../clients/einlasskontrolle'; 

import {Action} from '@ngrx/store';

export const GET_EINLASSKONTROLLE_BENUTZER_ZUGRIFF = '[EinlasskontrolleModul] GetEinlasskontrolleBenutzerZugriff laden';
export const GET_EINLASSKONTROLLE_BENUTZER_ZUGRIFF_AUSFUEHREN = '[EinlasskontrolleModul] GetEinlasskontrolleBenutzerZugriff ausführen';
export const GET_EINLASSKONTROLLE_BENUTZER_ZUGRIFF_ERFOLGREICH = '[EinlasskontrolleModul] GetEinlasskontrolleBenutzerZugriff laden erfolgreich';
export const GET_EINLASSKONTROLLE_BENUTZER_ZUGRIFF_FEHLER = '[EinlasskontrolleModul] GetEinlasskontrolleBenutzerZugriff Ladefehler';
export const GET_EINLASSKONTROLLE_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT = '[EinlasskontrolleModul] GetEinlasskontrolleBenutzerZugriff nicht ausgeführt (evtl. Offline)';



export class GetEinlasskontrolleBenutzerZugriffAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_BENUTZER_ZUGRIFF;
        constructor(public optPayload: any = null) {}
    }
export class GetEinlasskontrolleBenutzerZugriffAusfuehrenAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_BENUTZER_ZUGRIFF_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetEinlasskontrolleBenutzerZugriffErfolgreichAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_BENUTZER_ZUGRIFF_ERFOLGREICH;
        constructor(public payload: api.EinlasskontrolleBenutzerZugriff, public optPayload: any = null) {}
    }
export class GetEinlasskontrolleBenutzerZugriffFehlerAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_BENUTZER_ZUGRIFF_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetEinlasskontrolleBenutzerZugriffNichtAusgefuehrtAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type EinlasskontrolleQueryEinlasskontrolleBenutzerZugriffActions =
    GetEinlasskontrolleBenutzerZugriffAction
    | GetEinlasskontrolleBenutzerZugriffAusfuehrenAction
    | GetEinlasskontrolleBenutzerZugriffErfolgreichAction
    | GetEinlasskontrolleBenutzerZugriffFehlerAction
    | GetEinlasskontrolleBenutzerZugriffNichtAusgefuehrtAction
    ;
