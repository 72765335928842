import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungHinzufuegen laden';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_AUSFUEHREN = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungHinzufuegen ausführen';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_ERFOLGREICH = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungHinzufuegen laden erfolgreich';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_FEHLER = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungHinzufuegen Ladefehler';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostDokumentBearbeitenBerechtigungHinzufuegenAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN;
        constructor(public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_AUSFUEHREN;
        constructor(public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandDokumentBearbeitenBerechtigungHinzufuegenActions =
    PostDokumentBearbeitenBerechtigungHinzufuegenAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenAusfuehrenAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenErfolgreichAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenFehlerAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenNichtAusgefuehrtAction
    ;
