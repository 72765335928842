import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_REGISTRIERE_PUSH_NOTIFICATION = '[BenutzerverwaltungModul] PostRegistrierePushNotification laden';
export const POST_REGISTRIERE_PUSH_NOTIFICATION_AUSFUEHREN = '[BenutzerverwaltungModul] PostRegistrierePushNotification ausführen';
export const POST_REGISTRIERE_PUSH_NOTIFICATION_ERFOLGREICH = '[BenutzerverwaltungModul] PostRegistrierePushNotification laden erfolgreich';
export const POST_REGISTRIERE_PUSH_NOTIFICATION_FEHLER = '[BenutzerverwaltungModul] PostRegistrierePushNotification Ladefehler';
export const POST_REGISTRIERE_PUSH_NOTIFICATION_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostRegistrierePushNotification nicht ausgeführt (evtl. Offline)';



export class PostRegistrierePushNotificationAction implements Action {
    readonly type = POST_REGISTRIERE_PUSH_NOTIFICATION;
        constructor(public benutzerPushNotificationRequest: api.BenutzerPushNotificationRequest, public optPayload: any = null) {}
    }
export class PostRegistrierePushNotificationAusfuehrenAction implements Action {
    readonly type = POST_REGISTRIERE_PUSH_NOTIFICATION_AUSFUEHREN;
        constructor(public benutzerPushNotificationRequest: api.BenutzerPushNotificationRequest, public optPayload: any = null) {}
    }
export class PostRegistrierePushNotificationErfolgreichAction implements Action {
    readonly type = POST_REGISTRIERE_PUSH_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public benutzerPushNotificationRequest: api.BenutzerPushNotificationRequest, public optPayload: any = null) {}
    }
export class PostRegistrierePushNotificationFehlerAction implements Action {
    readonly type = POST_REGISTRIERE_PUSH_NOTIFICATION_FEHLER;
        constructor(public payload: any, public benutzerPushNotificationRequest: api.BenutzerPushNotificationRequest, public optPayload: any = null) {}
    }
export class PostRegistrierePushNotificationNichtAusgefuehrtAction implements Action {
    readonly type = POST_REGISTRIERE_PUSH_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandRegistrierePushNotificationActions =
    PostRegistrierePushNotificationAction
    | PostRegistrierePushNotificationAusfuehrenAction
    | PostRegistrierePushNotificationErfolgreichAction
    | PostRegistrierePushNotificationFehlerAction
    | PostRegistrierePushNotificationNichtAusgefuehrtAction
    ;
