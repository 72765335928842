import * as varApi from './../../clients/api/variables';
import * as varBenutzerverwaltung from './../../clients/benutzerverwaltung/variables';
import * as varVorlagen from './../../clients/vorlagen/variables';
import * as varVeranstaltungen from './../../clients/veranstaltungen/variables';
import * as varVeranstaltungenDp from './../../clients/veranstaltungen-dienstplan/variables';
import * as varVeranstaltungenRpt from './../../clients/veranstaltungen-reporting/variables';
import * as varKommunikation from './../../clients/kommunikation/variables';
import * as varPandemie from './../../clients/pandemie/variables';
import * as varIncidents from './../../clients/incidents/variables';
import * as varEinlasskontrolle from './../../clients/einlasskontrolle/variables';
import * as varDisplaysteuerung from './../../clients/displaysteuerung/variables';
import * as varQsChecks from './../../clients/qschecks/variables';
import * as varVerTypen from './../../clients/veranstaltungstypen/variables';
import * as varKontaktverwaltung from './../../clients/kontaktverwaltung/variables';

import * as apiBenutzerverwaltung from './../../clients/benutzerverwaltung';
import * as api from './../../clients/api';
import * as apiVeranstaltungen from './../../clients/veranstaltungen';
import * as apiVeranstaltungenDp from './../../clients/veranstaltungen-dienstplan';
import * as apiVeranstaltungenRpt from './../../clients/veranstaltungen-reporting';
import * as apiKommunikation from './../../clients/kommunikation';
import * as apiVorlagen from './../../clients/vorlagen';
import * as apiPandemie from './../../clients/pandemie';
import * as apiIncidents from './../../clients/incidents';
import * as apiEinlasskontrolle from './../../clients/einlasskontrolle';
import * as apiDisplaysteuerung from './../../clients/displaysteuerung';
import * as apiQsChecks from './../../clients/qschecks';
import * as apiVerTypen from './../../clients/veranstaltungstypen';
import * as apiKontaktverwaltung from './../../clients/kontaktverwaltung';


import {ModuleWithProviders, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
    imports: [
        HttpClientModule,

        // Clients
        api.ApiModule,
        apiKommunikation.ApiModule,
        apiBenutzerverwaltung.ApiModule,
        apiVorlagen.ApiModule,
        apiVeranstaltungen.ApiModule,
        apiVeranstaltungenDp.ApiModule,
        apiVeranstaltungenRpt.ApiModule,
        apiPandemie.ApiModule,
        apiIncidents.ApiModule,
        apiEinlasskontrolle.ApiModule,
        apiDisplaysteuerung.ApiModule,
        apiQsChecks.ApiModule,
        apiVerTypen.ApiModule,
        apiKontaktverwaltung.ApiModule
    ],
    providers: []
})
export class ClientsModule {
    public static forRoot(apiUrl: string): ModuleWithProviders {
        return {
            ngModule: ClientsModule,
            providers: [
                {
                    provide: varApi.BASE_PATH,
                    useValue: apiUrl
                },
                {
                    provide: varBenutzerverwaltung.BASE_PATH,
                    useValue: apiUrl
                },
                {
                    provide: varVorlagen.BASE_PATH,
                    useValue: apiUrl
                },
                {
                    provide: varVeranstaltungen.BASE_PATH,
                    useValue: apiUrl
                },
                {
                    provide: varVeranstaltungenDp.BASE_PATH,
                    useValue: apiUrl
                },
                {
                    provide: varVeranstaltungenRpt.BASE_PATH,
                    useValue: apiUrl
                },
                {
                    provide: varKommunikation.BASE_PATH,
                    useValue: apiUrl
                },
                {
                    provide: varPandemie.BASE_PATH,
                    useValue: apiUrl
                },
                {
                    provide: varIncidents.BASE_PATH,
                    useValue: apiUrl
                },
                {
                    provide: varEinlasskontrolle.BASE_PATH,
                    useValue: apiUrl
                },
                {
                    provide: varDisplaysteuerung.BASE_PATH,
                    useValue: apiUrl
                },
                {
                    provide: varQsChecks.BASE_PATH,
                    useValue: apiUrl
                },
                {
                    provide: varVerTypen.BASE_PATH,
                    useValue: apiUrl             
                },
                {
                    provide: varKontaktverwaltung.BASE_PATH,
                    useValue: apiUrl
                }
            ]
        };
    }
}
