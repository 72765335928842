import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_CHECKLISTE_AUFGABEN_ADMIN = '[VeranstaltungenModul] GetChecklisteAufgabenAdmin laden';
export const GET_CHECKLISTE_AUFGABEN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetChecklisteAufgabenAdmin ausführen';
export const GET_CHECKLISTE_AUFGABEN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetChecklisteAufgabenAdmin laden erfolgreich';
export const GET_CHECKLISTE_AUFGABEN_ADMIN_FEHLER = '[VeranstaltungenModul] GetChecklisteAufgabenAdmin Ladefehler';
export const GET_CHECKLISTE_AUFGABEN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetChecklisteAufgabenAdmin nicht ausgeführt (evtl. Offline)';



export class GetChecklisteAufgabenAdminAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABEN_ADMIN;
        constructor( public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabenAdminAusfuehrenAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABEN_ADMIN_AUSFUEHREN;
        constructor( public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabenAdminErfolgreichAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.AufgabeAdmin>,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabenAdminFehlerAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABEN_ADMIN_FEHLER;
        constructor(public payload: any,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryChecklisteAufgabenAdminActions =
    GetChecklisteAufgabenAdminAction
    | GetChecklisteAufgabenAdminAusfuehrenAction
    | GetChecklisteAufgabenAdminErfolgreichAction
    | GetChecklisteAufgabenAdminFehlerAction
    | GetChecklisteAufgabenAdminNichtAusgefuehrtAction
    ;
