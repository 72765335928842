import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_OK_ERFASSEN = '[VeranstaltungenModul] PostAufgabeOkErfassen laden';
export const POST_AUFGABE_OK_ERFASSEN_AUSFUEHREN = '[VeranstaltungenModul] PostAufgabeOkErfassen ausführen';
export const POST_AUFGABE_OK_ERFASSEN_ERFOLGREICH = '[VeranstaltungenModul] PostAufgabeOkErfassen laden erfolgreich';
export const POST_AUFGABE_OK_ERFASSEN_FEHLER = '[VeranstaltungenModul] PostAufgabeOkErfassen Ladefehler';
export const POST_AUFGABE_OK_ERFASSEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostAufgabeOkErfassen nicht ausgeführt (evtl. Offline)';



export class PostAufgabeOkErfassenAction implements Action {
    readonly type = POST_AUFGABE_OK_ERFASSEN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeOkErfassenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_OK_ERFASSEN_AUSFUEHREN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeOkErfassenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_OK_ERFASSEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeOkErfassenFehlerAction implements Action {
    readonly type = POST_AUFGABE_OK_ERFASSEN_FEHLER;
        constructor(public payload: any, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeOkErfassenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_OK_ERFASSEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandAufgabeOkErfassenActions =
    PostAufgabeOkErfassenAction
    | PostAufgabeOkErfassenAusfuehrenAction
    | PostAufgabeOkErfassenErfolgreichAction
    | PostAufgabeOkErfassenFehlerAction
    | PostAufgabeOkErfassenNichtAusgefuehrtAction
    ;
