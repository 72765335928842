import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_HINZUFUEGEN_MULTI = '[VeranstaltungenModul] PostFunktionsbereichHinzufuegenMulti laden';
export const POST_FUNKTIONSBEREICH_HINZUFUEGEN_MULTI_AUSFUEHREN = '[VeranstaltungenModul] PostFunktionsbereichHinzufuegenMulti ausführen';
export const POST_FUNKTIONSBEREICH_HINZUFUEGEN_MULTI_ERFOLGREICH = '[VeranstaltungenModul] PostFunktionsbereichHinzufuegenMulti laden erfolgreich';
export const POST_FUNKTIONSBEREICH_HINZUFUEGEN_MULTI_FEHLER = '[VeranstaltungenModul] PostFunktionsbereichHinzufuegenMulti Ladefehler';
export const POST_FUNKTIONSBEREICH_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFunktionsbereichHinzufuegenMulti nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichHinzufuegenMultiAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_HINZUFUEGEN_MULTI;
        constructor(public funktionsbereichHinzufuegenMultiRequest: api.FunktionsbereichHinzufuegenMultiRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichHinzufuegenMultiAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_HINZUFUEGEN_MULTI_AUSFUEHREN;
        constructor(public funktionsbereichHinzufuegenMultiRequest: api.FunktionsbereichHinzufuegenMultiRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichHinzufuegenMultiErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_HINZUFUEGEN_MULTI_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funktionsbereichHinzufuegenMultiRequest: api.FunktionsbereichHinzufuegenMultiRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichHinzufuegenMultiFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_HINZUFUEGEN_MULTI_FEHLER;
        constructor(public payload: any, public funktionsbereichHinzufuegenMultiRequest: api.FunktionsbereichHinzufuegenMultiRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichHinzufuegenMultiNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFunktionsbereichHinzufuegenMultiActions =
    PostFunktionsbereichHinzufuegenMultiAction
    | PostFunktionsbereichHinzufuegenMultiAusfuehrenAction
    | PostFunktionsbereichHinzufuegenMultiErfolgreichAction
    | PostFunktionsbereichHinzufuegenMultiFehlerAction
    | PostFunktionsbereichHinzufuegenMultiNichtAusgefuehrtAction
    ;
