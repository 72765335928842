import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/Observable';
import {Action, select, Store} from '@ngrx/store';
import {filter, flatMap, map, withLatestFrom} from 'rxjs/operators';
import {UuidService} from '@orga-app/providers/uuid.service';
import {INCIDENT_SPEICHERN, IncidentSpeichernAction, PAGE_STATE_RESET, PageStateResetAction, SETZE_INCIDENT_DETAIL_ID, SetzeIncidentDetailIdAction} from './incident.actions.ui';
import {getIncidentDetail, getSelectedIncidentId} from './incident.selectors';
import {GetIncidentDetailAction, PostIncidentAnlegen2Action, PostIncidentTitelUndBeschreibungSetzenAction} from '../../../client-ngrx/incidents';
import {IncidentAnlegen2Request, IncidentBeschreibungRequest, IncidentDetail} from '../../../clients/incidents';
import {NotificationService} from '@orga-app/providers/notification.service';
import {getAktuelleVeranstaltung} from '../app-state/app-state.reducer';
import {Veranstaltung} from '../../../clients/veranstaltungen';

@Injectable()
export class IncidentPageUiEffects {

    @Effect()
    pageNeuLaden$: Observable<Action> = this.actions$.pipe(
        ofType<PageStateResetAction>(PAGE_STATE_RESET),
        withLatestFrom(this.store$.pipe(select(getSelectedIncidentId))),
        filter(([x, incidentId]) => incidentId !== null && incidentId !== ''),
        flatMap(([x, incidentId]) =>
            [
                new GetIncidentDetailAction(incidentId)
            ]
        )
    );

    @Effect()
    datenLaden$: Observable<Action> = this.actions$.pipe(
        ofType<SetzeIncidentDetailIdAction>(SETZE_INCIDENT_DETAIL_ID),
        filter((x) => x.payload !== null && x.payload !== ''),
        flatMap((x) =>
            [
                new GetIncidentDetailAction(x.payload)
            ]
        )
    );

    @Effect()
    incidentSpeichern$: Observable<Action> = this.actions$.pipe(
        ofType<IncidentSpeichernAction>(INCIDENT_SPEICHERN),
        withLatestFrom(this.store$.pipe(select(getIncidentDetail)),
            this.store$.pipe(select(getAktuelleVeranstaltung))),
        filter(([x, detail, veranstaltung]) => {
            if (detail.titel === null || detail.titel === '') {
                this.notificationService.showMessageWithOk('Bitte einen Titel angeben. Sonst kann der Incident nicht gespeichert werden.');
                return false;
            }
            return true;
        }),
        map(([x, detail, veranstaltung]: [IncidentSpeichernAction, IncidentDetail, Veranstaltung]) => {
            let incidentId = detail.incident_id;
            if (incidentId === undefined || incidentId === null) {
                // Neu
                incidentId = this.uuid.generateUUID();

                const request: IncidentAnlegen2Request = {
                    incident_id: incidentId,
                    zeitpunkt_utc: new Date(),
                    anlegen_request: {
                        incident_id: incidentId,
                        beschreibung: detail.beschreibung,
                        ort: detail.ort,
                        veranstaltung_id: veranstaltung.veranstaltung_id,
                        zeitpunkt_utc: new Date(),
                        titel: detail.titel
                    }
                };
                if (detail.ist_verantwortlichkeit_abteilung || detail.ist_verantwortlichkeit_zentrale) {
                    request.verantwortlichkeit_request = {
                        incident_id: incidentId,
                        verantwortlichkeit_funktionsbereich_id: detail.verantwortlichkeit_funktionsbereich_id,
                        zeitpunkt_utc: new Date()
                    };
                }
                if (detail.delegation_rolle !== undefined && detail.delegation_rolle !== null) {
                    request.delegation_request = {
                        incident_id: incidentId,
                        delegation_rolle_id: detail.delegation_rolle_id,
                        delegation_funktionsbereich_id: detail.delegation_funktionsbereich_id,
                        zeitpunkt_utc: new Date()
                    };
                }
                // console.log('Request: ', request);
                return new PostIncidentAnlegen2Action(request);
            } else {
                // Bearbeiten
                const request: IncidentBeschreibungRequest = {
                    incident_id: incidentId,
                    beschreibung: detail.beschreibung,
                    titel: detail.titel,
                    ort: detail.ort,
                    veranstaltung_id: detail.veranstaltung_id,
                    zeitpunkt_utc: new Date(),
                    bilder_upload_ids: detail.bilder_ids
                };
                return new PostIncidentTitelUndBeschreibungSetzenAction(request);
            }
        })
    );

    constructor(private actions$: Actions,
                private uuid: UuidService,
                private store$: Store<any>,
                private notificationService: NotificationService) {
    }
}
