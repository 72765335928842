import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_ROLLE_VERANSTALTUNG_HOME_SICHTBARKEIT_FESTLEGEN = '[BenutzerverwaltungModul] PostRolleVeranstaltungHomeSichtbarkeitFestlegen laden';
export const POST_ROLLE_VERANSTALTUNG_HOME_SICHTBARKEIT_FESTLEGEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostRolleVeranstaltungHomeSichtbarkeitFestlegen ausführen';
export const POST_ROLLE_VERANSTALTUNG_HOME_SICHTBARKEIT_FESTLEGEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostRolleVeranstaltungHomeSichtbarkeitFestlegen laden erfolgreich';
export const POST_ROLLE_VERANSTALTUNG_HOME_SICHTBARKEIT_FESTLEGEN_FEHLER = '[BenutzerverwaltungModul] PostRolleVeranstaltungHomeSichtbarkeitFestlegen Ladefehler';
export const POST_ROLLE_VERANSTALTUNG_HOME_SICHTBARKEIT_FESTLEGEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostRolleVeranstaltungHomeSichtbarkeitFestlegen nicht ausgeführt (evtl. Offline)';



export class PostRolleVeranstaltungHomeSichtbarkeitFestlegenAction implements Action {
    readonly type = POST_ROLLE_VERANSTALTUNG_HOME_SICHTBARKEIT_FESTLEGEN;
        constructor(public rolleVeranstaltungHomeSichtbarkeitFestlegenRequest: api.RolleVeranstaltungHomeSichtbarkeitFestlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleVeranstaltungHomeSichtbarkeitFestlegenAusfuehrenAction implements Action {
    readonly type = POST_ROLLE_VERANSTALTUNG_HOME_SICHTBARKEIT_FESTLEGEN_AUSFUEHREN;
        constructor(public rolleVeranstaltungHomeSichtbarkeitFestlegenRequest: api.RolleVeranstaltungHomeSichtbarkeitFestlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleVeranstaltungHomeSichtbarkeitFestlegenErfolgreichAction implements Action {
    readonly type = POST_ROLLE_VERANSTALTUNG_HOME_SICHTBARKEIT_FESTLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public rolleVeranstaltungHomeSichtbarkeitFestlegenRequest: api.RolleVeranstaltungHomeSichtbarkeitFestlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleVeranstaltungHomeSichtbarkeitFestlegenFehlerAction implements Action {
    readonly type = POST_ROLLE_VERANSTALTUNG_HOME_SICHTBARKEIT_FESTLEGEN_FEHLER;
        constructor(public payload: any, public rolleVeranstaltungHomeSichtbarkeitFestlegenRequest: api.RolleVeranstaltungHomeSichtbarkeitFestlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleVeranstaltungHomeSichtbarkeitFestlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_ROLLE_VERANSTALTUNG_HOME_SICHTBARKEIT_FESTLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandRolleVeranstaltungHomeSichtbarkeitFestlegenActions =
    PostRolleVeranstaltungHomeSichtbarkeitFestlegenAction
    | PostRolleVeranstaltungHomeSichtbarkeitFestlegenAusfuehrenAction
    | PostRolleVeranstaltungHomeSichtbarkeitFestlegenErfolgreichAction
    | PostRolleVeranstaltungHomeSichtbarkeitFestlegenFehlerAction
    | PostRolleVeranstaltungHomeSichtbarkeitFestlegenNichtAusgefuehrtAction
    ;
