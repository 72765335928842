import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';
import * as actionsUi from './qschecks-controlling-saison-filter.actions.ui';
import {PageState} from './qschecks-controlling-saison-filter.reducer';
import {GetFunktionsbereichePruefplaeneControllingAction, GetPruefplanSaisonFilterAction} from '../../../../client-ngrx/qschecks';


@Injectable()
export class QschecksControllingSaisonFilterPageUiEffects {

    @Effect()
    pageReset$: Observable<Action> = this.actions$.pipe(
        ofType(actionsUi.PAGE_STATE_RESET, actionsUi.REFRESH_DATA, actionsUi.FUNKTIONSBEREICH_PRUEFPLAENE_LADEN)
        , map((x) => {
            return new GetFunktionsbereichePruefplaeneControllingAction();
        })
    );

    @Effect()
    pruefplanSelected$: Observable<Action> = this.actions$.pipe(
        ofType<actionsUi.AktuellenPruefplanSetzenAction>(actionsUi.AKTUELLEN_PRUEFPLAN_SETZEN)
        , filter((x) => x.payload !== null)
        , map((x) => {
            return new GetPruefplanSaisonFilterAction(x.payload.pruefplan_id);
        })
    );

    constructor(private actions$: Actions,
                private store: Store<PageState>) {
    }
}
