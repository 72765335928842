import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_ENTFERNEN = '[VorlagenModul] PostChecklisteVeranstaltungsTypenEntfernen laden';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_ENTFERNEN_AUSFUEHREN = '[VorlagenModul] PostChecklisteVeranstaltungsTypenEntfernen ausführen';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_ENTFERNEN_ERFOLGREICH = '[VorlagenModul] PostChecklisteVeranstaltungsTypenEntfernen laden erfolgreich';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_ENTFERNEN_FEHLER = '[VorlagenModul] PostChecklisteVeranstaltungsTypenEntfernen Ladefehler';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostChecklisteVeranstaltungsTypenEntfernen nicht ausgeführt (evtl. Offline)';



export class PostChecklisteVeranstaltungsTypenEntfernenAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_ENTFERNEN;
        constructor(public checklisteVeranstaltungsTypMultiRequest: api.ChecklisteVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenEntfernenAusfuehrenAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_ENTFERNEN_AUSFUEHREN;
        constructor(public checklisteVeranstaltungsTypMultiRequest: api.ChecklisteVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenEntfernenErfolgreichAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public checklisteVeranstaltungsTypMultiRequest: api.ChecklisteVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenEntfernenFehlerAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_ENTFERNEN_FEHLER;
        constructor(public payload: any, public checklisteVeranstaltungsTypMultiRequest: api.ChecklisteVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandChecklisteVeranstaltungsTypenEntfernenActions =
    PostChecklisteVeranstaltungsTypenEntfernenAction
    | PostChecklisteVeranstaltungsTypenEntfernenAusfuehrenAction
    | PostChecklisteVeranstaltungsTypenEntfernenErfolgreichAction
    | PostChecklisteVeranstaltungsTypenEntfernenFehlerAction
    | PostChecklisteVeranstaltungsTypenEntfernenNichtAusgefuehrtAction
    ;
