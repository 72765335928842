export namespace BenutzerverwaltungEntityTypes {
    export const BENUTZER_ENTITY = 'BV.BEN';
    export const BENUTZER_ENTITY_PART_NUTZUNGS_BEDINGUNGEN = 'NUTZB';
    export const BENUTZER_ENTITY_PART_PUSH_NOTIFICATION = 'PUSH';
    export const FUNKTIONSBEREICH_ENTITY = 'BV.FB';
    export const FUNKTIONSBEREICH_ENTITY_PART_ADMIN_BENUTZER = 'ADMINS';
    export const FUNKTIONSBEREICH_ENTITY_PART_FREIGABE_ROLLEN = 'FREIGROLLEN';
    export const FUNKTIONSBEREICH_ENTITY_PART_DETAIL = 'DETAIL';
    export const ROLLE_ENTITY = 'BV.ROL';
    export const ROLLE_ENTITY_PART_DETAIL = 'DETAIL';
    export const ROLLE_ENTITY_PART_BENUTZER = 'BENUTZER';
    export const SCANNERLOG_ENTITY = 'BENUTZERV.SCANNERLOG';
    
}

