import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_MULTI = '[VorlagenModul] PostDokumentBerechtigungHinzufuegenMulti laden';
export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_MULTI_AUSFUEHREN = '[VorlagenModul] PostDokumentBerechtigungHinzufuegenMulti ausführen';
export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_MULTI_ERFOLGREICH = '[VorlagenModul] PostDokumentBerechtigungHinzufuegenMulti laden erfolgreich';
export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_MULTI_FEHLER = '[VorlagenModul] PostDokumentBerechtigungHinzufuegenMulti Ladefehler';
export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentBerechtigungHinzufuegenMulti nicht ausgeführt (evtl. Offline)';



export class PostDokumentBerechtigungHinzufuegenMultiAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_MULTI;
        constructor(public dokumentBerechtigungRolleMultiRequest: api.DokumentBerechtigungRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungHinzufuegenMultiAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_MULTI_AUSFUEHREN;
        constructor(public dokumentBerechtigungRolleMultiRequest: api.DokumentBerechtigungRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungHinzufuegenMultiErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_MULTI_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungRolleMultiRequest: api.DokumentBerechtigungRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungHinzufuegenMultiFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_MULTI_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungRolleMultiRequest: api.DokumentBerechtigungRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungHinzufuegenMultiNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentBerechtigungHinzufuegenMultiActions =
    PostDokumentBerechtigungHinzufuegenMultiAction
    | PostDokumentBerechtigungHinzufuegenMultiAusfuehrenAction
    | PostDokumentBerechtigungHinzufuegenMultiErfolgreichAction
    | PostDokumentBerechtigungHinzufuegenMultiFehlerAction
    | PostDokumentBerechtigungHinzufuegenMultiNichtAusgefuehrtAction
    ;
