import {Component, OnInit} from '@angular/core';
import {ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {select, Store} from '@ngrx/store';
import {getMandantStammOptions} from '@orga-app/mandant/reducer';
import {Observable} from 'rxjs/Observable';
import {MandantStammOptions} from '../../clients/api';
import {AppState} from '../reducers';

@Component({
    selector: 'app-impressum',
    templateUrl: './impressum.page.html',
    styleUrls: ['./impressum.page.scss'],
})
export class ImpressumPage implements OnInit {
    private aiName = 'ImpressumPage';

    // MANDANTEN OPTIONEN
    mandantOptions$: Observable<MandantStammOptions>;

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private store: Store<AppState>,
                public platform: Platform,
                private modalCtrl: ModalController) {

    }


    ngOnInit() {
        // MANDANTEN OPTIONEN
        this.mandantOptions$ = this.store.pipe(select(getMandantStammOptions));
    }

    ok() {

        this.modalCtrl.dismiss(true);
    }

    abbrechen() {
        this.modalCtrl.dismiss();
    }

}
