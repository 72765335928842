import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {ChecklisteEntfernenPage} from './checkliste-entfernen.page';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {SopiComponentsModule} from '../../shared/sopi-components/sopi-components.module';

const routes: Routes = [
    {
        path: '',
        component: ChecklisteEntfernenPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        SopiScrollModule,
        SopiComponentsModule.forRoot()
    ],
    declarations: [ChecklisteEntfernenPage],
    entryComponents: [
        ChecklisteEntfernenPage
    ]
})
export class ChecklisteEntfernenPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: ChecklisteEntfernenPageModule,
        };
    }
}
