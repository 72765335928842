import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AdminCommandService } from './api/adminCommand.service';
import { AdminQueryService } from './api/adminQuery.service';
import { ControllingCommandService } from './api/controllingCommand.service';
import { ControllingQueryService } from './api/controllingQuery.service';
import { PrueflaufCommandService } from './api/prueflaufCommand.service';
import { PrueflaufQueryService } from './api/prueflaufQuery.service';
import { QsChecksQueryService } from './api/qsChecksQuery.service';
import { QsChecksTemplatesService } from './api/qsChecksTemplates.service';
import { RollenCommandService } from './api/rollenCommand.service';
import { RollenQueryService } from './api/rollenQuery.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AdminCommandService,
    AdminQueryService,
    ControllingCommandService,
    ControllingQueryService,
    PrueflaufCommandService,
    PrueflaufQueryService,
    QsChecksQueryService,
    QsChecksTemplatesService,
    RollenCommandService,
    RollenQueryService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
