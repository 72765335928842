import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENT_DELEGATION_NOTIFICATION = '[IncidentsModul] GetIncidentDelegationNotification laden';
export const GET_INCIDENT_DELEGATION_NOTIFICATION_AUSFUEHREN = '[IncidentsModul] GetIncidentDelegationNotification ausführen';
export const GET_INCIDENT_DELEGATION_NOTIFICATION_ERFOLGREICH = '[IncidentsModul] GetIncidentDelegationNotification laden erfolgreich';
export const GET_INCIDENT_DELEGATION_NOTIFICATION_FEHLER = '[IncidentsModul] GetIncidentDelegationNotification Ladefehler';
export const GET_INCIDENT_DELEGATION_NOTIFICATION_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentDelegationNotification nicht ausgeführt (evtl. Offline)';



export class GetIncidentDelegationNotificationAction implements Action {
    readonly type = GET_INCIDENT_DELEGATION_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentDelegationNotificationAusfuehrenAction implements Action {
    readonly type = GET_INCIDENT_DELEGATION_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentDelegationNotificationErfolgreichAction implements Action {
    readonly type = GET_INCIDENT_DELEGATION_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.IncidentDelegationNotification, public optPayload: any = null) {}
    }
export class GetIncidentDelegationNotificationFehlerAction implements Action {
    readonly type = GET_INCIDENT_DELEGATION_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetIncidentDelegationNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENT_DELEGATION_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentDelegationNotificationActions =
    GetIncidentDelegationNotificationAction
    | GetIncidentDelegationNotificationAusfuehrenAction
    | GetIncidentDelegationNotificationErfolgreichAction
    | GetIncidentDelegationNotificationFehlerAction
    | GetIncidentDelegationNotificationNichtAusgefuehrtAction
    ;
