import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGEN_ALLE = '[VeranstaltungenModul] GetVeranstaltungenAlle laden';
export const GET_VERANSTALTUNGEN_ALLE_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungenAlle ausführen';
export const GET_VERANSTALTUNGEN_ALLE_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungenAlle laden erfolgreich';
export const GET_VERANSTALTUNGEN_ALLE_FEHLER = '[VeranstaltungenModul] GetVeranstaltungenAlle Ladefehler';
export const GET_VERANSTALTUNGEN_ALLE_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungenAlle nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungenAlleAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ALLE;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenAlleAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ALLE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenAlleErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ALLE_ERFOLGREICH;
        constructor(public payload: Array<api.Veranstaltung>, public optPayload: any = null) {}
    }
export class GetVeranstaltungenAlleFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ALLE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungenAlleNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ALLE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungenAlleActions =
    GetVeranstaltungenAlleAction
    | GetVeranstaltungenAlleAusfuehrenAction
    | GetVeranstaltungenAlleErfolgreichAction
    | GetVeranstaltungenAlleFehlerAction
    | GetVeranstaltungenAlleNichtAusgefuehrtAction
    ;
