import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, LoginOptions, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, last, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as authActions from './actions';
import { AuthTypes, MyAuthOptions } from './variables';

export interface MyAuthError {
  error: string;
  error_description: string;
  event_type: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private lastProviderError = new BehaviorSubject<MyAuthError>(null);
  public lastProviderError$ = this.lastProviderError.asObservable();
  private silentRefreshSetupDone: boolean;

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private store: Store<any>,
    private config: MyAuthOptions
  ) {
    // Useful for debugging:
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error('OAuthService::err: type:', event.type, 'reason:', event.reason, 'parms:', JSON.stringify(event.params));

        let err: MyAuthError;

        if (event.params) {
          err = JSON.parse(JSON.stringify(event.params));
          err.event_type = event.type;
        } else {

          // der aad response steht entweder im params teil
          // oder die reason ist auch ein das OAuthErrorEvent, dann steht der aad response im params eine ebene tiefer
          if (event.params === null && event.reason !== null) {

            if (event.reason instanceof OAuthErrorEvent) {
              err = JSON.parse(JSON.stringify(event.reason.params));
              err.event_type = event.type;
            }
          } else {
            err = <MyAuthError>{
              event_type: event.type
            };
          }
        }

        this.lastProviderError.next(err);

        this.oauthService.stopAutomaticRefresh();

        let action: authActions.AuthErrorAction;
        if (err) {
          action = new authActions.AuthErrorAction(event.type, err.error, err.error_description);
        } else {
          action = new authActions.AuthErrorAction(event.type, '', JSON.stringify(event));
        }
        console.debug('authService: sending AuthErrorAction', action);
        this.store.dispatch(action);
        this.navigateToLoginPage();

      } else {
        console.debug('OAuthService::event: ', event.type, JSON.stringify(event));
      }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup.
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events
      .subscribe(_ => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      });

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type))
      // ,last()
      )
      .subscribe(e => {
        // this.oauthService.loadUserProfile();
        console.debug('authService: token_received: ', e.type);
        this.setupAutomaticSilentRefresh();
        // this.dispatchAuthLoginErfolgreichAction();
      });


    this.oauthService.events
      .pipe(filter(e => ['silently_refreshed'].includes(e.type))
      // ,last()
      )
      .subscribe(e => {
        // this.oauthService.loadUserProfile();
        console.debug('authService: silently_refreshed: ', e.type);
        console.debug('authService: sending RefreshTokenErfolgreichAction');
        this.store.dispatch(new authActions.RefreshTokenErfolgreichAction());
        this.dispatchAuthLoginErfolgreichAction();

      });

  }


  public get accessToken() {
    return this.oauthService.getAccessToken();
  }

  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }

  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  public get idToken() {
    return this.oauthService.getIdToken();
  }

  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }

  private config2: AuthConfig;

  public async initAuth(): Promise<boolean> {
    console.log('authService: start init');

    //   if (this.config.type == AuthTypes.aad1) {

    const c = this.getAuthConfig();
    this.config2 = c;

    this.oauthService.configure(c);


    const disco = await this.oauthService.loadDiscoveryDocument(this.getDiscoveryDocument());
    console.log('authService | initAuth: oauthService.loadDiscoveryDocument finished', disco.info);
    //  this.logoutUrl = disco.info['end_session_endpoint'];

    // 1. HASH LOGIN:
    //         // Try to log in via hash fragment after redirect back
    //         // from IdServer from initImplicitFlow:
    console.log('authService | initAuth: oauthService.tryLogin');
    const opt: LoginOptions = {
      // customRedirectUri
    };
    const l1 = await this.oauthService.tryLogin(opt);
    if (l1) {
      console.log('authService | initAuth: oauthService.tryLogin: result', l1);
    }

    const tokenValid = this.oauthService.hasValidAccessToken();
    console.log('authService | initAuth: oauthService.hasValidAccessToken: ', tokenValid);

    if (tokenValid) {

      console.debug('authService | initAuth: hasValidAccessToken');

      this.setupAutomaticSilentRefresh();
      this.dispatchAuthLoginErfolgreichAction();

      console.log('authService: initAuth | return');
      // this.debugStateUrl();

      return true;
    }


    // if (!this.refreshToken || this.refreshToken === null || this.refreshToken === undefined) {
    //     console.log('authService: initAuth | no refresh token | return');
    //     return false;
    // }

    // 2. SILENT LOGIN:
    // Try to log in via a refresh because then we can prevent
    // needing to redirect the user:
    console.log('authService | initAuth: oauthService.silentRefresh');
    try {

      const sil = await this.oauthService.silentRefresh();

      if (sil.type) {
        console.debug('authService | initAuth | silentRefresh: ok');
        // this.debugStateUrl();
        this.dispatchAuthLoginErfolgreichAction();

        return true;
        // this.debugStateUrl();

      } else {
        console.debug('authService | initAuth | silentRefresh: notok - return');
        return false;
      }
    } catch (result) {
      console.debug('authService | silentRefresh: error', result.reason);

      // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
      // Only the ones where it's reasonably sure that sending the
      // user to the IdServer will help.
      const errorResponsesRequiringUserInteraction = [
        'interaction_required',
        'login_required',
        'account_selection_required',
        'consent_required',
      ];

      if (result
        && result.reason
        && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

        // 3. ASK FOR LOGIN:
        // At this point we know for sure that we have to ask the
        // user to log in, so we redirect them to the IdServer to
        // enter credentials.
        //
        // Enable this to ALWAYS force a user to login.
        // this.oauthService.initImplicitFlow();
        //
        // Instead, we'll now do this:
        console.warn('authService: User interaction is needed to log in, we will wait for the user to manually log in.');
        return false;
        // return Promise.resolve();
      } else {
        throw new Error(result);
        // We can't handle the truth, just pass on the problem to the
        // next handler.
        // return Promise.reject(result);
      }

    }
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(targetUrl || this.router.url, this.getLoginArgs());
  }

  public logout() {
    this.oauthService.logOut();
  }

  public logout2() {

    const url = encodeURI(this.logoutUrl + '?post_logout_redirect_uri=' + this.config2.redirectUri);
    location.href = url;
  }

  public async refresh() {
    const refrep = this.getRefreshParams(this.oauthService.getIdentityClaims());
    console.debug('authService: refresh : setupAutomaticSilentRefresh refreshparams: ', refrep);
    await this.oauthService.silentRefresh(refrep);
  }

  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

 

  private setupAutomaticSilentRefresh() {

    if (!this.silentRefreshSetupDone) {
      
      const refrep = this.getRefreshParams(this.oauthService.getIdentityClaims());
      console.debug('authService: setupAutomaticSilentRefresh refreshparams: ', refrep);
      // this.oauthService.setupAutomaticSilentRefresh(refrep, 'access_token');
      this.oauthService.setupAutomaticSilentRefresh(refrep, 'access_token');
      this.silentRefreshSetupDone = true;
    }
  }

  private dispatchAuthLoginErfolgreichAction() {
    const userId = this.getUserId(this.oauthService.getIdentityClaims());
    console.log('authService: sending AuthLoginErfolgreichAction');
    this.store.dispatch(authActions.CreateAuthLoginErfolgreichAction(userId));
  }

  private async navigateToLoginPage() {
    // TODO: Remember current URL
    await this.router.navigateByUrl(this.config.loginRoute);
  }

  private debugStateUrl() {
    if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
      let stateUrl = this.oauthService.state;

      if (stateUrl.startsWith('/') === false) {
        stateUrl = decodeURIComponent(stateUrl);
      }
      console.log('authService: debugStateUrl', this.oauthService.state, stateUrl);
      // console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
      //  return Promise.resolve();

    } else {
      console.log('authService: debugStateUrl - no state');
    }
  }

  private getLoginArgs() {
    if (this.config.type === AuthTypes.aad1) {

      const a = {
        prompt: 'select_account'
      };

      return a;

    }

    if (this.config.type === AuthTypes.aad2) {

      const a = {
        prompt: 'select_account'
      };

      return a;

    }

    throw new Error('invalid config type: ' + this.config.type);
  }

  private getDiscoveryDocument(): string {
    if (this.config.type === AuthTypes.aad1) {

      console.debug('authService: DiscoveryDocument:', this.config.azureAd.discoveryDocumentV1);
      return this.config.azureAd.discoveryDocumentV1;

    }

    if (this.config.type === AuthTypes.aad2) {


      const p = window.location.hostname;

      if (this.config.tenantMappings && this.config.tenantMappings[p]) {
        const t = this.config.tenantMappings[p];
        const disc = 'https://login.microsoftonline.com/' + t + '/v2.0/.well-known/openid-configuration';
        console.debug('authService: DiscoveryDocument:', disc);
        return disc;
      } else {

        const disc = 'https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration';
        console.debug('authService: DiscoveryDocument:', disc);
        return disc;
      }

    }

    throw new Error('invalid config type: ' + this.config.type);
  }

  private getAuthConfig(): AuthConfig {

    // timeout factor for debug, refresh vor token laufzeit * faktor
    const timeoutFactor = 0.1;
    // const timeoutFactor = 0.01;

    if (this.config.type === AuthTypes.aad1) {

      const auth: AuthConfig = {
        redirectUri: this.config.redirectUri, // window.location.origin , //+ '/home',
        clientId: this.config.azureAd.clientId,
        scope: 'user_impersonation',
        showDebugInformation: this.config.debug,
        resource: this.config.azureAd.resource,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        customQueryParams: {
          //     'prompt': 'select_account'
          //  resource: appconfig.azureAd.resource
        },
        useSilentRefresh: true,
        silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
        // silentRefreshTimeout: 2500

      };

      if (this.config.redirectUriFromOrigin) {
        auth.redirectUri = window.location.origin + this.config.redirectUri;
      }

      if(this.config.debug) {
        console.debug('authService: set timeout factor', timeoutFactor);
        auth.timeoutFactor = timeoutFactor;
      }

      console.debug('authService: auth config:', auth);
      return auth;

    }

    if (this.config.type === AuthTypes.aad2) {
      const auth: AuthConfig = {
        redirectUri: this.config.redirectUri, // window.location.origin , //+ '/home',
        clientId: this.config.azureAd.clientId2,
        scope: 'openid profile offline_access ' + this.config.azureAd.resource + '/user_impersonation',
        showDebugInformation: this.config.debug,
        responseType: 'code',
        // resource: this.config.azureAd.resource,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        customQueryParams: {
          //     'prompt': 'select_account'
          //  resource: appconfig.azureAd.resource
        },
        useSilentRefresh: true,
        silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
        // silentRefreshTimeout: 2500

      };

      if(this.config.debug) {
        console.debug('authService: set timeout factor', timeoutFactor);
        auth.timeoutFactor = timeoutFactor;
      }

      if (this.config.redirectUriFromOrigin) {
        auth.redirectUri = window.location.origin + this.config.redirectUri;
      }


      console.debug('authService: auth config:', auth);
      return auth;

    }

    throw new Error('invalid config type: ' + this.config.type);
  }

  private getUserId(identityClaims: object): string {

    if (identityClaims !== null && identityClaims !== undefined) {

      const email = identityClaims['email'];
      console.debug('authService: getUserId: identityClaims.email', email);

      if (email !== null && email !== undefined) {
        return email;
      }

      const unique_name = identityClaims['unique_name'];
      console.debug('authService: getUserId: identityClaims.unique_name', unique_name);

      if (unique_name !== null && unique_name !== undefined) {
        return unique_name;
      }

      const preferred_username = identityClaims['preferred_username'];
      console.debug('authService: getRefreshParams: identityClaims.preferred_username', preferred_username);

      if (preferred_username !== null && preferred_username !== undefined) {
        return preferred_username;
      }
    }

    return null;
  }

  private getRefreshParams(identityClaims: object): object {
    const params: object = {};

    if (identityClaims !== null && identityClaims !== undefined) {
      console.debug('authService: getRefreshParams: identityClaims', identityClaims);

      const lhint = this.getUserId(identityClaims);

      if (lhint !== null && lhint !== undefined) {
        console.debug('authService: getRefreshParams: login_hint', lhint);
        params['login_hint'] = lhint;
      }
    }

    return params;
  }

}
