import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { EinlasskontrolleService } from './api/einlasskontrolle.service';
import { EinlasskontrolleCommandService } from './api/einlasskontrolleCommand.service';
import { EinlasskontrolleQueryService } from './api/einlasskontrolleQuery.service';
import { EinlasskontrolleSqlService } from './api/einlasskontrolleSql.service';
import { EinlasskontrolleTemplatesService } from './api/einlasskontrolleTemplates.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    EinlasskontrolleService,
    EinlasskontrolleCommandService,
    EinlasskontrolleQueryService,
    EinlasskontrolleSqlService,
    EinlasskontrolleTemplatesService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
