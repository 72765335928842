import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {filter, flatMap, map, withLatestFrom} from 'rxjs/operators';
import {
    GetVeranstaltungBerechtigungAction,
    GetVeranstaltungDeckblattAction, GetVeranstaltungMitteilungBerechtigungAction, GetVeranstaltungStatusAction
} from '../../../../client-ngrx/veranstaltungen';
import {Funktionsbereich, Rolle, Veranstaltung} from '../../../../clients/veranstaltungen';
import {WS_READ_MODEL_UPDATED, WsReadModelUpdatedAction} from '../../../websocket.actions';
import {VeranstaltungenEntityTypes} from '../../../../client-constants';
import {getAktuelleVeranstaltung} from '../app-state.reducer';
import {GetVeranstaltungenAktuelleAction} from '../../../../client-ngrx/veranstaltungen';
import VERANSTALTUNG_ENTITY = VeranstaltungenEntityTypes.VERANSTALTUNG_ENTITY;
import VERANSTALTUNG_ENTITY_PART_STATUS = VeranstaltungenEntityTypes.VERANSTALTUNG_ENTITY_PART_STATUS;
// import DOKUMENT_ROLLE_BERECHTIGUNG = VeranstaltungenEntityTypes.DOKUMENT_ROLLE_BERECHTIGUNG;
import ROLLE_ENTITY = VeranstaltungenEntityTypes.ROLLE_ENTITY;
import FUNKTIONSBEREICH_ENTITY = VeranstaltungenEntityTypes.FUNKTIONSBEREICH_ENTITY;
import FUNKTIONSBEREICH_ENTITY_PART_BERECHTIGUNG = VeranstaltungenEntityTypes.FUNKTIONSBEREICH_ENTITY_PART_BERECHTIGUNG;
import DOKUMENT_ENTITY = VeranstaltungenEntityTypes.DOKUMENT_ENTITY;
import DOKUMENT_ENTITY_PART_BERECHTIGUNG = VeranstaltungenEntityTypes.DOKUMENT_ENTITY_PART_BERECHTIGUNG;
import ROLLE_ENTITY_PART_MITTEILUNG_OPTIONEN = VeranstaltungenEntityTypes.ROLLE_ENTITY_PART_MITTEILUNG_OPTIONEN;
import CHECKLISTE_ENTITY = VeranstaltungenEntityTypes.CHECKLISTE_ENTITY;
import CHECKLISTE_ENTITY_PART_BERECHTIGUNG = VeranstaltungenEntityTypes.CHECKLISTE_ENTITY_PART_BERECHTIGUNG;
import VERANSTALTUNG_ENTITY_PART_BERECHTIGUNG = VeranstaltungenEntityTypes.VERANSTALTUNG_ENTITY_PART_BERECHTIGUNG;


@Injectable()
export class AppStateWsEffects {

    @Effect()
    wsVeranstaltung$: Observable<Action> = this.actions$.pipe(
        ofType<WsReadModelUpdatedAction>(WS_READ_MODEL_UPDATED),
        filter((x: WsReadModelUpdatedAction) => {
            return x.message.entity_type.toUpperCase() === VERANSTALTUNG_ENTITY;
        }),
        map((x) => <Veranstaltung>JSON.parse(x.message.entity)),
        withLatestFrom(this.store$.pipe(select(getAktuelleVeranstaltung))),
        filter(([x, aktuelleVeranstaltung]: [Veranstaltung, Veranstaltung]) => aktuelleVeranstaltung !== null),
        filter(([x, aktuelleVeranstaltung]: [Veranstaltung, Veranstaltung]) => aktuelleVeranstaltung.veranstaltung_id === x.veranstaltung_id),
        flatMap(([x, aktuelleVeranstaltung]: [Veranstaltung, Veranstaltung]) =>
            [
                new GetVeranstaltungenAktuelleAction(),
                new GetVeranstaltungDeckblattAction(aktuelleVeranstaltung.veranstaltung_id),
                new GetVeranstaltungBerechtigungAction(aktuelleVeranstaltung.veranstaltung_id),
                new GetVeranstaltungStatusAction(aktuelleVeranstaltung.veranstaltung_id)
            ])
    );

/*    @Effect()
    wsVeranstaltungDetail$: Observable<Action> = this.actions$.pipe(
        ofType<WsReadModelUpdatedAction>(WS_READ_MODEL_UPDATED),
        filter((x: WsReadModelUpdatedAction) => {
            return x.message.entity_type.toUpperCase() === VERANSTALTUNG_ENTITY;
        }),
        map((x) => <Veranstaltung>JSON.parse(x.message.entity)),
        withLatestFrom(this.store$.pipe(select(getAktuelleVeranstaltung))),
        filter(([x, aktuelleVeranstaltung]: [Veranstaltung, Veranstaltung]) => aktuelleVeranstaltung !== null),
        filter(([x, aktuelleVeranstaltung]: [Veranstaltung, Veranstaltung]) => aktuelleVeranstaltung.veranstaltung_id === x.veranstaltung_id),
        flatMap(([x, aktuelleVeranstaltung]: [Veranstaltung, Veranstaltung]) =>
            [
                new GetVeranstaltungenAktuelleAction(),
                new GetVeranstaltungDeckblattAction(aktuelleVeranstaltung.veranstaltung_id),
                new GetVeranstaltungBerechtigungAction(aktuelleVeranstaltung.veranstaltung_id),
                new GetVeranstaltungStatusAction(aktuelleVeranstaltung.veranstaltung_id)
            ])
    );*/

    @Effect()
    wsVeranstaltungStatus$: Observable<Action> = this.actions$.pipe(
        ofType<WsReadModelUpdatedAction>(WS_READ_MODEL_UPDATED),
        filter((x: WsReadModelUpdatedAction) => {
            return x.message.entity_type.toUpperCase() === VERANSTALTUNG_ENTITY && x.message.entity_part.toUpperCase() === VERANSTALTUNG_ENTITY_PART_STATUS;
        }),
        map((x) => <Veranstaltung>JSON.parse(x.message.entity)),
        withLatestFrom(this.store$.pipe(select(getAktuelleVeranstaltung))),
        filter(([x, aktuelleVeranstaltung]: [Veranstaltung, Veranstaltung]) => aktuelleVeranstaltung !== null),
        filter(([x, aktuelleVeranstaltung]: [Veranstaltung, Veranstaltung]) => aktuelleVeranstaltung.veranstaltung_id === x.veranstaltung_id),
        flatMap(([x, aktuelleVeranstaltung]: [Veranstaltung, Veranstaltung]) =>
            [
                new GetVeranstaltungDeckblattAction(aktuelleVeranstaltung.veranstaltung_id),
                new GetVeranstaltungStatusAction(aktuelleVeranstaltung.veranstaltung_id)
            ])
    );

    @Effect()
    wsVeranstaltungRolle$: Observable<Action> = this.actions$.pipe(
        ofType<WsReadModelUpdatedAction>(WS_READ_MODEL_UPDATED),
        filter((x: WsReadModelUpdatedAction) => {
            return x.message.entity_type.toUpperCase() === ROLLE_ENTITY;
        }),
        map((x) => <Rolle>JSON.parse(x.message.entity)),
        withLatestFrom(this.store$.pipe(select(getAktuelleVeranstaltung))),
        filter(([x, aktuelleVeranstaltung]: [Rolle, Veranstaltung]) => aktuelleVeranstaltung !== null),
        filter(([x, aktuelleVeranstaltung]: [Rolle, Veranstaltung]) => aktuelleVeranstaltung.veranstaltung_id === x.veranstaltung_id),
        flatMap(([x, aktuelleVeranstaltung]: [Rolle, Veranstaltung]) =>
            [
                new GetVeranstaltungDeckblattAction(aktuelleVeranstaltung.veranstaltung_id),
                new GetVeranstaltungStatusAction(aktuelleVeranstaltung.veranstaltung_id)
            ])
    );

    @Effect()
    wsVeranstaltungRolleMitteilungen$: Observable<Action> = this.actions$.pipe(
        ofType<WsReadModelUpdatedAction>(WS_READ_MODEL_UPDATED),
        filter((x: WsReadModelUpdatedAction) => {
            return x.message.entity_type.toUpperCase() === ROLLE_ENTITY && x.message.entity_part.toUpperCase() === ROLLE_ENTITY_PART_MITTEILUNG_OPTIONEN;
        }),
        map((x) => <Rolle>JSON.parse(x.message.entity)),
        withLatestFrom(this.store$.pipe(select(getAktuelleVeranstaltung))),
        filter(([x, aktuelleVeranstaltung]: [Rolle, Veranstaltung]) => aktuelleVeranstaltung !== null),
        filter(([x, aktuelleVeranstaltung]: [Rolle, Veranstaltung]) => aktuelleVeranstaltung.veranstaltung_id === x.veranstaltung_id),
        flatMap(([x, aktuelleVeranstaltung]: [Rolle, Veranstaltung]) =>
            [
                new GetVeranstaltungMitteilungBerechtigungAction(aktuelleVeranstaltung.veranstaltung_id)
            ])
    );

    @Effect()
    wsVeranstaltungRolleBerechtigung$: Observable<Action> = this.actions$.pipe(
        ofType<WsReadModelUpdatedAction>(WS_READ_MODEL_UPDATED),
        filter((x: WsReadModelUpdatedAction) => {
            return (x.message.entity_type.toUpperCase() === VERANSTALTUNG_ENTITY && x.message.entity_part.toUpperCase() === VERANSTALTUNG_ENTITY_PART_BERECHTIGUNG) ||
                (x.message.entity_type.toUpperCase() === CHECKLISTE_ENTITY && x.message.entity_part.toUpperCase() === CHECKLISTE_ENTITY_PART_BERECHTIGUNG) ||
                (x.message.entity_type.toUpperCase() === DOKUMENT_ENTITY && x.message.entity_part.toUpperCase() === DOKUMENT_ENTITY_PART_BERECHTIGUNG) ||
                    x.message.entity_type.toUpperCase() === ROLLE_ENTITY ||
                (x.message.entity_type.toUpperCase() === FUNKTIONSBEREICH_ENTITY && x.message.entity_part.toUpperCase() === FUNKTIONSBEREICH_ENTITY_PART_BERECHTIGUNG);
        }),
        map((x) => <Veranstaltung>JSON.parse(x.message.entity)),
        withLatestFrom(this.store$.pipe(select(getAktuelleVeranstaltung))),
        filter(([x, aktuelleVeranstaltung]: [Veranstaltung, Veranstaltung]) => aktuelleVeranstaltung !== null),
        filter(([x, aktuelleVeranstaltung]: [Veranstaltung, Veranstaltung]) => aktuelleVeranstaltung.veranstaltung_id === x.veranstaltung_id),
        flatMap(([x, aktuelleVeranstaltung]: [Veranstaltung, Veranstaltung]) =>
            [
                new GetVeranstaltungBerechtigungAction(aktuelleVeranstaltung.veranstaltung_id)
            ])
    );

    constructor(private actions$: Actions,
                private store$: Store<any>) {
    }
}
