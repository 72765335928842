import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_COMMAND_FAILED_NOTIFICATION = '[ApiModul] GetCommandFailedNotification laden';
export const GET_COMMAND_FAILED_NOTIFICATION_AUSFUEHREN = '[ApiModul] GetCommandFailedNotification ausführen';
export const GET_COMMAND_FAILED_NOTIFICATION_ERFOLGREICH = '[ApiModul] GetCommandFailedNotification laden erfolgreich';
export const GET_COMMAND_FAILED_NOTIFICATION_FEHLER = '[ApiModul] GetCommandFailedNotification Ladefehler';
export const GET_COMMAND_FAILED_NOTIFICATION_NICHT_AUSGEFUEHRT = '[ApiModul] GetCommandFailedNotification nicht ausgeführt (evtl. Offline)';



export class GetCommandFailedNotificationAction implements Action {
    readonly type = GET_COMMAND_FAILED_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetCommandFailedNotificationAusfuehrenAction implements Action {
    readonly type = GET_COMMAND_FAILED_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetCommandFailedNotificationErfolgreichAction implements Action {
    readonly type = GET_COMMAND_FAILED_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.CommandFailedNotification, public optPayload: any = null) {}
    }
export class GetCommandFailedNotificationFehlerAction implements Action {
    readonly type = GET_COMMAND_FAILED_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetCommandFailedNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_COMMAND_FAILED_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryCommandFailedNotificationActions =
    GetCommandFailedNotificationAction
    | GetCommandFailedNotificationAusfuehrenAction
    | GetCommandFailedNotificationErfolgreichAction
    | GetCommandFailedNotificationFehlerAction
    | GetCommandFailedNotificationNichtAusgefuehrtAction
    ;
