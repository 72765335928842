import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_LOGO_AKTUALISIEREN = '[VeranstaltungenModul] PostVeranstaltungLogoAktualisieren laden';
export const POST_VERANSTALTUNG_LOGO_AKTUALISIEREN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungLogoAktualisieren ausführen';
export const POST_VERANSTALTUNG_LOGO_AKTUALISIEREN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungLogoAktualisieren laden erfolgreich';
export const POST_VERANSTALTUNG_LOGO_AKTUALISIEREN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungLogoAktualisieren Ladefehler';
export const POST_VERANSTALTUNG_LOGO_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungLogoAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungLogoAktualisierenAction implements Action {
    readonly type = POST_VERANSTALTUNG_LOGO_AKTUALISIEREN;
        constructor(public veranstaltungLogoAktualisierenRequest: api.VeranstaltungLogoAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungLogoAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_LOGO_AKTUALISIEREN_AUSFUEHREN;
        constructor(public veranstaltungLogoAktualisierenRequest: api.VeranstaltungLogoAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungLogoAktualisierenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_LOGO_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungLogoAktualisierenRequest: api.VeranstaltungLogoAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungLogoAktualisierenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_LOGO_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public veranstaltungLogoAktualisierenRequest: api.VeranstaltungLogoAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungLogoAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_LOGO_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungLogoAktualisierenActions =
    PostVeranstaltungLogoAktualisierenAction
    | PostVeranstaltungLogoAktualisierenAusfuehrenAction
    | PostVeranstaltungLogoAktualisierenErfolgreichAction
    | PostVeranstaltungLogoAktualisierenFehlerAction
    | PostVeranstaltungLogoAktualisierenNichtAusgefuehrtAction
    ;
