

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_PRUEFBERICHT_PDF = '[VeranstaltungenModul] GetVeranstaltungPruefberichtPdf laden';
export const GET_VERANSTALTUNG_PRUEFBERICHT_PDF_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungPruefberichtPdf ausführen';
export const GET_VERANSTALTUNG_PRUEFBERICHT_PDF_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungPruefberichtPdf laden erfolgreich';
export const GET_VERANSTALTUNG_PRUEFBERICHT_PDF_FEHLER = '[VeranstaltungenModul] GetVeranstaltungPruefberichtPdf Ladefehler';
export const GET_VERANSTALTUNG_PRUEFBERICHT_PDF_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungPruefberichtPdf nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungPruefberichtPdfAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_PDF;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtPdfAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_PDF_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtPdfErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_PDF_ERFOLGREICH;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtPdfFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_PDF_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtPdfNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_PDF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungPruefberichtPdfActions =
    GetVeranstaltungPruefberichtPdfAction
    | GetVeranstaltungPruefberichtPdfAusfuehrenAction
    | GetVeranstaltungPruefberichtPdfErfolgreichAction
    | GetVeranstaltungPruefberichtPdfFehlerAction
    | GetVeranstaltungPruefberichtPdfNichtAusgefuehrtAction
    ;
