import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AbteilungQueryService } from './api/abteilungQuery.service';
import { IncidentsCommandService } from './api/incidentsCommand.service';
import { IncidentsQueryService } from './api/incidentsQuery.service';
import { IncidentsReportingService } from './api/incidentsReporting.service';
import { IncidentsReportingTemplatesService } from './api/incidentsReportingTemplates.service';
import { IncidentsTemplatesService } from './api/incidentsTemplates.service';
import { ZentraleQueryService } from './api/zentraleQuery.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AbteilungQueryService,
    IncidentsCommandService,
    IncidentsQueryService,
    IncidentsReportingService,
    IncidentsReportingTemplatesService,
    IncidentsTemplatesService,
    ZentraleQueryService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
