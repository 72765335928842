import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFPLAN_ANTWORT_ADMIN = '[QschecksModul] GetPruefplanAntwortAdmin laden';
export const GET_PRUEFPLAN_ANTWORT_ADMIN_AUSFUEHREN = '[QschecksModul] GetPruefplanAntwortAdmin ausführen';
export const GET_PRUEFPLAN_ANTWORT_ADMIN_ERFOLGREICH = '[QschecksModul] GetPruefplanAntwortAdmin laden erfolgreich';
export const GET_PRUEFPLAN_ANTWORT_ADMIN_FEHLER = '[QschecksModul] GetPruefplanAntwortAdmin Ladefehler';
export const GET_PRUEFPLAN_ANTWORT_ADMIN_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPruefplanAntwortAdmin nicht ausgeführt (evtl. Offline)';



export class GetPruefplanAntwortAdminAction implements Action {
    readonly type = GET_PRUEFPLAN_ANTWORT_ADMIN;
        constructor( public pruefplan_id:string ,  public antwort_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanAntwortAdminAusfuehrenAction implements Action {
    readonly type = GET_PRUEFPLAN_ANTWORT_ADMIN_AUSFUEHREN;
        constructor( public pruefplan_id:string ,  public antwort_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanAntwortAdminErfolgreichAction implements Action {
    readonly type = GET_PRUEFPLAN_ANTWORT_ADMIN_ERFOLGREICH;
        constructor(public payload: api.PruefantwortDetail,  public pruefplan_id:string ,  public antwort_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanAntwortAdminFehlerAction implements Action {
    readonly type = GET_PRUEFPLAN_ANTWORT_ADMIN_FEHLER;
        constructor(public payload: any,  public pruefplan_id:string ,  public antwort_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanAntwortAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFPLAN_ANTWORT_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPruefplanAntwortAdminActions =
    GetPruefplanAntwortAdminAction
    | GetPruefplanAntwortAdminAusfuehrenAction
    | GetPruefplanAntwortAdminErfolgreichAction
    | GetPruefplanAntwortAdminFehlerAction
    | GetPruefplanAntwortAdminNichtAusgefuehrtAction
    ;
