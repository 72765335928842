import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFCHECKLISTE_AKTUALISIEREN = '[QschecksModul] PostPruefchecklisteAktualisieren laden';
export const POST_PRUEFCHECKLISTE_AKTUALISIEREN_AUSFUEHREN = '[QschecksModul] PostPruefchecklisteAktualisieren ausführen';
export const POST_PRUEFCHECKLISTE_AKTUALISIEREN_ERFOLGREICH = '[QschecksModul] PostPruefchecklisteAktualisieren laden erfolgreich';
export const POST_PRUEFCHECKLISTE_AKTUALISIEREN_FEHLER = '[QschecksModul] PostPruefchecklisteAktualisieren Ladefehler';
export const POST_PRUEFCHECKLISTE_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefchecklisteAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostPruefchecklisteAktualisierenAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_AKTUALISIEREN;
        constructor(public pruefchecklisteModelRequest: api.PruefchecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_AKTUALISIEREN_AUSFUEHREN;
        constructor(public pruefchecklisteModelRequest: api.PruefchecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteAktualisierenErfolgreichAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefchecklisteModelRequest: api.PruefchecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteAktualisierenFehlerAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public pruefchecklisteModelRequest: api.PruefchecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefchecklisteAktualisierenActions =
    PostPruefchecklisteAktualisierenAction
    | PostPruefchecklisteAktualisierenAusfuehrenAction
    | PostPruefchecklisteAktualisierenErfolgreichAction
    | PostPruefchecklisteAktualisierenFehlerAction
    | PostPruefchecklisteAktualisierenNichtAusgefuehrtAction
    ;
