import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_LOESCHEN = '[BenutzerverwaltungModul] PostFunktionsbereichLoeschen laden';
export const POST_FUNKTIONSBEREICH_LOESCHEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostFunktionsbereichLoeschen ausführen';
export const POST_FUNKTIONSBEREICH_LOESCHEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostFunktionsbereichLoeschen laden erfolgreich';
export const POST_FUNKTIONSBEREICH_LOESCHEN_FEHLER = '[BenutzerverwaltungModul] PostFunktionsbereichLoeschen Ladefehler';
export const POST_FUNKTIONSBEREICH_LOESCHEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostFunktionsbereichLoeschen nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichLoeschenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_LOESCHEN;
        constructor(public funktionsbereichRequest: api.FunktionsbereichRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichLoeschenAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_LOESCHEN_AUSFUEHREN;
        constructor(public funktionsbereichRequest: api.FunktionsbereichRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichLoeschenErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_LOESCHEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funktionsbereichRequest: api.FunktionsbereichRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichLoeschenFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_LOESCHEN_FEHLER;
        constructor(public payload: any, public funktionsbereichRequest: api.FunktionsbereichRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichLoeschenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_LOESCHEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandFunktionsbereichLoeschenActions =
    PostFunktionsbereichLoeschenAction
    | PostFunktionsbereichLoeschenAusfuehrenAction
    | PostFunktionsbereichLoeschenErfolgreichAction
    | PostFunktionsbereichLoeschenFehlerAction
    | PostFunktionsbereichLoeschenNichtAusgefuehrtAction
    ;
