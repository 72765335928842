import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFPLAN_ROLLEN_PRUEFER_ENTFERNEN = '[QschecksModul] PostPruefplanRollenPrueferEntfernen laden';
export const POST_PRUEFPLAN_ROLLEN_PRUEFER_ENTFERNEN_AUSFUEHREN = '[QschecksModul] PostPruefplanRollenPrueferEntfernen ausführen';
export const POST_PRUEFPLAN_ROLLEN_PRUEFER_ENTFERNEN_ERFOLGREICH = '[QschecksModul] PostPruefplanRollenPrueferEntfernen laden erfolgreich';
export const POST_PRUEFPLAN_ROLLEN_PRUEFER_ENTFERNEN_FEHLER = '[QschecksModul] PostPruefplanRollenPrueferEntfernen Ladefehler';
export const POST_PRUEFPLAN_ROLLEN_PRUEFER_ENTFERNEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefplanRollenPrueferEntfernen nicht ausgeführt (evtl. Offline)';



export class PostPruefplanRollenPrueferEntfernenAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_PRUEFER_ENTFERNEN;
        constructor(public pruefplanRolleRequest: api.PruefplanRolleRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRollenPrueferEntfernenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_PRUEFER_ENTFERNEN_AUSFUEHREN;
        constructor(public pruefplanRolleRequest: api.PruefplanRolleRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRollenPrueferEntfernenErfolgreichAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_PRUEFER_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefplanRolleRequest: api.PruefplanRolleRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRollenPrueferEntfernenFehlerAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_PRUEFER_ENTFERNEN_FEHLER;
        constructor(public payload: any, public pruefplanRolleRequest: api.PruefplanRolleRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRollenPrueferEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_PRUEFER_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefplanRollenPrueferEntfernenActions =
    PostPruefplanRollenPrueferEntfernenAction
    | PostPruefplanRollenPrueferEntfernenAusfuehrenAction
    | PostPruefplanRollenPrueferEntfernenErfolgreichAction
    | PostPruefplanRollenPrueferEntfernenFehlerAction
    | PostPruefplanRollenPrueferEntfernenNichtAusgefuehrtAction
    ;
