import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFAUFGABE_REIHENFOLGE_SETZEN = '[QschecksModul] PostPruefaufgabeReihenfolgeSetzen laden';
export const POST_PRUEFAUFGABE_REIHENFOLGE_SETZEN_AUSFUEHREN = '[QschecksModul] PostPruefaufgabeReihenfolgeSetzen ausführen';
export const POST_PRUEFAUFGABE_REIHENFOLGE_SETZEN_ERFOLGREICH = '[QschecksModul] PostPruefaufgabeReihenfolgeSetzen laden erfolgreich';
export const POST_PRUEFAUFGABE_REIHENFOLGE_SETZEN_FEHLER = '[QschecksModul] PostPruefaufgabeReihenfolgeSetzen Ladefehler';
export const POST_PRUEFAUFGABE_REIHENFOLGE_SETZEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefaufgabeReihenfolgeSetzen nicht ausgeführt (evtl. Offline)';



export class PostPruefaufgabeReihenfolgeSetzenAction implements Action {
    readonly type = POST_PRUEFAUFGABE_REIHENFOLGE_SETZEN;
        constructor(public pruefaufgabeReihenfolgeRequest: api.PruefaufgabeReihenfolgeRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeReihenfolgeSetzenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFAUFGABE_REIHENFOLGE_SETZEN_AUSFUEHREN;
        constructor(public pruefaufgabeReihenfolgeRequest: api.PruefaufgabeReihenfolgeRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeReihenfolgeSetzenErfolgreichAction implements Action {
    readonly type = POST_PRUEFAUFGABE_REIHENFOLGE_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefaufgabeReihenfolgeRequest: api.PruefaufgabeReihenfolgeRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeReihenfolgeSetzenFehlerAction implements Action {
    readonly type = POST_PRUEFAUFGABE_REIHENFOLGE_SETZEN_FEHLER;
        constructor(public payload: any, public pruefaufgabeReihenfolgeRequest: api.PruefaufgabeReihenfolgeRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeReihenfolgeSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFAUFGABE_REIHENFOLGE_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefaufgabeReihenfolgeSetzenActions =
    PostPruefaufgabeReihenfolgeSetzenAction
    | PostPruefaufgabeReihenfolgeSetzenAusfuehrenAction
    | PostPruefaufgabeReihenfolgeSetzenErfolgreichAction
    | PostPruefaufgabeReihenfolgeSetzenFehlerAction
    | PostPruefaufgabeReihenfolgeSetzenNichtAusgefuehrtAction
    ;
