import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFAUFGABE_AKTUALISIEREN = '[QschecksModul] PostPruefaufgabeAktualisieren laden';
export const POST_PRUEFAUFGABE_AKTUALISIEREN_AUSFUEHREN = '[QschecksModul] PostPruefaufgabeAktualisieren ausführen';
export const POST_PRUEFAUFGABE_AKTUALISIEREN_ERFOLGREICH = '[QschecksModul] PostPruefaufgabeAktualisieren laden erfolgreich';
export const POST_PRUEFAUFGABE_AKTUALISIEREN_FEHLER = '[QschecksModul] PostPruefaufgabeAktualisieren Ladefehler';
export const POST_PRUEFAUFGABE_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefaufgabeAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostPruefaufgabeAktualisierenAction implements Action {
    readonly type = POST_PRUEFAUFGABE_AKTUALISIEREN;
        constructor(public pruefaufgabeModelRequest: api.PruefaufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFAUFGABE_AKTUALISIEREN_AUSFUEHREN;
        constructor(public pruefaufgabeModelRequest: api.PruefaufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeAktualisierenErfolgreichAction implements Action {
    readonly type = POST_PRUEFAUFGABE_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefaufgabeModelRequest: api.PruefaufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeAktualisierenFehlerAction implements Action {
    readonly type = POST_PRUEFAUFGABE_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public pruefaufgabeModelRequest: api.PruefaufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFAUFGABE_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefaufgabeAktualisierenActions =
    PostPruefaufgabeAktualisierenAction
    | PostPruefaufgabeAktualisierenAusfuehrenAction
    | PostPruefaufgabeAktualisierenErfolgreichAction
    | PostPruefaufgabeAktualisierenFehlerAction
    | PostPruefaufgabeAktualisierenNichtAusgefuehrtAction
    ;
