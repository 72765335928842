import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.query.checkliste-aufgaben';
import * as api from '../../../clients/veranstaltungen';


    @Injectable()
    export class VeranstaltungenChecklisteAufgabenEffects {

        @Effect()
            ChecklisteAufgaben$ = this.actions$.pipe(
                ofType(ac.GET_CHECKLISTE_AUFGABEN),
                flatMap((x: ac.GetChecklisteAufgabenAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().getChecklisteAufgaben(x.checkliste_id).pipe(
                    map((result: any) => {
                        const nextAction = new ac.GetChecklisteAufgabenErfolgreichAction(result, x.checkliste_id,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.GetChecklisteAufgabenFehlerAction(error, x.checkliste_id,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.ChecklistenQueryService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.ChecklistenQueryService {
                if (this.service === null) {
                    this.service = this.injector.get(api.ChecklistenQueryService);
                }
                return this.service;
            }
        
}
