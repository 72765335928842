import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const GET_DISPLAYSTEUERUNG_CONSTANTS = '[DisplaysteuerungModul] GetDisplaysteuerungConstants laden';
export const GET_DISPLAYSTEUERUNG_CONSTANTS_AUSFUEHREN = '[DisplaysteuerungModul] GetDisplaysteuerungConstants ausführen';
export const GET_DISPLAYSTEUERUNG_CONSTANTS_ERFOLGREICH = '[DisplaysteuerungModul] GetDisplaysteuerungConstants laden erfolgreich';
export const GET_DISPLAYSTEUERUNG_CONSTANTS_FEHLER = '[DisplaysteuerungModul] GetDisplaysteuerungConstants Ladefehler';
export const GET_DISPLAYSTEUERUNG_CONSTANTS_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] GetDisplaysteuerungConstants nicht ausgeführt (evtl. Offline)';



export class GetDisplaysteuerungConstantsAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_CONSTANTS;
        constructor(public optPayload: any = null) {}
    }
export class GetDisplaysteuerungConstantsAusfuehrenAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_CONSTANTS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetDisplaysteuerungConstantsErfolgreichAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_CONSTANTS_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetDisplaysteuerungConstantsFehlerAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_CONSTANTS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetDisplaysteuerungConstantsNichtAusgefuehrtAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_CONSTANTS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungQueryDisplaysteuerungConstantsActions =
    GetDisplaysteuerungConstantsAction
    | GetDisplaysteuerungConstantsAusfuehrenAction
    | GetDisplaysteuerungConstantsErfolgreichAction
    | GetDisplaysteuerungConstantsFehlerAction
    | GetDisplaysteuerungConstantsNichtAusgefuehrtAction
    ;
