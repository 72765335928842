import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {VeranstaltungKopierenPage} from './veranstaltung-kopieren.page';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {SopiComponentsModule} from '../../shared/sopi-components/sopi-components.module';

const routes: Routes = [
    {
        path: '',
        component: VeranstaltungKopierenPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        SopiScrollModule,
        SopiComponentsModule.forRoot()
    ],
    declarations: [VeranstaltungKopierenPage],
    entryComponents: [VeranstaltungKopierenPage]
})
export class VeranstaltungKopierenPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: VeranstaltungKopierenPageModule,
            providers: []
        };
    }
}
