import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENT_ZENTRALE_NOTIFICATION = '[IncidentsModul] GetIncidentZentraleNotification laden';
export const GET_INCIDENT_ZENTRALE_NOTIFICATION_AUSFUEHREN = '[IncidentsModul] GetIncidentZentraleNotification ausführen';
export const GET_INCIDENT_ZENTRALE_NOTIFICATION_ERFOLGREICH = '[IncidentsModul] GetIncidentZentraleNotification laden erfolgreich';
export const GET_INCIDENT_ZENTRALE_NOTIFICATION_FEHLER = '[IncidentsModul] GetIncidentZentraleNotification Ladefehler';
export const GET_INCIDENT_ZENTRALE_NOTIFICATION_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentZentraleNotification nicht ausgeführt (evtl. Offline)';



export class GetIncidentZentraleNotificationAction implements Action {
    readonly type = GET_INCIDENT_ZENTRALE_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentZentraleNotificationAusfuehrenAction implements Action {
    readonly type = GET_INCIDENT_ZENTRALE_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentZentraleNotificationErfolgreichAction implements Action {
    readonly type = GET_INCIDENT_ZENTRALE_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.IncidentZentraleNotification, public optPayload: any = null) {}
    }
export class GetIncidentZentraleNotificationFehlerAction implements Action {
    readonly type = GET_INCIDENT_ZENTRALE_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetIncidentZentraleNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENT_ZENTRALE_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentZentraleNotificationActions =
    GetIncidentZentraleNotificationAction
    | GetIncidentZentraleNotificationAusfuehrenAction
    | GetIncidentZentraleNotificationErfolgreichAction
    | GetIncidentZentraleNotificationFehlerAction
    | GetIncidentZentraleNotificationNichtAusgefuehrtAction
    ;
