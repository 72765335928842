import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFCHECKLISTE_ENTFERNEN = '[QschecksModul] PostPruefchecklisteEntfernen laden';
export const POST_PRUEFCHECKLISTE_ENTFERNEN_AUSFUEHREN = '[QschecksModul] PostPruefchecklisteEntfernen ausführen';
export const POST_PRUEFCHECKLISTE_ENTFERNEN_ERFOLGREICH = '[QschecksModul] PostPruefchecklisteEntfernen laden erfolgreich';
export const POST_PRUEFCHECKLISTE_ENTFERNEN_FEHLER = '[QschecksModul] PostPruefchecklisteEntfernen Ladefehler';
export const POST_PRUEFCHECKLISTE_ENTFERNEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefchecklisteEntfernen nicht ausgeführt (evtl. Offline)';



export class PostPruefchecklisteEntfernenAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_ENTFERNEN;
        constructor(public pruefchecklisteRequest: api.PruefchecklisteRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteEntfernenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_ENTFERNEN_AUSFUEHREN;
        constructor(public pruefchecklisteRequest: api.PruefchecklisteRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteEntfernenErfolgreichAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefchecklisteRequest: api.PruefchecklisteRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteEntfernenFehlerAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_ENTFERNEN_FEHLER;
        constructor(public payload: any, public pruefchecklisteRequest: api.PruefchecklisteRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefchecklisteEntfernenActions =
    PostPruefchecklisteEntfernenAction
    | PostPruefchecklisteEntfernenAusfuehrenAction
    | PostPruefchecklisteEntfernenErfolgreichAction
    | PostPruefchecklisteEntfernenFehlerAction
    | PostPruefchecklisteEntfernenNichtAusgefuehrtAction
    ;
