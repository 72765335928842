import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_FEHLER_IDENTIFIER = '[VeranstaltungenModul] GetFehlerIdentifier laden';
export const GET_FEHLER_IDENTIFIER_AUSFUEHREN = '[VeranstaltungenModul] GetFehlerIdentifier ausführen';
export const GET_FEHLER_IDENTIFIER_ERFOLGREICH = '[VeranstaltungenModul] GetFehlerIdentifier laden erfolgreich';
export const GET_FEHLER_IDENTIFIER_FEHLER = '[VeranstaltungenModul] GetFehlerIdentifier Ladefehler';
export const GET_FEHLER_IDENTIFIER_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFehlerIdentifier nicht ausgeführt (evtl. Offline)';



export class GetFehlerIdentifierAction implements Action {
    readonly type = GET_FEHLER_IDENTIFIER;
        constructor(public optPayload: any = null) {}
    }
export class GetFehlerIdentifierAusfuehrenAction implements Action {
    readonly type = GET_FEHLER_IDENTIFIER_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFehlerIdentifierErfolgreichAction implements Action {
    readonly type = GET_FEHLER_IDENTIFIER_ERFOLGREICH;
        constructor(public payload: api.FehlerIdentifier, public optPayload: any = null) {}
    }
export class GetFehlerIdentifierFehlerAction implements Action {
    readonly type = GET_FEHLER_IDENTIFIER_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFehlerIdentifierNichtAusgefuehrtAction implements Action {
    readonly type = GET_FEHLER_IDENTIFIER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFehlerIdentifierActions =
    GetFehlerIdentifierAction
    | GetFehlerIdentifierAusfuehrenAction
    | GetFehlerIdentifierErfolgreichAction
    | GetFehlerIdentifierFehlerAction
    | GetFehlerIdentifierNichtAusgefuehrtAction
    ;
