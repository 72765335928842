

import {Action} from '@ngrx/store';

export const GET_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL = '[VorlagenModul] GetAufgabenBenutzerBerechtigungenExcel laden';
export const GET_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_AUSFUEHREN = '[VorlagenModul] GetAufgabenBenutzerBerechtigungenExcel ausführen';
export const GET_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_ERFOLGREICH = '[VorlagenModul] GetAufgabenBenutzerBerechtigungenExcel laden erfolgreich';
export const GET_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_FEHLER = '[VorlagenModul] GetAufgabenBenutzerBerechtigungenExcel Ladefehler';
export const GET_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetAufgabenBenutzerBerechtigungenExcel nicht ausgeführt (evtl. Offline)';



export class GetAufgabenBenutzerBerechtigungenExcelAction implements Action {
    readonly type = GET_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL;
        constructor(public optPayload: any = null) {}
    }
export class GetAufgabenBenutzerBerechtigungenExcelAusfuehrenAction implements Action {
    readonly type = GET_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetAufgabenBenutzerBerechtigungenExcelErfolgreichAction implements Action {
    readonly type = GET_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_ERFOLGREICH;
        constructor(public optPayload: any = null) {}
    }
export class GetAufgabenBenutzerBerechtigungenExcelFehlerAction implements Action {
    readonly type = GET_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetAufgabenBenutzerBerechtigungenExcelNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryAufgabenBenutzerBerechtigungenExcelActions =
    GetAufgabenBenutzerBerechtigungenExcelAction
    | GetAufgabenBenutzerBerechtigungenExcelAusfuehrenAction
    | GetAufgabenBenutzerBerechtigungenExcelErfolgreichAction
    | GetAufgabenBenutzerBerechtigungenExcelFehlerAction
    | GetAufgabenBenutzerBerechtigungenExcelNichtAusgefuehrtAction
    ;
