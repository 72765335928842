import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFPLAN_AKTUALISIEREN = '[QschecksModul] PostPruefplanAktualisieren laden';
export const POST_PRUEFPLAN_AKTUALISIEREN_AUSFUEHREN = '[QschecksModul] PostPruefplanAktualisieren ausführen';
export const POST_PRUEFPLAN_AKTUALISIEREN_ERFOLGREICH = '[QschecksModul] PostPruefplanAktualisieren laden erfolgreich';
export const POST_PRUEFPLAN_AKTUALISIEREN_FEHLER = '[QschecksModul] PostPruefplanAktualisieren Ladefehler';
export const POST_PRUEFPLAN_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefplanAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostPruefplanAktualisierenAction implements Action {
    readonly type = POST_PRUEFPLAN_AKTUALISIEREN;
        constructor(public pruefplanModelRequest: api.PruefplanModelRequest, public optPayload: any = null) {}
    }
export class PostPruefplanAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFPLAN_AKTUALISIEREN_AUSFUEHREN;
        constructor(public pruefplanModelRequest: api.PruefplanModelRequest, public optPayload: any = null) {}
    }
export class PostPruefplanAktualisierenErfolgreichAction implements Action {
    readonly type = POST_PRUEFPLAN_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefplanModelRequest: api.PruefplanModelRequest, public optPayload: any = null) {}
    }
export class PostPruefplanAktualisierenFehlerAction implements Action {
    readonly type = POST_PRUEFPLAN_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public pruefplanModelRequest: api.PruefplanModelRequest, public optPayload: any = null) {}
    }
export class PostPruefplanAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFPLAN_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefplanAktualisierenActions =
    PostPruefplanAktualisierenAction
    | PostPruefplanAktualisierenAusfuehrenAction
    | PostPruefplanAktualisierenErfolgreichAction
    | PostPruefplanAktualisierenFehlerAction
    | PostPruefplanAktualisierenNichtAusgefuehrtAction
    ;
