import {Inject, Injectable, Injector} from '@angular/core';
import {Settings, Themes} from './settings.model';
import {BehaviorSubject, Observable, Observer} from 'rxjs';
import {Platform} from '@ionic/angular';
import {select, Store} from '@ngrx/store';
import {ThemeRefreshAction, ThemeReloadImagesAction, ThemeSetzenAction} from './actions';
import {PageState} from './reducer';
import {Configuration} from '@orga-app/clients/benutzerverwaltung';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {THEME_URL, THEME_TYPE} from './variables';
import {getMandant} from '@orga-app/mandant/reducer';

declare const localforage;

export const localstorageKeyThemeSettings = 'ORGAApp::settings';
export const localstorageKeyThemes = 'ORGAApp::themes';
export const localstorageKeyThemesDefault = 'ORGAApp::themes::default';
export const localstorageKeySettingsPrefix = 'ORGAApp::Theme::';
export const localstorageKeyThemesUserSettings = 'ORGAApp::themes::usersetting';
export const localstorageKeyAppId = 'ORGAApp::appid';

@Injectable({providedIn: 'root'})
export class SettingsProvider {
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    themes: BehaviorSubject<Themes>;
    themes$: Observable<Themes>;
    loginBackground: BehaviorSubject<any>;
    loginBackground$: Observable<any>;
    updateBackground: BehaviorSubject<any>;
    updateBackground$: Observable<any>;
    loadingBackground: BehaviorSubject<SafeUrl>;
    loadingBackground$: Observable<SafeUrl>;
    logoSVGUnsafe: BehaviorSubject<any>;
    logoSVGUnsafe$: Observable<any>;
    logoSVG: BehaviorSubject<SafeUrl>;
    logoSVG$: Observable<SafeUrl>;
    logoHeaderSVG: BehaviorSubject<SafeUrl>;
    logoHeaderSVG$: Observable<SafeUrl>;
    urlCreator = window.URL;
    themeSettings: Settings;
    protected basePath = '/';
    private themeDefault: BehaviorSubject<Settings>;
    private themeDefault$: Observable<Settings>;
    private theme: BehaviorSubject<Settings>;
    private theme$: Observable<Settings>;
    private defaultSettings = <Settings>{
        value: 'theme-orga-hell',
        title: 'ORGA-APP Hell',
        color: '#455a64',
        loginBackground: '../assets/mandanten/default/images/loginbackground.jpg',
        updateBackground: '../assets/mandanten/default/images/update-background.jpg',
        logoSVG: 'assets/mandanten/default/images/logo.svg',
        logoHeaderSVG: 'assets/mandanten/default/images/logo-header.svg',
        loadingBackground: 'assets/mandanten/default/images/loadingbackground.jpg',
        clubName: 'ORGArena',
        clubNameShort: 'ORGArena',
        statusBarDarkText: false
    };

    private myThemUrl: string;
    private myThemType: string;
    private storage: string;
    private isOnlineTheme = true;

    constructor(
        public platform: Platform,
        private store: Store<PageState>,
        protected httpClient: HttpClient,
        public domSanitizer: DomSanitizer,
        private injector: Injector,
        @Inject(THEME_URL) themeUrl: string,
        @Inject(THEME_TYPE) themeType: string
    ) {
        if (this.platform.is('android')) {
            this.defaultSettings.loginBackground = '/android_asset/www/assets/mandanten/default/images/loginbackground.jpg';
            this.defaultSettings.updateBackground = '/android_asset/www/assets/mandanten/default/images/update-background.jpg';
        }
        const localSettings = localStorage.getItem(localstorageKeyThemeSettings);
        const settings = (localSettings !== null) ? <Settings>JSON.parse(localSettings) : this.defaultSettings;

        this.myThemUrl = themeUrl;
        this.myThemType = themeType;
        this.store.dispatch(new ThemeSetzenAction(settings));

        // THEME
        this.themeDefault = new BehaviorSubject(this.defaultSettings);
        this.themeDefault$ = this.themeDefault.asObservable();
        this.theme = new BehaviorSubject(settings);
        this.theme$ = this.theme.asObservable();
        this.themes = new BehaviorSubject(null);
        this.loginBackground = new BehaviorSubject(null);
        this.updateBackground = new BehaviorSubject(null);
        this.logoSVGUnsafe = new BehaviorSubject(null);
        this.logoSVG = new BehaviorSubject(null);
        this.logoHeaderSVG = new BehaviorSubject(null);
        this.loadingBackground = new BehaviorSubject(null);

        this.themes$ = this.themes.asObservable();
        this.loginBackground$ = this.loginBackground.asObservable();
        this.updateBackground$ = this.updateBackground.asObservable();
        this.logoSVGUnsafe$ = this.logoSVGUnsafe.asObservable();
        this.logoSVG$ = this.logoSVG.asObservable();
        this.logoHeaderSVG$ = this.logoHeaderSVG.asObservable();
        this.loadingBackground$ = this.loadingBackground.asObservable();


        this.store.pipe(select(getMandant)).subscribe((mandant) => {
            if (mandant && mandant.mandant_id) {
                this.reloadImages(mandant.mandant_id);
            }
        }).unsubscribe();
        this.reloadThemes();
        // this.reloadImages('sopidev');
        // this.store.dispatch(new ThemeReloadImagesAction());
    }

    public getIsOnlineTheme(): Boolean {
        return this.isOnlineTheme;
    }

    public getThemeDefault(): Observable<Settings> {
        return this.themeDefault$;
    }

    public getThemeSettings(): Settings {
        return this.themeSettings;
    }

    public reloadThemes() {
        const res = localStorage.getItem(localstorageKeyThemes);
        const themes = (res !== null) ? <Themes>JSON.parse(res) : null;
        this.themes.next(themes);
    }

    public getThemes(): Observable<Themes> {
        return this.themes$;
    }

    public setThemes(themes: Themes): void {
        const res = localStorage.setItem(localstorageKeyThemes, JSON.stringify(themes));
        this.themes.next(themes);
    }

    public reloadThemeSettings() {
        const localSettings = localStorage.getItem(localstorageKeyThemeSettings);
        const settings = (localSettings !== null) ? <Settings>JSON.parse(localSettings) : this.defaultSettings;
        this.themeSettings = settings;
        this.theme.next(settings);
    }

    public reloadImages(mandantId: string) {
        this.reloadThemeSettings();
        const settings = this.getThemeSettings();
        this.reloadImageLoginBackground(settings, mandantId);
        this.reloadImageUpdateBackground(settings, mandantId);
        this.reloadImageLogoSVG(settings, mandantId);
        this.reloadImageLogoHeaderSVG(settings, mandantId);
        this.reloadImageLoadingBackground(settings, mandantId);
    }


    // RELOAD IMAGES
    public reloadImageLoginBackground(settings: Settings, mandantId: string) {
        const storageKeyLoginBackground = localstorageKeySettingsPrefix + 'File::' + mandantId + '::' + settings.value + '::loginBackground';
        localforage.getItem(storageKeyLoginBackground).then((x: Blob) => {
            this.setImageLoginBackground(x);
        });

    }

    public reloadImageUpdateBackground(settings: Settings, mandantId: string) {
        const storageKeyUpdateBackground = localstorageKeySettingsPrefix + 'File::' + mandantId + '::' + settings.value + '::updateBackground';
        localforage.getItem(storageKeyUpdateBackground).then((x: Blob) => {
            this.setImageUpdateBackground(x);
        });
    }

    public reloadImageLogoSVG(settings: Settings, mandantId: string) {
        const storageKeyLogoSVG = localstorageKeySettingsPrefix + 'File::' + mandantId + '::' + settings.value + '::logoSVG';
        localforage.getItem(storageKeyLogoSVG).then((x: Blob) => {
            this.setImageLogoSVG(x);
        });
    }

    public reloadImageLogoHeaderSVG(settings: Settings, mandantId: string) {
        const storageKeyLogoHeaderSVG = localstorageKeySettingsPrefix + 'File::' + mandantId + '::' + settings.value + '::logoHeaderSVG';
        localforage.getItem(storageKeyLogoHeaderSVG).then((x: Blob) => {
            this.setImageLogoHeaderSVG(x);
        });
    }

    public reloadImageLoadingBackground(settings: Settings, mandantId: string) {
        const storageKeyLoadingBackground = localstorageKeySettingsPrefix + 'File::' + mandantId + '::' + settings.value + '::loadingBackground';
        localforage.getItem(storageKeyLoadingBackground).then((x: Blob) => {
            this.setImageLoadingBackground(x);
        });
    }


    // SET IMAGES
    public setImageLoginBackground(image: Blob) {
        if (image) {
            this.loginBackground.next(this.urlCreator.createObjectURL(image));
        } else {
            this.loginBackground.next(null);
        }
    }

    public setImageUpdateBackground(image: Blob) {
        if (image) {
            this.updateBackground.next(this.urlCreator.createObjectURL(image));
        } else {
            this.updateBackground.next(null);
        }
    }


    public setImageLogoSVG(image: Blob) {
        if (image) {
            this.logoSVGUnsafe.next(this.urlCreator.createObjectURL(image));
            this.logoSVG.next(this.domSanitizer.bypassSecurityTrustUrl(this.urlCreator.createObjectURL(image)));
        } else {
            this.logoSVGUnsafe.next(null);
            this.logoSVG.next(null);
        }
    }

    public setImageLogoHeaderSVG(image: Blob) {
        if (image) {
            this.logoHeaderSVG.next(this.domSanitizer.bypassSecurityTrustUrl(this.urlCreator.createObjectURL(image)));
        } else {
            this.logoHeaderSVG.next(null);
        }
    }

    public setImageLoadingBackground(image: Blob) {
        if (image) {
            this.loadingBackground.next(this.domSanitizer.bypassSecurityTrustUrl(this.urlCreator.createObjectURL(image)));
        } else {
            this.loadingBackground.next(null);
        }
    }


    setActiveTheme(val: Settings) {
        if (val !== null) {
            localStorage.setItem(localstorageKeyThemeSettings, JSON.stringify(val));
            let userSettings = JSON.parse(localStorage.getItem(localstorageKeyThemesUserSettings));
            if (!userSettings) {
                userSettings = {};
            }
            userSettings[val.clubNameShort] = val.value;
            localStorage.setItem(localstorageKeyThemesUserSettings, JSON.stringify(userSettings));
        } else {
            localStorage.removeItem(localstorageKeyThemeSettings);
        }
        this.theme.next(val);
        this.store.dispatch(new ThemeSetzenAction(val));
    }

    getActiveTheme(): Observable<Settings> {
        return this.theme$;
    }


    getLoginBackground(): Observable<any> {
        return this.loginBackground$;
    }

    getUpdateBackground(): Observable<any> {
        return this.updateBackground$;
    }

    getLogoSVG(): Observable<SafeUrl> {
        return this.logoSVG$;
    }

    getLogoSVGUnsafe(): Observable<any> {
        return this.logoSVGUnsafe$;
    }

    getLogoHeaderSVG(): Observable<SafeUrl> {
        return this.logoHeaderSVG$;
    }

    getLoadingBackground(): Observable<SafeUrl> {
        return this.loadingBackground$;
    }


    public getTheme(mandantId): Observable<Themes> {
        return new Observable((observer: Observer<any>) => {
            const onlineUrl = `${this.myThemUrl}/${mandantId}/${this.myThemType}/themes.json`;
            this.loadJSON(function (response) {
                if (response != null) {
                    this.isOnlineTheme = true;
                    const themesJson = <Themes>JSON.parse(response);
                    observer.next(themesJson);
                    observer.complete();
                } else {
                    this.isOnlineTheme = false;
                    const localUrl = `/assets/mandanten/default/themes.json`;
                    this.loadJSON(function (responseLocal) {
                        let themesJson = null;
                        if (responseLocal != null) {
                            themesJson = <Themes>JSON.parse(responseLocal);
                        } else {
                            // KEIN THEME
                            this.isOnlineTheme = false;
                            console.debug('THEME::KEIN ONLINE THEME');
                            this.setActiveTheme(null);
                            this.reloadImages();
                            const resDefault = localStorage.getItem(localstorageKeyThemesDefault);
                            localStorage.setItem(localstorageKeyThemes, resDefault);
                            this.reloadThemes();
                        }
                        observer.next(themesJson);
                        observer.complete();
                    }.bind(this), localUrl);
                }
            }.bind(this), onlineUrl);
        });
    }

    loadJSON(callback, theme_FILE) {
        const xobj = new XMLHttpRequest();
        xobj.overrideMimeType('application/json');
        xobj.open('GET', theme_FILE + '?' + Date.now(), true); // Replace 'my_data' with the path to your file
        xobj.onreadystatechange = function () {
            if (xobj.readyState == 4 && xobj.status == 200) {
                // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
                callback(xobj.responseText);
            } else if (xobj.status == 404) {
                // KEIN THEME GESETZT
                callback(null);
            }
        };
        xobj.send(null);
    }

    public getFile(mandantId, filePath: string): Observable<Blob> {
        return new Observable((observer: Observer<any>) => {
            let url;
            if (this.isOnlineTheme) {
                // TODO evtl muss hier noch this.myThemType rein (desktop)
                url = `${this.myThemUrl}/${mandantId}/${this.myThemType}/${filePath}`;
            } else {
                url = `/assets/mandanten/default/${filePath}`;
            }
            this.loadFile(function (response) {
                observer.next(response);
                observer.complete();
            }.bind(this), url);
        });
    }

    loadFile(callback, theme_FILE) {
        const xobj = new XMLHttpRequest();
        // xobj.overrideMimeType("application/json");
        xobj.open('GET', theme_FILE, true); // Replace 'my_data' with the path to your file
        xobj.responseType = 'blob';
        xobj.onreadystatechange = function () {
            if (xobj.readyState == 4 && xobj.status == 200) {
                // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
                callback(xobj.response);
            }
        };
        xobj.send(null);
    }

    getPlaceholderUrl(): string {
        // return this.theme.getValue().logoPNG;
        return this.theme.getValue().logoSVG;
    }

}
