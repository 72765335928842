import {Action} from '@ngrx/store';
import * as  apiVeranstaltungen from '../../../clients/veranstaltungen';


export const PAGE_STATE_RESET = '[AufgabebemerkungPage] Page State reset';
export const AUFGABEBEMERKUNG_ERFOLGREICH_HINZUGEFUEGT = '[AufgabebemerkungPage] Aufgabebemerkung erfolgreich hinzugefügt';
export const IGNORE_WS_ACTION = '[AufgabebemerkungPage] WS Action ignoriert';
export const AKTUELLE_AUFGABE_SETZEN = '[AufgabebemerkungPage] Aktuelle Aufgabe setzen';
export const SETZE_VERANSTALTUNG_ID = '[AufgabebemerkungPage] Setze Veranstaltung Id';
export const SETZE_CHECKLISTE_ID = '[AufgabebemerkungPage] Setze Checkliste Id';
export const SETZE_FUNKTIONSBEREICH_ID = '[AufgabebemerkungPage] Setze Funktionsbereich Id';
export const SETZE_AUFGABE_ID = '[AufgabebemerkungPage] Setze Aufgabe Id';



// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;
}

// IGNORE WS ACTION
export class IgnoreWSAction implements Action {
    readonly type = IGNORE_WS_ACTION;
}

export class SetzeVeranstaltungIdAction implements Action {
    readonly type = SETZE_VERANSTALTUNG_ID;

    constructor(public veranstaltungId: string) {
    }
}

export class SetzeFunktionsbereichIdAction implements Action {
    readonly type = SETZE_FUNKTIONSBEREICH_ID;

    constructor(public funktionsbereichId: string) {
    }
}

export class SetzeChecklisteIdAction implements Action {
    readonly type = SETZE_CHECKLISTE_ID;

    constructor(public checklisteId: string) {
    }
}

export class SetzeAufgabeIdAction implements Action {
    readonly type = SETZE_AUFGABE_ID;

    constructor(public aufgabeId: string) {
    }
}

export class AufgabebemerkungErfolgreichVersendetAction implements Action {
    readonly type = AUFGABEBEMERKUNG_ERFOLGREICH_HINZUGEFUEGT;

    constructor() {
    }
}

export class AktuelleAufgabeSetzenAction implements Action {
    readonly type = AKTUELLE_AUFGABE_SETZEN;

    constructor(public aufgabe: apiVeranstaltungen.Aufgabe) {
    }
}

export type Actions =
    AufgabebemerkungErfolgreichVersendetAction
    | PageStateResetAction
    | IgnoreWSAction
    | AktuelleAufgabeSetzenAction
    | SetzeFunktionsbereichIdAction
    | SetzeChecklisteIdAction
    | SetzeAufgabeIdAction
    | SetzeVeranstaltungIdAction
    ;
