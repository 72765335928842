import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_SCANNER_LICENSE_OLD = '[ApiModul] GetScannerLicenseOld laden';
export const GET_SCANNER_LICENSE_OLD_AUSFUEHREN = '[ApiModul] GetScannerLicenseOld ausführen';
export const GET_SCANNER_LICENSE_OLD_ERFOLGREICH = '[ApiModul] GetScannerLicenseOld laden erfolgreich';
export const GET_SCANNER_LICENSE_OLD_FEHLER = '[ApiModul] GetScannerLicenseOld Ladefehler';
export const GET_SCANNER_LICENSE_OLD_NICHT_AUSGEFUEHRT = '[ApiModul] GetScannerLicenseOld nicht ausgeführt (evtl. Offline)';



export class GetScannerLicenseOldAction implements Action {
    readonly type = GET_SCANNER_LICENSE_OLD;
        constructor( public device_id:string , public optPayload: any = null) {}
    }
export class GetScannerLicenseOldAusfuehrenAction implements Action {
    readonly type = GET_SCANNER_LICENSE_OLD_AUSFUEHREN;
        constructor( public device_id:string , public optPayload: any = null) {}
    }
export class GetScannerLicenseOldErfolgreichAction implements Action {
    readonly type = GET_SCANNER_LICENSE_OLD_ERFOLGREICH;
        constructor(public payload: api.ScannerLicense,  public device_id:string , public optPayload: any = null) {}
    }
export class GetScannerLicenseOldFehlerAction implements Action {
    readonly type = GET_SCANNER_LICENSE_OLD_FEHLER;
        constructor(public payload: any,  public device_id:string , public optPayload: any = null) {}
    }
export class GetScannerLicenseOldNichtAusgefuehrtAction implements Action {
    readonly type = GET_SCANNER_LICENSE_OLD_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryScannerLicenseOldActions =
    GetScannerLicenseOldAction
    | GetScannerLicenseOldAusfuehrenAction
    | GetScannerLicenseOldErfolgreichAction
    | GetScannerLicenseOldFehlerAction
    | GetScannerLicenseOldNichtAusgefuehrtAction
    ;
