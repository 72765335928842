import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_SAISON_UEBERSICHT = '[QschecksModul] GetSaisonUebersicht laden';
export const GET_SAISON_UEBERSICHT_AUSFUEHREN = '[QschecksModul] GetSaisonUebersicht ausführen';
export const GET_SAISON_UEBERSICHT_ERFOLGREICH = '[QschecksModul] GetSaisonUebersicht laden erfolgreich';
export const GET_SAISON_UEBERSICHT_FEHLER = '[QschecksModul] GetSaisonUebersicht Ladefehler';
export const GET_SAISON_UEBERSICHT_NICHT_AUSGEFUEHRT = '[QschecksModul] GetSaisonUebersicht nicht ausgeführt (evtl. Offline)';



export class GetSaisonUebersichtAction implements Action {
    readonly type = GET_SAISON_UEBERSICHT;
        constructor(public saisonUebersichtRequest: api.SaisonUebersichtRequest, public optPayload: any = null) {}
    }
export class GetSaisonUebersichtAusfuehrenAction implements Action {
    readonly type = GET_SAISON_UEBERSICHT_AUSFUEHREN;
        constructor(public saisonUebersichtRequest: api.SaisonUebersichtRequest, public optPayload: any = null) {}
    }
export class GetSaisonUebersichtErfolgreichAction implements Action {
    readonly type = GET_SAISON_UEBERSICHT_ERFOLGREICH;
        constructor(public payload: api.SaisonUebersicht, public saisonUebersichtRequest: api.SaisonUebersichtRequest, public optPayload: any = null) {}
    }
export class GetSaisonUebersichtFehlerAction implements Action {
    readonly type = GET_SAISON_UEBERSICHT_FEHLER;
        constructor(public payload: any, public saisonUebersichtRequest: api.SaisonUebersichtRequest, public optPayload: any = null) {}
    }
export class GetSaisonUebersichtNichtAusgefuehrtAction implements Action {
    readonly type = GET_SAISON_UEBERSICHT_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandSaisonUebersichtActions =
    GetSaisonUebersichtAction
    | GetSaisonUebersichtAusfuehrenAction
    | GetSaisonUebersichtErfolgreichAction
    | GetSaisonUebersichtFehlerAction
    | GetSaisonUebersichtNichtAusgefuehrtAction
    ;
