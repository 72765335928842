import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_HINZUFUEGEN = '[KommunikationModul] PostMitteilungsVorlageEmpfaengerRollenHinzufuegen laden';
export const POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_HINZUFUEGEN_AUSFUEHREN = '[KommunikationModul] PostMitteilungsVorlageEmpfaengerRollenHinzufuegen ausführen';
export const POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_HINZUFUEGEN_ERFOLGREICH = '[KommunikationModul] PostMitteilungsVorlageEmpfaengerRollenHinzufuegen laden erfolgreich';
export const POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_HINZUFUEGEN_FEHLER = '[KommunikationModul] PostMitteilungsVorlageEmpfaengerRollenHinzufuegen Ladefehler';
export const POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[KommunikationModul] PostMitteilungsVorlageEmpfaengerRollenHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostMitteilungsVorlageEmpfaengerRollenHinzufuegenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_HINZUFUEGEN;
        constructor(public mitteilungsVorlageRolleMultiRequest: api.MitteilungsVorlageRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageEmpfaengerRollenHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_HINZUFUEGEN_AUSFUEHREN;
        constructor(public mitteilungsVorlageRolleMultiRequest: api.MitteilungsVorlageRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageEmpfaengerRollenHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public mitteilungsVorlageRolleMultiRequest: api.MitteilungsVorlageRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageEmpfaengerRollenHinzufuegenFehlerAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public mitteilungsVorlageRolleMultiRequest: api.MitteilungsVorlageRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageEmpfaengerRollenHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationCommandMitteilungsVorlageEmpfaengerRollenHinzufuegenActions =
    PostMitteilungsVorlageEmpfaengerRollenHinzufuegenAction
    | PostMitteilungsVorlageEmpfaengerRollenHinzufuegenAusfuehrenAction
    | PostMitteilungsVorlageEmpfaengerRollenHinzufuegenErfolgreichAction
    | PostMitteilungsVorlageEmpfaengerRollenHinzufuegenFehlerAction
    | PostMitteilungsVorlageEmpfaengerRollenHinzufuegenNichtAusgefuehrtAction
    ;
