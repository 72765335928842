import {animate, query, stagger, state, style, transition, trigger} from '@angular/animations';

/*export const expandCollapsiblePanelAnimation = trigger('expandCollapsiblePanel', [
    state('open', style({
        'height': '*',
        'overflow': 'hidden'
    })),
    state('closed', style({
        'height': '0px',
        'overflow': 'hidden'
    })),
    transition('* => *', animate('150ms ease-in-out'))
]);*/

export const expandCollapsiblePanelAnimation = trigger('expandCollapsiblePanel', [
    transition('void => *', [
        query('.collapsiblePanelWrapper', style({height: 0}), { optional: true }),
        query('.collapsiblePanelWrapper', stagger('20ms', [
            animate('100ms ease-in-out', style({height: '*'}))
        ]), { optional: true })
    ]),
    transition('* => void', [
        query('.collapsiblePanelWrapper', stagger('20ms', [
            animate('100ms ease-in-out', style({height: 0}))
        ]), { optional: true })
    ])
]);
