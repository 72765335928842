import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_INFO_KATEGORIE_ENTFERNEN = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieEntfernen laden';
export const POST_VERANSTALTUNG_INFO_KATEGORIE_ENTFERNEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieEntfernen ausführen';
export const POST_VERANSTALTUNG_INFO_KATEGORIE_ENTFERNEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieEntfernen laden erfolgreich';
export const POST_VERANSTALTUNG_INFO_KATEGORIE_ENTFERNEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieEntfernen Ladefehler';
export const POST_VERANSTALTUNG_INFO_KATEGORIE_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieEntfernen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungInfoKategorieEntfernenAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_ENTFERNEN;
        constructor(public veranstaltungInfoKategorieEntfernenRequest: api.VeranstaltungInfoKategorieEntfernenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungInfoKategorieEntfernenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_ENTFERNEN_AUSFUEHREN;
        constructor(public veranstaltungInfoKategorieEntfernenRequest: api.VeranstaltungInfoKategorieEntfernenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungInfoKategorieEntfernenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungInfoKategorieEntfernenRequest: api.VeranstaltungInfoKategorieEntfernenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungInfoKategorieEntfernenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_ENTFERNEN_FEHLER;
        constructor(public payload: any, public veranstaltungInfoKategorieEntfernenRequest: api.VeranstaltungInfoKategorieEntfernenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungInfoKategorieEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungInfoKategorieEntfernenActions =
    PostVeranstaltungInfoKategorieEntfernenAction
    | PostVeranstaltungInfoKategorieEntfernenAusfuehrenAction
    | PostVeranstaltungInfoKategorieEntfernenErfolgreichAction
    | PostVeranstaltungInfoKategorieEntfernenFehlerAction
    | PostVeranstaltungInfoKategorieEntfernenNichtAusgefuehrtAction
    ;
