import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_MANDANTEN_CONFIG_UPDATED_NOTIFICATION = '[ApiModul] GetMandantenConfigUpdatedNotification laden';
export const GET_MANDANTEN_CONFIG_UPDATED_NOTIFICATION_AUSFUEHREN = '[ApiModul] GetMandantenConfigUpdatedNotification ausführen';
export const GET_MANDANTEN_CONFIG_UPDATED_NOTIFICATION_ERFOLGREICH = '[ApiModul] GetMandantenConfigUpdatedNotification laden erfolgreich';
export const GET_MANDANTEN_CONFIG_UPDATED_NOTIFICATION_FEHLER = '[ApiModul] GetMandantenConfigUpdatedNotification Ladefehler';
export const GET_MANDANTEN_CONFIG_UPDATED_NOTIFICATION_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantenConfigUpdatedNotification nicht ausgeführt (evtl. Offline)';



export class GetMandantenConfigUpdatedNotificationAction implements Action {
    readonly type = GET_MANDANTEN_CONFIG_UPDATED_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantenConfigUpdatedNotificationAusfuehrenAction implements Action {
    readonly type = GET_MANDANTEN_CONFIG_UPDATED_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantenConfigUpdatedNotificationErfolgreichAction implements Action {
    readonly type = GET_MANDANTEN_CONFIG_UPDATED_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.MandantConfigUpdatedNotification, public optPayload: any = null) {}
    }
export class GetMandantenConfigUpdatedNotificationFehlerAction implements Action {
    readonly type = GET_MANDANTEN_CONFIG_UPDATED_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantenConfigUpdatedNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANTEN_CONFIG_UPDATED_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantenConfigUpdatedNotificationActions =
    GetMandantenConfigUpdatedNotificationAction
    | GetMandantenConfigUpdatedNotificationAusfuehrenAction
    | GetMandantenConfigUpdatedNotificationErfolgreichAction
    | GetMandantenConfigUpdatedNotificationFehlerAction
    | GetMandantenConfigUpdatedNotificationNichtAusgefuehrtAction
    ;
