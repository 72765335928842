import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_ANLEGEN2 = '[IncidentsModul] PostIncidentAnlegen2 laden';
export const POST_INCIDENT_ANLEGEN2_AUSFUEHREN = '[IncidentsModul] PostIncidentAnlegen2 ausführen';
export const POST_INCIDENT_ANLEGEN2_ERFOLGREICH = '[IncidentsModul] PostIncidentAnlegen2 laden erfolgreich';
export const POST_INCIDENT_ANLEGEN2_FEHLER = '[IncidentsModul] PostIncidentAnlegen2 Ladefehler';
export const POST_INCIDENT_ANLEGEN2_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentAnlegen2 nicht ausgeführt (evtl. Offline)';



export class PostIncidentAnlegen2Action implements Action {
    readonly type = POST_INCIDENT_ANLEGEN2;
        constructor(public incidentAnlegen2Request: api.IncidentAnlegen2Request, public optPayload: any = null) {}
    }
export class PostIncidentAnlegen2AusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN2_AUSFUEHREN;
        constructor(public incidentAnlegen2Request: api.IncidentAnlegen2Request, public optPayload: any = null) {}
    }
export class PostIncidentAnlegen2ErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN2_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentAnlegen2Request: api.IncidentAnlegen2Request, public optPayload: any = null) {}
    }
export class PostIncidentAnlegen2FehlerAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN2_FEHLER;
        constructor(public payload: any, public incidentAnlegen2Request: api.IncidentAnlegen2Request, public optPayload: any = null) {}
    }
export class PostIncidentAnlegen2NichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN2_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentAnlegen2Actions =
    PostIncidentAnlegen2Action
    | PostIncidentAnlegen2AusfuehrenAction
    | PostIncidentAnlegen2ErfolgreichAction
    | PostIncidentAnlegen2FehlerAction
    | PostIncidentAnlegen2NichtAusgefuehrtAction
    ;
