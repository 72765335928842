import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {FunktionsbereichLoeschenPage} from './funktionsbereich-loeschen.page';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {SopiComponentsModule} from '../../shared/sopi-components/sopi-components.module';

const routes: Routes = [
    {
        path: '',
        component: FunktionsbereichLoeschenPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        SopiScrollModule,
        SopiComponentsModule.forRoot()
    ],
    declarations: [FunktionsbereichLoeschenPage],
    entryComponents: [FunktionsbereichLoeschenPage],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FunktionsbereichLoeschenPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: FunktionsbereichLoeschenPageModule
        };
    }
}
