import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.command.displaysteuerung-rolle-berechtigungen-setzen';
import * as api from '../../../clients/displaysteuerung';


    @Injectable()
    export class DisplaysteuerungDisplaysteuerungRolleBerechtigungenSetzenEffects {

        @Effect()
            DisplaysteuerungRolleBerechtigungenSetzen$ = this.actions$.pipe(
                ofType(ac.POST_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNGEN_SETZEN),
                flatMap((x: ac.PostDisplaysteuerungRolleBerechtigungenSetzenAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().postDisplaysteuerungRolleBerechtigungenSetzen(x.displaysteuerungRolleBerechtigungenRequest).pipe(
                    map((result: any) => {
                        const nextAction = new ac.PostDisplaysteuerungRolleBerechtigungenSetzenErfolgreichAction(result, x.displaysteuerungRolleBerechtigungenRequest,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.PostDisplaysteuerungRolleBerechtigungenSetzenFehlerAction(error, x.displaysteuerungRolleBerechtigungenRequest,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.DisplaysteuerungCommandService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.DisplaysteuerungCommandService {
                if (this.service === null) {
                    this.service = this.injector.get(api.DisplaysteuerungCommandService);
                }
                return this.service;
            }
        
}
