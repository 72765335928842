import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_MULTI = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungEntfernenMulti laden';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_MULTI_AUSFUEHREN = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungEntfernenMulti ausführen';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_MULTI_ERFOLGREICH = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungEntfernenMulti laden erfolgreich';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_MULTI_FEHLER = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungEntfernenMulti Ladefehler';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_MULTI_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungEntfernenMulti nicht ausgeführt (evtl. Offline)';



export class PostDokumentBearbeitenBerechtigungEntfernenMultiAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_MULTI;
        constructor(public dokumentBerechtigungMultiRequest: api.DokumentBerechtigungMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungEntfernenMultiAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_MULTI_AUSFUEHREN;
        constructor(public dokumentBerechtigungMultiRequest: api.DokumentBerechtigungMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungEntfernenMultiErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_MULTI_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungMultiRequest: api.DokumentBerechtigungMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungEntfernenMultiFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_MULTI_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungMultiRequest: api.DokumentBerechtigungMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungEntfernenMultiNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_MULTI_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandDokumentBearbeitenBerechtigungEntfernenMultiActions =
    PostDokumentBearbeitenBerechtigungEntfernenMultiAction
    | PostDokumentBearbeitenBerechtigungEntfernenMultiAusfuehrenAction
    | PostDokumentBearbeitenBerechtigungEntfernenMultiErfolgreichAction
    | PostDokumentBearbeitenBerechtigungEntfernenMultiFehlerAction
    | PostDokumentBearbeitenBerechtigungEntfernenMultiNichtAusgefuehrtAction
    ;
