import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_ROLLE_MITTEILUNG_OPTIONEN = '[KommunikationModul] GetRolleMitteilungOptionen laden';
export const GET_ROLLE_MITTEILUNG_OPTIONEN_AUSFUEHREN = '[KommunikationModul] GetRolleMitteilungOptionen ausführen';
export const GET_ROLLE_MITTEILUNG_OPTIONEN_ERFOLGREICH = '[KommunikationModul] GetRolleMitteilungOptionen laden erfolgreich';
export const GET_ROLLE_MITTEILUNG_OPTIONEN_FEHLER = '[KommunikationModul] GetRolleMitteilungOptionen Ladefehler';
export const GET_ROLLE_MITTEILUNG_OPTIONEN_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetRolleMitteilungOptionen nicht ausgeführt (evtl. Offline)';



export class GetRolleMitteilungOptionenAction implements Action {
    readonly type = GET_ROLLE_MITTEILUNG_OPTIONEN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleMitteilungOptionenAusfuehrenAction implements Action {
    readonly type = GET_ROLLE_MITTEILUNG_OPTIONEN_AUSFUEHREN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleMitteilungOptionenErfolgreichAction implements Action {
    readonly type = GET_ROLLE_MITTEILUNG_OPTIONEN_ERFOLGREICH;
        constructor(public payload: api.KommunikationRolleMitteilungOptionen,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleMitteilungOptionenFehlerAction implements Action {
    readonly type = GET_ROLLE_MITTEILUNG_OPTIONEN_FEHLER;
        constructor(public payload: any,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleMitteilungOptionenNichtAusgefuehrtAction implements Action {
    readonly type = GET_ROLLE_MITTEILUNG_OPTIONEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryRolleMitteilungOptionenActions =
    GetRolleMitteilungOptionenAction
    | GetRolleMitteilungOptionenAusfuehrenAction
    | GetRolleMitteilungOptionenErfolgreichAction
    | GetRolleMitteilungOptionenFehlerAction
    | GetRolleMitteilungOptionenNichtAusgefuehrtAction
    ;
