import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import * as authModuleUiActions from './actions';
import {
     AuthLogoutErfolgreichAction, AUTH_REFRESH_TOKEN  
} from './actions';
// import {
//     USER_INFO_LADEN,
//     USER_INFO_LADEN_ERFOLGREICH,
//     UserInfoLadenAction,
//     UserInfoLadenErfolgreichAction,
//     UserInfoLadenFehlerAction
// } from './actions.user-info';
// import {AuthState} from './reducer';
// import {Observable, of} from 'rxjs';
import {catchError, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {Router} from '@angular/router';
// import * as api from '@orga-app/clients/auth';
// import {CommunicationService} from '../providers/communication.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthEffects {

    @Effect({ dispatch: false })
    resfreshToken$ = this.actions$.pipe(
        ofType(AUTH_REFRESH_TOKEN)
        , tap((x) => {

            const service = this.getAuthService();

            if (service.hasValidToken()) {

                service.refresh();
                // service
                //     .silentRefresh(this.getRefreshParams(service.getIdentityClaims()))
                //     .then(info => console.debug('refresh ok', info))
                //     .catch(err => console.error('refresh error', err));

            }
        })
    );
   
    // // LOGIN
    // @Effect({dispatch: false})
    // loginFehlgeschlagen$: Observable<Action> = this.actions$.pipe(
    //     ofType<AuthLoginFehlgeschlagenAction>(AUTH_LOGIN_FEHLER)
    //     , tap(() => {
    //         this.router.navigate(['/login'], {queryParams: {returnUrl: this.router.url.split('?')[0]}});
    //     })
    // );

   
    // @Effect()
    // userInfoLaden$: Observable<Action> = this.actions$.pipe(
    //     ofType(USER_INFO_LADEN),
    //     switchMap((x: UserInfoLadenAction) => {
    //         return this.getService().getUserInfo().pipe(
    //             map((result: any) => {
    //                 return new UserInfoLadenErfolgreichAction(result);
    //             }),
    //             catchError((error) => {
    //                 this.communicationService.requestError(error);
    //                 return of(new UserInfoLadenFehlerAction(error));
    //             })
    //         );
    //     })
    // );
  

    // // REDIRECT AFTER LOGIN
    // @Effect({dispatch: false})
    // loggedinRedirect$: Observable<Action> = this.actions$.pipe(
    //     ofType<AuthLoginErfolgreichAction>(AUTH_LOGIN_ERFOLGREICH)
    //     , tap<AuthLoginErfolgreichAction>(y => {
    //         // TODO TZ: CHECKEN OB DAS FUNKTIONIERT FÜR IOS
    //         if (this.tokenService.navRoute != null) {
    //             this.router.navigateByUrl(this.tokenService.navRoute);
    //             this.tokenService.navRoute = null;
    //         }
    //     })
    // );

    // LOGOUT
    // @Effect()
    // logout$: Observable<Action> = this.actions$.pipe(
    //     ofType(authModuleUiActions.AUTH_LOGOUT)
    //     , tap(() => {
    //         this.getAuthService().logout();
    //     })
    //     , switchMap(() => {
    //             return of(new AuthLogoutErfolgreichAction());
    //         }
    //     )
    // );

    // @Effect({dispatch: false})
    // copyToken$: Observable<Action> = this.actions$.pipe(
    //     ofType(authModuleUiActions.AUTH_COPY_TOKEN)
    //     , tap((x: authModuleUiActions.CopyTokenAction) => {
    //         this.clipboardService.copyFromContent(this.getAuthService().accessToken);
    //     })
    // );
   

    constructor(private actions$: Actions,
                // private store$: Store<AuthState>,
                private router: Router,
                // private communicationService: CommunicationService,
                private injector: Injector) {

    }

    // private _service: api.AccountService = null;
    // private getService(): api.AccountService {
    //     if (this._service === null) {
    //         this._service = this.injector.get(api.AccountService);
    //     }
    //     return this._service;
    // }


    private _authService: AuthService = null;
    private getAuthService(): AuthService {
        if (this._authService === null) {
            this._authService = this.injector.get(AuthService);
        }
        return this._authService;
    }

}
