import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ENTFERNEN = '[VeranstaltungenModul] PostAufgabenBenutzerBerechtigungenEntfernen laden';
export const POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ENTFERNEN_AUSFUEHREN = '[VeranstaltungenModul] PostAufgabenBenutzerBerechtigungenEntfernen ausführen';
export const POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ENTFERNEN_ERFOLGREICH = '[VeranstaltungenModul] PostAufgabenBenutzerBerechtigungenEntfernen laden erfolgreich';
export const POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ENTFERNEN_FEHLER = '[VeranstaltungenModul] PostAufgabenBenutzerBerechtigungenEntfernen Ladefehler';
export const POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostAufgabenBenutzerBerechtigungenEntfernen nicht ausgeführt (evtl. Offline)';



export class PostAufgabenBenutzerBerechtigungenEntfernenAction implements Action {
    readonly type = POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ENTFERNEN;
        constructor(public aufgabenBenutzerIdsRequest: api.AufgabenBenutzerIdsRequest, public optPayload: any = null) {}
    }
export class PostAufgabenBenutzerBerechtigungenEntfernenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ENTFERNEN_AUSFUEHREN;
        constructor(public aufgabenBenutzerIdsRequest: api.AufgabenBenutzerIdsRequest, public optPayload: any = null) {}
    }
export class PostAufgabenBenutzerBerechtigungenEntfernenErfolgreichAction implements Action {
    readonly type = POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabenBenutzerIdsRequest: api.AufgabenBenutzerIdsRequest, public optPayload: any = null) {}
    }
export class PostAufgabenBenutzerBerechtigungenEntfernenFehlerAction implements Action {
    readonly type = POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ENTFERNEN_FEHLER;
        constructor(public payload: any, public aufgabenBenutzerIdsRequest: api.AufgabenBenutzerIdsRequest, public optPayload: any = null) {}
    }
export class PostAufgabenBenutzerBerechtigungenEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandAufgabenBenutzerBerechtigungenEntfernenActions =
    PostAufgabenBenutzerBerechtigungenEntfernenAction
    | PostAufgabenBenutzerBerechtigungenEntfernenAusfuehrenAction
    | PostAufgabenBenutzerBerechtigungenEntfernenErfolgreichAction
    | PostAufgabenBenutzerBerechtigungenEntfernenFehlerAction
    | PostAufgabenBenutzerBerechtigungenEntfernenNichtAusgefuehrtAction
    ;
