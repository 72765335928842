import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_FREIGEBEN = '[VeranstaltungenModul] PostAufgabeFreigeben laden';
export const POST_AUFGABE_FREIGEBEN_AUSFUEHREN = '[VeranstaltungenModul] PostAufgabeFreigeben ausführen';
export const POST_AUFGABE_FREIGEBEN_ERFOLGREICH = '[VeranstaltungenModul] PostAufgabeFreigeben laden erfolgreich';
export const POST_AUFGABE_FREIGEBEN_FEHLER = '[VeranstaltungenModul] PostAufgabeFreigeben Ladefehler';
export const POST_AUFGABE_FREIGEBEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostAufgabeFreigeben nicht ausgeführt (evtl. Offline)';



export class PostAufgabeFreigebenAction implements Action {
    readonly type = POST_AUFGABE_FREIGEBEN;
        constructor(public aufgabeFreigebenRequest: api.AufgabeFreigebenRequest, public optPayload: any = null) {}
    }
export class PostAufgabeFreigebenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_FREIGEBEN_AUSFUEHREN;
        constructor(public aufgabeFreigebenRequest: api.AufgabeFreigebenRequest, public optPayload: any = null) {}
    }
export class PostAufgabeFreigebenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_FREIGEBEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeFreigebenRequest: api.AufgabeFreigebenRequest, public optPayload: any = null) {}
    }
export class PostAufgabeFreigebenFehlerAction implements Action {
    readonly type = POST_AUFGABE_FREIGEBEN_FEHLER;
        constructor(public payload: any, public aufgabeFreigebenRequest: api.AufgabeFreigebenRequest, public optPayload: any = null) {}
    }
export class PostAufgabeFreigebenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_FREIGEBEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandAufgabeFreigebenActions =
    PostAufgabeFreigebenAction
    | PostAufgabeFreigebenAusfuehrenAction
    | PostAufgabeFreigebenErfolgreichAction
    | PostAufgabeFreigebenFehlerAction
    | PostAufgabeFreigebenNichtAusgefuehrtAction
    ;
