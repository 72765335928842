import { Inject, Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MandantAuswahlRoute } from './variables';
import * as fromMandantModule from './reducer';
import { getMandant } from './reducer';
import { AuthService } from '@orga-app/auth';

@Injectable()
export class IsMandantAusgewaehltGuard implements CanActivate {

  constructor(private injector: Injector, private _router: Router,
    @Inject(MandantAuswahlRoute) private auswahlRoute: string,
    private storeMandant: Store<fromMandantModule.ModuleState>) {

  }

  
  private _oauthService: AuthService = null;
  private getOAuthService(): AuthService {
    if (this._oauthService === null) {
      this._oauthService = this.injector.get(AuthService);
    }
    return this._oauthService;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.storeMandant.pipe(select(getMandant))
      .map(m => {

        // const result = this.getOAuthService().hasValidToken();
        // if (!result) {
        //   console.debug("mandantGuard, authGuard: hasvalidtoken: ", result);
    
        //   if (state.root && state.root.fragment && state.root.fragment.length > 0) {
        //     if (state.root.fragment.indexOf("access_token") > -1) {
        //       console.debug("mandantGuard: access token in state");          
        //       return true;
        //     }
        //   }             
        // }      

        if (m == null) {

          console.debug("mandantGuard: kein mandant:", this.auswahlRoute, state.url, state);

          // this._router.navigate([this.auswahlRoute], {
          //   preserveFragment: true
          // });
         
          return false;
        }
        console.debug("mandantGuard: OK");

        return true;

      });
  }

}