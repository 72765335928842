import { ModuleWithProviders, NgModule } from '@angular/core';
import { DocumentService } from './document.service';
import { ImageService } from './image.service';
import { NotificationService } from './notification.service';
import { UuidService } from './uuid.service';
import { WindowRefService } from './window.service';
import { API_URL } from './variables';


@NgModule({
    imports: [
     
    ],
    providers: [         
        WindowRefService, 
        // ApplicationService,
        // WebsocketService,
        ImageService,
        DocumentService,
        // DeviceDetectionService,
        NotificationService,
        UuidService,
        //CommunicationService,
        // NavigationAccessService,
     
    ]
})
export class MyModule {
    public static forRoot(apiUrl: string): ModuleWithProviders {
        return {
            ngModule: MyModule,
            providers: [         
                {
                    provide: API_URL,
                    useValue: apiUrl
                }
            ]
        };
    }
}
