/**
 * orga app | qschecks
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FunktionsbereichPruefplaene } from '../model/funktionsbereichPruefplaene';
import { FunktionsbereichRolle } from '../model/funktionsbereichRolle';
import { FunktionsbereichRollen } from '../model/funktionsbereichRollen';
import { Pruefantwort } from '../model/pruefantwort';
import { PruefantwortDetail } from '../model/pruefantwortDetail';
import { Pruefaufgabe } from '../model/pruefaufgabe';
import { PruefaufgabeDetail } from '../model/pruefaufgabeDetail';
import { Pruefcheckliste } from '../model/pruefcheckliste';
import { PruefchecklisteDetail } from '../model/pruefchecklisteDetail';
import { Pruefobjekt } from '../model/pruefobjekt';
import { PruefobjektDetail } from '../model/pruefobjektDetail';
import { PruefplanDetail } from '../model/pruefplanDetail';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdminQueryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetFunktionsbereichePruefplaeneAdmin (Auth)
     * ORGA App Zugriff: * QsChecks - Zugriff  | Admin * Mandant: ValidUser * Lizenz: qschecks 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereichePruefplaeneAdmin(observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichPruefplaene>>;
    public getFunktionsbereichePruefplaeneAdmin(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichPruefplaene>>>;
    public getFunktionsbereichePruefplaeneAdmin(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichPruefplaene>>>;
    public getFunktionsbereichePruefplaeneAdmin(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichPruefplaene>>('get',`${this.basePath}/api/qschecks/query/admin/funktionsbereiche/pruefplaene`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunktionsbereicheRollen (Auth)
     * ORGA App Zugriff: * QsChecks - Zugriff  | Admin * Mandant: ValidUser * Lizenz: qschecks 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereicheRollen(observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichRollen>>;
    public getFunktionsbereicheRollen(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichRollen>>>;
    public getFunktionsbereicheRollen(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichRollen>>>;
    public getFunktionsbereicheRollen(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichRollen>>('get',`${this.basePath}/api/qschecks/query/admin/funktionsbereiche/rollen`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPruefplanAdmin (Auth)
     * ORGA App Zugriff: * QsChecks - Zugriff  | Admin * Mandant: ValidUser * Lizenz: qschecks 
     * @param pruefplan_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPruefplanAdmin(pruefplan_id: string, observe?: 'body', reportProgress?: boolean): Observable<PruefplanDetail>;
    public getPruefplanAdmin(pruefplan_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PruefplanDetail>>;
    public getPruefplanAdmin(pruefplan_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PruefplanDetail>>;
    public getPruefplanAdmin(pruefplan_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pruefplan_id === null || pruefplan_id === undefined) {
            throw new Error('Required parameter pruefplan_id was null or undefined when calling getPruefplanAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PruefplanDetail>('get',`${this.basePath}/api/qschecks/query/admin/pruefplaene/${encodeURIComponent(String(pruefplan_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPruefplanAntwortAdmin (Auth)
     * ORGA App Zugriff: * QsChecks - Zugriff  | Admin * Mandant: ValidUser * Lizenz: qschecks 
     * @param pruefplan_id 
     * @param antwort_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPruefplanAntwortAdmin(pruefplan_id: string, antwort_id: string, observe?: 'body', reportProgress?: boolean): Observable<PruefantwortDetail>;
    public getPruefplanAntwortAdmin(pruefplan_id: string, antwort_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PruefantwortDetail>>;
    public getPruefplanAntwortAdmin(pruefplan_id: string, antwort_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PruefantwortDetail>>;
    public getPruefplanAntwortAdmin(pruefplan_id: string, antwort_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pruefplan_id === null || pruefplan_id === undefined) {
            throw new Error('Required parameter pruefplan_id was null or undefined when calling getPruefplanAntwortAdmin.');
        }

        if (antwort_id === null || antwort_id === undefined) {
            throw new Error('Required parameter antwort_id was null or undefined when calling getPruefplanAntwortAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PruefantwortDetail>('get',`${this.basePath}/api/qschecks/query/admin/pruefplaene/${encodeURIComponent(String(pruefplan_id))}/antworten/${encodeURIComponent(String(antwort_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPruefplanAntwortenAdmin (Auth)
     * ORGA App Zugriff: * QsChecks - Zugriff  | Admin * Mandant: ValidUser * Lizenz: qschecks 
     * @param pruefplan_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPruefplanAntwortenAdmin(pruefplan_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Pruefantwort>>;
    public getPruefplanAntwortenAdmin(pruefplan_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Pruefantwort>>>;
    public getPruefplanAntwortenAdmin(pruefplan_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Pruefantwort>>>;
    public getPruefplanAntwortenAdmin(pruefplan_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pruefplan_id === null || pruefplan_id === undefined) {
            throw new Error('Required parameter pruefplan_id was null or undefined when calling getPruefplanAntwortenAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Pruefantwort>>('get',`${this.basePath}/api/qschecks/query/admin/pruefplaene/${encodeURIComponent(String(pruefplan_id))}/antworten`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPruefplanPruefaufgabeAdmin (Auth)
     * ORGA App Zugriff: * QsChecks - Zugriff  | Admin * Mandant: ValidUser * Lizenz: qschecks 
     * @param pruefplan_id 
     * @param checkliste_id 
     * @param aufgabe_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPruefplanPruefaufgabeAdmin(pruefplan_id: string, checkliste_id: string, aufgabe_id: string, observe?: 'body', reportProgress?: boolean): Observable<PruefaufgabeDetail>;
    public getPruefplanPruefaufgabeAdmin(pruefplan_id: string, checkliste_id: string, aufgabe_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PruefaufgabeDetail>>;
    public getPruefplanPruefaufgabeAdmin(pruefplan_id: string, checkliste_id: string, aufgabe_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PruefaufgabeDetail>>;
    public getPruefplanPruefaufgabeAdmin(pruefplan_id: string, checkliste_id: string, aufgabe_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pruefplan_id === null || pruefplan_id === undefined) {
            throw new Error('Required parameter pruefplan_id was null or undefined when calling getPruefplanPruefaufgabeAdmin.');
        }

        if (checkliste_id === null || checkliste_id === undefined) {
            throw new Error('Required parameter checkliste_id was null or undefined when calling getPruefplanPruefaufgabeAdmin.');
        }

        if (aufgabe_id === null || aufgabe_id === undefined) {
            throw new Error('Required parameter aufgabe_id was null or undefined when calling getPruefplanPruefaufgabeAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PruefaufgabeDetail>('get',`${this.basePath}/api/qschecks/query/admin/pruefplaene/${encodeURIComponent(String(pruefplan_id))}/checklisten/${encodeURIComponent(String(checkliste_id))}/aufgaben/${encodeURIComponent(String(aufgabe_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPruefplanPruefaufgabenAdmin (Auth)
     * ORGA App Zugriff: * QsChecks - Zugriff  | Admin * Mandant: ValidUser * Lizenz: qschecks 
     * @param pruefplan_id 
     * @param checkliste_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPruefplanPruefaufgabenAdmin(pruefplan_id: string, checkliste_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Pruefaufgabe>>;
    public getPruefplanPruefaufgabenAdmin(pruefplan_id: string, checkliste_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Pruefaufgabe>>>;
    public getPruefplanPruefaufgabenAdmin(pruefplan_id: string, checkliste_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Pruefaufgabe>>>;
    public getPruefplanPruefaufgabenAdmin(pruefplan_id: string, checkliste_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pruefplan_id === null || pruefplan_id === undefined) {
            throw new Error('Required parameter pruefplan_id was null or undefined when calling getPruefplanPruefaufgabenAdmin.');
        }

        if (checkliste_id === null || checkliste_id === undefined) {
            throw new Error('Required parameter checkliste_id was null or undefined when calling getPruefplanPruefaufgabenAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Pruefaufgabe>>('get',`${this.basePath}/api/qschecks/query/admin/pruefplaene/${encodeURIComponent(String(pruefplan_id))}/checklisten/${encodeURIComponent(String(checkliste_id))}/aufgaben`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPruefplanPruefchecklisteAdmin (Auth)
     * ORGA App Zugriff: * QsChecks - Zugriff  | Admin * Mandant: ValidUser * Lizenz: qschecks 
     * @param pruefplan_id 
     * @param checkliste_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPruefplanPruefchecklisteAdmin(pruefplan_id: string, checkliste_id: string, observe?: 'body', reportProgress?: boolean): Observable<PruefchecklisteDetail>;
    public getPruefplanPruefchecklisteAdmin(pruefplan_id: string, checkliste_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PruefchecklisteDetail>>;
    public getPruefplanPruefchecklisteAdmin(pruefplan_id: string, checkliste_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PruefchecklisteDetail>>;
    public getPruefplanPruefchecklisteAdmin(pruefplan_id: string, checkliste_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pruefplan_id === null || pruefplan_id === undefined) {
            throw new Error('Required parameter pruefplan_id was null or undefined when calling getPruefplanPruefchecklisteAdmin.');
        }

        if (checkliste_id === null || checkliste_id === undefined) {
            throw new Error('Required parameter checkliste_id was null or undefined when calling getPruefplanPruefchecklisteAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PruefchecklisteDetail>('get',`${this.basePath}/api/qschecks/query/admin/pruefplaene/${encodeURIComponent(String(pruefplan_id))}/checklisten/${encodeURIComponent(String(checkliste_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPruefplanPruefchecklistenAdmin (Auth)
     * ORGA App Zugriff: * QsChecks - Zugriff  | Admin * Mandant: ValidUser * Lizenz: qschecks 
     * @param pruefplan_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPruefplanPruefchecklistenAdmin(pruefplan_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Pruefcheckliste>>;
    public getPruefplanPruefchecklistenAdmin(pruefplan_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Pruefcheckliste>>>;
    public getPruefplanPruefchecklistenAdmin(pruefplan_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Pruefcheckliste>>>;
    public getPruefplanPruefchecklistenAdmin(pruefplan_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pruefplan_id === null || pruefplan_id === undefined) {
            throw new Error('Required parameter pruefplan_id was null or undefined when calling getPruefplanPruefchecklistenAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Pruefcheckliste>>('get',`${this.basePath}/api/qschecks/query/admin/pruefplaene/${encodeURIComponent(String(pruefplan_id))}/checklisten`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPruefplanPruefobjektAdmin (Auth)
     * ORGA App Zugriff: * QsChecks - Zugriff  | Admin * Mandant: ValidUser * Lizenz: qschecks 
     * @param pruefplan_id 
     * @param pruefobjekt_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPruefplanPruefobjektAdmin(pruefplan_id: string, pruefobjekt_id: string, observe?: 'body', reportProgress?: boolean): Observable<PruefobjektDetail>;
    public getPruefplanPruefobjektAdmin(pruefplan_id: string, pruefobjekt_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PruefobjektDetail>>;
    public getPruefplanPruefobjektAdmin(pruefplan_id: string, pruefobjekt_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PruefobjektDetail>>;
    public getPruefplanPruefobjektAdmin(pruefplan_id: string, pruefobjekt_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pruefplan_id === null || pruefplan_id === undefined) {
            throw new Error('Required parameter pruefplan_id was null or undefined when calling getPruefplanPruefobjektAdmin.');
        }

        if (pruefobjekt_id === null || pruefobjekt_id === undefined) {
            throw new Error('Required parameter pruefobjekt_id was null or undefined when calling getPruefplanPruefobjektAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PruefobjektDetail>('get',`${this.basePath}/api/qschecks/query/admin/pruefplaene/${encodeURIComponent(String(pruefplan_id))}/pruefobjekte/${encodeURIComponent(String(pruefobjekt_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPruefplanPruefobjekteAdmin (Auth)
     * ORGA App Zugriff: * QsChecks - Zugriff  | Admin * Mandant: ValidUser * Lizenz: qschecks 
     * @param pruefplan_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPruefplanPruefobjekteAdmin(pruefplan_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Pruefobjekt>>;
    public getPruefplanPruefobjekteAdmin(pruefplan_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Pruefobjekt>>>;
    public getPruefplanPruefobjekteAdmin(pruefplan_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Pruefobjekt>>>;
    public getPruefplanPruefobjekteAdmin(pruefplan_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pruefplan_id === null || pruefplan_id === undefined) {
            throw new Error('Required parameter pruefplan_id was null or undefined when calling getPruefplanPruefobjekteAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Pruefobjekt>>('get',`${this.basePath}/api/qschecks/query/admin/pruefplaene/${encodeURIComponent(String(pruefplan_id))}/pruefobjekte`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPruefplanRollenControllerAdmin (Auth)
     * ORGA App Zugriff: * QsChecks - Zugriff  | Admin * Mandant: ValidUser * Lizenz: qschecks 
     * @param pruefplan_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPruefplanRollenControllerAdmin(pruefplan_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichRolle>>;
    public getPruefplanRollenControllerAdmin(pruefplan_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichRolle>>>;
    public getPruefplanRollenControllerAdmin(pruefplan_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichRolle>>>;
    public getPruefplanRollenControllerAdmin(pruefplan_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pruefplan_id === null || pruefplan_id === undefined) {
            throw new Error('Required parameter pruefplan_id was null or undefined when calling getPruefplanRollenControllerAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichRolle>>('get',`${this.basePath}/api/qschecks/query/admin/pruefplaene/${encodeURIComponent(String(pruefplan_id))}/rollen/controller`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPruefplanRollenPrueferAdmin (Auth)
     * ORGA App Zugriff: * QsChecks - Zugriff  | Admin * Mandant: ValidUser * Lizenz: qschecks 
     * @param pruefplan_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPruefplanRollenPrueferAdmin(pruefplan_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichRolle>>;
    public getPruefplanRollenPrueferAdmin(pruefplan_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichRolle>>>;
    public getPruefplanRollenPrueferAdmin(pruefplan_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichRolle>>>;
    public getPruefplanRollenPrueferAdmin(pruefplan_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (pruefplan_id === null || pruefplan_id === undefined) {
            throw new Error('Required parameter pruefplan_id was null or undefined when calling getPruefplanRollenPrueferAdmin.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichRolle>>('get',`${this.basePath}/api/qschecks/query/admin/pruefplaene/${encodeURIComponent(String(pruefplan_id))}/rollen/pruefer`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
