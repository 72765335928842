import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_ROLLEN_ADMIN = '[VeranstaltungenModul] GetVeranstaltungRollenAdmin laden';
export const GET_VERANSTALTUNG_ROLLEN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungRollenAdmin ausführen';
export const GET_VERANSTALTUNG_ROLLEN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungRollenAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_ROLLEN_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungRollenAdmin Ladefehler';
export const GET_VERANSTALTUNG_ROLLEN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungRollenAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungRollenAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_ROLLEN_ADMIN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungRollenAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_ROLLEN_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungRollenAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_ROLLEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.Rolle>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungRollenAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_ROLLEN_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungRollenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_ROLLEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungRollenAdminActions =
    GetVeranstaltungRollenAdminAction
    | GetVeranstaltungRollenAdminAusfuehrenAction
    | GetVeranstaltungRollenAdminErfolgreichAction
    | GetVeranstaltungRollenAdminFehlerAction
    | GetVeranstaltungRollenAdminNichtAusgefuehrtAction
    ;
