import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';

import { EnvironmentOptions, IAppConfig, IAzureAdConfig, IEnvironment, IMobileConfig } from './model';
import { MY_ENV } from './variables';




@NgModule({
  imports: [

  ],
  providers: [

  ],
})
export class EnvModule {
  public static forRoot(options?: IEnvironment): ModuleWithProviders {
    return {
      ngModule: EnvModule,
      providers: [
        { provide: MY_ENV, useValue: options },
        {
          provide: EnvironmentOptions,
          useFactory: provideEnvironmentOptions,
          deps: [MY_ENV]
        }
      ],
    };
  }
}

// https://www.bennadel.com/blog/3565-providing-module-configuration-using-forroot-and-ahead-of-time-compiling-in-angular-7-2-0.htm

export function provideEnvironmentOptions(options?: IEnvironment): EnvironmentOptions {

  var ret = new EnvironmentOptions();

  // If the optional options were provided via the .forRoot() static method, then apply
  // them to the MyServiceOptions Type provider.
  if (options) {
    ret.appConfig = options.appConfig;
    ret.appTitle = options.appTitle;
    ret.azureAd = options.azureAd;
    ret.debug = options.debug;
    ret.homeRoute = options.homeRoute;
    ret.loginRoute = options.loginRoute;
    ret.mobile = options.mobile;
    ret.production = options.production;
    ret.testMode = options.testMode;
    ret.version = options.version;

    // if ( typeof( options. ) === "number" ) { 
    // 	myServiceOptions.retryCount = options.retryCount; 
    // }

    // if ( typeof( options.retryInterval ) === "number" ) { 
    // 	myServiceOptions.retryInterval = options.retryInterval; 
    // }

  }

  console.group("provideEnvironmentOptions");
  console.log("options interface", options);
  console.log("options class", ret);
  console.groupEnd();
  
  return (ret);

}