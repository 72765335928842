import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const POST_BEREICH_TRIGGER_ENTFERNEN = '[DisplaysteuerungModul] PostBereichTriggerEntfernen laden';
export const POST_BEREICH_TRIGGER_ENTFERNEN_AUSFUEHREN = '[DisplaysteuerungModul] PostBereichTriggerEntfernen ausführen';
export const POST_BEREICH_TRIGGER_ENTFERNEN_ERFOLGREICH = '[DisplaysteuerungModul] PostBereichTriggerEntfernen laden erfolgreich';
export const POST_BEREICH_TRIGGER_ENTFERNEN_FEHLER = '[DisplaysteuerungModul] PostBereichTriggerEntfernen Ladefehler';
export const POST_BEREICH_TRIGGER_ENTFERNEN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] PostBereichTriggerEntfernen nicht ausgeführt (evtl. Offline)';



export class PostBereichTriggerEntfernenAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_ENTFERNEN;
        constructor(public bereichTriggerRequest: api.BereichTriggerRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerEntfernenAusfuehrenAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_ENTFERNEN_AUSFUEHREN;
        constructor(public bereichTriggerRequest: api.BereichTriggerRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerEntfernenErfolgreichAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public bereichTriggerRequest: api.BereichTriggerRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerEntfernenFehlerAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_ENTFERNEN_FEHLER;
        constructor(public payload: any, public bereichTriggerRequest: api.BereichTriggerRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungCommandBereichTriggerEntfernenActions =
    PostBereichTriggerEntfernenAction
    | PostBereichTriggerEntfernenAusfuehrenAction
    | PostBereichTriggerEntfernenErfolgreichAction
    | PostBereichTriggerEntfernenFehlerAction
    | PostBereichTriggerEntfernenNichtAusgefuehrtAction
    ;
