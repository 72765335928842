import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FEHLERBILD_ENTFERNEN = '[VeranstaltungenModul] PostFehlerbildEntfernen laden';
export const POST_FEHLERBILD_ENTFERNEN_AUSFUEHREN = '[VeranstaltungenModul] PostFehlerbildEntfernen ausführen';
export const POST_FEHLERBILD_ENTFERNEN_ERFOLGREICH = '[VeranstaltungenModul] PostFehlerbildEntfernen laden erfolgreich';
export const POST_FEHLERBILD_ENTFERNEN_FEHLER = '[VeranstaltungenModul] PostFehlerbildEntfernen Ladefehler';
export const POST_FEHLERBILD_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFehlerbildEntfernen nicht ausgeführt (evtl. Offline)';



export class PostFehlerbildEntfernenAction implements Action {
    readonly type = POST_FEHLERBILD_ENTFERNEN;
        constructor(public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostFehlerbildEntfernenAusfuehrenAction implements Action {
    readonly type = POST_FEHLERBILD_ENTFERNEN_AUSFUEHREN;
        constructor(public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostFehlerbildEntfernenErfolgreichAction implements Action {
    readonly type = POST_FEHLERBILD_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostFehlerbildEntfernenFehlerAction implements Action {
    readonly type = POST_FEHLERBILD_ENTFERNEN_FEHLER;
        constructor(public payload: any, public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostFehlerbildEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FEHLERBILD_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFehlerbildEntfernenActions =
    PostFehlerbildEntfernenAction
    | PostFehlerbildEntfernenAusfuehrenAction
    | PostFehlerbildEntfernenErfolgreichAction
    | PostFehlerbildEntfernenFehlerAction
    | PostFehlerbildEntfernenNichtAusgefuehrtAction
    ;
