import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_NICHT_VEROEFFENTLICHEN = '[VeranstaltungenModul] PostVeranstaltungNichtVeroeffentlichen laden';
export const POST_VERANSTALTUNG_NICHT_VEROEFFENTLICHEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungNichtVeroeffentlichen ausführen';
export const POST_VERANSTALTUNG_NICHT_VEROEFFENTLICHEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungNichtVeroeffentlichen laden erfolgreich';
export const POST_VERANSTALTUNG_NICHT_VEROEFFENTLICHEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungNichtVeroeffentlichen Ladefehler';
export const POST_VERANSTALTUNG_NICHT_VEROEFFENTLICHEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungNichtVeroeffentlichen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungNichtVeroeffentlichenAction implements Action {
    readonly type = POST_VERANSTALTUNG_NICHT_VEROEFFENTLICHEN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungNichtVeroeffentlichenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_NICHT_VEROEFFENTLICHEN_AUSFUEHREN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungNichtVeroeffentlichenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_NICHT_VEROEFFENTLICHEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungNichtVeroeffentlichenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_NICHT_VEROEFFENTLICHEN_FEHLER;
        constructor(public payload: any, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungNichtVeroeffentlichenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_NICHT_VEROEFFENTLICHEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungNichtVeroeffentlichenActions =
    PostVeranstaltungNichtVeroeffentlichenAction
    | PostVeranstaltungNichtVeroeffentlichenAusfuehrenAction
    | PostVeranstaltungNichtVeroeffentlichenErfolgreichAction
    | PostVeranstaltungNichtVeroeffentlichenFehlerAction
    | PostVeranstaltungNichtVeroeffentlichenNichtAusgefuehrtAction
    ;
