import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_MITTEILUNG_NOTIFICATION = '[KommunikationModul] GetMitteilungNotification laden';
export const GET_MITTEILUNG_NOTIFICATION_AUSFUEHREN = '[KommunikationModul] GetMitteilungNotification ausführen';
export const GET_MITTEILUNG_NOTIFICATION_ERFOLGREICH = '[KommunikationModul] GetMitteilungNotification laden erfolgreich';
export const GET_MITTEILUNG_NOTIFICATION_FEHLER = '[KommunikationModul] GetMitteilungNotification Ladefehler';
export const GET_MITTEILUNG_NOTIFICATION_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetMitteilungNotification nicht ausgeführt (evtl. Offline)';



export class GetMitteilungNotificationAction implements Action {
    readonly type = GET_MITTEILUNG_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetMitteilungNotificationAusfuehrenAction implements Action {
    readonly type = GET_MITTEILUNG_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMitteilungNotificationErfolgreichAction implements Action {
    readonly type = GET_MITTEILUNG_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.MitteilungNotification, public optPayload: any = null) {}
    }
export class GetMitteilungNotificationFehlerAction implements Action {
    readonly type = GET_MITTEILUNG_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMitteilungNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_MITTEILUNG_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryMitteilungNotificationActions =
    GetMitteilungNotificationAction
    | GetMitteilungNotificationAusfuehrenAction
    | GetMitteilungNotificationErfolgreichAction
    | GetMitteilungNotificationFehlerAction
    | GetMitteilungNotificationNichtAusgefuehrtAction
    ;
