import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_ANLEGEN = '[VeranstaltungenModul] PostVeranstaltungAnlegen laden';
export const POST_VERANSTALTUNG_ANLEGEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungAnlegen ausführen';
export const POST_VERANSTALTUNG_ANLEGEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungAnlegen laden erfolgreich';
export const POST_VERANSTALTUNG_ANLEGEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungAnlegen Ladefehler';
export const POST_VERANSTALTUNG_ANLEGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungAnlegen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungAnlegenAction implements Action {
    readonly type = POST_VERANSTALTUNG_ANLEGEN;
        constructor(public veranstaltungAnlegenRequest: api.VeranstaltungAnlegenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAnlegenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_ANLEGEN_AUSFUEHREN;
        constructor(public veranstaltungAnlegenRequest: api.VeranstaltungAnlegenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAnlegenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_ANLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungAnlegenRequest: api.VeranstaltungAnlegenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAnlegenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_ANLEGEN_FEHLER;
        constructor(public payload: any, public veranstaltungAnlegenRequest: api.VeranstaltungAnlegenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAnlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_ANLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungAnlegenActions =
    PostVeranstaltungAnlegenAction
    | PostVeranstaltungAnlegenAusfuehrenAction
    | PostVeranstaltungAnlegenErfolgreichAction
    | PostVeranstaltungAnlegenFehlerAction
    | PostVeranstaltungAnlegenNichtAusgefuehrtAction
    ;
