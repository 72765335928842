import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNGS_TYP_FELDER_SORTIEREN = '[VeranstaltungstypenModul] PostVeranstaltungsTypFelderSortieren laden';
export const POST_VERANSTALTUNGS_TYP_FELDER_SORTIEREN_AUSFUEHREN = '[VeranstaltungstypenModul] PostVeranstaltungsTypFelderSortieren ausführen';
export const POST_VERANSTALTUNGS_TYP_FELDER_SORTIEREN_ERFOLGREICH = '[VeranstaltungstypenModul] PostVeranstaltungsTypFelderSortieren laden erfolgreich';
export const POST_VERANSTALTUNGS_TYP_FELDER_SORTIEREN_FEHLER = '[VeranstaltungstypenModul] PostVeranstaltungsTypFelderSortieren Ladefehler';
export const POST_VERANSTALTUNGS_TYP_FELDER_SORTIEREN_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] PostVeranstaltungsTypFelderSortieren nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungsTypFelderSortierenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELDER_SORTIEREN;
        constructor(public veranstaltungsTypIdArrayRequest: api.VeranstaltungsTypIdArrayRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypFelderSortierenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELDER_SORTIEREN_AUSFUEHREN;
        constructor(public veranstaltungsTypIdArrayRequest: api.VeranstaltungsTypIdArrayRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypFelderSortierenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELDER_SORTIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungsTypIdArrayRequest: api.VeranstaltungsTypIdArrayRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypFelderSortierenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELDER_SORTIEREN_FEHLER;
        constructor(public payload: any, public veranstaltungsTypIdArrayRequest: api.VeranstaltungsTypIdArrayRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypFelderSortierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELDER_SORTIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenCommandVeranstaltungsTypFelderSortierenActions =
    PostVeranstaltungsTypFelderSortierenAction
    | PostVeranstaltungsTypFelderSortierenAusfuehrenAction
    | PostVeranstaltungsTypFelderSortierenErfolgreichAction
    | PostVeranstaltungsTypFelderSortierenFehlerAction
    | PostVeranstaltungsTypFelderSortierenNichtAusgefuehrtAction
    ;
