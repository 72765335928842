import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_READ_MODEL_UPDATED_NOTIFICATION = '[ApiModul] GetReadModelUpdatedNotification laden';
export const GET_READ_MODEL_UPDATED_NOTIFICATION_AUSFUEHREN = '[ApiModul] GetReadModelUpdatedNotification ausführen';
export const GET_READ_MODEL_UPDATED_NOTIFICATION_ERFOLGREICH = '[ApiModul] GetReadModelUpdatedNotification laden erfolgreich';
export const GET_READ_MODEL_UPDATED_NOTIFICATION_FEHLER = '[ApiModul] GetReadModelUpdatedNotification Ladefehler';
export const GET_READ_MODEL_UPDATED_NOTIFICATION_NICHT_AUSGEFUEHRT = '[ApiModul] GetReadModelUpdatedNotification nicht ausgeführt (evtl. Offline)';



export class GetReadModelUpdatedNotificationAction implements Action {
    readonly type = GET_READ_MODEL_UPDATED_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetReadModelUpdatedNotificationAusfuehrenAction implements Action {
    readonly type = GET_READ_MODEL_UPDATED_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetReadModelUpdatedNotificationErfolgreichAction implements Action {
    readonly type = GET_READ_MODEL_UPDATED_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.ReadModelUpdatedNotification, public optPayload: any = null) {}
    }
export class GetReadModelUpdatedNotificationFehlerAction implements Action {
    readonly type = GET_READ_MODEL_UPDATED_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetReadModelUpdatedNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_READ_MODEL_UPDATED_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryReadModelUpdatedNotificationActions =
    GetReadModelUpdatedNotificationAction
    | GetReadModelUpdatedNotificationAusfuehrenAction
    | GetReadModelUpdatedNotificationErfolgreichAction
    | GetReadModelUpdatedNotificationFehlerAction
    | GetReadModelUpdatedNotificationNichtAusgefuehrtAction
    ;
