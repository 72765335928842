import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.command.veranstaltungen-benutzerverwaltung-rolle-berechtigungen-setzen';
import * as api from '../../../clients/veranstaltungen';


    @Injectable()
    export class VeranstaltungenVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenEffects {

        @Effect()
            VeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzen$ = this.actions$.pipe(
                ofType(ac.POST_VERANSTALTUNGEN_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_SETZEN),
                flatMap((x: ac.PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().postVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzen(x.veranstaltungBenutzerverwaltungRolleBerechtigungenRequest).pipe(
                    map((result: any) => {
                        const nextAction = new ac.PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenErfolgreichAction(result, x.veranstaltungBenutzerverwaltungRolleBerechtigungenRequest,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.PostVeranstaltungenBenutzerverwaltungRolleBerechtigungenSetzenFehlerAction(error, x.veranstaltungBenutzerverwaltungRolleBerechtigungenRequest,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.BenutzerVerwaltungExtensionCommandService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.BenutzerVerwaltungExtensionCommandService {
                if (this.service === null) {
                    this.service = this.injector.get(api.BenutzerVerwaltungExtensionCommandService);
                }
                return this.service;
            }
        
}
