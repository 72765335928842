import {Action} from '@ngrx/store';
import {Settings, Themes} from './settings.model';

export const THEME_SETZEN = '[Settings] Theme setzen';
export const THEME_RELOAD_IMAGES = '[Settings] Theme reload images';
export const THEME_REFRESH = '[Settings] Theme refresh';
export const THEME_FILE_GELADEN = '[Settings] Theme File geladen';
export const GET_FILE_ACTION = '[Settings] File laden';
export const GET_FILE_ERFOLGREICH_ACTION = '[Settings] File laden erfolgreich';
export const GET_FILE_FEHLER_ACTION = '[Settings] File laden Fehler';

export class ThemeSetzenAction implements Action {
    readonly type = THEME_SETZEN;

    constructor(public settings: Settings) {
    }
}

export class ThemeReloadImagesAction implements Action {
    readonly type = THEME_RELOAD_IMAGES;

    constructor() {
    }
}

export class ThemeRefreshAction implements Action {
    readonly type = THEME_REFRESH;

    constructor() {
    }
}

export class ThemeFileGeladenAction implements Action {
    readonly type = THEME_FILE_GELADEN;

    constructor(public themes: Themes, public mandantId: string = null, public forceUpdate: boolean = false) {
    }
}

export class GetFileAction implements Action {
    readonly type = GET_FILE_ACTION;

    constructor(public mandantId: string, public file: string, public key: string = null, public storeLocalstorage: boolean = false, public storeIonicStorage: boolean = false, public appendFileToHeader = false, public imageType: any = null, public isDefault: boolean = false) {
    }
}

export class GetFileErfolgreichAction implements Action {
    readonly type = GET_FILE_ERFOLGREICH_ACTION;

    constructor() {
    }
}

export class GetFileFehlerAction implements Action {
    readonly type = GET_FILE_FEHLER_ACTION;

    constructor(public error: any) {
    }
}

export type Actions =
    ThemeSetzenAction
    | ThemeReloadImagesAction
    | ThemeRefreshAction
    | ThemeFileGeladenAction
    | GetFileAction
    | GetFileErfolgreichAction
    | GetFileFehlerAction
    ;
