import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_FUNKPROTOKOLL_REPORT_DATA = '[VeranstaltungenModul] GetFunkprotokollReportData laden';
export const GET_FUNKPROTOKOLL_REPORT_DATA_AUSFUEHREN = '[VeranstaltungenModul] GetFunkprotokollReportData ausführen';
export const GET_FUNKPROTOKOLL_REPORT_DATA_ERFOLGREICH = '[VeranstaltungenModul] GetFunkprotokollReportData laden erfolgreich';
export const GET_FUNKPROTOKOLL_REPORT_DATA_FEHLER = '[VeranstaltungenModul] GetFunkprotokollReportData Ladefehler';
export const GET_FUNKPROTOKOLL_REPORT_DATA_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunkprotokollReportData nicht ausgeführt (evtl. Offline)';



export class GetFunkprotokollReportDataAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_DATA;
        constructor( public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollReportDataAusfuehrenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_DATA_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollReportDataErfolgreichAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_DATA_ERFOLGREICH;
        constructor(public payload: api.FunkprotokollReportModel,  public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollReportDataFehlerAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_DATA_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollReportDataNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_DATA_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunkprotokollReportDataActions =
    GetFunkprotokollReportDataAction
    | GetFunkprotokollReportDataAusfuehrenAction
    | GetFunkprotokollReportDataErfolgreichAction
    | GetFunkprotokollReportDataFehlerAction
    | GetFunkprotokollReportDataNichtAusgefuehrtAction
    ;
