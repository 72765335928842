/**
 * orga app | api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Benutzer } from '../model/benutzer';
import { Bild } from '../model/bild';
import { CacheClearInfo } from '../model/cacheClearInfo';
import { MandantInfo } from '../model/mandantInfo';
import { ScannerLicense } from '../model/scannerLicense';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MandantenService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetMandantClientConfig (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantClientConfig(observe?: 'body', reportProgress?: boolean): Observable<{ [key: string]: any; }>;
    public getMandantClientConfig(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<{ [key: string]: any; }>>;
    public getMandantClientConfig(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<{ [key: string]: any; }>>;
    public getMandantClientConfig(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<{ [key: string]: any; }>('get',`${this.basePath}/api/mandanten/config/client`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantInfo (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantInfo(observe?: 'body', reportProgress?: boolean): Observable<MandantInfo>;
    public getMandantInfo(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MandantInfo>>;
    public getMandantInfo(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MandantInfo>>;
    public getMandantInfo(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MandantInfo>('get',`${this.basePath}/api/mandanten/info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantLogo (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantLogo(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getMandantLogo(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getMandantLogo(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getMandantLogo(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mandanten/logo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantLogoBase64 (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantLogoBase64(observe?: 'body', reportProgress?: boolean): Observable<Bild>;
    public getMandantLogoBase64(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Bild>>;
    public getMandantLogoBase64(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Bild>>;
    public getMandantLogoBase64(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Bild>('get',`${this.basePath}/api/mandanten/logo/base64`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantLogoBase64Scaled (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param width 
     * @param height 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantLogoBase64Scaled(width: number, height: number, observe?: 'body', reportProgress?: boolean): Observable<Bild>;
    public getMandantLogoBase64Scaled(width: number, height: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Bild>>;
    public getMandantLogoBase64Scaled(width: number, height: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Bild>>;
    public getMandantLogoBase64Scaled(width: number, height: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (width === null || width === undefined) {
            throw new Error('Required parameter width was null or undefined when calling getMandantLogoBase64Scaled.');
        }

        if (height === null || height === undefined) {
            throw new Error('Required parameter height was null or undefined when calling getMandantLogoBase64Scaled.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Bild>('get',`${this.basePath}/api/mandanten/logo/base64/scale/${encodeURIComponent(String(width))}/${encodeURIComponent(String(height))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantLogoScaled (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param width 
     * @param height 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantLogoScaled(width: number, height: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getMandantLogoScaled(width: number, height: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getMandantLogoScaled(width: number, height: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getMandantLogoScaled(width: number, height: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (width === null || width === undefined) {
            throw new Error('Required parameter width was null or undefined when calling getMandantLogoScaled.');
        }

        if (height === null || height === undefined) {
            throw new Error('Required parameter height was null or undefined when calling getMandantLogoScaled.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/mandanten/logo/scale/${encodeURIComponent(String(width))}/${encodeURIComponent(String(height))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantUserInfo (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantUserInfo(observe?: 'body', reportProgress?: boolean): Observable<Benutzer>;
    public getMandantUserInfo(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Benutzer>>;
    public getMandantUserInfo(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Benutzer>>;
    public getMandantUserInfo(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Benutzer>('get',`${this.basePath}/api/mandanten/userinfo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMeineMandanten (Auth)
     * 
     * @param logos 
     * @param pWidth 
     * @param pHheight 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeineMandanten(logos?: boolean, pWidth?: number, pHheight?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<MandantInfo>>;
    public getMeineMandanten(logos?: boolean, pWidth?: number, pHheight?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MandantInfo>>>;
    public getMeineMandanten(logos?: boolean, pWidth?: number, pHheight?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MandantInfo>>>;
    public getMeineMandanten(logos?: boolean, pWidth?: number, pHheight?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (logos !== undefined && logos !== null) {
            queryParameters = queryParameters.set('logos', <any>logos);
        }
        if (pWidth !== undefined && pWidth !== null) {
            queryParameters = queryParameters.set('pWidth', <any>pWidth);
        }
        if (pHheight !== undefined && pHheight !== null) {
            queryParameters = queryParameters.set('pHheight', <any>pHheight);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<MandantInfo>>('get',`${this.basePath}/api/mandanten/meine`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetScannerLicense (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param device_id 
     * @param app_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getScannerLicense(device_id: string, app_id: string, observe?: 'body', reportProgress?: boolean): Observable<ScannerLicense>;
    public getScannerLicense(device_id: string, app_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ScannerLicense>>;
    public getScannerLicense(device_id: string, app_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ScannerLicense>>;
    public getScannerLicense(device_id: string, app_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (device_id === null || device_id === undefined) {
            throw new Error('Required parameter device_id was null or undefined when calling getScannerLicense.');
        }

        if (app_id === null || app_id === undefined) {
            throw new Error('Required parameter app_id was null or undefined when calling getScannerLicense.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ScannerLicense>('get',`${this.basePath}/api/mandanten/config/license/scanner/${encodeURIComponent(String(device_id))}/${encodeURIComponent(String(app_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetScannerLicenseOld (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param device_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getScannerLicenseOld(device_id: string, observe?: 'body', reportProgress?: boolean): Observable<ScannerLicense>;
    public getScannerLicenseOld(device_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ScannerLicense>>;
    public getScannerLicenseOld(device_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ScannerLicense>>;
    public getScannerLicenseOld(device_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (device_id === null || device_id === undefined) {
            throw new Error('Required parameter device_id was null or undefined when calling getScannerLicenseOld.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ScannerLicense>('get',`${this.basePath}/api/mandanten/config/license/scanner/${encodeURIComponent(String(device_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostMandantClearCache (Auth)
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postMandantClearCache(observe?: 'body', reportProgress?: boolean): Observable<CacheClearInfo>;
    public postMandantClearCache(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CacheClearInfo>>;
    public postMandantClearCache(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CacheClearInfo>>;
    public postMandantClearCache(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CacheClearInfo>('post',`${this.basePath}/api/mandanten/clearcache`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
