import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_DIENSTPLAN = '[VeranstaltungenModul] GetVeranstaltungDienstplan laden';
export const GET_VERANSTALTUNG_DIENSTPLAN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungDienstplan ausführen';
export const GET_VERANSTALTUNG_DIENSTPLAN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungDienstplan laden erfolgreich';
export const GET_VERANSTALTUNG_DIENSTPLAN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungDienstplan Ladefehler';
export const GET_VERANSTALTUNG_DIENSTPLAN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungDienstplan nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungDienstplanAction implements Action {
    readonly type = GET_VERANSTALTUNG_DIENSTPLAN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDienstplanAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_DIENSTPLAN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDienstplanErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_DIENSTPLAN_ERFOLGREICH;
        constructor(public payload: Array<api.DienstplanFunktionsbereich>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDienstplanFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_DIENSTPLAN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDienstplanNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_DIENSTPLAN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungDienstplanActions =
    GetVeranstaltungDienstplanAction
    | GetVeranstaltungDienstplanAusfuehrenAction
    | GetVeranstaltungDienstplanErfolgreichAction
    | GetVeranstaltungDienstplanFehlerAction
    | GetVeranstaltungDienstplanNichtAusgefuehrtAction
    ;
