import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN = '[VeranstaltungenModul] GetVeranstaltungBenutzerverwaltungRolleBerechtigungen laden';
export const GET_VERANSTALTUNG_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungBenutzerverwaltungRolleBerechtigungen ausführen';
export const GET_VERANSTALTUNG_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungBenutzerverwaltungRolleBerechtigungen laden erfolgreich';
export const GET_VERANSTALTUNG_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungBenutzerverwaltungRolleBerechtigungen Ladefehler';
export const GET_VERANSTALTUNG_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungBenutzerverwaltungRolleBerechtigungen nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungBenutzerverwaltungRolleBerechtigungenAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBenutzerverwaltungRolleBerechtigungenAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_AUSFUEHREN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBenutzerverwaltungRolleBerechtigungenErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_ERFOLGREICH;
        constructor(public payload: api.BvRolleBerechtigung,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBenutzerverwaltungRolleBerechtigungenFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_FEHLER;
        constructor(public payload: any,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBenutzerverwaltungRolleBerechtigungenNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZERVERWALTUNG_ROLLE_BERECHTIGUNGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungBenutzerverwaltungRolleBerechtigungenActions =
    GetVeranstaltungBenutzerverwaltungRolleBerechtigungenAction
    | GetVeranstaltungBenutzerverwaltungRolleBerechtigungenAusfuehrenAction
    | GetVeranstaltungBenutzerverwaltungRolleBerechtigungenErfolgreichAction
    | GetVeranstaltungBenutzerverwaltungRolleBerechtigungenFehlerAction
    | GetVeranstaltungBenutzerverwaltungRolleBerechtigungenNichtAusgefuehrtAction
    ;
