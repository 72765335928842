import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ABTEILUNG_LISTE = '[IncidentsModul] GetAbteilungListe laden';
export const GET_ABTEILUNG_LISTE_AUSFUEHREN = '[IncidentsModul] GetAbteilungListe ausführen';
export const GET_ABTEILUNG_LISTE_ERFOLGREICH = '[IncidentsModul] GetAbteilungListe laden erfolgreich';
export const GET_ABTEILUNG_LISTE_FEHLER = '[IncidentsModul] GetAbteilungListe Ladefehler';
export const GET_ABTEILUNG_LISTE_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetAbteilungListe nicht ausgeführt (evtl. Offline)';



export class GetAbteilungListeAction implements Action {
    readonly type = GET_ABTEILUNG_LISTE;
        constructor( public funktionsbereich_id:string ,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetAbteilungListeAusfuehrenAction implements Action {
    readonly type = GET_ABTEILUNG_LISTE_AUSFUEHREN;
        constructor( public funktionsbereich_id:string ,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetAbteilungListeErfolgreichAction implements Action {
    readonly type = GET_ABTEILUNG_LISTE_ERFOLGREICH;
        constructor(public payload: api.IncidentsListResponse,  public funktionsbereich_id:string ,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetAbteilungListeFehlerAction implements Action {
    readonly type = GET_ABTEILUNG_LISTE_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string ,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetAbteilungListeNichtAusgefuehrtAction implements Action {
    readonly type = GET_ABTEILUNG_LISTE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryAbteilungListeActions =
    GetAbteilungListeAction
    | GetAbteilungListeAusfuehrenAction
    | GetAbteilungListeErfolgreichAction
    | GetAbteilungListeFehlerAction
    | GetAbteilungListeNichtAusgefuehrtAction
    ;
