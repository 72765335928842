import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'pinboard-pin',
    templateUrl: './pinboard-pin.component.html',
    styleUrls: ['./pinboard-pin.component.scss'],
})
export class PinboardPinComponent implements OnInit {

    // VALUES
    @Input() mainValue: string = null;
    @Input() rightRow1: string = null;
    @Input() rightRow1IconName: string = null;
    @Input() rightRow2: string = null;
    @Input() rightRow2IconName: string = null;
    @Input() subValue: string = null;

    @Input() iconIstOk = true;
    @Input() iconIstNichtOk = true;
    @Input() iconIstInBearbeitung = true;

    @Input() badgeEnabled = true;
    @Input() badgeValue: string = null;
    @Input() badgeStyleClass: string = null;

    constructor() {
    }

    ngOnInit() {
    }

}
