import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FUNKPROTOKOLL_EINTRAG_ERFASSEN = '[VeranstaltungenModul] PostFunkprotokollEintragErfassen laden';
export const POST_FUNKPROTOKOLL_EINTRAG_ERFASSEN_AUSFUEHREN = '[VeranstaltungenModul] PostFunkprotokollEintragErfassen ausführen';
export const POST_FUNKPROTOKOLL_EINTRAG_ERFASSEN_ERFOLGREICH = '[VeranstaltungenModul] PostFunkprotokollEintragErfassen laden erfolgreich';
export const POST_FUNKPROTOKOLL_EINTRAG_ERFASSEN_FEHLER = '[VeranstaltungenModul] PostFunkprotokollEintragErfassen Ladefehler';
export const POST_FUNKPROTOKOLL_EINTRAG_ERFASSEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFunkprotokollEintragErfassen nicht ausgeführt (evtl. Offline)';



export class PostFunkprotokollEintragErfassenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_EINTRAG_ERFASSEN;
        constructor(public funkprotokollEintragRequest: api.FunkprotokollEintragRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollEintragErfassenAusfuehrenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_EINTRAG_ERFASSEN_AUSFUEHREN;
        constructor(public funkprotokollEintragRequest: api.FunkprotokollEintragRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollEintragErfassenErfolgreichAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_EINTRAG_ERFASSEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funkprotokollEintragRequest: api.FunkprotokollEintragRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollEintragErfassenFehlerAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_EINTRAG_ERFASSEN_FEHLER;
        constructor(public payload: any, public funkprotokollEintragRequest: api.FunkprotokollEintragRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollEintragErfassenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_EINTRAG_ERFASSEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFunkprotokollEintragErfassenActions =
    PostFunkprotokollEintragErfassenAction
    | PostFunkprotokollEintragErfassenAusfuehrenAction
    | PostFunkprotokollEintragErfassenErfolgreichAction
    | PostFunkprotokollEintragErfassenFehlerAction
    | PostFunkprotokollEintragErfassenNichtAusgefuehrtAction
    ;
