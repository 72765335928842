import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_DOKUMENT_ADMIN = '[VeranstaltungenModul] GetVeranstaltungDokumentAdmin laden';
export const GET_VERANSTALTUNG_DOKUMENT_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungDokumentAdmin ausführen';
export const GET_VERANSTALTUNG_DOKUMENT_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungDokumentAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_DOKUMENT_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungDokumentAdmin Ladefehler';
export const GET_VERANSTALTUNG_DOKUMENT_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungDokumentAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungDokumentAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_ADMIN;
        constructor( public veranstaltung_id:string ,  public dokumentId:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumentAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public dokumentId:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumentAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_ADMIN_ERFOLGREICH;
        constructor(public payload: api.Dokument,  public veranstaltung_id:string ,  public dokumentId:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumentAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public dokumentId:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumentAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungDokumentAdminActions =
    GetVeranstaltungDokumentAdminAction
    | GetVeranstaltungDokumentAdminAusfuehrenAction
    | GetVeranstaltungDokumentAdminErfolgreichAction
    | GetVeranstaltungDokumentAdminFehlerAction
    | GetVeranstaltungDokumentAdminNichtAusgefuehrtAction
    ;
