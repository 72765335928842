import {Action} from '@ngrx/store';
import {Checkliste} from '../../../clients/vorlagen/model/checkliste';
import {Funktionsbereich} from '../../../clients/benutzerverwaltung/model/funktionsbereich';
import {Datenquelle} from './rollenselektion.model';

export const PAGE_STATE_RESET = '[RollenselektionModal] Page State reset';
export const SELEKTIERTE_ROLLEN = '[RollenselektionModal] Selektierte Rollen IDs';
export const ALLE_ANZEIGEN_SETZEN = '[RollenselektionModal] Alle Anzeigen setzen';
export const REFRESH_DATA = '[RollenselektionModal] Rollen erneut laden';


// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;

    constructor(public datenquelle: Datenquelle,
                public veranstaltungsId: string,
                public funktionsbereich: Funktionsbereich,
                public checkliste: Checkliste,
                public nichtAnzuzeigendeRollenIds: Array<string>,
                public alleAnzeigen: boolean) {
    }
}


// UI
export class SelektierteRollenAction implements Action {
    readonly type = SELEKTIERTE_ROLLEN;

    //
    constructor(public payload: Array<string>) {
    }
}

export class AlleAnzeigenSetzenAction implements Action {
    readonly type = ALLE_ANZEIGEN_SETZEN;

    constructor(public payload: boolean) {
    }
}

export class RefreshDataAction implements Action {
    readonly type = REFRESH_DATA;

    constructor(public datenquelle: Datenquelle) {
    }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
    SelektierteRollenAction
    | PageStateResetAction
    | AlleAnzeigenSetzenAction
    | RefreshDataAction
    ;
