import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL } from './variables';
import { AuthService } from '@orga-app/auth';

export interface BildUploadResponse {
  bild_id?: string;
}

@Injectable()
export class ImageService {

  public defaultHeaders: Headers = new Headers();
  public defaultHttpHeaders = new HttpHeaders();
  protected basePath = 'https://api-dev.orga-app.de';

  constructor(protected httpClient: HttpClient,
    @Inject(API_URL) basePath: string,
    protected authService: AuthService) {
    if (basePath) {
      this.basePath = basePath;
    }

  }

  public getImage(urlWithoutBaseUrl: string, extraHttpRequestParams?: any): Observable<Blob> {
    return this.httpClient
      .get(this.basePath + urlWithoutBaseUrl, {
        // headers: headers,
        responseType: 'blob'
      });
  }

  public getLocalImage(urlWithoutBaseUrl: string, extraHttpRequestParams?: any): Observable<Blob> {
    return this.httpClient
      .get(urlWithoutBaseUrl, {
        // headers: headers,
        responseType: 'blob'
      });
  }

  public urltoFile(url, filename, mimeType) {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
    return (fetch(url)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        return new File([buf], filename, { type: mimeType });
      })
    );
  }

  /**
   * GetAufgabeFehlerBild (BC:VERANSTALTUNGEN)
   *
   * @param aufgabeId
   * @param bildId
   */
  public getAufgabeFehlerBildWithHttpInfo(aufgabeId: string, bildId: string, extraHttpRequestParams?: any): Observable<Blob> {
    const id = bildId.replace(/\//g, '_~_');
    const path = '/api/query/veranstaltungen/aufgaben/{aufgabeId}/fehlerbild/{bildId}'
      .replace('{aufgabeId}', String(aufgabeId))
      .replace('{bildId}', String(id));
    return this.getImage(path);
  }

  /**
   * GetAufgabeBild (BC:VERANSTALTUNGEN)
   *
   * @param aufgabeId
   * @param bildId
   */
  public getAufgabeBemerkungBildWithHttpInfo(aufgabeId: string, bildId: string, extraHttpRequestParams?: any): Observable<Blob> {
    const id = bildId.replace(/\//g, '_~_');
    const path = '/api/query/veranstaltungen/aufgaben/{aufgabeId}/bild/{bildId}'
      .replace('{aufgabeId}', String(aufgabeId))
      .replace('{bildId}', String(id));
    return this.getImage(path);
  }

  /**
   * GetAufgabeVorlageBild (BC:VERANSTALTUNGEN)
   *
   * @param aufgabeId
   * @param bildId
   */
  public getAufgabeVorlageBildWithHttpInfo(aufgabeId: string, bildId: string, extraHttpRequestParams?: any): Observable<Blob> {
    const id = bildId.replace(/\//g, '_~_');
    const path = '/api/query/vorlagen/aufgaben/{aufgabeId}/bild/{bildId}'
        .replace('{aufgabeId}', String(aufgabeId))
        .replace('{bildId}', String(id));
    return this.getImage(path);
  }

  /**
   * GetIncidentFehlerBild (BC:INCIDENT)
   *
   * @param incidentId
   * @param bildId
   */
  public getIncidentBildWithHttpInfo(incidentId: string, bildId: string, extraHttpRequestParams?: any): Observable<Blob> {
    const id = bildId.replace(/\//g, '_~_');
    const path = '/api/incidents/query/incident/{incidentId}/bild/?bild_id={bildId}'
      .replace('{incidentId}', String(incidentId))
      .replace('{bildId}', String(id));
    return this.getImage(path);
  }


  /**
   * GetIncidentFehlerBildScaled (BC:INCIDENT)
   *
   * @param incidentId
   * @param bildId
   */
  public getIncidentBildScaledWithHttpInfo(incidentId: string, bildId: string, width: number, height: number, extraHttpRequestParams?: any): Observable<Blob> {
    const id = bildId.replace(/\//g, '_~_');
    const path = '/api/incidents/query/incident/{incidentId}/bild/scaled/?bild_id={bildId}&width={width}&height={height}'
      .replace('{incidentId}', String(incidentId))
      .replace('{bildId}', String(id))
      .replace('{width}', String(width))
      .replace('{height}', String(height));
    return this.getImage(path);
  }


  /**
   * getPruefungsaufgabeBildWithHttpInfo (BC:GASTROCHECKS)
   *
   * @param aufgabeId
   * @param bildId
   */
  public getPruefungsaufgabeBildWithHttpInfo(prueflaufId: string, aufgabeId: string, bildId: string, width: number = 400, height: number = 400): Observable<Blob> {
    const path = '/api/qschecks/query/prueflauf/{prueflaufId}/aufgaben/{aufgabeId}/bild?bild_id={bildId}&width={width}&height={height}'
      .replace('{prueflaufId}', String(prueflaufId))
      .replace('{aufgabeId}', String(aufgabeId))
      .replace('{bildId}', String(bildId))
      .replace('{width}', String(width))
      .replace('{height}', String(height))
      ;

    return this.getImage(path);
  }

}
