import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'sopi-footer',
    templateUrl: './sopi-footer.component.html',
    styleUrls: ['./sopi-footer.component.scss'],
})
export class SopiFooterComponent implements OnInit {

    // DESIGN
    @Input() borderRadiusTop = false;
    @Input() borderRadiusTopRight = false;
    @Input() borderRadiusTopLeft = false;
    @Input() borderRadiusBottom = false;
    @Input() borderRadiusBottomRight = false;
    @Input() borderRadiusBottomLeft = false;

    constructor() {
    }

    ngOnInit() {
    }

}
