import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_BILD_ENTFERNEN = '[VorlagenModul] PostAufgabeBildEntfernen laden';
export const POST_AUFGABE_BILD_ENTFERNEN_AUSFUEHREN = '[VorlagenModul] PostAufgabeBildEntfernen ausführen';
export const POST_AUFGABE_BILD_ENTFERNEN_ERFOLGREICH = '[VorlagenModul] PostAufgabeBildEntfernen laden erfolgreich';
export const POST_AUFGABE_BILD_ENTFERNEN_FEHLER = '[VorlagenModul] PostAufgabeBildEntfernen Ladefehler';
export const POST_AUFGABE_BILD_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostAufgabeBildEntfernen nicht ausgeführt (evtl. Offline)';



export class PostAufgabeBildEntfernenAction implements Action {
    readonly type = POST_AUFGABE_BILD_ENTFERNEN;
        constructor(public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildEntfernenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_BILD_ENTFERNEN_AUSFUEHREN;
        constructor(public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildEntfernenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_BILD_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildEntfernenFehlerAction implements Action {
    readonly type = POST_AUFGABE_BILD_ENTFERNEN_FEHLER;
        constructor(public payload: any, public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_BILD_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandAufgabeBildEntfernenActions =
    PostAufgabeBildEntfernenAction
    | PostAufgabeBildEntfernenAusfuehrenAction
    | PostAufgabeBildEntfernenErfolgreichAction
    | PostAufgabeBildEntfernenFehlerAction
    | PostAufgabeBildEntfernenNichtAusgefuehrtAction
    ;
