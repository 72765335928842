import { Injectable, Injector } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { BehaviorSubject, Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { filter, map, tap } from 'rxjs/operators';
import { WebsocketService } from '@orga-app/websocket';
import { START_APP_ACTION, StartAppAction } from '@orga-app/appstart/actions';
import { MitteilungNotification } from '../clients/kommunikation';
import { IncidentsNotificationTypes, KommunikationNotificationTypes, MandantenNotificationTypes, NotificationTypes, VeranstaltungenNotificationtypes } from '../client-constants';
import { BenutzerZugriffUpdatedNotification, CommandFailedNotification, CommandSucceededNotification, MandantConfigUpdatedNotification, MyNotificationMessage, ReadModelUpdatedNotification } from '../clients/api';
import { WsCommandFailedAction, WsCommandSucceededAction, WsMandantConfigUpdatedAction, WsMitteilungAction, WsReadModelUpdatedAction, WsAufgabeErinnerungAction, WsAufgabeEskalationAction, WsAufgabeNichtOkAction, WsFunktionsbereichOkAction, WsMeinDienstplanAction, WsBenutzerZugriffUpdatedAction, WsIncidentManagementZentraleAction, WsIncidentManagementAbteilungAction, WsIncidentManagementDelegationAction } from './websocket.actions';
import { AufgabeErinnerungNotification, AufgabeEskalationNotification, AufgabeNichtOkNotification, FunktionsbereichOkNotification, MeinDienstplanNotification } from 'src/clients/veranstaltungen';
import { IncidentAbteilungNotification, IncidentDelegationNotification, IncidentZentraleNotification } from 'src/clients/incidents';


export interface NotificationMessageHelper<T> {
  content: T;
  message: MyNotificationMessage;
}

@Injectable()
export class WebsocketInitEffects {

  public notification$: Observable<MyNotificationMessage>;
  private notifications: BehaviorSubject<MyNotificationMessage> = new BehaviorSubject(null);
  private alreadyRegistered = false;

  @Effect({ dispatch: false })
  appStarted$: Observable<Action> = this.actions$.pipe(
    ofType<StartAppAction>(START_APP_ACTION)
    , filter(x => !this.alreadyRegistered)
    , tap(() => {
      console.debug('Websocket.init.effects :: appStarted$');
      this.getWebsocketService().register('Notitfy', this.notifications);
      this.alreadyRegistered = true;
    })
  );


  private websocketService: WebsocketService = null;
  // private tokenService: TokenService;

  constructor(private store: Store<any>,
    private actions$: Actions,
    private injector: Injector
  ) {
    console.debug('Websocket.init.effects :: constructor');
    this.notification$ = this.notifications.asObservable();

    this.subscribeToNotification<ReadModelUpdatedNotification>(NotificationTypes.READ_MODEL_UPDATED_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsReadModelUpdatedAction(x.content));
    });

    this.subscribeToNotification<MandantConfigUpdatedNotification>(MandantenNotificationTypes.MANDANT_CONFIG_UPDATED_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsMandantConfigUpdatedAction(x.content));
    });

    this.subscribeToNotification<CommandSucceededNotification>(NotificationTypes.COMMAND_SUCCEEDED_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsCommandSucceededAction(x.content));
    });

    this.subscribeToNotification<CommandFailedNotification>(NotificationTypes.COMMAND_FAILED_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsCommandFailedAction(x.content));
    });

    this.subscribeToNotification<BenutzerZugriffUpdatedNotification>(NotificationTypes.BENUTZER_ZUGRIFF_UPDATED_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsBenutzerZugriffUpdatedAction(x.content));
    });

    // MESSAGES
    this.subscribeToNotification<MitteilungNotification>(KommunikationNotificationTypes.MITTEILUNG_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsMitteilungAction(x.content));
    });

    this.subscribeToNotification<AufgabeErinnerungNotification>(VeranstaltungenNotificationtypes.AUFGABE_ERINNERUNG_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsAufgabeErinnerungAction(x.content));
    });

    this.subscribeToNotification<AufgabeEskalationNotification>(VeranstaltungenNotificationtypes.AUFGABE_ESKALATION_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsAufgabeEskalationAction(x.content));
    });

    this.subscribeToNotification<AufgabeNichtOkNotification>(VeranstaltungenNotificationtypes.AUFGABE_NICHT_OK_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsAufgabeNichtOkAction(x.content));
    });

    this.subscribeToNotification<FunktionsbereichOkNotification>(VeranstaltungenNotificationtypes.FUNKTIONSBEREICH_OK_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsFunktionsbereichOkAction(x.content));
    });

    this.subscribeToNotification<MeinDienstplanNotification>(VeranstaltungenNotificationtypes.MEIN_DIENSTPLAN_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsMeinDienstplanAction(x.content));
    });

    this.subscribeToNotification<IncidentZentraleNotification>(IncidentsNotificationTypes.INCIDENT_ZENTRALE_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsIncidentManagementZentraleAction(x.content));
    });

    this.subscribeToNotification<IncidentAbteilungNotification>(IncidentsNotificationTypes.INCIDENT_ABTEILUNG_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsIncidentManagementAbteilungAction(x.content));
    });

    this.subscribeToNotification<IncidentDelegationNotification>(IncidentsNotificationTypes.INCIDENT_DELEGATION_NOTIFICATION).subscribe(x => {
      this.store.dispatch(new WsIncidentManagementDelegationAction(x.content));
    });

  }

  public subscribeToNotification<T>(notificationType: string): Observable<NotificationMessageHelper<T>> {
    console.debug('Websocket.init.effects :: subscribeToNotification', notificationType);
    return this.notification$.pipe(
      filter(x => {
        return x !== undefined && x !== null;
      }),
      filter(x => {
        return x.notification_type !== undefined && x.notification_type !== null;
      }),
      filter(x => {
        return x.notification_type.toLowerCase() === notificationType.toLowerCase();
      }),
      filter(x => {
        return x.content !== undefined && x.content != null;
      }),
      map(x => {
        const t: T = JSON.parse(x.content);
        return {
          content: t,
          message: x
        } as NotificationMessageHelper<T>;
      })
    );
  }

  private getWebsocketService(): WebsocketService {
    console.debug('Websocket.init.effects :: getWebsocketService');
    if (this.websocketService == null) {
      this.websocketService = this.injector.get(WebsocketService);
    }

    return this.websocketService;
  }
}
