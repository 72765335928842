import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_PRUEFLAUF = '[QschecksModul] GetVeranstaltungPrueflauf laden';
export const GET_VERANSTALTUNG_PRUEFLAUF_AUSFUEHREN = '[QschecksModul] GetVeranstaltungPrueflauf ausführen';
export const GET_VERANSTALTUNG_PRUEFLAUF_ERFOLGREICH = '[QschecksModul] GetVeranstaltungPrueflauf laden erfolgreich';
export const GET_VERANSTALTUNG_PRUEFLAUF_FEHLER = '[QschecksModul] GetVeranstaltungPrueflauf Ladefehler';
export const GET_VERANSTALTUNG_PRUEFLAUF_NICHT_AUSGEFUEHRT = '[QschecksModul] GetVeranstaltungPrueflauf nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungPrueflaufAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFLAUF;
        constructor(public prueflaufRequest: api.PrueflaufRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungPrueflaufAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFLAUF_AUSFUEHREN;
        constructor(public prueflaufRequest: api.PrueflaufRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungPrueflaufErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFLAUF_ERFOLGREICH;
        constructor(public payload: api.PrueflaufDetail, public prueflaufRequest: api.PrueflaufRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungPrueflaufFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFLAUF_FEHLER;
        constructor(public payload: any, public prueflaufRequest: api.PrueflaufRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungPrueflaufNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFLAUF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandVeranstaltungPrueflaufActions =
    GetVeranstaltungPrueflaufAction
    | GetVeranstaltungPrueflaufAusfuehrenAction
    | GetVeranstaltungPrueflaufErfolgreichAction
    | GetVeranstaltungPrueflaufFehlerAction
    | GetVeranstaltungPrueflaufNichtAusgefuehrtAction
    ;
