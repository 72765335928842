import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_COMMAND_SUCCEEDED_NOTIFICATION = '[ApiModul] GetCommandSucceededNotification laden';
export const GET_COMMAND_SUCCEEDED_NOTIFICATION_AUSFUEHREN = '[ApiModul] GetCommandSucceededNotification ausführen';
export const GET_COMMAND_SUCCEEDED_NOTIFICATION_ERFOLGREICH = '[ApiModul] GetCommandSucceededNotification laden erfolgreich';
export const GET_COMMAND_SUCCEEDED_NOTIFICATION_FEHLER = '[ApiModul] GetCommandSucceededNotification Ladefehler';
export const GET_COMMAND_SUCCEEDED_NOTIFICATION_NICHT_AUSGEFUEHRT = '[ApiModul] GetCommandSucceededNotification nicht ausgeführt (evtl. Offline)';



export class GetCommandSucceededNotificationAction implements Action {
    readonly type = GET_COMMAND_SUCCEEDED_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetCommandSucceededNotificationAusfuehrenAction implements Action {
    readonly type = GET_COMMAND_SUCCEEDED_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetCommandSucceededNotificationErfolgreichAction implements Action {
    readonly type = GET_COMMAND_SUCCEEDED_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.CommandSucceededNotification, public optPayload: any = null) {}
    }
export class GetCommandSucceededNotificationFehlerAction implements Action {
    readonly type = GET_COMMAND_SUCCEEDED_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetCommandSucceededNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_COMMAND_SUCCEEDED_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryCommandSucceededNotificationActions =
    GetCommandSucceededNotificationAction
    | GetCommandSucceededNotificationAusfuehrenAction
    | GetCommandSucceededNotificationErfolgreichAction
    | GetCommandSucceededNotificationFehlerAction
    | GetCommandSucceededNotificationNichtAusgefuehrtAction
    ;
