import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ModuleState, PageState} from './displaysteuerung.reducer';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';
import {BereichDetail, BereichTrigger} from '../../../clients/displaysteuerung';

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const getModuleState = createFeatureSelector<ModuleState>('DisplaysteuerungPage');
export const getPageState = createSelector(getModuleState, (state: ModuleState) => state.page);

export const getBereicheListe = createSelector(getPageState, (state: PageState) => {
    if (state.bereicheListe === null) {
        return [];
    }
    return state.bereicheListe
        .map((bereich) => <ListInputItem>{
            group: null,
            groupTitle: null,
            itemId: bereich.bereich_id,
            itemTitle: bereich.titel,
            item: bereich
        });
});
export const getBereicheListeLaden = createSelector(getPageState, (state: PageState) => state.bereicheListeLaden);
export const getBereicheListeFehler = createSelector(getPageState, (state: PageState) => state.bereicheListeFehler);

export const getSelectedBereich = createSelector(getPageState, (state: PageState) => state.selectedBereich);

export const getBereichDetail = createSelector(getPageState, (state: PageState) => state.selectedBereichDetail);
export const getBereichDetailLaden = createSelector(getPageState, (state: PageState) => state.selectedBereichDetailLaden);
export const getBereichDetailFehler = createSelector(getPageState, (state: PageState) => state.selectedBereichDetailFehler);

export const getBerechtigungRollen = createSelector(getPageState, (state: PageState) => {
    if (state.selectedBereichDetail == null){
        return [];
    }
    return state.selectedBereichDetail.berechtigung_rollen
        .map((rolle) => <ListInputItem>{
            group: null,
            groupTitle: null,
            itemId: rolle.rolle_id,
            itemTitle: rolle.titel,
            item: rolle
        });
});

export const getSelectedRolle = createSelector(getPageState, (state: PageState) => state.selectedRolle);

export const getTriggerListe = createSelector(getBereichDetail, (detail: BereichDetail) => {
    if (detail === null || detail.trigger === null) {
        return [];
    }
    return detail.trigger
        .map((trigger: BereichTrigger) => <ListInputItem>{
            group: null,
            groupTitle: null,
            itemId: trigger.trigger_id,
            itemTitle: trigger.anzeige_name,
            item: trigger
        });
});

export const getTriggerIds = createSelector(getTriggerListe, (triggers: Array<ListInputItem>) => {
    return triggers.map(item => item.itemId);
});

export const getSelectedTrigger = createSelector(getPageState, (state: PageState) => state.selectedTrigger);

