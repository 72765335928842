import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const POST_MITTEILUNGS_VORLAGE_ANLEGEN = '[KommunikationModul] PostMitteilungsVorlageAnlegen laden';
export const POST_MITTEILUNGS_VORLAGE_ANLEGEN_AUSFUEHREN = '[KommunikationModul] PostMitteilungsVorlageAnlegen ausführen';
export const POST_MITTEILUNGS_VORLAGE_ANLEGEN_ERFOLGREICH = '[KommunikationModul] PostMitteilungsVorlageAnlegen laden erfolgreich';
export const POST_MITTEILUNGS_VORLAGE_ANLEGEN_FEHLER = '[KommunikationModul] PostMitteilungsVorlageAnlegen Ladefehler';
export const POST_MITTEILUNGS_VORLAGE_ANLEGEN_NICHT_AUSGEFUEHRT = '[KommunikationModul] PostMitteilungsVorlageAnlegen nicht ausgeführt (evtl. Offline)';



export class PostMitteilungsVorlageAnlegenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_ANLEGEN;
        constructor(public mitteilungsVorlageDatenRequest: api.MitteilungsVorlageDatenRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageAnlegenAusfuehrenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_ANLEGEN_AUSFUEHREN;
        constructor(public mitteilungsVorlageDatenRequest: api.MitteilungsVorlageDatenRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageAnlegenErfolgreichAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_ANLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public mitteilungsVorlageDatenRequest: api.MitteilungsVorlageDatenRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageAnlegenFehlerAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_ANLEGEN_FEHLER;
        constructor(public payload: any, public mitteilungsVorlageDatenRequest: api.MitteilungsVorlageDatenRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageAnlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_ANLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationCommandMitteilungsVorlageAnlegenActions =
    PostMitteilungsVorlageAnlegenAction
    | PostMitteilungsVorlageAnlegenAusfuehrenAction
    | PostMitteilungsVorlageAnlegenErfolgreichAction
    | PostMitteilungsVorlageAnlegenFehlerAction
    | PostMitteilungsVorlageAnlegenNichtAusgefuehrtAction
    ;
