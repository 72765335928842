import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_VEROEFFENTLICHEN = '[VeranstaltungenModul] PostVeranstaltungVeroeffentlichen laden';
export const POST_VERANSTALTUNG_VEROEFFENTLICHEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungVeroeffentlichen ausführen';
export const POST_VERANSTALTUNG_VEROEFFENTLICHEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungVeroeffentlichen laden erfolgreich';
export const POST_VERANSTALTUNG_VEROEFFENTLICHEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungVeroeffentlichen Ladefehler';
export const POST_VERANSTALTUNG_VEROEFFENTLICHEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungVeroeffentlichen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungVeroeffentlichenAction implements Action {
    readonly type = POST_VERANSTALTUNG_VEROEFFENTLICHEN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungVeroeffentlichenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_VEROEFFENTLICHEN_AUSFUEHREN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungVeroeffentlichenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_VEROEFFENTLICHEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungVeroeffentlichenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_VEROEFFENTLICHEN_FEHLER;
        constructor(public payload: any, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungVeroeffentlichenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_VEROEFFENTLICHEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungVeroeffentlichenActions =
    PostVeranstaltungVeroeffentlichenAction
    | PostVeranstaltungVeroeffentlichenAusfuehrenAction
    | PostVeranstaltungVeroeffentlichenErfolgreichAction
    | PostVeranstaltungVeroeffentlichenFehlerAction
    | PostVeranstaltungVeroeffentlichenNichtAusgefuehrtAction
    ;
