import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {AufgabePage} from './aufgabe.page';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {SopiComponentsModule} from '../../shared/sopi-components/sopi-components.module';
import {SopiDirectivesModule} from '../../../sopi/directives/sopi-directives.module';
import {TranslateModule} from '@ngx-translate/core';

const routes: Routes = [
    {
        path: '',
        component: AufgabePage,
        data: { title: 'Aufgabe' }
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SopiScrollModule,
        RouterModule.forChild(routes),
        SopiComponentsModule.forRoot(),
        SopiDirectivesModule.forRoot(),
        TranslateModule,
    ],
    declarations: [AufgabePage],
    entryComponents: [
        AufgabePage
    ]
})

export class AufgabePageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: AufgabePageModule,
        };
    }
}
