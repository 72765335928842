import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_INFO_KATEGORIE_REIHENFOLGE_SETZEN = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieReihenfolgeSetzen laden';
export const POST_VERANSTALTUNG_INFO_KATEGORIE_REIHENFOLGE_SETZEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieReihenfolgeSetzen ausführen';
export const POST_VERANSTALTUNG_INFO_KATEGORIE_REIHENFOLGE_SETZEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieReihenfolgeSetzen laden erfolgreich';
export const POST_VERANSTALTUNG_INFO_KATEGORIE_REIHENFOLGE_SETZEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieReihenfolgeSetzen Ladefehler';
export const POST_VERANSTALTUNG_INFO_KATEGORIE_REIHENFOLGE_SETZEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieReihenfolgeSetzen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungInfoKategorieReihenfolgeSetzenAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_REIHENFOLGE_SETZEN;
        constructor(public veranstaltungInfoKategorieReihenfolgeSetzenMultiRequest: api.VeranstaltungInfoKategorieReihenfolgeSetzenMultiRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungInfoKategorieReihenfolgeSetzenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_REIHENFOLGE_SETZEN_AUSFUEHREN;
        constructor(public veranstaltungInfoKategorieReihenfolgeSetzenMultiRequest: api.VeranstaltungInfoKategorieReihenfolgeSetzenMultiRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungInfoKategorieReihenfolgeSetzenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_REIHENFOLGE_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungInfoKategorieReihenfolgeSetzenMultiRequest: api.VeranstaltungInfoKategorieReihenfolgeSetzenMultiRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungInfoKategorieReihenfolgeSetzenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_REIHENFOLGE_SETZEN_FEHLER;
        constructor(public payload: any, public veranstaltungInfoKategorieReihenfolgeSetzenMultiRequest: api.VeranstaltungInfoKategorieReihenfolgeSetzenMultiRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungInfoKategorieReihenfolgeSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_REIHENFOLGE_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungInfoKategorieReihenfolgeSetzenActions =
    PostVeranstaltungInfoKategorieReihenfolgeSetzenAction
    | PostVeranstaltungInfoKategorieReihenfolgeSetzenAusfuehrenAction
    | PostVeranstaltungInfoKategorieReihenfolgeSetzenErfolgreichAction
    | PostVeranstaltungInfoKategorieReihenfolgeSetzenFehlerAction
    | PostVeranstaltungInfoKategorieReihenfolgeSetzenNichtAusgefuehrtAction
    ;
