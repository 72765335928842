import {Action} from '@ngrx/store';
import {MenuPageDefiniton} from './navi.model';


export const NAVI_SETZEN = '[Navi] Navi setzen';
export const NAVI_BENUTZER_ZUGRIFFE_LADEN = '[Navi] Benutzerzugriffe für alle Boundet Contexte laden';
export const NAVI_BOUNDED_CONTEXT_ZUGRIFF_SETZEN = '[Navi] Bounded Context Zugriff setzen';
export const NAVI_BOUNDED_CONTEXT_VORLAGEN_SETZEN = '[Navi] Bounded Context Vorlagen setzen';
export const NAVI_ZUGRIFF_SETZEN = '[Navi] Zugriff auf Navigationspunkte aktivieren/deaktiveren per Key';




export class NaviSetzenAction implements Action {
    readonly type = NAVI_SETZEN;

    constructor(public pages: Array<MenuPageDefiniton>) {
    }
}

export class NaviBenutzerZugriffeLadenAction implements Action {
    readonly type = NAVI_BENUTZER_ZUGRIFFE_LADEN;

    constructor() {
    }
}

export class NaviBoundedContextZugriffSetzenAction implements Action {
    readonly type = NAVI_BOUNDED_CONTEXT_ZUGRIFF_SETZEN;

    constructor(public boundedContect: string, public zugriff: any) {
    }
}

export class NaviBoundedContextVorlagenSetzenAction implements Action {
    readonly type = NAVI_BOUNDED_CONTEXT_VORLAGEN_SETZEN;

    constructor(public boundedContect: string, public zugriff: any) {
    }
}

export class NaviZugriffSetzenAction implements Action {
    readonly type = NAVI_ZUGRIFF_SETZEN;

    constructor(public key: string, public value: boolean) {
    }
}


export type Actions =
    NaviBoundedContextZugriffSetzenAction
    | NaviSetzenAction
    | NaviZugriffSetzenAction
    | NaviBoundedContextVorlagenSetzenAction
    | NaviBenutzerZugriffeLadenAction
    ;
