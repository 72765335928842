import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_FUNKPROTOKOLL_FALL = '[VeranstaltungenModul] GetFunkprotokollFall laden';
export const GET_FUNKPROTOKOLL_FALL_AUSFUEHREN = '[VeranstaltungenModul] GetFunkprotokollFall ausführen';
export const GET_FUNKPROTOKOLL_FALL_ERFOLGREICH = '[VeranstaltungenModul] GetFunkprotokollFall laden erfolgreich';
export const GET_FUNKPROTOKOLL_FALL_FEHLER = '[VeranstaltungenModul] GetFunkprotokollFall Ladefehler';
export const GET_FUNKPROTOKOLL_FALL_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunkprotokollFall nicht ausgeführt (evtl. Offline)';



export class GetFunkprotokollFallAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_FALL;
        constructor( public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollFallAusfuehrenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_FALL_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollFallErfolgreichAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_FALL_ERFOLGREICH;
        constructor(public payload: api.FunkprotokollFall,  public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollFallFehlerAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_FALL_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollFallNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_FALL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunkprotokollFallActions =
    GetFunkprotokollFallAction
    | GetFunkprotokollFallAusfuehrenAction
    | GetFunkprotokollFallErfolgreichAction
    | GetFunkprotokollFallFehlerAction
    | GetFunkprotokollFallNichtAusgefuehrtAction
    ;
