import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_AUFGABE_FEHLER = '[VeranstaltungenModul] GetAufgabeFehler laden';
export const GET_AUFGABE_FEHLER_AUSFUEHREN = '[VeranstaltungenModul] GetAufgabeFehler ausführen';
export const GET_AUFGABE_FEHLER_ERFOLGREICH = '[VeranstaltungenModul] GetAufgabeFehler laden erfolgreich';
export const GET_AUFGABE_FEHLER_FEHLER = '[VeranstaltungenModul] GetAufgabeFehler Ladefehler';
export const GET_AUFGABE_FEHLER_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetAufgabeFehler nicht ausgeführt (evtl. Offline)';



export class GetAufgabeFehlerAction implements Action {
    readonly type = GET_AUFGABE_FEHLER;
        constructor( public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_AUSFUEHREN;
        constructor( public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_ERFOLGREICH;
        constructor(public payload: api.Fehler,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerFehlerAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_FEHLER;
        constructor(public payload: any,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryAufgabeFehlerActions =
    GetAufgabeFehlerAction
    | GetAufgabeFehlerAusfuehrenAction
    | GetAufgabeFehlerErfolgreichAction
    | GetAufgabeFehlerFehlerAction
    | GetAufgabeFehlerNichtAusgefuehrtAction
    ;
