

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_PRUEFBERICHT_XML_TEMPLATE = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXmlTemplate laden';
export const GET_VERANSTALTUNG_PRUEFBERICHT_XML_TEMPLATE_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXmlTemplate ausführen';
export const GET_VERANSTALTUNG_PRUEFBERICHT_XML_TEMPLATE_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXmlTemplate laden erfolgreich';
export const GET_VERANSTALTUNG_PRUEFBERICHT_XML_TEMPLATE_FEHLER = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXmlTemplate Ladefehler';
export const GET_VERANSTALTUNG_PRUEFBERICHT_XML_TEMPLATE_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXmlTemplate nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungPruefberichtXmlTemplateAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XML_TEMPLATE;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtXmlTemplateAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XML_TEMPLATE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtXmlTemplateErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XML_TEMPLATE_ERFOLGREICH;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtXmlTemplateFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XML_TEMPLATE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtXmlTemplateNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XML_TEMPLATE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungPruefberichtXmlTemplateActions =
    GetVeranstaltungPruefberichtXmlTemplateAction
    | GetVeranstaltungPruefberichtXmlTemplateAusfuehrenAction
    | GetVeranstaltungPruefberichtXmlTemplateErfolgreichAction
    | GetVeranstaltungPruefberichtXmlTemplateFehlerAction
    | GetVeranstaltungPruefberichtXmlTemplateNichtAusgefuehrtAction
    ;
