import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICHE_ROLLEN_ADMIN = '[BenutzerverwaltungModul] GetFunktionsbereicheRollenAdmin laden';
export const GET_FUNKTIONSBEREICHE_ROLLEN_ADMIN_AUSFUEHREN = '[BenutzerverwaltungModul] GetFunktionsbereicheRollenAdmin ausführen';
export const GET_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ERFOLGREICH = '[BenutzerverwaltungModul] GetFunktionsbereicheRollenAdmin laden erfolgreich';
export const GET_FUNKTIONSBEREICHE_ROLLEN_ADMIN_FEHLER = '[BenutzerverwaltungModul] GetFunktionsbereicheRollenAdmin Ladefehler';
export const GET_FUNKTIONSBEREICHE_ROLLEN_ADMIN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetFunktionsbereicheRollenAdmin nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereicheRollenAdminAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_ADMIN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereicheRollenAdminAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_ADMIN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereicheRollenAdminErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichRollen>, public optPayload: any = null) {}
    }
export class GetFunktionsbereicheRollenAdminFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_ADMIN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFunktionsbereicheRollenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryFunktionsbereicheRollenAdminActions =
    GetFunktionsbereicheRollenAdminAction
    | GetFunktionsbereicheRollenAdminAusfuehrenAction
    | GetFunktionsbereicheRollenAdminErfolgreichAction
    | GetFunktionsbereicheRollenAdminFehlerAction
    | GetFunktionsbereicheRollenAdminNichtAusgefuehrtAction
    ;
