import * as api from '../../../clients/einlasskontrolle'; 

import {Action} from '@ngrx/store';

export const GET_EINLASSKONTROLLE_CONSTANTS = '[EinlasskontrolleModul] GetEinlasskontrolleConstants laden';
export const GET_EINLASSKONTROLLE_CONSTANTS_AUSFUEHREN = '[EinlasskontrolleModul] GetEinlasskontrolleConstants ausführen';
export const GET_EINLASSKONTROLLE_CONSTANTS_ERFOLGREICH = '[EinlasskontrolleModul] GetEinlasskontrolleConstants laden erfolgreich';
export const GET_EINLASSKONTROLLE_CONSTANTS_FEHLER = '[EinlasskontrolleModul] GetEinlasskontrolleConstants Ladefehler';
export const GET_EINLASSKONTROLLE_CONSTANTS_NICHT_AUSGEFUEHRT = '[EinlasskontrolleModul] GetEinlasskontrolleConstants nicht ausgeführt (evtl. Offline)';



export class GetEinlasskontrolleConstantsAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CONSTANTS;
        constructor(public optPayload: any = null) {}
    }
export class GetEinlasskontrolleConstantsAusfuehrenAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CONSTANTS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetEinlasskontrolleConstantsErfolgreichAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CONSTANTS_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetEinlasskontrolleConstantsFehlerAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CONSTANTS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetEinlasskontrolleConstantsNichtAusgefuehrtAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CONSTANTS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type EinlasskontrolleQueryEinlasskontrolleConstantsActions =
    GetEinlasskontrolleConstantsAction
    | GetEinlasskontrolleConstantsAusfuehrenAction
    | GetEinlasskontrolleConstantsErfolgreichAction
    | GetEinlasskontrolleConstantsFehlerAction
    | GetEinlasskontrolleConstantsNichtAusgefuehrtAction
    ;
