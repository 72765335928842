import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICHE_ROLLEN_ALLE = '[BenutzerverwaltungModul] GetFunktionsbereicheRollenAlle laden';
export const GET_FUNKTIONSBEREICHE_ROLLEN_ALLE_AUSFUEHREN = '[BenutzerverwaltungModul] GetFunktionsbereicheRollenAlle ausführen';
export const GET_FUNKTIONSBEREICHE_ROLLEN_ALLE_ERFOLGREICH = '[BenutzerverwaltungModul] GetFunktionsbereicheRollenAlle laden erfolgreich';
export const GET_FUNKTIONSBEREICHE_ROLLEN_ALLE_FEHLER = '[BenutzerverwaltungModul] GetFunktionsbereicheRollenAlle Ladefehler';
export const GET_FUNKTIONSBEREICHE_ROLLEN_ALLE_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetFunktionsbereicheRollenAlle nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereicheRollenAlleAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_ALLE;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereicheRollenAlleAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_ALLE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereicheRollenAlleErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_ALLE_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichRollen>, public optPayload: any = null) {}
    }
export class GetFunktionsbereicheRollenAlleFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_ALLE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFunktionsbereicheRollenAlleNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_ALLE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryFunktionsbereicheRollenAlleActions =
    GetFunktionsbereicheRollenAlleAction
    | GetFunktionsbereicheRollenAlleAusfuehrenAction
    | GetFunktionsbereicheRollenAlleErfolgreichAction
    | GetFunktionsbereicheRollenAlleFehlerAction
    | GetFunktionsbereicheRollenAlleNichtAusgefuehrtAction
    ;
