import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_MELDUNG_ERFASSEN = '[IncidentsModul] PostIncidentMeldungErfassen laden';
export const POST_INCIDENT_MELDUNG_ERFASSEN_AUSFUEHREN = '[IncidentsModul] PostIncidentMeldungErfassen ausführen';
export const POST_INCIDENT_MELDUNG_ERFASSEN_ERFOLGREICH = '[IncidentsModul] PostIncidentMeldungErfassen laden erfolgreich';
export const POST_INCIDENT_MELDUNG_ERFASSEN_FEHLER = '[IncidentsModul] PostIncidentMeldungErfassen Ladefehler';
export const POST_INCIDENT_MELDUNG_ERFASSEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentMeldungErfassen nicht ausgeführt (evtl. Offline)';



export class PostIncidentMeldungErfassenAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_ERFASSEN;
        constructor(public incidentMeldungRequest: api.IncidentMeldungRequest, public optPayload: any = null) {}
    }
export class PostIncidentMeldungErfassenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_ERFASSEN_AUSFUEHREN;
        constructor(public incidentMeldungRequest: api.IncidentMeldungRequest, public optPayload: any = null) {}
    }
export class PostIncidentMeldungErfassenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_ERFASSEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentMeldungRequest: api.IncidentMeldungRequest, public optPayload: any = null) {}
    }
export class PostIncidentMeldungErfassenFehlerAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_ERFASSEN_FEHLER;
        constructor(public payload: any, public incidentMeldungRequest: api.IncidentMeldungRequest, public optPayload: any = null) {}
    }
export class PostIncidentMeldungErfassenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_ERFASSEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentMeldungErfassenActions =
    PostIncidentMeldungErfassenAction
    | PostIncidentMeldungErfassenAusfuehrenAction
    | PostIncidentMeldungErfassenErfolgreichAction
    | PostIncidentMeldungErfassenFehlerAction
    | PostIncidentMeldungErfassenNichtAusgefuehrtAction
    ;
