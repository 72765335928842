

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_XML = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtXml laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_XML_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtXml ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_XML_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtXml laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_XML_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtXml Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_XML_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtXml nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereichPruefberichtXmlAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_XML;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichPruefberichtXmlAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_XML_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichPruefberichtXmlErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_XML_ERFOLGREICH;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichPruefberichtXmlFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_XML_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichPruefberichtXmlNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_XML_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereichPruefberichtXmlActions =
    GetVeranstaltungFunktionsbereichPruefberichtXmlAction
    | GetVeranstaltungFunktionsbereichPruefberichtXmlAusfuehrenAction
    | GetVeranstaltungFunktionsbereichPruefberichtXmlErfolgreichAction
    | GetVeranstaltungFunktionsbereichPruefberichtXmlFehlerAction
    | GetVeranstaltungFunktionsbereichPruefberichtXmlNichtAusgefuehrtAction
    ;
