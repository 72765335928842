import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_MANDANT_INFO = '[ApiModul] GetMandantInfo laden';
export const GET_MANDANT_INFO_AUSFUEHREN = '[ApiModul] GetMandantInfo ausführen';
export const GET_MANDANT_INFO_ERFOLGREICH = '[ApiModul] GetMandantInfo laden erfolgreich';
export const GET_MANDANT_INFO_FEHLER = '[ApiModul] GetMandantInfo Ladefehler';
export const GET_MANDANT_INFO_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantInfo nicht ausgeführt (evtl. Offline)';



export class GetMandantInfoAction implements Action {
    readonly type = GET_MANDANT_INFO;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantInfoAusfuehrenAction implements Action {
    readonly type = GET_MANDANT_INFO_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantInfoErfolgreichAction implements Action {
    readonly type = GET_MANDANT_INFO_ERFOLGREICH;
        constructor(public payload: api.MandantInfo, public optPayload: any = null) {}
    }
export class GetMandantInfoFehlerAction implements Action {
    readonly type = GET_MANDANT_INFO_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantInfoNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANT_INFO_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantInfoActions =
    GetMandantInfoAction
    | GetMandantInfoAusfuehrenAction
    | GetMandantInfoErfolgreichAction
    | GetMandantInfoFehlerAction
    | GetMandantInfoNichtAusgefuehrtAction
    ;
