export * from './abteilungQuery.service';
import { AbteilungQueryService } from './abteilungQuery.service';
export * from './incidentsCommand.service';
import { IncidentsCommandService } from './incidentsCommand.service';
export * from './incidentsQuery.service';
import { IncidentsQueryService } from './incidentsQuery.service';
export * from './incidentsReporting.service';
import { IncidentsReportingService } from './incidentsReporting.service';
export * from './incidentsReportingTemplates.service';
import { IncidentsReportingTemplatesService } from './incidentsReportingTemplates.service';
export * from './incidentsTemplates.service';
import { IncidentsTemplatesService } from './incidentsTemplates.service';
export * from './zentraleQuery.service';
import { ZentraleQueryService } from './zentraleQuery.service';
export const APIS = [AbteilungQueryService, IncidentsCommandService, IncidentsQueryService, IncidentsReportingService, IncidentsReportingTemplatesService, IncidentsTemplatesService, ZentraleQueryService];
