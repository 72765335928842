import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_AKTUALISIEREN = '[IncidentsModul] PostIncidentAktualisieren laden';
export const POST_INCIDENT_AKTUALISIEREN_AUSFUEHREN = '[IncidentsModul] PostIncidentAktualisieren ausführen';
export const POST_INCIDENT_AKTUALISIEREN_ERFOLGREICH = '[IncidentsModul] PostIncidentAktualisieren laden erfolgreich';
export const POST_INCIDENT_AKTUALISIEREN_FEHLER = '[IncidentsModul] PostIncidentAktualisieren Ladefehler';
export const POST_INCIDENT_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostIncidentAktualisierenAction implements Action {
    readonly type = POST_INCIDENT_AKTUALISIEREN;
        constructor(public incidentAktualisierenRequest: api.IncidentAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostIncidentAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_AKTUALISIEREN_AUSFUEHREN;
        constructor(public incidentAktualisierenRequest: api.IncidentAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostIncidentAktualisierenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentAktualisierenRequest: api.IncidentAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostIncidentAktualisierenFehlerAction implements Action {
    readonly type = POST_INCIDENT_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public incidentAktualisierenRequest: api.IncidentAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostIncidentAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentAktualisierenActions =
    PostIncidentAktualisierenAction
    | PostIncidentAktualisierenAusfuehrenAction
    | PostIncidentAktualisierenErfolgreichAction
    | PostIncidentAktualisierenFehlerAction
    | PostIncidentAktualisierenNichtAusgefuehrtAction
    ;
