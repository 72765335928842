import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_UMBENENNEN = '[BenutzerverwaltungModul] PostFunktionsbereichUmbenennen laden';
export const POST_FUNKTIONSBEREICH_UMBENENNEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostFunktionsbereichUmbenennen ausführen';
export const POST_FUNKTIONSBEREICH_UMBENENNEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostFunktionsbereichUmbenennen laden erfolgreich';
export const POST_FUNKTIONSBEREICH_UMBENENNEN_FEHLER = '[BenutzerverwaltungModul] PostFunktionsbereichUmbenennen Ladefehler';
export const POST_FUNKTIONSBEREICH_UMBENENNEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostFunktionsbereichUmbenennen nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichUmbenennenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_UMBENENNEN;
        constructor(public funktionsbereichUmbenennenRequest: api.FunktionsbereichUmbenennenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichUmbenennenAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_UMBENENNEN_AUSFUEHREN;
        constructor(public funktionsbereichUmbenennenRequest: api.FunktionsbereichUmbenennenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichUmbenennenErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_UMBENENNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funktionsbereichUmbenennenRequest: api.FunktionsbereichUmbenennenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichUmbenennenFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_UMBENENNEN_FEHLER;
        constructor(public payload: any, public funktionsbereichUmbenennenRequest: api.FunktionsbereichUmbenennenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichUmbenennenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_UMBENENNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandFunktionsbereichUmbenennenActions =
    PostFunktionsbereichUmbenennenAction
    | PostFunktionsbereichUmbenennenAusfuehrenAction
    | PostFunktionsbereichUmbenennenErfolgreichAction
    | PostFunktionsbereichUmbenennenFehlerAction
    | PostFunktionsbereichUmbenennenNichtAusgefuehrtAction
    ;
