import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ZENTRALE_EINGANG = '[IncidentsModul] GetZentraleEingang laden';
export const GET_ZENTRALE_EINGANG_AUSFUEHREN = '[IncidentsModul] GetZentraleEingang ausführen';
export const GET_ZENTRALE_EINGANG_ERFOLGREICH = '[IncidentsModul] GetZentraleEingang laden erfolgreich';
export const GET_ZENTRALE_EINGANG_FEHLER = '[IncidentsModul] GetZentraleEingang Ladefehler';
export const GET_ZENTRALE_EINGANG_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetZentraleEingang nicht ausgeführt (evtl. Offline)';



export class GetZentraleEingangAction implements Action {
    readonly type = GET_ZENTRALE_EINGANG;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetZentraleEingangAusfuehrenAction implements Action {
    readonly type = GET_ZENTRALE_EINGANG_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetZentraleEingangErfolgreichAction implements Action {
    readonly type = GET_ZENTRALE_EINGANG_ERFOLGREICH;
        constructor(public payload: Array<api.IncidentKopf>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetZentraleEingangFehlerAction implements Action {
    readonly type = GET_ZENTRALE_EINGANG_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetZentraleEingangNichtAusgefuehrtAction implements Action {
    readonly type = GET_ZENTRALE_EINGANG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryZentraleEingangActions =
    GetZentraleEingangAction
    | GetZentraleEingangAusfuehrenAction
    | GetZentraleEingangErfolgreichAction
    | GetZentraleEingangFehlerAction
    | GetZentraleEingangNichtAusgefuehrtAction
    ;
