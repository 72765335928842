import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {ChecklistenauswahlPage} from './checklistenauswahl.page';
import {StoreModule} from '@ngrx/store';
import {reducers} from './checklistenauswahl.reducer';
import {EffectsModule} from '@ngrx/effects';
import {VorlagenFunktionsbereicheChecklistenEffects} from '../../../client-ngrx/vorlagen';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {SopiComponentsModule} from '../../shared/sopi-components/sopi-components.module';

const routes: Routes = [
    {
        path: '',
        component: ChecklistenauswahlPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SopiScrollModule,
        RouterModule.forChild(routes),

        StoreModule.forFeature('ChecklistenauswahlModal', reducers),
        EffectsModule.forFeature([
            VorlagenFunktionsbereicheChecklistenEffects
        ]),
        SopiComponentsModule.forRoot()
    ],
    declarations: [ChecklistenauswahlPage],
    entryComponents: [ChecklistenauswahlPage],
})
export class ChecklistenauswahlPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: ChecklistenauswahlPageModule,
        };
    }
}
