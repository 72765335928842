import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_QS_CHECKS_ENTITY_TYPES = '[QschecksModul] GetQsChecksEntityTypes laden';
export const GET_QS_CHECKS_ENTITY_TYPES_AUSFUEHREN = '[QschecksModul] GetQsChecksEntityTypes ausführen';
export const GET_QS_CHECKS_ENTITY_TYPES_ERFOLGREICH = '[QschecksModul] GetQsChecksEntityTypes laden erfolgreich';
export const GET_QS_CHECKS_ENTITY_TYPES_FEHLER = '[QschecksModul] GetQsChecksEntityTypes Ladefehler';
export const GET_QS_CHECKS_ENTITY_TYPES_NICHT_AUSGEFUEHRT = '[QschecksModul] GetQsChecksEntityTypes nicht ausgeführt (evtl. Offline)';



export class GetQsChecksEntityTypesAction implements Action {
    readonly type = GET_QS_CHECKS_ENTITY_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetQsChecksEntityTypesAusfuehrenAction implements Action {
    readonly type = GET_QS_CHECKS_ENTITY_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetQsChecksEntityTypesErfolgreichAction implements Action {
    readonly type = GET_QS_CHECKS_ENTITY_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.EntityTypeInfo>, public optPayload: any = null) {}
    }
export class GetQsChecksEntityTypesFehlerAction implements Action {
    readonly type = GET_QS_CHECKS_ENTITY_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetQsChecksEntityTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_QS_CHECKS_ENTITY_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryQsChecksEntityTypesActions =
    GetQsChecksEntityTypesAction
    | GetQsChecksEntityTypesAusfuehrenAction
    | GetQsChecksEntityTypesErfolgreichAction
    | GetQsChecksEntityTypesFehlerAction
    | GetQsChecksEntityTypesNichtAusgefuehrtAction
    ;
