import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const GET_BEREICHE_LISTE_ADMIN = '[DisplaysteuerungModul] GetBereicheListeAdmin laden';
export const GET_BEREICHE_LISTE_ADMIN_AUSFUEHREN = '[DisplaysteuerungModul] GetBereicheListeAdmin ausführen';
export const GET_BEREICHE_LISTE_ADMIN_ERFOLGREICH = '[DisplaysteuerungModul] GetBereicheListeAdmin laden erfolgreich';
export const GET_BEREICHE_LISTE_ADMIN_FEHLER = '[DisplaysteuerungModul] GetBereicheListeAdmin Ladefehler';
export const GET_BEREICHE_LISTE_ADMIN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] GetBereicheListeAdmin nicht ausgeführt (evtl. Offline)';



export class GetBereicheListeAdminAction implements Action {
    readonly type = GET_BEREICHE_LISTE_ADMIN;
        constructor(public optPayload: any = null) {}
    }
export class GetBereicheListeAdminAusfuehrenAction implements Action {
    readonly type = GET_BEREICHE_LISTE_ADMIN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetBereicheListeAdminErfolgreichAction implements Action {
    readonly type = GET_BEREICHE_LISTE_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.BereichListItem>, public optPayload: any = null) {}
    }
export class GetBereicheListeAdminFehlerAction implements Action {
    readonly type = GET_BEREICHE_LISTE_ADMIN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetBereicheListeAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_BEREICHE_LISTE_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungQueryBereicheListeAdminActions =
    GetBereicheListeAdminAction
    | GetBereicheListeAdminAusfuehrenAction
    | GetBereicheListeAdminErfolgreichAction
    | GetBereicheListeAdminFehlerAction
    | GetBereicheListeAdminNichtAusgefuehrtAction
    ;
