import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.command.aufgabe-eskalation-versenden';
import * as api from '../../../clients/veranstaltungen';


    @Injectable()
    export class VeranstaltungenAufgabeEskalationVersendenEffects {

        @Effect()
            AufgabeEskalationVersenden$ = this.actions$.pipe(
                ofType(ac.POST_AUFGABE_ESKALATION_VERSENDEN),
                flatMap((x: ac.PostAufgabeEskalationVersendenAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().postAufgabeEskalationVersenden(x.aufgabeRequest).pipe(
                    map((result: any) => {
                        const nextAction = new ac.PostAufgabeEskalationVersendenErfolgreichAction(result, x.aufgabeRequest,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.PostAufgabeEskalationVersendenFehlerAction(error, x.aufgabeRequest,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.AufgabenCommandService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.AufgabenCommandService {
                if (this.service === null) {
                    this.service = this.injector.get(api.AufgabenCommandService);
                }
                return this.service;
            }
        
}
