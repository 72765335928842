import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {RollenselektionPage} from './rollenselektion.page';
import {StoreModule} from '@ngrx/store';
import {reducers} from './rollenselektion.reducer';
import {EffectsModule} from '@ngrx/effects';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {RollenselektionPageUiEffects} from './rollenselektion.effects.ui';
import {BenutzerverwaltungFunktionsbereicheRollenAlleEffects} from '../../../client-ngrx/benutzerverwaltung';
import {VorlagenFunktionsbereicheRollenEffects} from '../../../client-ngrx/vorlagen';
import {VeranstaltungenVeranstaltungFunktionsbereicheRollenAdminAlleEffects} from '../../../client-ngrx/veranstaltungen';
import {SopiComponentsModule} from 'src/app/shared/sopi-components/sopi-components.module';
import {DisplaysteuerungFunktionsbereicheRollenEffects} from '../../../client-ngrx/displaysteuerung';
import {QschecksFunktionsbereicheRollenEffects} from '../../../client-ngrx/qschecks';

const routes: Routes = [
    {
        path: '',
        component: RollenselektionPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature('RollenselektionPage', reducers),
        EffectsModule.forFeature([
            RollenselektionPageUiEffects,
            BenutzerverwaltungFunktionsbereicheRollenAlleEffects,
            VorlagenFunktionsbereicheRollenEffects,
            VeranstaltungenVeranstaltungFunktionsbereicheRollenAdminAlleEffects,
            DisplaysteuerungFunktionsbereicheRollenEffects,
            QschecksFunktionsbereicheRollenEffects
        ]),
        SopiScrollModule,
        SopiComponentsModule.forRoot()
    ],
    declarations: [RollenselektionPage],
    entryComponents: [RollenselektionPage],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RollenselektionPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: RollenselektionPageModule,
        };
    }
}
