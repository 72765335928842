import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_BENUTZER_ZUM_DIENST_EINTEILEN_MULTI = '[VeranstaltungenModul] PostBenutzerZumDienstEinteilenMulti laden';
export const POST_BENUTZER_ZUM_DIENST_EINTEILEN_MULTI_AUSFUEHREN = '[VeranstaltungenModul] PostBenutzerZumDienstEinteilenMulti ausführen';
export const POST_BENUTZER_ZUM_DIENST_EINTEILEN_MULTI_ERFOLGREICH = '[VeranstaltungenModul] PostBenutzerZumDienstEinteilenMulti laden erfolgreich';
export const POST_BENUTZER_ZUM_DIENST_EINTEILEN_MULTI_FEHLER = '[VeranstaltungenModul] PostBenutzerZumDienstEinteilenMulti Ladefehler';
export const POST_BENUTZER_ZUM_DIENST_EINTEILEN_MULTI_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostBenutzerZumDienstEinteilenMulti nicht ausgeführt (evtl. Offline)';



export class PostBenutzerZumDienstEinteilenMultiAction implements Action {
    readonly type = POST_BENUTZER_ZUM_DIENST_EINTEILEN_MULTI;
        constructor(public benutzerZumDienstEinteilenMultiRequest: api.BenutzerZumDienstEinteilenMultiRequest, public optPayload: any = null) {}
    }
export class PostBenutzerZumDienstEinteilenMultiAusfuehrenAction implements Action {
    readonly type = POST_BENUTZER_ZUM_DIENST_EINTEILEN_MULTI_AUSFUEHREN;
        constructor(public benutzerZumDienstEinteilenMultiRequest: api.BenutzerZumDienstEinteilenMultiRequest, public optPayload: any = null) {}
    }
export class PostBenutzerZumDienstEinteilenMultiErfolgreichAction implements Action {
    readonly type = POST_BENUTZER_ZUM_DIENST_EINTEILEN_MULTI_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public benutzerZumDienstEinteilenMultiRequest: api.BenutzerZumDienstEinteilenMultiRequest, public optPayload: any = null) {}
    }
export class PostBenutzerZumDienstEinteilenMultiFehlerAction implements Action {
    readonly type = POST_BENUTZER_ZUM_DIENST_EINTEILEN_MULTI_FEHLER;
        constructor(public payload: any, public benutzerZumDienstEinteilenMultiRequest: api.BenutzerZumDienstEinteilenMultiRequest, public optPayload: any = null) {}
    }
export class PostBenutzerZumDienstEinteilenMultiNichtAusgefuehrtAction implements Action {
    readonly type = POST_BENUTZER_ZUM_DIENST_EINTEILEN_MULTI_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandBenutzerZumDienstEinteilenMultiActions =
    PostBenutzerZumDienstEinteilenMultiAction
    | PostBenutzerZumDienstEinteilenMultiAusfuehrenAction
    | PostBenutzerZumDienstEinteilenMultiErfolgreichAction
    | PostBenutzerZumDienstEinteilenMultiFehlerAction
    | PostBenutzerZumDienstEinteilenMultiNichtAusgefuehrtAction
    ;
