import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FUNKPROTOKOLL_FALL_REAKTIVIEREN = '[VeranstaltungenModul] PostFunkprotokollFallReaktivieren laden';
export const POST_FUNKPROTOKOLL_FALL_REAKTIVIEREN_AUSFUEHREN = '[VeranstaltungenModul] PostFunkprotokollFallReaktivieren ausführen';
export const POST_FUNKPROTOKOLL_FALL_REAKTIVIEREN_ERFOLGREICH = '[VeranstaltungenModul] PostFunkprotokollFallReaktivieren laden erfolgreich';
export const POST_FUNKPROTOKOLL_FALL_REAKTIVIEREN_FEHLER = '[VeranstaltungenModul] PostFunkprotokollFallReaktivieren Ladefehler';
export const POST_FUNKPROTOKOLL_FALL_REAKTIVIEREN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFunkprotokollFallReaktivieren nicht ausgeführt (evtl. Offline)';



export class PostFunkprotokollFallReaktivierenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_REAKTIVIEREN;
        constructor(public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallReaktivierenAusfuehrenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_REAKTIVIEREN_AUSFUEHREN;
        constructor(public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallReaktivierenErfolgreichAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_REAKTIVIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallReaktivierenFehlerAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_REAKTIVIEREN_FEHLER;
        constructor(public payload: any, public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallReaktivierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_REAKTIVIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFunkprotokollFallReaktivierenActions =
    PostFunkprotokollFallReaktivierenAction
    | PostFunkprotokollFallReaktivierenAusfuehrenAction
    | PostFunkprotokollFallReaktivierenErfolgreichAction
    | PostFunkprotokollFallReaktivierenFehlerAction
    | PostFunkprotokollFallReaktivierenNichtAusgefuehrtAction
    ;
