import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_TROUBLE_TICKET_MANDANT_OPTIONS = '[KommunikationModul] GetTroubleTicketMandantOptions laden';
export const GET_TROUBLE_TICKET_MANDANT_OPTIONS_AUSFUEHREN = '[KommunikationModul] GetTroubleTicketMandantOptions ausführen';
export const GET_TROUBLE_TICKET_MANDANT_OPTIONS_ERFOLGREICH = '[KommunikationModul] GetTroubleTicketMandantOptions laden erfolgreich';
export const GET_TROUBLE_TICKET_MANDANT_OPTIONS_FEHLER = '[KommunikationModul] GetTroubleTicketMandantOptions Ladefehler';
export const GET_TROUBLE_TICKET_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetTroubleTicketMandantOptions nicht ausgeführt (evtl. Offline)';



export class GetTroubleTicketMandantOptionsAction implements Action {
    readonly type = GET_TROUBLE_TICKET_MANDANT_OPTIONS;
        constructor(public optPayload: any = null) {}
    }
export class GetTroubleTicketMandantOptionsAusfuehrenAction implements Action {
    readonly type = GET_TROUBLE_TICKET_MANDANT_OPTIONS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetTroubleTicketMandantOptionsErfolgreichAction implements Action {
    readonly type = GET_TROUBLE_TICKET_MANDANT_OPTIONS_ERFOLGREICH;
        constructor(public payload: api.TroubleTicketMandantOptions, public optPayload: any = null) {}
    }
export class GetTroubleTicketMandantOptionsFehlerAction implements Action {
    readonly type = GET_TROUBLE_TICKET_MANDANT_OPTIONS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetTroubleTicketMandantOptionsNichtAusgefuehrtAction implements Action {
    readonly type = GET_TROUBLE_TICKET_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryTroubleTicketMandantOptionsActions =
    GetTroubleTicketMandantOptionsAction
    | GetTroubleTicketMandantOptionsAusfuehrenAction
    | GetTroubleTicketMandantOptionsErfolgreichAction
    | GetTroubleTicketMandantOptionsFehlerAction
    | GetTroubleTicketMandantOptionsNichtAusgefuehrtAction
    ;
