import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENT_IDENTIFIER_TEMPLATE = '[IncidentsModul] GetIncidentIdentifierTemplate laden';
export const GET_INCIDENT_IDENTIFIER_TEMPLATE_AUSFUEHREN = '[IncidentsModul] GetIncidentIdentifierTemplate ausführen';
export const GET_INCIDENT_IDENTIFIER_TEMPLATE_ERFOLGREICH = '[IncidentsModul] GetIncidentIdentifierTemplate laden erfolgreich';
export const GET_INCIDENT_IDENTIFIER_TEMPLATE_FEHLER = '[IncidentsModul] GetIncidentIdentifierTemplate Ladefehler';
export const GET_INCIDENT_IDENTIFIER_TEMPLATE_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentIdentifierTemplate nicht ausgeführt (evtl. Offline)';



export class GetIncidentIdentifierTemplateAction implements Action {
    readonly type = GET_INCIDENT_IDENTIFIER_TEMPLATE;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentIdentifierTemplateAusfuehrenAction implements Action {
    readonly type = GET_INCIDENT_IDENTIFIER_TEMPLATE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentIdentifierTemplateErfolgreichAction implements Action {
    readonly type = GET_INCIDENT_IDENTIFIER_TEMPLATE_ERFOLGREICH;
        constructor(public payload: api.IncidentIdentifier, public optPayload: any = null) {}
    }
export class GetIncidentIdentifierTemplateFehlerAction implements Action {
    readonly type = GET_INCIDENT_IDENTIFIER_TEMPLATE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetIncidentIdentifierTemplateNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENT_IDENTIFIER_TEMPLATE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentIdentifierTemplateActions =
    GetIncidentIdentifierTemplateAction
    | GetIncidentIdentifierTemplateAusfuehrenAction
    | GetIncidentIdentifierTemplateErfolgreichAction
    | GetIncidentIdentifierTemplateFehlerAction
    | GetIncidentIdentifierTemplateNichtAusgefuehrtAction
    ;
