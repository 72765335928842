import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_MANDANT_LOGO_BASE64_SCALED = '[ApiModul] GetMandantLogoBase64Scaled laden';
export const GET_MANDANT_LOGO_BASE64_SCALED_AUSFUEHREN = '[ApiModul] GetMandantLogoBase64Scaled ausführen';
export const GET_MANDANT_LOGO_BASE64_SCALED_ERFOLGREICH = '[ApiModul] GetMandantLogoBase64Scaled laden erfolgreich';
export const GET_MANDANT_LOGO_BASE64_SCALED_FEHLER = '[ApiModul] GetMandantLogoBase64Scaled Ladefehler';
export const GET_MANDANT_LOGO_BASE64_SCALED_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantLogoBase64Scaled nicht ausgeführt (evtl. Offline)';



export class GetMandantLogoBase64ScaledAction implements Action {
    readonly type = GET_MANDANT_LOGO_BASE64_SCALED;
        constructor( public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetMandantLogoBase64ScaledAusfuehrenAction implements Action {
    readonly type = GET_MANDANT_LOGO_BASE64_SCALED_AUSFUEHREN;
        constructor( public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetMandantLogoBase64ScaledErfolgreichAction implements Action {
    readonly type = GET_MANDANT_LOGO_BASE64_SCALED_ERFOLGREICH;
        constructor(public payload: api.Bild,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetMandantLogoBase64ScaledFehlerAction implements Action {
    readonly type = GET_MANDANT_LOGO_BASE64_SCALED_FEHLER;
        constructor(public payload: any,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetMandantLogoBase64ScaledNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANT_LOGO_BASE64_SCALED_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantLogoBase64ScaledActions =
    GetMandantLogoBase64ScaledAction
    | GetMandantLogoBase64ScaledAusfuehrenAction
    | GetMandantLogoBase64ScaledErfolgreichAction
    | GetMandantLogoBase64ScaledFehlerAction
    | GetMandantLogoBase64ScaledNichtAusgefuehrtAction
    ;
