import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_API_NOTIFICATION_TYPES = '[ApiModul] GetApiNotificationTypes laden';
export const GET_API_NOTIFICATION_TYPES_AUSFUEHREN = '[ApiModul] GetApiNotificationTypes ausführen';
export const GET_API_NOTIFICATION_TYPES_ERFOLGREICH = '[ApiModul] GetApiNotificationTypes laden erfolgreich';
export const GET_API_NOTIFICATION_TYPES_FEHLER = '[ApiModul] GetApiNotificationTypes Ladefehler';
export const GET_API_NOTIFICATION_TYPES_NICHT_AUSGEFUEHRT = '[ApiModul] GetApiNotificationTypes nicht ausgeführt (evtl. Offline)';



export class GetApiNotificationTypesAction implements Action {
    readonly type = GET_API_NOTIFICATION_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetApiNotificationTypesAusfuehrenAction implements Action {
    readonly type = GET_API_NOTIFICATION_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetApiNotificationTypesErfolgreichAction implements Action {
    readonly type = GET_API_NOTIFICATION_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.NotificationMessageTypeInfo>, public optPayload: any = null) {}
    }
export class GetApiNotificationTypesFehlerAction implements Action {
    readonly type = GET_API_NOTIFICATION_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetApiNotificationTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_API_NOTIFICATION_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryApiNotificationTypesActions =
    GetApiNotificationTypesAction
    | GetApiNotificationTypesAusfuehrenAction
    | GetApiNotificationTypesErfolgreichAction
    | GetApiNotificationTypesFehlerAction
    | GetApiNotificationTypesNichtAusgefuehrtAction
    ;
