import { ModuleWithProviders, NgModule } from '@angular/core';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';

import { AuthEffects } from './effects';
import { AuthInterceptor } from './authInterceptor';
import { IsAuthenticatedGuard } from './isAuthenticatedGuard';
import { reducers } from './reducer';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './auth.service';
import { IMyAuthOptions, MyAuthOptions, MY_AUTH_CONFIG } from './variables';
import { LogGuard } from './logGuard';
import { Login401Interceptor } from './login401Interceptor';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    HttpClientModule,
    OAuthModule.forRoot(),
    StoreModule.forFeature('Auth', reducers),
    EffectsModule.forFeature([
      AuthEffects
    ])
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Login401Interceptor,
      multi: true
    },
    LogGuard,
    IsAuthenticatedGuard,
    OAuthService
  ],
})
export class AuthModule {
  public static forRoot(config: IMyAuthOptions): ModuleWithProviders {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: OAuthStorage, useFactory: storageFactory },
        { provide: MY_AUTH_CONFIG, useValue: config },
        {
          provide: MyAuthOptions,
          useFactory: provideMyAuthOptions,
          deps: [MY_AUTH_CONFIG]
        }
      ],
    };
  }
}


export function provideMyAuthOptions(options?: IMyAuthOptions): MyAuthOptions {

  var ret = new MyAuthOptions();

  if (options) {
    ret.azureAd = options.azureAd;
    ret.debug = options.debug;
    ret.loginRoute = options.loginRoute;
    ret.redirectUri = options.redirectUri;
    ret.redirectUriFromOrigin = options.redirectUriFromOrigin;
    ret.type = options.type;
    ret.tenantMappings = options.tenantMappings;
  
  }

  console.log('AuthModule: provideMyAuthOptions: ', JSON.stringify(ret));

  return (ret);

}
