import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungEntfernen laden';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_AUSFUEHREN = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungEntfernen ausführen';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_ERFOLGREICH = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungEntfernen laden erfolgreich';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_FEHLER = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungEntfernen Ladefehler';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungEntfernen nicht ausgeführt (evtl. Offline)';



export class PostDokumentBearbeitenBerechtigungEntfernenAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN;
        constructor(public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungEntfernenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_AUSFUEHREN;
        constructor(public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungEntfernenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungEntfernenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandDokumentBearbeitenBerechtigungEntfernenActions =
    PostDokumentBearbeitenBerechtigungEntfernenAction
    | PostDokumentBearbeitenBerechtigungEntfernenAusfuehrenAction
    | PostDokumentBearbeitenBerechtigungEntfernenErfolgreichAction
    | PostDokumentBearbeitenBerechtigungEntfernenFehlerAction
    | PostDokumentBearbeitenBerechtigungEntfernenNichtAusgefuehrtAction
    ;
