import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_INFO_KATEGORIEN_ADMIN = '[VeranstaltungenModul] GetVeranstaltungInfoKategorienAdmin laden';
export const GET_VERANSTALTUNG_INFO_KATEGORIEN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungInfoKategorienAdmin ausführen';
export const GET_VERANSTALTUNG_INFO_KATEGORIEN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungInfoKategorienAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_INFO_KATEGORIEN_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungInfoKategorienAdmin Ladefehler';
export const GET_VERANSTALTUNG_INFO_KATEGORIEN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungInfoKategorienAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungInfoKategorienAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_INFO_KATEGORIEN_ADMIN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungInfoKategorienAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_INFO_KATEGORIEN_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungInfoKategorienAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_INFO_KATEGORIEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.InfoKategorie>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungInfoKategorienAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_INFO_KATEGORIEN_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungInfoKategorienAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_INFO_KATEGORIEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungInfoKategorienAdminActions =
    GetVeranstaltungInfoKategorienAdminAction
    | GetVeranstaltungInfoKategorienAdminAusfuehrenAction
    | GetVeranstaltungInfoKategorienAdminErfolgreichAction
    | GetVeranstaltungInfoKategorienAdminFehlerAction
    | GetVeranstaltungInfoKategorienAdminNichtAusgefuehrtAction
    ;
