import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {FeldOptionPage} from './feld-option.page';
import {SopiScrollModule} from '../../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {SopiComponentsModule} from '../../../shared/sopi-components/sopi-components.module';

const routes: Routes = [
    {
        path: '',
        component: FeldOptionPage,
        data: { title: 'FeldOption' }
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SopiScrollModule,
        RouterModule.forChild(routes),
        SopiComponentsModule
    ],
    declarations: [FeldOptionPage],
    entryComponents: [
        FeldOptionPage
    ]
})

export class FeldOptionPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: FeldOptionPageModule,
        };
    }
}
