import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_MITTEILUNGEN_GRUPPIERT = '[KommunikationModul] GetMitteilungenGruppiert laden';
export const GET_MITTEILUNGEN_GRUPPIERT_AUSFUEHREN = '[KommunikationModul] GetMitteilungenGruppiert ausführen';
export const GET_MITTEILUNGEN_GRUPPIERT_ERFOLGREICH = '[KommunikationModul] GetMitteilungenGruppiert laden erfolgreich';
export const GET_MITTEILUNGEN_GRUPPIERT_FEHLER = '[KommunikationModul] GetMitteilungenGruppiert Ladefehler';
export const GET_MITTEILUNGEN_GRUPPIERT_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetMitteilungenGruppiert nicht ausgeführt (evtl. Offline)';



export class GetMitteilungenGruppiertAction implements Action {
    readonly type = GET_MITTEILUNGEN_GRUPPIERT;
        constructor( public korrelation_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGruppiertAusfuehrenAction implements Action {
    readonly type = GET_MITTEILUNGEN_GRUPPIERT_AUSFUEHREN;
        constructor( public korrelation_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGruppiertErfolgreichAction implements Action {
    readonly type = GET_MITTEILUNGEN_GRUPPIERT_ERFOLGREICH;
        constructor(public payload: Array<api.DatumGruppeMitteilungen>,  public korrelation_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGruppiertFehlerAction implements Action {
    readonly type = GET_MITTEILUNGEN_GRUPPIERT_FEHLER;
        constructor(public payload: any,  public korrelation_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGruppiertNichtAusgefuehrtAction implements Action {
    readonly type = GET_MITTEILUNGEN_GRUPPIERT_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryMitteilungenGruppiertActions =
    GetMitteilungenGruppiertAction
    | GetMitteilungenGruppiertAusfuehrenAction
    | GetMitteilungenGruppiertErfolgreichAction
    | GetMitteilungenGruppiertFehlerAction
    | GetMitteilungenGruppiertNichtAusgefuehrtAction
    ;
