import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICHE_PRUEFPLAENE_ADMIN = '[QschecksModul] GetFunktionsbereichePruefplaeneAdmin laden';
export const GET_FUNKTIONSBEREICHE_PRUEFPLAENE_ADMIN_AUSFUEHREN = '[QschecksModul] GetFunktionsbereichePruefplaeneAdmin ausführen';
export const GET_FUNKTIONSBEREICHE_PRUEFPLAENE_ADMIN_ERFOLGREICH = '[QschecksModul] GetFunktionsbereichePruefplaeneAdmin laden erfolgreich';
export const GET_FUNKTIONSBEREICHE_PRUEFPLAENE_ADMIN_FEHLER = '[QschecksModul] GetFunktionsbereichePruefplaeneAdmin Ladefehler';
export const GET_FUNKTIONSBEREICHE_PRUEFPLAENE_ADMIN_NICHT_AUSGEFUEHRT = '[QschecksModul] GetFunktionsbereichePruefplaeneAdmin nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichePruefplaeneAdminAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_PRUEFPLAENE_ADMIN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereichePruefplaeneAdminAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_PRUEFPLAENE_ADMIN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereichePruefplaeneAdminErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_PRUEFPLAENE_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichPruefplaene>, public optPayload: any = null) {}
    }
export class GetFunktionsbereichePruefplaeneAdminFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_PRUEFPLAENE_ADMIN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFunktionsbereichePruefplaeneAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_PRUEFPLAENE_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryFunktionsbereichePruefplaeneAdminActions =
    GetFunktionsbereichePruefplaeneAdminAction
    | GetFunktionsbereichePruefplaeneAdminAusfuehrenAction
    | GetFunktionsbereichePruefplaeneAdminErfolgreichAction
    | GetFunktionsbereichePruefplaeneAdminFehlerAction
    | GetFunktionsbereichePruefplaeneAdminNichtAusgefuehrtAction
    ;
