import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_CHECKLISTE_VERSCHIEBEN = '[VorlagenModul] PostChecklisteVerschieben laden';
export const POST_CHECKLISTE_VERSCHIEBEN_AUSFUEHREN = '[VorlagenModul] PostChecklisteVerschieben ausführen';
export const POST_CHECKLISTE_VERSCHIEBEN_ERFOLGREICH = '[VorlagenModul] PostChecklisteVerschieben laden erfolgreich';
export const POST_CHECKLISTE_VERSCHIEBEN_FEHLER = '[VorlagenModul] PostChecklisteVerschieben Ladefehler';
export const POST_CHECKLISTE_VERSCHIEBEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostChecklisteVerschieben nicht ausgeführt (evtl. Offline)';



export class PostChecklisteVerschiebenAction implements Action {
    readonly type = POST_CHECKLISTE_VERSCHIEBEN;
        constructor(public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVerschiebenAusfuehrenAction implements Action {
    readonly type = POST_CHECKLISTE_VERSCHIEBEN_AUSFUEHREN;
        constructor(public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVerschiebenErfolgreichAction implements Action {
    readonly type = POST_CHECKLISTE_VERSCHIEBEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVerschiebenFehlerAction implements Action {
    readonly type = POST_CHECKLISTE_VERSCHIEBEN_FEHLER;
        constructor(public payload: any, public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVerschiebenNichtAusgefuehrtAction implements Action {
    readonly type = POST_CHECKLISTE_VERSCHIEBEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandChecklisteVerschiebenActions =
    PostChecklisteVerschiebenAction
    | PostChecklisteVerschiebenAusfuehrenAction
    | PostChecklisteVerschiebenErfolgreichAction
    | PostChecklisteVerschiebenFehlerAction
    | PostChecklisteVerschiebenNichtAusgefuehrtAction
    ;
