import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'event-selection-button',
    templateUrl: './event-selection-button.component.html',
    styleUrls: ['./event-selection-button.component.scss'],
})
export class EventSelectionButtonComponent implements OnInit {

    // VALUES
    @Input() timeRow1: string = null;
    @Input() timeRow2: string = null;
    @Input() eventRow1: string = null;
    @Input() eventRow2: string = null;

    // DESIGN
    @Input() hasEvent = true;
    @Input() hasTime = true;

    constructor() {
    }

    ngOnInit() {
    }

}
