import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_PINNWAND_AKTIVIEREN = '[IncidentsModul] PostIncidentPinnwandAktivieren laden';
export const POST_INCIDENT_PINNWAND_AKTIVIEREN_AUSFUEHREN = '[IncidentsModul] PostIncidentPinnwandAktivieren ausführen';
export const POST_INCIDENT_PINNWAND_AKTIVIEREN_ERFOLGREICH = '[IncidentsModul] PostIncidentPinnwandAktivieren laden erfolgreich';
export const POST_INCIDENT_PINNWAND_AKTIVIEREN_FEHLER = '[IncidentsModul] PostIncidentPinnwandAktivieren Ladefehler';
export const POST_INCIDENT_PINNWAND_AKTIVIEREN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentPinnwandAktivieren nicht ausgeführt (evtl. Offline)';



export class PostIncidentPinnwandAktivierenAction implements Action {
    readonly type = POST_INCIDENT_PINNWAND_AKTIVIEREN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentPinnwandAktivierenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_PINNWAND_AKTIVIEREN_AUSFUEHREN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentPinnwandAktivierenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_PINNWAND_AKTIVIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentPinnwandAktivierenFehlerAction implements Action {
    readonly type = POST_INCIDENT_PINNWAND_AKTIVIEREN_FEHLER;
        constructor(public payload: any, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentPinnwandAktivierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_PINNWAND_AKTIVIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentPinnwandAktivierenActions =
    PostIncidentPinnwandAktivierenAction
    | PostIncidentPinnwandAktivierenAusfuehrenAction
    | PostIncidentPinnwandAktivierenErfolgreichAction
    | PostIncidentPinnwandAktivierenFehlerAction
    | PostIncidentPinnwandAktivierenNichtAusgefuehrtAction
    ;
