import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_AKTION_ENTFERNEN = '[VeranstaltungenModul] PostVeranstaltungAktionEntfernen laden';
export const POST_VERANSTALTUNG_AKTION_ENTFERNEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungAktionEntfernen ausführen';
export const POST_VERANSTALTUNG_AKTION_ENTFERNEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungAktionEntfernen laden erfolgreich';
export const POST_VERANSTALTUNG_AKTION_ENTFERNEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungAktionEntfernen Ladefehler';
export const POST_VERANSTALTUNG_AKTION_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungAktionEntfernen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungAktionEntfernenAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_ENTFERNEN;
        constructor(public veranstaltungAktionEntfernenRequest: api.VeranstaltungAktionEntfernenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAktionEntfernenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_ENTFERNEN_AUSFUEHREN;
        constructor(public veranstaltungAktionEntfernenRequest: api.VeranstaltungAktionEntfernenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAktionEntfernenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungAktionEntfernenRequest: api.VeranstaltungAktionEntfernenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAktionEntfernenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_ENTFERNEN_FEHLER;
        constructor(public payload: any, public veranstaltungAktionEntfernenRequest: api.VeranstaltungAktionEntfernenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAktionEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungAktionEntfernenActions =
    PostVeranstaltungAktionEntfernenAction
    | PostVeranstaltungAktionEntfernenAusfuehrenAction
    | PostVeranstaltungAktionEntfernenErfolgreichAction
    | PostVeranstaltungAktionEntfernenFehlerAction
    | PostVeranstaltungAktionEntfernenNichtAusgefuehrtAction
    ;
