import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ZENTRALE_PINNWAND = '[IncidentsModul] GetZentralePinnwand laden';
export const GET_ZENTRALE_PINNWAND_AUSFUEHREN = '[IncidentsModul] GetZentralePinnwand ausführen';
export const GET_ZENTRALE_PINNWAND_ERFOLGREICH = '[IncidentsModul] GetZentralePinnwand laden erfolgreich';
export const GET_ZENTRALE_PINNWAND_FEHLER = '[IncidentsModul] GetZentralePinnwand Ladefehler';
export const GET_ZENTRALE_PINNWAND_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetZentralePinnwand nicht ausgeführt (evtl. Offline)';



export class GetZentralePinnwandAction implements Action {
    readonly type = GET_ZENTRALE_PINNWAND;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetZentralePinnwandAusfuehrenAction implements Action {
    readonly type = GET_ZENTRALE_PINNWAND_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetZentralePinnwandErfolgreichAction implements Action {
    readonly type = GET_ZENTRALE_PINNWAND_ERFOLGREICH;
        constructor(public payload: Array<api.IncidentKopf>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetZentralePinnwandFehlerAction implements Action {
    readonly type = GET_ZENTRALE_PINNWAND_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetZentralePinnwandNichtAusgefuehrtAction implements Action {
    readonly type = GET_ZENTRALE_PINNWAND_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryZentralePinnwandActions =
    GetZentralePinnwandAction
    | GetZentralePinnwandAusfuehrenAction
    | GetZentralePinnwandErfolgreichAction
    | GetZentralePinnwandFehlerAction
    | GetZentralePinnwandNichtAusgefuehrtAction
    ;
