import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_VERANSTALTUNGLEITER_FESTLEGEN = '[VeranstaltungenModul] PostVeranstaltungVeranstaltungleiterFestlegen laden';
export const POST_VERANSTALTUNG_VERANSTALTUNGLEITER_FESTLEGEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungVeranstaltungleiterFestlegen ausführen';
export const POST_VERANSTALTUNG_VERANSTALTUNGLEITER_FESTLEGEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungVeranstaltungleiterFestlegen laden erfolgreich';
export const POST_VERANSTALTUNG_VERANSTALTUNGLEITER_FESTLEGEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungVeranstaltungleiterFestlegen Ladefehler';
export const POST_VERANSTALTUNG_VERANSTALTUNGLEITER_FESTLEGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungVeranstaltungleiterFestlegen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungVeranstaltungleiterFestlegenAction implements Action {
    readonly type = POST_VERANSTALTUNG_VERANSTALTUNGLEITER_FESTLEGEN;
        constructor(public veranstaltungRolleRequest: api.VeranstaltungRolleRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungVeranstaltungleiterFestlegenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_VERANSTALTUNGLEITER_FESTLEGEN_AUSFUEHREN;
        constructor(public veranstaltungRolleRequest: api.VeranstaltungRolleRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungVeranstaltungleiterFestlegenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_VERANSTALTUNGLEITER_FESTLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungRolleRequest: api.VeranstaltungRolleRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungVeranstaltungleiterFestlegenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_VERANSTALTUNGLEITER_FESTLEGEN_FEHLER;
        constructor(public payload: any, public veranstaltungRolleRequest: api.VeranstaltungRolleRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungVeranstaltungleiterFestlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_VERANSTALTUNGLEITER_FESTLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungVeranstaltungleiterFestlegenActions =
    PostVeranstaltungVeranstaltungleiterFestlegenAction
    | PostVeranstaltungVeranstaltungleiterFestlegenAusfuehrenAction
    | PostVeranstaltungVeranstaltungleiterFestlegenErfolgreichAction
    | PostVeranstaltungVeranstaltungleiterFestlegenFehlerAction
    | PostVeranstaltungVeranstaltungleiterFestlegenNichtAusgefuehrtAction
    ;
