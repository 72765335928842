import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_TROUBLE_TICKET = '[KommunikationModul] GetTroubleTicket laden';
export const GET_TROUBLE_TICKET_AUSFUEHREN = '[KommunikationModul] GetTroubleTicket ausführen';
export const GET_TROUBLE_TICKET_ERFOLGREICH = '[KommunikationModul] GetTroubleTicket laden erfolgreich';
export const GET_TROUBLE_TICKET_FEHLER = '[KommunikationModul] GetTroubleTicket Ladefehler';
export const GET_TROUBLE_TICKET_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetTroubleTicket nicht ausgeführt (evtl. Offline)';



export class GetTroubleTicketAction implements Action {
    readonly type = GET_TROUBLE_TICKET;
        constructor( public troubleticket_id:string , public optPayload: any = null) {}
    }
export class GetTroubleTicketAusfuehrenAction implements Action {
    readonly type = GET_TROUBLE_TICKET_AUSFUEHREN;
        constructor( public troubleticket_id:string , public optPayload: any = null) {}
    }
export class GetTroubleTicketErfolgreichAction implements Action {
    readonly type = GET_TROUBLE_TICKET_ERFOLGREICH;
        constructor(public payload: api.TroubleTicket,  public troubleticket_id:string , public optPayload: any = null) {}
    }
export class GetTroubleTicketFehlerAction implements Action {
    readonly type = GET_TROUBLE_TICKET_FEHLER;
        constructor(public payload: any,  public troubleticket_id:string , public optPayload: any = null) {}
    }
export class GetTroubleTicketNichtAusgefuehrtAction implements Action {
    readonly type = GET_TROUBLE_TICKET_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryTroubleTicketActions =
    GetTroubleTicketAction
    | GetTroubleTicketAusfuehrenAction
    | GetTroubleTicketErfolgreichAction
    | GetTroubleTicketFehlerAction
    | GetTroubleTicketNichtAusgefuehrtAction
    ;
