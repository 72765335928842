/**
 * orga app | api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AzureAdMandantOptions } from '../model/azureAdMandantOptions';
import { ConstantTypeInfo } from '../model/constantTypeInfo';
import { CustomUrlMandantOptions } from '../model/customUrlMandantOptions';
import { LizenzMandantOptions } from '../model/lizenzMandantOptions';
import { MandantConfigUpdatedNotification } from '../model/mandantConfigUpdatedNotification';
import { MandantStammOptions } from '../model/mandantStammOptions';
import { NotificationMessageTypeInfo } from '../model/notificationMessageTypeInfo';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MandantenTemplatesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetAzureAdMandantOptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAzureAdMandantOptions(observe?: 'body', reportProgress?: boolean): Observable<AzureAdMandantOptions>;
    public getAzureAdMandantOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AzureAdMandantOptions>>;
    public getAzureAdMandantOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AzureAdMandantOptions>>;
    public getAzureAdMandantOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AzureAdMandantOptions>('get',`${this.basePath}/api/mandanten/templates/config/azuread`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetLizenzMandantOptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLizenzMandantOptions(observe?: 'body', reportProgress?: boolean): Observable<LizenzMandantOptions>;
    public getLizenzMandantOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LizenzMandantOptions>>;
    public getLizenzMandantOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LizenzMandantOptions>>;
    public getLizenzMandantOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LizenzMandantOptions>('get',`${this.basePath}/api/mandanten/templates/config/lizenz`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantCustomUrlOptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantCustomUrlOptions(observe?: 'body', reportProgress?: boolean): Observable<CustomUrlMandantOptions>;
    public getMandantCustomUrlOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomUrlMandantOptions>>;
    public getMandantCustomUrlOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomUrlMandantOptions>>;
    public getMandantCustomUrlOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CustomUrlMandantOptions>('get',`${this.basePath}/api/mandanten/templates/config/customurl`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantNotificationTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantNotificationTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<NotificationMessageTypeInfo>>;
    public getMandantNotificationTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotificationMessageTypeInfo>>>;
    public getMandantNotificationTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotificationMessageTypeInfo>>>;
    public getMandantNotificationTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<NotificationMessageTypeInfo>>('get',`${this.basePath}/api/mandanten/templates/notificationtypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantStammOptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantStammOptions(observe?: 'body', reportProgress?: boolean): Observable<MandantStammOptions>;
    public getMandantStammOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MandantStammOptions>>;
    public getMandantStammOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MandantStammOptions>>;
    public getMandantStammOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MandantStammOptions>('get',`${this.basePath}/api/mandanten/templates/config/mandantstamm`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantenConfigTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantenConfigTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<ConstantTypeInfo>>;
    public getMandantenConfigTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ConstantTypeInfo>>>;
    public getMandantenConfigTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ConstantTypeInfo>>>;
    public getMandantenConfigTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ConstantTypeInfo>>('get',`${this.basePath}/api/mandanten/templates/configtypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantenConfigUpdatedNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantenConfigUpdatedNotification(observe?: 'body', reportProgress?: boolean): Observable<MandantConfigUpdatedNotification>;
    public getMandantenConfigUpdatedNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MandantConfigUpdatedNotification>>;
    public getMandantenConfigUpdatedNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MandantConfigUpdatedNotification>>;
    public getMandantenConfigUpdatedNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MandantConfigUpdatedNotification>('get',`${this.basePath}/api/mandanten/templates/notifications/madantconfigupdated`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMandantenConstants
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMandantenConstants(observe?: 'body', reportProgress?: boolean): Observable<Array<ConstantTypeInfo>>;
    public getMandantenConstants(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ConstantTypeInfo>>>;
    public getMandantenConstants(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ConstantTypeInfo>>>;
    public getMandantenConstants(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ConstantTypeInfo>>('get',`${this.basePath}/api/mandanten/templates/constants`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
