import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFPLAN_PRUEFOBJEKT_ADMIN = '[QschecksModul] GetPruefplanPruefobjektAdmin laden';
export const GET_PRUEFPLAN_PRUEFOBJEKT_ADMIN_AUSFUEHREN = '[QschecksModul] GetPruefplanPruefobjektAdmin ausführen';
export const GET_PRUEFPLAN_PRUEFOBJEKT_ADMIN_ERFOLGREICH = '[QschecksModul] GetPruefplanPruefobjektAdmin laden erfolgreich';
export const GET_PRUEFPLAN_PRUEFOBJEKT_ADMIN_FEHLER = '[QschecksModul] GetPruefplanPruefobjektAdmin Ladefehler';
export const GET_PRUEFPLAN_PRUEFOBJEKT_ADMIN_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPruefplanPruefobjektAdmin nicht ausgeführt (evtl. Offline)';



export class GetPruefplanPruefobjektAdminAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFOBJEKT_ADMIN;
        constructor( public pruefplan_id:string ,  public pruefobjekt_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefobjektAdminAusfuehrenAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFOBJEKT_ADMIN_AUSFUEHREN;
        constructor( public pruefplan_id:string ,  public pruefobjekt_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefobjektAdminErfolgreichAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFOBJEKT_ADMIN_ERFOLGREICH;
        constructor(public payload: api.PruefobjektDetail,  public pruefplan_id:string ,  public pruefobjekt_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefobjektAdminFehlerAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFOBJEKT_ADMIN_FEHLER;
        constructor(public payload: any,  public pruefplan_id:string ,  public pruefobjekt_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefobjektAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFOBJEKT_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPruefplanPruefobjektAdminActions =
    GetPruefplanPruefobjektAdminAction
    | GetPruefplanPruefobjektAdminAusfuehrenAction
    | GetPruefplanPruefobjektAdminErfolgreichAction
    | GetPruefplanPruefobjektAdminFehlerAction
    | GetPruefplanPruefobjektAdminNichtAusgefuehrtAction
    ;
