import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_NOTIFICATION_TYPES = '[IncidentsModul] GetNotificationTypes laden';
export const GET_NOTIFICATION_TYPES_AUSFUEHREN = '[IncidentsModul] GetNotificationTypes ausführen';
export const GET_NOTIFICATION_TYPES_ERFOLGREICH = '[IncidentsModul] GetNotificationTypes laden erfolgreich';
export const GET_NOTIFICATION_TYPES_FEHLER = '[IncidentsModul] GetNotificationTypes Ladefehler';
export const GET_NOTIFICATION_TYPES_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetNotificationTypes nicht ausgeführt (evtl. Offline)';



export class GetNotificationTypesAction implements Action {
    readonly type = GET_NOTIFICATION_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetNotificationTypesAusfuehrenAction implements Action {
    readonly type = GET_NOTIFICATION_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetNotificationTypesErfolgreichAction implements Action {
    readonly type = GET_NOTIFICATION_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.NotificationMessageTypeInfo>, public optPayload: any = null) {}
    }
export class GetNotificationTypesFehlerAction implements Action {
    readonly type = GET_NOTIFICATION_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetNotificationTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_NOTIFICATION_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryNotificationTypesActions =
    GetNotificationTypesAction
    | GetNotificationTypesAusfuehrenAction
    | GetNotificationTypesErfolgreichAction
    | GetNotificationTypesFehlerAction
    | GetNotificationTypesNichtAusgefuehrtAction
    ;
