import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_PRUEFEN = '[QschecksModul] PostAufgabePruefen laden';
export const POST_AUFGABE_PRUEFEN_AUSFUEHREN = '[QschecksModul] PostAufgabePruefen ausführen';
export const POST_AUFGABE_PRUEFEN_ERFOLGREICH = '[QschecksModul] PostAufgabePruefen laden erfolgreich';
export const POST_AUFGABE_PRUEFEN_FEHLER = '[QschecksModul] PostAufgabePruefen Ladefehler';
export const POST_AUFGABE_PRUEFEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostAufgabePruefen nicht ausgeführt (evtl. Offline)';



export class PostAufgabePruefenAction implements Action {
    readonly type = POST_AUFGABE_PRUEFEN;
        constructor(public prueflaufAufgabeAntwortRequest: api.PrueflaufAufgabeAntwortRequest, public optPayload: any = null) {}
    }
export class PostAufgabePruefenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_PRUEFEN_AUSFUEHREN;
        constructor(public prueflaufAufgabeAntwortRequest: api.PrueflaufAufgabeAntwortRequest, public optPayload: any = null) {}
    }
export class PostAufgabePruefenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_PRUEFEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public prueflaufAufgabeAntwortRequest: api.PrueflaufAufgabeAntwortRequest, public optPayload: any = null) {}
    }
export class PostAufgabePruefenFehlerAction implements Action {
    readonly type = POST_AUFGABE_PRUEFEN_FEHLER;
        constructor(public payload: any, public prueflaufAufgabeAntwortRequest: api.PrueflaufAufgabeAntwortRequest, public optPayload: any = null) {}
    }
export class PostAufgabePruefenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_PRUEFEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandAufgabePruefenActions =
    PostAufgabePruefenAction
    | PostAufgabePruefenAusfuehrenAction
    | PostAufgabePruefenErfolgreichAction
    | PostAufgabePruefenFehlerAction
    | PostAufgabePruefenNichtAusgefuehrtAction
    ;
