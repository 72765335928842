import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_MITTEILUNG_SENDEN2 = '[VeranstaltungenModul] PostMitteilungSenden2 laden';
export const POST_MITTEILUNG_SENDEN2_AUSFUEHREN = '[VeranstaltungenModul] PostMitteilungSenden2 ausführen';
export const POST_MITTEILUNG_SENDEN2_ERFOLGREICH = '[VeranstaltungenModul] PostMitteilungSenden2 laden erfolgreich';
export const POST_MITTEILUNG_SENDEN2_FEHLER = '[VeranstaltungenModul] PostMitteilungSenden2 Ladefehler';
export const POST_MITTEILUNG_SENDEN2_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostMitteilungSenden2 nicht ausgeführt (evtl. Offline)';



export class PostMitteilungSenden2Action implements Action {
    readonly type = POST_MITTEILUNG_SENDEN2;
        constructor(public mitteilungenSendenRequest2: api.MitteilungenSendenRequest2, public optPayload: any = null) {}
    }
export class PostMitteilungSenden2AusfuehrenAction implements Action {
    readonly type = POST_MITTEILUNG_SENDEN2_AUSFUEHREN;
        constructor(public mitteilungenSendenRequest2: api.MitteilungenSendenRequest2, public optPayload: any = null) {}
    }
export class PostMitteilungSenden2ErfolgreichAction implements Action {
    readonly type = POST_MITTEILUNG_SENDEN2_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public mitteilungenSendenRequest2: api.MitteilungenSendenRequest2, public optPayload: any = null) {}
    }
export class PostMitteilungSenden2FehlerAction implements Action {
    readonly type = POST_MITTEILUNG_SENDEN2_FEHLER;
        constructor(public payload: any, public mitteilungenSendenRequest2: api.MitteilungenSendenRequest2, public optPayload: any = null) {}
    }
export class PostMitteilungSenden2NichtAusgefuehrtAction implements Action {
    readonly type = POST_MITTEILUNG_SENDEN2_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandMitteilungSenden2Actions =
    PostMitteilungSenden2Action
    | PostMitteilungSenden2AusfuehrenAction
    | PostMitteilungSenden2ErfolgreichAction
    | PostMitteilungSenden2FehlerAction
    | PostMitteilungSenden2NichtAusgefuehrtAction
    ;
