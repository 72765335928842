import {Action, ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';

import * as actionsUi from './benutzerauswahl.actions.ui';
import {SelektierteBenutzerAction} from './benutzerauswahl.actions.ui';

import {
    GET_BENUTZER_LISTE,
    GET_BENUTZER_LISTE_ERFOLGREICH,
    GET_BENUTZER_LISTE_FEHLER,
    GetBenutzerListeErfolgreichAction
} from '../../../client-ngrx/benutzerverwaltung';
import {Benutzer} from '../../../clients/benutzerverwaltung';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';
import {getUiSelektierteRollen} from '../rollenselektion/rollenselektion.selectors';


export interface ModuleState {
    page: PageState;
}

export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer
};

export interface PageState {
    benutzer: Array<Benutzer>;
    nichtAnzuzeigendeBenutzer: Array<any>;
    uiBenutzerLadeanimation: boolean;
    uiBenutzerLadeFehler: boolean;
    uiSelektierteBenutzer: Array<string>;
}

export const initialPageState: PageState = {
    benutzer: [],
    nichtAnzuzeigendeBenutzer: [],
    uiBenutzerLadeanimation: false,
    uiBenutzerLadeFehler: false,
    uiSelektierteBenutzer: [],
};

export function pageReducer(state = initialPageState, action: Action): PageState {
    switch (action.type) {

        // Page State Reset
        case actionsUi.PAGE_STATE_RESET: {
            const a = <actionsUi.PageStateResetAction>action;

            return Object.assign({}, state, <PageState>initialPageState);
        }

        case GET_BENUTZER_LISTE_ERFOLGREICH: {
            const a = <GetBenutzerListeErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                benutzer: a.payload,
                uiBenutzerLadeanimation: false
            });
        }

        case GET_BENUTZER_LISTE: {
            return Object.assign({}, state, <PageState>{uiBenutzerLadeanimation: true});
        }

        case GET_BENUTZER_LISTE_FEHLER: {
            return Object.assign({}, state, <PageState>{benutzer: [], uiBenutzerLadeanimation: false, uiBenutzerLadeFehler: true});
        }

        case actionsUi.NICHT_ANZUZEIGENDE_BENUTZER_SETZEN: {
            const a = <actionsUi.NichtAnzuzeigendeBenutzerSetzenAction>action;

            if (a.nichtAnzuzeigendeBenutzer == null) {
                a.nichtAnzuzeigendeBenutzer = [];
            }
            return Object.assign({}, state, <PageState>{nichtAnzuzeigendeBenutzer: a.nichtAnzuzeigendeBenutzer});
        }

        case actionsUi.SELEKTIERTE_BENUTZER: {
            const a = <SelektierteBenutzerAction>action;
            return Object.assign({}, state, <PageState>{uiSelektierteBenutzer: a.payload});
        }

        default: {
            return state;
        }
    }
}


export function getModuleState() {
    return createFeatureSelector<ModuleState>('BenutzerauswahlPage');
}

export function getPageState() {
    return createSelector(getModuleState(), (state: ModuleState) => state.page);
}

export function getBenutzer() {
    return createSelector(getPageState(), (state: PageState) => state.benutzer);
}

export function getUiBenutzerLadeanimation() {
    return createSelector(getPageState(), (state: PageState) => state.uiBenutzerLadeanimation);
}

export function getUiBenutzerLadeFehler() {
    return createSelector(getPageState(), (state: PageState) => state.uiBenutzerLadeFehler);
}

export function getNichtAnzuzeigendeRollen() {
    return createSelector(getPageState(), (state: PageState) => state.nichtAnzuzeigendeBenutzer);
}

export function getUiSelektierteBenutzer() {
    return createSelector(getPageState(), (state: PageState) => state.uiSelektierteBenutzer);
}

export function getUiBenutzer() {
    return createSelector(getBenutzer(), getNichtAnzuzeigendeRollen(),
        (benutzer: Array<Benutzer>, nichtAnzuzeigendeBenutzer: Array<string>) => {
            const res = benutzer.map((user) => <ListInputItem>{
                groupId: user.display_text.substring(0, 1).toUpperCase(),
                groupTitle: user.display_text.substring(0, 1).toUpperCase(),
                itemId: user.benutzer_id,
                itemTitle: user.display_text,
                item: user,
            });

            const filteredRes = res.filter(item => {
                const nichtAnzuzeigendeBenutzerId = nichtAnzuzeigendeBenutzer.filter(i => {
                    return i === item.itemId;
                });
                return nichtAnzuzeigendeBenutzerId.length === 0;
            });

            return filteredRes;
        });
}

export function getOneSelected() {
    return createSelector(getUiSelektierteBenutzer(), (selektierte: Array<string>): boolean => {
        return selektierte.length > 0;
    });
}
