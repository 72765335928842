import {Component, OnInit} from '@angular/core';
import {ModalController, NavController, NavParams} from '@ionic/angular';

@Component({
    selector: 'app-funktionsbereich-entfernen',
    templateUrl: './funktionsbereich-entfernen.page.html',
    styleUrls: ['./funktionsbereich-entfernen.page.scss'],
})
export class FunktionsbereichEntfernenPage implements OnInit {

    aiName = 'FunktionsbereichEntfernenModal';
    titel = '';

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private modalCtrl: ModalController) {
        this.titel = this.navParams.get('titel');
    }

    ngOnInit() {
    }


    ok() {
        this.modalCtrl.dismiss('ok');
    }

    abbrechen() {
        this.modalCtrl.dismiss();
    }
}
