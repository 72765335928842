import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFPLAN_KOPIEREN = '[QschecksModul] PostPruefplanKopieren laden';
export const POST_PRUEFPLAN_KOPIEREN_AUSFUEHREN = '[QschecksModul] PostPruefplanKopieren ausführen';
export const POST_PRUEFPLAN_KOPIEREN_ERFOLGREICH = '[QschecksModul] PostPruefplanKopieren laden erfolgreich';
export const POST_PRUEFPLAN_KOPIEREN_FEHLER = '[QschecksModul] PostPruefplanKopieren Ladefehler';
export const POST_PRUEFPLAN_KOPIEREN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefplanKopieren nicht ausgeführt (evtl. Offline)';



export class PostPruefplanKopierenAction implements Action {
    readonly type = POST_PRUEFPLAN_KOPIEREN;
        constructor(public pruefplanRequest: api.PruefplanRequest, public optPayload: any = null) {}
    }
export class PostPruefplanKopierenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFPLAN_KOPIEREN_AUSFUEHREN;
        constructor(public pruefplanRequest: api.PruefplanRequest, public optPayload: any = null) {}
    }
export class PostPruefplanKopierenErfolgreichAction implements Action {
    readonly type = POST_PRUEFPLAN_KOPIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefplanRequest: api.PruefplanRequest, public optPayload: any = null) {}
    }
export class PostPruefplanKopierenFehlerAction implements Action {
    readonly type = POST_PRUEFPLAN_KOPIEREN_FEHLER;
        constructor(public payload: any, public pruefplanRequest: api.PruefplanRequest, public optPayload: any = null) {}
    }
export class PostPruefplanKopierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFPLAN_KOPIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefplanKopierenActions =
    PostPruefplanKopierenAction
    | PostPruefplanKopierenAusfuehrenAction
    | PostPruefplanKopierenErfolgreichAction
    | PostPruefplanKopierenFehlerAction
    | PostPruefplanKopierenNichtAusgefuehrtAction
    ;
