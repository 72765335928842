import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const GET_BEREICH_DETAIL = '[DisplaysteuerungModul] GetBereichDetail laden';
export const GET_BEREICH_DETAIL_AUSFUEHREN = '[DisplaysteuerungModul] GetBereichDetail ausführen';
export const GET_BEREICH_DETAIL_ERFOLGREICH = '[DisplaysteuerungModul] GetBereichDetail laden erfolgreich';
export const GET_BEREICH_DETAIL_FEHLER = '[DisplaysteuerungModul] GetBereichDetail Ladefehler';
export const GET_BEREICH_DETAIL_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] GetBereichDetail nicht ausgeführt (evtl. Offline)';



export class GetBereichDetailAction implements Action {
    readonly type = GET_BEREICH_DETAIL;
        constructor( public bereich_id:string , public optPayload: any = null) {}
    }
export class GetBereichDetailAusfuehrenAction implements Action {
    readonly type = GET_BEREICH_DETAIL_AUSFUEHREN;
        constructor( public bereich_id:string , public optPayload: any = null) {}
    }
export class GetBereichDetailErfolgreichAction implements Action {
    readonly type = GET_BEREICH_DETAIL_ERFOLGREICH;
        constructor(public payload: api.BereichDetail,  public bereich_id:string , public optPayload: any = null) {}
    }
export class GetBereichDetailFehlerAction implements Action {
    readonly type = GET_BEREICH_DETAIL_FEHLER;
        constructor(public payload: any,  public bereich_id:string , public optPayload: any = null) {}
    }
export class GetBereichDetailNichtAusgefuehrtAction implements Action {
    readonly type = GET_BEREICH_DETAIL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungQueryBereichDetailActions =
    GetBereichDetailAction
    | GetBereichDetailAusfuehrenAction
    | GetBereichDetailErfolgreichAction
    | GetBereichDetailFehlerAction
    | GetBereichDetailNichtAusgefuehrtAction
    ;
