export namespace MandantenConfigTypes {
    export const MANDANT_INFO = 'mandant-id';
    export const ABRECHNUNG_MANDANT_OPTIONS = 'abrechnung';
    export const AZURE_AD_MANDANT_OPTIONS = 'azuread';
    export const CRYPTO_MANDANT_OPTIONS = 'crypto';
    export const CUSTOM_URL_MANDANT_OPTIONS = 'custom-url';
    export const LIZENZ_MANDANT_OPTIONS = 'lizenz';
    export const MANDANT_STAMM_OPTIONS = 'stamm';
    export const SCANDIT_LICENSE_MANDANT_OPTIONS = 'scandit';
    
}

