import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICH_CHECKLISTEN_AUFGABEN = '[VeranstaltungenModul] GetFunktionsbereichChecklistenAufgaben laden';
export const GET_FUNKTIONSBEREICH_CHECKLISTEN_AUFGABEN_AUSFUEHREN = '[VeranstaltungenModul] GetFunktionsbereichChecklistenAufgaben ausführen';
export const GET_FUNKTIONSBEREICH_CHECKLISTEN_AUFGABEN_ERFOLGREICH = '[VeranstaltungenModul] GetFunktionsbereichChecklistenAufgaben laden erfolgreich';
export const GET_FUNKTIONSBEREICH_CHECKLISTEN_AUFGABEN_FEHLER = '[VeranstaltungenModul] GetFunktionsbereichChecklistenAufgaben Ladefehler';
export const GET_FUNKTIONSBEREICH_CHECKLISTEN_AUFGABEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunktionsbereichChecklistenAufgaben nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichChecklistenAufgabenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_AUFGABEN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichChecklistenAufgabenAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_AUFGABEN_AUSFUEHREN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichChecklistenAufgabenErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_AUFGABEN_ERFOLGREICH;
        constructor(public payload: Array<api.PhasenChecklistenAufgaben>,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichChecklistenAufgabenFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_AUFGABEN_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichChecklistenAufgabenNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_AUFGABEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunktionsbereichChecklistenAufgabenActions =
    GetFunktionsbereichChecklistenAufgabenAction
    | GetFunktionsbereichChecklistenAufgabenAusfuehrenAction
    | GetFunktionsbereichChecklistenAufgabenErfolgreichAction
    | GetFunktionsbereichChecklistenAufgabenFehlerAction
    | GetFunktionsbereichChecklistenAufgabenNichtAusgefuehrtAction
    ;
