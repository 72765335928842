import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereiche laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereiche ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereiche laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereiche Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereiche nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereicheAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ERFOLGREICH;
        constructor(public payload: Array<api.Funktionsbereich>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereicheActions =
    GetVeranstaltungFunktionsbereicheAction
    | GetVeranstaltungFunktionsbereicheAusfuehrenAction
    | GetVeranstaltungFunktionsbereicheErfolgreichAction
    | GetVeranstaltungFunktionsbereicheFehlerAction
    | GetVeranstaltungFunktionsbereicheNichtAusgefuehrtAction
    ;
