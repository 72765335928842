import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_CHECKLISTE_REIHENFOLGEN = '[VorlagenModul] PostChecklisteReihenfolgen laden';
export const POST_CHECKLISTE_REIHENFOLGEN_AUSFUEHREN = '[VorlagenModul] PostChecklisteReihenfolgen ausführen';
export const POST_CHECKLISTE_REIHENFOLGEN_ERFOLGREICH = '[VorlagenModul] PostChecklisteReihenfolgen laden erfolgreich';
export const POST_CHECKLISTE_REIHENFOLGEN_FEHLER = '[VorlagenModul] PostChecklisteReihenfolgen Ladefehler';
export const POST_CHECKLISTE_REIHENFOLGEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostChecklisteReihenfolgen nicht ausgeführt (evtl. Offline)';



export class PostChecklisteReihenfolgenAction implements Action {
    readonly type = POST_CHECKLISTE_REIHENFOLGEN;
        constructor(public checklisteIdListeRequest: api.ChecklisteIdListeRequest, public optPayload: any = null) {}
    }
export class PostChecklisteReihenfolgenAusfuehrenAction implements Action {
    readonly type = POST_CHECKLISTE_REIHENFOLGEN_AUSFUEHREN;
        constructor(public checklisteIdListeRequest: api.ChecklisteIdListeRequest, public optPayload: any = null) {}
    }
export class PostChecklisteReihenfolgenErfolgreichAction implements Action {
    readonly type = POST_CHECKLISTE_REIHENFOLGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public checklisteIdListeRequest: api.ChecklisteIdListeRequest, public optPayload: any = null) {}
    }
export class PostChecklisteReihenfolgenFehlerAction implements Action {
    readonly type = POST_CHECKLISTE_REIHENFOLGEN_FEHLER;
        constructor(public payload: any, public checklisteIdListeRequest: api.ChecklisteIdListeRequest, public optPayload: any = null) {}
    }
export class PostChecklisteReihenfolgenNichtAusgefuehrtAction implements Action {
    readonly type = POST_CHECKLISTE_REIHENFOLGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandChecklisteReihenfolgenActions =
    PostChecklisteReihenfolgenAction
    | PostChecklisteReihenfolgenAusfuehrenAction
    | PostChecklisteReihenfolgenErfolgreichAction
    | PostChecklisteReihenfolgenFehlerAction
    | PostChecklisteReihenfolgenNichtAusgefuehrtAction
    ;
