import {Action, ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';

import * as actionsUi from './checklistenauswahl.actions.ui';
import {GET_FUNKTIONSBEREICHE_CHECKLISTEN, GET_FUNKTIONSBEREICHE_CHECKLISTEN_ERFOLGREICH, GET_FUNKTIONSBEREICHE_CHECKLISTEN_FEHLER, GetFunktionsbereicheChecklistenErfolgreichAction} from '../../../client-ngrx/vorlagen';
import {Checkliste, FunktionsbereichChecklisten} from '../../../clients/vorlagen';
import {APP_RESET_ACTION} from '@orga-app/appstart/actions';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';

export interface ModuleState {
    page: PageState;
}

export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer
};

export interface PageState {
    funktionsbereichChecklisten: Array<FunktionsbereichChecklisten>;
    nichtAnzuzeigendeChecklisten: Array<any>;
    beteiligteFunktionsbereiche: Array<string>;
    uiChecklistenLadeanimation: boolean;
    uiChecklistenLadeFehler: boolean;
    uiSelektierteChecklisten: Array<Checkliste>;
}

export const initialPageState: PageState = {
    funktionsbereichChecklisten: [],
    nichtAnzuzeigendeChecklisten: [],
    beteiligteFunktionsbereiche: [],
    uiChecklistenLadeanimation: false,
    uiChecklistenLadeFehler: false,
    uiSelektierteChecklisten: []
};


export function pageReducer(state = initialPageState, action: Action): PageState {
    switch (action.type) {

        // APP RESET
        case APP_RESET_ACTION: {
            return Object.assign({}, state, <PageState>initialPageState);
        }

        // Page State Reset
        case actionsUi.PAGE_STATE_RESET: {
            // const a = <actionsUi.PageStateResetAction> action;

            return Object.assign({}, state, <PageState>initialPageState);
        }

        case GET_FUNKTIONSBEREICHE_CHECKLISTEN: {
            return Object.assign({}, state, <PageState>{uiChecklistenLadeanimation: true});
        }

        case GET_FUNKTIONSBEREICHE_CHECKLISTEN_ERFOLGREICH: {
            const a = <GetFunktionsbereicheChecklistenErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                funktionsbereichChecklisten: a.payload,
                uiChecklistenLadeanimation: false,
                uiChecklistenLadeFehler: false
            });
        }

        case GET_FUNKTIONSBEREICHE_CHECKLISTEN_FEHLER: {
            return Object.assign({}, state, <PageState>{
                funktionsbereichChecklisten: [],
                uiChecklistenLadeanimation: false,
                uiChecklistenLadeFehler: true
            });
        }

        case actionsUi.NICHT_ANZUZEIGENDE_CHECKLISTEN_SETZEN: {
            const a = <actionsUi.NichtAnzuzeigendeChecklistenSetzenAction>action;

            if (a.nichtAnzuzeigendeChecklisten == null) {
                a.nichtAnzuzeigendeChecklisten = [];
            }
            return Object.assign({}, state, <PageState>{nichtAnzuzeigendeChecklisten: a.nichtAnzuzeigendeChecklisten});
        }

        case actionsUi.BETEILIGTE_FUNKTIONSBEREICHE_SETZEN: {
            const a = <actionsUi.BeteiligteFunktionsbereicheSetzenAction>action;

            if (a.beteiligteFunktionsbereiche == null) {
                a.beteiligteFunktionsbereiche = [];
            }
            return Object.assign({}, state, <PageState>{beteiligteFunktionsbereiche: a.beteiligteFunktionsbereiche});
        }

        case actionsUi.SELEKTIERTE_CHECKLISTEN: {
            const a = <actionsUi.SelektierteChecklistenAction>action;

            return Object.assign({}, state, <PageState>{uiSelektierteChecklisten: a.payload});
        }

        default: {
            return state;
        }
    }
}


/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export function getModuleState() {
    return createFeatureSelector<ModuleState>('ChecklistenauswahlModal');
}

export function getPageState() {
    return createSelector(getModuleState(), (state: ModuleState) => state.page);
}

export function getUiChecklistenLadeanimation() {
    return createSelector(getPageState(), (state: PageState) => state.uiChecklistenLadeanimation);
}

export function getUiChecklistenLadeFehler() {
    return createSelector(getPageState(), (state: PageState) => state.uiChecklistenLadeFehler);
}

export function getNichtAnzuzeigendeChecklisten() {
    return createSelector(getPageState(), (state: PageState) => state.nichtAnzuzeigendeChecklisten);
}

export function getBeteiligteFunktionsbereiche() {
    return createSelector(getPageState(), (state: PageState) => state.beteiligteFunktionsbereiche);
}

export function getUiSelektierteChecklisten() {
    return createSelector(getPageState(), (state: PageState) => state.uiSelektierteChecklisten);
}

export function getChecklisten() {
    return createSelector(getPageState(), getNichtAnzuzeigendeChecklisten(), getBeteiligteFunktionsbereiche(),
        (state: PageState, nichtAnzuzeigendeRollen: Array<string>, beteiligteFBs: Array<string>) => {
            const res: Array<ListInputItem> = [];
            state.funktionsbereichChecklisten.forEach(fb => {
                fb.checklisten.forEach(cl => {
                    if (beteiligteFBs.findIndex(betFbId => betFbId === fb.funktionsbereich.funktionsbereich_id) > -1) {
                        res.push(<ListInputItem>{
                            groupId: fb.funktionsbereich.funktionsbereich_id,
                            groupTitle: fb.funktionsbereich.titel,
                            itemId: cl.checkliste_id,
                            itemTitle: cl.titel,
                            item: cl,
                        });
                    }
                });
            });

            const filteredRes = res.filter(item => {
                const nichtAnzuzeigendeRolleId = nichtAnzuzeigendeRollen.filter(i => {
                    return i === item.itemId;
                });
                return nichtAnzuzeigendeRolleId.length === 0;
            });

            return filteredRes;
        });
}
