import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFPLAN_PRUEFAUFGABE_ADMIN = '[QschecksModul] GetPruefplanPruefaufgabeAdmin laden';
export const GET_PRUEFPLAN_PRUEFAUFGABE_ADMIN_AUSFUEHREN = '[QschecksModul] GetPruefplanPruefaufgabeAdmin ausführen';
export const GET_PRUEFPLAN_PRUEFAUFGABE_ADMIN_ERFOLGREICH = '[QschecksModul] GetPruefplanPruefaufgabeAdmin laden erfolgreich';
export const GET_PRUEFPLAN_PRUEFAUFGABE_ADMIN_FEHLER = '[QschecksModul] GetPruefplanPruefaufgabeAdmin Ladefehler';
export const GET_PRUEFPLAN_PRUEFAUFGABE_ADMIN_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPruefplanPruefaufgabeAdmin nicht ausgeführt (evtl. Offline)';



export class GetPruefplanPruefaufgabeAdminAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFAUFGABE_ADMIN;
        constructor( public pruefplan_id:string ,  public checkliste_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefaufgabeAdminAusfuehrenAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFAUFGABE_ADMIN_AUSFUEHREN;
        constructor( public pruefplan_id:string ,  public checkliste_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefaufgabeAdminErfolgreichAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFAUFGABE_ADMIN_ERFOLGREICH;
        constructor(public payload: api.PruefaufgabeDetail,  public pruefplan_id:string ,  public checkliste_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefaufgabeAdminFehlerAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFAUFGABE_ADMIN_FEHLER;
        constructor(public payload: any,  public pruefplan_id:string ,  public checkliste_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefaufgabeAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFAUFGABE_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPruefplanPruefaufgabeAdminActions =
    GetPruefplanPruefaufgabeAdminAction
    | GetPruefplanPruefaufgabeAdminAusfuehrenAction
    | GetPruefplanPruefaufgabeAdminErfolgreichAction
    | GetPruefplanPruefaufgabeAdminFehlerAction
    | GetPruefplanPruefaufgabeAdminNichtAusgefuehrtAction
    ;
