import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_FUNKPROTOKOLL = '[VeranstaltungenModul] GetFunkprotokoll laden';
export const GET_FUNKPROTOKOLL_AUSFUEHREN = '[VeranstaltungenModul] GetFunkprotokoll ausführen';
export const GET_FUNKPROTOKOLL_ERFOLGREICH = '[VeranstaltungenModul] GetFunkprotokoll laden erfolgreich';
export const GET_FUNKPROTOKOLL_FEHLER = '[VeranstaltungenModul] GetFunkprotokoll Ladefehler';
export const GET_FUNKPROTOKOLL_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunkprotokoll nicht ausgeführt (evtl. Offline)';



export class GetFunkprotokollAction implements Action {
    readonly type = GET_FUNKPROTOKOLL;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollAusfuehrenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollErfolgreichAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_ERFOLGREICH;
        constructor(public payload: api.Funkprotokoll,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollFehlerAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunkprotokollActions =
    GetFunkprotokollAction
    | GetFunkprotokollAusfuehrenAction
    | GetFunkprotokollErfolgreichAction
    | GetFunkprotokollFehlerAction
    | GetFunkprotokollNichtAusgefuehrtAction
    ;
