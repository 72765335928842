import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGS_TYP_ADMIN_ROLLEN = '[VeranstaltungstypenModul] GetVeranstaltungsTypAdminRollen laden';
export const GET_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_AUSFUEHREN = '[VeranstaltungstypenModul] GetVeranstaltungsTypAdminRollen ausführen';
export const GET_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_ERFOLGREICH = '[VeranstaltungstypenModul] GetVeranstaltungsTypAdminRollen laden erfolgreich';
export const GET_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_FEHLER = '[VeranstaltungstypenModul] GetVeranstaltungsTypAdminRollen Ladefehler';
export const GET_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] GetVeranstaltungsTypAdminRollen nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungsTypAdminRollenAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_ADMIN_ROLLEN;
        constructor( public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypAdminRollenAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_AUSFUEHREN;
        constructor( public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypAdminRollenErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichRolle>,  public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypAdminRollenFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_FEHLER;
        constructor(public payload: any,  public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypAdminRollenNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenQueryVeranstaltungsTypAdminRollenActions =
    GetVeranstaltungsTypAdminRollenAction
    | GetVeranstaltungsTypAdminRollenAusfuehrenAction
    | GetVeranstaltungsTypAdminRollenErfolgreichAction
    | GetVeranstaltungsTypAdminRollenFehlerAction
    | GetVeranstaltungsTypAdminRollenNichtAusgefuehrtAction
    ;
