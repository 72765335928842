import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import * as fromBenutzerauswahlModal from './benutzerauswahl.reducer';
import {ModalController, NavController, NavParams} from '@ionic/angular';
import * as actionsUi from './benutzerauswahl.actions.ui';
import {select, Store} from '@ngrx/store';
import {GetBenutzerListeAction} from '../../../client-ngrx/benutzerverwaltung';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';

@Component({
    selector: 'app-benutzerauswahl',
    templateUrl: './benutzerauswahl.page.html',
    styleUrls: ['./benutzerauswahl.page.scss'],
})
export class BenutzerauswahlPage implements OnInit {
    @Input() aktuell: Array<string>;

    aiName = 'BenutzerauswahlModal';

    benutzer$: Observable<Array<ListInputItem>>;
    uiBenutzerLadeanimation$: Observable<boolean>;
    uiBenutzerLadeFehler$: Observable<boolean>;
    uiSelektierteBenutzer$: Observable<Array<string>>;
    oneSelected$: Observable<boolean>;
    titel: string = this.navParams.get('titel');

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private modalCtrl: ModalController,
                private store: Store<fromBenutzerauswahlModal.PageState>) {
    }

    ngOnInit(): void {
        this.benutzer$ = this.store.pipe(select(fromBenutzerauswahlModal.getUiBenutzer()));
        this.uiBenutzerLadeanimation$ = this.store.pipe(select(fromBenutzerauswahlModal.getUiBenutzerLadeanimation()));
        this.uiBenutzerLadeFehler$ = this.store.pipe(select(fromBenutzerauswahlModal.getUiBenutzerLadeFehler()));
        this.uiSelektierteBenutzer$ = this.store.pipe(select(fromBenutzerauswahlModal.getUiSelektierteBenutzer()));
        this.oneSelected$ = this.store.pipe(select(fromBenutzerauswahlModal.getOneSelected()));
    }

    ionViewWillEnter() {
        this.viewWillEnterPage().then();
    }

    async viewWillEnterPage() {
        await this.store.dispatch(new actionsUi.PageStateResetAction());
        await this.store.dispatch(new actionsUi.NichtAnzuzeigendeBenutzerSetzenAction(this.aktuell));

        await this.benutzerLaden();
    }

    async ok() {
        this.uiSelektierteBenutzer$.subscribe(async (item) => {
            const data = {hinzugefuegte: item, entfernte: []};
            await this.modalCtrl.dismiss(data);
        }).unsubscribe();
    }

    async abbrechen() {
        await this.modalCtrl.dismiss();
    }

    async benutzerLaden() {
        await this.store.dispatch(new GetBenutzerListeAction());
    }

    async updateSelectedBenutzer($event: Array<string>) {
        await this.store.dispatch(new actionsUi.SelektierteBenutzerAction($event));
    }

}
