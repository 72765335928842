import {Action, ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
// import * as api from '@orga-app/clients/auth';
import * as actions from './actions';
import {Benutzer} from '../../clients/api';

export const reducers: ActionReducerMap<ModuleState> = {
    auth: authReducer
};

export interface ModuleState {
    auth: AuthState;
}


export interface AuthState {
    userInfo: Benutzer;
    // tokenInfo: api.TokenInfo;
    // loggedInWithAzure: boolean;
    isLoggedIn: boolean;
    // loginInProcess: boolean;
    noRightsToAccess: boolean;
    error: string;
}


export const initialAuthState: AuthState = {
    userInfo: null,
    // tokenInfo: null,
    // loggedInWithAzure: false,
    isLoggedIn: false,
    // loginInProcess: false,
    noRightsToAccess: false,
    error: ''
};

export function authReducer(state = initialAuthState, action: Action): AuthState {
    switch (action.type) {
        // case actions.AUTH_LOGIN: {
        //     return Object.assign({}, state, <AuthState> {
        //         userInfo: {},
        //         // tokenInfo: {},
        //         isLoggedIn: false,
        //         error: ''
        //         // loginInProcess: true
        //     });
        // }

        case actions.AUTH_ERROR: {
            return Object.assign({}, state, <AuthState> {
                userInfo: {},
                isLoggedIn: false,
                error: ''
            });
        }

        case actions.AUTH_LOGIN_ERFOLGREICH: {
            const a = <actions.AuthLoginErfolgreichAction> action;

            if (a.tokenRefresh) {
                return Object.assign({}, state, <AuthState> {
                    isLoggedIn: true,
                    error: '',
                    // loginInProcess: false,
                    // loggedInWithAzure: false
                });
            }

            return Object.assign({}, state, <AuthState> {
                userInfo: a.payload,
                isLoggedIn: true,
                error: '',
                // loginInProcess: false,
                // loggedInWithAzure: false
            });
        }

        case actions.AUTH_LOGIN_FEHLER: {
            const a = <actions.AuthLoginFehlgeschlagenAction> action;
            const errorText = 'An error occurred while sending the request.';

            return Object.assign({}, state, <AuthState> {
                userInfo: {},
                // tokenInfo: {},
                isLoggedIn: false,
                error: a.error,
                // loginInProcess: false
            });
        }

        case actions.AUTH_LOGOUT_ERFOLGREICH: {
            const a = <actions.AuthLogoutErfolgreichAction> action;

            return Object.assign({}, state, <AuthState> {
                userInfo: {},
                // tokenInfo: {},
                isLoggedIn: false,
                error: '',
                // loginInProcess: false,
                // loggedInWithAzure: false
            });
        }

        default: {
            return state;
        }
    }
}



/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const getModuleState = createFeatureSelector<ModuleState>('Auth');

export const getAuthState = createSelector(getModuleState, (state: ModuleState) => state.auth);

// export const getLoginInProcess = createSelector(getAuthState, (state: AuthState) => state.loginInProcess);

export const getIsLoggedIn = createSelector(getAuthState, (state: AuthState) => state.isLoggedIn);

export const getUserInfo = createSelector(getAuthState, (state: AuthState) => state.userInfo);

// export const getTokenInfo = createSelector(getAuthState, (state: AuthState) => state.tokenInfo);

export const getError = createSelector(getAuthState, (state: AuthState) => state.error);

export const getNoRightsToAccess = createSelector(getAuthState, (state: AuthState) => state.noRightsToAccess);
