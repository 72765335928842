import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {LoadingController, MenuController} from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { AuthService } from '@orga-app/auth';
import { getIsLoggedIn } from '@orga-app/auth/reducer';
import { MandantInfo, MandantStammOptions } from '@orga-app/clients/api';
import { Benutzer } from '@orga-app/clients/benutzerverwaltung';
import { getBenutzer, getMandant, getMandantStammOptions } from '@orga-app/mandant/reducer';
import { MandantAuswahlRoute } from '@orga-app/mandant/variables';
import { ImageService } from '@orga-app/providers/image.service';
import { Settings, SettingsProvider } from '@orga-app/settings';
import { getTheme } from '@orga-app/settings/reducer';
import { Observable } from 'rxjs';
import { filter, map, takeWhile, withLatestFrom } from 'rxjs/operators';
import {EinlasskontrolleBenutzerZugriff} from '../../clients/einlasskontrolle';
import {getZugriffEinlasskontrolle} from '../navi/navi.reducer';
import {DocumentService} from '@orga-app/providers/document.service';
import {NotificationService} from '@orga-app/providers/notification.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  public settings$: Observable<Settings>;
  themeDefault$: Observable<Settings>;
  logoHeaderSVG$: Observable<SafeUrl>;
  public mandant$: Observable<MandantInfo>;
  public mandantStammOptions$: Observable<MandantStammOptions>;
  public benutzer$: Observable<Benutzer>;
  public zugriffEinlasskontrolle$: Observable<EinlasskontrolleBenutzerZugriff>;
  private alive = true;
  image: any;

  aiName = 'HomeComponent';

  constructor(
    public route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private store: Store<any>,
    private settingsProvider: SettingsProvider,
    private menu: MenuController,
    private imageService: ImageService,
    private sanitizer: DomSanitizer,
    public loadingCtrl: LoadingController,
    private documentService: DocumentService,
    private notificationService: NotificationService,
    @Inject(MandantAuswahlRoute) private mandantAuswahlRoute: string
  ) {
    this.themeDefault$ = this.settingsProvider.getThemeDefault();
    this.logoHeaderSVG$ = this.settingsProvider.getLogoHeaderSVG();
    this.settings$ = this.store.pipe(select(getTheme));

    this.mandant$ = this.store.pipe(select(getMandant));
    this.mandantStammOptions$ = this.store.pipe(select(getMandantStammOptions));
    this.benutzer$ = this.store.pipe(select(getBenutzer));

    this.zugriffEinlasskontrolle$ = this.store.pipe(select(getZugriffEinlasskontrolle));

    this.mandantStammOptions$.subscribe((x: MandantStammOptions) => {
      if (x !== null) {
        if (x.logo_id !== undefined && x.logo_id !== null && x.logo_id !== '') {
          this.imageService.getImage('/api/mandanten/logo/scale/200/200')
            .pipe(
              takeWhile(() => this.alive)
              , filter(image => !!image)
            )
            .subscribe(image => {
              const blob = new Blob([image]);

              const urlCreator = window.URL;
              this.image = this.sanitizer.bypassSecurityTrustUrl(
                urlCreator.createObjectURL(blob));
            },
              error => {
                this.image = null;
              });
        }
      }
    });

    this.route.url.pipe(
      filter(() => this.authService.hasValidToken()),
      withLatestFrom(this.store.pipe(select(getIsLoggedIn))),
      filter(([x, isLoggedIn]) => isLoggedIn),
      map(([x, isLoggedIn]) => x),

      withLatestFrom(this.store.pipe(select(getMandant)))

    ).subscribe(([x, mandant]: [any, MandantInfo]) => {
      if (mandant == null) {

        console.log('HomeComponent:: Navigate to Mandant Auswahl');
        this.router.navigate([this.mandantAuswahlRoute, { returnTo: window.location.pathname }],
          {
            preserveFragment: true
          });
      } else {
        console.log('HomeComponent:: open menu');
        this.menu.open();
      }
    });

  }

  ngOnInit() {

  }

  // ionViewWillEnter() {
  //   // console.log('ngOnInit');
  //   this.store.pipe(
  //     select(getIsLoggedIn),
  //     filter(b => b),
  //     withLatestFrom(this.store.select(getMandant)),
  //     map(([b, m]: [boolean, MandantInfo]) => {
  //       return m;
  //     })
  //   ).subscribe(m => {
  //     if (m == null) {

  //       console.log("Navigate to Mandant Auswahl");
  //       this.router.navigate([this.mandantAuswahlRoute, { returnTo: window.location.pathname }],
  //         {
  //           preserveFragment: true
  //         });
  //     } else {
  //       console.log("open menu");
  //       this.menu.open();
  //     }
  //   }).unsubscribe();
  // }

  async openMandantWechseln() {
    await this.router.navigate([this.mandantAuswahlRoute, { returnTo: window.location.pathname }],
      {
        preserveFragment: true
      });
  }


  async excelOeffnen() {
    const loading = await this.loadingCtrl.create({
      spinner: 'dots',
      message: 'Exceldownload für die Protokollierung der Mitarbeiter in der Einlasskontrolle'
    });
    await loading.present();

    const url = '/api/einlasskontrolle/query/protokoll/excel';
    this.documentService.getDocument(url, {}).pipe(takeWhile(() => this.alive))
        .subscribe(async (document) => {
              if (document != null) {
                // console.log('loaded');
                await loading.dismiss();

                const date = new Date();
                const month: number = <number>date.getMonth() + 1;
                this.documentService.saveFile(document, 'ProtokollEinlasskontrolle-' + date.getFullYear() + '-' + month + '-' + date.getDate());
              }
            },
            error => {
              this.notificationService.showMessageWithOk('Die Datei konnte nicht geladen werden.');
              loading.dismiss();
            }, () => {
            });
  }
}
