import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_API_CONFIG_TYPES = '[ApiModul] GetApiConfigTypes laden';
export const GET_API_CONFIG_TYPES_AUSFUEHREN = '[ApiModul] GetApiConfigTypes ausführen';
export const GET_API_CONFIG_TYPES_ERFOLGREICH = '[ApiModul] GetApiConfigTypes laden erfolgreich';
export const GET_API_CONFIG_TYPES_FEHLER = '[ApiModul] GetApiConfigTypes Ladefehler';
export const GET_API_CONFIG_TYPES_NICHT_AUSGEFUEHRT = '[ApiModul] GetApiConfigTypes nicht ausgeführt (evtl. Offline)';



export class GetApiConfigTypesAction implements Action {
    readonly type = GET_API_CONFIG_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetApiConfigTypesAusfuehrenAction implements Action {
    readonly type = GET_API_CONFIG_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetApiConfigTypesErfolgreichAction implements Action {
    readonly type = GET_API_CONFIG_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetApiConfigTypesFehlerAction implements Action {
    readonly type = GET_API_CONFIG_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetApiConfigTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_API_CONFIG_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryApiConfigTypesActions =
    GetApiConfigTypesAction
    | GetApiConfigTypesAusfuehrenAction
    | GetApiConfigTypesErfolgreichAction
    | GetApiConfigTypesFehlerAction
    | GetApiConfigTypesNichtAusgefuehrtAction
    ;
