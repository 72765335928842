import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_CONSTANTS = '[BenutzerverwaltungModul] GetConstants laden';
export const GET_CONSTANTS_AUSFUEHREN = '[BenutzerverwaltungModul] GetConstants ausführen';
export const GET_CONSTANTS_ERFOLGREICH = '[BenutzerverwaltungModul] GetConstants laden erfolgreich';
export const GET_CONSTANTS_FEHLER = '[BenutzerverwaltungModul] GetConstants Ladefehler';
export const GET_CONSTANTS_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetConstants nicht ausgeführt (evtl. Offline)';



export class GetConstantsAction implements Action {
    readonly type = GET_CONSTANTS;
        constructor(public optPayload: any = null) {}
    }
export class GetConstantsAusfuehrenAction implements Action {
    readonly type = GET_CONSTANTS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetConstantsErfolgreichAction implements Action {
    readonly type = GET_CONSTANTS_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetConstantsFehlerAction implements Action {
    readonly type = GET_CONSTANTS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetConstantsNichtAusgefuehrtAction implements Action {
    readonly type = GET_CONSTANTS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryConstantsActions =
    GetConstantsAction
    | GetConstantsAusfuehrenAction
    | GetConstantsErfolgreichAction
    | GetConstantsFehlerAction
    | GetConstantsNichtAusgefuehrtAction
    ;
