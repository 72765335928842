import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_KOMMUNIKATION_CONFIG_TYPES = '[KommunikationModul] GetKommunikationConfigTypes laden';
export const GET_KOMMUNIKATION_CONFIG_TYPES_AUSFUEHREN = '[KommunikationModul] GetKommunikationConfigTypes ausführen';
export const GET_KOMMUNIKATION_CONFIG_TYPES_ERFOLGREICH = '[KommunikationModul] GetKommunikationConfigTypes laden erfolgreich';
export const GET_KOMMUNIKATION_CONFIG_TYPES_FEHLER = '[KommunikationModul] GetKommunikationConfigTypes Ladefehler';
export const GET_KOMMUNIKATION_CONFIG_TYPES_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetKommunikationConfigTypes nicht ausgeführt (evtl. Offline)';



export class GetKommunikationConfigTypesAction implements Action {
    readonly type = GET_KOMMUNIKATION_CONFIG_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetKommunikationConfigTypesAusfuehrenAction implements Action {
    readonly type = GET_KOMMUNIKATION_CONFIG_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetKommunikationConfigTypesErfolgreichAction implements Action {
    readonly type = GET_KOMMUNIKATION_CONFIG_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetKommunikationConfigTypesFehlerAction implements Action {
    readonly type = GET_KOMMUNIKATION_CONFIG_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetKommunikationConfigTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_KOMMUNIKATION_CONFIG_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryKommunikationConfigTypesActions =
    GetKommunikationConfigTypesAction
    | GetKommunikationConfigTypesAusfuehrenAction
    | GetKommunikationConfigTypesErfolgreichAction
    | GetKommunikationConfigTypesFehlerAction
    | GetKommunikationConfigTypesNichtAusgefuehrtAction
    ;
