import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_LOESCHEN = '[VorlagenModul] PostAufgabeLoeschen laden';
export const POST_AUFGABE_LOESCHEN_AUSFUEHREN = '[VorlagenModul] PostAufgabeLoeschen ausführen';
export const POST_AUFGABE_LOESCHEN_ERFOLGREICH = '[VorlagenModul] PostAufgabeLoeschen laden erfolgreich';
export const POST_AUFGABE_LOESCHEN_FEHLER = '[VorlagenModul] PostAufgabeLoeschen Ladefehler';
export const POST_AUFGABE_LOESCHEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostAufgabeLoeschen nicht ausgeführt (evtl. Offline)';



export class PostAufgabeLoeschenAction implements Action {
    readonly type = POST_AUFGABE_LOESCHEN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeLoeschenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_LOESCHEN_AUSFUEHREN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeLoeschenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_LOESCHEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeLoeschenFehlerAction implements Action {
    readonly type = POST_AUFGABE_LOESCHEN_FEHLER;
        constructor(public payload: any, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeLoeschenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_LOESCHEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandAufgabeLoeschenActions =
    PostAufgabeLoeschenAction
    | PostAufgabeLoeschenAusfuehrenAction
    | PostAufgabeLoeschenErfolgreichAction
    | PostAufgabeLoeschenFehlerAction
    | PostAufgabeLoeschenNichtAusgefuehrtAction
    ;
