import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_AZURE_AD_MANDANT_OPTIONS = '[ApiModul] GetAzureAdMandantOptions laden';
export const GET_AZURE_AD_MANDANT_OPTIONS_AUSFUEHREN = '[ApiModul] GetAzureAdMandantOptions ausführen';
export const GET_AZURE_AD_MANDANT_OPTIONS_ERFOLGREICH = '[ApiModul] GetAzureAdMandantOptions laden erfolgreich';
export const GET_AZURE_AD_MANDANT_OPTIONS_FEHLER = '[ApiModul] GetAzureAdMandantOptions Ladefehler';
export const GET_AZURE_AD_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT = '[ApiModul] GetAzureAdMandantOptions nicht ausgeführt (evtl. Offline)';



export class GetAzureAdMandantOptionsAction implements Action {
    readonly type = GET_AZURE_AD_MANDANT_OPTIONS;
        constructor(public optPayload: any = null) {}
    }
export class GetAzureAdMandantOptionsAusfuehrenAction implements Action {
    readonly type = GET_AZURE_AD_MANDANT_OPTIONS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetAzureAdMandantOptionsErfolgreichAction implements Action {
    readonly type = GET_AZURE_AD_MANDANT_OPTIONS_ERFOLGREICH;
        constructor(public payload: api.AzureAdMandantOptions, public optPayload: any = null) {}
    }
export class GetAzureAdMandantOptionsFehlerAction implements Action {
    readonly type = GET_AZURE_AD_MANDANT_OPTIONS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetAzureAdMandantOptionsNichtAusgefuehrtAction implements Action {
    readonly type = GET_AZURE_AD_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryAzureAdMandantOptionsActions =
    GetAzureAdMandantOptionsAction
    | GetAzureAdMandantOptionsAusfuehrenAction
    | GetAzureAdMandantOptionsErfolgreichAction
    | GetAzureAdMandantOptionsFehlerAction
    | GetAzureAdMandantOptionsNichtAusgefuehrtAction
    ;
