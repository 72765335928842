import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'sopi-status-bubble',
    templateUrl: './sopi-status-bubble.component.html',
    styleUrls: ['./sopi-status-bubble.component.scss'],
})
export class SopiStatusBubbleComponent implements OnInit {

    // VALUES
    @Input() mainValue: string | number = null;
    @Input() size: string = null;
    @Input() iconIstOffen = false;
    @Input() iconIstFreigegeben = false;
    @Input() iconIstOk = false;
    @Input() iconIstNichtOk = false;
    @Input() iconIstInBearbeitung = false;

    constructor() {
    }

    ngOnInit() {
    }

}
