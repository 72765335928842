import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const POST_MITTEILUNG_LOESCHEN = '[KommunikationModul] PostMitteilungLoeschen laden';
export const POST_MITTEILUNG_LOESCHEN_AUSFUEHREN = '[KommunikationModul] PostMitteilungLoeschen ausführen';
export const POST_MITTEILUNG_LOESCHEN_ERFOLGREICH = '[KommunikationModul] PostMitteilungLoeschen laden erfolgreich';
export const POST_MITTEILUNG_LOESCHEN_FEHLER = '[KommunikationModul] PostMitteilungLoeschen Ladefehler';
export const POST_MITTEILUNG_LOESCHEN_NICHT_AUSGEFUEHRT = '[KommunikationModul] PostMitteilungLoeschen nicht ausgeführt (evtl. Offline)';



export class PostMitteilungLoeschenAction implements Action {
    readonly type = POST_MITTEILUNG_LOESCHEN;
        constructor(public mitteilungLoeschenRequest: api.MitteilungLoeschenRequest, public optPayload: any = null) {}
    }
export class PostMitteilungLoeschenAusfuehrenAction implements Action {
    readonly type = POST_MITTEILUNG_LOESCHEN_AUSFUEHREN;
        constructor(public mitteilungLoeschenRequest: api.MitteilungLoeschenRequest, public optPayload: any = null) {}
    }
export class PostMitteilungLoeschenErfolgreichAction implements Action {
    readonly type = POST_MITTEILUNG_LOESCHEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public mitteilungLoeschenRequest: api.MitteilungLoeschenRequest, public optPayload: any = null) {}
    }
export class PostMitteilungLoeschenFehlerAction implements Action {
    readonly type = POST_MITTEILUNG_LOESCHEN_FEHLER;
        constructor(public payload: any, public mitteilungLoeschenRequest: api.MitteilungLoeschenRequest, public optPayload: any = null) {}
    }
export class PostMitteilungLoeschenNichtAusgefuehrtAction implements Action {
    readonly type = POST_MITTEILUNG_LOESCHEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationCommandMitteilungLoeschenActions =
    PostMitteilungLoeschenAction
    | PostMitteilungLoeschenAusfuehrenAction
    | PostMitteilungLoeschenErfolgreichAction
    | PostMitteilungLoeschenFehlerAction
    | PostMitteilungLoeschenNichtAusgefuehrtAction
    ;
