export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '0.5044.1001',
    name: 'orga-app-desktop-client',
    versionDate: '2025-02-13T16:43:09.374Z',
    description: 'ORGArena ORGA App',
    gitCommitHash: 'g6ee139b0',
    gitCommitDate: '2025-02-13T16:36:13.000Z',
    versionLong: '0.5044.1001-g6ee139b0',
    gitTag: 'v1.48.1',
};
export default versions;
