import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_MELDUNG_ENTFERNEN = '[IncidentsModul] PostIncidentMeldungEntfernen laden';
export const POST_INCIDENT_MELDUNG_ENTFERNEN_AUSFUEHREN = '[IncidentsModul] PostIncidentMeldungEntfernen ausführen';
export const POST_INCIDENT_MELDUNG_ENTFERNEN_ERFOLGREICH = '[IncidentsModul] PostIncidentMeldungEntfernen laden erfolgreich';
export const POST_INCIDENT_MELDUNG_ENTFERNEN_FEHLER = '[IncidentsModul] PostIncidentMeldungEntfernen Ladefehler';
export const POST_INCIDENT_MELDUNG_ENTFERNEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentMeldungEntfernen nicht ausgeführt (evtl. Offline)';



export class PostIncidentMeldungEntfernenAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_ENTFERNEN;
        constructor(public incidentMeldungRequest: api.IncidentMeldungRequest, public optPayload: any = null) {}
    }
export class PostIncidentMeldungEntfernenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_ENTFERNEN_AUSFUEHREN;
        constructor(public incidentMeldungRequest: api.IncidentMeldungRequest, public optPayload: any = null) {}
    }
export class PostIncidentMeldungEntfernenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentMeldungRequest: api.IncidentMeldungRequest, public optPayload: any = null) {}
    }
export class PostIncidentMeldungEntfernenFehlerAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_ENTFERNEN_FEHLER;
        constructor(public payload: any, public incidentMeldungRequest: api.IncidentMeldungRequest, public optPayload: any = null) {}
    }
export class PostIncidentMeldungEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentMeldungEntfernenActions =
    PostIncidentMeldungEntfernenAction
    | PostIncidentMeldungEntfernenAusfuehrenAction
    | PostIncidentMeldungEntfernenErfolgreichAction
    | PostIncidentMeldungEntfernenFehlerAction
    | PostIncidentMeldungEntfernenNichtAusgefuehrtAction
    ;
