import {Observable} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {WS_BENUTZER_ZUGRIFF_UPDATED, WsBenutzerZugriffUpdatedAction} from '../websocket.actions';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {NaviBenutzerZugriffeLadenAction} from './navi.actions';

@Injectable()
export class NaviWsEffects {

    @Effect()
    wsUpdateListen$: Observable<Action> = this.actions$.pipe(
        ofType<WsBenutzerZugriffUpdatedAction>(WS_BENUTZER_ZUGRIFF_UPDATED)
        , map((x) => {
            return new NaviBenutzerZugriffeLadenAction();
        })
    );


    constructor(
        private actions$: Actions,
        private store: Store<any>,
    ) {
    }
}
