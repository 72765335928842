import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const POST_TEST_MAIL_SENDEN1 = '[KommunikationModul] PostTestMailSenden1 laden';
export const POST_TEST_MAIL_SENDEN1_AUSFUEHREN = '[KommunikationModul] PostTestMailSenden1 ausführen';
export const POST_TEST_MAIL_SENDEN1_ERFOLGREICH = '[KommunikationModul] PostTestMailSenden1 laden erfolgreich';
export const POST_TEST_MAIL_SENDEN1_FEHLER = '[KommunikationModul] PostTestMailSenden1 Ladefehler';
export const POST_TEST_MAIL_SENDEN1_NICHT_AUSGEFUEHRT = '[KommunikationModul] PostTestMailSenden1 nicht ausgeführt (evtl. Offline)';



export class PostTestMailSenden1Action implements Action {
    readonly type = POST_TEST_MAIL_SENDEN1;
        constructor(public testMailRequest: api.TestMailRequest, public optPayload: any = null) {}
    }
export class PostTestMailSenden1AusfuehrenAction implements Action {
    readonly type = POST_TEST_MAIL_SENDEN1_AUSFUEHREN;
        constructor(public testMailRequest: api.TestMailRequest, public optPayload: any = null) {}
    }
export class PostTestMailSenden1ErfolgreichAction implements Action {
    readonly type = POST_TEST_MAIL_SENDEN1_ERFOLGREICH;
        constructor(public testMailRequest: api.TestMailRequest, public optPayload: any = null) {}
    }
export class PostTestMailSenden1FehlerAction implements Action {
    readonly type = POST_TEST_MAIL_SENDEN1_FEHLER;
        constructor(public payload: any, public testMailRequest: api.TestMailRequest, public optPayload: any = null) {}
    }
export class PostTestMailSenden1NichtAusgefuehrtAction implements Action {
    readonly type = POST_TEST_MAIL_SENDEN1_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationCommandTestMailSenden1Actions =
    PostTestMailSenden1Action
    | PostTestMailSenden1AusfuehrenAction
    | PostTestMailSenden1ErfolgreichAction
    | PostTestMailSenden1FehlerAction
    | PostTestMailSenden1NichtAusgefuehrtAction
    ;
