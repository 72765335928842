import {Action} from '@ngrx/store';
import {Abteilung, Rolle} from '../../../clients/incidents';

export const PAGE_STATE_RESET = '[IncidentPage] Page State reset';
export const IGNORE_WS_ACTION = '[IncidentPage] WS Action ignoriert';

export const SETZE_INCIDENT_DETAIL_ID = '[IncidentPage] Setze Incident Detail Id';

export const SETZE_INCIDENT_TITEL = '[IncidentPage] Setze Incident Titel';
export const SETZE_INCIDENT_BESCHREIBUNG = '[IncidentPage] Setze Incident Beschreibung';
export const SETZE_INCIDENT_ORT = '[IncidentPage] Setze Incident Abteilung Ort';

export const SETZE_INCIDENT_ABTEILUNG = '[IncidentPage] Setze Incident Abteilung Verantwortlichkeit';
export const SETZE_INCIDENT_ZENTRALE = '[IncidentPage] Setze Incident Zentrale Verantwortlichkeit';

export const SETZE_INCIDENT_DELEGATION_ROLLE = '[IncidentPage] Setze Incident Delegation Rolle';

export const INCIDENT_SPEICHERN = '[IncidentPage] Incident speichern';


// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;
}

// IGNORE WS ACTION
export class IgnoreWSAction implements Action {
    readonly type = IGNORE_WS_ACTION;

    constructor(public payload: any) {
    }
}

export class SetzeIncidentDetailIdAction implements Action {
    readonly type = SETZE_INCIDENT_DETAIL_ID;

    constructor(public payload: string) {
    }
}

export class SetzeIncidentTitelAction implements Action {
    readonly type = SETZE_INCIDENT_TITEL;

    constructor(public payload: string) {
    }
}

export class SetzeIncidentBeschreibungAction implements Action {
    readonly type = SETZE_INCIDENT_BESCHREIBUNG;

    constructor(public payload: string) {
    }
}

export class SetzeIncidentOrtAction implements Action {
    readonly type = SETZE_INCIDENT_ORT;

    constructor(public payload: string) {
    }
}

export class SetzeIncidentAbteilungAction implements Action {
    readonly type = SETZE_INCIDENT_ABTEILUNG;

    constructor(public payload: Abteilung) {
    }
}

export class SetzeIncidentZentraleAction implements Action {
    readonly type = SETZE_INCIDENT_ZENTRALE;

    constructor() {
    }
}

export class SetzeIncidentDelegationRolleAction implements Action {
    readonly type = SETZE_INCIDENT_DELEGATION_ROLLE;

    constructor(public payload: Rolle) {
    }

}

export class IncidentSpeichernAction implements Action {
    readonly type = INCIDENT_SPEICHERN;

    constructor() {
    }
}


export type Actions =
    | PageStateResetAction
    | IgnoreWSAction
    | SetzeIncidentDetailIdAction
    | SetzeIncidentTitelAction
    | SetzeIncidentBeschreibungAction
    | SetzeIncidentOrtAction
    | SetzeIncidentAbteilungAction
    | SetzeIncidentZentraleAction
    | SetzeIncidentDelegationRolleAction
    | IncidentSpeichernAction
;
