import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFLAUF_BILD_HINZUFUEGEN = '[QschecksModul] PostPrueflaufBildHinzufuegen laden';
export const POST_PRUEFLAUF_BILD_HINZUFUEGEN_AUSFUEHREN = '[QschecksModul] PostPrueflaufBildHinzufuegen ausführen';
export const POST_PRUEFLAUF_BILD_HINZUFUEGEN_ERFOLGREICH = '[QschecksModul] PostPrueflaufBildHinzufuegen laden erfolgreich';
export const POST_PRUEFLAUF_BILD_HINZUFUEGEN_FEHLER = '[QschecksModul] PostPrueflaufBildHinzufuegen Ladefehler';
export const POST_PRUEFLAUF_BILD_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPrueflaufBildHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostPrueflaufBildHinzufuegenAction implements Action {
    readonly type = POST_PRUEFLAUF_BILD_HINZUFUEGEN;
        constructor(public prueflaufBildRequest: api.PrueflaufBildRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufBildHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFLAUF_BILD_HINZUFUEGEN_AUSFUEHREN;
        constructor(public prueflaufBildRequest: api.PrueflaufBildRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufBildHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_PRUEFLAUF_BILD_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public prueflaufBildRequest: api.PrueflaufBildRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufBildHinzufuegenFehlerAction implements Action {
    readonly type = POST_PRUEFLAUF_BILD_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public prueflaufBildRequest: api.PrueflaufBildRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufBildHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFLAUF_BILD_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPrueflaufBildHinzufuegenActions =
    PostPrueflaufBildHinzufuegenAction
    | PostPrueflaufBildHinzufuegenAusfuehrenAction
    | PostPrueflaufBildHinzufuegenErfolgreichAction
    | PostPrueflaufBildHinzufuegenFehlerAction
    | PostPrueflaufBildHinzufuegenNichtAusgefuehrtAction
    ;
