import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit
} from '@angular/core';
import {expandCollapsiblePanelAnimation} from '../core/animations';

@Component({
    selector: 'sopi-infobox',
    templateUrl: './sopi-infobox.component.html',
    styleUrls: ['./sopi-infobox.component.scss'],
    animations: [
        expandCollapsiblePanelAnimation
    ]
})
export class SopiInfoboxComponent implements OnInit, AfterViewInit {

    // FUNCTIONS
    @Input() mode = 'status';
    @Input() enableAutoShrink = true;

    // DESIGN
    @Input() borderRadiusTopRight = false;
    @Input() borderRadiusTopLeft = false;
    @Input() borderRadiusBottomRight = false;
    @Input() borderRadiusBottomLeft = false;

    constructor() {

    }

    ngOnInit() {

    }

    ngAfterViewInit() {

    }

}
