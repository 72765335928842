import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_TROUBLE_TICKETS_GESENDETE = '[KommunikationModul] GetTroubleTicketsGesendete laden';
export const GET_TROUBLE_TICKETS_GESENDETE_AUSFUEHREN = '[KommunikationModul] GetTroubleTicketsGesendete ausführen';
export const GET_TROUBLE_TICKETS_GESENDETE_ERFOLGREICH = '[KommunikationModul] GetTroubleTicketsGesendete laden erfolgreich';
export const GET_TROUBLE_TICKETS_GESENDETE_FEHLER = '[KommunikationModul] GetTroubleTicketsGesendete Ladefehler';
export const GET_TROUBLE_TICKETS_GESENDETE_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetTroubleTicketsGesendete nicht ausgeführt (evtl. Offline)';



export class GetTroubleTicketsGesendeteAction implements Action {
    readonly type = GET_TROUBLE_TICKETS_GESENDETE;
        constructor(public optPayload: any = null) {}
    }
export class GetTroubleTicketsGesendeteAusfuehrenAction implements Action {
    readonly type = GET_TROUBLE_TICKETS_GESENDETE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetTroubleTicketsGesendeteErfolgreichAction implements Action {
    readonly type = GET_TROUBLE_TICKETS_GESENDETE_ERFOLGREICH;
        constructor(public payload: Array<api.TroubleTicket>, public optPayload: any = null) {}
    }
export class GetTroubleTicketsGesendeteFehlerAction implements Action {
    readonly type = GET_TROUBLE_TICKETS_GESENDETE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetTroubleTicketsGesendeteNichtAusgefuehrtAction implements Action {
    readonly type = GET_TROUBLE_TICKETS_GESENDETE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryTroubleTicketsGesendeteActions =
    GetTroubleTicketsGesendeteAction
    | GetTroubleTicketsGesendeteAusfuehrenAction
    | GetTroubleTicketsGesendeteErfolgreichAction
    | GetTroubleTicketsGesendeteFehlerAction
    | GetTroubleTicketsGesendeteNichtAusgefuehrtAction
    ;
