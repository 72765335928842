import {Injectable, Injector} from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { LoadingController } from '@ionic/angular';
import {filter, flatMap, map, withLatestFrom} from 'rxjs/operators';
import {
    GetMandantClientConfigAction
} from '../../client-ngrx/api';
import { MANDANT_SETZEN, MandantSetzenAction } from './actions';
import { START_APP_ACTION, StartAppAction } from '@orga-app/appstart/actions';
import { select, Store } from '@ngrx/store';
import { MandantInfo } from '@orga-app/clients/api';
import { getMandant } from './reducer';
import {AuthService} from '@orga-app/auth';
import {GetZeitpunktTexteAction} from '../../client-ngrx/vorlagen';


@Injectable()
export class MandantEffects {
    public loading = null;

    @Effect()
    startAppAction$ = this.actions$.pipe(
        ofType<StartAppAction>(START_APP_ACTION),
        filter(() => this.getAuthService().hasValidToken()),
        withLatestFrom(this.store.pipe(select(getMandant))),
        filter(([x, mandant]: [StartAppAction, MandantInfo]) => mandant !== null),
        map(([x, mandant]: [StartAppAction, MandantInfo]) => {
            return new MandantSetzenAction(mandant);
        }
        )
    );

    @Effect()
    mandantSetzenAction$ = this.actions$.pipe(
        ofType<MandantSetzenAction>(MANDANT_SETZEN),
        withLatestFrom(this.store.pipe(select(getMandant))),
        filter(([x, mandant]: [MandantSetzenAction, MandantInfo]) => mandant !== null),
        flatMap(([x, mandant]: [MandantSetzenAction, MandantInfo]) => {
            return [
                new GetMandantClientConfigAction(),
                new GetZeitpunktTexteAction()
            ];
        }
        )
    );
    private _authService: AuthService = null;

    constructor(private actions$: Actions,
        public loadingCtrl: LoadingController,
        public store: Store<any>,
                private injector: Injector) {
    }

    private getAuthService(): AuthService {
        if (this._authService === null) {
            this._authService = this.injector.get(AuthService);
        }
        return this._authService;
    }
}
