import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_ROLLE_ZENTRALE_SETZEN = '[IncidentsModul] PostRolleZentraleSetzen laden';
export const POST_ROLLE_ZENTRALE_SETZEN_AUSFUEHREN = '[IncidentsModul] PostRolleZentraleSetzen ausführen';
export const POST_ROLLE_ZENTRALE_SETZEN_ERFOLGREICH = '[IncidentsModul] PostRolleZentraleSetzen laden erfolgreich';
export const POST_ROLLE_ZENTRALE_SETZEN_FEHLER = '[IncidentsModul] PostRolleZentraleSetzen Ladefehler';
export const POST_ROLLE_ZENTRALE_SETZEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostRolleZentraleSetzen nicht ausgeführt (evtl. Offline)';



export class PostRolleZentraleSetzenAction implements Action {
    readonly type = POST_ROLLE_ZENTRALE_SETZEN;
        constructor(public incidentsRolleOptionenRequest: api.IncidentsRolleOptionenRequest, public optPayload: any = null) {}
    }
export class PostRolleZentraleSetzenAusfuehrenAction implements Action {
    readonly type = POST_ROLLE_ZENTRALE_SETZEN_AUSFUEHREN;
        constructor(public incidentsRolleOptionenRequest: api.IncidentsRolleOptionenRequest, public optPayload: any = null) {}
    }
export class PostRolleZentraleSetzenErfolgreichAction implements Action {
    readonly type = POST_ROLLE_ZENTRALE_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentsRolleOptionenRequest: api.IncidentsRolleOptionenRequest, public optPayload: any = null) {}
    }
export class PostRolleZentraleSetzenFehlerAction implements Action {
    readonly type = POST_ROLLE_ZENTRALE_SETZEN_FEHLER;
        constructor(public payload: any, public incidentsRolleOptionenRequest: api.IncidentsRolleOptionenRequest, public optPayload: any = null) {}
    }
export class PostRolleZentraleSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_ROLLE_ZENTRALE_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandRolleZentraleSetzenActions =
    PostRolleZentraleSetzenAction
    | PostRolleZentraleSetzenAusfuehrenAction
    | PostRolleZentraleSetzenErfolgreichAction
    | PostRolleZentraleSetzenFehlerAction
    | PostRolleZentraleSetzenNichtAusgefuehrtAction
    ;
