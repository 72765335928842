import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_IDENTIFIER = '[VeranstaltungenModul] GetVeranstaltungIdentifier laden';
export const GET_VERANSTALTUNG_IDENTIFIER_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungIdentifier ausführen';
export const GET_VERANSTALTUNG_IDENTIFIER_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungIdentifier laden erfolgreich';
export const GET_VERANSTALTUNG_IDENTIFIER_FEHLER = '[VeranstaltungenModul] GetVeranstaltungIdentifier Ladefehler';
export const GET_VERANSTALTUNG_IDENTIFIER_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungIdentifier nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungIdentifierAction implements Action {
    readonly type = GET_VERANSTALTUNG_IDENTIFIER;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungIdentifierAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_IDENTIFIER_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungIdentifierErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_IDENTIFIER_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungIdentifier, public optPayload: any = null) {}
    }
export class GetVeranstaltungIdentifierFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_IDENTIFIER_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungIdentifierNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_IDENTIFIER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungIdentifierActions =
    GetVeranstaltungIdentifierAction
    | GetVeranstaltungIdentifierAusfuehrenAction
    | GetVeranstaltungIdentifierErfolgreichAction
    | GetVeranstaltungIdentifierFehlerAction
    | GetVeranstaltungIdentifierNichtAusgefuehrtAction
    ;
