import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_UEBERSICHT = '[QschecksModul] GetVeranstaltungUebersicht laden';
export const GET_VERANSTALTUNG_UEBERSICHT_AUSFUEHREN = '[QschecksModul] GetVeranstaltungUebersicht ausführen';
export const GET_VERANSTALTUNG_UEBERSICHT_ERFOLGREICH = '[QschecksModul] GetVeranstaltungUebersicht laden erfolgreich';
export const GET_VERANSTALTUNG_UEBERSICHT_FEHLER = '[QschecksModul] GetVeranstaltungUebersicht Ladefehler';
export const GET_VERANSTALTUNG_UEBERSICHT_NICHT_AUSGEFUEHRT = '[QschecksModul] GetVeranstaltungUebersicht nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungUebersichtAction implements Action {
    readonly type = GET_VERANSTALTUNG_UEBERSICHT;
        constructor(public veranstaltungUebersichtRequest: api.VeranstaltungUebersichtRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungUebersichtAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_UEBERSICHT_AUSFUEHREN;
        constructor(public veranstaltungUebersichtRequest: api.VeranstaltungUebersichtRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungUebersichtErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_UEBERSICHT_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungUebersicht, public veranstaltungUebersichtRequest: api.VeranstaltungUebersichtRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungUebersichtFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_UEBERSICHT_FEHLER;
        constructor(public payload: any, public veranstaltungUebersichtRequest: api.VeranstaltungUebersichtRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungUebersichtNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_UEBERSICHT_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandVeranstaltungUebersichtActions =
    GetVeranstaltungUebersichtAction
    | GetVeranstaltungUebersichtAusfuehrenAction
    | GetVeranstaltungUebersichtErfolgreichAction
    | GetVeranstaltungUebersichtFehlerAction
    | GetVeranstaltungUebersichtNichtAusgefuehrtAction
    ;
