import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_AKTUALISIEREN2 = '[IncidentsModul] PostIncidentAktualisieren2 laden';
export const POST_INCIDENT_AKTUALISIEREN2_AUSFUEHREN = '[IncidentsModul] PostIncidentAktualisieren2 ausführen';
export const POST_INCIDENT_AKTUALISIEREN2_ERFOLGREICH = '[IncidentsModul] PostIncidentAktualisieren2 laden erfolgreich';
export const POST_INCIDENT_AKTUALISIEREN2_FEHLER = '[IncidentsModul] PostIncidentAktualisieren2 Ladefehler';
export const POST_INCIDENT_AKTUALISIEREN2_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentAktualisieren2 nicht ausgeführt (evtl. Offline)';



export class PostIncidentAktualisieren2Action implements Action {
    readonly type = POST_INCIDENT_AKTUALISIEREN2;
        constructor(public incidentAktualisieren2Request: api.IncidentAktualisieren2Request, public optPayload: any = null) {}
    }
export class PostIncidentAktualisieren2AusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_AKTUALISIEREN2_AUSFUEHREN;
        constructor(public incidentAktualisieren2Request: api.IncidentAktualisieren2Request, public optPayload: any = null) {}
    }
export class PostIncidentAktualisieren2ErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_AKTUALISIEREN2_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentAktualisieren2Request: api.IncidentAktualisieren2Request, public optPayload: any = null) {}
    }
export class PostIncidentAktualisieren2FehlerAction implements Action {
    readonly type = POST_INCIDENT_AKTUALISIEREN2_FEHLER;
        constructor(public payload: any, public incidentAktualisieren2Request: api.IncidentAktualisieren2Request, public optPayload: any = null) {}
    }
export class PostIncidentAktualisieren2NichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_AKTUALISIEREN2_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentAktualisieren2Actions =
    PostIncidentAktualisieren2Action
    | PostIncidentAktualisieren2AusfuehrenAction
    | PostIncidentAktualisieren2ErfolgreichAction
    | PostIncidentAktualisieren2FehlerAction
    | PostIncidentAktualisieren2NichtAusgefuehrtAction
    ;
