import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFPLAN_PRUEFCHECKLISTEN_ADMIN = '[QschecksModul] GetPruefplanPruefchecklistenAdmin laden';
export const GET_PRUEFPLAN_PRUEFCHECKLISTEN_ADMIN_AUSFUEHREN = '[QschecksModul] GetPruefplanPruefchecklistenAdmin ausführen';
export const GET_PRUEFPLAN_PRUEFCHECKLISTEN_ADMIN_ERFOLGREICH = '[QschecksModul] GetPruefplanPruefchecklistenAdmin laden erfolgreich';
export const GET_PRUEFPLAN_PRUEFCHECKLISTEN_ADMIN_FEHLER = '[QschecksModul] GetPruefplanPruefchecklistenAdmin Ladefehler';
export const GET_PRUEFPLAN_PRUEFCHECKLISTEN_ADMIN_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPruefplanPruefchecklistenAdmin nicht ausgeführt (evtl. Offline)';



export class GetPruefplanPruefchecklistenAdminAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFCHECKLISTEN_ADMIN;
        constructor( public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefchecklistenAdminAusfuehrenAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFCHECKLISTEN_ADMIN_AUSFUEHREN;
        constructor( public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefchecklistenAdminErfolgreichAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFCHECKLISTEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.Pruefcheckliste>,  public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefchecklistenAdminFehlerAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFCHECKLISTEN_ADMIN_FEHLER;
        constructor(public payload: any,  public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefchecklistenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFCHECKLISTEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPruefplanPruefchecklistenAdminActions =
    GetPruefplanPruefchecklistenAdminAction
    | GetPruefplanPruefchecklistenAdminAusfuehrenAction
    | GetPruefplanPruefchecklistenAdminErfolgreichAction
    | GetPruefplanPruefchecklistenAdminFehlerAction
    | GetPruefplanPruefchecklistenAdminNichtAusgefuehrtAction
    ;
