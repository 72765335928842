import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_CHECKLISTE_ABARBEITER_ROLLEN_ADMIN = '[VeranstaltungenModul] GetChecklisteAbarbeiterRollenAdmin laden';
export const GET_CHECKLISTE_ABARBEITER_ROLLEN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetChecklisteAbarbeiterRollenAdmin ausführen';
export const GET_CHECKLISTE_ABARBEITER_ROLLEN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetChecklisteAbarbeiterRollenAdmin laden erfolgreich';
export const GET_CHECKLISTE_ABARBEITER_ROLLEN_ADMIN_FEHLER = '[VeranstaltungenModul] GetChecklisteAbarbeiterRollenAdmin Ladefehler';
export const GET_CHECKLISTE_ABARBEITER_ROLLEN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetChecklisteAbarbeiterRollenAdmin nicht ausgeführt (evtl. Offline)';



export class GetChecklisteAbarbeiterRollenAdminAction implements Action {
    readonly type = GET_CHECKLISTE_ABARBEITER_ROLLEN_ADMIN;
        constructor( public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAbarbeiterRollenAdminAusfuehrenAction implements Action {
    readonly type = GET_CHECKLISTE_ABARBEITER_ROLLEN_ADMIN_AUSFUEHREN;
        constructor( public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAbarbeiterRollenAdminErfolgreichAction implements Action {
    readonly type = GET_CHECKLISTE_ABARBEITER_ROLLEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichRolle>,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAbarbeiterRollenAdminFehlerAction implements Action {
    readonly type = GET_CHECKLISTE_ABARBEITER_ROLLEN_ADMIN_FEHLER;
        constructor(public payload: any,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAbarbeiterRollenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_CHECKLISTE_ABARBEITER_ROLLEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryChecklisteAbarbeiterRollenAdminActions =
    GetChecklisteAbarbeiterRollenAdminAction
    | GetChecklisteAbarbeiterRollenAdminAusfuehrenAction
    | GetChecklisteAbarbeiterRollenAdminErfolgreichAction
    | GetChecklisteAbarbeiterRollenAdminFehlerAction
    | GetChecklisteAbarbeiterRollenAdminNichtAusgefuehrtAction
    ;
