import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_ROLLE_BENUTZER_TOTAL = '[BenutzerverwaltungModul] GetRolleBenutzerTotal laden';
export const GET_ROLLE_BENUTZER_TOTAL_AUSFUEHREN = '[BenutzerverwaltungModul] GetRolleBenutzerTotal ausführen';
export const GET_ROLLE_BENUTZER_TOTAL_ERFOLGREICH = '[BenutzerverwaltungModul] GetRolleBenutzerTotal laden erfolgreich';
export const GET_ROLLE_BENUTZER_TOTAL_FEHLER = '[BenutzerverwaltungModul] GetRolleBenutzerTotal Ladefehler';
export const GET_ROLLE_BENUTZER_TOTAL_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetRolleBenutzerTotal nicht ausgeführt (evtl. Offline)';



export class GetRolleBenutzerTotalAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_TOTAL;
        constructor(public optPayload: any = null) {}
    }
export class GetRolleBenutzerTotalAusfuehrenAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_TOTAL_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetRolleBenutzerTotalErfolgreichAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_TOTAL_ERFOLGREICH;
        constructor(public payload: api.RolleBenutzerTotal, public optPayload: any = null) {}
    }
export class GetRolleBenutzerTotalFehlerAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_TOTAL_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetRolleBenutzerTotalNichtAusgefuehrtAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_TOTAL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryRolleBenutzerTotalActions =
    GetRolleBenutzerTotalAction
    | GetRolleBenutzerTotalAusfuehrenAction
    | GetRolleBenutzerTotalErfolgreichAction
    | GetRolleBenutzerTotalFehlerAction
    | GetRolleBenutzerTotalNichtAusgefuehrtAction
    ;
