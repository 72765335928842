import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {PAGE_STATE_RESET, PageStateResetAction} from './aufgaben-berechtigungen-modal.actions.ui';
import {GetBenutzerListeAction} from '../../../../client-ngrx/benutzerverwaltung';
import {GetVeranstaltungBenutzerListeAdminAction} from '../../../../client-ngrx/veranstaltungen';


@Injectable()
export class AufgabenBerechtigungenModalPageUiEffects {
    datenLaden$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<PageStateResetAction>(PAGE_STATE_RESET),
        map((x) => {
            if (x.veranstaltungsId === null) {
                return new GetBenutzerListeAction();
            } else {
                return new GetVeranstaltungBenutzerListeAdminAction(x.veranstaltungsId);
            }
        })));

    constructor(private actions$: Actions,
                private store$: Store<any>,
                public route: ActivatedRoute,
                public router: Router) {
    }
}
