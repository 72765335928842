import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {PageState} from '../checklisten/checklisten.reducer';
import {NotificationService} from '@orga-app/providers/notification.service';
import {Observable} from 'rxjs';
import {WS_READ_MODEL_UPDATED, WsReadModelUpdatedAction} from '../../websocket.actions';
import {filter, map, withLatestFrom} from 'rxjs/operators';
import {GetBereichDetailAdminAction, GetBereicheListeAdminAction} from '../../../client-ngrx/displaysteuerung';
import {DisplaysteuerungEntityTypes} from '../../../client-constants';
import BEREICH_ENTITY = DisplaysteuerungEntityTypes.BEREICH_ENTITY;
import {BereichListItem} from '../../../clients/displaysteuerung';
import {getSelectedBereich} from './displaysteuerung.selectors';

@Injectable()
export class DisplaysteuerungEffectsWs {

    @Effect()
    readModelUpdaterBereiche$: Observable<Action> = this.actions$.pipe(
        ofType<WsReadModelUpdatedAction>(WS_READ_MODEL_UPDATED)
        , filter((x: WsReadModelUpdatedAction) => {
            return x.message.entity_type.toUpperCase() === BEREICH_ENTITY;
        })
        , map((x) => {
            return new GetBereicheListeAdminAction();
        })
    );

    @Effect()
    readModelUpdaterBereich$: Observable<Action> = this.actions$.pipe(
        ofType<WsReadModelUpdatedAction>(WS_READ_MODEL_UPDATED)
        , filter((x: WsReadModelUpdatedAction) => {
            return x.message.entity_type.toUpperCase() === BEREICH_ENTITY;
        })
        , map((x: WsReadModelUpdatedAction) => {
            return <BereichListItem> JSON.parse(x.message.entity);
        })
        , withLatestFrom(this.store.pipe(select(getSelectedBereich)))
        , filter(([x, bereich]) => bereich !== null && x !== null && x.bereich_id === bereich.bereich_id)
        , map(([x, bereich]) => {
            return new GetBereichDetailAdminAction(x.bereich_id);
        })
    );


    constructor(private actions$: Actions,
                private store: Store<PageState>,
                private notificationService: NotificationService) {
    }
}
