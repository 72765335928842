import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_ROLLE_DETAIL = '[BenutzerverwaltungModul] GetRolleDetail laden';
export const GET_ROLLE_DETAIL_AUSFUEHREN = '[BenutzerverwaltungModul] GetRolleDetail ausführen';
export const GET_ROLLE_DETAIL_ERFOLGREICH = '[BenutzerverwaltungModul] GetRolleDetail laden erfolgreich';
export const GET_ROLLE_DETAIL_FEHLER = '[BenutzerverwaltungModul] GetRolleDetail Ladefehler';
export const GET_ROLLE_DETAIL_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetRolleDetail nicht ausgeführt (evtl. Offline)';



export class GetRolleDetailAction implements Action {
    readonly type = GET_ROLLE_DETAIL;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleDetailAusfuehrenAction implements Action {
    readonly type = GET_ROLLE_DETAIL_AUSFUEHREN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleDetailErfolgreichAction implements Action {
    readonly type = GET_ROLLE_DETAIL_ERFOLGREICH;
        constructor(public payload: api.RolleDetail,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleDetailFehlerAction implements Action {
    readonly type = GET_ROLLE_DETAIL_FEHLER;
        constructor(public payload: any,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleDetailNichtAusgefuehrtAction implements Action {
    readonly type = GET_ROLLE_DETAIL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryRolleDetailActions =
    GetRolleDetailAction
    | GetRolleDetailAusfuehrenAction
    | GetRolleDetailErfolgreichAction
    | GetRolleDetailFehlerAction
    | GetRolleDetailNichtAusgefuehrtAction
    ;
