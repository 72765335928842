import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ADMIN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheAdmin laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheAdmin ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheAdmin Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereicheAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ADMIN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.Funktionsbereich>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereicheAdminActions =
    GetVeranstaltungFunktionsbereicheAdminAction
    | GetVeranstaltungFunktionsbereicheAdminAusfuehrenAction
    | GetVeranstaltungFunktionsbereicheAdminErfolgreichAction
    | GetVeranstaltungFunktionsbereicheAdminFehlerAction
    | GetVeranstaltungFunktionsbereicheAdminNichtAusgefuehrtAction
    ;
