import {Action, ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import {Aufgabe, Fehler} from '../../../clients/veranstaltungen';
import {
    GET_AUFGABE_FEHLER_ERFOLGREICH,
    GetAufgabeFehlerErfolgreichAction,
    POST_FEHLERBESCHREIBUNG_HINZUFUEGEN, POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_ERFOLGREICH, POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_FEHLER,
    PostFehlerbeschreibungHinzufuegenAction,
    PostFehlerbeschreibungHinzufuegenErfolgreichAction, PostFehlerbeschreibungHinzufuegenFehlerAction
} from '../../../client-ngrx/veranstaltungen';
import {
    AKTUELLE_AUFGABE_SETZEN,
    AktuelleAufgabeSetzenAction,
    PAGE_STATE_RESET,
    PageStateResetAction
} from './fehlerbeschreibung.actions.ui';
import { APP_RESET_ACTION } from '@orga-app/appstart/actions';


export interface ModuleState {
    page: PageState;
}

export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer
};

export interface PageState {
    aktuelleAufgabe: Aufgabe;
    fehler: Fehler;
    fehlerbeschreibungGemeldet: boolean;
    uiZeigeFehlerbeschreibungMeldenLaden: boolean;
    uiZeigeFehlerbeschreibungMeldenLadefehler: boolean;
}

export const initialPageState: PageState = {
    aktuelleAufgabe: null,
    fehler: null,
    fehlerbeschreibungGemeldet: false,
    uiZeigeFehlerbeschreibungMeldenLaden: false,
    uiZeigeFehlerbeschreibungMeldenLadefehler: false,
};

export function pageReducer(state = initialPageState, action: Action): PageState {
    switch (action.type) {
        // APP RESET
        case APP_RESET_ACTION: {
            return Object.assign({}, state, <PageState> initialPageState);
        }

        // Page State Reset
        case PAGE_STATE_RESET: {
            const a = <PageStateResetAction> action;

            return Object.assign({}, state, <PageState> initialPageState);
        }

        /* OK MELDEN */
        case POST_FEHLERBESCHREIBUNG_HINZUFUEGEN: {
            const a = <PostFehlerbeschreibungHinzufuegenAction> action;

            return Object.assign({}, state, <PageState> {
                fehlerbeschreibungGemeldet: false,
                uiZeigeFehlerbeschreibungMeldenLaden: true,
                uiZeigeFehlerbeschreibungMeldenLadefehler: false
            });
        }

        case POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_ERFOLGREICH: {
            const a = <PostFehlerbeschreibungHinzufuegenErfolgreichAction> action;

            return Object.assign({}, state, <PageState> {
                fehlerbeschreibungGemeldet: true,
                uiZeigeFehlerbeschreibungMeldenLaden: false,
                uiZeigeFehlerbeschreibungMeldenLadefehler: false
            });
        }

        case POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_FEHLER: {
            const a = <PostFehlerbeschreibungHinzufuegenFehlerAction> action;

            return Object.assign({}, state, <PageState> {
                fehlerbeschreibungGemeldet: false,
                uiZeigeFehlerbeschreibungMeldenLaden: false,
                uiZeigeFehlerbeschreibungMeldenLadefehler: true
            });
        }


        case GET_AUFGABE_FEHLER_ERFOLGREICH: {
            const a = <GetAufgabeFehlerErfolgreichAction> action;

            return Object.assign({}, state, <PageState> {
                fehler: a.payload
            });
        }

        case AKTUELLE_AUFGABE_SETZEN: {
            const a = <AktuelleAufgabeSetzenAction> action;

            return Object.assign({}, state, <PageState> {
                aktuelleAufgabe: a.aufgabe
            });
        }

        default: {
            return state;
        }
    }
}


/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const getModuleState = createFeatureSelector<ModuleState>('FehlerbeschreibungPage');

export const getPageState = createSelector(getModuleState, (state: ModuleState) => state.page);

// DATA
export const getAktuelleAufgabe = createSelector(getPageState, (state: PageState) => state.aktuelleAufgabe);
export const getFehler = createSelector(getPageState, (state: PageState) => state.fehler);
export const getFehlerbeschreibungGemeldet = createSelector(getPageState, (state: PageState) => state.fehlerbeschreibungGemeldet);
export const getUiZeigeFehlerbeschreibungMeldenLaden = createSelector(getPageState, (state: PageState) => state.uiZeigeFehlerbeschreibungMeldenLaden);
export const getUiZeigeFehlerbeschreibungMeldenLadefehler = createSelector(getPageState, (state: PageState) => state.uiZeigeFehlerbeschreibungMeldenLadefehler);

