import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_MITTEILUNGS_VORLAGEN = '[VeranstaltungenModul] GetVeranstaltungMitteilungsVorlagen laden';
export const GET_VERANSTALTUNG_MITTEILUNGS_VORLAGEN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungMitteilungsVorlagen ausführen';
export const GET_VERANSTALTUNG_MITTEILUNGS_VORLAGEN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungMitteilungsVorlagen laden erfolgreich';
export const GET_VERANSTALTUNG_MITTEILUNGS_VORLAGEN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungMitteilungsVorlagen Ladefehler';
export const GET_VERANSTALTUNG_MITTEILUNGS_VORLAGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungMitteilungsVorlagen nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungMitteilungsVorlagenAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNGS_VORLAGEN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungMitteilungsVorlagenAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNGS_VORLAGEN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungMitteilungsVorlagenErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNGS_VORLAGEN_ERFOLGREICH;
        constructor(public payload: Array<api.VeranstaltungMitteilungsVorlage>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungMitteilungsVorlagenFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNGS_VORLAGEN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungMitteilungsVorlagenNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNGS_VORLAGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungMitteilungsVorlagenActions =
    GetVeranstaltungMitteilungsVorlagenAction
    | GetVeranstaltungMitteilungsVorlagenAusfuehrenAction
    | GetVeranstaltungMitteilungsVorlagenErfolgreichAction
    | GetVeranstaltungMitteilungsVorlagenFehlerAction
    | GetVeranstaltungMitteilungsVorlagenNichtAusgefuehrtAction
    ;
