import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FEHLERBILD_HINZUFUEGEN = '[VeranstaltungenModul] PostFehlerbildHinzufuegen laden';
export const POST_FEHLERBILD_HINZUFUEGEN_AUSFUEHREN = '[VeranstaltungenModul] PostFehlerbildHinzufuegen ausführen';
export const POST_FEHLERBILD_HINZUFUEGEN_ERFOLGREICH = '[VeranstaltungenModul] PostFehlerbildHinzufuegen laden erfolgreich';
export const POST_FEHLERBILD_HINZUFUEGEN_FEHLER = '[VeranstaltungenModul] PostFehlerbildHinzufuegen Ladefehler';
export const POST_FEHLERBILD_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFehlerbildHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostFehlerbildHinzufuegenAction implements Action {
    readonly type = POST_FEHLERBILD_HINZUFUEGEN;
        constructor(public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostFehlerbildHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_FEHLERBILD_HINZUFUEGEN_AUSFUEHREN;
        constructor(public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostFehlerbildHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_FEHLERBILD_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostFehlerbildHinzufuegenFehlerAction implements Action {
    readonly type = POST_FEHLERBILD_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostFehlerbildHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FEHLERBILD_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFehlerbildHinzufuegenActions =
    PostFehlerbildHinzufuegenAction
    | PostFehlerbildHinzufuegenAusfuehrenAction
    | PostFehlerbildHinzufuegenErfolgreichAction
    | PostFehlerbildHinzufuegenFehlerAction
    | PostFehlerbildHinzufuegenNichtAusgefuehrtAction
    ;
