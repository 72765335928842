import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FUNKPROTOKOLL_FALL_ERFASSEN = '[VeranstaltungenModul] PostFunkprotokollFallErfassen laden';
export const POST_FUNKPROTOKOLL_FALL_ERFASSEN_AUSFUEHREN = '[VeranstaltungenModul] PostFunkprotokollFallErfassen ausführen';
export const POST_FUNKPROTOKOLL_FALL_ERFASSEN_ERFOLGREICH = '[VeranstaltungenModul] PostFunkprotokollFallErfassen laden erfolgreich';
export const POST_FUNKPROTOKOLL_FALL_ERFASSEN_FEHLER = '[VeranstaltungenModul] PostFunkprotokollFallErfassen Ladefehler';
export const POST_FUNKPROTOKOLL_FALL_ERFASSEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFunkprotokollFallErfassen nicht ausgeführt (evtl. Offline)';



export class PostFunkprotokollFallErfassenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ERFASSEN;
        constructor(public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallErfassenAusfuehrenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ERFASSEN_AUSFUEHREN;
        constructor(public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallErfassenErfolgreichAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ERFASSEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallErfassenFehlerAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ERFASSEN_FEHLER;
        constructor(public payload: any, public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallErfassenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ERFASSEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFunkprotokollFallErfassenActions =
    PostFunkprotokollFallErfassenAction
    | PostFunkprotokollFallErfassenAusfuehrenAction
    | PostFunkprotokollFallErfassenErfolgreichAction
    | PostFunkprotokollFallErfassenFehlerAction
    | PostFunkprotokollFallErfassenNichtAusgefuehrtAction
    ;
