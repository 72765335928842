import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_IDENTITY_CLAIMS = '[ApiModul] GetIdentityClaims laden';
export const GET_IDENTITY_CLAIMS_AUSFUEHREN = '[ApiModul] GetIdentityClaims ausführen';
export const GET_IDENTITY_CLAIMS_ERFOLGREICH = '[ApiModul] GetIdentityClaims laden erfolgreich';
export const GET_IDENTITY_CLAIMS_FEHLER = '[ApiModul] GetIdentityClaims Ladefehler';
export const GET_IDENTITY_CLAIMS_NICHT_AUSGEFUEHRT = '[ApiModul] GetIdentityClaims nicht ausgeführt (evtl. Offline)';



export class GetIdentityClaimsAction implements Action {
    readonly type = GET_IDENTITY_CLAIMS;
        constructor(public optPayload: any = null) {}
    }
export class GetIdentityClaimsAusfuehrenAction implements Action {
    readonly type = GET_IDENTITY_CLAIMS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetIdentityClaimsErfolgreichAction implements Action {
    readonly type = GET_IDENTITY_CLAIMS_ERFOLGREICH;
        constructor(public payload: Array<api.KeyValueItem>, public optPayload: any = null) {}
    }
export class GetIdentityClaimsFehlerAction implements Action {
    readonly type = GET_IDENTITY_CLAIMS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetIdentityClaimsNichtAusgefuehrtAction implements Action {
    readonly type = GET_IDENTITY_CLAIMS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryIdentityClaimsActions =
    GetIdentityClaimsAction
    | GetIdentityClaimsAusfuehrenAction
    | GetIdentityClaimsErfolgreichAction
    | GetIdentityClaimsFehlerAction
    | GetIdentityClaimsNichtAusgefuehrtAction
    ;
