import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_FUNKPROTOKOLL_MELDEART_DETAIL = '[VeranstaltungenModul] GetFunkprotokollMeldeartDetail laden';
export const GET_FUNKPROTOKOLL_MELDEART_DETAIL_AUSFUEHREN = '[VeranstaltungenModul] GetFunkprotokollMeldeartDetail ausführen';
export const GET_FUNKPROTOKOLL_MELDEART_DETAIL_ERFOLGREICH = '[VeranstaltungenModul] GetFunkprotokollMeldeartDetail laden erfolgreich';
export const GET_FUNKPROTOKOLL_MELDEART_DETAIL_FEHLER = '[VeranstaltungenModul] GetFunkprotokollMeldeartDetail Ladefehler';
export const GET_FUNKPROTOKOLL_MELDEART_DETAIL_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunkprotokollMeldeartDetail nicht ausgeführt (evtl. Offline)';



export class GetFunkprotokollMeldeartDetailAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_MELDEART_DETAIL;
        constructor( public meldeart_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollMeldeartDetailAusfuehrenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_MELDEART_DETAIL_AUSFUEHREN;
        constructor( public meldeart_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollMeldeartDetailErfolgreichAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_MELDEART_DETAIL_ERFOLGREICH;
        constructor(public payload: api.Meldeart,  public meldeart_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollMeldeartDetailFehlerAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_MELDEART_DETAIL_FEHLER;
        constructor(public payload: any,  public meldeart_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollMeldeartDetailNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_MELDEART_DETAIL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunkprotokollMeldeartDetailActions =
    GetFunkprotokollMeldeartDetailAction
    | GetFunkprotokollMeldeartDetailAusfuehrenAction
    | GetFunkprotokollMeldeartDetailErfolgreichAction
    | GetFunkprotokollMeldeartDetailFehlerAction
    | GetFunkprotokollMeldeartDetailNichtAusgefuehrtAction
    ;
