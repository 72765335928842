import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const POST_BEREICH_TRIGGER_REIHENFOLGE_SETZEN = '[DisplaysteuerungModul] PostBereichTriggerReihenfolgeSetzen laden';
export const POST_BEREICH_TRIGGER_REIHENFOLGE_SETZEN_AUSFUEHREN = '[DisplaysteuerungModul] PostBereichTriggerReihenfolgeSetzen ausführen';
export const POST_BEREICH_TRIGGER_REIHENFOLGE_SETZEN_ERFOLGREICH = '[DisplaysteuerungModul] PostBereichTriggerReihenfolgeSetzen laden erfolgreich';
export const POST_BEREICH_TRIGGER_REIHENFOLGE_SETZEN_FEHLER = '[DisplaysteuerungModul] PostBereichTriggerReihenfolgeSetzen Ladefehler';
export const POST_BEREICH_TRIGGER_REIHENFOLGE_SETZEN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] PostBereichTriggerReihenfolgeSetzen nicht ausgeführt (evtl. Offline)';



export class PostBereichTriggerReihenfolgeSetzenAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_REIHENFOLGE_SETZEN;
        constructor(public bereichTriggerReihenfolgeRequest: api.BereichTriggerReihenfolgeRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerReihenfolgeSetzenAusfuehrenAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_REIHENFOLGE_SETZEN_AUSFUEHREN;
        constructor(public bereichTriggerReihenfolgeRequest: api.BereichTriggerReihenfolgeRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerReihenfolgeSetzenErfolgreichAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_REIHENFOLGE_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public bereichTriggerReihenfolgeRequest: api.BereichTriggerReihenfolgeRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerReihenfolgeSetzenFehlerAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_REIHENFOLGE_SETZEN_FEHLER;
        constructor(public payload: any, public bereichTriggerReihenfolgeRequest: api.BereichTriggerReihenfolgeRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerReihenfolgeSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_REIHENFOLGE_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungCommandBereichTriggerReihenfolgeSetzenActions =
    PostBereichTriggerReihenfolgeSetzenAction
    | PostBereichTriggerReihenfolgeSetzenAusfuehrenAction
    | PostBereichTriggerReihenfolgeSetzenErfolgreichAction
    | PostBereichTriggerReihenfolgeSetzenFehlerAction
    | PostBereichTriggerReihenfolgeSetzenNichtAusgefuehrtAction
    ;
