import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {tap} from 'rxjs/operators';
import {
    AUTH_LOGIN_ERFOLGREICH,
    AUTH_LOGOUT_ERFOLGREICH,
    AuthLoginErfolgreichAction,
    AuthLogoutErfolgreichAction
} from '@orga-app/auth/actions';
import {AppInsightsService} from '@markpieszak/ng-application-insights';

@Injectable()
export class AppInsightEffects {


    // USERNAME PASSWORD
    @Effect({dispatch: false})
    AuthLoginErfolgreichAction$ = this.actions$.pipe(
        ofType<AuthLoginErfolgreichAction>(AUTH_LOGIN_ERFOLGREICH)
        , tap((x: AuthLoginErfolgreichAction) => {
            this.appInsightsService.setAuthenticatedUserContext(x.payload.benutzer_id, x.payload.benutzer_id, true);
        }));

  
    @Effect({dispatch: false})
    AuthLogoutErfolgreich$ = this.actions$.pipe(
        ofType<AuthLogoutErfolgreichAction>(AUTH_LOGOUT_ERFOLGREICH)
        , tap((x: AuthLogoutErfolgreichAction) => {
            this.appInsightsService.clearAuthenticatedUserContext();
        }));

    constructor(private actions$: Actions,
                public appInsightsService: AppInsightsService) {
    }
}
