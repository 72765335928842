import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const POST_BEREICH_KOPFDATEN_AKTUALISIEREN = '[DisplaysteuerungModul] PostBereichKopfdatenAktualisieren laden';
export const POST_BEREICH_KOPFDATEN_AKTUALISIEREN_AUSFUEHREN = '[DisplaysteuerungModul] PostBereichKopfdatenAktualisieren ausführen';
export const POST_BEREICH_KOPFDATEN_AKTUALISIEREN_ERFOLGREICH = '[DisplaysteuerungModul] PostBereichKopfdatenAktualisieren laden erfolgreich';
export const POST_BEREICH_KOPFDATEN_AKTUALISIEREN_FEHLER = '[DisplaysteuerungModul] PostBereichKopfdatenAktualisieren Ladefehler';
export const POST_BEREICH_KOPFDATEN_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] PostBereichKopfdatenAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostBereichKopfdatenAktualisierenAction implements Action {
    readonly type = POST_BEREICH_KOPFDATEN_AKTUALISIEREN;
        constructor(public bereichKopfdatenRequest: api.BereichKopfdatenRequest, public optPayload: any = null) {}
    }
export class PostBereichKopfdatenAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_BEREICH_KOPFDATEN_AKTUALISIEREN_AUSFUEHREN;
        constructor(public bereichKopfdatenRequest: api.BereichKopfdatenRequest, public optPayload: any = null) {}
    }
export class PostBereichKopfdatenAktualisierenErfolgreichAction implements Action {
    readonly type = POST_BEREICH_KOPFDATEN_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public bereichKopfdatenRequest: api.BereichKopfdatenRequest, public optPayload: any = null) {}
    }
export class PostBereichKopfdatenAktualisierenFehlerAction implements Action {
    readonly type = POST_BEREICH_KOPFDATEN_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public bereichKopfdatenRequest: api.BereichKopfdatenRequest, public optPayload: any = null) {}
    }
export class PostBereichKopfdatenAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_BEREICH_KOPFDATEN_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungCommandBereichKopfdatenAktualisierenActions =
    PostBereichKopfdatenAktualisierenAction
    | PostBereichKopfdatenAktualisierenAusfuehrenAction
    | PostBereichKopfdatenAktualisierenErfolgreichAction
    | PostBereichKopfdatenAktualisierenFehlerAction
    | PostBereichKopfdatenAktualisierenNichtAusgefuehrtAction
    ;
