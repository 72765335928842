

import {Action} from '@ngrx/store';

export const GET_FUNKPROTOKOLL_XSD_TEMPLATE = '[VeranstaltungenModul] GetFunkprotokollXsdTemplate laden';
export const GET_FUNKPROTOKOLL_XSD_TEMPLATE_AUSFUEHREN = '[VeranstaltungenModul] GetFunkprotokollXsdTemplate ausführen';
export const GET_FUNKPROTOKOLL_XSD_TEMPLATE_ERFOLGREICH = '[VeranstaltungenModul] GetFunkprotokollXsdTemplate laden erfolgreich';
export const GET_FUNKPROTOKOLL_XSD_TEMPLATE_FEHLER = '[VeranstaltungenModul] GetFunkprotokollXsdTemplate Ladefehler';
export const GET_FUNKPROTOKOLL_XSD_TEMPLATE_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunkprotokollXsdTemplate nicht ausgeführt (evtl. Offline)';



export class GetFunkprotokollXsdTemplateAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_XSD_TEMPLATE;
        constructor(public optPayload: any = null) {}
    }
export class GetFunkprotokollXsdTemplateAusfuehrenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_XSD_TEMPLATE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunkprotokollXsdTemplateErfolgreichAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_XSD_TEMPLATE_ERFOLGREICH;
        constructor(public optPayload: any = null) {}
    }
export class GetFunkprotokollXsdTemplateFehlerAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_XSD_TEMPLATE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFunkprotokollXsdTemplateNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_XSD_TEMPLATE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunkprotokollXsdTemplateActions =
    GetFunkprotokollXsdTemplateAction
    | GetFunkprotokollXsdTemplateAusfuehrenAction
    | GetFunkprotokollXsdTemplateErfolgreichAction
    | GetFunkprotokollXsdTemplateFehlerAction
    | GetFunkprotokollXsdTemplateNichtAusgefuehrtAction
    ;
