import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFLAUF_AUFGABE_BEMERKUNG = '[QschecksModul] GetPrueflaufAufgabeBemerkung laden';
export const GET_PRUEFLAUF_AUFGABE_BEMERKUNG_AUSFUEHREN = '[QschecksModul] GetPrueflaufAufgabeBemerkung ausführen';
export const GET_PRUEFLAUF_AUFGABE_BEMERKUNG_ERFOLGREICH = '[QschecksModul] GetPrueflaufAufgabeBemerkung laden erfolgreich';
export const GET_PRUEFLAUF_AUFGABE_BEMERKUNG_FEHLER = '[QschecksModul] GetPrueflaufAufgabeBemerkung Ladefehler';
export const GET_PRUEFLAUF_AUFGABE_BEMERKUNG_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPrueflaufAufgabeBemerkung nicht ausgeführt (evtl. Offline)';



export class GetPrueflaufAufgabeBemerkungAction implements Action {
    readonly type = GET_PRUEFLAUF_AUFGABE_BEMERKUNG;
        constructor( public prueflauf_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufAufgabeBemerkungAusfuehrenAction implements Action {
    readonly type = GET_PRUEFLAUF_AUFGABE_BEMERKUNG_AUSFUEHREN;
        constructor( public prueflauf_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufAufgabeBemerkungErfolgreichAction implements Action {
    readonly type = GET_PRUEFLAUF_AUFGABE_BEMERKUNG_ERFOLGREICH;
        constructor(public payload: api.PrueflaufAufgabeBemerkung,  public prueflauf_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufAufgabeBemerkungFehlerAction implements Action {
    readonly type = GET_PRUEFLAUF_AUFGABE_BEMERKUNG_FEHLER;
        constructor(public payload: any,  public prueflauf_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufAufgabeBemerkungNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFLAUF_AUFGABE_BEMERKUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPrueflaufAufgabeBemerkungActions =
    GetPrueflaufAufgabeBemerkungAction
    | GetPrueflaufAufgabeBemerkungAusfuehrenAction
    | GetPrueflaufAufgabeBemerkungErfolgreichAction
    | GetPrueflaufAufgabeBemerkungFehlerAction
    | GetPrueflaufAufgabeBemerkungNichtAusgefuehrtAction
    ;
