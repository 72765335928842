import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_STATUS = '[VeranstaltungenModul] GetVeranstaltungStatus laden';
export const GET_VERANSTALTUNG_STATUS_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungStatus ausführen';
export const GET_VERANSTALTUNG_STATUS_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungStatus laden erfolgreich';
export const GET_VERANSTALTUNG_STATUS_FEHLER = '[VeranstaltungenModul] GetVeranstaltungStatus Ladefehler';
export const GET_VERANSTALTUNG_STATUS_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungStatus nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungStatusAction implements Action {
    readonly type = GET_VERANSTALTUNG_STATUS;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungStatusAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_STATUS_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungStatusErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_STATUS_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungStatus,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungStatusFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_STATUS_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungStatusNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_STATUS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungStatusActions =
    GetVeranstaltungStatusAction
    | GetVeranstaltungStatusAusfuehrenAction
    | GetVeranstaltungStatusErfolgreichAction
    | GetVeranstaltungStatusFehlerAction
    | GetVeranstaltungStatusNichtAusgefuehrtAction
    ;
