import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_BERECHTIGUNGEN_ADMIN = '[VeranstaltungenModul] GetVeranstaltungBerechtigungenAdmin laden';
export const GET_VERANSTALTUNG_BERECHTIGUNGEN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungBerechtigungenAdmin ausführen';
export const GET_VERANSTALTUNG_BERECHTIGUNGEN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungBerechtigungenAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_BERECHTIGUNGEN_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungBerechtigungenAdmin Ladefehler';
export const GET_VERANSTALTUNG_BERECHTIGUNGEN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungBerechtigungenAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungBerechtigungenAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_BERECHTIGUNGEN_ADMIN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBerechtigungenAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_BERECHTIGUNGEN_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBerechtigungenAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_BERECHTIGUNGEN_ADMIN_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungBerechtigung,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBerechtigungenAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_BERECHTIGUNGEN_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBerechtigungenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_BERECHTIGUNGEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungBerechtigungenAdminActions =
    GetVeranstaltungBerechtigungenAdminAction
    | GetVeranstaltungBerechtigungenAdminAusfuehrenAction
    | GetVeranstaltungBerechtigungenAdminErfolgreichAction
    | GetVeranstaltungBerechtigungenAdminFehlerAction
    | GetVeranstaltungBerechtigungenAdminNichtAusgefuehrtAction
    ;
