import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFPLAN_ROLLE_CONTROLLER_ENTFERNEN = '[QschecksModul] PostPruefplanRolleControllerEntfernen laden';
export const POST_PRUEFPLAN_ROLLE_CONTROLLER_ENTFERNEN_AUSFUEHREN = '[QschecksModul] PostPruefplanRolleControllerEntfernen ausführen';
export const POST_PRUEFPLAN_ROLLE_CONTROLLER_ENTFERNEN_ERFOLGREICH = '[QschecksModul] PostPruefplanRolleControllerEntfernen laden erfolgreich';
export const POST_PRUEFPLAN_ROLLE_CONTROLLER_ENTFERNEN_FEHLER = '[QschecksModul] PostPruefplanRolleControllerEntfernen Ladefehler';
export const POST_PRUEFPLAN_ROLLE_CONTROLLER_ENTFERNEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefplanRolleControllerEntfernen nicht ausgeführt (evtl. Offline)';



export class PostPruefplanRolleControllerEntfernenAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLE_CONTROLLER_ENTFERNEN;
        constructor(public pruefplanRolleRequest: api.PruefplanRolleRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRolleControllerEntfernenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLE_CONTROLLER_ENTFERNEN_AUSFUEHREN;
        constructor(public pruefplanRolleRequest: api.PruefplanRolleRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRolleControllerEntfernenErfolgreichAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLE_CONTROLLER_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefplanRolleRequest: api.PruefplanRolleRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRolleControllerEntfernenFehlerAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLE_CONTROLLER_ENTFERNEN_FEHLER;
        constructor(public payload: any, public pruefplanRolleRequest: api.PruefplanRolleRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRolleControllerEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLE_CONTROLLER_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefplanRolleControllerEntfernenActions =
    PostPruefplanRolleControllerEntfernenAction
    | PostPruefplanRolleControllerEntfernenAusfuehrenAction
    | PostPruefplanRolleControllerEntfernenErfolgreichAction
    | PostPruefplanRolleControllerEntfernenFehlerAction
    | PostPruefplanRolleControllerEntfernenNichtAusgefuehrtAction
    ;
