import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICH_FREIGABE_ROLLEN = '[BenutzerverwaltungModul] GetFunktionsbereichFreigabeRollen laden';
export const GET_FUNKTIONSBEREICH_FREIGABE_ROLLEN_AUSFUEHREN = '[BenutzerverwaltungModul] GetFunktionsbereichFreigabeRollen ausführen';
export const GET_FUNKTIONSBEREICH_FREIGABE_ROLLEN_ERFOLGREICH = '[BenutzerverwaltungModul] GetFunktionsbereichFreigabeRollen laden erfolgreich';
export const GET_FUNKTIONSBEREICH_FREIGABE_ROLLEN_FEHLER = '[BenutzerverwaltungModul] GetFunktionsbereichFreigabeRollen Ladefehler';
export const GET_FUNKTIONSBEREICH_FREIGABE_ROLLEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetFunktionsbereichFreigabeRollen nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichFreigabeRollenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_FREIGABE_ROLLEN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichFreigabeRollenAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_FREIGABE_ROLLEN_AUSFUEHREN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichFreigabeRollenErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_FREIGABE_ROLLEN_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichFreigabeRolle>,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichFreigabeRollenFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_FREIGABE_ROLLEN_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichFreigabeRollenNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_FREIGABE_ROLLEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryFunktionsbereichFreigabeRollenActions =
    GetFunktionsbereichFreigabeRollenAction
    | GetFunktionsbereichFreigabeRollenAusfuehrenAction
    | GetFunktionsbereichFreigabeRollenErfolgreichAction
    | GetFunktionsbereichFreigabeRollenFehlerAction
    | GetFunktionsbereichFreigabeRollenNichtAusgefuehrtAction
    ;
