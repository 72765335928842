

import {Action} from '@ngrx/store';

export const GET_AUFGABE_FEHLER_BILD = '[VeranstaltungenModul] GetAufgabeFehlerBild laden';
export const GET_AUFGABE_FEHLER_BILD_AUSFUEHREN = '[VeranstaltungenModul] GetAufgabeFehlerBild ausführen';
export const GET_AUFGABE_FEHLER_BILD_ERFOLGREICH = '[VeranstaltungenModul] GetAufgabeFehlerBild laden erfolgreich';
export const GET_AUFGABE_FEHLER_BILD_FEHLER = '[VeranstaltungenModul] GetAufgabeFehlerBild Ladefehler';
export const GET_AUFGABE_FEHLER_BILD_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetAufgabeFehlerBild nicht ausgeführt (evtl. Offline)';



export class GetAufgabeFehlerBildAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD;
        constructor( public aufgabe_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBildAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_AUSFUEHREN;
        constructor( public aufgabe_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBildErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_ERFOLGREICH;
        constructor( public aufgabe_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBildFehlerAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_FEHLER;
        constructor(public payload: any,  public aufgabe_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBildNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryAufgabeFehlerBildActions =
    GetAufgabeFehlerBildAction
    | GetAufgabeFehlerBildAusfuehrenAction
    | GetAufgabeFehlerBildErfolgreichAction
    | GetAufgabeFehlerBildFehlerAction
    | GetAufgabeFehlerBildNichtAusgefuehrtAction
    ;
