import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_LOESCHEN = '[VeranstaltungenModul] PostVeranstaltungLoeschen laden';
export const POST_VERANSTALTUNG_LOESCHEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungLoeschen ausführen';
export const POST_VERANSTALTUNG_LOESCHEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungLoeschen laden erfolgreich';
export const POST_VERANSTALTUNG_LOESCHEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungLoeschen Ladefehler';
export const POST_VERANSTALTUNG_LOESCHEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungLoeschen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungLoeschenAction implements Action {
    readonly type = POST_VERANSTALTUNG_LOESCHEN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungLoeschenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_LOESCHEN_AUSFUEHREN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungLoeschenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_LOESCHEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungLoeschenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_LOESCHEN_FEHLER;
        constructor(public payload: any, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungLoeschenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_LOESCHEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungLoeschenActions =
    PostVeranstaltungLoeschenAction
    | PostVeranstaltungLoeschenAusfuehrenAction
    | PostVeranstaltungLoeschenErfolgreichAction
    | PostVeranstaltungLoeschenFehlerAction
    | PostVeranstaltungLoeschenNichtAusgefuehrtAction
    ;
