import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICHE = '[BenutzerverwaltungModul] GetFunktionsbereiche laden';
export const GET_FUNKTIONSBEREICHE_AUSFUEHREN = '[BenutzerverwaltungModul] GetFunktionsbereiche ausführen';
export const GET_FUNKTIONSBEREICHE_ERFOLGREICH = '[BenutzerverwaltungModul] GetFunktionsbereiche laden erfolgreich';
export const GET_FUNKTIONSBEREICHE_FEHLER = '[BenutzerverwaltungModul] GetFunktionsbereiche Ladefehler';
export const GET_FUNKTIONSBEREICHE_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetFunktionsbereiche nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereicheAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereicheAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereicheErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ERFOLGREICH;
        constructor(public payload: Array<api.Funktionsbereich>, public optPayload: any = null) {}
    }
export class GetFunktionsbereicheFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFunktionsbereicheNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryFunktionsbereicheActions =
    GetFunktionsbereicheAction
    | GetFunktionsbereicheAusfuehrenAction
    | GetFunktionsbereicheErfolgreichAction
    | GetFunktionsbereicheFehlerAction
    | GetFunktionsbereicheNichtAusgefuehrtAction
    ;
