import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN = '[VorlagenModul] PostChecklisteVeranstaltungsTypenDeaktivieren laden';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_AUSFUEHREN = '[VorlagenModul] PostChecklisteVeranstaltungsTypenDeaktivieren ausführen';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_ERFOLGREICH = '[VorlagenModul] PostChecklisteVeranstaltungsTypenDeaktivieren laden erfolgreich';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_FEHLER = '[VorlagenModul] PostChecklisteVeranstaltungsTypenDeaktivieren Ladefehler';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostChecklisteVeranstaltungsTypenDeaktivieren nicht ausgeführt (evtl. Offline)';



export class PostChecklisteVeranstaltungsTypenDeaktivierenAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN;
        constructor(public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenDeaktivierenAusfuehrenAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_AUSFUEHREN;
        constructor(public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenDeaktivierenErfolgreichAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenDeaktivierenFehlerAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_FEHLER;
        constructor(public payload: any, public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenDeaktivierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandChecklisteVeranstaltungsTypenDeaktivierenActions =
    PostChecklisteVeranstaltungsTypenDeaktivierenAction
    | PostChecklisteVeranstaltungsTypenDeaktivierenAusfuehrenAction
    | PostChecklisteVeranstaltungsTypenDeaktivierenErfolgreichAction
    | PostChecklisteVeranstaltungsTypenDeaktivierenFehlerAction
    | PostChecklisteVeranstaltungsTypenDeaktivierenNichtAusgefuehrtAction
    ;
