import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: 'mandanten-auswahl',
        loadChildren: () => import('./mandanten-auswahl/mandanten-auswahl.module').then(m => m.MandantenAuswahlPageModule)
    },
    {
        path: 'mandanten-auswahl',
        redirectTo: 'login',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class MandantRoutingModule {
}
