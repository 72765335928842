import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.command.bereich-trigger-aktualisieren';
import * as api from '../../../clients/displaysteuerung';


    @Injectable()
    export class DisplaysteuerungBereichTriggerAktualisierenEffects {

        @Effect()
            BereichTriggerAktualisieren$ = this.actions$.pipe(
                ofType(ac.POST_BEREICH_TRIGGER_AKTUALISIEREN),
                flatMap((x: ac.PostBereichTriggerAktualisierenAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().postBereichTriggerAktualisieren(x.bereichTriggerDatenRequest).pipe(
                    map((result: any) => {
                        const nextAction = new ac.PostBereichTriggerAktualisierenErfolgreichAction(result, x.bereichTriggerDatenRequest,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.PostBereichTriggerAktualisierenFehlerAction(error, x.bereichTriggerDatenRequest,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.VerwaltenCommandService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.VerwaltenCommandService {
                if (this.service === null) {
                    this.service = this.injector.get(api.VerwaltenCommandService);
                }
                return this.service;
            }
        
}
