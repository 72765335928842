import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_CHECKLISTE_IDENTIFIER = '[VeranstaltungenModul] GetChecklisteIdentifier laden';
export const GET_CHECKLISTE_IDENTIFIER_AUSFUEHREN = '[VeranstaltungenModul] GetChecklisteIdentifier ausführen';
export const GET_CHECKLISTE_IDENTIFIER_ERFOLGREICH = '[VeranstaltungenModul] GetChecklisteIdentifier laden erfolgreich';
export const GET_CHECKLISTE_IDENTIFIER_FEHLER = '[VeranstaltungenModul] GetChecklisteIdentifier Ladefehler';
export const GET_CHECKLISTE_IDENTIFIER_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetChecklisteIdentifier nicht ausgeführt (evtl. Offline)';



export class GetChecklisteIdentifierAction implements Action {
    readonly type = GET_CHECKLISTE_IDENTIFIER;
        constructor(public optPayload: any = null) {}
    }
export class GetChecklisteIdentifierAusfuehrenAction implements Action {
    readonly type = GET_CHECKLISTE_IDENTIFIER_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetChecklisteIdentifierErfolgreichAction implements Action {
    readonly type = GET_CHECKLISTE_IDENTIFIER_ERFOLGREICH;
        constructor(public payload: api.ChecklisteIdentifier, public optPayload: any = null) {}
    }
export class GetChecklisteIdentifierFehlerAction implements Action {
    readonly type = GET_CHECKLISTE_IDENTIFIER_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetChecklisteIdentifierNichtAusgefuehrtAction implements Action {
    readonly type = GET_CHECKLISTE_IDENTIFIER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryChecklisteIdentifierActions =
    GetChecklisteIdentifierAction
    | GetChecklisteIdentifierAusfuehrenAction
    | GetChecklisteIdentifierErfolgreichAction
    | GetChecklisteIdentifierFehlerAction
    | GetChecklisteIdentifierNichtAusgefuehrtAction
    ;
