import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import * as fromFunktionsbereichauswahlModal from './funktionsbereichsauswahl.reducer';
import * as actionsUi from './funktionsbereichsauswahl.actions.ui';
import {select, Store} from '@ngrx/store';
import {IonSearchbar, ModalController} from '@ionic/angular';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';
import {GetVeranstaltungsTypFunktionsbereicheAction} from '../../../client-ngrx/veranstaltungstypen';

@Component({
    selector: 'app-funktionsbereichsauswahl',
    templateUrl: './funktionsbereichsauswahl.page.html'
})
export class FunktionsbereichsauswahlPage implements OnInit {
    @ViewChild('suche', {static: false}) suche: IonSearchbar;
    @Input() aktuell: Array<any>;
    @Input() titel: string;
    @Input() veranstaltungstypId: string;

    aiName = 'FunktionsbereichsauswahlModal';
    funktionsbereicheGruppiert$: Observable<Array<ListInputItem>>;
    funktionsbereicheSelektiert$: Observable<Array<ListInputItem>>;
    uiFunktionsbereicheLadeanimation$: Observable<boolean>;
    uiFunktionsbereicheLadeFehler$: Observable<boolean>;
    checklistenAnlegen = true;

    constructor(private modalCtrl: ModalController,
                private store: Store<fromFunktionsbereichauswahlModal.PageState>) {
        this.store.dispatch(new actionsUi.PageStateResetAction());
    }

    ngOnInit() {
        this.store.dispatch(new actionsUi.NichtAnzuzeigendeFunktionsbereicheSetzenAction(this.aktuell));
        this.funktionsbereicheGruppiert$ = this.store.pipe(select(fromFunktionsbereichauswahlModal.getFunktionsbereicheGruppiert));
        this.funktionsbereicheSelektiert$ = this.store.pipe(select(fromFunktionsbereichauswahlModal.getFunktionsbereicheSelektiert));
        this.uiFunktionsbereicheLadeanimation$ = this.store.pipe(select(fromFunktionsbereichauswahlModal.getUiFunktionsbereicheLadeanimation));
        this.uiFunktionsbereicheLadeFehler$ = this.store.pipe(select(fromFunktionsbereichauswahlModal.getUiFunktionsbereicheLadeFehler));
        this.funktionsbereicheLaden();
    }

    ionViewWillEnter() {
        this.viewWillEnterPage().then();
    }

    async viewWillEnterPage() {
        setTimeout(() => {
            if (this.suche) {
                this.suche.setFocus();
            }
        }, 500);
    }

    async ok() {
        this.funktionsbereicheSelektiert$.subscribe(async (items) => {
            const data = {checklistenAnlegen: this.checklistenAnlegen, hinzugefuegte: items, entfernte: []};
            await this.modalCtrl.dismiss(data);
        }).unsubscribe();
    }

    async abbrechen() {
        await this.modalCtrl.dismiss();
    }

    async funktionsbereicheLaden() {
        this.store.dispatch(new GetVeranstaltungsTypFunktionsbereicheAction(this.veranstaltungstypId));
    }

    async auswahl(items: Array<ListInputItem>) {
        this.store.dispatch(new actionsUi.FunktionsbereicheSetzenAction(items));
    }
}
