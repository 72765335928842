import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFAUFGABE_ENTFERNEN = '[QschecksModul] PostPruefaufgabeEntfernen laden';
export const POST_PRUEFAUFGABE_ENTFERNEN_AUSFUEHREN = '[QschecksModul] PostPruefaufgabeEntfernen ausführen';
export const POST_PRUEFAUFGABE_ENTFERNEN_ERFOLGREICH = '[QschecksModul] PostPruefaufgabeEntfernen laden erfolgreich';
export const POST_PRUEFAUFGABE_ENTFERNEN_FEHLER = '[QschecksModul] PostPruefaufgabeEntfernen Ladefehler';
export const POST_PRUEFAUFGABE_ENTFERNEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefaufgabeEntfernen nicht ausgeführt (evtl. Offline)';



export class PostPruefaufgabeEntfernenAction implements Action {
    readonly type = POST_PRUEFAUFGABE_ENTFERNEN;
        constructor(public pruefaufgabeRequest: api.PruefaufgabeRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeEntfernenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFAUFGABE_ENTFERNEN_AUSFUEHREN;
        constructor(public pruefaufgabeRequest: api.PruefaufgabeRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeEntfernenErfolgreichAction implements Action {
    readonly type = POST_PRUEFAUFGABE_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefaufgabeRequest: api.PruefaufgabeRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeEntfernenFehlerAction implements Action {
    readonly type = POST_PRUEFAUFGABE_ENTFERNEN_FEHLER;
        constructor(public payload: any, public pruefaufgabeRequest: api.PruefaufgabeRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFAUFGABE_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefaufgabeEntfernenActions =
    PostPruefaufgabeEntfernenAction
    | PostPruefaufgabeEntfernenAusfuehrenAction
    | PostPruefaufgabeEntfernenErfolgreichAction
    | PostPruefaufgabeEntfernenFehlerAction
    | PostPruefaufgabeEntfernenNichtAusgefuehrtAction
    ;
