import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'system-meldung',
  templateUrl: './system-meldung.component.html',
  styleUrls: ['./system-meldung.component.scss'],
})
export class SystemMeldungComponent implements OnInit {

  @Input() mainValue: string = null;
  @Input() rightValue: string = null;

  constructor() { }

  ngOnInit() {}

}
