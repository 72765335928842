import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_UPLOAD_AKTUALISIEREN = '[VorlagenModul] PostDokumentUploadAktualisieren laden';
export const POST_DOKUMENT_UPLOAD_AKTUALISIEREN_AUSFUEHREN = '[VorlagenModul] PostDokumentUploadAktualisieren ausführen';
export const POST_DOKUMENT_UPLOAD_AKTUALISIEREN_ERFOLGREICH = '[VorlagenModul] PostDokumentUploadAktualisieren laden erfolgreich';
export const POST_DOKUMENT_UPLOAD_AKTUALISIEREN_FEHLER = '[VorlagenModul] PostDokumentUploadAktualisieren Ladefehler';
export const POST_DOKUMENT_UPLOAD_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentUploadAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostDokumentUploadAktualisierenAction implements Action {
    readonly type = POST_DOKUMENT_UPLOAD_AKTUALISIEREN;
        constructor(public dokumentUploadAktualisierenRequest: api.DokumentUploadAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentUploadAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_UPLOAD_AKTUALISIEREN_AUSFUEHREN;
        constructor(public dokumentUploadAktualisierenRequest: api.DokumentUploadAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentUploadAktualisierenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_UPLOAD_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentUploadAktualisierenRequest: api.DokumentUploadAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentUploadAktualisierenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_UPLOAD_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public dokumentUploadAktualisierenRequest: api.DokumentUploadAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentUploadAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_UPLOAD_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentUploadAktualisierenActions =
    PostDokumentUploadAktualisierenAction
    | PostDokumentUploadAktualisierenAusfuehrenAction
    | PostDokumentUploadAktualisierenErfolgreichAction
    | PostDokumentUploadAktualisierenFehlerAction
    | PostDokumentUploadAktualisierenNichtAusgefuehrtAction
    ;
