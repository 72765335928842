import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_REIHENFOLGEN = '[VorlagenModul] PostAufgabeReihenfolgen laden';
export const POST_AUFGABE_REIHENFOLGEN_AUSFUEHREN = '[VorlagenModul] PostAufgabeReihenfolgen ausführen';
export const POST_AUFGABE_REIHENFOLGEN_ERFOLGREICH = '[VorlagenModul] PostAufgabeReihenfolgen laden erfolgreich';
export const POST_AUFGABE_REIHENFOLGEN_FEHLER = '[VorlagenModul] PostAufgabeReihenfolgen Ladefehler';
export const POST_AUFGABE_REIHENFOLGEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostAufgabeReihenfolgen nicht ausgeführt (evtl. Offline)';



export class PostAufgabeReihenfolgenAction implements Action {
    readonly type = POST_AUFGABE_REIHENFOLGEN;
        constructor(public aufgabeIdListeRequest: api.AufgabeIdListeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeReihenfolgenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_REIHENFOLGEN_AUSFUEHREN;
        constructor(public aufgabeIdListeRequest: api.AufgabeIdListeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeReihenfolgenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_REIHENFOLGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeIdListeRequest: api.AufgabeIdListeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeReihenfolgenFehlerAction implements Action {
    readonly type = POST_AUFGABE_REIHENFOLGEN_FEHLER;
        constructor(public payload: any, public aufgabeIdListeRequest: api.AufgabeIdListeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeReihenfolgenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_REIHENFOLGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandAufgabeReihenfolgenActions =
    PostAufgabeReihenfolgenAction
    | PostAufgabeReihenfolgenAusfuehrenAction
    | PostAufgabeReihenfolgenErfolgreichAction
    | PostAufgabeReihenfolgenFehlerAction
    | PostAufgabeReihenfolgenNichtAusgefuehrtAction
    ;
