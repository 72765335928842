import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_MITTEILUNGEN_GRUPPIERT = '[VeranstaltungenModul] GetMitteilungenGruppiert laden';
export const GET_MITTEILUNGEN_GRUPPIERT_AUSFUEHREN = '[VeranstaltungenModul] GetMitteilungenGruppiert ausführen';
export const GET_MITTEILUNGEN_GRUPPIERT_ERFOLGREICH = '[VeranstaltungenModul] GetMitteilungenGruppiert laden erfolgreich';
export const GET_MITTEILUNGEN_GRUPPIERT_FEHLER = '[VeranstaltungenModul] GetMitteilungenGruppiert Ladefehler';
export const GET_MITTEILUNGEN_GRUPPIERT_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetMitteilungenGruppiert nicht ausgeführt (evtl. Offline)';



export class GetMitteilungenGruppiertAction implements Action {
    readonly type = GET_MITTEILUNGEN_GRUPPIERT;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGruppiertAusfuehrenAction implements Action {
    readonly type = GET_MITTEILUNGEN_GRUPPIERT_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGruppiertErfolgreichAction implements Action {
    readonly type = GET_MITTEILUNGEN_GRUPPIERT_ERFOLGREICH;
        constructor(public payload: Array<api.DatumGruppeMitteilungen>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGruppiertFehlerAction implements Action {
    readonly type = GET_MITTEILUNGEN_GRUPPIERT_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGruppiertNichtAusgefuehrtAction implements Action {
    readonly type = GET_MITTEILUNGEN_GRUPPIERT_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryMitteilungenGruppiertActions =
    GetMitteilungenGruppiertAction
    | GetMitteilungenGruppiertAusfuehrenAction
    | GetMitteilungenGruppiertErfolgreichAction
    | GetMitteilungenGruppiertFehlerAction
    | GetMitteilungenGruppiertNichtAusgefuehrtAction
    ;
