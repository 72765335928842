import {Component, Input, OnInit} from '@angular/core';
import {ModalController, NavController, NavParams} from '@ionic/angular';
import {VeranstaltungsTypFeldSelectOption} from '../../../../clients/veranstaltungstypen';


@Component({
    selector: 'app-feld-option',
    templateUrl: './feld-option.page.html'
})
export class FeldOptionPage implements OnInit {
    @Input() titel: string;
    @Input() untertitel: string;
    @Input() option: VeranstaltungsTypFeldSelectOption;

    private aiName = 'FeldOptionPage';

    public istDeaktiviert: boolean;
    public optionTitel: string;

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private modalCtrl: ModalController) {
        if (navParams.data !== null && navParams.data.option !== undefined && navParams.data.option !== null) {
            this.istDeaktiviert = navParams.data.option.ist_deaktiviert;
            this.optionTitel = navParams.data.option.titel;
        }

        // console.log('navParams: ', navParams);

    }

    ngOnInit() {
    }

    abbrechen() {
        this.modalCtrl.dismiss();
    }

    async ok() {
        const ret = Object.assign({}, this.option, <VeranstaltungsTypFeldSelectOption> {
            ist_deaktiviert: this.istDeaktiviert,
            titel: this.optionTitel
        });
        const data = {option: ret};
        await this.modalCtrl.dismiss(data);
    }
}
