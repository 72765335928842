import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFLAUF_CONTROLLING = '[QschecksModul] GetPrueflaufControlling laden';
export const GET_PRUEFLAUF_CONTROLLING_AUSFUEHREN = '[QschecksModul] GetPrueflaufControlling ausführen';
export const GET_PRUEFLAUF_CONTROLLING_ERFOLGREICH = '[QschecksModul] GetPrueflaufControlling laden erfolgreich';
export const GET_PRUEFLAUF_CONTROLLING_FEHLER = '[QschecksModul] GetPrueflaufControlling Ladefehler';
export const GET_PRUEFLAUF_CONTROLLING_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPrueflaufControlling nicht ausgeführt (evtl. Offline)';



export class GetPrueflaufControllingAction implements Action {
    readonly type = GET_PRUEFLAUF_CONTROLLING;
        constructor( public prueflauf_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufControllingAusfuehrenAction implements Action {
    readonly type = GET_PRUEFLAUF_CONTROLLING_AUSFUEHREN;
        constructor( public prueflauf_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufControllingErfolgreichAction implements Action {
    readonly type = GET_PRUEFLAUF_CONTROLLING_ERFOLGREICH;
        constructor(public payload: api.PrueflaufDetail,  public prueflauf_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufControllingFehlerAction implements Action {
    readonly type = GET_PRUEFLAUF_CONTROLLING_FEHLER;
        constructor(public payload: any,  public prueflauf_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufControllingNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFLAUF_CONTROLLING_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPrueflaufControllingActions =
    GetPrueflaufControllingAction
    | GetPrueflaufControllingAusfuehrenAction
    | GetPrueflaufControllingErfolgreichAction
    | GetPrueflaufControllingFehlerAction
    | GetPrueflaufControllingNichtAusgefuehrtAction
    ;
