import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.query.funkprotokoll-fall';
import * as api from '../../../clients/veranstaltungen';


    @Injectable()
    export class VeranstaltungenFunkprotokollFallEffects {

        @Effect()
            FunkprotokollFall$ = this.actions$.pipe(
                ofType(ac.GET_FUNKPROTOKOLL_FALL),
                flatMap((x: ac.GetFunkprotokollFallAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().getFunkprotokollFall(x.veranstaltung_id, x.fall_id).pipe(
                    map((result: any) => {
                        const nextAction = new ac.GetFunkprotokollFallErfolgreichAction(result, x.veranstaltung_id, x.fall_id,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.GetFunkprotokollFallFehlerAction(error, x.veranstaltung_id, x.fall_id,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.FunkprotokollQueryService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.FunkprotokollQueryService {
                if (this.service === null) {
                    this.service = this.injector.get(api.FunkprotokollQueryService);
                }
                return this.service;
            }
        
}
