import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFLAUF_BEMERKUNG = '[QschecksModul] GetPrueflaufBemerkung laden';
export const GET_PRUEFLAUF_BEMERKUNG_AUSFUEHREN = '[QschecksModul] GetPrueflaufBemerkung ausführen';
export const GET_PRUEFLAUF_BEMERKUNG_ERFOLGREICH = '[QschecksModul] GetPrueflaufBemerkung laden erfolgreich';
export const GET_PRUEFLAUF_BEMERKUNG_FEHLER = '[QschecksModul] GetPrueflaufBemerkung Ladefehler';
export const GET_PRUEFLAUF_BEMERKUNG_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPrueflaufBemerkung nicht ausgeführt (evtl. Offline)';



export class GetPrueflaufBemerkungAction implements Action {
    readonly type = GET_PRUEFLAUF_BEMERKUNG;
        constructor( public prueflauf_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufBemerkungAusfuehrenAction implements Action {
    readonly type = GET_PRUEFLAUF_BEMERKUNG_AUSFUEHREN;
        constructor( public prueflauf_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufBemerkungErfolgreichAction implements Action {
    readonly type = GET_PRUEFLAUF_BEMERKUNG_ERFOLGREICH;
        constructor(public payload: api.PrueflaufBemerkung,  public prueflauf_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufBemerkungFehlerAction implements Action {
    readonly type = GET_PRUEFLAUF_BEMERKUNG_FEHLER;
        constructor(public payload: any,  public prueflauf_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufBemerkungNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFLAUF_BEMERKUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPrueflaufBemerkungActions =
    GetPrueflaufBemerkungAction
    | GetPrueflaufBemerkungAusfuehrenAction
    | GetPrueflaufBemerkungErfolgreichAction
    | GetPrueflaufBemerkungFehlerAction
    | GetPrueflaufBemerkungNichtAusgefuehrtAction
    ;
