import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ModuleState, PageState} from './qschecks-controlling-saison-filter.reducer';
import {ListInputItem} from '../../../shared/sopi-list/sopi-list.model';
import {FunktionsbereichRollen} from '../../../../clients/qschecks';

export function getModuleState() {
    return createFeatureSelector<ModuleState>('QschecksControllingSaisonFilterPage');
}

export function getPageState() {
    return createSelector(getModuleState(), (state: ModuleState) => state.page);
}


export function getFunktionsbereichePruefplaene() {
    return createSelector(getPageState(), (state: PageState) => {
        if (state.fbPruefplaeneListe === undefined || state.fbPruefplaeneListe === null) {
            return [];
        }
        const liste: Array<ListInputItem> = [];

        state.fbPruefplaeneListe.forEach((fbPruefplaene) => fbPruefplaene.pruefplan_liste.map((pruefplan ) => {
            liste.push(<ListInputItem>{
                groupId: fbPruefplaene.funktionsbereich.funktionsbereich_id,
                groupTitle: fbPruefplaene.funktionsbereich.titel,
                itemId: pruefplan.pruefplan_id,
                itemTitle: pruefplan.titel,
                item: pruefplan
            });
        }));

        return liste;
    });
}

export function getFunktionsbereichePruefplaeneLaden() {
    return createSelector(getPageState(), (state: PageState) => state.fbPruefplaeneListeLaden);
}

export function getFunktionsbereichePruefplaeneFehler() {
    return createSelector(getPageState(), (state: PageState) => state.fbPruefplaeneListeFehler);
}

export function getPruefplanSelected() {
    return createSelector(getPageState(), (state: PageState) => state.pruefplanSelected);
}

export function getPrueferrollenListe() {
    return createSelector(getPageState(), (state: PageState) => {
        if (state.saisonFilterOptions === undefined || state.saisonFilterOptions === null) {
            return [];
        }
        const liste: Array<ListInputItem> = [];

        state.saisonFilterOptions.pruefer_rolle_liste.forEach((fbRollen: FunktionsbereichRollen) => fbRollen.rollen.map((rolle ) => {
            liste.push(<ListInputItem>{
                groupId: fbRollen.funktionsbereich.funktionsbereich_id,
                groupTitle: fbRollen.funktionsbereich.titel,
                itemId: rolle.rolle_id,
                itemTitle: rolle.titel,
                item: rolle
            });
        }));

        return liste;
    });
}

export function getSaisonListe() {
    return createSelector(getPageState(), (state: PageState) => {
        if (state.saisonFilterOptions === undefined || state.saisonFilterOptions === null) {
            return [];
        }
        const liste: Array<ListInputItem> = [];

        state.saisonFilterOptions.saison_liste2.forEach((saison) => {
            liste.push(<ListInputItem>{
                groupId: '',
                groupTitle: '',
                itemId:  saison.titel,
                itemTitle: saison.titel,
                item: saison
            });
        });

        return liste;
    });
}

export function getSaisonFilterOptionsLaden() {
    return createSelector(getPageState(), (state: PageState) => state.saisonFilterOptionsLaden);
}

export function getSaisonFilterOptionsFehler() {
    return createSelector(getPageState(), (state: PageState) => state.saisonFilterOptionsFehler);
}
