import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICHE_ROLLEN = '[BenutzerverwaltungModul] GetFunktionsbereicheRollen laden';
export const GET_FUNKTIONSBEREICHE_ROLLEN_AUSFUEHREN = '[BenutzerverwaltungModul] GetFunktionsbereicheRollen ausführen';
export const GET_FUNKTIONSBEREICHE_ROLLEN_ERFOLGREICH = '[BenutzerverwaltungModul] GetFunktionsbereicheRollen laden erfolgreich';
export const GET_FUNKTIONSBEREICHE_ROLLEN_FEHLER = '[BenutzerverwaltungModul] GetFunktionsbereicheRollen Ladefehler';
export const GET_FUNKTIONSBEREICHE_ROLLEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetFunktionsbereicheRollen nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereicheRollenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereicheRollenAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereicheRollenErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichRollen>, public optPayload: any = null) {}
    }
export class GetFunktionsbereicheRollenFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFunktionsbereicheRollenNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_ROLLEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryFunktionsbereicheRollenActions =
    GetFunktionsbereicheRollenAction
    | GetFunktionsbereicheRollenAusfuehrenAction
    | GetFunktionsbereicheRollenErfolgreichAction
    | GetFunktionsbereicheRollenFehlerAction
    | GetFunktionsbereicheRollenNichtAusgefuehrtAction
    ;
