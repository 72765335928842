import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_MITTEILUNG_EMPFAENGER = '[KommunikationModul] GetMitteilungEmpfaenger laden';
export const GET_MITTEILUNG_EMPFAENGER_AUSFUEHREN = '[KommunikationModul] GetMitteilungEmpfaenger ausführen';
export const GET_MITTEILUNG_EMPFAENGER_ERFOLGREICH = '[KommunikationModul] GetMitteilungEmpfaenger laden erfolgreich';
export const GET_MITTEILUNG_EMPFAENGER_FEHLER = '[KommunikationModul] GetMitteilungEmpfaenger Ladefehler';
export const GET_MITTEILUNG_EMPFAENGER_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetMitteilungEmpfaenger nicht ausgeführt (evtl. Offline)';



export class GetMitteilungEmpfaengerAction implements Action {
    readonly type = GET_MITTEILUNG_EMPFAENGER;
        constructor( public mitteilung_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungEmpfaengerAusfuehrenAction implements Action {
    readonly type = GET_MITTEILUNG_EMPFAENGER_AUSFUEHREN;
        constructor( public mitteilung_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungEmpfaengerErfolgreichAction implements Action {
    readonly type = GET_MITTEILUNG_EMPFAENGER_ERFOLGREICH;
        constructor(public payload: Array<api.Benutzer>,  public mitteilung_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungEmpfaengerFehlerAction implements Action {
    readonly type = GET_MITTEILUNG_EMPFAENGER_FEHLER;
        constructor(public payload: any,  public mitteilung_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungEmpfaengerNichtAusgefuehrtAction implements Action {
    readonly type = GET_MITTEILUNG_EMPFAENGER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryMitteilungEmpfaengerActions =
    GetMitteilungEmpfaengerAction
    | GetMitteilungEmpfaengerAusfuehrenAction
    | GetMitteilungEmpfaengerErfolgreichAction
    | GetMitteilungEmpfaengerFehlerAction
    | GetMitteilungEmpfaengerNichtAusgefuehrtAction
    ;
