export * from './adminCommand.service';
import { AdminCommandService } from './adminCommand.service';
export * from './adminQuery.service';
import { AdminQueryService } from './adminQuery.service';
export * from './controllingCommand.service';
import { ControllingCommandService } from './controllingCommand.service';
export * from './controllingQuery.service';
import { ControllingQueryService } from './controllingQuery.service';
export * from './prueflaufCommand.service';
import { PrueflaufCommandService } from './prueflaufCommand.service';
export * from './prueflaufQuery.service';
import { PrueflaufQueryService } from './prueflaufQuery.service';
export * from './qsChecksQuery.service';
import { QsChecksQueryService } from './qsChecksQuery.service';
export * from './qsChecksTemplates.service';
import { QsChecksTemplatesService } from './qsChecksTemplates.service';
export * from './rollenCommand.service';
import { RollenCommandService } from './rollenCommand.service';
export * from './rollenQuery.service';
import { RollenQueryService } from './rollenQuery.service';
export const APIS = [AdminCommandService, AdminQueryService, ControllingCommandService, ControllingQueryService, PrueflaufCommandService, PrueflaufQueryService, QsChecksQueryService, QsChecksTemplatesService, RollenCommandService, RollenQueryService];
