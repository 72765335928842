/**
 * orga app | api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BenutzerZugriffUpdatedNotification } from '../model/benutzerZugriffUpdatedNotification';
import { CommandFailedNotification } from '../model/commandFailedNotification';
import { CommandSucceededNotification } from '../model/commandSucceededNotification';
import { ConstantTypeInfo } from '../model/constantTypeInfo';
import { MyNotificationMessage } from '../model/myNotificationMessage';
import { NotificationMessageTypeInfo } from '../model/notificationMessageTypeInfo';
import { ReadModelUpdatedNotification } from '../model/readModelUpdatedNotification';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CommonTemplatesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetApiConfigTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getApiConfigTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<ConstantTypeInfo>>;
    public getApiConfigTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ConstantTypeInfo>>>;
    public getApiConfigTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ConstantTypeInfo>>>;
    public getApiConfigTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ConstantTypeInfo>>('get',`${this.basePath}/api/templates/configtypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetApiNotificationTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getApiNotificationTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<NotificationMessageTypeInfo>>;
    public getApiNotificationTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotificationMessageTypeInfo>>>;
    public getApiNotificationTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotificationMessageTypeInfo>>>;
    public getApiNotificationTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<NotificationMessageTypeInfo>>('get',`${this.basePath}/api/templates/notificationtypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetBenutzerZugriffUpdatedNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBenutzerZugriffUpdatedNotification(observe?: 'body', reportProgress?: boolean): Observable<BenutzerZugriffUpdatedNotification>;
    public getBenutzerZugriffUpdatedNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BenutzerZugriffUpdatedNotification>>;
    public getBenutzerZugriffUpdatedNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BenutzerZugriffUpdatedNotification>>;
    public getBenutzerZugriffUpdatedNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BenutzerZugriffUpdatedNotification>('get',`${this.basePath}/api/templates/notifications/benutzerzugriffupdated`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetCommandFailedNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCommandFailedNotification(observe?: 'body', reportProgress?: boolean): Observable<CommandFailedNotification>;
    public getCommandFailedNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandFailedNotification>>;
    public getCommandFailedNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandFailedNotification>>;
    public getCommandFailedNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CommandFailedNotification>('get',`${this.basePath}/api/templates/notifications/commandfailed`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetCommandSucceededNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCommandSucceededNotification(observe?: 'body', reportProgress?: boolean): Observable<CommandSucceededNotification>;
    public getCommandSucceededNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommandSucceededNotification>>;
    public getCommandSucceededNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommandSucceededNotification>>;
    public getCommandSucceededNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CommandSucceededNotification>('get',`${this.basePath}/api/templates/notifications/commandsucceeded`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMyNotificationMessage
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyNotificationMessage(observe?: 'body', reportProgress?: boolean): Observable<MyNotificationMessage>;
    public getMyNotificationMessage(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MyNotificationMessage>>;
    public getMyNotificationMessage(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MyNotificationMessage>>;
    public getMyNotificationMessage(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MyNotificationMessage>('get',`${this.basePath}/api/templates/notifications/mynotificationmessage`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetReadModelUpdatedNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReadModelUpdatedNotification(observe?: 'body', reportProgress?: boolean): Observable<ReadModelUpdatedNotification>;
    public getReadModelUpdatedNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReadModelUpdatedNotification>>;
    public getReadModelUpdatedNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReadModelUpdatedNotification>>;
    public getReadModelUpdatedNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ReadModelUpdatedNotification>('get',`${this.basePath}/api/templates/notifications/readmodelupdated`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
