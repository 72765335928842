import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_PHASE_BEENDEN = '[VeranstaltungenModul] PostVeranstaltungPhaseBeenden laden';
export const POST_VERANSTALTUNG_PHASE_BEENDEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungPhaseBeenden ausführen';
export const POST_VERANSTALTUNG_PHASE_BEENDEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungPhaseBeenden laden erfolgreich';
export const POST_VERANSTALTUNG_PHASE_BEENDEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungPhaseBeenden Ladefehler';
export const POST_VERANSTALTUNG_PHASE_BEENDEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungPhaseBeenden nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungPhaseBeendenAction implements Action {
    readonly type = POST_VERANSTALTUNG_PHASE_BEENDEN;
        constructor(public veranstaltungPhaseRequest: api.VeranstaltungPhaseRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungPhaseBeendenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_PHASE_BEENDEN_AUSFUEHREN;
        constructor(public veranstaltungPhaseRequest: api.VeranstaltungPhaseRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungPhaseBeendenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_PHASE_BEENDEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungPhaseRequest: api.VeranstaltungPhaseRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungPhaseBeendenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_PHASE_BEENDEN_FEHLER;
        constructor(public payload: any, public veranstaltungPhaseRequest: api.VeranstaltungPhaseRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungPhaseBeendenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_PHASE_BEENDEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungPhaseBeendenActions =
    PostVeranstaltungPhaseBeendenAction
    | PostVeranstaltungPhaseBeendenAusfuehrenAction
    | PostVeranstaltungPhaseBeendenErfolgreichAction
    | PostVeranstaltungPhaseBeendenFehlerAction
    | PostVeranstaltungPhaseBeendenNichtAusgefuehrtAction
    ;
