import {Action, ActionReducerMap} from '@ngrx/store';
import {BereichDetail, BereichKopfdaten, BereichListItem, BereichTrigger, Rolle} from './../../../clients/displaysteuerung';
import {APP_RESET_ACTION} from '@orga-app/appstart/actions';
import * as actionsUi from './displaysteuerung.actions.ui';
import {GET_BEREICH_DETAIL_ADMIN, GET_BEREICH_DETAIL_ADMIN_ERFOLGREICH, GET_BEREICH_DETAIL_ADMIN_FEHLER, GET_BEREICHE_LISTE_ADMIN, GET_BEREICHE_LISTE_ADMIN_ERFOLGREICH, GET_BEREICHE_LISTE_ADMIN_FEHLER, GetBereichDetailAdminErfolgreichAction, GetBereicheListeAdminErfolgreichAction, POST_BEREICH_KOPFDATEN_AKTUALISIEREN, PostBereichKopfdatenAktualisierenAction} from '../../../client-ngrx/displaysteuerung';
import {AktuellenBereichGesetztAction, AktuelleRolleBerechtigungSetzenAction} from './displaysteuerung.actions.ui';

export interface ModuleState {
    page: PageState;
}


export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer
};


export interface PageState {
    bereicheListe: Array<BereichListItem>;
    bereicheListeLaden: boolean;
    bereicheListeFehler: boolean;

    selectedBereich: BereichListItem;

    selectedBereichDetailOriginal: BereichDetail;
    selectedBereichDetail: BereichDetail;
    selectedBereichDetailLaden: boolean;
    selectedBereichDetailFehler: boolean;

    selectedRolle: Rolle;

    selectedTrigger: BereichTrigger;
}

export const initialPageState: PageState = {
    bereicheListe: null,
    bereicheListeLaden: false,
    bereicheListeFehler: false,

    selectedBereich: null,

    selectedBereichDetailOriginal: null,
    selectedBereichDetail: null,
    selectedBereichDetailLaden: false,
    selectedBereichDetailFehler: false,

    selectedRolle: null,

    selectedTrigger: null
};

export function pageReducer(state = initialPageState, action: Action): PageState {

    switch (action.type) {

        // APP RESET
        case APP_RESET_ACTION: {
            return Object.assign({}, state, <PageState>initialPageState);
        }

        // Page State Reset
        case actionsUi.PAGE_STATE_RESET: {
            return Object.assign({}, state, <PageState>initialPageState);
        }

        /* BEREICH (LISTE) */
        case GET_BEREICHE_LISTE_ADMIN: {
            return Object.assign({}, state, <PageState>{
                bereicheListeLaden: true,
                bereicheListeFehler: false
            });
        }

        case GET_BEREICHE_LISTE_ADMIN_ERFOLGREICH: {
            const a = <GetBereicheListeAdminErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                bereicheListe: a.payload,
                bereicheListeLaden: false,
                bereicheListeFehler: false
            });
        }

        case GET_BEREICHE_LISTE_ADMIN_FEHLER: {
            return Object.assign({}, state, <PageState>{
                bereicheListe: [],
                bereicheListeLaden: false,
                bereicheListeFehler: true
            });
        }

        /* BEREICH DETAIL */
        case GET_BEREICH_DETAIL_ADMIN: {
            return Object.assign({}, state, <PageState>{
                selectedBereichDetailLaden: true,
                selectedBereichDetailFehler: false
            });
        }

        case GET_BEREICH_DETAIL_ADMIN_ERFOLGREICH: {
            const a = <GetBereichDetailAdminErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                selectedBereichDetailOriginal: a.payload,
                selectedBereichDetail: a.payload,
                selectedBereichDetailLaden: false,
                selectedBereichDetailFehler: false
            });
        }

        case GET_BEREICH_DETAIL_ADMIN_FEHLER: {
            return Object.assign({}, state, <PageState>{
                selectedBereichDetailOriginal: null,
                selectedBereichDetail: null,
                selectedBereichDetailLaden: false,
                selectedBereichDetailFehler: true
            });
        }

        /* Ui-Actions */
        case actionsUi.AKTUELLEN_BEREICH_GESETZT: {
            const a = <actionsUi.AktuellenBereichGesetztAction>action;

            return Object.assign({}, state, <PageState> {
                selectedBereich: a.payload,
                selectedTrigger: null,
                selectedRolle: null
            });
        }

        case actionsUi.BEREICH_HINZUFUEGEN: {
            const selectedBereichDetail: BereichDetail = {
                kopfdaten: {
                    titel: '',
                    externe_url: '',
                    ist_externe_url: false,
                    ist_trigger_liste: true,
                    bereich_id: '',
                    zusatz_beschreibung: '',
                    beschreibung: '',
                    logo_blob_id: '',
                    reihenfolge: 0
                },
                berechtigung_rollen: [],
                logo: null,
                trigger: []
            };

            return Object.assign({}, state, <PageState> {
                selectedBereichDetail
            });
        }

        case POST_BEREICH_KOPFDATEN_AKTUALISIEREN: {
            const a = <PostBereichKopfdatenAktualisierenAction>action;

            const selectedBereichDetail = Object.assign({}, state.selectedBereichDetail, <BereichDetail> {
                kopfdaten: Object.assign({}, state.selectedBereichDetail.kopfdaten, <BereichKopfdaten> {
                    bereich_id: a.bereichKopfdatenRequest.bereich_id
                })
            });

            let selectedBereich: BereichListItem = null;
            if (state.bereicheListe.findIndex(x => x.bereich_id === a.bereichKopfdatenRequest.bereich_id) > -1){
                selectedBereich = state.bereicheListe[state.bereicheListe.findIndex(x => x.bereich_id === a.bereichKopfdatenRequest.bereich_id)];
            } else {
                selectedBereich = <BereichListItem> {
                    bereich_id: a.bereichKopfdatenRequest.bereich_id,
                    titel: a.bereichKopfdatenRequest.titel,
                    beschreibung: a.bereichKopfdatenRequest.beschreibung,
                    zusatz_beschreibung: a.bereichKopfdatenRequest.zusatz_beschreibung
                };
            }

            return Object.assign({}, state, <PageState> {
                selectedBereichDetail,
                selectedBereich
            });
        }

        case actionsUi.BEREICH_TITEL_SETZEN: {
            const a = <actionsUi.BereichTitelSetzenAction>action;

            const selectedBereichDetail = Object.assign({}, state.selectedBereichDetail, <BereichDetail> {
                kopfdaten: Object.assign({}, state.selectedBereichDetail.kopfdaten, <BereichKopfdaten> {
                    titel: a.payload
                })
            });

            return Object.assign({}, state, <PageState> {
                selectedBereichDetail
            });
        }

        case actionsUi.BEREICH_BESCHREIBUNG_SETZEN: {
            const a = <actionsUi.BereichBeschreibungSetzenAction>action;

            const selectedBereichDetail = Object.assign({}, state.selectedBereichDetail, <BereichDetail> {
                kopfdaten: Object.assign({}, state.selectedBereichDetail.kopfdaten, <BereichKopfdaten> {
                    beschreibung: a.payload
                })
            });

            return Object.assign({}, state, <PageState> {
                selectedBereichDetail
            });
        }

        case actionsUi.BEREICH_ZUSATZ_BESCHREIBUNG_SETZEN: {
            const a = <actionsUi.BereichZusatzBeschreibungSetzenAction>action;

            const selectedBereichDetail = Object.assign({}, state.selectedBereichDetail, <BereichDetail> {
                kopfdaten: Object.assign({}, state.selectedBereichDetail.kopfdaten, <BereichKopfdaten> {
                    zusatz_beschreibung: a.payload
                })
            });

            return Object.assign({}, state, <PageState> {
                selectedBereichDetail
            });
        }

        case actionsUi.BEREICH_IST_TRIGGER_LISTE_SETZEN: {
            const a = <actionsUi.BereichIstTriggerListeSetzenAction>action;

            let selectedBereichDetail = Object.assign({}, state.selectedBereichDetail);

            if (state.selectedBereichDetail.kopfdaten.ist_trigger_liste !== a.payload) {
                if (a.payload === true) {
                    selectedBereichDetail = Object.assign(selectedBereichDetail, <BereichDetail> {
                        kopfdaten: Object.assign({}, state.selectedBereichDetail.kopfdaten, <BereichKopfdaten> {
                            ist_trigger_liste: true,
                            ist_externe_url: false,
                            externe_url: ''
                        })
                    });
                } else {
                    selectedBereichDetail = Object.assign(selectedBereichDetail, <BereichDetail> {
                        kopfdaten: Object.assign({}, state.selectedBereichDetail.kopfdaten, <BereichKopfdaten> {
                            ist_trigger_liste: false,
                            ist_externe_url: true
                        })
                    });
                }
            }

            return Object.assign({}, state, <PageState> {
                selectedBereichDetail
            });
        }

        case actionsUi.BEREICH_IST_EXTERNE_URL_SETZEN: {
            const a = <actionsUi.BereichIstExterneURLSetzenAction>action;

            let selectedBereichDetail = Object.assign({}, state.selectedBereichDetail);

            if (state.selectedBereichDetail.kopfdaten.ist_externe_url !== a.payload) {
                if (a.payload === true) {
                    selectedBereichDetail = Object.assign(selectedBereichDetail, <BereichDetail> {
                        kopfdaten: Object.assign({}, state.selectedBereichDetail.kopfdaten, <BereichKopfdaten> {
                            ist_externe_url: true,
                            ist_trigger_liste: false
                        })
                    });
                } else {
                    selectedBereichDetail = Object.assign(selectedBereichDetail, <BereichDetail> {
                        kopfdaten: Object.assign({}, state.selectedBereichDetail.kopfdaten, <BereichKopfdaten> {
                            ist_trigger_liste: true,
                            ist_externe_url: false
                        })
                    });
                }
            }

            return Object.assign({}, state, <PageState> {
                selectedBereichDetail
            });
        }

        case actionsUi.BEREICH_EXTERNE_URL_SETZEN: {
            const a = <actionsUi.BereichExterneURLSetzenAction>action;

            const selectedBereichDetail = Object.assign({}, state.selectedBereichDetail, <BereichDetail> {
                kopfdaten: Object.assign({}, state.selectedBereichDetail.kopfdaten, <BereichKopfdaten> {
                    externe_url: a.payload
                })
            });

            return Object.assign({}, state, <PageState> {
                selectedBereichDetail
            });
        }

        case actionsUi.AKTUELLE_ROLLE_BERECHTIGUNG_SETZEN: {
            const a = <actionsUi.AktuelleRolleBerechtigungSetzenAction>action;

            return Object.assign({}, state, <PageState>{
                selectedRolle: a.payload
            });
        }

        case actionsUi.AKTUELLEN_TRIGGER_GESETZT: {
            const a = <actionsUi.AktuellenTriggerGesetztAction>action;

            return Object.assign({}, state, <PageState>{
                selectedTrigger: a.payload
            });
        }

        case actionsUi.AKTUELLEN_TRIGGER_ICON_GESETZT: {
            const a = <actionsUi.AktuellenTriggerIconGesetztAction>action;

            const selectedTrigger = Object.assign({}, state.selectedTrigger, <BereichTrigger> {
                icon: a.payload
            });

            return Object.assign({}, state, <PageState>{
                selectedTrigger
            });
        }

        default: {
            return state;
        }
    }
}
