import {Action, ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import {THEME_SETZEN, ThemeSetzenAction} from './actions';
import {Settings} from './settings.model';

export interface ModuleState {
    page: PageState;
}

export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer
};

export interface PageState {
    theme: Settings;
}

export const initialPageState: PageState = {
    theme: null
};

export function pageReducer(state = initialPageState, action: Action): PageState {
    switch (action.type) {
        // Page State Reset
        case THEME_SETZEN: {
            const a = <ThemeSetzenAction> action;

            return Object.assign({}, state, <PageState> {theme: a.settings});
        }

        default: {
            return state;
        }
    }
}

export const getModuleState = createFeatureSelector<ModuleState>('Settings');
export const getPageState = createSelector(getModuleState, (state: ModuleState) => state.page);

export const getTheme = createSelector(getPageState, (state: PageState) => state.theme);
