import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICH_OK_NOTIFICATION = '[VeranstaltungenModul] GetFunktionsbereichOkNotification laden';
export const GET_FUNKTIONSBEREICH_OK_NOTIFICATION_AUSFUEHREN = '[VeranstaltungenModul] GetFunktionsbereichOkNotification ausführen';
export const GET_FUNKTIONSBEREICH_OK_NOTIFICATION_ERFOLGREICH = '[VeranstaltungenModul] GetFunktionsbereichOkNotification laden erfolgreich';
export const GET_FUNKTIONSBEREICH_OK_NOTIFICATION_FEHLER = '[VeranstaltungenModul] GetFunktionsbereichOkNotification Ladefehler';
export const GET_FUNKTIONSBEREICH_OK_NOTIFICATION_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunktionsbereichOkNotification nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichOkNotificationAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_OK_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereichOkNotificationAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_OK_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereichOkNotificationErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_OK_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.FunktionsbereichOkNotification, public optPayload: any = null) {}
    }
export class GetFunktionsbereichOkNotificationFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_OK_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFunktionsbereichOkNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_OK_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunktionsbereichOkNotificationActions =
    GetFunktionsbereichOkNotificationAction
    | GetFunktionsbereichOkNotificationAusfuehrenAction
    | GetFunktionsbereichOkNotificationErfolgreichAction
    | GetFunktionsbereichOkNotificationFehlerAction
    | GetFunktionsbereichOkNotificationNichtAusgefuehrtAction
    ;
