import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const POST_MANDANT_CONFIG_SPEICHERN = '[ApiModul] PostMandantConfigSpeichern laden';
export const POST_MANDANT_CONFIG_SPEICHERN_AUSFUEHREN = '[ApiModul] PostMandantConfigSpeichern ausführen';
export const POST_MANDANT_CONFIG_SPEICHERN_ERFOLGREICH = '[ApiModul] PostMandantConfigSpeichern laden erfolgreich';
export const POST_MANDANT_CONFIG_SPEICHERN_FEHLER = '[ApiModul] PostMandantConfigSpeichern Ladefehler';
export const POST_MANDANT_CONFIG_SPEICHERN_NICHT_AUSGEFUEHRT = '[ApiModul] PostMandantConfigSpeichern nicht ausgeführt (evtl. Offline)';



export class PostMandantConfigSpeichernAction implements Action {
    readonly type = POST_MANDANT_CONFIG_SPEICHERN;
        constructor(public mandantConfigRequest: api.MandantConfigRequest, public optPayload: any = null) {}
    }
export class PostMandantConfigSpeichernAusfuehrenAction implements Action {
    readonly type = POST_MANDANT_CONFIG_SPEICHERN_AUSFUEHREN;
        constructor(public mandantConfigRequest: api.MandantConfigRequest, public optPayload: any = null) {}
    }
export class PostMandantConfigSpeichernErfolgreichAction implements Action {
    readonly type = POST_MANDANT_CONFIG_SPEICHERN_ERFOLGREICH;
        constructor(public payload: string, public mandantConfigRequest: api.MandantConfigRequest, public optPayload: any = null) {}
    }
export class PostMandantConfigSpeichernFehlerAction implements Action {
    readonly type = POST_MANDANT_CONFIG_SPEICHERN_FEHLER;
        constructor(public payload: any, public mandantConfigRequest: api.MandantConfigRequest, public optPayload: any = null) {}
    }
export class PostMandantConfigSpeichernNichtAusgefuehrtAction implements Action {
    readonly type = POST_MANDANT_CONFIG_SPEICHERN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiCommandMandantConfigSpeichernActions =
    PostMandantConfigSpeichernAction
    | PostMandantConfigSpeichernAusfuehrenAction
    | PostMandantConfigSpeichernErfolgreichAction
    | PostMandantConfigSpeichernFehlerAction
    | PostMandantConfigSpeichernNichtAusgefuehrtAction
    ;
