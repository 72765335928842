import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class EnvironmentOptions  implements IEnvironment {
  production: boolean;
  debug: boolean;
  appConfig: IAppConfig;
  azureAd: IAzureAdConfig;
  appTitle: string;
  loginRoute: string;
  homeRoute: string;
  version: string;
  testMode: boolean;
  mobile: IMobileConfig;
}

// export interface IAppRoutes {

// }

export interface IEnvironment {
    production: boolean;
    debug: boolean;
    appConfig: IAppConfig;
    azureAd: IAzureAdConfig;
    appTitle: string;
    loginRoute: string;
    homeRoute: string;
    version: string;
    testMode: boolean;
    mobile: IMobileConfig;
}

export interface IMobileConfig {
  testModeCordovaSimulator: boolean;
  ionicAppId: string;
  ionicChannel: string;
  autoUpdate: boolean;
}

export interface IAppConfig {
    apiUrl: string;
    wsEndpoint: string;
    appInsightsInstrumentationKey: string;
    themeUrl: string;
    themeType: string;
}

export interface IAzureAdConfig {
    clientId: string;
    clientId2: string;
    tenantId: string;
    resource: string;
    discoveryDocumentV1: string;    
    tenantMappings: { [key: string]: string; };
}


