import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_NICHT_OK_ERFASSEN = '[VeranstaltungenModul] PostAufgabeNichtOkErfassen laden';
export const POST_AUFGABE_NICHT_OK_ERFASSEN_AUSFUEHREN = '[VeranstaltungenModul] PostAufgabeNichtOkErfassen ausführen';
export const POST_AUFGABE_NICHT_OK_ERFASSEN_ERFOLGREICH = '[VeranstaltungenModul] PostAufgabeNichtOkErfassen laden erfolgreich';
export const POST_AUFGABE_NICHT_OK_ERFASSEN_FEHLER = '[VeranstaltungenModul] PostAufgabeNichtOkErfassen Ladefehler';
export const POST_AUFGABE_NICHT_OK_ERFASSEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostAufgabeNichtOkErfassen nicht ausgeführt (evtl. Offline)';



export class PostAufgabeNichtOkErfassenAction implements Action {
    readonly type = POST_AUFGABE_NICHT_OK_ERFASSEN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeNichtOkErfassenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_NICHT_OK_ERFASSEN_AUSFUEHREN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeNichtOkErfassenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_NICHT_OK_ERFASSEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeNichtOkErfassenFehlerAction implements Action {
    readonly type = POST_AUFGABE_NICHT_OK_ERFASSEN_FEHLER;
        constructor(public payload: any, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeNichtOkErfassenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_NICHT_OK_ERFASSEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandAufgabeNichtOkErfassenActions =
    PostAufgabeNichtOkErfassenAction
    | PostAufgabeNichtOkErfassenAusfuehrenAction
    | PostAufgabeNichtOkErfassenErfolgreichAction
    | PostAufgabeNichtOkErfassenFehlerAction
    | PostAufgabeNichtOkErfassenNichtAusgefuehrtAction
    ;
