import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_AUFGABE_NICHT_OK_NOTIFICATION = '[VeranstaltungenModul] GetAufgabeNichtOkNotification laden';
export const GET_AUFGABE_NICHT_OK_NOTIFICATION_AUSFUEHREN = '[VeranstaltungenModul] GetAufgabeNichtOkNotification ausführen';
export const GET_AUFGABE_NICHT_OK_NOTIFICATION_ERFOLGREICH = '[VeranstaltungenModul] GetAufgabeNichtOkNotification laden erfolgreich';
export const GET_AUFGABE_NICHT_OK_NOTIFICATION_FEHLER = '[VeranstaltungenModul] GetAufgabeNichtOkNotification Ladefehler';
export const GET_AUFGABE_NICHT_OK_NOTIFICATION_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetAufgabeNichtOkNotification nicht ausgeführt (evtl. Offline)';



export class GetAufgabeNichtOkNotificationAction implements Action {
    readonly type = GET_AUFGABE_NICHT_OK_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetAufgabeNichtOkNotificationAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_NICHT_OK_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetAufgabeNichtOkNotificationErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_NICHT_OK_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.AufgabeNichtOkNotification, public optPayload: any = null) {}
    }
export class GetAufgabeNichtOkNotificationFehlerAction implements Action {
    readonly type = GET_AUFGABE_NICHT_OK_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetAufgabeNichtOkNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_NICHT_OK_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryAufgabeNichtOkNotificationActions =
    GetAufgabeNichtOkNotificationAction
    | GetAufgabeNichtOkNotificationAusfuehrenAction
    | GetAufgabeNichtOkNotificationErfolgreichAction
    | GetAufgabeNichtOkNotificationFehlerAction
    | GetAufgabeNichtOkNotificationNichtAusgefuehrtAction
    ;
