import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_QS_CHECKS_BENUTZER_ZUGRIFF = '[QschecksModul] GetQsChecksBenutzerZugriff laden';
export const GET_QS_CHECKS_BENUTZER_ZUGRIFF_AUSFUEHREN = '[QschecksModul] GetQsChecksBenutzerZugriff ausführen';
export const GET_QS_CHECKS_BENUTZER_ZUGRIFF_ERFOLGREICH = '[QschecksModul] GetQsChecksBenutzerZugriff laden erfolgreich';
export const GET_QS_CHECKS_BENUTZER_ZUGRIFF_FEHLER = '[QschecksModul] GetQsChecksBenutzerZugriff Ladefehler';
export const GET_QS_CHECKS_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT = '[QschecksModul] GetQsChecksBenutzerZugriff nicht ausgeführt (evtl. Offline)';



export class GetQsChecksBenutzerZugriffAction implements Action {
    readonly type = GET_QS_CHECKS_BENUTZER_ZUGRIFF;
        constructor(public optPayload: any = null) {}
    }
export class GetQsChecksBenutzerZugriffAusfuehrenAction implements Action {
    readonly type = GET_QS_CHECKS_BENUTZER_ZUGRIFF_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetQsChecksBenutzerZugriffErfolgreichAction implements Action {
    readonly type = GET_QS_CHECKS_BENUTZER_ZUGRIFF_ERFOLGREICH;
        constructor(public payload: api.QsChecksBenutzerZugriff, public optPayload: any = null) {}
    }
export class GetQsChecksBenutzerZugriffFehlerAction implements Action {
    readonly type = GET_QS_CHECKS_BENUTZER_ZUGRIFF_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetQsChecksBenutzerZugriffNichtAusgefuehrtAction implements Action {
    readonly type = GET_QS_CHECKS_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryQsChecksBenutzerZugriffActions =
    GetQsChecksBenutzerZugriffAction
    | GetQsChecksBenutzerZugriffAusfuehrenAction
    | GetQsChecksBenutzerZugriffErfolgreichAction
    | GetQsChecksBenutzerZugriffFehlerAction
    | GetQsChecksBenutzerZugriffNichtAusgefuehrtAction
    ;
