import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_CHECKLISTE_FREIGABE_ROLLEN_ADMIN = '[VeranstaltungenModul] GetChecklisteFreigabeRollenAdmin laden';
export const GET_CHECKLISTE_FREIGABE_ROLLEN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetChecklisteFreigabeRollenAdmin ausführen';
export const GET_CHECKLISTE_FREIGABE_ROLLEN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetChecklisteFreigabeRollenAdmin laden erfolgreich';
export const GET_CHECKLISTE_FREIGABE_ROLLEN_ADMIN_FEHLER = '[VeranstaltungenModul] GetChecklisteFreigabeRollenAdmin Ladefehler';
export const GET_CHECKLISTE_FREIGABE_ROLLEN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetChecklisteFreigabeRollenAdmin nicht ausgeführt (evtl. Offline)';



export class GetChecklisteFreigabeRollenAdminAction implements Action {
    readonly type = GET_CHECKLISTE_FREIGABE_ROLLEN_ADMIN;
        constructor( public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteFreigabeRollenAdminAusfuehrenAction implements Action {
    readonly type = GET_CHECKLISTE_FREIGABE_ROLLEN_ADMIN_AUSFUEHREN;
        constructor( public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteFreigabeRollenAdminErfolgreichAction implements Action {
    readonly type = GET_CHECKLISTE_FREIGABE_ROLLEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichRolle>,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteFreigabeRollenAdminFehlerAction implements Action {
    readonly type = GET_CHECKLISTE_FREIGABE_ROLLEN_ADMIN_FEHLER;
        constructor(public payload: any,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteFreigabeRollenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_CHECKLISTE_FREIGABE_ROLLEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryChecklisteFreigabeRollenAdminActions =
    GetChecklisteFreigabeRollenAdminAction
    | GetChecklisteFreigabeRollenAdminAusfuehrenAction
    | GetChecklisteFreigabeRollenAdminErfolgreichAction
    | GetChecklisteFreigabeRollenAdminFehlerAction
    | GetChecklisteFreigabeRollenAdminNichtAusgefuehrtAction
    ;
