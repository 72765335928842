import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENT_BILD_IDS = '[IncidentsModul] GetIncidentBildIds laden';
export const GET_INCIDENT_BILD_IDS_AUSFUEHREN = '[IncidentsModul] GetIncidentBildIds ausführen';
export const GET_INCIDENT_BILD_IDS_ERFOLGREICH = '[IncidentsModul] GetIncidentBildIds laden erfolgreich';
export const GET_INCIDENT_BILD_IDS_FEHLER = '[IncidentsModul] GetIncidentBildIds Ladefehler';
export const GET_INCIDENT_BILD_IDS_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentBildIds nicht ausgeführt (evtl. Offline)';



export class GetIncidentBildIdsAction implements Action {
    readonly type = GET_INCIDENT_BILD_IDS;
        constructor( public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentBildIdsAusfuehrenAction implements Action {
    readonly type = GET_INCIDENT_BILD_IDS_AUSFUEHREN;
        constructor( public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentBildIdsErfolgreichAction implements Action {
    readonly type = GET_INCIDENT_BILD_IDS_ERFOLGREICH;
        constructor(public payload: api.IncidentBildsIds,  public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentBildIdsFehlerAction implements Action {
    readonly type = GET_INCIDENT_BILD_IDS_FEHLER;
        constructor(public payload: any,  public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentBildIdsNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENT_BILD_IDS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentBildIdsActions =
    GetIncidentBildIdsAction
    | GetIncidentBildIdsAusfuehrenAction
    | GetIncidentBildIdsErfolgreichAction
    | GetIncidentBildIdsFehlerAction
    | GetIncidentBildIdsNichtAusgefuehrtAction
    ;
