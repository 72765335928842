import {createFeatureSelector, createSelector} from '@ngrx/store';
import {Funktionsbereich} from '../../../clients/benutzerverwaltung';
import {ModuleState, PageState} from './rollenselektion.reducer';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';

export const getModuleState = createFeatureSelector<ModuleState>('RollenselektionPage');

export const getPageState = createSelector(getModuleState, (state: ModuleState) => state.page);

export const getNichtAnzuzeigendeRollen = createSelector(getPageState, (state: PageState) => state.nichtAnzuzeigendeRollen);

export const getAktuellenFunktionsbereich = createSelector(getPageState, (state: PageState) => state.aktuellerFunktionsbereich);

export const getAlleAnzeigen = createSelector(getPageState, (state: PageState) => state.uiAlleAnzeigen);

export const getRollenClean = createSelector(getPageState, getNichtAnzuzeigendeRollen, getAktuellenFunktionsbereich, getAlleAnzeigen,
        (state: PageState, nichtAnzuzeigendeRollen: Array<string>, aktuellerFunktionsbereich: Funktionsbereich, alleAnzeigen: boolean) => {
            const res: Array<ListInputItem> = [];
            state.funktionsbereicheRollen.forEach((fb) => {
                fb.rollen.forEach(rolle => {
                    res.push(<ListInputItem>{
                        groupId: fb.funktionsbereich.funktionsbereich_id,
                        groupTitle: fb.funktionsbereich.titel,
                        itemId: rolle.rolle_id,
                        itemTitle: rolle.titel,
                        item: rolle,
                    });
                });
            });

            const filteredRes = res.filter(item => {
                if (alleAnzeigen) {
                    return true;
                } else {
                    return aktuellerFunktionsbereich !== null && item.item.funktionsbereich_id === aktuellerFunktionsbereich.funktionsbereich_id;
                }
            }).filter(item => {
                const nichtAnzuzeigendeRolleId = nichtAnzuzeigendeRollen.filter(i => {
                    return i === item.itemId;
                });
                return nichtAnzuzeigendeRolleId.length === 0;
            });

            return filteredRes;
        }
);

export const getUiRollenLadeanimation = createSelector(getPageState, (state: PageState) => state.uiRollenLadeanimation);

export const getUiRollenLadeFehler = createSelector(getPageState, (state: PageState) => state.uiBenutzerLadeFehler);

export const getDatenquelle = createSelector(getPageState, (state: PageState) => state.uiDatenquelle);

export const getVeranstaltungsId = createSelector(getPageState, (state: PageState) => state.uiVeranstaltungsId);

export const getUiSelektierteRollen = createSelector(getPageState, (state: PageState) => state.uiSelektierteRollen);

export const getOneSelected = createSelector(getUiSelektierteRollen, (selektierteRollen: Array<string>): boolean => {
        return selektierteRollen.length > 0;
    }
);
