import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_DOKUMENT_BERECHTIGUNG = '[VorlagenModul] GetDokumentBerechtigung laden';
export const GET_DOKUMENT_BERECHTIGUNG_AUSFUEHREN = '[VorlagenModul] GetDokumentBerechtigung ausführen';
export const GET_DOKUMENT_BERECHTIGUNG_ERFOLGREICH = '[VorlagenModul] GetDokumentBerechtigung laden erfolgreich';
export const GET_DOKUMENT_BERECHTIGUNG_FEHLER = '[VorlagenModul] GetDokumentBerechtigung Ladefehler';
export const GET_DOKUMENT_BERECHTIGUNG_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetDokumentBerechtigung nicht ausgeführt (evtl. Offline)';



export class GetDokumentBerechtigungAction implements Action {
    readonly type = GET_DOKUMENT_BERECHTIGUNG;
        constructor( public dokument_id:string , public optPayload: any = null) {}
    }
export class GetDokumentBerechtigungAusfuehrenAction implements Action {
    readonly type = GET_DOKUMENT_BERECHTIGUNG_AUSFUEHREN;
        constructor( public dokument_id:string , public optPayload: any = null) {}
    }
export class GetDokumentBerechtigungErfolgreichAction implements Action {
    readonly type = GET_DOKUMENT_BERECHTIGUNG_ERFOLGREICH;
        constructor(public payload: api.DokumentBerechtigung,  public dokument_id:string , public optPayload: any = null) {}
    }
export class GetDokumentBerechtigungFehlerAction implements Action {
    readonly type = GET_DOKUMENT_BERECHTIGUNG_FEHLER;
        constructor(public payload: any,  public dokument_id:string , public optPayload: any = null) {}
    }
export class GetDokumentBerechtigungNichtAusgefuehrtAction implements Action {
    readonly type = GET_DOKUMENT_BERECHTIGUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryDokumentBerechtigungActions =
    GetDokumentBerechtigungAction
    | GetDokumentBerechtigungAusfuehrenAction
    | GetDokumentBerechtigungErfolgreichAction
    | GetDokumentBerechtigungFehlerAction
    | GetDokumentBerechtigungNichtAusgefuehrtAction
    ;
