import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_BENUTZERVERWALTUNG_BENUTZER_ZUGRIFF = '[BenutzerverwaltungModul] GetBenutzerverwaltungBenutzerZugriff laden';
export const GET_BENUTZERVERWALTUNG_BENUTZER_ZUGRIFF_AUSFUEHREN = '[BenutzerverwaltungModul] GetBenutzerverwaltungBenutzerZugriff ausführen';
export const GET_BENUTZERVERWALTUNG_BENUTZER_ZUGRIFF_ERFOLGREICH = '[BenutzerverwaltungModul] GetBenutzerverwaltungBenutzerZugriff laden erfolgreich';
export const GET_BENUTZERVERWALTUNG_BENUTZER_ZUGRIFF_FEHLER = '[BenutzerverwaltungModul] GetBenutzerverwaltungBenutzerZugriff Ladefehler';
export const GET_BENUTZERVERWALTUNG_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetBenutzerverwaltungBenutzerZugriff nicht ausgeführt (evtl. Offline)';



export class GetBenutzerverwaltungBenutzerZugriffAction implements Action {
    readonly type = GET_BENUTZERVERWALTUNG_BENUTZER_ZUGRIFF;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerverwaltungBenutzerZugriffAusfuehrenAction implements Action {
    readonly type = GET_BENUTZERVERWALTUNG_BENUTZER_ZUGRIFF_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerverwaltungBenutzerZugriffErfolgreichAction implements Action {
    readonly type = GET_BENUTZERVERWALTUNG_BENUTZER_ZUGRIFF_ERFOLGREICH;
        constructor(public payload: api.BenutzerverwaltungBenutzerZugriff, public optPayload: any = null) {}
    }
export class GetBenutzerverwaltungBenutzerZugriffFehlerAction implements Action {
    readonly type = GET_BENUTZERVERWALTUNG_BENUTZER_ZUGRIFF_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetBenutzerverwaltungBenutzerZugriffNichtAusgefuehrtAction implements Action {
    readonly type = GET_BENUTZERVERWALTUNG_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryBenutzerverwaltungBenutzerZugriffActions =
    GetBenutzerverwaltungBenutzerZugriffAction
    | GetBenutzerverwaltungBenutzerZugriffAusfuehrenAction
    | GetBenutzerverwaltungBenutzerZugriffErfolgreichAction
    | GetBenutzerverwaltungBenutzerZugriffFehlerAction
    | GetBenutzerverwaltungBenutzerZugriffNichtAusgefuehrtAction
    ;
