import {Action} from '@ngrx/store';
import {DatenquelleVeranstaltungsTypen} from './veranstaltungstyp-auswahl.model';
import {VeranstaltungsTyp} from '../../../clients/veranstaltungstypen';

export const PAGE_STATE_RESET = '[VeranstaltungstypAuswahlModal] Page State reset';
export const SELEKTIERTE_TYPEN = '[VeranstaltungstypAuswahlModal] Selektierte Typen IDs';
export const REFRESH_DATA = '[VeranstaltungstypAuswahlModal] Typen erneut laden';


// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;

    constructor(public datenquelle: DatenquelleVeranstaltungsTypen,
                public nichtAnzuzeigendeTypenIds: Array<string>) {
    }
}


// UI
export class SelektierteTypenAction implements Action {
    readonly type = SELEKTIERTE_TYPEN;

    //
    constructor(public payload: Array<string>) {
    }
}

export class RefreshDataAction implements Action {
    readonly type = REFRESH_DATA;

    constructor(public datenquelle: DatenquelleVeranstaltungsTypen) {
    }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
    PageStateResetAction
    | RefreshDataAction
    ;
