import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFCHECKLISTE_KOPIEREN = '[QschecksModul] PostPruefchecklisteKopieren laden';
export const POST_PRUEFCHECKLISTE_KOPIEREN_AUSFUEHREN = '[QschecksModul] PostPruefchecklisteKopieren ausführen';
export const POST_PRUEFCHECKLISTE_KOPIEREN_ERFOLGREICH = '[QschecksModul] PostPruefchecklisteKopieren laden erfolgreich';
export const POST_PRUEFCHECKLISTE_KOPIEREN_FEHLER = '[QschecksModul] PostPruefchecklisteKopieren Ladefehler';
export const POST_PRUEFCHECKLISTE_KOPIEREN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefchecklisteKopieren nicht ausgeführt (evtl. Offline)';



export class PostPruefchecklisteKopierenAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_KOPIEREN;
        constructor(public pruefchecklisteRequest: api.PruefchecklisteRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteKopierenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_KOPIEREN_AUSFUEHREN;
        constructor(public pruefchecklisteRequest: api.PruefchecklisteRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteKopierenErfolgreichAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_KOPIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefchecklisteRequest: api.PruefchecklisteRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteKopierenFehlerAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_KOPIEREN_FEHLER;
        constructor(public payload: any, public pruefchecklisteRequest: api.PruefchecklisteRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteKopierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_KOPIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefchecklisteKopierenActions =
    PostPruefchecklisteKopierenAction
    | PostPruefchecklisteKopierenAusfuehrenAction
    | PostPruefchecklisteKopierenErfolgreichAction
    | PostPruefchecklisteKopierenFehlerAction
    | PostPruefchecklisteKopierenNichtAusgefuehrtAction
    ;
