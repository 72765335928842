import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_QS_CHECKS_ROLLE_BERECHTIGUNGEN_SETZEN = '[QschecksModul] PostQsChecksRolleBerechtigungenSetzen laden';
export const POST_QS_CHECKS_ROLLE_BERECHTIGUNGEN_SETZEN_AUSFUEHREN = '[QschecksModul] PostQsChecksRolleBerechtigungenSetzen ausführen';
export const POST_QS_CHECKS_ROLLE_BERECHTIGUNGEN_SETZEN_ERFOLGREICH = '[QschecksModul] PostQsChecksRolleBerechtigungenSetzen laden erfolgreich';
export const POST_QS_CHECKS_ROLLE_BERECHTIGUNGEN_SETZEN_FEHLER = '[QschecksModul] PostQsChecksRolleBerechtigungenSetzen Ladefehler';
export const POST_QS_CHECKS_ROLLE_BERECHTIGUNGEN_SETZEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostQsChecksRolleBerechtigungenSetzen nicht ausgeführt (evtl. Offline)';



export class PostQsChecksRolleBerechtigungenSetzenAction implements Action {
    readonly type = POST_QS_CHECKS_ROLLE_BERECHTIGUNGEN_SETZEN;
        constructor(public qsChecksRolleBerechtigungenRequest: api.QsChecksRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostQsChecksRolleBerechtigungenSetzenAusfuehrenAction implements Action {
    readonly type = POST_QS_CHECKS_ROLLE_BERECHTIGUNGEN_SETZEN_AUSFUEHREN;
        constructor(public qsChecksRolleBerechtigungenRequest: api.QsChecksRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostQsChecksRolleBerechtigungenSetzenErfolgreichAction implements Action {
    readonly type = POST_QS_CHECKS_ROLLE_BERECHTIGUNGEN_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public qsChecksRolleBerechtigungenRequest: api.QsChecksRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostQsChecksRolleBerechtigungenSetzenFehlerAction implements Action {
    readonly type = POST_QS_CHECKS_ROLLE_BERECHTIGUNGEN_SETZEN_FEHLER;
        constructor(public payload: any, public qsChecksRolleBerechtigungenRequest: api.QsChecksRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostQsChecksRolleBerechtigungenSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_QS_CHECKS_ROLLE_BERECHTIGUNGEN_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandQsChecksRolleBerechtigungenSetzenActions =
    PostQsChecksRolleBerechtigungenSetzenAction
    | PostQsChecksRolleBerechtigungenSetzenAusfuehrenAction
    | PostQsChecksRolleBerechtigungenSetzenErfolgreichAction
    | PostQsChecksRolleBerechtigungenSetzenFehlerAction
    | PostQsChecksRolleBerechtigungenSetzenNichtAusgefuehrtAction
    ;
