import {ModuleWithProviders, NgModule} from '@angular/core';
import {EffectsModule} from '@ngrx/effects';
import {NaviEffects} from './navi.effects';
import {StoreModule} from '@ngrx/store';
import {naviReducer, StoreNaviFeature} from './navi.reducer';
import {VeranstaltungenVeranstaltungBenutzerZugriffEffects} from '../../client-ngrx/veranstaltungen';
import {BenutzerverwaltungBenutzerverwaltungBenutzerZugriffEffects} from '../../client-ngrx/benutzerverwaltung';
import {VorlagenVorlagenBenutzerZugriffEffects} from '../../client-ngrx/vorlagen';
import {IncidentsIncidentsBenutzerZugriffEffects} from '../../client-ngrx/incidents';
import {DisplaysteuerungDisplaysteuerungBenutzerZugriffEffects} from '../../client-ngrx/displaysteuerung';
import {EinlasskontrolleEinlasskontrolleBenutzerZugriffEffects} from '../../client-ngrx/einlasskontrolle';
import {QschecksQsChecksBenutzerZugriffEffects} from '../../client-ngrx/qschecks';
import {NaviWsEffects} from './navi.effects.ws';

@NgModule({
    imports: [
        StoreModule.forFeature(StoreNaviFeature, naviReducer),
        EffectsModule.forFeature([
            NaviEffects,
            NaviWsEffects,
            VeranstaltungenVeranstaltungBenutzerZugriffEffects,
            BenutzerverwaltungBenutzerverwaltungBenutzerZugriffEffects,
            VorlagenVorlagenBenutzerZugriffEffects,
            IncidentsIncidentsBenutzerZugriffEffects,
            DisplaysteuerungDisplaysteuerungBenutzerZugriffEffects,
            EinlasskontrolleEinlasskontrolleBenutzerZugriffEffects,
            QschecksQsChecksBenutzerZugriffEffects
        ]),
    ]
})
export class NaviModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: NaviModule
        };
    }
}
