import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFPLAN_SAISON_FILTER = '[QschecksModul] GetPruefplanSaisonFilter laden';
export const GET_PRUEFPLAN_SAISON_FILTER_AUSFUEHREN = '[QschecksModul] GetPruefplanSaisonFilter ausführen';
export const GET_PRUEFPLAN_SAISON_FILTER_ERFOLGREICH = '[QschecksModul] GetPruefplanSaisonFilter laden erfolgreich';
export const GET_PRUEFPLAN_SAISON_FILTER_FEHLER = '[QschecksModul] GetPruefplanSaisonFilter Ladefehler';
export const GET_PRUEFPLAN_SAISON_FILTER_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPruefplanSaisonFilter nicht ausgeführt (evtl. Offline)';



export class GetPruefplanSaisonFilterAction implements Action {
    readonly type = GET_PRUEFPLAN_SAISON_FILTER;
        constructor( public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanSaisonFilterAusfuehrenAction implements Action {
    readonly type = GET_PRUEFPLAN_SAISON_FILTER_AUSFUEHREN;
        constructor( public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanSaisonFilterErfolgreichAction implements Action {
    readonly type = GET_PRUEFPLAN_SAISON_FILTER_ERFOLGREICH;
        constructor(public payload: api.SaisonFilterOption,  public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanSaisonFilterFehlerAction implements Action {
    readonly type = GET_PRUEFPLAN_SAISON_FILTER_FEHLER;
        constructor(public payload: any,  public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanSaisonFilterNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFPLAN_SAISON_FILTER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPruefplanSaisonFilterActions =
    GetPruefplanSaisonFilterAction
    | GetPruefplanSaisonFilterAusfuehrenAction
    | GetPruefplanSaisonFilterErfolgreichAction
    | GetPruefplanSaisonFilterFehlerAction
    | GetPruefplanSaisonFilterNichtAusgefuehrtAction
    ;
