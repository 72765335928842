import {Action} from '@ngrx/store';
import {Benutzer} from '../../../clients/benutzerverwaltung';
import {SETZE_AUFGABE_ACTION, SETZE_FUNKTIONSBEREICH_CHECKLISTE_ACTION, SetzeAufgabeAction, SetzeFunktionsbereichChecklisteAction} from './actions/app-state.actions.ui';
import {
    GET_BENUTZER_INFO,
    GET_BENUTZER_INFO_ERFOLGREICH,
    GET_BENUTZER_INFO_FEHLER,
    GetBenutzerInfoErfolgreichAction,
    GetBenutzerInfoFehlerAction
} from '../../../client-ngrx/benutzerverwaltung';
import {Aufgabe, Checkliste, Funktionsbereich, Veranstaltung, VeranstaltungBerechtigung, VeranstaltungDetail, VeranstaltungMitteilungBerechtigung, VeranstaltungStatus} from '../../../clients/veranstaltungen';
import {AKTUELLE_VERANSTALTUNG_SETZEN, AktuelleVeranstaltungSetzenAction} from './actions/veranstaltung.actions';
import {
    GET_VERANSTALTUNG_BERECHTIGUNG, GET_VERANSTALTUNG_BERECHTIGUNG_ERFOLGREICH,
    GET_VERANSTALTUNG_BERECHTIGUNG_FEHLER,
    GET_VERANSTALTUNG_DECKBLATT,
    GET_VERANSTALTUNG_DECKBLATT_ERFOLGREICH,
    GET_VERANSTALTUNG_DECKBLATT_FEHLER, GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG, GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ERFOLGREICH, GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_FEHLER,
    GET_VERANSTALTUNG_STATUS,
    GET_VERANSTALTUNG_STATUS_ERFOLGREICH, GET_VERANSTALTUNG_STATUS_FEHLER,
    GET_VERANSTALTUNGEN_AKTUELLE,
    GET_VERANSTALTUNGEN_AKTUELLE_ERFOLGREICH,
    GET_VERANSTALTUNGEN_AKTUELLE_FEHLER, GetVeranstaltungBerechtigungErfolgreichAction,
    GetVeranstaltungDeckblattErfolgreichAction,
    GetVeranstaltungDeckblattFehlerAction,
    GetVeranstaltungenAktuelleErfolgreichAction,
    GetVeranstaltungenAktuelleFehlerAction, GetVeranstaltungMitteilungBerechtigungErfolgreichAction, GetVeranstaltungStatusErfolgreichAction, GetVeranstaltungStatusFehlerAction
} from '../../../client-ngrx/veranstaltungen';
import { APP_RESET_ACTION } from '@orga-app/appstart/actions';
import {MANDANT_SETZEN} from '@orga-app/mandant/actions';


export interface DatenState {
    benutzer: Benutzer | null;
    benutzerLaden: boolean;
    benutzerFehler: boolean;

    platform: string;

    aktuelleVeranstaltungen: Array<Veranstaltung> | null;
    aktuelleVeranstaltungenLaden: boolean;
    aktuelleVeranstaltungenFehler: boolean;

    veranstaltungBerechtigung: VeranstaltungBerechtigung | null;
    veranstaltungBerechtigungLaden: boolean;
    veranstaltungBerechtigungFehler: boolean;

    veranstaltungDeckblatt: VeranstaltungDetail | null;
    veranstaltungDeckblattLaden: boolean;
    veranstaltungDeckblattFehler: boolean;

    veranstaltungStatus: VeranstaltungStatus | null;
    veranstaltungStatusLaden: boolean;
    veranstaltungStatusFehler: boolean;

    veranstaltungMitteilungBerechtigung: VeranstaltungMitteilungBerechtigung | null;
    veranstaltungMitteilungBerechtigungLaden: boolean;
    veranstaltungMitteilungBerechtigungFehler: boolean;

    aktuelleVeranstaltung: Veranstaltung;

    aktuellerFunktionsbereich: Funktionsbereich;
    aktuelleCheckliste: Checkliste;
    aktuelleAufgabe: Aufgabe;

    pushRegistrationId: string;
}

export const initialDatenState: DatenState = {
    benutzer: null,
    benutzerLaden: false,
    benutzerFehler: false,

    platform: 'desktop',

    aktuelleVeranstaltungen: [],
    aktuelleVeranstaltungenLaden: false,
    aktuelleVeranstaltungenFehler: false,

    veranstaltungBerechtigung: null,
    veranstaltungBerechtigungLaden: false,
    veranstaltungBerechtigungFehler: false,

    veranstaltungDeckblatt: null,
    veranstaltungDeckblattLaden: false,
    veranstaltungDeckblattFehler: false,

    veranstaltungStatus: null,
    veranstaltungStatusLaden: false,
    veranstaltungStatusFehler: false,

    veranstaltungMitteilungBerechtigung: null,
    veranstaltungMitteilungBerechtigungLaden: false,
    veranstaltungMitteilungBerechtigungFehler: false,

    aktuelleVeranstaltung: null,

    aktuellerFunktionsbereich: null,
    aktuelleCheckliste: null,
    aktuelleAufgabe: null,

    pushRegistrationId: null
};

export function datenReducer(state = initialDatenState, action: Action): DatenState {
    switch (action.type) {

        // APP RESET
        case APP_RESET_ACTION: {
            return Object.assign({}, state, <DatenState> initialDatenState);
        }

        // Wenn der Mandant gewechselt wird, dann die Veranstaltung und alles weitere, bis auf die BenutzerInfo leeren
        case MANDANT_SETZEN: {
            return Object.assign({}, state, <DatenState> initialDatenState, <DatenState> {
                benutzer: state.benutzer,
                benutzerFehler: state.benutzerFehler,
                benutzerLaden: state.benutzerLaden
            });
        }

        // BENUTZER INFO
        case GET_BENUTZER_INFO: {
            return Object.assign({}, state, <DatenState> {
                benutzerLaden: true
            });
        }

        case GET_BENUTZER_INFO_ERFOLGREICH: {
            const a = <GetBenutzerInfoErfolgreichAction> action;

            return Object.assign({}, state, <DatenState> {
                benutzer: a.payload,
                benutzerLaden: false,
                benutzerFehler: false
            });
        }

        case GET_BENUTZER_INFO_FEHLER: {
            const a = <GetBenutzerInfoFehlerAction> action;

            return Object.assign({}, state, <DatenState> {
                benutzerLaden: false,
                benutzerFehler: true
            });
        }

        // AKTUELLE VERANSTALTUNGEN
        case GET_VERANSTALTUNGEN_AKTUELLE: {
            return Object.assign({}, state, <DatenState>{
                aktuelleVeranstaltungenLaden: true
            });
        }

        case GET_VERANSTALTUNGEN_AKTUELLE_ERFOLGREICH: {
            const a = <GetVeranstaltungenAktuelleErfolgreichAction>action;

            return Object.assign({}, state, <DatenState>{
                aktuelleVeranstaltungen: a.payload,
                aktuelleVeranstaltungenLaden: false,
                aktuelleVeranstaltungenFehler: false
            });
        }

        case GET_VERANSTALTUNGEN_AKTUELLE_FEHLER: {
            const a = <GetVeranstaltungenAktuelleFehlerAction>action;

            return Object.assign({}, state, <DatenState>{
                aktuelleVeranstaltungenLaden: false,
                aktuelleVeranstaltungenFehler: true
            });
        }

        // AKTUELLE VERANSTALTUNGEN DECKBLATT
        case GET_VERANSTALTUNG_DECKBLATT: {
            return Object.assign({}, state, <DatenState>{
                veranstaltungDeckblattLaden: true
            });
        }

        case GET_VERANSTALTUNG_DECKBLATT_ERFOLGREICH: {
            const a = <GetVeranstaltungDeckblattErfolgreichAction>action;

            return Object.assign({}, state, <DatenState>{
                veranstaltungDeckblatt: a.payload,
                veranstaltungDeckblattLaden: false,
                veranstaltungDeckblattFehler: false
            });
        }

        case GET_VERANSTALTUNG_DECKBLATT_FEHLER: {
            const a = <GetVeranstaltungDeckblattFehlerAction>action;

            return Object.assign({}, state, <DatenState>{
                veranstaltungDeckblattLaden: false,
                veranstaltungDeckblattFehler: true
            });
        }

        // AKTUELLE VERANSTALTUNGEN STATUS
        case GET_VERANSTALTUNG_STATUS: {
            return Object.assign({}, state, <DatenState>{
                veranstaltungStatusLaden: true
            });
        }

        case GET_VERANSTALTUNG_STATUS_ERFOLGREICH: {
            const a = <GetVeranstaltungStatusErfolgreichAction>action;

            return Object.assign({}, state, <DatenState>{
                veranstaltungStatus: a.payload,
                veranstaltungStatusLaden: false,
                veranstaltungStatusFehler: false
            });
        }

        case GET_VERANSTALTUNG_STATUS_FEHLER: {
            const a = <GetVeranstaltungStatusFehlerAction>action;

            return Object.assign({}, state, <DatenState>{
                veranstaltungStatusLaden: false,
                veranstaltungStatusFehler: true
            });
        }

        // AKTUELLE VERANSTALTUNGEN MITTEILUNG BERECHTIGUNGEN
        case GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG: {
            return Object.assign({}, state, <DatenState>{
                veranstaltungMitteilungBerechtigungLaden: true
            });
        }

        case GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ERFOLGREICH: {
            const a = <GetVeranstaltungMitteilungBerechtigungErfolgreichAction>action;

            return Object.assign({}, state, <DatenState>{
                veranstaltungMitteilungBerechtigung: a.payload,
                veranstaltungMitteilungBerechtigungLaden: false,
                veranstaltungMitteilungBerechtigungFehler: false
            });
        }

        case GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_FEHLER: {
            const a = <GetVeranstaltungStatusFehlerAction>action;

            return Object.assign({}, state, <DatenState>{
                veranstaltungMitteilungBerechtigungLaden: false,
                veranstaltungMitteilungBerechtigungFehler: true
            });
        }

        // VERANSTALTUNG BERECHTIGUNGEN
        // Berechtigungen der Veranstaltung laden
        case GET_VERANSTALTUNG_BERECHTIGUNG: {
            return Object.assign({}, state, <DatenState>{
                veranstaltungBerechtigungLaden: true
            });
        }

        case GET_VERANSTALTUNG_BERECHTIGUNG_ERFOLGREICH: {
            const a = <GetVeranstaltungBerechtigungErfolgreichAction>action;

            return Object.assign({}, state, <DatenState>{
                veranstaltungBerechtigung: a.payload,
                veranstaltungBerechtigungLaden: false,
                veranstaltungBerechtigungFehler: false
            });
        }

        case GET_VERANSTALTUNG_BERECHTIGUNG_FEHLER: {
            // Wenn der Benutzer Offline ist, sollten seine schon geladenen Daten erhalten bleiben
            let veranstaltungBerechtigung = null;
            if (state.veranstaltungBerechtigung !== null) {
                veranstaltungBerechtigung = Object.assign({}, state.veranstaltungBerechtigung);
            } else {
                veranstaltungBerechtigung = <VeranstaltungBerechtigung>{
                    benutzer_ist_veranstaltungsleiter: false,
                    veranstaltung_id: state.aktuelleVeranstaltung.veranstaltung_id
                };
            }
            return Object.assign({}, state, <DatenState>{
                veranstaltungBerechtigung: veranstaltungBerechtigung,
                veranstaltungBerechtigungLaden: false,
                veranstaltungBerechtigungFehler: true
            });
        }

        // Aktuelle Veranstaltung
        case AKTUELLE_VERANSTALTUNG_SETZEN: {
            const a = <AktuelleVeranstaltungSetzenAction>action;

            return Object.assign({}, state, <DatenState>{
                aktuelleVeranstaltung: a.payload
            });
        }

        // Aktueller Funktionsbereich und aktuelle Checkliste
        case SETZE_FUNKTIONSBEREICH_CHECKLISTE_ACTION: {
            const a = <SetzeFunktionsbereichChecklisteAction>action;

            return Object.assign({}, state, <DatenState>{
                aktuellerFunktionsbereich: a.funktionsbereich,
                aktuelleCheckliste: a.checkliste
            });
        }

        // AktuelleAufgabe
        case SETZE_AUFGABE_ACTION: {
            const a = <SetzeAufgabeAction>action;

            return Object.assign({}, state, <DatenState>{
                aktuelleAufgabe: a.aufgabe
            });
        }

        /*// PUSH REGISTRIERUNG
        case PUSH_REGISTRATION_ACTION: {
            const a = <PushRegistrationAction>action;

            return Object.assign({}, state, <DatenState>{
                pushRegistrationId: a.registrationEventResponse.registrationId
            });
        }*/

        default: {
            return state;
        }
    }
}
