import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICH_CHECKLISTEN_ADMIN = '[VeranstaltungenModul] GetFunktionsbereichChecklistenAdmin laden';
export const GET_FUNKTIONSBEREICH_CHECKLISTEN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetFunktionsbereichChecklistenAdmin ausführen';
export const GET_FUNKTIONSBEREICH_CHECKLISTEN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetFunktionsbereichChecklistenAdmin laden erfolgreich';
export const GET_FUNKTIONSBEREICH_CHECKLISTEN_ADMIN_FEHLER = '[VeranstaltungenModul] GetFunktionsbereichChecklistenAdmin Ladefehler';
export const GET_FUNKTIONSBEREICH_CHECKLISTEN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunktionsbereichChecklistenAdmin nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichChecklistenAdminAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_ADMIN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichChecklistenAdminAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_ADMIN_AUSFUEHREN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichChecklistenAdminErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.Checkliste>,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichChecklistenAdminFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_ADMIN_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichChecklistenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunktionsbereichChecklistenAdminActions =
    GetFunktionsbereichChecklistenAdminAction
    | GetFunktionsbereichChecklistenAdminAusfuehrenAction
    | GetFunktionsbereichChecklistenAdminErfolgreichAction
    | GetFunktionsbereichChecklistenAdminFehlerAction
    | GetFunktionsbereichChecklistenAdminNichtAusgefuehrtAction
    ;
