import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_AUFGABE_ERINNERUNG_NOTIFICATION = '[VeranstaltungenModul] GetAufgabeErinnerungNotification laden';
export const GET_AUFGABE_ERINNERUNG_NOTIFICATION_AUSFUEHREN = '[VeranstaltungenModul] GetAufgabeErinnerungNotification ausführen';
export const GET_AUFGABE_ERINNERUNG_NOTIFICATION_ERFOLGREICH = '[VeranstaltungenModul] GetAufgabeErinnerungNotification laden erfolgreich';
export const GET_AUFGABE_ERINNERUNG_NOTIFICATION_FEHLER = '[VeranstaltungenModul] GetAufgabeErinnerungNotification Ladefehler';
export const GET_AUFGABE_ERINNERUNG_NOTIFICATION_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetAufgabeErinnerungNotification nicht ausgeführt (evtl. Offline)';



export class GetAufgabeErinnerungNotificationAction implements Action {
    readonly type = GET_AUFGABE_ERINNERUNG_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetAufgabeErinnerungNotificationAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_ERINNERUNG_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetAufgabeErinnerungNotificationErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_ERINNERUNG_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.AufgabeErinnerungNotification, public optPayload: any = null) {}
    }
export class GetAufgabeErinnerungNotificationFehlerAction implements Action {
    readonly type = GET_AUFGABE_ERINNERUNG_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetAufgabeErinnerungNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_ERINNERUNG_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryAufgabeErinnerungNotificationActions =
    GetAufgabeErinnerungNotificationAction
    | GetAufgabeErinnerungNotificationAusfuehrenAction
    | GetAufgabeErinnerungNotificationErfolgreichAction
    | GetAufgabeErinnerungNotificationFehlerAction
    | GetAufgabeErinnerungNotificationNichtAusgefuehrtAction
    ;
