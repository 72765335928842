import * as api from '../../../clients/einlasskontrolle'; 

import {Action} from '@ngrx/store';

export const GET_EINLASSKONTROLLE_CORONA_CHECK_CODES = '[EinlasskontrolleModul] GetEinlasskontrolleCoronaCheckCodes laden';
export const GET_EINLASSKONTROLLE_CORONA_CHECK_CODES_AUSFUEHREN = '[EinlasskontrolleModul] GetEinlasskontrolleCoronaCheckCodes ausführen';
export const GET_EINLASSKONTROLLE_CORONA_CHECK_CODES_ERFOLGREICH = '[EinlasskontrolleModul] GetEinlasskontrolleCoronaCheckCodes laden erfolgreich';
export const GET_EINLASSKONTROLLE_CORONA_CHECK_CODES_FEHLER = '[EinlasskontrolleModul] GetEinlasskontrolleCoronaCheckCodes Ladefehler';
export const GET_EINLASSKONTROLLE_CORONA_CHECK_CODES_NICHT_AUSGEFUEHRT = '[EinlasskontrolleModul] GetEinlasskontrolleCoronaCheckCodes nicht ausgeführt (evtl. Offline)';



export class GetEinlasskontrolleCoronaCheckCodesAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CORONA_CHECK_CODES;
        constructor(public optPayload: any = null) {}
    }
export class GetEinlasskontrolleCoronaCheckCodesAusfuehrenAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CORONA_CHECK_CODES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetEinlasskontrolleCoronaCheckCodesErfolgreichAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CORONA_CHECK_CODES_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetEinlasskontrolleCoronaCheckCodesFehlerAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CORONA_CHECK_CODES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetEinlasskontrolleCoronaCheckCodesNichtAusgefuehrtAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_CORONA_CHECK_CODES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type EinlasskontrolleQueryEinlasskontrolleCoronaCheckCodesActions =
    GetEinlasskontrolleCoronaCheckCodesAction
    | GetEinlasskontrolleCoronaCheckCodesAusfuehrenAction
    | GetEinlasskontrolleCoronaCheckCodesErfolgreichAction
    | GetEinlasskontrolleCoronaCheckCodesFehlerAction
    | GetEinlasskontrolleCoronaCheckCodesNichtAusgefuehrtAction
    ;
