import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_ROLLE_LOESCHEN = '[BenutzerverwaltungModul] PostRolleLoeschen laden';
export const POST_ROLLE_LOESCHEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostRolleLoeschen ausführen';
export const POST_ROLLE_LOESCHEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostRolleLoeschen laden erfolgreich';
export const POST_ROLLE_LOESCHEN_FEHLER = '[BenutzerverwaltungModul] PostRolleLoeschen Ladefehler';
export const POST_ROLLE_LOESCHEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostRolleLoeschen nicht ausgeführt (evtl. Offline)';



export class PostRolleLoeschenAction implements Action {
    readonly type = POST_ROLLE_LOESCHEN;
        constructor(public rolleRequest: api.RolleRequest, public optPayload: any = null) {}
    }
export class PostRolleLoeschenAusfuehrenAction implements Action {
    readonly type = POST_ROLLE_LOESCHEN_AUSFUEHREN;
        constructor(public rolleRequest: api.RolleRequest, public optPayload: any = null) {}
    }
export class PostRolleLoeschenErfolgreichAction implements Action {
    readonly type = POST_ROLLE_LOESCHEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public rolleRequest: api.RolleRequest, public optPayload: any = null) {}
    }
export class PostRolleLoeschenFehlerAction implements Action {
    readonly type = POST_ROLLE_LOESCHEN_FEHLER;
        constructor(public payload: any, public rolleRequest: api.RolleRequest, public optPayload: any = null) {}
    }
export class PostRolleLoeschenNichtAusgefuehrtAction implements Action {
    readonly type = POST_ROLLE_LOESCHEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandRolleLoeschenActions =
    PostRolleLoeschenAction
    | PostRolleLoeschenAusfuehrenAction
    | PostRolleLoeschenErfolgreichAction
    | PostRolleLoeschenFehlerAction
    | PostRolleLoeschenNichtAusgefuehrtAction
    ;
