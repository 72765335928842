import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_DIENSTPLAN_ADMIN = '[VeranstaltungenModul] GetVeranstaltungDienstplanAdmin laden';
export const GET_VERANSTALTUNG_DIENSTPLAN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungDienstplanAdmin ausführen';
export const GET_VERANSTALTUNG_DIENSTPLAN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungDienstplanAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_DIENSTPLAN_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungDienstplanAdmin Ladefehler';
export const GET_VERANSTALTUNG_DIENSTPLAN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungDienstplanAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungDienstplanAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_DIENSTPLAN_ADMIN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDienstplanAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_DIENSTPLAN_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDienstplanAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_DIENSTPLAN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.DienstplanFunktionsbereich>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDienstplanAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_DIENSTPLAN_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDienstplanAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_DIENSTPLAN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungDienstplanAdminActions =
    GetVeranstaltungDienstplanAdminAction
    | GetVeranstaltungDienstplanAdminAusfuehrenAction
    | GetVeranstaltungDienstplanAdminErfolgreichAction
    | GetVeranstaltungDienstplanAdminFehlerAction
    | GetVeranstaltungDienstplanAdminNichtAusgefuehrtAction
    ;
