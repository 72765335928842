import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_KOPFDATEN_AKTUALISIEREN = '[VeranstaltungenModul] PostVeranstaltungKopfdatenAktualisieren laden';
export const POST_VERANSTALTUNG_KOPFDATEN_AKTUALISIEREN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungKopfdatenAktualisieren ausführen';
export const POST_VERANSTALTUNG_KOPFDATEN_AKTUALISIEREN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungKopfdatenAktualisieren laden erfolgreich';
export const POST_VERANSTALTUNG_KOPFDATEN_AKTUALISIEREN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungKopfdatenAktualisieren Ladefehler';
export const POST_VERANSTALTUNG_KOPFDATEN_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungKopfdatenAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungKopfdatenAktualisierenAction implements Action {
    readonly type = POST_VERANSTALTUNG_KOPFDATEN_AKTUALISIEREN;
        constructor(public veranstaltungKopfdatenAktualisierenRequest: api.VeranstaltungKopfdatenAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungKopfdatenAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_KOPFDATEN_AKTUALISIEREN_AUSFUEHREN;
        constructor(public veranstaltungKopfdatenAktualisierenRequest: api.VeranstaltungKopfdatenAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungKopfdatenAktualisierenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_KOPFDATEN_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungKopfdatenAktualisierenRequest: api.VeranstaltungKopfdatenAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungKopfdatenAktualisierenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_KOPFDATEN_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public veranstaltungKopfdatenAktualisierenRequest: api.VeranstaltungKopfdatenAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungKopfdatenAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_KOPFDATEN_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungKopfdatenAktualisierenActions =
    PostVeranstaltungKopfdatenAktualisierenAction
    | PostVeranstaltungKopfdatenAktualisierenAusfuehrenAction
    | PostVeranstaltungKopfdatenAktualisierenErfolgreichAction
    | PostVeranstaltungKopfdatenAktualisierenFehlerAction
    | PostVeranstaltungKopfdatenAktualisierenNichtAusgefuehrtAction
    ;
