import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_FREIGEBEN = '[VeranstaltungenModul] PostVeranstaltungFreigeben laden';
export const POST_VERANSTALTUNG_FREIGEBEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungFreigeben ausführen';
export const POST_VERANSTALTUNG_FREIGEBEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungFreigeben laden erfolgreich';
export const POST_VERANSTALTUNG_FREIGEBEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungFreigeben Ladefehler';
export const POST_VERANSTALTUNG_FREIGEBEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungFreigeben nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungFreigebenAction implements Action {
    readonly type = POST_VERANSTALTUNG_FREIGEBEN;
        constructor(public veranstaltungPhaseRequest: api.VeranstaltungPhaseRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungFreigebenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_FREIGEBEN_AUSFUEHREN;
        constructor(public veranstaltungPhaseRequest: api.VeranstaltungPhaseRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungFreigebenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_FREIGEBEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungPhaseRequest: api.VeranstaltungPhaseRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungFreigebenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_FREIGEBEN_FEHLER;
        constructor(public payload: any, public veranstaltungPhaseRequest: api.VeranstaltungPhaseRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungFreigebenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_FREIGEBEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungFreigebenActions =
    PostVeranstaltungFreigebenAction
    | PostVeranstaltungFreigebenAusfuehrenAction
    | PostVeranstaltungFreigebenErfolgreichAction
    | PostVeranstaltungFreigebenFehlerAction
    | PostVeranstaltungFreigebenNichtAusgefuehrtAction
    ;
