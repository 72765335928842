

import {Action} from '@ngrx/store';

export const GET_INCIDENT_BILD_SCALED = '[IncidentsModul] GetIncidentBildScaled laden';
export const GET_INCIDENT_BILD_SCALED_AUSFUEHREN = '[IncidentsModul] GetIncidentBildScaled ausführen';
export const GET_INCIDENT_BILD_SCALED_ERFOLGREICH = '[IncidentsModul] GetIncidentBildScaled laden erfolgreich';
export const GET_INCIDENT_BILD_SCALED_FEHLER = '[IncidentsModul] GetIncidentBildScaled Ladefehler';
export const GET_INCIDENT_BILD_SCALED_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentBildScaled nicht ausgeführt (evtl. Offline)';



export class GetIncidentBildScaledAction implements Action {
    readonly type = GET_INCIDENT_BILD_SCALED;
        constructor( public incident_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetIncidentBildScaledAusfuehrenAction implements Action {
    readonly type = GET_INCIDENT_BILD_SCALED_AUSFUEHREN;
        constructor( public incident_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetIncidentBildScaledErfolgreichAction implements Action {
    readonly type = GET_INCIDENT_BILD_SCALED_ERFOLGREICH;
        constructor( public incident_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetIncidentBildScaledFehlerAction implements Action {
    readonly type = GET_INCIDENT_BILD_SCALED_FEHLER;
        constructor(public payload: any,  public incident_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetIncidentBildScaledNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENT_BILD_SCALED_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentBildScaledActions =
    GetIncidentBildScaledAction
    | GetIncidentBildScaledAusfuehrenAction
    | GetIncidentBildScaledErfolgreichAction
    | GetIncidentBildScaledFehlerAction
    | GetIncidentBildScaledNichtAusgefuehrtAction
    ;
