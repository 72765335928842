import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_CHECKLISTE_ANLEGEN = '[VorlagenModul] PostChecklisteAnlegen laden';
export const POST_CHECKLISTE_ANLEGEN_AUSFUEHREN = '[VorlagenModul] PostChecklisteAnlegen ausführen';
export const POST_CHECKLISTE_ANLEGEN_ERFOLGREICH = '[VorlagenModul] PostChecklisteAnlegen laden erfolgreich';
export const POST_CHECKLISTE_ANLEGEN_FEHLER = '[VorlagenModul] PostChecklisteAnlegen Ladefehler';
export const POST_CHECKLISTE_ANLEGEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostChecklisteAnlegen nicht ausgeführt (evtl. Offline)';



export class PostChecklisteAnlegenAction implements Action {
    readonly type = POST_CHECKLISTE_ANLEGEN;
        constructor(public checklisteModelRequest: api.ChecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostChecklisteAnlegenAusfuehrenAction implements Action {
    readonly type = POST_CHECKLISTE_ANLEGEN_AUSFUEHREN;
        constructor(public checklisteModelRequest: api.ChecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostChecklisteAnlegenErfolgreichAction implements Action {
    readonly type = POST_CHECKLISTE_ANLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public checklisteModelRequest: api.ChecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostChecklisteAnlegenFehlerAction implements Action {
    readonly type = POST_CHECKLISTE_ANLEGEN_FEHLER;
        constructor(public payload: any, public checklisteModelRequest: api.ChecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostChecklisteAnlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_CHECKLISTE_ANLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandChecklisteAnlegenActions =
    PostChecklisteAnlegenAction
    | PostChecklisteAnlegenAusfuehrenAction
    | PostChecklisteAnlegenErfolgreichAction
    | PostChecklisteAnlegenFehlerAction
    | PostChecklisteAnlegenNichtAusgefuehrtAction
    ;
