import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {LoadingController} from '@ionic/angular';
import {catchError, filter, flatMap, map, tap, withLatestFrom} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {MandantInfo} from '@orga-app/clients/api';

import {Storage} from '@ionic/storage';
import {getMandant} from '@orga-app/mandant/reducer';
import {MANDANT_SETZEN, MandantSetzenAction} from '@orga-app/mandant/actions';
import {
    localstorageKeyAppId,
    localstorageKeySettingsPrefix,
    localstorageKeyThemeSettings,
    localstorageKeyThemesUserSettings,
    SettingsProvider
} from './settings.service';
import {of} from 'rxjs';
import {Settings, Themes} from './settings.model';
import {
    GET_FILE_ACTION,
    GetFileAction,
    GetFileErfolgreichAction,
    THEME_FILE_GELADEN, THEME_REFRESH, THEME_RELOAD_IMAGES,
    ThemeFileGeladenAction, ThemeRefreshAction, ThemeReloadImagesAction,
    ThemeSetzenAction
} from './actions';
import {START_APP_ACTION} from '@orga-app/appstart/actions';
import {AUTH_LOGOUT_ERFOLGREICH} from '@orga-app/auth/actions';

declare const localforage;
declare const originMappingList;
declare const window;

@Injectable()
export class SettingsEffects {
    public loading = null;

    @Effect()
    getThemes$ = this.actions$.pipe(
        // ofType<MandantSetzenAction>(MANDANT_SETZEN),
        ofType<any>(MANDANT_SETZEN, START_APP_ACTION),
        withLatestFrom(this.store.pipe(select(getMandant))),
        filter(([x, mandant]: [MandantSetzenAction, MandantInfo]) => mandant !== null),
        flatMap(([x, mandant]: [MandantSetzenAction, MandantInfo]) => {
            return this.settingsProvider.getTheme(mandant.mandant_id).pipe(
                map((themes: Themes) => {
                    console.debug('SETTINGS :: ', themes);
                    return new ThemeFileGeladenAction(themes, mandant.mandant_id);
                }),
                catchError((error: any) => {
                    console.debug('SETTINGS :: Failed :: ', error);
                    return of(error);
                })
            );
        })
    );

    @Effect()
    refreshThemes$ = this.actions$.pipe(
        ofType<any>(THEME_REFRESH),
        withLatestFrom(this.store.pipe(select(getMandant))),
        filter(([x, mandant]: [ThemeRefreshAction, MandantInfo]) => mandant !== null),
        flatMap(([x, mandant]: [ThemeRefreshAction, MandantInfo]) => {
            return this.settingsProvider.getTheme(mandant.mandant_id).pipe(
                map((themes: Themes) => {
                    console.debug('SETTINGS :: ', themes);
                    return new ThemeFileGeladenAction(themes, mandant.mandant_id, true);
                }),
                catchError((error: any) => {
                    console.debug('SETTINGS :: Failed :: ', error);
                    return of(error);
                })
            );
        })
    );

    @Effect({dispatch: false})
    reloadImages$ = this.actions$.pipe(
        ofType<any>(THEME_RELOAD_IMAGES),
        withLatestFrom(this.store.pipe(select(getMandant))),
        filter(([x, mandant]: [ThemeReloadImagesAction, MandantInfo]) => mandant !== null),
        tap(([x, mandant]: [ThemeReloadImagesAction, MandantInfo]) => {
            this.settingsProvider.reloadImages(mandant.mandant_id);
        })
    );

    @Effect()
    resetThemes$ = this.actions$.pipe(
        ofType<any>(AUTH_LOGOUT_ERFOLGREICH),
        withLatestFrom(this.store.pipe(select(getMandant))),
        filter(([x, mandant]: [MandantSetzenAction, MandantInfo]) => {
            const identifier = this.getAppIdentifier();
            return originMappingList[identifier] && (mandant === null || originMappingList[identifier] !== mandant.mandant_id);
        }),
        flatMap(([x, mandant]: [MandantSetzenAction, MandantInfo]) => {
            const identifier = this.getAppIdentifier();
            return this.settingsProvider.getTheme(originMappingList[identifier]).pipe(
                map((themes: Themes) => {
                    console.debug('SETTINGS :: ', themes);
                    return new ThemeFileGeladenAction(themes, originMappingList[identifier]);
                }),
                catchError((error: any) => {
                    console.debug('SETTINGS :: Failed :: ', error);
                    return of(error);
                })
            );
        })
    );

    @Effect()
    getLadeBilder$ = this.actions$.pipe(
        ofType<ThemeFileGeladenAction>(THEME_FILE_GELADEN),
        withLatestFrom(this.settingsProvider.getThemes()),
        filter(([x, themes]: [ThemeFileGeladenAction, Themes]) => {
            return themes.versionId !== x.themes.versionId || x.themes && x.themes.versionId == null || x.forceUpdate;
        }),
        flatMap(([x, themes]: [ThemeFileGeladenAction, Themes]) => {
            const res = [];
            let theme: Settings = null;
            let defaultTheme = null;
            const mandantId = x.mandantId;

            if (x.themes == null) {
                // KEIN THEME FÜR MANDANT HINTERLEGT
                document.body.classList.forEach(function (key, value) {
                    if (key.startsWith('theme-')) {
                        document.body.classList.remove(key);
                    }
                });
                document.body.classList.add('theme-orga-hell');

                // this.settingsProvider.deleteThemeSettings();
                // this.settingsProvider.reloadImages();
            } else {
                if (x.themes !== null && x.themes.names !== null && x.themes.names.length > 0) {
                    let userSettings = JSON.parse(localStorage.getItem(localstorageKeyThemesUserSettings));
                    const themeSettings = JSON.parse(localStorage.getItem(localstorageKeyThemeSettings));
                    userSettings = (userSettings == null) ? {} : userSettings;
                    if (themeSettings !== null) {
                        theme = x.themes.names.find(name => name.value === themeSettings.value);
                    }
                    if (theme == null) {
                        defaultTheme = x.themes.names.filter(y => y.isDefault);
                        if (defaultTheme.length > 0) {
                            theme = defaultTheme[0];
                        } else {
                            theme = x.themes.names[0];
                        }
                    }
                }

                // save new themes
                this.settingsProvider.setThemes(x.themes);

                // save data for all themes
                x.themes.files.forEach(file => {
                    // res.push(new GetFileAction(mandantId, file, file, true, true, true));
                    // TODO XXXXXXX
                    res.push(new GetFileAction(mandantId, file, file, false, true, true));
                });

                x.themes.names.forEach((themeVariante: Settings) => {
                    res.push(new GetFileAction(mandantId, themeVariante.loginBackground, themeVariante.value + '::loginBackground', false, true, false, 'loginBackground', (themeVariante.value === theme.value)));
                    res.push(new GetFileAction(mandantId, themeVariante.updateBackground, themeVariante.value + '::updateBackground', false, true, false, 'updateBackground', (themeVariante.value === theme.value)));
                    res.push(new GetFileAction(mandantId, themeVariante.logoSVG, themeVariante.value + '::logoSVG', false, true, false, 'logoSVG', (themeVariante.value === theme.value)));
                    res.push(new GetFileAction(mandantId, themeVariante.logoHeaderSVG, themeVariante.value + '::logoHeaderSVG', false, true, false, 'logoHeaderSVG', (themeVariante.value === theme.value)));
                    res.push(new GetFileAction(mandantId, themeVariante.loadingBackground, themeVariante.value + '::loadingBackground', false, true, false, 'loginBackground', (themeVariante.value === theme.value)));
                });

                if (theme !== null) {
                    const currentTheme = localStorage.getItem(localstorageKeyThemeSettings);
                    document.body.classList.remove('theme-orga-hell');
                    if (currentTheme !== null) {
                        document.body.classList.remove(JSON.parse(currentTheme).value);
                        document.body.classList.add(theme.value);
                    } else {
                        document.body.classList.add(theme.value);
                    }
                    localStorage.setItem(localstorageKeyThemeSettings, JSON.stringify(theme));
                    res.push(new ThemeSetzenAction(theme));
                }
            }
            return res;
        })
    );

    @Effect()
    getLadeFile$ = this.actions$.pipe(
        ofType<GetFileAction>(GET_FILE_ACTION),
        flatMap((x: GetFileAction) => {
            // return this.settingsProvider.getFile(mandant.mandant_id, x.file).pipe(
            return this.settingsProvider.getFile(x.mandantId, x.file).pipe(
                map((result) => {

                    if (x.storeLocalstorage) {
                        var fileReader = new FileReader();
                        fileReader.onload = function (evt: any) {
                            // Read out file contents as a Data URL
                            var result = evt.target.result;
                            // Store Data URL in localStorage
                            try {
                                try {
                                    if (x.appendFileToHeader) {
                                        this.appendFiledToHeader(result);
                                    }
                                    localStorage.setItem(localstorageKeySettingsPrefix + 'File::' + x.key, result);
                                    console.debug('SETTINGS :: Localstorage belegt in kB:', this.getLocalstorageSize());
                                } catch (domException) {
                                    if (
                                        ['QuotaExceededError', 'NS_ERROR_DOM_QUOTA_REACHED'].includes(
                                            domException.name
                                        )
                                    ) {
                                        // handle quota limit exceeded error
                                        console.debug('SETTINGS :: handle quota limit exceeded error');
                                    }
                                }
                            } catch (e) {
                                console.debug('SETTINGS :: Storage failed: ' + e);
                            }
                        }.bind(this);
                        // Load blob as Data URL
                        fileReader.readAsDataURL(result);
                    }

                    if (x.storeIonicStorage) {
                        localforage.setItem(localstorageKeySettingsPrefix + 'File::' + x.mandantId + '::' + x.key, result);

                        // add css files to html header
                        if (x.appendFileToHeader) {
                            const reader = new FileReader();
                            reader.readAsDataURL(result);
                            reader.onloadend = function () {
                                this.appendFiledToHeader(reader.result);
                            }.bind(this);
                        }

                        if (x.imageType !== null) {
                            this.settingsProvider.reloadThemeSettings();
                            if (x.imageType === 'loginBackground' && x.isDefault) {
                                this.settingsProvider.setImageLoginBackground(result);
                            }
                            if (x.imageType === 'updateBackground' && x.isDefault) {
                                this.settingsProvider.setImageUpdateBackground(result);
                            }
                            if (x.imageType === 'logoSVG' && x.isDefault) {
                                this.settingsProvider.setImageLogoSVG(result);
                            }
                            if (x.imageType === 'logoHeaderSVG' && x.isDefault) {
                                this.settingsProvider.setImageLogoHeaderSVG(result);
                            }
                            if (x.imageType === 'loginBackground' && x.isDefault) {
                                this.settingsProvider.setImageLoginBackground(result);
                            }
                        }
                    }
                    return new GetFileErfolgreichAction();
                }),
                catchError((error: any) => {
                    console.debug('SETTINGS :: Failed :: ', error);
                    return of(error);
                })
            );
        })
    );


    constructor(private actions$: Actions,
                public loadingCtrl: LoadingController,
                private settingsProvider: SettingsProvider,
                // private storage: Storage,
                public store: Store<any>) {
    }

    getLocalstorageSize() {
        let _lsTotal = 0, _xLen, _x;

        for (_x in localStorage) {
            if (!localStorage.hasOwnProperty(_x)) {
                continue;
            }
            _xLen = ((localStorage[_x].length + _x.length) * 2);
            _lsTotal += _xLen;
            // console.debug(_x.substr(0, 50) + ' = ' + (_xLen / 1024).toFixed(2) + ' KB')
        }
        // console.debug('Total = ' + (_lsTotal / 1024).toFixed(2) + ' KB');
        return (_lsTotal / 1024);
    }

    appendFiledToHeader(base64Url: string) {
        const link = document.createElement('link');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('type', 'text/css');
        link.setAttribute('href', base64Url);
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    getAppIdentifier() {
        return (localStorage.getItem(localstorageKeyAppId) !== null && localStorage.getItem(localstorageKeyAppId) !== undefined) ? localStorage.getItem(localstorageKeyAppId) : window.origin;
    }
}
