import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_ENTITY_TYPES = '[BenutzerverwaltungModul] GetEntityTypes laden';
export const GET_ENTITY_TYPES_AUSFUEHREN = '[BenutzerverwaltungModul] GetEntityTypes ausführen';
export const GET_ENTITY_TYPES_ERFOLGREICH = '[BenutzerverwaltungModul] GetEntityTypes laden erfolgreich';
export const GET_ENTITY_TYPES_FEHLER = '[BenutzerverwaltungModul] GetEntityTypes Ladefehler';
export const GET_ENTITY_TYPES_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetEntityTypes nicht ausgeführt (evtl. Offline)';



export class GetEntityTypesAction implements Action {
    readonly type = GET_ENTITY_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetEntityTypesAusfuehrenAction implements Action {
    readonly type = GET_ENTITY_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetEntityTypesErfolgreichAction implements Action {
    readonly type = GET_ENTITY_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.EntityTypeInfo>, public optPayload: any = null) {}
    }
export class GetEntityTypesFehlerAction implements Action {
    readonly type = GET_ENTITY_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetEntityTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_ENTITY_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryEntityTypesActions =
    GetEntityTypesAction
    | GetEntityTypesAusfuehrenAction
    | GetEntityTypesErfolgreichAction
    | GetEntityTypesFehlerAction
    | GetEntityTypesNichtAusgefuehrtAction
    ;
