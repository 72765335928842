import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_DOKUMENTE = '[VorlagenModul] GetDokumente laden';
export const GET_DOKUMENTE_AUSFUEHREN = '[VorlagenModul] GetDokumente ausführen';
export const GET_DOKUMENTE_ERFOLGREICH = '[VorlagenModul] GetDokumente laden erfolgreich';
export const GET_DOKUMENTE_FEHLER = '[VorlagenModul] GetDokumente Ladefehler';
export const GET_DOKUMENTE_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetDokumente nicht ausgeführt (evtl. Offline)';



export class GetDokumenteAction implements Action {
    readonly type = GET_DOKUMENTE;
        constructor(public optPayload: any = null) {}
    }
export class GetDokumenteAusfuehrenAction implements Action {
    readonly type = GET_DOKUMENTE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetDokumenteErfolgreichAction implements Action {
    readonly type = GET_DOKUMENTE_ERFOLGREICH;
        constructor(public payload: Array<api.Dokument>, public optPayload: any = null) {}
    }
export class GetDokumenteFehlerAction implements Action {
    readonly type = GET_DOKUMENTE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetDokumenteNichtAusgefuehrtAction implements Action {
    readonly type = GET_DOKUMENTE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryDokumenteActions =
    GetDokumenteAction
    | GetDokumenteAusfuehrenAction
    | GetDokumenteErfolgreichAction
    | GetDokumenteFehlerAction
    | GetDokumenteNichtAusgefuehrtAction
    ;
