import {RouterModule, Routes} from '@angular/router';
import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {StoreModule} from '@ngrx/store';
import {reducers} from './displaysteuerung.reducer';
import {EffectsModule} from '@ngrx/effects';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {SopiComponentsModule} from '../../shared/sopi-components/sopi-components.module';
import {
    DisplaysteuerungBereichDetailAdminEffects,
    DisplaysteuerungBereicheListeAdminEffects, DisplaysteuerungBereichEntfernenEffects,
    DisplaysteuerungBereichKopfdatenAktualisierenEffects,
    DisplaysteuerungBereichLogoAktualisierenEffects,
    DisplaysteuerungBereichRolleEntfernenEffects,
    DisplaysteuerungBereichRolleHinzufuegenMultiEffects,
    DisplaysteuerungBereichTriggerAktualisierenEffects, DisplaysteuerungBereichTriggerAusloesenEffects,
    DisplaysteuerungBereichTriggerEntfernenEffects,
    DisplaysteuerungBereichTriggerHinzufuegenEffects,
    DisplaysteuerungBereichTriggerReihenfolgeSetzenEffects
} from '../../../client-ngrx/displaysteuerung';
import {DisplaysteuerungEffectsWs} from './displaysteuerung.effects.ws';
import {DisplaysteuerungEffectsUi} from './displaysteuerung.effect.ui';
import {DisplaysteuerungPage} from './displaysteuerung.page';
import {PipesModule} from '../../../sopi/pipes/pipes.module';

const routes: Routes = [
    {
        path: '',
        component: DisplaysteuerungPage,
        data: {title: 'Bereiche verwalten'}
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        TranslateModule,
        StoreModule.forFeature('DisplaysteuerungPage', reducers),
        EffectsModule.forFeature([
            DisplaysteuerungEffectsUi,
            DisplaysteuerungEffectsWs,

            DisplaysteuerungBereicheListeAdminEffects,
            DisplaysteuerungBereichDetailAdminEffects,

            DisplaysteuerungBereichEntfernenEffects,
            DisplaysteuerungBereichKopfdatenAktualisierenEffects,
            DisplaysteuerungBereichLogoAktualisierenEffects,
            DisplaysteuerungBereichRolleHinzufuegenMultiEffects,
            DisplaysteuerungBereichRolleEntfernenEffects,

            DisplaysteuerungBereichTriggerAktualisierenEffects,
            DisplaysteuerungBereichTriggerEntfernenEffects,
            DisplaysteuerungBereichTriggerHinzufuegenEffects,
            DisplaysteuerungBereichTriggerReihenfolgeSetzenEffects,
            DisplaysteuerungBereichTriggerAusloesenEffects
        ]),
        SopiScrollModule,
        DragDropModule,
        SopiComponentsModule.forRoot(),
        PipesModule
    ],
    declarations: [DisplaysteuerungPage],
    entryComponents: [DisplaysteuerungPage],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DisplaysteuerungPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: DisplaysteuerungPageModule,
        };
    }
}
