import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.query.notification-types';
import * as api from '../../../clients/incidents';


    @Injectable()
    export class IncidentsNotificationTypesEffects {

        @Effect()
            NotificationTypes$ = this.actions$.pipe(
                ofType(ac.GET_NOTIFICATION_TYPES),
                flatMap((x: ac.GetNotificationTypesAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().getNotificationTypes().pipe(
                    map((result: any) => {
                        const nextAction = new ac.GetNotificationTypesErfolgreichAction(result,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.GetNotificationTypesFehlerAction(error,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.IncidentsTemplatesService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.IncidentsTemplatesService {
                if (this.service === null) {
                    this.service = this.injector.get(api.IncidentsTemplatesService);
                }
                return this.service;
            }
        
}
