import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sopi-top',
  templateUrl: './sopi-dockpanel-top.component.html',
  styleUrls: ['./sopi-dockpanel-top.component.scss'],
})
export class SopiDockpanelTopComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
