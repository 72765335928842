

import {Action} from '@ngrx/store';

export const GET_MANDANT_LOGO_SCALED = '[ApiModul] GetMandantLogoScaled laden';
export const GET_MANDANT_LOGO_SCALED_AUSFUEHREN = '[ApiModul] GetMandantLogoScaled ausführen';
export const GET_MANDANT_LOGO_SCALED_ERFOLGREICH = '[ApiModul] GetMandantLogoScaled laden erfolgreich';
export const GET_MANDANT_LOGO_SCALED_FEHLER = '[ApiModul] GetMandantLogoScaled Ladefehler';
export const GET_MANDANT_LOGO_SCALED_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantLogoScaled nicht ausgeführt (evtl. Offline)';



export class GetMandantLogoScaledAction implements Action {
    readonly type = GET_MANDANT_LOGO_SCALED;
        constructor( public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetMandantLogoScaledAusfuehrenAction implements Action {
    readonly type = GET_MANDANT_LOGO_SCALED_AUSFUEHREN;
        constructor( public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetMandantLogoScaledErfolgreichAction implements Action {
    readonly type = GET_MANDANT_LOGO_SCALED_ERFOLGREICH;
        constructor( public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetMandantLogoScaledFehlerAction implements Action {
    readonly type = GET_MANDANT_LOGO_SCALED_FEHLER;
        constructor(public payload: any,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetMandantLogoScaledNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANT_LOGO_SCALED_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantLogoScaledActions =
    GetMandantLogoScaledAction
    | GetMandantLogoScaledAusfuehrenAction
    | GetMandantLogoScaledErfolgreichAction
    | GetMandantLogoScaledFehlerAction
    | GetMandantLogoScaledNichtAusgefuehrtAction
    ;
