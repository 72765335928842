import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFPLAN_HINZUFUEGEN = '[QschecksModul] PostPruefplanHinzufuegen laden';
export const POST_PRUEFPLAN_HINZUFUEGEN_AUSFUEHREN = '[QschecksModul] PostPruefplanHinzufuegen ausführen';
export const POST_PRUEFPLAN_HINZUFUEGEN_ERFOLGREICH = '[QschecksModul] PostPruefplanHinzufuegen laden erfolgreich';
export const POST_PRUEFPLAN_HINZUFUEGEN_FEHLER = '[QschecksModul] PostPruefplanHinzufuegen Ladefehler';
export const POST_PRUEFPLAN_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefplanHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostPruefplanHinzufuegenAction implements Action {
    readonly type = POST_PRUEFPLAN_HINZUFUEGEN;
        constructor(public pruefplanModelRequest: api.PruefplanModelRequest, public optPayload: any = null) {}
    }
export class PostPruefplanHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFPLAN_HINZUFUEGEN_AUSFUEHREN;
        constructor(public pruefplanModelRequest: api.PruefplanModelRequest, public optPayload: any = null) {}
    }
export class PostPruefplanHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_PRUEFPLAN_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefplanModelRequest: api.PruefplanModelRequest, public optPayload: any = null) {}
    }
export class PostPruefplanHinzufuegenFehlerAction implements Action {
    readonly type = POST_PRUEFPLAN_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public pruefplanModelRequest: api.PruefplanModelRequest, public optPayload: any = null) {}
    }
export class PostPruefplanHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFPLAN_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefplanHinzufuegenActions =
    PostPruefplanHinzufuegenAction
    | PostPruefplanHinzufuegenAusfuehrenAction
    | PostPruefplanHinzufuegenErfolgreichAction
    | PostPruefplanHinzufuegenFehlerAction
    | PostPruefplanHinzufuegenNichtAusgefuehrtAction
    ;
