import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_BENUTZER_INCIDENT_MANAGEMENT_SICHTBARKEIT_ADMIN = '[BenutzerverwaltungModul] PostBenutzerIncidentManagementSichtbarkeitAdmin laden';
export const POST_BENUTZER_INCIDENT_MANAGEMENT_SICHTBARKEIT_ADMIN_AUSFUEHREN = '[BenutzerverwaltungModul] PostBenutzerIncidentManagementSichtbarkeitAdmin ausführen';
export const POST_BENUTZER_INCIDENT_MANAGEMENT_SICHTBARKEIT_ADMIN_ERFOLGREICH = '[BenutzerverwaltungModul] PostBenutzerIncidentManagementSichtbarkeitAdmin laden erfolgreich';
export const POST_BENUTZER_INCIDENT_MANAGEMENT_SICHTBARKEIT_ADMIN_FEHLER = '[BenutzerverwaltungModul] PostBenutzerIncidentManagementSichtbarkeitAdmin Ladefehler';
export const POST_BENUTZER_INCIDENT_MANAGEMENT_SICHTBARKEIT_ADMIN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostBenutzerIncidentManagementSichtbarkeitAdmin nicht ausgeführt (evtl. Offline)';



export class PostBenutzerIncidentManagementSichtbarkeitAdminAction implements Action {
    readonly type = POST_BENUTZER_INCIDENT_MANAGEMENT_SICHTBARKEIT_ADMIN;
        constructor(public benutzerSichtbarkeitRequest: api.BenutzerSichtbarkeitRequest, public optPayload: any = null) {}
    }
export class PostBenutzerIncidentManagementSichtbarkeitAdminAusfuehrenAction implements Action {
    readonly type = POST_BENUTZER_INCIDENT_MANAGEMENT_SICHTBARKEIT_ADMIN_AUSFUEHREN;
        constructor(public benutzerSichtbarkeitRequest: api.BenutzerSichtbarkeitRequest, public optPayload: any = null) {}
    }
export class PostBenutzerIncidentManagementSichtbarkeitAdminErfolgreichAction implements Action {
    readonly type = POST_BENUTZER_INCIDENT_MANAGEMENT_SICHTBARKEIT_ADMIN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public benutzerSichtbarkeitRequest: api.BenutzerSichtbarkeitRequest, public optPayload: any = null) {}
    }
export class PostBenutzerIncidentManagementSichtbarkeitAdminFehlerAction implements Action {
    readonly type = POST_BENUTZER_INCIDENT_MANAGEMENT_SICHTBARKEIT_ADMIN_FEHLER;
        constructor(public payload: any, public benutzerSichtbarkeitRequest: api.BenutzerSichtbarkeitRequest, public optPayload: any = null) {}
    }
export class PostBenutzerIncidentManagementSichtbarkeitAdminNichtAusgefuehrtAction implements Action {
    readonly type = POST_BENUTZER_INCIDENT_MANAGEMENT_SICHTBARKEIT_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandBenutzerIncidentManagementSichtbarkeitAdminActions =
    PostBenutzerIncidentManagementSichtbarkeitAdminAction
    | PostBenutzerIncidentManagementSichtbarkeitAdminAusfuehrenAction
    | PostBenutzerIncidentManagementSichtbarkeitAdminErfolgreichAction
    | PostBenutzerIncidentManagementSichtbarkeitAdminFehlerAction
    | PostBenutzerIncidentManagementSichtbarkeitAdminNichtAusgefuehrtAction
    ;
