import {Component, Input, OnInit} from '@angular/core';
import {ModalController, NavController, NavParams} from '@ionic/angular';

@Component({
  selector: 'app-checkliste-loeschen',
  templateUrl: './checkliste-loeschen.page.html',
  styleUrls: ['./checkliste-loeschen.page.scss'],
})
export class ChecklisteLoeschenPage implements OnInit {
  private aiName = 'ChecklisteLoeschenPage';
  @Input() titel: string;

  constructor(public navCtrl: NavController,
              public navParams: NavParams,
              private modalCtrl: ModalController) {
  }

  ngOnInit() {
  }


  ok() {
    this.modalCtrl.dismiss('ok');
  }

  abbrechen() {
    this.modalCtrl.dismiss();
  }

}
