import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {VeranstaltungstypAuswahlPage} from './veranstaltungstyp-auswahl.page';
import {StoreModule} from '@ngrx/store';
import {reducers} from './veranstaltungstyp-auswahl.reducer';
import {EffectsModule} from '@ngrx/effects';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {VeranstaltungstypAuswahlPageUiEffects} from './veranstaltungstyp-auswahl.effects.ui';
import {SopiComponentsModule} from 'src/app/shared/sopi-components/sopi-components.module';
import {KommunikationMitteilungsVorlageEmpfaengerRollenEntfernenEffects, KommunikationMitteilungsVorlageEmpfaengerRollenHinzufuegenEffects, KommunikationMitteilungsVorlageVeranstaltungsTypenEffects, KommunikationMitteilungsVorlageVeranstaltungsTypenEntfernenEffects, KommunikationMitteilungsVorlageVeranstaltungsTypenHinzufuegenEffects} from '../../../client-ngrx/kommunikation';
import {VeranstaltungenVeranstaltungsTypenAdminEffects} from '../../../client-ngrx/veranstaltungen';
import {VeranstaltungstypenVeranstaltungsTypenAktivEffects} from '../../../client-ngrx/veranstaltungstypen';

const routes: Routes = [
    {
        path: '',
        component: VeranstaltungstypAuswahlPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature('VeranstaltungstypAuswahlPage', reducers),
        EffectsModule.forFeature([
            VeranstaltungstypAuswahlPageUiEffects,

            KommunikationMitteilungsVorlageVeranstaltungsTypenEffects,
            VeranstaltungenVeranstaltungsTypenAdminEffects,
            KommunikationMitteilungsVorlageEmpfaengerRollenHinzufuegenEffects,
            KommunikationMitteilungsVorlageEmpfaengerRollenEntfernenEffects,
            KommunikationMitteilungsVorlageVeranstaltungsTypenHinzufuegenEffects,
            KommunikationMitteilungsVorlageVeranstaltungsTypenEntfernenEffects,

            VeranstaltungstypenVeranstaltungsTypenAktivEffects
        ]),
        SopiScrollModule,
        SopiComponentsModule.forRoot()
    ],
    declarations: [VeranstaltungstypAuswahlPage],
    entryComponents: [VeranstaltungstypAuswahlPage],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VeranstaltungstypAuswahlPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: VeranstaltungstypAuswahlPageModule,
        };
    }
}
