import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_AUFGABE_BEMERKUNG = '[VeranstaltungenModul] GetAufgabeBemerkung laden';
export const GET_AUFGABE_BEMERKUNG_AUSFUEHREN = '[VeranstaltungenModul] GetAufgabeBemerkung ausführen';
export const GET_AUFGABE_BEMERKUNG_ERFOLGREICH = '[VeranstaltungenModul] GetAufgabeBemerkung laden erfolgreich';
export const GET_AUFGABE_BEMERKUNG_FEHLER = '[VeranstaltungenModul] GetAufgabeBemerkung Ladefehler';
export const GET_AUFGABE_BEMERKUNG_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetAufgabeBemerkung nicht ausgeführt (evtl. Offline)';



export class GetAufgabeBemerkungAction implements Action {
    readonly type = GET_AUFGABE_BEMERKUNG;
        constructor( public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeBemerkungAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_BEMERKUNG_AUSFUEHREN;
        constructor( public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeBemerkungErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_BEMERKUNG_ERFOLGREICH;
        constructor(public payload: api.AufgabeBemerkung,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeBemerkungFehlerAction implements Action {
    readonly type = GET_AUFGABE_BEMERKUNG_FEHLER;
        constructor(public payload: any,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeBemerkungNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_BEMERKUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryAufgabeBemerkungActions =
    GetAufgabeBemerkungAction
    | GetAufgabeBemerkungAusfuehrenAction
    | GetAufgabeBemerkungErfolgreichAction
    | GetAufgabeBemerkungFehlerAction
    | GetAufgabeBemerkungNichtAusgefuehrtAction
    ;
