import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_MITTEILUNGEN = '[KommunikationModul] GetMitteilungen laden';
export const GET_MITTEILUNGEN_AUSFUEHREN = '[KommunikationModul] GetMitteilungen ausführen';
export const GET_MITTEILUNGEN_ERFOLGREICH = '[KommunikationModul] GetMitteilungen laden erfolgreich';
export const GET_MITTEILUNGEN_FEHLER = '[KommunikationModul] GetMitteilungen Ladefehler';
export const GET_MITTEILUNGEN_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetMitteilungen nicht ausgeführt (evtl. Offline)';



export class GetMitteilungenAction implements Action {
    readonly type = GET_MITTEILUNGEN;
        constructor( public korrelation_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenAusfuehrenAction implements Action {
    readonly type = GET_MITTEILUNGEN_AUSFUEHREN;
        constructor( public korrelation_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenErfolgreichAction implements Action {
    readonly type = GET_MITTEILUNGEN_ERFOLGREICH;
        constructor(public payload: Array<api.Mitteilung>,  public korrelation_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenFehlerAction implements Action {
    readonly type = GET_MITTEILUNGEN_FEHLER;
        constructor(public payload: any,  public korrelation_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenNichtAusgefuehrtAction implements Action {
    readonly type = GET_MITTEILUNGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryMitteilungenActions =
    GetMitteilungenAction
    | GetMitteilungenAusfuehrenAction
    | GetMitteilungenErfolgreichAction
    | GetMitteilungenFehlerAction
    | GetMitteilungenNichtAusgefuehrtAction
    ;
