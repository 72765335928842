

import {Action} from '@ngrx/store';

export const GET_HOME_TIME_ZONE = '[ApiModul] GetHomeTimeZone laden';
export const GET_HOME_TIME_ZONE_AUSFUEHREN = '[ApiModul] GetHomeTimeZone ausführen';
export const GET_HOME_TIME_ZONE_ERFOLGREICH = '[ApiModul] GetHomeTimeZone laden erfolgreich';
export const GET_HOME_TIME_ZONE_FEHLER = '[ApiModul] GetHomeTimeZone Ladefehler';
export const GET_HOME_TIME_ZONE_NICHT_AUSGEFUEHRT = '[ApiModul] GetHomeTimeZone nicht ausgeführt (evtl. Offline)';



export class GetHomeTimeZoneAction implements Action {
    readonly type = GET_HOME_TIME_ZONE;
        constructor(public optPayload: any = null) {}
    }
export class GetHomeTimeZoneAusfuehrenAction implements Action {
    readonly type = GET_HOME_TIME_ZONE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetHomeTimeZoneErfolgreichAction implements Action {
    readonly type = GET_HOME_TIME_ZONE_ERFOLGREICH;
        constructor(public optPayload: any = null) {}
    }
export class GetHomeTimeZoneFehlerAction implements Action {
    readonly type = GET_HOME_TIME_ZONE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetHomeTimeZoneNichtAusgefuehrtAction implements Action {
    readonly type = GET_HOME_TIME_ZONE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryHomeTimeZoneActions =
    GetHomeTimeZoneAction
    | GetHomeTimeZoneAusfuehrenAction
    | GetHomeTimeZoneErfolgreichAction
    | GetHomeTimeZoneFehlerAction
    | GetHomeTimeZoneNichtAusgefuehrtAction
    ;
