import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENTS_VERANSTALTUNG_COUNTER = '[IncidentsModul] GetIncidentsVeranstaltungCounter laden';
export const GET_INCIDENTS_VERANSTALTUNG_COUNTER_AUSFUEHREN = '[IncidentsModul] GetIncidentsVeranstaltungCounter ausführen';
export const GET_INCIDENTS_VERANSTALTUNG_COUNTER_ERFOLGREICH = '[IncidentsModul] GetIncidentsVeranstaltungCounter laden erfolgreich';
export const GET_INCIDENTS_VERANSTALTUNG_COUNTER_FEHLER = '[IncidentsModul] GetIncidentsVeranstaltungCounter Ladefehler';
export const GET_INCIDENTS_VERANSTALTUNG_COUNTER_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentsVeranstaltungCounter nicht ausgeführt (evtl. Offline)';



export class GetIncidentsVeranstaltungCounterAction implements Action {
    readonly type = GET_INCIDENTS_VERANSTALTUNG_COUNTER;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetIncidentsVeranstaltungCounterAusfuehrenAction implements Action {
    readonly type = GET_INCIDENTS_VERANSTALTUNG_COUNTER_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetIncidentsVeranstaltungCounterErfolgreichAction implements Action {
    readonly type = GET_INCIDENTS_VERANSTALTUNG_COUNTER_ERFOLGREICH;
        constructor(public payload: api.IncidentsCounter,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetIncidentsVeranstaltungCounterFehlerAction implements Action {
    readonly type = GET_INCIDENTS_VERANSTALTUNG_COUNTER_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetIncidentsVeranstaltungCounterNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENTS_VERANSTALTUNG_COUNTER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentsVeranstaltungCounterActions =
    GetIncidentsVeranstaltungCounterAction
    | GetIncidentsVeranstaltungCounterAusfuehrenAction
    | GetIncidentsVeranstaltungCounterErfolgreichAction
    | GetIncidentsVeranstaltungCounterFehlerAction
    | GetIncidentsVeranstaltungCounterNichtAusgefuehrtAction
    ;
