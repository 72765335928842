import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_BENUTZER_LISTE = '[BenutzerverwaltungModul] GetBenutzerListe laden';
export const GET_BENUTZER_LISTE_AUSFUEHREN = '[BenutzerverwaltungModul] GetBenutzerListe ausführen';
export const GET_BENUTZER_LISTE_ERFOLGREICH = '[BenutzerverwaltungModul] GetBenutzerListe laden erfolgreich';
export const GET_BENUTZER_LISTE_FEHLER = '[BenutzerverwaltungModul] GetBenutzerListe Ladefehler';
export const GET_BENUTZER_LISTE_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetBenutzerListe nicht ausgeführt (evtl. Offline)';



export class GetBenutzerListeAction implements Action {
    readonly type = GET_BENUTZER_LISTE;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerListeAusfuehrenAction implements Action {
    readonly type = GET_BENUTZER_LISTE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerListeErfolgreichAction implements Action {
    readonly type = GET_BENUTZER_LISTE_ERFOLGREICH;
        constructor(public payload: Array<api.Benutzer>, public optPayload: any = null) {}
    }
export class GetBenutzerListeFehlerAction implements Action {
    readonly type = GET_BENUTZER_LISTE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetBenutzerListeNichtAusgefuehrtAction implements Action {
    readonly type = GET_BENUTZER_LISTE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryBenutzerListeActions =
    GetBenutzerListeAction
    | GetBenutzerListeAusfuehrenAction
    | GetBenutzerListeErfolgreichAction
    | GetBenutzerListeFehlerAction
    | GetBenutzerListeNichtAusgefuehrtAction
    ;
