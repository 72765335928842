import {Directive, ElementRef, HostListener, OnInit,  Renderer2} from '@angular/core';

@Directive({
  selector: "ion-textarea[clearTextarea]" // Attribute selector
})
export class ClearTextareaDirective implements OnInit {

  public clickListener: () => void;
  public cb: HTMLElement;

  constructor(public element: ElementRef, private renderer: Renderer2) {
  }

  // ngAfterViewInit()
  // ngAfterContentInit()
  ngOnInit() {

    // add the clear button
    const clearButton = this.renderer.createElement('button');
    this.renderer.addClass(clearButton, 'input-clear-icon');
    this.renderer.addClass(clearButton, 'sc-ion-input-md');
    this.renderer.setAttribute(clearButton, 'type', 'button');
    this.renderer.setAttribute(clearButton, 'tabindex', '-1');
    this.renderer.setStyle(clearButton, 'position', 'absolute');
    this.renderer.setStyle(clearButton, 'top', '3px');
    this.renderer.setStyle(clearButton, 'right', '0');

    if (!this.clickListener) {
      this.clickListener = this.renderer.listen(clearButton, 'click', (clickEvent) => {
        this.element.nativeElement.value = '';
        clearButton.style.visibility = 'hidden';
      });
    }

    this.renderer.appendChild(this.element.nativeElement, clearButton);

    this.cb = clearButton;

  }


  @HostListener('ionFocus', ['$event'])
  @HostListener('ionBlur', ['$event'])
  @HostListener('input', ['$event'])
  async onEvent(event) {

    const ta: HTMLElement = this.element.nativeElement.querySelector('textarea');

    // on focus or input change event
    if (event.type === 'ionFocus' || event.type === 'input') {

      if (ta) {

        // event.preventDefault();
        // event.stopPropagation();

        const disabled = this.element.nativeElement.getAttribute('disabled');
        const readonly = this.element.nativeElement.getAttribute('readonly');

        if (this.element.nativeElement.value !== '' && ((disabled === null && readonly === null) || (disabled === undefined && readonly === undefined))) {
          this.renderer.setAttribute(ta, 'style', 'padding-right: 38px !important');
          this.renderer.setStyle(this.cb, 'visibility', 'visible');
        } else {
          this.cb.style.visibility = 'hidden';
        }

      }
    } else

    // on blur event
    if (event.type === 'ionBlur') {

      // if (this.cb) {
      //   this.cb.style.visibility = 'hidden';
      // }

      setTimeout(function () {
        this.cb.style.visibility = 'hidden';
      }.bind(this), 240);

      // if (this.clickListener) {
      //   this.clickListener();
      // }
    } else {
      // console.log(event);
    }
  }

}
