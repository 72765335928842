import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEF_ANTWORT_AKTUALISIEREN = '[QschecksModul] PostPruefAntwortAktualisieren laden';
export const POST_PRUEF_ANTWORT_AKTUALISIEREN_AUSFUEHREN = '[QschecksModul] PostPruefAntwortAktualisieren ausführen';
export const POST_PRUEF_ANTWORT_AKTUALISIEREN_ERFOLGREICH = '[QschecksModul] PostPruefAntwortAktualisieren laden erfolgreich';
export const POST_PRUEF_ANTWORT_AKTUALISIEREN_FEHLER = '[QschecksModul] PostPruefAntwortAktualisieren Ladefehler';
export const POST_PRUEF_ANTWORT_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefAntwortAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostPruefAntwortAktualisierenAction implements Action {
    readonly type = POST_PRUEF_ANTWORT_AKTUALISIEREN;
        constructor(public pruefAntwortModelRequest: api.PruefAntwortModelRequest, public optPayload: any = null) {}
    }
export class PostPruefAntwortAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_PRUEF_ANTWORT_AKTUALISIEREN_AUSFUEHREN;
        constructor(public pruefAntwortModelRequest: api.PruefAntwortModelRequest, public optPayload: any = null) {}
    }
export class PostPruefAntwortAktualisierenErfolgreichAction implements Action {
    readonly type = POST_PRUEF_ANTWORT_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefAntwortModelRequest: api.PruefAntwortModelRequest, public optPayload: any = null) {}
    }
export class PostPruefAntwortAktualisierenFehlerAction implements Action {
    readonly type = POST_PRUEF_ANTWORT_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public pruefAntwortModelRequest: api.PruefAntwortModelRequest, public optPayload: any = null) {}
    }
export class PostPruefAntwortAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEF_ANTWORT_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefAntwortAktualisierenActions =
    PostPruefAntwortAktualisierenAction
    | PostPruefAntwortAktualisierenAusfuehrenAction
    | PostPruefAntwortAktualisierenErfolgreichAction
    | PostPruefAntwortAktualisierenFehlerAction
    | PostPruefAntwortAktualisierenNichtAusgefuehrtAction
    ;
