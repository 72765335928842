import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_CHECKLISTE_AUFGABE = '[VorlagenModul] GetChecklisteAufgabe laden';
export const GET_CHECKLISTE_AUFGABE_AUSFUEHREN = '[VorlagenModul] GetChecklisteAufgabe ausführen';
export const GET_CHECKLISTE_AUFGABE_ERFOLGREICH = '[VorlagenModul] GetChecklisteAufgabe laden erfolgreich';
export const GET_CHECKLISTE_AUFGABE_FEHLER = '[VorlagenModul] GetChecklisteAufgabe Ladefehler';
export const GET_CHECKLISTE_AUFGABE_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetChecklisteAufgabe nicht ausgeführt (evtl. Offline)';



export class GetChecklisteAufgabeAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE;
        constructor( public checkliste_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabeAusfuehrenAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_AUSFUEHREN;
        constructor( public checkliste_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabeErfolgreichAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_ERFOLGREICH;
        constructor(public payload: api.Aufgabe,  public checkliste_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabeFehlerAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_FEHLER;
        constructor(public payload: any,  public checkliste_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabeNichtAusgefuehrtAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryChecklisteAufgabeActions =
    GetChecklisteAufgabeAction
    | GetChecklisteAufgabeAusfuehrenAction
    | GetChecklisteAufgabeErfolgreichAction
    | GetChecklisteAufgabeFehlerAction
    | GetChecklisteAufgabeNichtAusgefuehrtAction
    ;
