import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFLAUF_BEMERKUNG_SETZEN = '[QschecksModul] PostPrueflaufBemerkungSetzen laden';
export const POST_PRUEFLAUF_BEMERKUNG_SETZEN_AUSFUEHREN = '[QschecksModul] PostPrueflaufBemerkungSetzen ausführen';
export const POST_PRUEFLAUF_BEMERKUNG_SETZEN_ERFOLGREICH = '[QschecksModul] PostPrueflaufBemerkungSetzen laden erfolgreich';
export const POST_PRUEFLAUF_BEMERKUNG_SETZEN_FEHLER = '[QschecksModul] PostPrueflaufBemerkungSetzen Ladefehler';
export const POST_PRUEFLAUF_BEMERKUNG_SETZEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPrueflaufBemerkungSetzen nicht ausgeführt (evtl. Offline)';



export class PostPrueflaufBemerkungSetzenAction implements Action {
    readonly type = POST_PRUEFLAUF_BEMERKUNG_SETZEN;
        constructor(public prueflaufBemerkungRequest: api.PrueflaufBemerkungRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufBemerkungSetzenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFLAUF_BEMERKUNG_SETZEN_AUSFUEHREN;
        constructor(public prueflaufBemerkungRequest: api.PrueflaufBemerkungRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufBemerkungSetzenErfolgreichAction implements Action {
    readonly type = POST_PRUEFLAUF_BEMERKUNG_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public prueflaufBemerkungRequest: api.PrueflaufBemerkungRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufBemerkungSetzenFehlerAction implements Action {
    readonly type = POST_PRUEFLAUF_BEMERKUNG_SETZEN_FEHLER;
        constructor(public payload: any, public prueflaufBemerkungRequest: api.PrueflaufBemerkungRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufBemerkungSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFLAUF_BEMERKUNG_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPrueflaufBemerkungSetzenActions =
    PostPrueflaufBemerkungSetzenAction
    | PostPrueflaufBemerkungSetzenAusfuehrenAction
    | PostPrueflaufBemerkungSetzenErfolgreichAction
    | PostPrueflaufBemerkungSetzenFehlerAction
    | PostPrueflaufBemerkungSetzenNichtAusgefuehrtAction
    ;
