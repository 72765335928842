import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_KOMMUNIKATION_BENUTZER_ZUGRIFF = '[KommunikationModul] GetKommunikationBenutzerZugriff laden';
export const GET_KOMMUNIKATION_BENUTZER_ZUGRIFF_AUSFUEHREN = '[KommunikationModul] GetKommunikationBenutzerZugriff ausführen';
export const GET_KOMMUNIKATION_BENUTZER_ZUGRIFF_ERFOLGREICH = '[KommunikationModul] GetKommunikationBenutzerZugriff laden erfolgreich';
export const GET_KOMMUNIKATION_BENUTZER_ZUGRIFF_FEHLER = '[KommunikationModul] GetKommunikationBenutzerZugriff Ladefehler';
export const GET_KOMMUNIKATION_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetKommunikationBenutzerZugriff nicht ausgeführt (evtl. Offline)';



export class GetKommunikationBenutzerZugriffAction implements Action {
    readonly type = GET_KOMMUNIKATION_BENUTZER_ZUGRIFF;
        constructor(public optPayload: any = null) {}
    }
export class GetKommunikationBenutzerZugriffAusfuehrenAction implements Action {
    readonly type = GET_KOMMUNIKATION_BENUTZER_ZUGRIFF_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetKommunikationBenutzerZugriffErfolgreichAction implements Action {
    readonly type = GET_KOMMUNIKATION_BENUTZER_ZUGRIFF_ERFOLGREICH;
        constructor(public payload: api.KommunikationBenutzerZugriff, public optPayload: any = null) {}
    }
export class GetKommunikationBenutzerZugriffFehlerAction implements Action {
    readonly type = GET_KOMMUNIKATION_BENUTZER_ZUGRIFF_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetKommunikationBenutzerZugriffNichtAusgefuehrtAction implements Action {
    readonly type = GET_KOMMUNIKATION_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryKommunikationBenutzerZugriffActions =
    GetKommunikationBenutzerZugriffAction
    | GetKommunikationBenutzerZugriffAusfuehrenAction
    | GetKommunikationBenutzerZugriffErfolgreichAction
    | GetKommunikationBenutzerZugriffFehlerAction
    | GetKommunikationBenutzerZugriffNichtAusgefuehrtAction
    ;
