import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_FUNKPROTOKOLL_BEENDETE_VERANSTALTUNGEN = '[VeranstaltungenModul] GetFunkprotokollBeendeteVeranstaltungen laden';
export const GET_FUNKPROTOKOLL_BEENDETE_VERANSTALTUNGEN_AUSFUEHREN = '[VeranstaltungenModul] GetFunkprotokollBeendeteVeranstaltungen ausführen';
export const GET_FUNKPROTOKOLL_BEENDETE_VERANSTALTUNGEN_ERFOLGREICH = '[VeranstaltungenModul] GetFunkprotokollBeendeteVeranstaltungen laden erfolgreich';
export const GET_FUNKPROTOKOLL_BEENDETE_VERANSTALTUNGEN_FEHLER = '[VeranstaltungenModul] GetFunkprotokollBeendeteVeranstaltungen Ladefehler';
export const GET_FUNKPROTOKOLL_BEENDETE_VERANSTALTUNGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunkprotokollBeendeteVeranstaltungen nicht ausgeführt (evtl. Offline)';



export class GetFunkprotokollBeendeteVeranstaltungenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_BEENDETE_VERANSTALTUNGEN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunkprotokollBeendeteVeranstaltungenAusfuehrenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_BEENDETE_VERANSTALTUNGEN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunkprotokollBeendeteVeranstaltungenErfolgreichAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_BEENDETE_VERANSTALTUNGEN_ERFOLGREICH;
        constructor(public payload: Array<api.Veranstaltung>, public optPayload: any = null) {}
    }
export class GetFunkprotokollBeendeteVeranstaltungenFehlerAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_BEENDETE_VERANSTALTUNGEN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFunkprotokollBeendeteVeranstaltungenNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_BEENDETE_VERANSTALTUNGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunkprotokollBeendeteVeranstaltungenActions =
    GetFunkprotokollBeendeteVeranstaltungenAction
    | GetFunkprotokollBeendeteVeranstaltungenAusfuehrenAction
    | GetFunkprotokollBeendeteVeranstaltungenErfolgreichAction
    | GetFunkprotokollBeendeteVeranstaltungenFehlerAction
    | GetFunkprotokollBeendeteVeranstaltungenNichtAusgefuehrtAction
    ;
