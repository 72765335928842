import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_BENUTZER_ZUGRIFF_UPDATED_NOTIFICATION = '[ApiModul] GetBenutzerZugriffUpdatedNotification laden';
export const GET_BENUTZER_ZUGRIFF_UPDATED_NOTIFICATION_AUSFUEHREN = '[ApiModul] GetBenutzerZugriffUpdatedNotification ausführen';
export const GET_BENUTZER_ZUGRIFF_UPDATED_NOTIFICATION_ERFOLGREICH = '[ApiModul] GetBenutzerZugriffUpdatedNotification laden erfolgreich';
export const GET_BENUTZER_ZUGRIFF_UPDATED_NOTIFICATION_FEHLER = '[ApiModul] GetBenutzerZugriffUpdatedNotification Ladefehler';
export const GET_BENUTZER_ZUGRIFF_UPDATED_NOTIFICATION_NICHT_AUSGEFUEHRT = '[ApiModul] GetBenutzerZugriffUpdatedNotification nicht ausgeführt (evtl. Offline)';



export class GetBenutzerZugriffUpdatedNotificationAction implements Action {
    readonly type = GET_BENUTZER_ZUGRIFF_UPDATED_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerZugriffUpdatedNotificationAusfuehrenAction implements Action {
    readonly type = GET_BENUTZER_ZUGRIFF_UPDATED_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerZugriffUpdatedNotificationErfolgreichAction implements Action {
    readonly type = GET_BENUTZER_ZUGRIFF_UPDATED_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.BenutzerZugriffUpdatedNotification, public optPayload: any = null) {}
    }
export class GetBenutzerZugriffUpdatedNotificationFehlerAction implements Action {
    readonly type = GET_BENUTZER_ZUGRIFF_UPDATED_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetBenutzerZugriffUpdatedNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_BENUTZER_ZUGRIFF_UPDATED_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryBenutzerZugriffUpdatedNotificationActions =
    GetBenutzerZugriffUpdatedNotificationAction
    | GetBenutzerZugriffUpdatedNotificationAusfuehrenAction
    | GetBenutzerZugriffUpdatedNotificationErfolgreichAction
    | GetBenutzerZugriffUpdatedNotificationFehlerAction
    | GetBenutzerZugriffUpdatedNotificationNichtAusgefuehrtAction
    ;
