import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FUNKPROTOKOLL_FALL_ERINNERUNG_SETZEN = '[VeranstaltungenModul] PostFunkprotokollFallErinnerungSetzen laden';
export const POST_FUNKPROTOKOLL_FALL_ERINNERUNG_SETZEN_AUSFUEHREN = '[VeranstaltungenModul] PostFunkprotokollFallErinnerungSetzen ausführen';
export const POST_FUNKPROTOKOLL_FALL_ERINNERUNG_SETZEN_ERFOLGREICH = '[VeranstaltungenModul] PostFunkprotokollFallErinnerungSetzen laden erfolgreich';
export const POST_FUNKPROTOKOLL_FALL_ERINNERUNG_SETZEN_FEHLER = '[VeranstaltungenModul] PostFunkprotokollFallErinnerungSetzen Ladefehler';
export const POST_FUNKPROTOKOLL_FALL_ERINNERUNG_SETZEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFunkprotokollFallErinnerungSetzen nicht ausgeführt (evtl. Offline)';



export class PostFunkprotokollFallErinnerungSetzenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ERINNERUNG_SETZEN;
        constructor(public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallErinnerungSetzenAusfuehrenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ERINNERUNG_SETZEN_AUSFUEHREN;
        constructor(public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallErinnerungSetzenErfolgreichAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ERINNERUNG_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallErinnerungSetzenFehlerAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ERINNERUNG_SETZEN_FEHLER;
        constructor(public payload: any, public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallErinnerungSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ERINNERUNG_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFunkprotokollFallErinnerungSetzenActions =
    PostFunkprotokollFallErinnerungSetzenAction
    | PostFunkprotokollFallErinnerungSetzenAusfuehrenAction
    | PostFunkprotokollFallErinnerungSetzenErfolgreichAction
    | PostFunkprotokollFallErinnerungSetzenFehlerAction
    | PostFunkprotokollFallErinnerungSetzenNichtAusgefuehrtAction
    ;
