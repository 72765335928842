import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGEN_VEROEFFENTLICHTE_ADMIN = '[VeranstaltungenModul] GetVeranstaltungenVeroeffentlichteAdmin laden';
export const GET_VERANSTALTUNGEN_VEROEFFENTLICHTE_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungenVeroeffentlichteAdmin ausführen';
export const GET_VERANSTALTUNGEN_VEROEFFENTLICHTE_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungenVeroeffentlichteAdmin laden erfolgreich';
export const GET_VERANSTALTUNGEN_VEROEFFENTLICHTE_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungenVeroeffentlichteAdmin Ladefehler';
export const GET_VERANSTALTUNGEN_VEROEFFENTLICHTE_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungenVeroeffentlichteAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungenVeroeffentlichteAdminAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_VEROEFFENTLICHTE_ADMIN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenVeroeffentlichteAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_VEROEFFENTLICHTE_ADMIN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenVeroeffentlichteAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_VEROEFFENTLICHTE_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.VeranstaltungForAdmin>, public optPayload: any = null) {}
    }
export class GetVeranstaltungenVeroeffentlichteAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_VEROEFFENTLICHTE_ADMIN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungenVeroeffentlichteAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_VEROEFFENTLICHTE_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungenVeroeffentlichteAdminActions =
    GetVeranstaltungenVeroeffentlichteAdminAction
    | GetVeranstaltungenVeroeffentlichteAdminAusfuehrenAction
    | GetVeranstaltungenVeroeffentlichteAdminErfolgreichAction
    | GetVeranstaltungenVeroeffentlichteAdminFehlerAction
    | GetVeranstaltungenVeroeffentlichteAdminNichtAusgefuehrtAction
    ;
