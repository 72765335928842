import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN = '[VeranstaltungenModul] PostDokumentBerechtigungHinzufuegen laden';
export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_AUSFUEHREN = '[VeranstaltungenModul] PostDokumentBerechtigungHinzufuegen ausführen';
export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_ERFOLGREICH = '[VeranstaltungenModul] PostDokumentBerechtigungHinzufuegen laden erfolgreich';
export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_FEHLER = '[VeranstaltungenModul] PostDokumentBerechtigungHinzufuegen Ladefehler';
export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostDokumentBerechtigungHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostDokumentBerechtigungHinzufuegenAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN;
        constructor(public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_AUSFUEHREN;
        constructor(public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungHinzufuegenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandDokumentBerechtigungHinzufuegenActions =
    PostDokumentBerechtigungHinzufuegenAction
    | PostDokumentBerechtigungHinzufuegenAusfuehrenAction
    | PostDokumentBerechtigungHinzufuegenErfolgreichAction
    | PostDokumentBerechtigungHinzufuegenFehlerAction
    | PostDokumentBerechtigungHinzufuegenNichtAusgefuehrtAction
    ;
