import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_ROLLE_BENUTZER_HINZUFUEGEN = '[BenutzerverwaltungModul] PostRolleBenutzerHinzufuegen laden';
export const POST_ROLLE_BENUTZER_HINZUFUEGEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostRolleBenutzerHinzufuegen ausführen';
export const POST_ROLLE_BENUTZER_HINZUFUEGEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostRolleBenutzerHinzufuegen laden erfolgreich';
export const POST_ROLLE_BENUTZER_HINZUFUEGEN_FEHLER = '[BenutzerverwaltungModul] PostRolleBenutzerHinzufuegen Ladefehler';
export const POST_ROLLE_BENUTZER_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostRolleBenutzerHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostRolleBenutzerHinzufuegenAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_HINZUFUEGEN;
        constructor(public rolleBenutzerRequest: api.RolleBenutzerRequest, public optPayload: any = null) {}
    }
export class PostRolleBenutzerHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_HINZUFUEGEN_AUSFUEHREN;
        constructor(public rolleBenutzerRequest: api.RolleBenutzerRequest, public optPayload: any = null) {}
    }
export class PostRolleBenutzerHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public rolleBenutzerRequest: api.RolleBenutzerRequest, public optPayload: any = null) {}
    }
export class PostRolleBenutzerHinzufuegenFehlerAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public rolleBenutzerRequest: api.RolleBenutzerRequest, public optPayload: any = null) {}
    }
export class PostRolleBenutzerHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandRolleBenutzerHinzufuegenActions =
    PostRolleBenutzerHinzufuegenAction
    | PostRolleBenutzerHinzufuegenAusfuehrenAction
    | PostRolleBenutzerHinzufuegenErfolgreichAction
    | PostRolleBenutzerHinzufuegenFehlerAction
    | PostRolleBenutzerHinzufuegenNichtAusgefuehrtAction
    ;
