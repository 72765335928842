import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.query.dokument-identifier';
import * as api from '../../../clients/veranstaltungen';


    @Injectable()
    export class VeranstaltungenDokumentIdentifierEffects {

        @Effect()
            DokumentIdentifier$ = this.actions$.pipe(
                ofType(ac.GET_DOKUMENT_IDENTIFIER),
                flatMap((x: ac.GetDokumentIdentifierAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().getDokumentIdentifier().pipe(
                    map((result: any) => {
                        const nextAction = new ac.GetDokumentIdentifierErfolgreichAction(result,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.GetDokumentIdentifierFehlerAction(error,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.VeranstaltungenTemplatesService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.VeranstaltungenTemplatesService {
                if (this.service === null) {
                    this.service = this.injector.get(api.VeranstaltungenTemplatesService);
                }
                return this.service;
            }
        
}
