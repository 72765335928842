

import {Action} from '@ngrx/store';

export const GET_FUNKPROTOKOLL_XML_TEMPLATE = '[VeranstaltungenModul] GetFunkprotokollXmlTemplate laden';
export const GET_FUNKPROTOKOLL_XML_TEMPLATE_AUSFUEHREN = '[VeranstaltungenModul] GetFunkprotokollXmlTemplate ausführen';
export const GET_FUNKPROTOKOLL_XML_TEMPLATE_ERFOLGREICH = '[VeranstaltungenModul] GetFunkprotokollXmlTemplate laden erfolgreich';
export const GET_FUNKPROTOKOLL_XML_TEMPLATE_FEHLER = '[VeranstaltungenModul] GetFunkprotokollXmlTemplate Ladefehler';
export const GET_FUNKPROTOKOLL_XML_TEMPLATE_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunkprotokollXmlTemplate nicht ausgeführt (evtl. Offline)';



export class GetFunkprotokollXmlTemplateAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_XML_TEMPLATE;
        constructor(public optPayload: any = null) {}
    }
export class GetFunkprotokollXmlTemplateAusfuehrenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_XML_TEMPLATE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunkprotokollXmlTemplateErfolgreichAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_XML_TEMPLATE_ERFOLGREICH;
        constructor(public optPayload: any = null) {}
    }
export class GetFunkprotokollXmlTemplateFehlerAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_XML_TEMPLATE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFunkprotokollXmlTemplateNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_XML_TEMPLATE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunkprotokollXmlTemplateActions =
    GetFunkprotokollXmlTemplateAction
    | GetFunkprotokollXmlTemplateAusfuehrenAction
    | GetFunkprotokollXmlTemplateErfolgreichAction
    | GetFunkprotokollXmlTemplateFehlerAction
    | GetFunkprotokollXmlTemplateNichtAusgefuehrtAction
    ;
