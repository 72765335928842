import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFPLAN_ROLLEN_CONTROLLER_ADMIN = '[QschecksModul] GetPruefplanRollenControllerAdmin laden';
export const GET_PRUEFPLAN_ROLLEN_CONTROLLER_ADMIN_AUSFUEHREN = '[QschecksModul] GetPruefplanRollenControllerAdmin ausführen';
export const GET_PRUEFPLAN_ROLLEN_CONTROLLER_ADMIN_ERFOLGREICH = '[QschecksModul] GetPruefplanRollenControllerAdmin laden erfolgreich';
export const GET_PRUEFPLAN_ROLLEN_CONTROLLER_ADMIN_FEHLER = '[QschecksModul] GetPruefplanRollenControllerAdmin Ladefehler';
export const GET_PRUEFPLAN_ROLLEN_CONTROLLER_ADMIN_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPruefplanRollenControllerAdmin nicht ausgeführt (evtl. Offline)';



export class GetPruefplanRollenControllerAdminAction implements Action {
    readonly type = GET_PRUEFPLAN_ROLLEN_CONTROLLER_ADMIN;
        constructor( public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanRollenControllerAdminAusfuehrenAction implements Action {
    readonly type = GET_PRUEFPLAN_ROLLEN_CONTROLLER_ADMIN_AUSFUEHREN;
        constructor( public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanRollenControllerAdminErfolgreichAction implements Action {
    readonly type = GET_PRUEFPLAN_ROLLEN_CONTROLLER_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichRolle>,  public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanRollenControllerAdminFehlerAction implements Action {
    readonly type = GET_PRUEFPLAN_ROLLEN_CONTROLLER_ADMIN_FEHLER;
        constructor(public payload: any,  public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanRollenControllerAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFPLAN_ROLLEN_CONTROLLER_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPruefplanRollenControllerAdminActions =
    GetPruefplanRollenControllerAdminAction
    | GetPruefplanRollenControllerAdminAusfuehrenAction
    | GetPruefplanRollenControllerAdminErfolgreichAction
    | GetPruefplanRollenControllerAdminFehlerAction
    | GetPruefplanRollenControllerAdminNichtAusgefuehrtAction
    ;
