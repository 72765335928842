import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_EXPLIZITE_FREIGABE_FESTLEGEN = '[BenutzerverwaltungModul] PostFunktionsbereichExpliziteFreigabeFestlegen laden';
export const POST_FUNKTIONSBEREICH_EXPLIZITE_FREIGABE_FESTLEGEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostFunktionsbereichExpliziteFreigabeFestlegen ausführen';
export const POST_FUNKTIONSBEREICH_EXPLIZITE_FREIGABE_FESTLEGEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostFunktionsbereichExpliziteFreigabeFestlegen laden erfolgreich';
export const POST_FUNKTIONSBEREICH_EXPLIZITE_FREIGABE_FESTLEGEN_FEHLER = '[BenutzerverwaltungModul] PostFunktionsbereichExpliziteFreigabeFestlegen Ladefehler';
export const POST_FUNKTIONSBEREICH_EXPLIZITE_FREIGABE_FESTLEGEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostFunktionsbereichExpliziteFreigabeFestlegen nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichExpliziteFreigabeFestlegenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EXPLIZITE_FREIGABE_FESTLEGEN;
        constructor(public funktionsbereichExpliziteFreigabeErforderlichFestlegenRequest: api.FunktionsbereichExpliziteFreigabeErforderlichFestlegenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichExpliziteFreigabeFestlegenAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EXPLIZITE_FREIGABE_FESTLEGEN_AUSFUEHREN;
        constructor(public funktionsbereichExpliziteFreigabeErforderlichFestlegenRequest: api.FunktionsbereichExpliziteFreigabeErforderlichFestlegenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichExpliziteFreigabeFestlegenErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EXPLIZITE_FREIGABE_FESTLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funktionsbereichExpliziteFreigabeErforderlichFestlegenRequest: api.FunktionsbereichExpliziteFreigabeErforderlichFestlegenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichExpliziteFreigabeFestlegenFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EXPLIZITE_FREIGABE_FESTLEGEN_FEHLER;
        constructor(public payload: any, public funktionsbereichExpliziteFreigabeErforderlichFestlegenRequest: api.FunktionsbereichExpliziteFreigabeErforderlichFestlegenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichExpliziteFreigabeFestlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EXPLIZITE_FREIGABE_FESTLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandFunktionsbereichExpliziteFreigabeFestlegenActions =
    PostFunktionsbereichExpliziteFreigabeFestlegenAction
    | PostFunktionsbereichExpliziteFreigabeFestlegenAusfuehrenAction
    | PostFunktionsbereichExpliziteFreigabeFestlegenErfolgreichAction
    | PostFunktionsbereichExpliziteFreigabeFestlegenFehlerAction
    | PostFunktionsbereichExpliziteFreigabeFestlegenNichtAusgefuehrtAction
    ;
