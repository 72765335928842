import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFCHECKLISTE_REIHENFOLGE_SETZEN = '[QschecksModul] PostPruefchecklisteReihenfolgeSetzen laden';
export const POST_PRUEFCHECKLISTE_REIHENFOLGE_SETZEN_AUSFUEHREN = '[QschecksModul] PostPruefchecklisteReihenfolgeSetzen ausführen';
export const POST_PRUEFCHECKLISTE_REIHENFOLGE_SETZEN_ERFOLGREICH = '[QschecksModul] PostPruefchecklisteReihenfolgeSetzen laden erfolgreich';
export const POST_PRUEFCHECKLISTE_REIHENFOLGE_SETZEN_FEHLER = '[QschecksModul] PostPruefchecklisteReihenfolgeSetzen Ladefehler';
export const POST_PRUEFCHECKLISTE_REIHENFOLGE_SETZEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefchecklisteReihenfolgeSetzen nicht ausgeführt (evtl. Offline)';



export class PostPruefchecklisteReihenfolgeSetzenAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_REIHENFOLGE_SETZEN;
        constructor(public pruefchecklisteReihenfolgeRequest: api.PruefchecklisteReihenfolgeRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteReihenfolgeSetzenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_REIHENFOLGE_SETZEN_AUSFUEHREN;
        constructor(public pruefchecklisteReihenfolgeRequest: api.PruefchecklisteReihenfolgeRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteReihenfolgeSetzenErfolgreichAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_REIHENFOLGE_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefchecklisteReihenfolgeRequest: api.PruefchecklisteReihenfolgeRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteReihenfolgeSetzenFehlerAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_REIHENFOLGE_SETZEN_FEHLER;
        constructor(public payload: any, public pruefchecklisteReihenfolgeRequest: api.PruefchecklisteReihenfolgeRequest, public optPayload: any = null) {}
    }
export class PostPruefchecklisteReihenfolgeSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFCHECKLISTE_REIHENFOLGE_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefchecklisteReihenfolgeSetzenActions =
    PostPruefchecklisteReihenfolgeSetzenAction
    | PostPruefchecklisteReihenfolgeSetzenAusfuehrenAction
    | PostPruefchecklisteReihenfolgeSetzenErfolgreichAction
    | PostPruefchecklisteReihenfolgeSetzenFehlerAction
    | PostPruefchecklisteReihenfolgeSetzenNichtAusgefuehrtAction
    ;
