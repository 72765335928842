import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN = '[KommunikationModul] PostMitteilungsVorlageVeranstaltungsTypenHinzufuegen laden';
export const POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_AUSFUEHREN = '[KommunikationModul] PostMitteilungsVorlageVeranstaltungsTypenHinzufuegen ausführen';
export const POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_ERFOLGREICH = '[KommunikationModul] PostMitteilungsVorlageVeranstaltungsTypenHinzufuegen laden erfolgreich';
export const POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_FEHLER = '[KommunikationModul] PostMitteilungsVorlageVeranstaltungsTypenHinzufuegen Ladefehler';
export const POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[KommunikationModul] PostMitteilungsVorlageVeranstaltungsTypenHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostMitteilungsVorlageVeranstaltungsTypenHinzufuegenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN;
        constructor(public mitteilungsVorlageTypMultiRequest: api.MitteilungsVorlageTypMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageVeranstaltungsTypenHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_AUSFUEHREN;
        constructor(public mitteilungsVorlageTypMultiRequest: api.MitteilungsVorlageTypMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageVeranstaltungsTypenHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public mitteilungsVorlageTypMultiRequest: api.MitteilungsVorlageTypMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageVeranstaltungsTypenHinzufuegenFehlerAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public mitteilungsVorlageTypMultiRequest: api.MitteilungsVorlageTypMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageVeranstaltungsTypenHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationCommandMitteilungsVorlageVeranstaltungsTypenHinzufuegenActions =
    PostMitteilungsVorlageVeranstaltungsTypenHinzufuegenAction
    | PostMitteilungsVorlageVeranstaltungsTypenHinzufuegenAusfuehrenAction
    | PostMitteilungsVorlageVeranstaltungsTypenHinzufuegenErfolgreichAction
    | PostMitteilungsVorlageVeranstaltungsTypenHinzufuegenFehlerAction
    | PostMitteilungsVorlageVeranstaltungsTypenHinzufuegenNichtAusgefuehrtAction
    ;
