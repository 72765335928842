export * from './displaysteuerungCommand.service';
import { DisplaysteuerungCommandService } from './displaysteuerungCommand.service';
export * from './displaysteuerungQuery.service';
import { DisplaysteuerungQueryService } from './displaysteuerungQuery.service';
export * from './displaysteuerungTemplates.service';
import { DisplaysteuerungTemplatesService } from './displaysteuerungTemplates.service';
export * from './verwaltenCommand.service';
import { VerwaltenCommandService } from './verwaltenCommand.service';
export * from './verwaltenQuery.service';
import { VerwaltenQueryService } from './verwaltenQuery.service';
export const APIS = [DisplaysteuerungCommandService, DisplaysteuerungQueryService, DisplaysteuerungTemplatesService, VerwaltenCommandService, VerwaltenQueryService];
