import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import * as fromRollenselektionModal from './rollenselektion.reducer';
import {IonSearchbar, ModalController, NavController, NavParams} from '@ionic/angular';
import * as actionsUi from './rollenselektion.actions.ui';
import {select, Store} from '@ngrx/store';
import {Funktionsbereich} from '../../../clients/benutzerverwaltung';
import {Checkliste} from '../../../clients/vorlagen';
import {Datenquelle, RollenDividerGroup} from './rollenselektion.model';
import {
    getAlleAnzeigen,
    getOneSelected,
    getRollenClean,
    getUiRollenLadeanimation,
    getUiRollenLadeFehler,
    getUiSelektierteRollen
} from './rollenselektion.selectors';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';


@Component({
    selector: 'app-rollenselektion',
    templateUrl: './rollenselektion.page.html',
    styleUrls: ['./rollenselektion.page.scss'],
})
export class RollenselektionPage implements OnInit {
    @Input() titel: string;
    @Input() untertitel: string;
    @Input() alleAnzeigenAuswahlAnzeigen: boolean;
    @Input() alleAnzeigen: boolean;
    @Input() nichtAnzuzeigendeRollenIds: Array<string>;
    @Input() funktionsbereich: Funktionsbereich;
    @Input() checkliste: Checkliste;
    @Input() veranstaltungsId: string;
    @Input() datenquelle: Datenquelle;
    @Input() einzelauswahl: boolean;

    aiName = 'RollenselektionPage';
    aktuellerTab = 'Meine';

    rollen$: Observable<Array<ListInputItem>>;
    rollenGruppiert$: Observable<RollenDividerGroup[]>;
    uiRollenLadeanimation$: Observable<boolean>;
    uiRollenLadeFehler$: Observable<boolean>;
    filterString$: Observable<string>;
    oneSelected$: Observable<boolean>;
    selektierteRollen$: Observable<Array<string>>;

    alleAnzeigen$: Observable<boolean>;

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private modalCtrl: ModalController,
                private store: Store<fromRollenselektionModal.PageState>) {
        if (this.einzelauswahl === undefined || this.einzelauswahl === null) {
            this.einzelauswahl = false;
        }
        // console.log('Einzelauswahl: ', this.einzelauswahl);
    }

    ngOnInit() {
        this.rollen$ = this.store.pipe(select(getRollenClean));
        this.uiRollenLadeanimation$ = this.store.pipe(select(getUiRollenLadeanimation));
        this.uiRollenLadeFehler$ = this.store.pipe(select(getUiRollenLadeFehler));
        this.alleAnzeigen$ = this.store.pipe(select(getAlleAnzeigen));
        this.selektierteRollen$ = this.store.pipe(select(getUiSelektierteRollen));

        this.oneSelected$ = this.store.pipe(select(getOneSelected));

        if (this.alleAnzeigen) {
            this.aktuellerTab = 'Alle';
        }
    }

    ionViewWillEnter() {
        this.viewWillEnterPage().then();
    }

    async viewWillEnterPage() {
        await this.store.dispatch(new actionsUi.PageStateResetAction(this.datenquelle, this.veranstaltungsId, this.funktionsbereich, this.checkliste, this.nichtAnzuzeigendeRollenIds, this.alleAnzeigen));
    }


    async ok() {
        this.selektierteRollen$.subscribe(async (item) => {
            const data = {ausgewaehlteRollen: item};
            await this.modalCtrl.dismiss(data);
        }).unsubscribe();
    }

    async abbrechen() {
        await this.modalCtrl.dismiss();
    }

    async refreshData() {
        await this.store.dispatch(new actionsUi.RefreshDataAction(this.datenquelle));
    }

    async alleAnzeigenChecked($event) {
        if ($event.detail !== undefined && $event.detail !== null && $event.detail.checked !== undefined && $event.detail.checked !== null) {
            await this.store.dispatch(new actionsUi.AlleAnzeigenSetzenAction($event.detail.checked));
        }
    }

    async updateSelectedRollen($event: Array<string>) {
        await this.store.dispatch(new actionsUi.SelektierteRollenAction($event));
    }

    async updateSelectedRolle($event: string) {
        const rolle: Array<string> = [$event];
        await this.store.dispatch(new actionsUi.SelektierteRollenAction(rolle));
    }
}
