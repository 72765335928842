import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_MITTEILUNGEN_GESENDET = '[KommunikationModul] GetMitteilungenGesendet laden';
export const GET_MITTEILUNGEN_GESENDET_AUSFUEHREN = '[KommunikationModul] GetMitteilungenGesendet ausführen';
export const GET_MITTEILUNGEN_GESENDET_ERFOLGREICH = '[KommunikationModul] GetMitteilungenGesendet laden erfolgreich';
export const GET_MITTEILUNGEN_GESENDET_FEHLER = '[KommunikationModul] GetMitteilungenGesendet Ladefehler';
export const GET_MITTEILUNGEN_GESENDET_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetMitteilungenGesendet nicht ausgeführt (evtl. Offline)';



export class GetMitteilungenGesendetAction implements Action {
    readonly type = GET_MITTEILUNGEN_GESENDET;
        constructor( public korrelation_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGesendetAusfuehrenAction implements Action {
    readonly type = GET_MITTEILUNGEN_GESENDET_AUSFUEHREN;
        constructor( public korrelation_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGesendetErfolgreichAction implements Action {
    readonly type = GET_MITTEILUNGEN_GESENDET_ERFOLGREICH;
        constructor(public payload: Array<api.Mitteilung>,  public korrelation_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGesendetFehlerAction implements Action {
    readonly type = GET_MITTEILUNGEN_GESENDET_FEHLER;
        constructor(public payload: any,  public korrelation_id:string , public optPayload: any = null) {}
    }
export class GetMitteilungenGesendetNichtAusgefuehrtAction implements Action {
    readonly type = GET_MITTEILUNGEN_GESENDET_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryMitteilungenGesendetActions =
    GetMitteilungenGesendetAction
    | GetMitteilungenGesendetAusfuehrenAction
    | GetMitteilungenGesendetErfolgreichAction
    | GetMitteilungenGesendetFehlerAction
    | GetMitteilungenGesendetNichtAusgefuehrtAction
    ;
