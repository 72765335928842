import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AdminCommandService } from './api/adminCommand.service';
import { AdminQueryService } from './api/adminQuery.service';
import { AufgabenCommandService } from './api/aufgabenCommand.service';
import { AufgabenQueryService } from './api/aufgabenQuery.service';
import { BenutzerVerwaltungExtensionCommandService } from './api/benutzerVerwaltungExtensionCommand.service';
import { BenutzerVerwaltungExtensionQueryService } from './api/benutzerVerwaltungExtensionQuery.service';
import { ChecklistenCommandService } from './api/checklistenCommand.service';
import { ChecklistenQueryService } from './api/checklistenQuery.service';
import { DokumenteCommandService } from './api/dokumenteCommand.service';
import { FehlerCommandService } from './api/fehlerCommand.service';
import { FunkprotokollCommandService } from './api/funkprotokollCommand.service';
import { FunkprotokollQueryService } from './api/funkprotokollQuery.service';
import { FunktionsbereicheCommandService } from './api/funktionsbereicheCommand.service';
import { FunktionsbereicheQueryService } from './api/funktionsbereicheQuery.service';
import { MitteilungenCommandService } from './api/mitteilungenCommand.service';
import { MitteilungenQueryService } from './api/mitteilungenQuery.service';
import { RollenCommandService } from './api/rollenCommand.service';
import { VeranstaltungenCommandService } from './api/veranstaltungenCommand.service';
import { VeranstaltungenQueryService } from './api/veranstaltungenQuery.service';
import { VeranstaltungenTemplatesService } from './api/veranstaltungenTemplates.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AdminCommandService,
    AdminQueryService,
    AufgabenCommandService,
    AufgabenQueryService,
    BenutzerVerwaltungExtensionCommandService,
    BenutzerVerwaltungExtensionQueryService,
    ChecklistenCommandService,
    ChecklistenQueryService,
    DokumenteCommandService,
    FehlerCommandService,
    FunkprotokollCommandService,
    FunkprotokollQueryService,
    FunktionsbereicheCommandService,
    FunktionsbereicheQueryService,
    MitteilungenCommandService,
    MitteilungenQueryService,
    RollenCommandService,
    VeranstaltungenCommandService,
    VeranstaltungenQueryService,
    VeranstaltungenTemplatesService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
