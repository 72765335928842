import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const GET_TRIGGER_LIST_ADMIN = '[DisplaysteuerungModul] GetTriggerListAdmin laden';
export const GET_TRIGGER_LIST_ADMIN_AUSFUEHREN = '[DisplaysteuerungModul] GetTriggerListAdmin ausführen';
export const GET_TRIGGER_LIST_ADMIN_ERFOLGREICH = '[DisplaysteuerungModul] GetTriggerListAdmin laden erfolgreich';
export const GET_TRIGGER_LIST_ADMIN_FEHLER = '[DisplaysteuerungModul] GetTriggerListAdmin Ladefehler';
export const GET_TRIGGER_LIST_ADMIN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] GetTriggerListAdmin nicht ausgeführt (evtl. Offline)';



export class GetTriggerListAdminAction implements Action {
    readonly type = GET_TRIGGER_LIST_ADMIN;
        constructor(public optPayload: any = null) {}
    }
export class GetTriggerListAdminAusfuehrenAction implements Action {
    readonly type = GET_TRIGGER_LIST_ADMIN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetTriggerListAdminErfolgreichAction implements Action {
    readonly type = GET_TRIGGER_LIST_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.Trigger>, public optPayload: any = null) {}
    }
export class GetTriggerListAdminFehlerAction implements Action {
    readonly type = GET_TRIGGER_LIST_ADMIN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetTriggerListAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_TRIGGER_LIST_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungQueryTriggerListAdminActions =
    GetTriggerListAdminAction
    | GetTriggerListAdminAusfuehrenAction
    | GetTriggerListAdminErfolgreichAction
    | GetTriggerListAdminFehlerAction
    | GetTriggerListAdminNichtAusgefuehrtAction
    ;
