import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICH_IDENTIFIER = '[BenutzerverwaltungModul] GetFunktionsbereichIdentifier laden';
export const GET_FUNKTIONSBEREICH_IDENTIFIER_AUSFUEHREN = '[BenutzerverwaltungModul] GetFunktionsbereichIdentifier ausführen';
export const GET_FUNKTIONSBEREICH_IDENTIFIER_ERFOLGREICH = '[BenutzerverwaltungModul] GetFunktionsbereichIdentifier laden erfolgreich';
export const GET_FUNKTIONSBEREICH_IDENTIFIER_FEHLER = '[BenutzerverwaltungModul] GetFunktionsbereichIdentifier Ladefehler';
export const GET_FUNKTIONSBEREICH_IDENTIFIER_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetFunktionsbereichIdentifier nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichIdentifierAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_IDENTIFIER;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereichIdentifierAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_IDENTIFIER_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunktionsbereichIdentifierErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_IDENTIFIER_ERFOLGREICH;
        constructor(public payload: api.FunktionsbereichIdentifier, public optPayload: any = null) {}
    }
export class GetFunktionsbereichIdentifierFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_IDENTIFIER_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFunktionsbereichIdentifierNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_IDENTIFIER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryFunktionsbereichIdentifierActions =
    GetFunktionsbereichIdentifierAction
    | GetFunktionsbereichIdentifierAusfuehrenAction
    | GetFunktionsbereichIdentifierErfolgreichAction
    | GetFunktionsbereichIdentifierFehlerAction
    | GetFunktionsbereichIdentifierNichtAusgefuehrtAction
    ;
