import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_MANDANT_USER_INFO = '[ApiModul] GetMandantUserInfo laden';
export const GET_MANDANT_USER_INFO_AUSFUEHREN = '[ApiModul] GetMandantUserInfo ausführen';
export const GET_MANDANT_USER_INFO_ERFOLGREICH = '[ApiModul] GetMandantUserInfo laden erfolgreich';
export const GET_MANDANT_USER_INFO_FEHLER = '[ApiModul] GetMandantUserInfo Ladefehler';
export const GET_MANDANT_USER_INFO_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantUserInfo nicht ausgeführt (evtl. Offline)';



export class GetMandantUserInfoAction implements Action {
    readonly type = GET_MANDANT_USER_INFO;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantUserInfoAusfuehrenAction implements Action {
    readonly type = GET_MANDANT_USER_INFO_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantUserInfoErfolgreichAction implements Action {
    readonly type = GET_MANDANT_USER_INFO_ERFOLGREICH;
        constructor(public payload: api.Benutzer, public optPayload: any = null) {}
    }
export class GetMandantUserInfoFehlerAction implements Action {
    readonly type = GET_MANDANT_USER_INFO_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantUserInfoNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANT_USER_INFO_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantUserInfoActions =
    GetMandantUserInfoAction
    | GetMandantUserInfoAusfuehrenAction
    | GetMandantUserInfoErfolgreichAction
    | GetMandantUserInfoFehlerAction
    | GetMandantUserInfoNichtAusgefuehrtAction
    ;
