import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_DOKUMENT_BERECHTIGUNG = '[VeranstaltungenModul] GetVeranstaltungDokumentBerechtigung laden';
export const GET_VERANSTALTUNG_DOKUMENT_BERECHTIGUNG_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungDokumentBerechtigung ausführen';
export const GET_VERANSTALTUNG_DOKUMENT_BERECHTIGUNG_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungDokumentBerechtigung laden erfolgreich';
export const GET_VERANSTALTUNG_DOKUMENT_BERECHTIGUNG_FEHLER = '[VeranstaltungenModul] GetVeranstaltungDokumentBerechtigung Ladefehler';
export const GET_VERANSTALTUNG_DOKUMENT_BERECHTIGUNG_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungDokumentBerechtigung nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungDokumentBerechtigungAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_BERECHTIGUNG;
        constructor( public veranstaltung_id:string ,  public dokument_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumentBerechtigungAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_BERECHTIGUNG_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public dokument_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumentBerechtigungErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_BERECHTIGUNG_ERFOLGREICH;
        constructor(public payload: api.DokumentBerechtigung,  public veranstaltung_id:string ,  public dokument_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumentBerechtigungFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_BERECHTIGUNG_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public dokument_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumentBerechtigungNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_BERECHTIGUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungDokumentBerechtigungActions =
    GetVeranstaltungDokumentBerechtigungAction
    | GetVeranstaltungDokumentBerechtigungAusfuehrenAction
    | GetVeranstaltungDokumentBerechtigungErfolgreichAction
    | GetVeranstaltungDokumentBerechtigungFehlerAction
    | GetVeranstaltungDokumentBerechtigungNichtAusgefuehrtAction
    ;
