import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ADMIN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdmin laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdmin ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdmin Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ADMIN;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.ChecklisteAufgabenAdmin2>,  public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdminActions =
    GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdminAction
    | GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdminAusfuehrenAction
    | GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdminErfolgreichAction
    | GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdminFehlerAction
    | GetVeranstaltungFunktionsbereichAufgabenBenutzerBerechtigungenAdminNichtAusgefuehrtAction
    ;
