import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGS_TYP_IDENTIFIER = '[VeranstaltungstypenModul] GetVeranstaltungsTypIdentifier laden';
export const GET_VERANSTALTUNGS_TYP_IDENTIFIER_AUSFUEHREN = '[VeranstaltungstypenModul] GetVeranstaltungsTypIdentifier ausführen';
export const GET_VERANSTALTUNGS_TYP_IDENTIFIER_ERFOLGREICH = '[VeranstaltungstypenModul] GetVeranstaltungsTypIdentifier laden erfolgreich';
export const GET_VERANSTALTUNGS_TYP_IDENTIFIER_FEHLER = '[VeranstaltungstypenModul] GetVeranstaltungsTypIdentifier Ladefehler';
export const GET_VERANSTALTUNGS_TYP_IDENTIFIER_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] GetVeranstaltungsTypIdentifier nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungsTypIdentifierAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_IDENTIFIER;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypIdentifierAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_IDENTIFIER_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypIdentifierErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_IDENTIFIER_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungsTypIdentifier, public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypIdentifierFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_IDENTIFIER_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypIdentifierNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_IDENTIFIER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenQueryVeranstaltungsTypIdentifierActions =
    GetVeranstaltungsTypIdentifierAction
    | GetVeranstaltungsTypIdentifierAusfuehrenAction
    | GetVeranstaltungsTypIdentifierErfolgreichAction
    | GetVeranstaltungsTypIdentifierFehlerAction
    | GetVeranstaltungsTypIdentifierNichtAusgefuehrtAction
    ;
