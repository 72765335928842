import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_AKTIVIEREN = '[VeranstaltungenModul] PostAufgabeAktivieren laden';
export const POST_AUFGABE_AKTIVIEREN_AUSFUEHREN = '[VeranstaltungenModul] PostAufgabeAktivieren ausführen';
export const POST_AUFGABE_AKTIVIEREN_ERFOLGREICH = '[VeranstaltungenModul] PostAufgabeAktivieren laden erfolgreich';
export const POST_AUFGABE_AKTIVIEREN_FEHLER = '[VeranstaltungenModul] PostAufgabeAktivieren Ladefehler';
export const POST_AUFGABE_AKTIVIEREN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostAufgabeAktivieren nicht ausgeführt (evtl. Offline)';



export class PostAufgabeAktivierenAction implements Action {
    readonly type = POST_AUFGABE_AKTIVIEREN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeAktivierenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_AKTIVIEREN_AUSFUEHREN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeAktivierenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_AKTIVIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeAktivierenFehlerAction implements Action {
    readonly type = POST_AUFGABE_AKTIVIEREN_FEHLER;
        constructor(public payload: any, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeAktivierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_AKTIVIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandAufgabeAktivierenActions =
    PostAufgabeAktivierenAction
    | PostAufgabeAktivierenAusfuehrenAction
    | PostAufgabeAktivierenErfolgreichAction
    | PostAufgabeAktivierenFehlerAction
    | PostAufgabeAktivierenNichtAusgefuehrtAction
    ;
