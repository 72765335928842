

import {Action} from '@ngrx/store';

export const GET_INCIDENT_REPORT_XML = '[IncidentsModul] GetIncidentReportXml laden';
export const GET_INCIDENT_REPORT_XML_AUSFUEHREN = '[IncidentsModul] GetIncidentReportXml ausführen';
export const GET_INCIDENT_REPORT_XML_ERFOLGREICH = '[IncidentsModul] GetIncidentReportXml laden erfolgreich';
export const GET_INCIDENT_REPORT_XML_FEHLER = '[IncidentsModul] GetIncidentReportXml Ladefehler';
export const GET_INCIDENT_REPORT_XML_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentReportXml nicht ausgeführt (evtl. Offline)';



export class GetIncidentReportXmlAction implements Action {
    readonly type = GET_INCIDENT_REPORT_XML;
        constructor( public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentReportXmlAusfuehrenAction implements Action {
    readonly type = GET_INCIDENT_REPORT_XML_AUSFUEHREN;
        constructor( public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentReportXmlErfolgreichAction implements Action {
    readonly type = GET_INCIDENT_REPORT_XML_ERFOLGREICH;
        constructor( public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentReportXmlFehlerAction implements Action {
    readonly type = GET_INCIDENT_REPORT_XML_FEHLER;
        constructor(public payload: any,  public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentReportXmlNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENT_REPORT_XML_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentReportXmlActions =
    GetIncidentReportXmlAction
    | GetIncidentReportXmlAusfuehrenAction
    | GetIncidentReportXmlErfolgreichAction
    | GetIncidentReportXmlFehlerAction
    | GetIncidentReportXmlNichtAusgefuehrtAction
    ;
