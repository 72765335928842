

import {Action} from '@ngrx/store';

export const GET_MANDANT_CLIENT_CONFIG = '[ApiModul] GetMandantClientConfig laden';
export const GET_MANDANT_CLIENT_CONFIG_AUSFUEHREN = '[ApiModul] GetMandantClientConfig ausführen';
export const GET_MANDANT_CLIENT_CONFIG_ERFOLGREICH = '[ApiModul] GetMandantClientConfig laden erfolgreich';
export const GET_MANDANT_CLIENT_CONFIG_FEHLER = '[ApiModul] GetMandantClientConfig Ladefehler';
export const GET_MANDANT_CLIENT_CONFIG_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantClientConfig nicht ausgeführt (evtl. Offline)';



export class GetMandantClientConfigAction implements Action {
    readonly type = GET_MANDANT_CLIENT_CONFIG;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantClientConfigAusfuehrenAction implements Action {
    readonly type = GET_MANDANT_CLIENT_CONFIG_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantClientConfigErfolgreichAction implements Action {
    readonly type = GET_MANDANT_CLIENT_CONFIG_ERFOLGREICH;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantClientConfigFehlerAction implements Action {
    readonly type = GET_MANDANT_CLIENT_CONFIG_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantClientConfigNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANT_CLIENT_CONFIG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantClientConfigActions =
    GetMandantClientConfigAction
    | GetMandantClientConfigAusfuehrenAction
    | GetMandantClientConfigErfolgreichAction
    | GetMandantClientConfigFehlerAction
    | GetMandantClientConfigNichtAusgefuehrtAction
    ;
