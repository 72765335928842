import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN = '[KommunikationModul] GetMitteilungsVorlageVeranstaltungsTypen laden';
export const GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_AUSFUEHREN = '[KommunikationModul] GetMitteilungsVorlageVeranstaltungsTypen ausführen';
export const GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ERFOLGREICH = '[KommunikationModul] GetMitteilungsVorlageVeranstaltungsTypen laden erfolgreich';
export const GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_FEHLER = '[KommunikationModul] GetMitteilungsVorlageVeranstaltungsTypen Ladefehler';
export const GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetMitteilungsVorlageVeranstaltungsTypen nicht ausgeführt (evtl. Offline)';



export class GetMitteilungsVorlageVeranstaltungsTypenAction implements Action {
    readonly type = GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN;
        constructor(public optPayload: any = null) {}
    }
export class GetMitteilungsVorlageVeranstaltungsTypenAusfuehrenAction implements Action {
    readonly type = GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMitteilungsVorlageVeranstaltungsTypenErfolgreichAction implements Action {
    readonly type = GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ERFOLGREICH;
        constructor(public payload: Array<api.VeranstaltungsTyp>, public optPayload: any = null) {}
    }
export class GetMitteilungsVorlageVeranstaltungsTypenFehlerAction implements Action {
    readonly type = GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMitteilungsVorlageVeranstaltungsTypenNichtAusgefuehrtAction implements Action {
    readonly type = GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryMitteilungsVorlageVeranstaltungsTypenActions =
    GetMitteilungsVorlageVeranstaltungsTypenAction
    | GetMitteilungsVorlageVeranstaltungsTypenAusfuehrenAction
    | GetMitteilungsVorlageVeranstaltungsTypenErfolgreichAction
    | GetMitteilungsVorlageVeranstaltungsTypenFehlerAction
    | GetMitteilungsVorlageVeranstaltungsTypenNichtAusgefuehrtAction
    ;
