import {Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {IonInput, LoadingController, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {Aufgabe, Checkliste, ZeitpunktTexte} from '../../../clients/vorlagen';
import {Funktionsbereich} from '../../../clients/benutzerverwaltung';
import {Md2DateChange} from 'angular-md2/lib/datepicker/datepicker';
import {select, Store} from '@ngrx/store';
import {getZeitpunktTexte} from '@orga-app/mandant/reducer';
import {PageState} from '../checklisten/checklisten.reducer';
import {Observable} from 'rxjs/Observable';
import {Settings, SettingsProvider} from '@orga-app/settings';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {getTheme} from '@orga-app/settings/reducer';
import {UploadResponse, UploadService} from '../../../clients/api';
import {BildInfo} from '../../shared/models/bildInfo';
import {BildVorschauComponent} from '../../../sopi/components/bild-vorschau/bild-vorschau';
import {UuidService} from '@orga-app/providers/uuid.service';
import {of} from 'rxjs';
import {catchError, filter, takeWhile} from 'rxjs/operators';
import {ResponseContentType} from '@angular/http';
import {ImageService} from '@orga-app/providers/image.service';

interface Wochentag {
    number: number;
    wochentag: string;
    kuerzel: string;
}

/**
 * Generated class for the AufgabePage page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'app-aufgabe',
    templateUrl: './aufgabe.page.html',
    styleUrls: ['./aufgabe.page.scss'],
})
export class AufgabePage implements OnInit {
    @Input() aufgabe: Aufgabe;
    @Input() funktionsbereich: Funktionsbereich;
    @Input() checkliste: Checkliste;
    @Input() untertitel: string;
    @Input() titel: string;
    @Input() veranstaltungsId: string;
    @ViewChild('titelInput', {static: false}) titelInput: IonInput;
    @ViewChild('logoFileInput', {static: false, read: ElementRef}) logoFileInput: ElementRef;


    public settings$: Observable<Settings>;
    themeDefault$: Observable<Settings>;
    logoHeaderSVG$: Observable<SafeUrl>;

    aufgabeBilder$: Observable<BildInfo[]>;

    beschreibung = '';
    erinnerungTage = 0;
    eskalationTage = 0;
    erinnerungWochentag = 0;
    eskalationWochentag = 0;
    istOptional = false;
    istOptionalStandardAktiviert = false;
    istVeranstaltungsrelevant = false;
    istErinnerungAktiv = false;
    istEskalationAktiv = false;
    istEskalationAnAbarbeiterAktiv = false;
    istEskalationAnFreigeberFunktionsbereichAktiv = false;
    istEskalationAnFreigeberChecklisteAktiv = false;
    istEskalationAnVeranstaltungsleitungAktiv = false;
    istErinnerungUhrzeitFix = true;
    istEskalationUhrzeitFix = true;
    istErinnerungWochentagFix = false;
    istEskalationWochentagFix = false;
    erinnerungUhrzeitTimePicker = new Date();
    erinnerungUhrzeitFixStunden = 0;
    erinnerungUhrzeitFixMinuten = 0;
    erinnerungRelativStunden = 0;
    erinnerungRelativMinuten = 0;
    eskalationUhrzeitTimePicker = new Date();
    eskalationUhrzeitFixStunden = 0;
    eskalationUhrzeitFixMinuten = 0;
    eskalationRelativStunden = 0;
    eskalationRelativMinuten = 0;
    relation = 'Vor'.toUpperCase();
    erinnerungReferenzZeitpunkt = 'EINLASS';
    demoVeranstaltungsbeginn: Date;
    demoEinlass: Date;
    demoErinnerungszeitpunkt: Date;
    demoEskalationszeitpunkt: Date;
    private aiName = 'AufgabePage';

    bilderIds: Array<string> = [];
    aufgabeBilder: Array<BildInfo> = [];
    image: any;
    alive = true;

    wochentage: Array<Wochentag> = [
        { number: 1, wochentag: 'Montag', kuerzel: 'MO'},
        { number: 2, wochentag: 'Dienstag', kuerzel: 'DI'},
        { number: 3, wochentag: 'Mittwoch', kuerzel: 'MI'},
        { number: 4, wochentag: 'Donnerstag', kuerzel: 'DO'},
        { number: 5, wochentag: 'Freitag', kuerzel: 'FR'},
        { number: 6, wochentag: 'Samstag', kuerzel: 'SA'},
        { number: 0, wochentag: 'Sonntag', kuerzel: 'SO'}];


    zeitpunktTexte$: Observable<ZeitpunktTexte>;

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private store: Store<PageState>,
                public settingsProvider: SettingsProvider,
                public platform: Platform,
                private uploadCommandService: UploadService,
                public loadingCtrl: LoadingController,
                private uuiService: UuidService,
                private zone: NgZone,
                private imageService: ImageService,
                public domSanitizer: DomSanitizer,
                private modalCtrl: ModalController) {

        this.themeDefault$ = this.settingsProvider.getThemeDefault();
        this.logoHeaderSVG$ = this.settingsProvider.getLogoHeaderSVG();
        this.settings$ = this.store.pipe(select(getTheme));
    }


    ngOnInit() {
        this.zeitpunktTexte$ = this.store.pipe(select(getZeitpunktTexte));

        if (this.aufgabe != null) {
            // console.log('Aufgabesetzen: ', this.aufgabe);

            // Erinnerungen
            this.erinnerungTage = this.aufgabe.erinnerung_relativ_tage;
            this.erinnerungRelativStunden = ((this.aufgabe.erinnerung_relativ_minuten % 1440) - ((this.aufgabe.erinnerung_relativ_minuten % 1440) % 60)) / 60;
            this.erinnerungRelativMinuten = this.aufgabe.erinnerung_relativ_minuten % 60;
            // console.log('Erinnerungen Tage: ' + this.erinnerungTage);
            // console.log('Erinnerungen Stunden: ' + this.erinnerungStunden);
            // console.log('Erinnerungen Minuten: ' + this.erinnerungMinuten);

            // Eskalationen
            this.eskalationTage = this.aufgabe.eskalation_relativ_tage;
            this.eskalationRelativStunden = ((this.aufgabe.eskalation_relativ_minuten % 1440) - ((this.aufgabe.eskalation_relativ_minuten % 1440) % 60)) / 60;
            this.eskalationRelativMinuten = this.aufgabe.eskalation_relativ_minuten % 60;
            // console.log('Eskalationen Tage: ' + this.eskalationTage);
            // console.log('Eskalationen Stunden: ' + this.eskalationStunden);
            // console.log('Eskalationen Minuten: ' + this.eskalationMinuten);

            this.erinnerungReferenzZeitpunkt = this.aufgabe.referenz_zeitpunkt;
            this.relation = this.aufgabe.relation;

            this.titel = this.aufgabe.titel;
            this.beschreibung = this.aufgabe.beschreibung;
            this.istOptional = this.aufgabe.ist_optional;
            this.istOptionalStandardAktiviert = this.aufgabe.optional_standard_aktiviert;
            this.istVeranstaltungsrelevant = this.aufgabe.ist_veranstaltungsrelevant;
            this.istErinnerungAktiv = this.aufgabe.ist_erinnerung_aktiv;
            this.istEskalationAktiv = this.aufgabe.ist_eskalation_aktiv;
            this.istEskalationAnAbarbeiterAktiv = this.aufgabe.ist_eskalation_an_abarbeiter_aktiv;
            this.istEskalationAnFreigeberFunktionsbereichAktiv = this.aufgabe.ist_eskalation_an_freigeber_funktionsbereich_aktiv;
            this.istEskalationAnFreigeberChecklisteAktiv = this.aufgabe.ist_eskalation_an_freigeber_checkliste_aktiv;
            this.istEskalationAnVeranstaltungsleitungAktiv = this.aufgabe.ist_eskalation_an_veranstaltungsleiter_aktiv;

            this.istErinnerungUhrzeitFix = this.aufgabe.ist_erinnerung_uhrzeit_fix;
            this.istEskalationUhrzeitFix = this.aufgabe.ist_eskalation_uhrzeit_fix;

            this.istErinnerungWochentagFix = this.aufgabe.ist_erinnerung_wochentag_fix;
            this.istEskalationWochentagFix = this.aufgabe.ist_eskalation_wochentag_fix;

            this.erinnerungWochentag = this.aufgabe.erinnerung_fix_wochentag;
            this.eskalationWochentag = this.aufgabe.eskalation_fix_wochentag;

            this.erinnerungUhrzeitFixStunden = this.aufgabe.erinnerung_uhrzeit_fix_stunden;
            this.erinnerungUhrzeitFixMinuten = this.aufgabe.erinnerung_uhrzeit_fix_minuten;
            this.eskalationUhrzeitFixStunden = this.aufgabe.eskalation_uhrzeit_fix_stunden;
            this.eskalationUhrzeitFixMinuten = this.aufgabe.eskalation_uhrzeit_fix_minuten;

            this.bilderIds = Object.assign(this.aufgabe.bild_ids);

            while (this.aufgabeBilder.length > 0) {
                this.aufgabeBilder.pop();
            }
            this.zone.run(() => {
                this.aufgabeBilder = [];
                this.aufgabeBilder$ = of(this.aufgabeBilder);
            });

            if (this.bilderIds !== undefined && this.bilderIds !== null && this.bilderIds.length > 0) {

                this.bilderIds.forEach(bildId => {
                    this.getAufgabeBild(this.aufgabe.aufgabe_id, bildId)
                        .pipe(
                            takeWhile(() => this.alive)
                            , filter(image => {
                                return !!image;
                            }),
                            catchError((error: any) => {
                                console.debug('ERROR :: ', error);
                                return of();
                            }),
                        )
                        .subscribe((image: BlobPart) => {
                                const imgtype = bildId.split('.')[1];
                                if (image) {
                                    const blob = new Blob([image]);

                                    const reader = new FileReader();
                                    reader.onload = function () {
                                        const readerResult = reader.result.toString().replace('application/octet-stream', 'image/jpeg');
                                        const imgBase64JPEG = 'data:image/' + imgtype + ';base64,' + readerResult.split('base64,')[1];

                                        const bi = <BildInfo>{
                                            bildId,
                                            data: imgBase64JPEG,
                                            dataTrusted: this.domSanitizer.bypassSecurityTrustUrl(imgBase64JPEG),
                                            type: 'image/' + imgtype
                                        };

                                        this.zone.run(() => {
                                            this.aufgabeBilder.push(bi);
                                            this.aufgabeBilder$ = of(this.aufgabeBilder);
                                        });

                                    }.bind(this);

                                    reader.readAsDataURL(blob);
                                }
                            },
                            error => {

                            });
                });
            }

        } else {
            this.aufgabe = this.createAufgabe();
            this.aufgabe.aufgabe_id = this.uuiService.generateUUID();
        }

        this.erinnerungUhrzeitTimePicker.setHours(this.erinnerungUhrzeitFixStunden);
        this.erinnerungUhrzeitTimePicker.setMinutes(this.erinnerungUhrzeitFixMinuten);
        this.eskalationUhrzeitTimePicker.setHours(this.eskalationUhrzeitFixStunden);
        this.eskalationUhrzeitTimePicker.setMinutes(this.eskalationUhrzeitFixMinuten);

        this.demoVeranstaltungsbeginn = new Date(Date.now());
        this.demoVeranstaltungsbeginn.setDate(this.demoVeranstaltungsbeginn.getDate() + 14);
        this.demoVeranstaltungsbeginn.setMinutes(0);
        this.demoVeranstaltungsbeginn.setSeconds(0);
        this.demoVeranstaltungsbeginn.setMilliseconds(0);

        this.demoEinlass = new Date(this.demoVeranstaltungsbeginn);
        this.demoEinlass.setHours(this.demoEinlass.getHours() - 2);


        this.updateBeispieldaten();

        setTimeout(() => {
            if (this.titelInput !== null && this.titelInput !== undefined) {
                this.titelInput.setFocus();
            }
        }, 500);
    }

    getAufgabeBild(aufgabeId: string, bildId: string): Observable<any> {
        const requestOptions = {
            responseType: ResponseContentType.Blob
        };
        return this.imageService.getAufgabeVorlageBildWithHttpInfo(aufgabeId, bildId, requestOptions);
    }

    createAufgabe(erinnerungMinuten: number = 0, eskalationMinuten: number = 0): Aufgabe {
        return <Aufgabe>{
            titel: this.titel,
            beschreibung: this.beschreibung,
            erinnerung_relativ_minuten: Number(erinnerungMinuten),
            eskalation_relativ_minuten: Number(eskalationMinuten),
            referenz_zeitpunkt: this.erinnerungReferenzZeitpunkt,
            ist_optional: Boolean(this.istOptional),
            optional_standard_aktiviert: Boolean(this.istOptionalStandardAktiviert),
            ist_veranstaltungsrelevant: Boolean(this.istVeranstaltungsrelevant),
            ist_erinnerung_aktiv: Boolean(this.istErinnerungAktiv),
            ist_eskalation_aktiv: Boolean(this.istEskalationAktiv),
            ist_eskalation_an_abarbeiter_aktiv: Boolean(this.istEskalationAnAbarbeiterAktiv),
            ist_eskalation_an_freigeber_funktionsbereich_aktiv: Boolean(this.istEskalationAnFreigeberFunktionsbereichAktiv),
            ist_eskalation_an_freigeber_checkliste_aktiv: Boolean(this.istEskalationAnFreigeberChecklisteAktiv),
            ist_eskalation_an_veranstaltungsleiter_aktiv: Boolean(this.istEskalationAnVeranstaltungsleitungAktiv),
            ist_erinnerung_uhrzeit_fix: Boolean(this.istErinnerungUhrzeitFix),
            ist_eskalation_uhrzeit_fix: Boolean(this.istEskalationUhrzeitFix),
            erinnerung_relativ_tage: Number(this.erinnerungTage),
            erinnerung_uhrzeit_fix_stunden: Number(this.erinnerungUhrzeitFixStunden),
            erinnerung_uhrzeit_fix_minuten: Number(this.erinnerungUhrzeitFixMinuten),
            eskalation_relativ_tage: Number(this.eskalationTage),
            eskalation_uhrzeit_fix_stunden: Number(this.eskalationUhrzeitFixStunden),
            eskalation_uhrzeit_fix_minuten: Number(this.eskalationUhrzeitFixMinuten),
            reihenfolge: (this.aufgabe) ? this.aufgabe.reihenfolge : 0,
            ist_eskalation_wochentag_fix: Boolean(this.istEskalationWochentagFix),
            ist_erinnerung_wochentag_fix: Boolean(this.istErinnerungWochentagFix),
            eskalation_fix_wochentag: Number(this.eskalationWochentag),
            erinnerung_fix_wochentag: Number(this.erinnerungWochentag),
            relation: this.relation.toUpperCase(),
            bild_ids: this.aufgabeBilder.map(x => x.bildId)
        };
    }

    ok() {

        if (this.erinnerungTage.toString() === '' || this.erinnerungTage.toString() === undefined) {
            this.erinnerungTage = 0;
        }
        if (this.erinnerungRelativStunden.toString() === '' || this.erinnerungRelativStunden.toString() === undefined) {
            this.erinnerungRelativStunden = 0;
        }
        if (this.erinnerungRelativMinuten.toString() === '' || this.erinnerungRelativMinuten.toString() === undefined) {
            this.erinnerungRelativMinuten = 0;
        }

        if (this.eskalationTage.toString() === '' || this.eskalationTage.toString() === undefined) {
            this.eskalationTage = 0;
        }
        if (this.eskalationRelativStunden.toString() === '' || this.eskalationRelativStunden.toString() === undefined) {
            this.eskalationRelativStunden = 0;
        }
        if (this.eskalationRelativMinuten.toString() === '' || this.eskalationRelativMinuten.toString() === undefined) {
            this.eskalationRelativMinuten = 0;
        }

        const erinnerungMinuten = (Boolean(this.istErinnerungAktiv)) ? parseInt(this.erinnerungTage.toString(), 0) * 1440 + parseInt(this.erinnerungRelativStunden.toString(), 0) * 60 + parseInt(this.erinnerungRelativMinuten.toString(), 0) : 0;

        const eskalationMinuten = (Boolean(this.istEskalationAktiv)) ? parseInt(this.eskalationTage.toString(), 0) * 1440 + parseInt(this.eskalationRelativStunden.toString(), 0) * 60 + parseInt(this.eskalationRelativMinuten.toString(), 0) : 0;

        const aufgabe = this.createAufgabe(erinnerungMinuten, eskalationMinuten);

        const data = {aufgabe};

        // console.log('Aufgabe speichern: ', aufgabe);
        this.modalCtrl.dismiss(data);
    }

    abbrechen() {
        this.modalCtrl.dismiss();
    }

    erinnerungSchalten(state: boolean) {
        if (state === false) {
            this.istEskalationAktiv = state;
            this.istEskalationAnAbarbeiterAktiv = state;
            this.istEskalationAnFreigeberFunktionsbereichAktiv = state;
            this.istEskalationAnFreigeberChecklisteAktiv = state;
            this.istEskalationAnVeranstaltungsleitungAktiv = state;
        }
        this.updateBeispieldaten();
    }

    eskalationSchalten(state: boolean) {
        this.istEskalationAnAbarbeiterAktiv = state;
        this.istEskalationAnFreigeberFunktionsbereichAktiv = state;
        this.istEskalationAnFreigeberChecklisteAktiv = state;
        this.istEskalationAnVeranstaltungsleitungAktiv = state;
        this.updateBeispieldaten();
    }

    radioGroupErinnerungVorOderNachChange(event) {
        if (event.detail.value) {
            this.relation = event.detail.value;
            this.updateBeispieldaten();
        }
    }

    radioGroupErinnerungReferenzZeitpunktChange(event) {
        if (event.detail.value) {
            this.erinnerungReferenzZeitpunkt = event.detail.value;
            this.updateBeispieldaten();
        }
    }

    updateBeispieldaten() {

        if (this.istErinnerungAktiv) {
            if (this.erinnerungReferenzZeitpunkt === 'EINLASS') {
                this.demoErinnerungszeitpunkt = new Date(this.demoEinlass);
            } else {
                this.demoErinnerungszeitpunkt = new Date(this.demoVeranstaltungsbeginn);
            }
        } else {
            this.demoErinnerungszeitpunkt = null;
        }
        if (this.istEskalationAktiv) {
            if (this.erinnerungReferenzZeitpunkt === 'EINLASS') {
                this.demoEskalationszeitpunkt = new Date(this.demoEinlass);
            } else {
                this.demoEskalationszeitpunkt = new Date(this.demoVeranstaltungsbeginn);
            }
        } else {
            this.demoEskalationszeitpunkt = null;
        }

        // console.log('UpdateBeispielDaten: Erinnerung ', this.demoErinnerungszeitpunkt);
        // console.log('UpdateBeispielDaten: Eskalation ', this.demoEskalationszeitpunkt);

        const erinnerungTage = parseInt(this.erinnerungTage.toString(), 0);
        // const erinnerungWochentag = parseInt(this.erinnerungWochentag.toString(), 0);
        const erinnerungStunden = parseInt(this.erinnerungRelativStunden.toString(), 0);
        const erinnerungMinuten = parseInt(this.erinnerungRelativMinuten.toString(), 0);

        const eskalationTage = parseInt(this.eskalationTage.toString(), 0);
        // const eskalationWochentag = parseInt(this.eskalationWochentag.toString(), 0);
        const eskalationStunden = parseInt(this.eskalationRelativStunden.toString(), 0);
        const eskalationMinuten = parseInt(this.eskalationRelativMinuten.toString(), 0);

        const erinnerungUhrzeitFixStunden = parseInt(this.erinnerungUhrzeitFixStunden.toString(), 0);
        const erinnerungUhrzeitFixMinuten = parseInt(this.erinnerungUhrzeitFixMinuten.toString(), 0);

        const eskalationUhrzeitFixStunden = parseInt(this.eskalationUhrzeitFixStunden.toString(), 0);
        const eskalationUhrzeitFixMinuten = parseInt(this.eskalationUhrzeitFixMinuten.toString(), 0);

        if (this.istErinnerungAktiv) {
            if (!this.istErinnerungWochentagFix) {
                // Relativer Tag
                if (!this.istErinnerungUhrzeitFix) {
                    // relativer Zeitpunkt
                    if (this.relation.toUpperCase() === 'VOR') {
                        this.demoErinnerungszeitpunkt.setDate(this.demoErinnerungszeitpunkt.getDate() - erinnerungTage);
                        this.demoErinnerungszeitpunkt.setHours(this.demoErinnerungszeitpunkt.getHours() - erinnerungStunden);
                        this.demoErinnerungszeitpunkt.setMinutes(this.demoErinnerungszeitpunkt.getMinutes() - erinnerungMinuten);
                    } else {
                        this.demoErinnerungszeitpunkt.setDate(this.demoErinnerungszeitpunkt.getDate() + erinnerungTage);
                        this.demoErinnerungszeitpunkt.setHours(this.demoErinnerungszeitpunkt.getHours() + erinnerungStunden);
                        this.demoErinnerungszeitpunkt.setMinutes(this.demoErinnerungszeitpunkt.getMinutes() + erinnerungMinuten);
                    }
                } else {
                    // feste Uhrzeit
                    if (this.relation.toUpperCase() === 'VOR') {
                        this.demoErinnerungszeitpunkt.setDate(this.demoErinnerungszeitpunkt.getDate() - erinnerungTage);
                        this.demoErinnerungszeitpunkt.setHours(erinnerungUhrzeitFixStunden);
                        this.demoErinnerungszeitpunkt.setMinutes(erinnerungUhrzeitFixMinuten);
                    } else {
                        this.demoErinnerungszeitpunkt.setDate(this.demoErinnerungszeitpunkt.getDate() + erinnerungTage);
                        this.demoErinnerungszeitpunkt.setHours(erinnerungUhrzeitFixStunden);
                        this.demoErinnerungszeitpunkt.setMinutes(erinnerungUhrzeitFixMinuten);
                    }
                }
                // console.log('relativer Tag, erinnerung: ', this.demoErinnerungszeitpunkt);
                // console.log('relativer Tag, eskalation: ', this.demoEskalationszeitpunkt);
            } else {
                // fester Tag
                if (!this.istErinnerungUhrzeitFix) {
                    // relativer Zeitpunkt
                    if (this.relation.toUpperCase() === 'VOR') {
                        if (!(this.demoErinnerungszeitpunkt.getDay() === this.erinnerungWochentag)) {
                            while (!(this.demoErinnerungszeitpunkt.getDay() === this.erinnerungWochentag)) {
                                this.demoErinnerungszeitpunkt = this.addDays(this.demoErinnerungszeitpunkt, -1);
                            }
                        }
                        this.demoErinnerungszeitpunkt.setHours(this.demoErinnerungszeitpunkt.getHours() - erinnerungStunden);
                        this.demoErinnerungszeitpunkt.setMinutes(this.demoErinnerungszeitpunkt.getMinutes() - erinnerungMinuten);
                    } else {
                        this.demoErinnerungszeitpunkt = this.addDays(this.demoErinnerungszeitpunkt, 1);
                        while (!(this.demoErinnerungszeitpunkt.getDay() === this.erinnerungWochentag)) {
                            this.demoErinnerungszeitpunkt = this.addDays(this.demoErinnerungszeitpunkt, 1);
                        }
                        this.demoErinnerungszeitpunkt.setHours(this.demoErinnerungszeitpunkt.getHours() + erinnerungStunden);
                        this.demoErinnerungszeitpunkt.setMinutes(this.demoErinnerungszeitpunkt.getMinutes() + erinnerungMinuten);
                    }
                } else {
                    // feste Uhrzeit
                    if (this.relation.toUpperCase() === 'VOR') {
                        if (!(this.demoErinnerungszeitpunkt.getDay() === this.erinnerungWochentag)) {
                            while (!(this.demoErinnerungszeitpunkt.getDay() === this.erinnerungWochentag)) {
                                this.demoErinnerungszeitpunkt = this.addDays(this.demoErinnerungszeitpunkt, -1);
                            }
                        }
                        this.demoErinnerungszeitpunkt.setHours(erinnerungUhrzeitFixStunden);
                        this.demoErinnerungszeitpunkt.setMinutes(erinnerungUhrzeitFixMinuten);
                    } else {
                        this.demoErinnerungszeitpunkt = this.addDays(this.demoErinnerungszeitpunkt, 1);
                        while (!(this.demoErinnerungszeitpunkt.getDay() === this.erinnerungWochentag)) {
                            this.demoErinnerungszeitpunkt = this.addDays(this.demoErinnerungszeitpunkt, 1);
                        }
                        this.demoErinnerungszeitpunkt.setHours(erinnerungUhrzeitFixStunden);
                        this.demoErinnerungszeitpunkt.setMinutes(erinnerungUhrzeitFixMinuten);
                    }
                }
                // console.log('fester Tag, erinnerung: ', this.demoErinnerungszeitpunkt);
                // console.log('fester Tag, eskalation: ', this.demoEskalationszeitpunkt);
            }
        }

        if (this.istEskalationAktiv) {
            if (!this.istEskalationWochentagFix) {
                // Relativer Tag
                if (!this.istEskalationUhrzeitFix) {
                    // relativer Zeitpunkt
                    if (this.relation.toUpperCase() === 'VOR') {
                        this.demoEskalationszeitpunkt.setDate(this.demoEskalationszeitpunkt.getDate() - eskalationTage);
                        this.demoEskalationszeitpunkt.setHours(this.demoEskalationszeitpunkt.getHours() - eskalationStunden);
                        this.demoEskalationszeitpunkt.setMinutes(this.demoEskalationszeitpunkt.getMinutes() - eskalationMinuten);
                    } else {
                        this.demoEskalationszeitpunkt.setDate(this.demoEskalationszeitpunkt.getDate() + eskalationTage);
                        this.demoEskalationszeitpunkt.setHours(this.demoEskalationszeitpunkt.getHours() + eskalationStunden);
                        this.demoEskalationszeitpunkt.setMinutes(this.demoEskalationszeitpunkt.getMinutes() + eskalationMinuten);
                    }
                } else {
                    // feste Uhrzeit
                    if (this.relation.toUpperCase() === 'VOR') {
                        this.demoEskalationszeitpunkt.setDate(this.demoEskalationszeitpunkt.getDate() - eskalationTage);
                        this.demoEskalationszeitpunkt.setHours(eskalationUhrzeitFixStunden);
                        this.demoEskalationszeitpunkt.setMinutes(eskalationUhrzeitFixMinuten);
                    } else {
                        this.demoEskalationszeitpunkt.setDate(this.demoEskalationszeitpunkt.getDate() + eskalationTage);
                        this.demoEskalationszeitpunkt.setHours(eskalationUhrzeitFixStunden);
                        this.demoEskalationszeitpunkt.setMinutes(eskalationUhrzeitFixMinuten);
                    }
                }
            } else {
                // fester Tag
                if (!this.istEskalationUhrzeitFix) {
                    // relativer Zeitpunkt
                    if (this.relation.toUpperCase() === 'VOR') {
                        if (!(this.demoEskalationszeitpunkt.getDay() === this.eskalationWochentag)) {
                            while (!(this.demoEskalationszeitpunkt.getDay() === this.eskalationWochentag)) {
                                this.demoEskalationszeitpunkt = this.addDays(this.demoEskalationszeitpunkt, -1);
                            }
                        }
                        this.demoEskalationszeitpunkt.setHours(this.demoEskalationszeitpunkt.getHours() - eskalationStunden);
                        this.demoEskalationszeitpunkt.setMinutes(this.demoEskalationszeitpunkt.getMinutes() - eskalationMinuten);
                    } else {
                        this.demoEskalationszeitpunkt = this.addDays(this.demoEskalationszeitpunkt, 1);
                        while (!(this.demoEskalationszeitpunkt.getDay() === this.eskalationWochentag)) {
                            this.demoEskalationszeitpunkt = this.addDays(this.demoEskalationszeitpunkt, 1);
                        }
                        this.demoEskalationszeitpunkt.setHours(this.demoEskalationszeitpunkt.getHours() + eskalationStunden);
                        this.demoEskalationszeitpunkt.setMinutes(this.demoEskalationszeitpunkt.getMinutes() + eskalationMinuten);
                    }
                } else {
                    // feste Uhrzeit
                    if (this.relation.toUpperCase() === 'VOR') {
                        if (!(this.demoEskalationszeitpunkt.getDay() === this.eskalationWochentag)) {
                            while (!(this.demoEskalationszeitpunkt.getDay() === this.eskalationWochentag)) {
                                this.demoEskalationszeitpunkt = this.addDays(this.demoEskalationszeitpunkt, -1);
                            }
                        }
                        this.demoEskalationszeitpunkt.setHours(eskalationUhrzeitFixStunden);
                        this.demoEskalationszeitpunkt.setMinutes(eskalationUhrzeitFixMinuten);
                    } else {
                        this.demoEskalationszeitpunkt = this.addDays(this.demoEskalationszeitpunkt, 1);
                        while (!(this.demoEskalationszeitpunkt.getDay() === this.eskalationWochentag)) {
                            this.demoEskalationszeitpunkt = this.addDays(this.demoEskalationszeitpunkt, 1);
                        }
                        this.demoEskalationszeitpunkt.setHours(eskalationUhrzeitFixStunden);
                        this.demoEskalationszeitpunkt.setMinutes(eskalationUhrzeitFixMinuten);
                    }
                }
            }
        }
    }

    addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }

    select($event) {
        if ($event != null && $event.target != null && typeof $event.target.select === 'function') {
            $event.target.select();
        }
    }

    changeErinnerungUhrzeit($event: Md2DateChange) {
        this.erinnerungUhrzeitFixStunden = $event.value.getHours();
        this.erinnerungUhrzeitFixMinuten = $event.value.getMinutes();
        this.updateBeispieldaten();
    }

    changeEskalationUhrzeit($event: Md2DateChange) {
        this.eskalationUhrzeitFixStunden = $event.value.getHours();
        this.eskalationUhrzeitFixMinuten = $event.value.getMinutes();
        this.updateBeispieldaten();
    }


    setzeErinnerungUhrzeitRelativ($event: CustomEvent) {
        if ($event.detail.checked === false) {
            this.erinnerungRelativStunden = 0;
            this.erinnerungRelativMinuten = 0;
        } else if ($event.detail.checked === true) {
            this.erinnerungUhrzeitFixStunden = 0;
            this.erinnerungUhrzeitFixMinuten = 0;
            this.erinnerungUhrzeitTimePicker.setHours(this.erinnerungUhrzeitFixStunden);
            this.erinnerungUhrzeitTimePicker.setMinutes(this.erinnerungUhrzeitFixMinuten);
        }
        this.istErinnerungUhrzeitFix = !$event.detail.checked;
        this.updateBeispieldaten();
    }

    setzeErinnerungWochentagRelativ($event: CustomEvent) {
        // console.log('setze: ', !$event.detail.checked);
        if ($event.detail.checked === false) {
            this.erinnerungTage = 0;
        } else if ($event.detail.checked === true) {
            this.erinnerungWochentag = 0;
        }
        this.istErinnerungWochentagFix = !$event.detail.checked;
        this.updateBeispieldaten();
    }

    setzeEskalationUhrzeitRelativ($event: CustomEvent) {
        if ($event.detail.checked === false) {
            this.eskalationRelativStunden = 0;
            this.eskalationRelativMinuten = 0;
        } else if ($event.detail.checked === true) {
            this.eskalationUhrzeitFixStunden = 0;
            this.eskalationUhrzeitFixMinuten = 0;
            this.eskalationUhrzeitTimePicker.setHours(this.eskalationUhrzeitFixStunden);
            this.eskalationUhrzeitTimePicker.setMinutes(this.eskalationUhrzeitFixMinuten);
        }
        this.istEskalationUhrzeitFix = !$event.detail.checked;
        this.updateBeispieldaten();
    }

    setzeEskalationWochentagRelativ($event: CustomEvent) {
        if ($event.detail.checked === false) {
            this.eskalationTage = 0;
        } else if ($event.detail.checked === true) {
            this.eskalationWochentag = 0;
        }
        this.istEskalationWochentagFix = !$event.detail.checked;
        this.updateBeispieldaten();
    }

    changeWeekday($event: CustomEvent) {
        this.erinnerungWochentag = $event.detail.value;
        this.updateBeispieldaten();
    }

    changeWeekdayEskalation($event: CustomEvent) {
        this.eskalationWochentag = $event.detail.value;
        this.updateBeispieldaten();
    }

    async bildAuswahlen() {
        this.logoFileInput.nativeElement.click();
    }

    async bildEinfuegen() {
        // let fi = this.logoFileInput['_native'].nativeElement;
        const fi = this.logoFileInput.nativeElement;
        console.log('fi.files.length:', fi.files.length);
        if (fi.files && fi.files[0]) {
            for (let i = 0; i < fi.files.length; i++) {
                this.uploadFile(fi.files[i]).then();
            }
        }
    }

    async uploadFile (fileToUpload) {
        const loading = await this.loadingCtrl.create({
            spinner: 'dots',
            message: 'Das Bild wird hochgeladen ...',
            translucent: true,
            backdropDismiss: false,
            keyboardClose: true
        });

        await loading.present();


        const reader = new FileReader();
        let base64String;
        reader.readAsDataURL(fileToUpload);
        reader.onloadend = function () {
            base64String = reader.result;
        };

        const uploadId = `${this.uuiService.generateUUID()}.jpeg`;
        const folder = 'vorlagen/aufgaben/' + this.aufgabe.aufgabe_id + '/bilder';

        this.uploadCommandService.postUploadForm(fileToUpload, folder, uploadId).subscribe((result: UploadResponse) => {
            const imgtype = result.upload_id.split('.')[1];

            const bi = <BildInfo>{
                bildId: result.upload_id,
                data: base64String,
                dataTrusted: this.domSanitizer.bypassSecurityTrustUrl(base64String),
                type: 'image/' + imgtype
            };

            this.bilderIds = [...this.bilderIds, result.upload_id];
            this.zone.run(() => {
                this.aufgabeBilder.push(bi);
                this.aufgabeBilder$ = of(this.aufgabeBilder);
            });

            loading.dismiss();
        }, () => {
        });
    }

    async bilderAnzeigen(index) {
        const imageModal = await this.modalCtrl.create({
            component: BildVorschauComponent,
            componentProps: {
                bilder: Object.assign([], this.aufgabeBilder),
                showDeleteButton: true,
                initialImage: index
            }
        });
        imageModal.onDidDismiss().then(result => {
            if (result.data && result.data.length > 0) {
                result.data.forEach(bildId => {
                    const index2 = this.aufgabeBilder.findIndex(x => x.bildId === bildId);
                    if (index2 > -1) {
                        this.aufgabeBilder.splice(index2, 1);
                    }
                    const indexIds = this.bilderIds.findIndex(x => x === bildId);
                    console.log('indexIds: ', indexIds);
                    console.log('aufgabeBilder: ', this.aufgabeBilder);
                    if (indexIds > -1) {
                        const arr = [...this.bilderIds];
                        arr.splice(indexIds, 1);
                        console.log('Bild_ids: ', this.bilderIds);
                        console.log('arr: ', arr);
                        this.bilderIds = [...arr];
                    }
                });
                this.aufgabeBilder$ = of(this.aufgabeBilder);
            }
        });
        await imageModal.present();
    }

}
