import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_ROLLE_EXTERNER_ANSPRECHPARTNER_FESTLEGEN = '[BenutzerverwaltungModul] PostRolleExternerAnsprechpartnerFestlegen laden';
export const POST_ROLLE_EXTERNER_ANSPRECHPARTNER_FESTLEGEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostRolleExternerAnsprechpartnerFestlegen ausführen';
export const POST_ROLLE_EXTERNER_ANSPRECHPARTNER_FESTLEGEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostRolleExternerAnsprechpartnerFestlegen laden erfolgreich';
export const POST_ROLLE_EXTERNER_ANSPRECHPARTNER_FESTLEGEN_FEHLER = '[BenutzerverwaltungModul] PostRolleExternerAnsprechpartnerFestlegen Ladefehler';
export const POST_ROLLE_EXTERNER_ANSPRECHPARTNER_FESTLEGEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostRolleExternerAnsprechpartnerFestlegen nicht ausgeführt (evtl. Offline)';



export class PostRolleExternerAnsprechpartnerFestlegenAction implements Action {
    readonly type = POST_ROLLE_EXTERNER_ANSPRECHPARTNER_FESTLEGEN;
        constructor(public rolleExternerAnsprechpartnerFestlegenRequest: api.RolleExternerAnsprechpartnerFestlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleExternerAnsprechpartnerFestlegenAusfuehrenAction implements Action {
    readonly type = POST_ROLLE_EXTERNER_ANSPRECHPARTNER_FESTLEGEN_AUSFUEHREN;
        constructor(public rolleExternerAnsprechpartnerFestlegenRequest: api.RolleExternerAnsprechpartnerFestlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleExternerAnsprechpartnerFestlegenErfolgreichAction implements Action {
    readonly type = POST_ROLLE_EXTERNER_ANSPRECHPARTNER_FESTLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public rolleExternerAnsprechpartnerFestlegenRequest: api.RolleExternerAnsprechpartnerFestlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleExternerAnsprechpartnerFestlegenFehlerAction implements Action {
    readonly type = POST_ROLLE_EXTERNER_ANSPRECHPARTNER_FESTLEGEN_FEHLER;
        constructor(public payload: any, public rolleExternerAnsprechpartnerFestlegenRequest: api.RolleExternerAnsprechpartnerFestlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleExternerAnsprechpartnerFestlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_ROLLE_EXTERNER_ANSPRECHPARTNER_FESTLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandRolleExternerAnsprechpartnerFestlegenActions =
    PostRolleExternerAnsprechpartnerFestlegenAction
    | PostRolleExternerAnsprechpartnerFestlegenAusfuehrenAction
    | PostRolleExternerAnsprechpartnerFestlegenErfolgreichAction
    | PostRolleExternerAnsprechpartnerFestlegenFehlerAction
    | PostRolleExternerAnsprechpartnerFestlegenNichtAusgefuehrtAction
    ;
