import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGS_TYP_ROLLE_BENUTZER = '[VeranstaltungstypenModul] GetVeranstaltungsTypRolleBenutzer laden';
export const GET_VERANSTALTUNGS_TYP_ROLLE_BENUTZER_AUSFUEHREN = '[VeranstaltungstypenModul] GetVeranstaltungsTypRolleBenutzer ausführen';
export const GET_VERANSTALTUNGS_TYP_ROLLE_BENUTZER_ERFOLGREICH = '[VeranstaltungstypenModul] GetVeranstaltungsTypRolleBenutzer laden erfolgreich';
export const GET_VERANSTALTUNGS_TYP_ROLLE_BENUTZER_FEHLER = '[VeranstaltungstypenModul] GetVeranstaltungsTypRolleBenutzer Ladefehler';
export const GET_VERANSTALTUNGS_TYP_ROLLE_BENUTZER_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] GetVeranstaltungsTypRolleBenutzer nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungsTypRolleBenutzerAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_ROLLE_BENUTZER;
        constructor( public veranstaltungstyp_id:string ,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypRolleBenutzerAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_ROLLE_BENUTZER_AUSFUEHREN;
        constructor( public veranstaltungstyp_id:string ,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypRolleBenutzerErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_ROLLE_BENUTZER_ERFOLGREICH;
        constructor(public payload: Array<api.RolleBenutzer>,  public veranstaltungstyp_id:string ,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypRolleBenutzerFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_ROLLE_BENUTZER_FEHLER;
        constructor(public payload: any,  public veranstaltungstyp_id:string ,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypRolleBenutzerNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_ROLLE_BENUTZER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenQueryVeranstaltungsTypRolleBenutzerActions =
    GetVeranstaltungsTypRolleBenutzerAction
    | GetVeranstaltungsTypRolleBenutzerAusfuehrenAction
    | GetVeranstaltungsTypRolleBenutzerErfolgreichAction
    | GetVeranstaltungsTypRolleBenutzerFehlerAction
    | GetVeranstaltungsTypRolleBenutzerNichtAusgefuehrtAction
    ;
