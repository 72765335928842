import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const GET_DISPLAYSTEUERUNG_BENUTZER_ZUGRIFF = '[DisplaysteuerungModul] GetDisplaysteuerungBenutzerZugriff laden';
export const GET_DISPLAYSTEUERUNG_BENUTZER_ZUGRIFF_AUSFUEHREN = '[DisplaysteuerungModul] GetDisplaysteuerungBenutzerZugriff ausführen';
export const GET_DISPLAYSTEUERUNG_BENUTZER_ZUGRIFF_ERFOLGREICH = '[DisplaysteuerungModul] GetDisplaysteuerungBenutzerZugriff laden erfolgreich';
export const GET_DISPLAYSTEUERUNG_BENUTZER_ZUGRIFF_FEHLER = '[DisplaysteuerungModul] GetDisplaysteuerungBenutzerZugriff Ladefehler';
export const GET_DISPLAYSTEUERUNG_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] GetDisplaysteuerungBenutzerZugriff nicht ausgeführt (evtl. Offline)';



export class GetDisplaysteuerungBenutzerZugriffAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_BENUTZER_ZUGRIFF;
        constructor(public optPayload: any = null) {}
    }
export class GetDisplaysteuerungBenutzerZugriffAusfuehrenAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_BENUTZER_ZUGRIFF_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetDisplaysteuerungBenutzerZugriffErfolgreichAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_BENUTZER_ZUGRIFF_ERFOLGREICH;
        constructor(public payload: api.DisplaysteuerungBenutzerZugriff, public optPayload: any = null) {}
    }
export class GetDisplaysteuerungBenutzerZugriffFehlerAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_BENUTZER_ZUGRIFF_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetDisplaysteuerungBenutzerZugriffNichtAusgefuehrtAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungQueryDisplaysteuerungBenutzerZugriffActions =
    GetDisplaysteuerungBenutzerZugriffAction
    | GetDisplaysteuerungBenutzerZugriffAusfuehrenAction
    | GetDisplaysteuerungBenutzerZugriffErfolgreichAction
    | GetDisplaysteuerungBenutzerZugriffFehlerAction
    | GetDisplaysteuerungBenutzerZugriffNichtAusgefuehrtAction
    ;
