import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_BEMERKUNG_SETZEN = '[QschecksModul] PostAufgabeBemerkungSetzen laden';
export const POST_AUFGABE_BEMERKUNG_SETZEN_AUSFUEHREN = '[QschecksModul] PostAufgabeBemerkungSetzen ausführen';
export const POST_AUFGABE_BEMERKUNG_SETZEN_ERFOLGREICH = '[QschecksModul] PostAufgabeBemerkungSetzen laden erfolgreich';
export const POST_AUFGABE_BEMERKUNG_SETZEN_FEHLER = '[QschecksModul] PostAufgabeBemerkungSetzen Ladefehler';
export const POST_AUFGABE_BEMERKUNG_SETZEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostAufgabeBemerkungSetzen nicht ausgeführt (evtl. Offline)';



export class PostAufgabeBemerkungSetzenAction implements Action {
    readonly type = POST_AUFGABE_BEMERKUNG_SETZEN;
        constructor(public prueflaufAufgabeBemerkungRequest: api.PrueflaufAufgabeBemerkungRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBemerkungSetzenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_BEMERKUNG_SETZEN_AUSFUEHREN;
        constructor(public prueflaufAufgabeBemerkungRequest: api.PrueflaufAufgabeBemerkungRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBemerkungSetzenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_BEMERKUNG_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public prueflaufAufgabeBemerkungRequest: api.PrueflaufAufgabeBemerkungRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBemerkungSetzenFehlerAction implements Action {
    readonly type = POST_AUFGABE_BEMERKUNG_SETZEN_FEHLER;
        constructor(public payload: any, public prueflaufAufgabeBemerkungRequest: api.PrueflaufAufgabeBemerkungRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBemerkungSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_BEMERKUNG_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandAufgabeBemerkungSetzenActions =
    PostAufgabeBemerkungSetzenAction
    | PostAufgabeBemerkungSetzenAusfuehrenAction
    | PostAufgabeBemerkungSetzenErfolgreichAction
    | PostAufgabeBemerkungSetzenFehlerAction
    | PostAufgabeBemerkungSetzenNichtAusgefuehrtAction
    ;
