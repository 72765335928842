import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_SAISON_UEBERSICHT_EXCEL = '[QschecksModul] GetSaisonUebersichtExcel laden';
export const GET_SAISON_UEBERSICHT_EXCEL_AUSFUEHREN = '[QschecksModul] GetSaisonUebersichtExcel ausführen';
export const GET_SAISON_UEBERSICHT_EXCEL_ERFOLGREICH = '[QschecksModul] GetSaisonUebersichtExcel laden erfolgreich';
export const GET_SAISON_UEBERSICHT_EXCEL_FEHLER = '[QschecksModul] GetSaisonUebersichtExcel Ladefehler';
export const GET_SAISON_UEBERSICHT_EXCEL_NICHT_AUSGEFUEHRT = '[QschecksModul] GetSaisonUebersichtExcel nicht ausgeführt (evtl. Offline)';



export class GetSaisonUebersichtExcelAction implements Action {
    readonly type = GET_SAISON_UEBERSICHT_EXCEL;
        constructor(public saisonUebersichtRequest: api.SaisonUebersichtRequest, public optPayload: any = null) {}
    }
export class GetSaisonUebersichtExcelAusfuehrenAction implements Action {
    readonly type = GET_SAISON_UEBERSICHT_EXCEL_AUSFUEHREN;
        constructor(public saisonUebersichtRequest: api.SaisonUebersichtRequest, public optPayload: any = null) {}
    }
export class GetSaisonUebersichtExcelErfolgreichAction implements Action {
    readonly type = GET_SAISON_UEBERSICHT_EXCEL_ERFOLGREICH;
        constructor(public saisonUebersichtRequest: api.SaisonUebersichtRequest, public optPayload: any = null) {}
    }
export class GetSaisonUebersichtExcelFehlerAction implements Action {
    readonly type = GET_SAISON_UEBERSICHT_EXCEL_FEHLER;
        constructor(public payload: any, public saisonUebersichtRequest: api.SaisonUebersichtRequest, public optPayload: any = null) {}
    }
export class GetSaisonUebersichtExcelNichtAusgefuehrtAction implements Action {
    readonly type = GET_SAISON_UEBERSICHT_EXCEL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandSaisonUebersichtExcelActions =
    GetSaisonUebersichtExcelAction
    | GetSaisonUebersichtExcelAusfuehrenAction
    | GetSaisonUebersichtExcelErfolgreichAction
    | GetSaisonUebersichtExcelFehlerAction
    | GetSaisonUebersichtExcelNichtAusgefuehrtAction
    ;
