import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI = '[VeranstaltungenModul] PostDokumentBerechtigungEntfernenMulti laden';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_AUSFUEHREN = '[VeranstaltungenModul] PostDokumentBerechtigungEntfernenMulti ausführen';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_ERFOLGREICH = '[VeranstaltungenModul] PostDokumentBerechtigungEntfernenMulti laden erfolgreich';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_FEHLER = '[VeranstaltungenModul] PostDokumentBerechtigungEntfernenMulti Ladefehler';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostDokumentBerechtigungEntfernenMulti nicht ausgeführt (evtl. Offline)';



export class PostDokumentBerechtigungEntfernenMultiAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI;
        constructor(public dokumentBerechtigungMultiRequest: api.DokumentBerechtigungMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenMultiAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_AUSFUEHREN;
        constructor(public dokumentBerechtigungMultiRequest: api.DokumentBerechtigungMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenMultiErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungMultiRequest: api.DokumentBerechtigungMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenMultiFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungMultiRequest: api.DokumentBerechtigungMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenMultiNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandDokumentBerechtigungEntfernenMultiActions =
    PostDokumentBerechtigungEntfernenMultiAction
    | PostDokumentBerechtigungEntfernenMultiAusfuehrenAction
    | PostDokumentBerechtigungEntfernenMultiErfolgreichAction
    | PostDokumentBerechtigungEntfernenMultiFehlerAction
    | PostDokumentBerechtigungEntfernenMultiNichtAusgefuehrtAction
    ;
