

import {Action} from '@ngrx/store';

export const GET_TROUBLE_TICKET_BILD = '[KommunikationModul] GetTroubleTicketBild laden';
export const GET_TROUBLE_TICKET_BILD_AUSFUEHREN = '[KommunikationModul] GetTroubleTicketBild ausführen';
export const GET_TROUBLE_TICKET_BILD_ERFOLGREICH = '[KommunikationModul] GetTroubleTicketBild laden erfolgreich';
export const GET_TROUBLE_TICKET_BILD_FEHLER = '[KommunikationModul] GetTroubleTicketBild Ladefehler';
export const GET_TROUBLE_TICKET_BILD_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetTroubleTicketBild nicht ausgeführt (evtl. Offline)';



export class GetTroubleTicketBildAction implements Action {
    readonly type = GET_TROUBLE_TICKET_BILD;
        constructor( public troubleticket_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetTroubleTicketBildAusfuehrenAction implements Action {
    readonly type = GET_TROUBLE_TICKET_BILD_AUSFUEHREN;
        constructor( public troubleticket_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetTroubleTicketBildErfolgreichAction implements Action {
    readonly type = GET_TROUBLE_TICKET_BILD_ERFOLGREICH;
        constructor( public troubleticket_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetTroubleTicketBildFehlerAction implements Action {
    readonly type = GET_TROUBLE_TICKET_BILD_FEHLER;
        constructor(public payload: any,  public troubleticket_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetTroubleTicketBildNichtAusgefuehrtAction implements Action {
    readonly type = GET_TROUBLE_TICKET_BILD_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryTroubleTicketBildActions =
    GetTroubleTicketBildAction
    | GetTroubleTicketBildAusfuehrenAction
    | GetTroubleTicketBildErfolgreichAction
    | GetTroubleTicketBildFehlerAction
    | GetTroubleTicketBildNichtAusgefuehrtAction
    ;
