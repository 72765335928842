import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFOBJEKT_HINZUFUEGEN = '[QschecksModul] PostPruefobjektHinzufuegen laden';
export const POST_PRUEFOBJEKT_HINZUFUEGEN_AUSFUEHREN = '[QschecksModul] PostPruefobjektHinzufuegen ausführen';
export const POST_PRUEFOBJEKT_HINZUFUEGEN_ERFOLGREICH = '[QschecksModul] PostPruefobjektHinzufuegen laden erfolgreich';
export const POST_PRUEFOBJEKT_HINZUFUEGEN_FEHLER = '[QschecksModul] PostPruefobjektHinzufuegen Ladefehler';
export const POST_PRUEFOBJEKT_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefobjektHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostPruefobjektHinzufuegenAction implements Action {
    readonly type = POST_PRUEFOBJEKT_HINZUFUEGEN;
        constructor(public pruefobjektModelRequest: api.PruefobjektModelRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFOBJEKT_HINZUFUEGEN_AUSFUEHREN;
        constructor(public pruefobjektModelRequest: api.PruefobjektModelRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_PRUEFOBJEKT_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefobjektModelRequest: api.PruefobjektModelRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektHinzufuegenFehlerAction implements Action {
    readonly type = POST_PRUEFOBJEKT_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public pruefobjektModelRequest: api.PruefobjektModelRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFOBJEKT_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefobjektHinzufuegenActions =
    PostPruefobjektHinzufuegenAction
    | PostPruefobjektHinzufuegenAusfuehrenAction
    | PostPruefobjektHinzufuegenErfolgreichAction
    | PostPruefobjektHinzufuegenFehlerAction
    | PostPruefobjektHinzufuegenNichtAusgefuehrtAction
    ;
