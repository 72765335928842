import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_BEREITSCHAFT = '[BenutzerverwaltungModul] PostFunktionsbereichEMailBenachrichtigungBereitschaft laden';
export const POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_BEREITSCHAFT_AUSFUEHREN = '[BenutzerverwaltungModul] PostFunktionsbereichEMailBenachrichtigungBereitschaft ausführen';
export const POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_BEREITSCHAFT_ERFOLGREICH = '[BenutzerverwaltungModul] PostFunktionsbereichEMailBenachrichtigungBereitschaft laden erfolgreich';
export const POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_BEREITSCHAFT_FEHLER = '[BenutzerverwaltungModul] PostFunktionsbereichEMailBenachrichtigungBereitschaft Ladefehler';
export const POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_BEREITSCHAFT_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostFunktionsbereichEMailBenachrichtigungBereitschaft nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichEMailBenachrichtigungBereitschaftAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_BEREITSCHAFT;
        constructor(public funktionsbereichEmpfaengerRequest: api.FunktionsbereichEmpfaengerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichEMailBenachrichtigungBereitschaftAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_BEREITSCHAFT_AUSFUEHREN;
        constructor(public funktionsbereichEmpfaengerRequest: api.FunktionsbereichEmpfaengerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichEMailBenachrichtigungBereitschaftErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_BEREITSCHAFT_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funktionsbereichEmpfaengerRequest: api.FunktionsbereichEmpfaengerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichEMailBenachrichtigungBereitschaftFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_BEREITSCHAFT_FEHLER;
        constructor(public payload: any, public funktionsbereichEmpfaengerRequest: api.FunktionsbereichEmpfaengerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichEMailBenachrichtigungBereitschaftNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_EMAIL_BENACHRICHTIGUNG_BEREITSCHAFT_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandFunktionsbereichEMailBenachrichtigungBereitschaftActions =
    PostFunktionsbereichEMailBenachrichtigungBereitschaftAction
    | PostFunktionsbereichEMailBenachrichtigungBereitschaftAusfuehrenAction
    | PostFunktionsbereichEMailBenachrichtigungBereitschaftErfolgreichAction
    | PostFunktionsbereichEMailBenachrichtigungBereitschaftFehlerAction
    | PostFunktionsbereichEMailBenachrichtigungBereitschaftNichtAusgefuehrtAction
    ;
