import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_AKTIVIEREN = '[VorlagenModul] PostDokumentVeranstaltungsTypenAktivieren laden';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_AKTIVIEREN_AUSFUEHREN = '[VorlagenModul] PostDokumentVeranstaltungsTypenAktivieren ausführen';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_AKTIVIEREN_ERFOLGREICH = '[VorlagenModul] PostDokumentVeranstaltungsTypenAktivieren laden erfolgreich';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_AKTIVIEREN_FEHLER = '[VorlagenModul] PostDokumentVeranstaltungsTypenAktivieren Ladefehler';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_AKTIVIEREN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentVeranstaltungsTypenAktivieren nicht ausgeführt (evtl. Offline)';



export class PostDokumentVeranstaltungsTypenAktivierenAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_AKTIVIEREN;
        constructor(public dokumentVeranstaltungsTypenRequest: api.DokumentVeranstaltungsTypenRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenAktivierenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_AKTIVIEREN_AUSFUEHREN;
        constructor(public dokumentVeranstaltungsTypenRequest: api.DokumentVeranstaltungsTypenRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenAktivierenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_AKTIVIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentVeranstaltungsTypenRequest: api.DokumentVeranstaltungsTypenRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenAktivierenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_AKTIVIEREN_FEHLER;
        constructor(public payload: any, public dokumentVeranstaltungsTypenRequest: api.DokumentVeranstaltungsTypenRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenAktivierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_AKTIVIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentVeranstaltungsTypenAktivierenActions =
    PostDokumentVeranstaltungsTypenAktivierenAction
    | PostDokumentVeranstaltungsTypenAktivierenAusfuehrenAction
    | PostDokumentVeranstaltungsTypenAktivierenErfolgreichAction
    | PostDokumentVeranstaltungsTypenAktivierenFehlerAction
    | PostDokumentVeranstaltungsTypenAktivierenNichtAusgefuehrtAction
    ;
