import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ModuleState, PageState} from './aufgaben-berechtigungen-modal.reducer';
import {Benutzer} from '../../../../clients/vorlagen';
import {ListInputItem} from '../../../shared/sopi-list/sopi-list.model';

export function getModuleState() {
    return createFeatureSelector<ModuleState>('AufgabenBerechtigungenModal');
}

export function getPageState() {
    return createSelector(getModuleState(), (state: ModuleState) => state.page);
}

export function getBenutzer() {
    return createSelector(getPageState(), (state: PageState) => state.benutzer);
}

export function getBenutzerLaden() {
    return createSelector(getPageState(), (state: PageState) => state.benutzerLaden);
}

export function getBenutzerFehler() {
    return createSelector(getPageState(), (state: PageState) => state.benutzerFehler);
}

export function getSelectedBenutzer() {
    return createSelector(getPageState(), (state: PageState) => state.selectedBenutzer);
}


export function getBenutzerListe() {
    return createSelector(getBenutzer()
        , (benutzer: Array<Benutzer>) => {
            const res = benutzer.map((user) => <ListInputItem>{
                groupId: user.display_text.substring(0, 1).toUpperCase(),
                groupTitle: user.display_text.substring(0, 1).toUpperCase(),
                itemId: user.benutzer_id,
                itemTitle: user.display_text,
                item: user,
            });

           return res;
        });
}
