import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGEN_ANGELEGTE_ADMIN = '[VeranstaltungenModul] GetVeranstaltungenAngelegteAdmin laden';
export const GET_VERANSTALTUNGEN_ANGELEGTE_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungenAngelegteAdmin ausführen';
export const GET_VERANSTALTUNGEN_ANGELEGTE_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungenAngelegteAdmin laden erfolgreich';
export const GET_VERANSTALTUNGEN_ANGELEGTE_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungenAngelegteAdmin Ladefehler';
export const GET_VERANSTALTUNGEN_ANGELEGTE_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungenAngelegteAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungenAngelegteAdminAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ANGELEGTE_ADMIN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenAngelegteAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ANGELEGTE_ADMIN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenAngelegteAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ANGELEGTE_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.VeranstaltungForAdmin>, public optPayload: any = null) {}
    }
export class GetVeranstaltungenAngelegteAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ANGELEGTE_ADMIN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungenAngelegteAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_ANGELEGTE_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungenAngelegteAdminActions =
    GetVeranstaltungenAngelegteAdminAction
    | GetVeranstaltungenAngelegteAdminAusfuehrenAction
    | GetVeranstaltungenAngelegteAdminErfolgreichAction
    | GetVeranstaltungenAngelegteAdminFehlerAction
    | GetVeranstaltungenAngelegteAdminNichtAusgefuehrtAction
    ;
