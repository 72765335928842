import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGEN_MANDANT_OPTIONS = '[VeranstaltungenModul] GetVeranstaltungenMandantOptions laden';
export const GET_VERANSTALTUNGEN_MANDANT_OPTIONS_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungenMandantOptions ausführen';
export const GET_VERANSTALTUNGEN_MANDANT_OPTIONS_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungenMandantOptions laden erfolgreich';
export const GET_VERANSTALTUNGEN_MANDANT_OPTIONS_FEHLER = '[VeranstaltungenModul] GetVeranstaltungenMandantOptions Ladefehler';
export const GET_VERANSTALTUNGEN_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungenMandantOptions nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungenMandantOptionsAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_MANDANT_OPTIONS;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenMandantOptionsAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_MANDANT_OPTIONS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenMandantOptionsErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_MANDANT_OPTIONS_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungenMandantOptions, public optPayload: any = null) {}
    }
export class GetVeranstaltungenMandantOptionsFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_MANDANT_OPTIONS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungenMandantOptionsNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungenMandantOptionsActions =
    GetVeranstaltungenMandantOptionsAction
    | GetVeranstaltungenMandantOptionsAusfuehrenAction
    | GetVeranstaltungenMandantOptionsErfolgreichAction
    | GetVeranstaltungenMandantOptionsFehlerAction
    | GetVeranstaltungenMandantOptionsNichtAusgefuehrtAction
    ;
