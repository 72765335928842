import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
// Import Auth0Cordova
import {Observable} from 'rxjs/Observable';
// PAGES
import {DOCUMENT} from '@angular/common';
import {SettingsProvider } from '@orga-app/settings';
import {Settings} from '@orga-app/settings/settings.model';
import {IonNav, MenuController, ModalController, Platform} from '@ionic/angular';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MandantStammOptions} from '../clients/api';
import {MenuPageDefiniton, MenuPageGroup} from './navi/navi.model';
import {Benutzer, BenutzerverwaltungBenutzerZugriff} from '../clients/benutzerverwaltung';
import {SafeUrl, Title} from '@angular/platform-browser';
import {filter, map, withLatestFrom} from 'rxjs/operators';
import {getTheme} from '@orga-app/settings/reducer';
import {environment} from 'src/environments/environment';
import {getBenutzer, getMandantStammOptions} from '@orga-app/mandant/reducer';
import {StartAppAction} from '@orga-app/appstart/actions';
import {getIsLoggedIn} from '@orga-app/auth/reducer';
import {AuthLogoutAction, RefreshTokenAction} from '@orga-app/auth/actions';
import {AuthService} from '@orga-app/auth';
import {ClipboardService} from 'ngx-clipboard';
import {ImpressumPage} from './impressum/impressum.page';
import {getPages, getZugriffBenutzerverwaltung} from './navi/navi.reducer';
import {PostMandantClearCacheAction} from '../client-ngrx/api';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    @ViewChild(IonNav, {static: false}) nav: IonNav;

    selectedTheme: string;

    pages$: Observable<MenuPageGroup[]>;
    loggedIn$: Observable<boolean>;
    loginInProgress: boolean;
    settings$: Observable<Settings>;
    themeDefault$: Observable<Settings>;
    logoHeaderSVG$: Observable<SafeUrl>;
    mandantStammOptions$: Observable<MandantStammOptions>;
    benutzer$: Observable<Benutzer>;
    showMandantverwaltung$: Observable<boolean>;
    zugriff$: Observable<BenutzerverwaltungBenutzerZugriff>;

    public envi = environment;

    constructor(public platform: Platform,
                public menu: MenuController,
                private store: Store<any>,
                private settingsProvider: SettingsProvider,
                @Inject(DOCUMENT) private document: Document,
                translate: TranslateService,
                private route: ActivatedRoute,
                private titleService: Title,
                private router: Router,
                private authService: AuthService,
                private clipboardService: ClipboardService,
                // private storage: Storage,
                private modalCtrl: ModalController) {

        console.log('app component: envi:', this.envi);

        // this.loginInProgress = true;
        console.log('AUTHFLOW: app component: ctor');

        this.authService.initAuth().then(() => {
            console.log('AUTHFLOW: app component: initAuthFinished');
            //   this.loginInProgress = false;

            //   console.log("AUTHFLOW: app component: valid token:", this.authService.hasValidToken());
            //   // if (this.authService.hasValidToken()) {
            //   //   // console.log("routerState", router.routerState);
            //   //   var target = '/';

            //   //   if (router.routerState && router.routerState.snapshot.url) {
            //   //     const url = router.routerState.snapshot.url;

            //   //     //bei login auf home umleiten
            //   //     if (url.startsWith(environment.loginRoute)) {
            //   //       target = environment.homeRoute;
            //   //     }
            //   //     else {
            //   //       target = url;
            //   //     }
            //   //   }

            //   //   console.log("AUTHFLOW: app component: navigate to target:", target);
            //   //   this.router.navigateByUrl(target);
            //   // }

        });

        this.themeDefault$ = this.settingsProvider.getThemeDefault();
        this.logoHeaderSVG$ = this.settingsProvider.getLogoHeaderSVG();
        this.settings$ = this.store.pipe(select(getTheme));
        // this.settings$ = this.settingsProvider.getActiveTheme();
        translate.setDefaultLang('de');

        this.mandantStammOptions$ = this.store.pipe(select(getMandantStammOptions));
        this.loggedIn$ = this.store.pipe(select(getIsLoggedIn));

        // this.store.pipe(select(fromAuthModule.getLoginInProcess)).subscribe((loginInProgress: boolean) => {
        //     if (loginInProgress != null) {
        //         this.loginInProgress = loginInProgress;
        //     }
        // });

        this.pages$ = this.store.pipe(select(getPages));
        this.benutzer$ = this.store.pipe(select(getBenutzer));

        this.showMandantverwaltung$ = this.store.pipe(
            withLatestFrom(this.store.pipe(select(getZugriffBenutzerverwaltung)), this.store.pipe(select(getIsLoggedIn))),
            map(([x, zugriff, loggedIn]: [any, BenutzerverwaltungBenutzerZugriff, boolean]) => {
                if (zugriff === null) {
                    return false;
                }
                return (zugriff.ist_admin || zugriff.ist_tech_admin) && loggedIn;
            })
        );

        this.zugriff$ = this.store.pipe(select(getZugriffBenutzerverwaltung));

        this.initializeApp();
    }

    ngOnInit() {
        const appTitle = this.titleService.getTitle();
        this.router
            .events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                let child = this.route.firstChild;
                while (child.firstChild) {
                    child = child.firstChild;
                }
                if (child.snapshot.data['title']) {
                    return child.snapshot.data['title'];
                }
                return appTitle;
            })
        ).subscribe((ttl: string) => {
            this.titleService.setTitle(ttl + ' - ' + appTitle);
        });
    }

    initializeApp() {
        this.platform.ready().then(() => {
            console.debug('initializeApp:: platform.ready()');
            this.store.dispatch(new StartAppAction());
        });
    }

    async logout() {
        await this.closeMenu();
        await this.store.dispatch(new AuthLogoutAction());
    }


    async openLogin() {
        await this.closeMenu();

        await this.router.navigate([environment.loginRoute], {
            // queryParams: (window.location.pathname !== '/login' && window.location.pathname !== '/') ? { returnUrl: window.location.pathname } : {},
            // fragment: window.location.hash.substr(1, window.location.hash.length),
            relativeTo: this.route
        });
    }

    async openPage(menuPageDefiniton: MenuPageDefiniton, params?: any, opts?: any) {
        await this.closeMenu();
        await this.router.navigate([menuPageDefiniton.path], {
            relativeTo: this.route
        });
    }

    async openNoAccess() {
        await this.closeMenu();
        await this.router.navigate(['/no-access'], {
            relativeTo: this.route
        });
    }

    async closeMenu() {
        await this.menu.close();
    }

    async openMandantWechseln() {
        await this.closeMenu();
        await this.router.navigate(['/mandanten-auswahl', {
            returnTo: window.location.pathname
        }], {
            // queryParams: { returnUrl: window.location.pathname },
            relativeTo: this.route
        });
    }

    async openKomponenten() {
        await this.closeMenu();
        await this.router.navigate(['/komponenten'], {
            relativeTo: this.route
        });
    }

    async openEinstellungen() {
        await this.closeMenu();
        await this.router.navigate(['/einstellungen'], {
            relativeTo: this.route
        });
    }

    async openMandantenverwaltung() {
        await this.closeMenu();
        await this.router.navigate(['/mandantenverwaltung'], {
            relativeTo: this.route
        });
    }

    async copyToken() {
        await this.closeMenu();
        this.clipboardService.copyFromContent(this.authService.accessToken);
    }

    async refreshToken() {
        await this.closeMenu();
        await this.store.dispatch(new RefreshTokenAction());
    }

    async openImpressum() {
        const params = {
        };
        const modal = await this.modalCtrl.create({component: ImpressumPage, componentProps: params});
        modal.onDidDismiss().then((result: any) => {
            if (result != null && result.data) {
            }
        });

        await modal.present();
    }

    async clearCache() {
        await this.store.dispatch(new PostMandantClearCacheAction());
    }
}
