import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_NUTZUNGSBEDINGUNGEN = '[BenutzerverwaltungModul] PostNutzungsbedingungen laden';
export const POST_NUTZUNGSBEDINGUNGEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostNutzungsbedingungen ausführen';
export const POST_NUTZUNGSBEDINGUNGEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostNutzungsbedingungen laden erfolgreich';
export const POST_NUTZUNGSBEDINGUNGEN_FEHLER = '[BenutzerverwaltungModul] PostNutzungsbedingungen Ladefehler';
export const POST_NUTZUNGSBEDINGUNGEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostNutzungsbedingungen nicht ausgeführt (evtl. Offline)';



export class PostNutzungsbedingungenAction implements Action {
    readonly type = POST_NUTZUNGSBEDINGUNGEN;
        constructor(public benutzerNutzungsBedingungenRequest: api.BenutzerNutzungsBedingungenRequest, public optPayload: any = null) {}
    }
export class PostNutzungsbedingungenAusfuehrenAction implements Action {
    readonly type = POST_NUTZUNGSBEDINGUNGEN_AUSFUEHREN;
        constructor(public benutzerNutzungsBedingungenRequest: api.BenutzerNutzungsBedingungenRequest, public optPayload: any = null) {}
    }
export class PostNutzungsbedingungenErfolgreichAction implements Action {
    readonly type = POST_NUTZUNGSBEDINGUNGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public benutzerNutzungsBedingungenRequest: api.BenutzerNutzungsBedingungenRequest, public optPayload: any = null) {}
    }
export class PostNutzungsbedingungenFehlerAction implements Action {
    readonly type = POST_NUTZUNGSBEDINGUNGEN_FEHLER;
        constructor(public payload: any, public benutzerNutzungsBedingungenRequest: api.BenutzerNutzungsBedingungenRequest, public optPayload: any = null) {}
    }
export class PostNutzungsbedingungenNichtAusgefuehrtAction implements Action {
    readonly type = POST_NUTZUNGSBEDINGUNGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandNutzungsbedingungenActions =
    PostNutzungsbedingungenAction
    | PostNutzungsbedingungenAusfuehrenAction
    | PostNutzungsbedingungenErfolgreichAction
    | PostNutzungsbedingungenFehlerAction
    | PostNutzungsbedingungenNichtAusgefuehrtAction
    ;
