import {VeranstaltungsTyp} from '../../../clients/veranstaltungstypen';

export interface TypenDividerGroup {
    titel: string;
    items: TypenCheckboxWrapper[];
}

export interface TypenCheckboxWrapper {
    selected: boolean;
    titel: string;
    titelFunktionsbereich: string;
    funktionsbereichId: string;
    rolle: VeranstaltungsTyp;
}

export enum DatenquelleVeranstaltungsTypen {
    Kommunikation,
    Veranstaltungen,
    VeranstaltungsTypen
}
