

import {Action} from '@ngrx/store';

export const GET_INCIDENT_REPORT_PDF = '[IncidentsModul] GetIncidentReportPdf laden';
export const GET_INCIDENT_REPORT_PDF_AUSFUEHREN = '[IncidentsModul] GetIncidentReportPdf ausführen';
export const GET_INCIDENT_REPORT_PDF_ERFOLGREICH = '[IncidentsModul] GetIncidentReportPdf laden erfolgreich';
export const GET_INCIDENT_REPORT_PDF_FEHLER = '[IncidentsModul] GetIncidentReportPdf Ladefehler';
export const GET_INCIDENT_REPORT_PDF_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentReportPdf nicht ausgeführt (evtl. Offline)';



export class GetIncidentReportPdfAction implements Action {
    readonly type = GET_INCIDENT_REPORT_PDF;
        constructor( public incident_id:string ,  public reportVersion:string , public optPayload: any = null) {}
    }
export class GetIncidentReportPdfAusfuehrenAction implements Action {
    readonly type = GET_INCIDENT_REPORT_PDF_AUSFUEHREN;
        constructor( public incident_id:string ,  public reportVersion:string , public optPayload: any = null) {}
    }
export class GetIncidentReportPdfErfolgreichAction implements Action {
    readonly type = GET_INCIDENT_REPORT_PDF_ERFOLGREICH;
        constructor( public incident_id:string ,  public reportVersion:string , public optPayload: any = null) {}
    }
export class GetIncidentReportPdfFehlerAction implements Action {
    readonly type = GET_INCIDENT_REPORT_PDF_FEHLER;
        constructor(public payload: any,  public incident_id:string ,  public reportVersion:string , public optPayload: any = null) {}
    }
export class GetIncidentReportPdfNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENT_REPORT_PDF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentReportPdfActions =
    GetIncidentReportPdfAction
    | GetIncidentReportPdfAusfuehrenAction
    | GetIncidentReportPdfErfolgreichAction
    | GetIncidentReportPdfFehlerAction
    | GetIncidentReportPdfNichtAusgefuehrtAction
    ;
