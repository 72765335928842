

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_DOKUMENT = '[VeranstaltungenModul] GetVeranstaltungDokument laden';
export const GET_VERANSTALTUNG_DOKUMENT_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungDokument ausführen';
export const GET_VERANSTALTUNG_DOKUMENT_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungDokument laden erfolgreich';
export const GET_VERANSTALTUNG_DOKUMENT_FEHLER = '[VeranstaltungenModul] GetVeranstaltungDokument Ladefehler';
export const GET_VERANSTALTUNG_DOKUMENT_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungDokument nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungDokumentAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT;
        constructor( public veranstaltung_id:string ,  public dokument_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumentAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public dokument_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumentErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_ERFOLGREICH;
        constructor( public veranstaltung_id:string ,  public dokument_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumentFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public dokument_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumentNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENT_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungDokumentActions =
    GetVeranstaltungDokumentAction
    | GetVeranstaltungDokumentAusfuehrenAction
    | GetVeranstaltungDokumentErfolgreichAction
    | GetVeranstaltungDokumentFehlerAction
    | GetVeranstaltungDokumentNichtAusgefuehrtAction
    ;
