import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_MANDANTEN_CONFIG_TYPES = '[ApiModul] GetMandantenConfigTypes laden';
export const GET_MANDANTEN_CONFIG_TYPES_AUSFUEHREN = '[ApiModul] GetMandantenConfigTypes ausführen';
export const GET_MANDANTEN_CONFIG_TYPES_ERFOLGREICH = '[ApiModul] GetMandantenConfigTypes laden erfolgreich';
export const GET_MANDANTEN_CONFIG_TYPES_FEHLER = '[ApiModul] GetMandantenConfigTypes Ladefehler';
export const GET_MANDANTEN_CONFIG_TYPES_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantenConfigTypes nicht ausgeführt (evtl. Offline)';



export class GetMandantenConfigTypesAction implements Action {
    readonly type = GET_MANDANTEN_CONFIG_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantenConfigTypesAusfuehrenAction implements Action {
    readonly type = GET_MANDANTEN_CONFIG_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantenConfigTypesErfolgreichAction implements Action {
    readonly type = GET_MANDANTEN_CONFIG_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetMandantenConfigTypesFehlerAction implements Action {
    readonly type = GET_MANDANTEN_CONFIG_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantenConfigTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANTEN_CONFIG_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantenConfigTypesActions =
    GetMandantenConfigTypesAction
    | GetMandantenConfigTypesAusfuehrenAction
    | GetMandantenConfigTypesErfolgreichAction
    | GetMandantenConfigTypesFehlerAction
    | GetMandantenConfigTypesNichtAusgefuehrtAction
    ;
