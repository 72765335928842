import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ModalController, NavController, NavParams} from '@ionic/angular';
import * as actionsUi from './aufgaben-berechtigungen-modal.actions.ui';
import {select, Store} from '@ngrx/store';
import {getBenutzerFehler, getBenutzerLaden, getBenutzerListe, getSelectedBenutzer} from './aufgaben-berechtigungen-modal.selectors';
import {PageState} from './aufgaben-berechtigungen-modal.reducer';
import {ListInputItem} from '../../../shared/sopi-list/sopi-list.model';

@Component({
    selector: 'app-aufgaben-berechtigungen-modal',
    templateUrl: './aufgaben-berechtigungen-modal.page.html',
    styleUrls: ['./aufgaben-berechtigungen-modal.page.scss'],
})
export class AufgabenBerechtigungenModalPage implements OnInit {
    @Input() veranstaltungsId: string;

    benutzerListe$: Observable<Array<ListInputItem>>;
    benutzerLaden$: Observable<boolean>;
    benutzerFehler$: Observable<boolean>;
    selectedBenutzer$: Observable<Array<string>>;

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private modalCtrl: ModalController,
                private store: Store<PageState>) {
    }

    ngOnInit(): void {
        this.benutzerListe$ = this.store.pipe(select(getBenutzerListe()));
        this.benutzerLaden$ = this.store.pipe(select(getBenutzerLaden()));
        this.benutzerFehler$ = this.store.pipe(select(getBenutzerFehler()));
        this.selectedBenutzer$ = this.store.pipe(select(getSelectedBenutzer()));
    }

    ionViewWillEnter() {
        this.viewWillEnterPage().then();
    }

    async viewWillEnterPage() {
        await this.benutzerLaden();
    }

    async ok() {
        this.selectedBenutzer$.subscribe(async (item) => {
            const data = {hinzugefuegte: item};
            await this.modalCtrl.dismiss(data);
        }).unsubscribe();
    }

    async abbrechen() {
        await this.modalCtrl.dismiss();
    }

    async benutzerLaden() {
        await this.store.dispatch(new actionsUi.PageStateResetAction(this.veranstaltungsId));
    }

    async updateSelectedBenutzer($event: Array<string>) {
        await this.store.dispatch(new actionsUi.SelektierteBenutzerAction($event));
    }

}
