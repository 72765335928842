import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGS_TYP_DETAIL = '[VeranstaltungstypenModul] GetVeranstaltungsTypDetail laden';
export const GET_VERANSTALTUNGS_TYP_DETAIL_AUSFUEHREN = '[VeranstaltungstypenModul] GetVeranstaltungsTypDetail ausführen';
export const GET_VERANSTALTUNGS_TYP_DETAIL_ERFOLGREICH = '[VeranstaltungstypenModul] GetVeranstaltungsTypDetail laden erfolgreich';
export const GET_VERANSTALTUNGS_TYP_DETAIL_FEHLER = '[VeranstaltungstypenModul] GetVeranstaltungsTypDetail Ladefehler';
export const GET_VERANSTALTUNGS_TYP_DETAIL_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] GetVeranstaltungsTypDetail nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungsTypDetailAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_DETAIL;
        constructor( public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypDetailAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_DETAIL_AUSFUEHREN;
        constructor( public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypDetailErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_DETAIL_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungsTypDetail,  public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypDetailFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_DETAIL_FEHLER;
        constructor(public payload: any,  public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypDetailNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_DETAIL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenQueryVeranstaltungsTypDetailActions =
    GetVeranstaltungsTypDetailAction
    | GetVeranstaltungsTypDetailAusfuehrenAction
    | GetVeranstaltungsTypDetailErfolgreichAction
    | GetVeranstaltungsTypDetailFehlerAction
    | GetVeranstaltungsTypDetailNichtAusgefuehrtAction
    ;
