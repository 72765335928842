import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {QschecksControllingSaisonFilterPage} from './qschecks-controlling-saison-filter.page';
import {StoreModule} from '@ngrx/store';
import {reducers} from './qschecks-controlling-saison-filter.reducer';
import {EffectsModule} from '@ngrx/effects';
import {QschecksControllingSaisonFilterPageUiEffects} from './qschecks-controlling-saison-filter.effects.ui';
import {SopiComponentsModule} from 'src/app/shared/sopi-components/sopi-components.module';
import {SopiScrollModule} from '../../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {QschecksFunktionsbereichePruefplaeneControllingEffects, QschecksPruefplanSaisonFilterEffects} from '../../../../client-ngrx/qschecks';

const routes: Routes = [
    {
        path: '',
        component: QschecksControllingSaisonFilterPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature('QschecksControllingSaisonFilterPage', reducers),
        EffectsModule.forFeature([
            QschecksControllingSaisonFilterPageUiEffects,

            QschecksFunktionsbereichePruefplaeneControllingEffects,
            QschecksPruefplanSaisonFilterEffects
        ]),
        SopiScrollModule,
        SopiComponentsModule.forRoot()
    ],
    declarations: [QschecksControllingSaisonFilterPage],
    entryComponents: [QschecksControllingSaisonFilterPage],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class QschecksControllingSaisonFilterPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: QschecksControllingSaisonFilterPageModule,
        };
    }
}
