import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_AKTION_REIHENFOLGE_SETZEN = '[VeranstaltungenModul] PostVeranstaltungAktionReihenfolgeSetzen laden';
export const POST_VERANSTALTUNG_AKTION_REIHENFOLGE_SETZEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungAktionReihenfolgeSetzen ausführen';
export const POST_VERANSTALTUNG_AKTION_REIHENFOLGE_SETZEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungAktionReihenfolgeSetzen laden erfolgreich';
export const POST_VERANSTALTUNG_AKTION_REIHENFOLGE_SETZEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungAktionReihenfolgeSetzen Ladefehler';
export const POST_VERANSTALTUNG_AKTION_REIHENFOLGE_SETZEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungAktionReihenfolgeSetzen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungAktionReihenfolgeSetzenAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_REIHENFOLGE_SETZEN;
        constructor(public veranstaltungAktionReihenfolgeSetzenMultiRequest: api.VeranstaltungAktionReihenfolgeSetzenMultiRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAktionReihenfolgeSetzenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_REIHENFOLGE_SETZEN_AUSFUEHREN;
        constructor(public veranstaltungAktionReihenfolgeSetzenMultiRequest: api.VeranstaltungAktionReihenfolgeSetzenMultiRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAktionReihenfolgeSetzenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_REIHENFOLGE_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungAktionReihenfolgeSetzenMultiRequest: api.VeranstaltungAktionReihenfolgeSetzenMultiRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAktionReihenfolgeSetzenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_REIHENFOLGE_SETZEN_FEHLER;
        constructor(public payload: any, public veranstaltungAktionReihenfolgeSetzenMultiRequest: api.VeranstaltungAktionReihenfolgeSetzenMultiRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAktionReihenfolgeSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_REIHENFOLGE_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungAktionReihenfolgeSetzenActions =
    PostVeranstaltungAktionReihenfolgeSetzenAction
    | PostVeranstaltungAktionReihenfolgeSetzenAusfuehrenAction
    | PostVeranstaltungAktionReihenfolgeSetzenErfolgreichAction
    | PostVeranstaltungAktionReihenfolgeSetzenFehlerAction
    | PostVeranstaltungAktionReihenfolgeSetzenNichtAusgefuehrtAction
    ;
