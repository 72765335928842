export * from './adminCommand.service';
import { AdminCommandService } from './adminCommand.service';
export * from './adminQuery.service';
import { AdminQueryService } from './adminQuery.service';
export * from './aufgabenCommand.service';
import { AufgabenCommandService } from './aufgabenCommand.service';
export * from './aufgabenQuery.service';
import { AufgabenQueryService } from './aufgabenQuery.service';
export * from './benutzerVerwaltungExtensionCommand.service';
import { BenutzerVerwaltungExtensionCommandService } from './benutzerVerwaltungExtensionCommand.service';
export * from './benutzerVerwaltungExtensionQuery.service';
import { BenutzerVerwaltungExtensionQueryService } from './benutzerVerwaltungExtensionQuery.service';
export * from './checklistenCommand.service';
import { ChecklistenCommandService } from './checklistenCommand.service';
export * from './checklistenQuery.service';
import { ChecklistenQueryService } from './checklistenQuery.service';
export * from './dokumenteCommand.service';
import { DokumenteCommandService } from './dokumenteCommand.service';
export * from './fehlerCommand.service';
import { FehlerCommandService } from './fehlerCommand.service';
export * from './funkprotokollCommand.service';
import { FunkprotokollCommandService } from './funkprotokollCommand.service';
export * from './funkprotokollQuery.service';
import { FunkprotokollQueryService } from './funkprotokollQuery.service';
export * from './funktionsbereicheCommand.service';
import { FunktionsbereicheCommandService } from './funktionsbereicheCommand.service';
export * from './funktionsbereicheQuery.service';
import { FunktionsbereicheQueryService } from './funktionsbereicheQuery.service';
export * from './mitteilungenCommand.service';
import { MitteilungenCommandService } from './mitteilungenCommand.service';
export * from './mitteilungenQuery.service';
import { MitteilungenQueryService } from './mitteilungenQuery.service';
export * from './rollenCommand.service';
import { RollenCommandService } from './rollenCommand.service';
export * from './veranstaltungenCommand.service';
import { VeranstaltungenCommandService } from './veranstaltungenCommand.service';
export * from './veranstaltungenQuery.service';
import { VeranstaltungenQueryService } from './veranstaltungenQuery.service';
export * from './veranstaltungenTemplates.service';
import { VeranstaltungenTemplatesService } from './veranstaltungenTemplates.service';
export const APIS = [AdminCommandService, AdminQueryService, AufgabenCommandService, AufgabenQueryService, BenutzerVerwaltungExtensionCommandService, BenutzerVerwaltungExtensionQueryService, ChecklistenCommandService, ChecklistenQueryService, DokumenteCommandService, FehlerCommandService, FunkprotokollCommandService, FunkprotokollQueryService, FunktionsbereicheCommandService, FunktionsbereicheQueryService, MitteilungenCommandService, MitteilungenQueryService, RollenCommandService, VeranstaltungenCommandService, VeranstaltungenQueryService, VeranstaltungenTemplatesService];
