import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_BILD_ENTFERNEN = '[IncidentsModul] PostIncidentBildEntfernen laden';
export const POST_INCIDENT_BILD_ENTFERNEN_AUSFUEHREN = '[IncidentsModul] PostIncidentBildEntfernen ausführen';
export const POST_INCIDENT_BILD_ENTFERNEN_ERFOLGREICH = '[IncidentsModul] PostIncidentBildEntfernen laden erfolgreich';
export const POST_INCIDENT_BILD_ENTFERNEN_FEHLER = '[IncidentsModul] PostIncidentBildEntfernen Ladefehler';
export const POST_INCIDENT_BILD_ENTFERNEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentBildEntfernen nicht ausgeführt (evtl. Offline)';



export class PostIncidentBildEntfernenAction implements Action {
    readonly type = POST_INCIDENT_BILD_ENTFERNEN;
        constructor(public incidentBildRequest: api.IncidentBildRequest, public optPayload: any = null) {}
    }
export class PostIncidentBildEntfernenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_BILD_ENTFERNEN_AUSFUEHREN;
        constructor(public incidentBildRequest: api.IncidentBildRequest, public optPayload: any = null) {}
    }
export class PostIncidentBildEntfernenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_BILD_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentBildRequest: api.IncidentBildRequest, public optPayload: any = null) {}
    }
export class PostIncidentBildEntfernenFehlerAction implements Action {
    readonly type = POST_INCIDENT_BILD_ENTFERNEN_FEHLER;
        constructor(public payload: any, public incidentBildRequest: api.IncidentBildRequest, public optPayload: any = null) {}
    }
export class PostIncidentBildEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_BILD_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentBildEntfernenActions =
    PostIncidentBildEntfernenAction
    | PostIncidentBildEntfernenAusfuehrenAction
    | PostIncidentBildEntfernenErfolgreichAction
    | PostIncidentBildEntfernenFehlerAction
    | PostIncidentBildEntfernenNichtAusgefuehrtAction
    ;
