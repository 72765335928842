import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {BenutzerauswahlPage} from './benutzerauswahl.page';
import {StoreModule} from '@ngrx/store';
import {reducers} from './benutzerauswahl.reducer';
import {EffectsModule} from '@ngrx/effects';
import {BenutzerverwaltungBenutzerListeEffects} from '../../../client-ngrx/benutzerverwaltung';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {SopiComponentsModule} from '../../shared/sopi-components/sopi-components.module';

const routes: Routes = [
    {
        path: '',
        component: BenutzerauswahlPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        StoreModule.forFeature('BenutzerauswahlPage', reducers),
        EffectsModule.forFeature([
            BenutzerverwaltungBenutzerListeEffects
        ]),
        SopiScrollModule,
        SopiComponentsModule.forRoot()
    ],
    declarations: [BenutzerauswahlPage],
    entryComponents: [BenutzerauswahlPage],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BenutzerauswahlPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: BenutzerauswahlPageModule,
            providers: []
        };
    }
}
