import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {TriggerauswahlPage} from './triggerauswahl.page';
import {StoreModule} from '@ngrx/store';
import {reducers} from './triggerauswahl.reducer';
import {EffectsModule} from '@ngrx/effects';
import {SopiComponentsModule} from '../../../shared/sopi-components/sopi-components.module';
import {SopiScrollModule} from '../../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {DisplaysteuerungTriggerListAdminEffects} from '../../../../client-ngrx/displaysteuerung';

const routes: Routes = [
    {
        path: '',
        component: TriggerauswahlPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SopiScrollModule,
        RouterModule.forChild(routes),

        StoreModule.forFeature('TriggerauswahlModal', reducers),
        EffectsModule.forFeature([
            DisplaysteuerungTriggerListAdminEffects
        ]),
        SopiComponentsModule.forRoot()
    ],
    declarations: [TriggerauswahlPage],
    entryComponents: [TriggerauswahlPage],
})
export class TriggerauswahlPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: TriggerauswahlPageModule,
        };
    }
}
