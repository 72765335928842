import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FUNKPROTOKOLL_FALL_ENTFERNEN = '[VeranstaltungenModul] PostFunkprotokollFallEntfernen laden';
export const POST_FUNKPROTOKOLL_FALL_ENTFERNEN_AUSFUEHREN = '[VeranstaltungenModul] PostFunkprotokollFallEntfernen ausführen';
export const POST_FUNKPROTOKOLL_FALL_ENTFERNEN_ERFOLGREICH = '[VeranstaltungenModul] PostFunkprotokollFallEntfernen laden erfolgreich';
export const POST_FUNKPROTOKOLL_FALL_ENTFERNEN_FEHLER = '[VeranstaltungenModul] PostFunkprotokollFallEntfernen Ladefehler';
export const POST_FUNKPROTOKOLL_FALL_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFunkprotokollFallEntfernen nicht ausgeführt (evtl. Offline)';



export class PostFunkprotokollFallEntfernenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ENTFERNEN;
        constructor(public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallEntfernenAusfuehrenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ENTFERNEN_AUSFUEHREN;
        constructor(public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallEntfernenErfolgreichAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallEntfernenFehlerAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ENTFERNEN_FEHLER;
        constructor(public payload: any, public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFunkprotokollFallEntfernenActions =
    PostFunkprotokollFallEntfernenAction
    | PostFunkprotokollFallEntfernenAusfuehrenAction
    | PostFunkprotokollFallEntfernenErfolgreichAction
    | PostFunkprotokollFallEntfernenFehlerAction
    | PostFunkprotokollFallEntfernenNichtAusgefuehrtAction
    ;
