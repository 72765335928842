import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@orga-app/auth';
import { API_URL } from './variables';



@Injectable()
export class DocumentService {

    public defaultHeaders: Headers = new Headers();
    protected basePath = 'https://api-dev.orga-app.de';

    constructor(protected httpClient: HttpClient,
         @Inject(API_URL) basePath: string,
        protected authService: AuthService    ) {
        if (basePath) {
            this.basePath = basePath;
        }

    }

    public getDocument(urlWithoutBaseUrl: string, extraHttpRequestParams?: any): Observable<Blob> {
        return this.httpClient
            .get(this.basePath + urlWithoutBaseUrl, {
                // headers: headers,
                responseType: 'blob'
            });
    }

    public getDocumentByPost(urlWithoutBaseUrl: string, body: any, extraHttpRequestParams?: any): Observable<Blob> {
        return this.httpClient
            .post(this.basePath + urlWithoutBaseUrl, body, {
                // headers: headers,
                responseType: 'blob'
            });
    }

    public getLocalDokument(urlWithoutBaseUrl: string, extraHttpRequestParams?: any): Observable<Blob> {
        return this.httpClient
            .get(urlWithoutBaseUrl, {
                // headers: headers,
                responseType: 'blob'
            });
    }

    public urltoFile(url, filename, mimeType) {
        mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
        return (fetch(url)
            .then((res) => {
                return res.arrayBuffer();
            })
            .then((buf) => {
                return new File([buf], filename, { type: mimeType });
            })
        );
    }

    // tslint:disable-next-line:no-unnecessary-initializer
    public saveFile(data, filename, mimetype = undefined) {

        if (!data) {
            return;
        }

        filename = this.replaceSpecialCharacter(filename);

        const blob = data.constructor !== Blob
            ? new Blob([data], { type: mimetype || 'application/octet-stream' })
            : data;

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
            return;
        }

        // var lnk = document.createElement('a'),
        //     url = window.URL,
        //     objectURL;

        const lnk = document.createElement('a');
        const url = window.URL;
        let objectURL;

        if (mimetype) {
            lnk.type = mimetype;
        }

        lnk.download = filename || 'untitled';
        lnk.href = objectURL = url.createObjectURL(blob);
        lnk.dispatchEvent(new MouseEvent('click'));
        setTimeout(url.revokeObjectURL.bind(url, objectURL));
    }

    replaceSpecialCharacter(value: string) {
        return value
            .replace(/ /g, '_')
            .replace(/ä/g, 'ae')
            .replace(/Ä/g, 'Ae')
            .replace(/ü/g, 'ue')
            .replace(/Ü/g, 'Ue')
            .replace(/ö/g, 'oe')
            .replace(/Ö/g, 'Oe')
            .replace(/ß/g, 'ss')
            .replace(/\//g, '-')
            .replace(/\\/g, '-')
            ;
    }
}
