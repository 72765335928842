import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENTS_ROLLE_BERECHTIGUNG = '[IncidentsModul] GetIncidentsRolleBerechtigung laden';
export const GET_INCIDENTS_ROLLE_BERECHTIGUNG_AUSFUEHREN = '[IncidentsModul] GetIncidentsRolleBerechtigung ausführen';
export const GET_INCIDENTS_ROLLE_BERECHTIGUNG_ERFOLGREICH = '[IncidentsModul] GetIncidentsRolleBerechtigung laden erfolgreich';
export const GET_INCIDENTS_ROLLE_BERECHTIGUNG_FEHLER = '[IncidentsModul] GetIncidentsRolleBerechtigung Ladefehler';
export const GET_INCIDENTS_ROLLE_BERECHTIGUNG_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentsRolleBerechtigung nicht ausgeführt (evtl. Offline)';



export class GetIncidentsRolleBerechtigungAction implements Action {
    readonly type = GET_INCIDENTS_ROLLE_BERECHTIGUNG;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetIncidentsRolleBerechtigungAusfuehrenAction implements Action {
    readonly type = GET_INCIDENTS_ROLLE_BERECHTIGUNG_AUSFUEHREN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetIncidentsRolleBerechtigungErfolgreichAction implements Action {
    readonly type = GET_INCIDENTS_ROLLE_BERECHTIGUNG_ERFOLGREICH;
        constructor(public payload: api.IncidentsRolleBerechtigung,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetIncidentsRolleBerechtigungFehlerAction implements Action {
    readonly type = GET_INCIDENTS_ROLLE_BERECHTIGUNG_FEHLER;
        constructor(public payload: any,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetIncidentsRolleBerechtigungNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENTS_ROLLE_BERECHTIGUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentsRolleBerechtigungActions =
    GetIncidentsRolleBerechtigungAction
    | GetIncidentsRolleBerechtigungAusfuehrenAction
    | GetIncidentsRolleBerechtigungErfolgreichAction
    | GetIncidentsRolleBerechtigungFehlerAction
    | GetIncidentsRolleBerechtigungNichtAusgefuehrtAction
    ;
