/**
 * orga app | veranstaltungen
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DienstplanFunktionsbereich } from '../model/dienstplanFunktionsbereich';
import { Dokument } from '../model/dokument';
import { DokumentBerechtigung } from '../model/dokumentBerechtigung';
import { Funktionsbereich } from '../model/funktionsbereich';
import { PhasenVeranstaltungFunktionsbereiche } from '../model/phasenVeranstaltungFunktionsbereiche';
import { Veranstaltung } from '../model/veranstaltung';
import { VeranstaltungBerechtigung } from '../model/veranstaltungBerechtigung';
import { VeranstaltungDetail } from '../model/veranstaltungDetail';
import { VeranstaltungMitteilungBerechtigung } from '../model/veranstaltungMitteilungBerechtigung';
import { VeranstaltungPruefberichtReportModel } from '../model/veranstaltungPruefberichtReportModel';
import { VeranstaltungStatus } from '../model/veranstaltungStatus';
import { VeranstaltungenBenutzerZugriff } from '../model/veranstaltungenBenutzerZugriff';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class VeranstaltungenQueryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetVeranstaltungBenutzerZugriff (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungBenutzerZugriff(observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungenBenutzerZugriff>;
    public getVeranstaltungBenutzerZugriff(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungenBenutzerZugriff>>;
    public getVeranstaltungBenutzerZugriff(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungenBenutzerZugriff>>;
    public getVeranstaltungBenutzerZugriff(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VeranstaltungenBenutzerZugriff>('get',`${this.basePath}/api/query/veranstaltungen/zugriff`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungBerechtigung (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungBerechtigung(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungBerechtigung>;
    public getVeranstaltungBerechtigung(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungBerechtigung>>;
    public getVeranstaltungBerechtigung(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungBerechtigung>>;
    public getVeranstaltungBerechtigung(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungBerechtigung.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VeranstaltungBerechtigung>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/berechtigung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungDeckblatt (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungDeckblatt(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungDetail>;
    public getVeranstaltungDeckblatt(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungDetail>>;
    public getVeranstaltungDeckblatt(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungDetail>>;
    public getVeranstaltungDeckblatt(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungDeckblatt.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VeranstaltungDetail>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungDienstplan (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungDienstplan(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DienstplanFunktionsbereich>>;
    public getVeranstaltungDienstplan(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DienstplanFunktionsbereich>>>;
    public getVeranstaltungDienstplan(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DienstplanFunktionsbereich>>>;
    public getVeranstaltungDienstplan(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungDienstplan.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DienstplanFunktionsbereich>>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/dienstplan`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungDokument (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param dokument_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungDokument(veranstaltung_id: string, dokument_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVeranstaltungDokument(veranstaltung_id: string, dokument_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVeranstaltungDokument(veranstaltung_id: string, dokument_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVeranstaltungDokument(veranstaltung_id: string, dokument_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungDokument.');
        }

        if (dokument_id === null || dokument_id === undefined) {
            throw new Error('Required parameter dokument_id was null or undefined when calling getVeranstaltungDokument.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/dokumente/${encodeURIComponent(String(dokument_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungDokumentBerechtigung (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param dokument_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungDokumentBerechtigung(veranstaltung_id: string, dokument_id: string, observe?: 'body', reportProgress?: boolean): Observable<DokumentBerechtigung>;
    public getVeranstaltungDokumentBerechtigung(veranstaltung_id: string, dokument_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DokumentBerechtigung>>;
    public getVeranstaltungDokumentBerechtigung(veranstaltung_id: string, dokument_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DokumentBerechtigung>>;
    public getVeranstaltungDokumentBerechtigung(veranstaltung_id: string, dokument_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungDokumentBerechtigung.');
        }

        if (dokument_id === null || dokument_id === undefined) {
            throw new Error('Required parameter dokument_id was null or undefined when calling getVeranstaltungDokumentBerechtigung.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DokumentBerechtigung>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/dokumente/${encodeURIComponent(String(dokument_id))}/berechtigung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungDokumente (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungDokumente(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Dokument>>;
    public getVeranstaltungDokumente(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Dokument>>>;
    public getVeranstaltungDokumente(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Dokument>>>;
    public getVeranstaltungDokumente(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungDokumente.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Dokument>>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/dokumente`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungFunktionsbereichChecklistePruefberichtData (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param funktionsbereich_id 
     * @param checkliste_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungFunktionsbereichChecklistePruefberichtData(veranstaltung_id: string, funktionsbereich_id: string, checkliste_id: string, observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungPruefberichtReportModel>;
    public getVeranstaltungFunktionsbereichChecklistePruefberichtData(veranstaltung_id: string, funktionsbereich_id: string, checkliste_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungPruefberichtReportModel>>;
    public getVeranstaltungFunktionsbereichChecklistePruefberichtData(veranstaltung_id: string, funktionsbereich_id: string, checkliste_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungPruefberichtReportModel>>;
    public getVeranstaltungFunktionsbereichChecklistePruefberichtData(veranstaltung_id: string, funktionsbereich_id: string, checkliste_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungFunktionsbereichChecklistePruefberichtData.');
        }

        if (funktionsbereich_id === null || funktionsbereich_id === undefined) {
            throw new Error('Required parameter funktionsbereich_id was null or undefined when calling getVeranstaltungFunktionsbereichChecklistePruefberichtData.');
        }

        if (checkliste_id === null || checkliste_id === undefined) {
            throw new Error('Required parameter checkliste_id was null or undefined when calling getVeranstaltungFunktionsbereichChecklistePruefberichtData.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VeranstaltungPruefberichtReportModel>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/pruefbericht/funktionsbereiche/${encodeURIComponent(String(funktionsbereich_id))}/checklisten/${encodeURIComponent(String(checkliste_id))}/data`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungFunktionsbereichChecklistePruefberichtPdf (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param funktionsbereich_id 
     * @param checkliste_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungFunktionsbereichChecklistePruefberichtPdf(veranstaltung_id: string, funktionsbereich_id: string, checkliste_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVeranstaltungFunktionsbereichChecklistePruefberichtPdf(veranstaltung_id: string, funktionsbereich_id: string, checkliste_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVeranstaltungFunktionsbereichChecklistePruefberichtPdf(veranstaltung_id: string, funktionsbereich_id: string, checkliste_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVeranstaltungFunktionsbereichChecklistePruefberichtPdf(veranstaltung_id: string, funktionsbereich_id: string, checkliste_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungFunktionsbereichChecklistePruefberichtPdf.');
        }

        if (funktionsbereich_id === null || funktionsbereich_id === undefined) {
            throw new Error('Required parameter funktionsbereich_id was null or undefined when calling getVeranstaltungFunktionsbereichChecklistePruefberichtPdf.');
        }

        if (checkliste_id === null || checkliste_id === undefined) {
            throw new Error('Required parameter checkliste_id was null or undefined when calling getVeranstaltungFunktionsbereichChecklistePruefberichtPdf.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/pruefbericht/funktionsbereiche/${encodeURIComponent(String(funktionsbereich_id))}/checklisten/${encodeURIComponent(String(checkliste_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungFunktionsbereichChecklistePruefberichtXml (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param funktionsbereich_id 
     * @param checkliste_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungFunktionsbereichChecklistePruefberichtXml(veranstaltung_id: string, funktionsbereich_id: string, checkliste_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVeranstaltungFunktionsbereichChecklistePruefberichtXml(veranstaltung_id: string, funktionsbereich_id: string, checkliste_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVeranstaltungFunktionsbereichChecklistePruefberichtXml(veranstaltung_id: string, funktionsbereich_id: string, checkliste_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVeranstaltungFunktionsbereichChecklistePruefberichtXml(veranstaltung_id: string, funktionsbereich_id: string, checkliste_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungFunktionsbereichChecklistePruefberichtXml.');
        }

        if (funktionsbereich_id === null || funktionsbereich_id === undefined) {
            throw new Error('Required parameter funktionsbereich_id was null or undefined when calling getVeranstaltungFunktionsbereichChecklistePruefberichtXml.');
        }

        if (checkliste_id === null || checkliste_id === undefined) {
            throw new Error('Required parameter checkliste_id was null or undefined when calling getVeranstaltungFunktionsbereichChecklistePruefberichtXml.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/pruefbericht/funktionsbereiche/${encodeURIComponent(String(funktionsbereich_id))}/checklisten/${encodeURIComponent(String(checkliste_id))}/xml`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungFunktionsbereichPruefberichtData (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param funktionsbereich_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungFunktionsbereichPruefberichtData(veranstaltung_id: string, funktionsbereich_id: string, observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungPruefberichtReportModel>;
    public getVeranstaltungFunktionsbereichPruefberichtData(veranstaltung_id: string, funktionsbereich_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungPruefberichtReportModel>>;
    public getVeranstaltungFunktionsbereichPruefberichtData(veranstaltung_id: string, funktionsbereich_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungPruefberichtReportModel>>;
    public getVeranstaltungFunktionsbereichPruefberichtData(veranstaltung_id: string, funktionsbereich_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungFunktionsbereichPruefberichtData.');
        }

        if (funktionsbereich_id === null || funktionsbereich_id === undefined) {
            throw new Error('Required parameter funktionsbereich_id was null or undefined when calling getVeranstaltungFunktionsbereichPruefberichtData.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VeranstaltungPruefberichtReportModel>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/pruefbericht/funktionsbereiche/${encodeURIComponent(String(funktionsbereich_id))}/data`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungFunktionsbereichPruefberichtPdf (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param funktionsbereich_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungFunktionsbereichPruefberichtPdf(veranstaltung_id: string, funktionsbereich_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVeranstaltungFunktionsbereichPruefberichtPdf(veranstaltung_id: string, funktionsbereich_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVeranstaltungFunktionsbereichPruefberichtPdf(veranstaltung_id: string, funktionsbereich_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVeranstaltungFunktionsbereichPruefberichtPdf(veranstaltung_id: string, funktionsbereich_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungFunktionsbereichPruefberichtPdf.');
        }

        if (funktionsbereich_id === null || funktionsbereich_id === undefined) {
            throw new Error('Required parameter funktionsbereich_id was null or undefined when calling getVeranstaltungFunktionsbereichPruefberichtPdf.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/pruefbericht/funktionsbereiche/${encodeURIComponent(String(funktionsbereich_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungFunktionsbereichPruefberichtXml (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param funktionsbereich_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungFunktionsbereichPruefberichtXml(veranstaltung_id: string, funktionsbereich_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVeranstaltungFunktionsbereichPruefberichtXml(veranstaltung_id: string, funktionsbereich_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVeranstaltungFunktionsbereichPruefberichtXml(veranstaltung_id: string, funktionsbereich_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVeranstaltungFunktionsbereichPruefberichtXml(veranstaltung_id: string, funktionsbereich_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungFunktionsbereichPruefberichtXml.');
        }

        if (funktionsbereich_id === null || funktionsbereich_id === undefined) {
            throw new Error('Required parameter funktionsbereich_id was null or undefined when calling getVeranstaltungFunktionsbereichPruefberichtXml.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/pruefbericht/funktionsbereiche/${encodeURIComponent(String(funktionsbereich_id))}/xml`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungFunktionsbereiche (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungFunktionsbereiche(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Funktionsbereich>>;
    public getVeranstaltungFunktionsbereiche(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Funktionsbereich>>>;
    public getVeranstaltungFunktionsbereiche(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Funktionsbereich>>>;
    public getVeranstaltungFunktionsbereiche(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungFunktionsbereiche.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Funktionsbereich>>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/funktionsbereiche`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungFunktionsbereicheChecklisten (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungFunktionsbereicheChecklisten(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PhasenVeranstaltungFunktionsbereiche>>;
    public getVeranstaltungFunktionsbereicheChecklisten(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PhasenVeranstaltungFunktionsbereiche>>>;
    public getVeranstaltungFunktionsbereicheChecklisten(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PhasenVeranstaltungFunktionsbereiche>>>;
    public getVeranstaltungFunktionsbereicheChecklisten(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungFunktionsbereicheChecklisten.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PhasenVeranstaltungFunktionsbereiche>>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/funktionsbereichechecklisten`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungLogo (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungLogo(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVeranstaltungLogo(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVeranstaltungLogo(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVeranstaltungLogo(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungLogo.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/logo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungLogoScaled (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param width 
     * @param height 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungLogoScaled(veranstaltung_id: string, width: number, height: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVeranstaltungLogoScaled(veranstaltung_id: string, width: number, height: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVeranstaltungLogoScaled(veranstaltung_id: string, width: number, height: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVeranstaltungLogoScaled(veranstaltung_id: string, width: number, height: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungLogoScaled.');
        }

        if (width === null || width === undefined) {
            throw new Error('Required parameter width was null or undefined when calling getVeranstaltungLogoScaled.');
        }

        if (height === null || height === undefined) {
            throw new Error('Required parameter height was null or undefined when calling getVeranstaltungLogoScaled.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/logo/scale/${encodeURIComponent(String(width))}/${encodeURIComponent(String(height))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungMitteilungBerechtigungALT (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungMitteilungBerechtigungALT(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungMitteilungBerechtigung>;
    public getVeranstaltungMitteilungBerechtigungALT(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungMitteilungBerechtigung>>;
    public getVeranstaltungMitteilungBerechtigungALT(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungMitteilungBerechtigung>>;
    public getVeranstaltungMitteilungBerechtigungALT(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungMitteilungBerechtigungALT.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VeranstaltungMitteilungBerechtigung>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/berechtigung/mitteilung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungPruefberichtData (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungPruefberichtData(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungPruefberichtReportModel>;
    public getVeranstaltungPruefberichtData(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungPruefberichtReportModel>>;
    public getVeranstaltungPruefberichtData(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungPruefberichtReportModel>>;
    public getVeranstaltungPruefberichtData(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungPruefberichtData.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VeranstaltungPruefberichtReportModel>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/pruefbericht/data`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungPruefberichtPdf (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungPruefberichtPdf(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getVeranstaltungPruefberichtPdf(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getVeranstaltungPruefberichtPdf(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getVeranstaltungPruefberichtPdf(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungPruefberichtPdf.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/pruefbericht`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungPruefberichtXml (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungPruefberichtXml(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getVeranstaltungPruefberichtXml(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getVeranstaltungPruefberichtXml(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getVeranstaltungPruefberichtXml(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungPruefberichtXml.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/pruefbericht/xml`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungStatus (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungStatus(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<VeranstaltungStatus>;
    public getVeranstaltungStatus(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VeranstaltungStatus>>;
    public getVeranstaltungStatus(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VeranstaltungStatus>>;
    public getVeranstaltungStatus(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getVeranstaltungStatus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VeranstaltungStatus>('get',`${this.basePath}/api/query/veranstaltungen/${encodeURIComponent(String(veranstaltung_id))}/status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungenAktuelle (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungenAktuelle(observe?: 'body', reportProgress?: boolean): Observable<Array<Veranstaltung>>;
    public getVeranstaltungenAktuelle(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Veranstaltung>>>;
    public getVeranstaltungenAktuelle(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Veranstaltung>>>;
    public getVeranstaltungenAktuelle(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Veranstaltung>>('get',`${this.basePath}/api/query/veranstaltungen/aktuelle`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetVeranstaltungenAlle (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVeranstaltungenAlle(observe?: 'body', reportProgress?: boolean): Observable<Array<Veranstaltung>>;
    public getVeranstaltungenAlle(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Veranstaltung>>>;
    public getVeranstaltungenAlle(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Veranstaltung>>>;
    public getVeranstaltungenAlle(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Veranstaltung>>('get',`${this.basePath}/api/query/veranstaltungen/alle`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
