import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_AUFGABE_FEHLER_BESCHREIBUNG = '[VeranstaltungenModul] GetAufgabeFehlerBeschreibung laden';
export const GET_AUFGABE_FEHLER_BESCHREIBUNG_AUSFUEHREN = '[VeranstaltungenModul] GetAufgabeFehlerBeschreibung ausführen';
export const GET_AUFGABE_FEHLER_BESCHREIBUNG_ERFOLGREICH = '[VeranstaltungenModul] GetAufgabeFehlerBeschreibung laden erfolgreich';
export const GET_AUFGABE_FEHLER_BESCHREIBUNG_FEHLER = '[VeranstaltungenModul] GetAufgabeFehlerBeschreibung Ladefehler';
export const GET_AUFGABE_FEHLER_BESCHREIBUNG_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetAufgabeFehlerBeschreibung nicht ausgeführt (evtl. Offline)';



export class GetAufgabeFehlerBeschreibungAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BESCHREIBUNG;
        constructor( public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBeschreibungAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BESCHREIBUNG_AUSFUEHREN;
        constructor( public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBeschreibungErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BESCHREIBUNG_ERFOLGREICH;
        constructor(public payload: api.FehlerBeschreibung,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBeschreibungFehlerAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BESCHREIBUNG_FEHLER;
        constructor(public payload: any,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBeschreibungNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BESCHREIBUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryAufgabeFehlerBeschreibungActions =
    GetAufgabeFehlerBeschreibungAction
    | GetAufgabeFehlerBeschreibungAusfuehrenAction
    | GetAufgabeFehlerBeschreibungErfolgreichAction
    | GetAufgabeFehlerBeschreibungFehlerAction
    | GetAufgabeFehlerBeschreibungNichtAusgefuehrtAction
    ;
