import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FEHLERBESCHREIBUNG_HINZUFUEGEN = '[VeranstaltungenModul] PostFehlerbeschreibungHinzufuegen laden';
export const POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_AUSFUEHREN = '[VeranstaltungenModul] PostFehlerbeschreibungHinzufuegen ausführen';
export const POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_ERFOLGREICH = '[VeranstaltungenModul] PostFehlerbeschreibungHinzufuegen laden erfolgreich';
export const POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_FEHLER = '[VeranstaltungenModul] PostFehlerbeschreibungHinzufuegen Ladefehler';
export const POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFehlerbeschreibungHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostFehlerbeschreibungHinzufuegenAction implements Action {
    readonly type = POST_FEHLERBESCHREIBUNG_HINZUFUEGEN;
        constructor(public fehlerbeschreibungHinzufuegenRequest: api.FehlerbeschreibungHinzufuegenRequest, public optPayload: any = null) {}
    }
export class PostFehlerbeschreibungHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_AUSFUEHREN;
        constructor(public fehlerbeschreibungHinzufuegenRequest: api.FehlerbeschreibungHinzufuegenRequest, public optPayload: any = null) {}
    }
export class PostFehlerbeschreibungHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public fehlerbeschreibungHinzufuegenRequest: api.FehlerbeschreibungHinzufuegenRequest, public optPayload: any = null) {}
    }
export class PostFehlerbeschreibungHinzufuegenFehlerAction implements Action {
    readonly type = POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public fehlerbeschreibungHinzufuegenRequest: api.FehlerbeschreibungHinzufuegenRequest, public optPayload: any = null) {}
    }
export class PostFehlerbeschreibungHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFehlerbeschreibungHinzufuegenActions =
    PostFehlerbeschreibungHinzufuegenAction
    | PostFehlerbeschreibungHinzufuegenAusfuehrenAction
    | PostFehlerbeschreibungHinzufuegenErfolgreichAction
    | PostFehlerbeschreibungHinzufuegenFehlerAction
    | PostFehlerbeschreibungHinzufuegenNichtAusgefuehrtAction
    ;
