import {Action} from '@ngrx/store';

export const WEBSOCKET_START_ACTION = '[WebSocketModule] Websocket starten';
export const WEBSOCKET_START_ERFOLGREICH_ACTION = '[WebSocketModule] Websocket start erfolgreich';
export const WEBSOCKET_RECONNECTED_ACTION = '[WebSocketModule] Websocket reconnected';
export const WEBSOCKET_STOP_ACTION = '[WebSocketModule] Websocket stoppen';
export const WEBSOCKET_TELEMETRY_EVENT_ACTION = '[WebSocketModule] Telemetry Event';
export const WEBSOCKET_SET_TOKEN_ACTION = '[WebSocketModule] Token setzen';
export const WEBSOCKET_SET_MANDANT_ACTION = '[WebSocketModule] Mandant setzen';
export const WEBSOCKET_SET_TOKEN_AND_START_ACTION = '[WebSocketModule] Token setzen und Starten ';

export const WEBSOCKET_REFRESH_ACTION = '[WebSocketModule] Refreshen - alles neu setzen';


export class WebsocketStartAction implements Action {
    readonly type = WEBSOCKET_START_ACTION;

    constructor() {
    }
}

export class WebsocketStartErfolgreichAction implements Action {
    readonly type = WEBSOCKET_START_ERFOLGREICH_ACTION;

    constructor() {
    }
}

export class WebsocketReconnectedAction implements Action {
    readonly type = WEBSOCKET_RECONNECTED_ACTION;

    constructor() {
    }
}

export class WebsocketStopAction implements Action {
    readonly type = WEBSOCKET_STOP_ACTION;

    constructor() {
    }
}

export class WebsocketTelemetryEventAction implements Action {
    readonly type = WEBSOCKET_TELEMETRY_EVENT_ACTION;

    constructor(public eventName: string, public eventProperties: any) {
    }
}

export class WebsocketSetTokenAction implements Action {
    readonly type = WEBSOCKET_SET_TOKEN_ACTION;

    constructor(public token: string) {
    }
}

export class WebsocketSetTokenAndStartAction implements Action {
    readonly type = WEBSOCKET_SET_TOKEN_AND_START_ACTION;

    constructor(public token: string) {
    }
}

export class WebsocketSetMandantAction implements Action {
    readonly type = WEBSOCKET_SET_MANDANT_ACTION;

    constructor(public mandant_id: string) {
    }
}


export class WebsocketRefreshAction implements Action {
    readonly type = WEBSOCKET_REFRESH_ACTION;

    constructor(public mandant_id: string, public access_token: string) {
    }
}

export type Actions =
    WebsocketStartAction
    | WebsocketStartErfolgreichAction
    | WebsocketReconnectedAction
    | WebsocketStopAction
    | WebsocketTelemetryEventAction
    | WebsocketSetTokenAction
    | WebsocketSetMandantAction
    | WebsocketSetTokenAndStartAction
    | WebsocketRefreshAction
    ;
