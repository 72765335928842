import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { MandantFeature, reducers } from './reducer';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientMandantInterceptor } from './http-client-mandant.interceptor';
import { EffectsModule } from '@ngrx/effects';
import { ApiMandantClientConfigEffects, ApiMeineMandantenEffects } from '../../client-ngrx/api';
import { BenutzerverwaltungBenutzerInfoEffects } from '../../client-ngrx/benutzerverwaltung';
import { MandantEffects } from './effects';
import { LocalStorageConfig, localStorageSync } from 'ngrx-store-localstorage';
import { IonicModule } from '@ionic/angular';
import { API_URL, MandantAuswahlRoute } from './variables';
import { IsMandantAusgewaehltGuard } from './mandant-ausgewaehlt-guard';
import {VorlagenZeitpunktTexteEffects} from '../../client-ngrx/vorlagen';

export function sessionStorage(reducer: ActionReducer<any>): ActionReducer<any> {

    const config: LocalStorageConfig = {
        keys: [
            'mandant1'
        ],
        rehydrate: true,
        removeOnUndefined: true
    };

    return localStorageSync(config)(reducer);
}

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        IonicModule,
        StoreModule.forFeature(MandantFeature, reducers, { metaReducers: [sessionStorage] }),
        EffectsModule.forFeature([
            MandantEffects,
            ApiMeineMandantenEffects,
            ApiMandantClientConfigEffects,
            BenutzerverwaltungBenutzerInfoEffects,
            VorlagenZeitpunktTexteEffects
        ])
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpClientMandantInterceptor, multi: true },
        IsMandantAusgewaehltGuard
    ]

})
export class MandantModule {
    public static forRoot(apiUrl: string, auswahlRoute: string): ModuleWithProviders {
        return {
            ngModule: MandantModule,
            providers: [
                { provide: API_URL, useValue: apiUrl },
                { provide: MandantAuswahlRoute, useValue: auswahlRoute }
            ]
        };
    }
}
