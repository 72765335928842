import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheRollenAdmin laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheRollenAdmin ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheRollenAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheRollenAdmin Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheRollenAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereicheRollenAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheRollenAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheRollenAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichRollen>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheRollenAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheRollenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereicheRollenAdminActions =
    GetVeranstaltungFunktionsbereicheRollenAdminAction
    | GetVeranstaltungFunktionsbereicheRollenAdminAusfuehrenAction
    | GetVeranstaltungFunktionsbereicheRollenAdminErfolgreichAction
    | GetVeranstaltungFunktionsbereicheRollenAdminFehlerAction
    | GetVeranstaltungFunktionsbereicheRollenAdminNichtAusgefuehrtAction
    ;
