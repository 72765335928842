import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICH_DETAIL = '[BenutzerverwaltungModul] GetFunktionsbereichDetail laden';
export const GET_FUNKTIONSBEREICH_DETAIL_AUSFUEHREN = '[BenutzerverwaltungModul] GetFunktionsbereichDetail ausführen';
export const GET_FUNKTIONSBEREICH_DETAIL_ERFOLGREICH = '[BenutzerverwaltungModul] GetFunktionsbereichDetail laden erfolgreich';
export const GET_FUNKTIONSBEREICH_DETAIL_FEHLER = '[BenutzerverwaltungModul] GetFunktionsbereichDetail Ladefehler';
export const GET_FUNKTIONSBEREICH_DETAIL_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetFunktionsbereichDetail nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichDetailAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_DETAIL;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichDetailAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_DETAIL_AUSFUEHREN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichDetailErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_DETAIL_ERFOLGREICH;
        constructor(public payload: api.FunktionsbereichDetail,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichDetailFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_DETAIL_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichDetailNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_DETAIL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryFunktionsbereichDetailActions =
    GetFunktionsbereichDetailAction
    | GetFunktionsbereichDetailAusfuehrenAction
    | GetFunktionsbereichDetailErfolgreichAction
    | GetFunktionsbereichDetailFehlerAction
    | GetFunktionsbereichDetailNichtAusgefuehrtAction
    ;
