import {NgModule} from '@angular/core';
import {NumbersOnlyDirective} from './numbers-only/numbers-only.directive';
import {ClearTextareaDirective} from './clear-textarea/clear-textarea.directive';
import {DisableControlDirective} from './disable-control/disable-control.directive';
import {LetDirective} from './ng-let/ng-let.directive';

@NgModule({
    declarations:   [ NumbersOnlyDirective, ClearTextareaDirective, DisableControlDirective, LetDirective ],
    exports:        [ NumbersOnlyDirective, ClearTextareaDirective, DisableControlDirective, LetDirective ]
})

// export class SopiDirectivesModule {}
export class SopiDirectivesModule {
    public static forRoot() {
        return {
            ngModule: SopiDirectivesModule,
        };
    }
}
