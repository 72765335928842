import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFPLAN_ROLLEN_PRUEFER_ADMIN = '[QschecksModul] GetPruefplanRollenPrueferAdmin laden';
export const GET_PRUEFPLAN_ROLLEN_PRUEFER_ADMIN_AUSFUEHREN = '[QschecksModul] GetPruefplanRollenPrueferAdmin ausführen';
export const GET_PRUEFPLAN_ROLLEN_PRUEFER_ADMIN_ERFOLGREICH = '[QschecksModul] GetPruefplanRollenPrueferAdmin laden erfolgreich';
export const GET_PRUEFPLAN_ROLLEN_PRUEFER_ADMIN_FEHLER = '[QschecksModul] GetPruefplanRollenPrueferAdmin Ladefehler';
export const GET_PRUEFPLAN_ROLLEN_PRUEFER_ADMIN_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPruefplanRollenPrueferAdmin nicht ausgeführt (evtl. Offline)';



export class GetPruefplanRollenPrueferAdminAction implements Action {
    readonly type = GET_PRUEFPLAN_ROLLEN_PRUEFER_ADMIN;
        constructor( public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanRollenPrueferAdminAusfuehrenAction implements Action {
    readonly type = GET_PRUEFPLAN_ROLLEN_PRUEFER_ADMIN_AUSFUEHREN;
        constructor( public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanRollenPrueferAdminErfolgreichAction implements Action {
    readonly type = GET_PRUEFPLAN_ROLLEN_PRUEFER_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichRolle>,  public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanRollenPrueferAdminFehlerAction implements Action {
    readonly type = GET_PRUEFPLAN_ROLLEN_PRUEFER_ADMIN_FEHLER;
        constructor(public payload: any,  public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanRollenPrueferAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFPLAN_ROLLEN_PRUEFER_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPruefplanRollenPrueferAdminActions =
    GetPruefplanRollenPrueferAdminAction
    | GetPruefplanRollenPrueferAdminAusfuehrenAction
    | GetPruefplanRollenPrueferAdminErfolgreichAction
    | GetPruefplanRollenPrueferAdminFehlerAction
    | GetPruefplanRollenPrueferAdminNichtAusgefuehrtAction
    ;
