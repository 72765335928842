import {Action, ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';

import * as actionsUi from './triggerauswahl.actions.ui';
import {APP_RESET_ACTION} from '@orga-app/appstart/actions';
import {Trigger} from '../../../../clients/displaysteuerung';
import {GET_TRIGGER_LIST_ADMIN, GET_TRIGGER_LIST_ADMIN_ERFOLGREICH, GET_TRIGGER_LIST_ADMIN_FEHLER, GetTriggerListAdminErfolgreichAction} from '../../../../client-ngrx/displaysteuerung';
import {ListInputItem} from '../../../shared/sopi-list/sopi-list.model';

export interface ModuleState {
    page: PageState;
}

export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer
};

export interface PageState {
    trigger: Array<Trigger>;
    triggerLaden: boolean;
    triggerFehler: boolean;
    nichtAnzuzeigendeTrigger: Array<string>;
    selektierteTrigger: Array<Trigger>;
}

export const initialPageState: PageState = {
    trigger: [],
    triggerLaden: false,
    triggerFehler: false,
    nichtAnzuzeigendeTrigger: [],
    selektierteTrigger: []
};


export function pageReducer(state = initialPageState, action: Action): PageState {
    switch (action.type) {

        // APP RESET
        case APP_RESET_ACTION: {
            return Object.assign({}, state, <PageState>initialPageState);
        }

        // Page State Reset
        case actionsUi.PAGE_STATE_RESET: {
            // const a = <actionsUi.PageStateResetAction> action;

            return Object.assign({}, state, <PageState>initialPageState);
        }

        case GET_TRIGGER_LIST_ADMIN: {
            return Object.assign({}, state, <PageState>{
                triggerLaden: true,
                triggerFehler: false
            });
        }

        case GET_TRIGGER_LIST_ADMIN_ERFOLGREICH: {
            const a = <GetTriggerListAdminErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                trigger: a.payload,
                triggerLaden: false,
                triggerFehler: false
            });
        }

        case GET_TRIGGER_LIST_ADMIN_FEHLER: {
            return Object.assign({}, state, <PageState>{
                trigger: [],
                triggerLaden: false,
                triggerFehler: true
            });
        }

        case actionsUi.NICHT_ANZUZEIGENDE_TRIGGER_SETZEN: {
            const a = <actionsUi.NichtAnzuzeigendeTriggerSetzenAction>action;

            if (a.payload == null) {
                a.payload = [];
            }
            return Object.assign({}, state, <PageState>{
                nichtAnzuzeigendeTrigger: a.payload
            });
        }

        case actionsUi.SELEKTIERTE_TRIGGER: {
            const a = <actionsUi.SelektierteTriggerAction>action;

            return Object.assign({}, state, <PageState>{
                selektierteTrigger: a.payload
            });
        }

        default: {
            return state;
        }
    }
}


/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export function getModuleState() {
    return createFeatureSelector<ModuleState>('TriggerauswahlModal');
}

export function getPageState() {
    return createSelector(getModuleState(), (state: ModuleState) => state.page);
}

export function getTriggerLaden() {
    return createSelector(getPageState(), (state: PageState) => state.triggerLaden);
}

export function getTriggerFehler() {
    return createSelector(getPageState(), (state: PageState) => state.triggerFehler);
}

export function getNichtAnzuzeigendeTrigger() {
    return createSelector(getPageState(), (state: PageState) => state.nichtAnzuzeigendeTrigger);
}

export function getSelektierteTrigger() {
    return createSelector(getPageState(), (state: PageState) => state.selektierteTrigger);
}

export function getTrigger() {
    return createSelector(getPageState(), getNichtAnzuzeigendeTrigger(),
        (state: PageState, nichtAnzuzeigendeTrigger: Array<string>) => {
            const res: Array<ListInputItem> = [];
            state.trigger.forEach(trigger => {
                res.push(<ListInputItem>{
                    groupId: null,
                    groupTitle: '',
                    itemId: trigger.id,
                    itemTitle: trigger.name,
                    item: trigger,
                });
            });

            return res.filter(item => {
                const nichtAnzuzeigendeRolleId = nichtAnzuzeigendeTrigger.filter(i => {
                    return i === item.itemId;
                });
                return nichtAnzuzeigendeRolleId.length === 0;
            });
        });
}
