import {Action} from '@ngrx/store';
import {Trigger} from '../../../../clients/displaysteuerung';


export const PAGE_STATE_RESET = '[TriggerauswahlModal] Page State reset';

export const NICHT_ANZUZEIGENDE_TRIGGER_SETZEN = '[TriggerauswahlModal] Trigger die aus der Auswahl entfernt werden sollen setzen';
export const SELEKTIERTE_TRIGGER = '[TriggerauswahlModal] Selektierte Trigger';


// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;
}

// UI
export class NichtAnzuzeigendeTriggerSetzenAction implements Action {
    readonly type = NICHT_ANZUZEIGENDE_TRIGGER_SETZEN;

    constructor(public payload: Array<any>) {
    }
}

export class SelektierteTriggerAction implements Action {
    readonly type = SELEKTIERTE_TRIGGER;

    constructor(public payload: Array<Trigger>) {
    }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
    NichtAnzuzeigendeTriggerSetzenAction
    | PageStateResetAction
    | SelektierteTriggerAction
    ;
