import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_HINZUFUEGEN = '[VorlagenModul] PostDokumentVeranstaltungsTypenHinzufuegen laden';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_AUSFUEHREN = '[VorlagenModul] PostDokumentVeranstaltungsTypenHinzufuegen ausführen';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_ERFOLGREICH = '[VorlagenModul] PostDokumentVeranstaltungsTypenHinzufuegen laden erfolgreich';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_FEHLER = '[VorlagenModul] PostDokumentVeranstaltungsTypenHinzufuegen Ladefehler';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentVeranstaltungsTypenHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostDokumentVeranstaltungsTypenHinzufuegenAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_HINZUFUEGEN;
        constructor(public dokumentVeranstaltungsTypMultiRequest: api.DokumentVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_AUSFUEHREN;
        constructor(public dokumentVeranstaltungsTypMultiRequest: api.DokumentVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentVeranstaltungsTypMultiRequest: api.DokumentVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenHinzufuegenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public dokumentVeranstaltungsTypMultiRequest: api.DokumentVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentVeranstaltungsTypenHinzufuegenActions =
    PostDokumentVeranstaltungsTypenHinzufuegenAction
    | PostDokumentVeranstaltungsTypenHinzufuegenAusfuehrenAction
    | PostDokumentVeranstaltungsTypenHinzufuegenErfolgreichAction
    | PostDokumentVeranstaltungsTypenHinzufuegenFehlerAction
    | PostDokumentVeranstaltungsTypenHinzufuegenNichtAusgefuehrtAction
    ;
