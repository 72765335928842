import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICH_ABTEILUNG = '[IncidentsModul] GetFunktionsbereichAbteilung laden';
export const GET_FUNKTIONSBEREICH_ABTEILUNG_AUSFUEHREN = '[IncidentsModul] GetFunktionsbereichAbteilung ausführen';
export const GET_FUNKTIONSBEREICH_ABTEILUNG_ERFOLGREICH = '[IncidentsModul] GetFunktionsbereichAbteilung laden erfolgreich';
export const GET_FUNKTIONSBEREICH_ABTEILUNG_FEHLER = '[IncidentsModul] GetFunktionsbereichAbteilung Ladefehler';
export const GET_FUNKTIONSBEREICH_ABTEILUNG_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetFunktionsbereichAbteilung nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichAbteilungAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ABTEILUNG;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAbteilungAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ABTEILUNG_AUSFUEHREN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAbteilungErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ABTEILUNG_ERFOLGREICH;
        constructor(public payload: api.FunktionsbereichAbteilung,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAbteilungFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ABTEILUNG_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAbteilungNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ABTEILUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryFunktionsbereichAbteilungActions =
    GetFunktionsbereichAbteilungAction
    | GetFunktionsbereichAbteilungAusfuehrenAction
    | GetFunktionsbereichAbteilungErfolgreichAction
    | GetFunktionsbereichAbteilungFehlerAction
    | GetFunktionsbereichAbteilungNichtAusgefuehrtAction
    ;
