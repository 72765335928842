

import {Action} from '@ngrx/store';

export const GET_MANDANT_LOGO = '[ApiModul] GetMandantLogo laden';
export const GET_MANDANT_LOGO_AUSFUEHREN = '[ApiModul] GetMandantLogo ausführen';
export const GET_MANDANT_LOGO_ERFOLGREICH = '[ApiModul] GetMandantLogo laden erfolgreich';
export const GET_MANDANT_LOGO_FEHLER = '[ApiModul] GetMandantLogo Ladefehler';
export const GET_MANDANT_LOGO_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantLogo nicht ausgeführt (evtl. Offline)';



export class GetMandantLogoAction implements Action {
    readonly type = GET_MANDANT_LOGO;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantLogoAusfuehrenAction implements Action {
    readonly type = GET_MANDANT_LOGO_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantLogoErfolgreichAction implements Action {
    readonly type = GET_MANDANT_LOGO_ERFOLGREICH;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantLogoFehlerAction implements Action {
    readonly type = GET_MANDANT_LOGO_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantLogoNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANT_LOGO_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantLogoActions =
    GetMandantLogoAction
    | GetMandantLogoAusfuehrenAction
    | GetMandantLogoErfolgreichAction
    | GetMandantLogoFehlerAction
    | GetMandantLogoNichtAusgefuehrtAction
    ;
