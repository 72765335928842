import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_NICHT_OK_ERFASSEN = '[IncidentsModul] PostIncidentNichtOkErfassen laden';
export const POST_INCIDENT_NICHT_OK_ERFASSEN_AUSFUEHREN = '[IncidentsModul] PostIncidentNichtOkErfassen ausführen';
export const POST_INCIDENT_NICHT_OK_ERFASSEN_ERFOLGREICH = '[IncidentsModul] PostIncidentNichtOkErfassen laden erfolgreich';
export const POST_INCIDENT_NICHT_OK_ERFASSEN_FEHLER = '[IncidentsModul] PostIncidentNichtOkErfassen Ladefehler';
export const POST_INCIDENT_NICHT_OK_ERFASSEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentNichtOkErfassen nicht ausgeführt (evtl. Offline)';



export class PostIncidentNichtOkErfassenAction implements Action {
    readonly type = POST_INCIDENT_NICHT_OK_ERFASSEN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentNichtOkErfassenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_NICHT_OK_ERFASSEN_AUSFUEHREN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentNichtOkErfassenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_NICHT_OK_ERFASSEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentNichtOkErfassenFehlerAction implements Action {
    readonly type = POST_INCIDENT_NICHT_OK_ERFASSEN_FEHLER;
        constructor(public payload: any, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentNichtOkErfassenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_NICHT_OK_ERFASSEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentNichtOkErfassenActions =
    PostIncidentNichtOkErfassenAction
    | PostIncidentNichtOkErfassenAusfuehrenAction
    | PostIncidentNichtOkErfassenErfolgreichAction
    | PostIncidentNichtOkErfassenFehlerAction
    | PostIncidentNichtOkErfassenNichtAusgefuehrtAction
    ;
