import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENTS_CONFIG_TYPES = '[IncidentsModul] GetIncidentsConfigTypes laden';
export const GET_INCIDENTS_CONFIG_TYPES_AUSFUEHREN = '[IncidentsModul] GetIncidentsConfigTypes ausführen';
export const GET_INCIDENTS_CONFIG_TYPES_ERFOLGREICH = '[IncidentsModul] GetIncidentsConfigTypes laden erfolgreich';
export const GET_INCIDENTS_CONFIG_TYPES_FEHLER = '[IncidentsModul] GetIncidentsConfigTypes Ladefehler';
export const GET_INCIDENTS_CONFIG_TYPES_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentsConfigTypes nicht ausgeführt (evtl. Offline)';



export class GetIncidentsConfigTypesAction implements Action {
    readonly type = GET_INCIDENTS_CONFIG_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsConfigTypesAusfuehrenAction implements Action {
    readonly type = GET_INCIDENTS_CONFIG_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsConfigTypesErfolgreichAction implements Action {
    readonly type = GET_INCIDENTS_CONFIG_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetIncidentsConfigTypesFehlerAction implements Action {
    readonly type = GET_INCIDENTS_CONFIG_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetIncidentsConfigTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENTS_CONFIG_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentsConfigTypesActions =
    GetIncidentsConfigTypesAction
    | GetIncidentsConfigTypesAusfuehrenAction
    | GetIncidentsConfigTypesErfolgreichAction
    | GetIncidentsConfigTypesFehlerAction
    | GetIncidentsConfigTypesNichtAusgefuehrtAction
    ;
