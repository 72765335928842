import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_HINZUFUEGEN = '[VeranstaltungstypenModul] PostVeranstaltungsTypAdminRollenHinzufuegen laden';
export const POST_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_HINZUFUEGEN_AUSFUEHREN = '[VeranstaltungstypenModul] PostVeranstaltungsTypAdminRollenHinzufuegen ausführen';
export const POST_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_HINZUFUEGEN_ERFOLGREICH = '[VeranstaltungstypenModul] PostVeranstaltungsTypAdminRollenHinzufuegen laden erfolgreich';
export const POST_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_HINZUFUEGEN_FEHLER = '[VeranstaltungstypenModul] PostVeranstaltungsTypAdminRollenHinzufuegen Ladefehler';
export const POST_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] PostVeranstaltungsTypAdminRollenHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungsTypAdminRollenHinzufuegenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_HINZUFUEGEN;
        constructor(public veranstaltungsTypRolleMultiRequest: api.VeranstaltungsTypRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAdminRollenHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_HINZUFUEGEN_AUSFUEHREN;
        constructor(public veranstaltungsTypRolleMultiRequest: api.VeranstaltungsTypRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAdminRollenHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungsTypRolleMultiRequest: api.VeranstaltungsTypRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAdminRollenHinzufuegenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public veranstaltungsTypRolleMultiRequest: api.VeranstaltungsTypRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAdminRollenHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ADMIN_ROLLEN_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenCommandVeranstaltungsTypAdminRollenHinzufuegenActions =
    PostVeranstaltungsTypAdminRollenHinzufuegenAction
    | PostVeranstaltungsTypAdminRollenHinzufuegenAusfuehrenAction
    | PostVeranstaltungsTypAdminRollenHinzufuegenErfolgreichAction
    | PostVeranstaltungsTypAdminRollenHinzufuegenFehlerAction
    | PostVeranstaltungsTypAdminRollenHinzufuegenNichtAusgefuehrtAction
    ;
