import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_BENUTZER_INFO = '[BenutzerverwaltungModul] GetBenutzerInfo laden';
export const GET_BENUTZER_INFO_AUSFUEHREN = '[BenutzerverwaltungModul] GetBenutzerInfo ausführen';
export const GET_BENUTZER_INFO_ERFOLGREICH = '[BenutzerverwaltungModul] GetBenutzerInfo laden erfolgreich';
export const GET_BENUTZER_INFO_FEHLER = '[BenutzerverwaltungModul] GetBenutzerInfo Ladefehler';
export const GET_BENUTZER_INFO_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetBenutzerInfo nicht ausgeführt (evtl. Offline)';



export class GetBenutzerInfoAction implements Action {
    readonly type = GET_BENUTZER_INFO;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerInfoAusfuehrenAction implements Action {
    readonly type = GET_BENUTZER_INFO_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerInfoErfolgreichAction implements Action {
    readonly type = GET_BENUTZER_INFO_ERFOLGREICH;
        constructor(public payload: api.Benutzer, public optPayload: any = null) {}
    }
export class GetBenutzerInfoFehlerAction implements Action {
    readonly type = GET_BENUTZER_INFO_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetBenutzerInfoNichtAusgefuehrtAction implements Action {
    readonly type = GET_BENUTZER_INFO_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryBenutzerInfoActions =
    GetBenutzerInfoAction
    | GetBenutzerInfoAusfuehrenAction
    | GetBenutzerInfoErfolgreichAction
    | GetBenutzerInfoFehlerAction
    | GetBenutzerInfoNichtAusgefuehrtAction
    ;
