import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ROLLE_ZENTRALE = '[IncidentsModul] GetRolleZentrale laden';
export const GET_ROLLE_ZENTRALE_AUSFUEHREN = '[IncidentsModul] GetRolleZentrale ausführen';
export const GET_ROLLE_ZENTRALE_ERFOLGREICH = '[IncidentsModul] GetRolleZentrale laden erfolgreich';
export const GET_ROLLE_ZENTRALE_FEHLER = '[IncidentsModul] GetRolleZentrale Ladefehler';
export const GET_ROLLE_ZENTRALE_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetRolleZentrale nicht ausgeführt (evtl. Offline)';



export class GetRolleZentraleAction implements Action {
    readonly type = GET_ROLLE_ZENTRALE;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleZentraleAusfuehrenAction implements Action {
    readonly type = GET_ROLLE_ZENTRALE_AUSFUEHREN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleZentraleErfolgreichAction implements Action {
    readonly type = GET_ROLLE_ZENTRALE_ERFOLGREICH;
        constructor(public payload: api.RolleZentrale,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleZentraleFehlerAction implements Action {
    readonly type = GET_ROLLE_ZENTRALE_FEHLER;
        constructor(public payload: any,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleZentraleNichtAusgefuehrtAction implements Action {
    readonly type = GET_ROLLE_ZENTRALE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryRolleZentraleActions =
    GetRolleZentraleAction
    | GetRolleZentraleAusfuehrenAction
    | GetRolleZentraleErfolgreichAction
    | GetRolleZentraleFehlerAction
    | GetRolleZentraleNichtAusgefuehrtAction
    ;
