import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.query.mandant-logo-scaled';
import * as api from '../../../clients/api';


    @Injectable()
    export class ApiMandantLogoScaledEffects {

        @Effect()
            MandantLogoScaled$ = this.actions$.pipe(
                ofType(ac.GET_MANDANT_LOGO_SCALED),
                flatMap((x: ac.GetMandantLogoScaledAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().getMandantLogoScaled(x.width, x.height).pipe(
                    map((result: any) => {
                        const nextAction = new ac.GetMandantLogoScaledErfolgreichAction(x.width, x.height,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.GetMandantLogoScaledFehlerAction(error, x.width, x.height,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.MandantenService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.MandantenService {
                if (this.service === null) {
                    this.service = this.injector.get(api.MandantenService);
                }
                return this.service;
            }
        
}
