import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import * as actionsUi from './checklistenauswahl.actions.ui';
import * as fromChecklistenModal from './checklistenauswahl.reducer';
import {IonSearchbar, ModalController, NavController, NavParams} from '@ionic/angular';
import {select, Store} from '@ngrx/store';
import {GetFunktionsbereicheChecklistenAction} from '../../../client-ngrx/vorlagen';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';
import {Checkliste} from '../../../clients/vorlagen';

@Component({
    selector: 'app-checklistenauswahl',
    templateUrl: './checklistenauswahl.page.html',
})
export class ChecklistenauswahlPage implements OnInit {

    @ViewChild('suche', {static: false}) suche: IonSearchbar;

    private aiName = 'ChecklistenauswahlModal';

    checklisten$: Observable<ListInputItem[]>;
    uiChecklistenLadeanimation$: Observable<boolean>;
    uiChecklistenLadeFehler$: Observable<boolean>;
    uiSelektierteChecklisten$: Observable<Checkliste[]>;
    titel = '';
    filterString = '';
    aktuell: Array<any>;
    beteiligteFunktionsbereiche: Array<string>;

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private modalCtrl: ModalController,
                private store: Store<fromChecklistenModal.PageState>) {
        this.store.dispatch(new actionsUi.PageStateResetAction());
        this.aktuell = this.navParams.get('aktuell');
        this.titel = this.navParams.get('titel');
        this.beteiligteFunktionsbereiche = this.navParams.get('beteiligteFunktionsbereiche');
    }

    ngOnInit() {
        this.checklisten$ = this.store.pipe(select(fromChecklistenModal.getChecklisten()));
        this.uiChecklistenLadeanimation$ = this.store.pipe(select(fromChecklistenModal.getUiChecklistenLadeanimation()));
        this.uiChecklistenLadeFehler$ = this.store.pipe(select(fromChecklistenModal.getUiChecklistenLadeFehler()));
        this.uiSelektierteChecklisten$ = this.store.pipe(select(fromChecklistenModal.getUiSelektierteChecklisten()));

        this.store.dispatch(new actionsUi.NichtAnzuzeigendeChecklistenSetzenAction(this.aktuell));
        this.store.dispatch(new actionsUi.BeteiligteFunktionsbereicheSetzenAction(this.beteiligteFunktionsbereiche));
    }

    ionViewWillEnter() {
        this.viewWillEnterPage().then();
    }

    async viewWillEnterPage() {
        this.funktionsbereicheLaden();

        setTimeout(() => {
            if (this.suche) {
                this.suche.setFocus();
            }
        }, 500);
    }

    async ok() {
        this.store.pipe(select(fromChecklistenModal.getUiSelektierteChecklisten())).subscribe(ausgewaehlte => {
            const data = {hinzugefuegte: ausgewaehlte, entfernte: []};
            this.modalCtrl.dismiss(data);
        }).unsubscribe();
    }

    async abbrechen() {
        await this.modalCtrl.dismiss();
    }

    async funktionsbereicheLaden() {
        await this.store.dispatch(new GetFunktionsbereicheChecklistenAction(true));
    }

    async updateSelectedChecklisten($event: Checkliste[]) {
        await this.store.dispatch(new actionsUi.SelektierteChecklistenAction($event));
    }
}
