import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.command.checkliste-kopieren';
import * as api from '../../../clients/vorlagen';


    @Injectable()
    export class VorlagenChecklisteKopierenEffects {

        @Effect()
            ChecklisteKopieren$ = this.actions$.pipe(
                ofType(ac.POST_CHECKLISTE_KOPIEREN),
                flatMap((x: ac.PostChecklisteKopierenAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().postChecklisteKopieren(x.checklisteRequest).pipe(
                    map((result: any) => {
                        const nextAction = new ac.PostChecklisteKopierenErfolgreichAction(result, x.checklisteRequest,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.PostChecklisteKopierenFehlerAction(error, x.checklisteRequest,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.ChecklisteCommandService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.ChecklisteCommandService {
                if (this.service === null) {
                    this.service = this.injector.get(api.ChecklisteCommandService);
                }
                return this.service;
            }
        
}
