import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FUNKPROTOKOLL_MELDEART_AKTUALISIEREN = '[VeranstaltungenModul] PostFunkprotokollMeldeartAktualisieren laden';
export const POST_FUNKPROTOKOLL_MELDEART_AKTUALISIEREN_AUSFUEHREN = '[VeranstaltungenModul] PostFunkprotokollMeldeartAktualisieren ausführen';
export const POST_FUNKPROTOKOLL_MELDEART_AKTUALISIEREN_ERFOLGREICH = '[VeranstaltungenModul] PostFunkprotokollMeldeartAktualisieren laden erfolgreich';
export const POST_FUNKPROTOKOLL_MELDEART_AKTUALISIEREN_FEHLER = '[VeranstaltungenModul] PostFunkprotokollMeldeartAktualisieren Ladefehler';
export const POST_FUNKPROTOKOLL_MELDEART_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFunkprotokollMeldeartAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostFunkprotokollMeldeartAktualisierenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_AKTUALISIEREN;
        constructor(public funkprotokollMeldeartDatenRequest: api.FunkprotokollMeldeartDatenRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollMeldeartAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_AKTUALISIEREN_AUSFUEHREN;
        constructor(public funkprotokollMeldeartDatenRequest: api.FunkprotokollMeldeartDatenRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollMeldeartAktualisierenErfolgreichAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funkprotokollMeldeartDatenRequest: api.FunkprotokollMeldeartDatenRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollMeldeartAktualisierenFehlerAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public funkprotokollMeldeartDatenRequest: api.FunkprotokollMeldeartDatenRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollMeldeartAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFunkprotokollMeldeartAktualisierenActions =
    PostFunkprotokollMeldeartAktualisierenAction
    | PostFunkprotokollMeldeartAktualisierenAusfuehrenAction
    | PostFunkprotokollMeldeartAktualisierenErfolgreichAction
    | PostFunkprotokollMeldeartAktualisierenFehlerAction
    | PostFunkprotokollMeldeartAktualisierenNichtAusgefuehrtAction
    ;
