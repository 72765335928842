import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_MITTEILUNGS_VORLAGE_DETAIL = '[KommunikationModul] GetMitteilungsVorlageDetail laden';
export const GET_MITTEILUNGS_VORLAGE_DETAIL_AUSFUEHREN = '[KommunikationModul] GetMitteilungsVorlageDetail ausführen';
export const GET_MITTEILUNGS_VORLAGE_DETAIL_ERFOLGREICH = '[KommunikationModul] GetMitteilungsVorlageDetail laden erfolgreich';
export const GET_MITTEILUNGS_VORLAGE_DETAIL_FEHLER = '[KommunikationModul] GetMitteilungsVorlageDetail Ladefehler';
export const GET_MITTEILUNGS_VORLAGE_DETAIL_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetMitteilungsVorlageDetail nicht ausgeführt (evtl. Offline)';



export class GetMitteilungsVorlageDetailAction implements Action {
    readonly type = GET_MITTEILUNGS_VORLAGE_DETAIL;
        constructor( public id:string , public optPayload: any = null) {}
    }
export class GetMitteilungsVorlageDetailAusfuehrenAction implements Action {
    readonly type = GET_MITTEILUNGS_VORLAGE_DETAIL_AUSFUEHREN;
        constructor( public id:string , public optPayload: any = null) {}
    }
export class GetMitteilungsVorlageDetailErfolgreichAction implements Action {
    readonly type = GET_MITTEILUNGS_VORLAGE_DETAIL_ERFOLGREICH;
        constructor(public payload: api.MitteilungsVorlageDetail,  public id:string , public optPayload: any = null) {}
    }
export class GetMitteilungsVorlageDetailFehlerAction implements Action {
    readonly type = GET_MITTEILUNGS_VORLAGE_DETAIL_FEHLER;
        constructor(public payload: any,  public id:string , public optPayload: any = null) {}
    }
export class GetMitteilungsVorlageDetailNichtAusgefuehrtAction implements Action {
    readonly type = GET_MITTEILUNGS_VORLAGE_DETAIL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryMitteilungsVorlageDetailActions =
    GetMitteilungsVorlageDetailAction
    | GetMitteilungsVorlageDetailAusfuehrenAction
    | GetMitteilungsVorlageDetailErfolgreichAction
    | GetMitteilungsVorlageDetailFehlerAction
    | GetMitteilungsVorlageDetailNichtAusgefuehrtAction
    ;
