import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const POST_TEST_MAIL_SENDEN = '[KommunikationModul] PostTestMailSenden laden';
export const POST_TEST_MAIL_SENDEN_AUSFUEHREN = '[KommunikationModul] PostTestMailSenden ausführen';
export const POST_TEST_MAIL_SENDEN_ERFOLGREICH = '[KommunikationModul] PostTestMailSenden laden erfolgreich';
export const POST_TEST_MAIL_SENDEN_FEHLER = '[KommunikationModul] PostTestMailSenden Ladefehler';
export const POST_TEST_MAIL_SENDEN_NICHT_AUSGEFUEHRT = '[KommunikationModul] PostTestMailSenden nicht ausgeführt (evtl. Offline)';



export class PostTestMailSendenAction implements Action {
    readonly type = POST_TEST_MAIL_SENDEN;
        constructor(public testMailRequest: api.TestMailRequest, public optPayload: any = null) {}
    }
export class PostTestMailSendenAusfuehrenAction implements Action {
    readonly type = POST_TEST_MAIL_SENDEN_AUSFUEHREN;
        constructor(public testMailRequest: api.TestMailRequest, public optPayload: any = null) {}
    }
export class PostTestMailSendenErfolgreichAction implements Action {
    readonly type = POST_TEST_MAIL_SENDEN_ERFOLGREICH;
        constructor(public testMailRequest: api.TestMailRequest, public optPayload: any = null) {}
    }
export class PostTestMailSendenFehlerAction implements Action {
    readonly type = POST_TEST_MAIL_SENDEN_FEHLER;
        constructor(public payload: any, public testMailRequest: api.TestMailRequest, public optPayload: any = null) {}
    }
export class PostTestMailSendenNichtAusgefuehrtAction implements Action {
    readonly type = POST_TEST_MAIL_SENDEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationCommandTestMailSendenActions =
    PostTestMailSendenAction
    | PostTestMailSendenAusfuehrenAction
    | PostTestMailSendenErfolgreichAction
    | PostTestMailSendenFehlerAction
    | PostTestMailSendenNichtAusgefuehrtAction
    ;
