import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_ROLLE_IDENTIFIER = '[VeranstaltungenModul] GetRolleIdentifier laden';
export const GET_ROLLE_IDENTIFIER_AUSFUEHREN = '[VeranstaltungenModul] GetRolleIdentifier ausführen';
export const GET_ROLLE_IDENTIFIER_ERFOLGREICH = '[VeranstaltungenModul] GetRolleIdentifier laden erfolgreich';
export const GET_ROLLE_IDENTIFIER_FEHLER = '[VeranstaltungenModul] GetRolleIdentifier Ladefehler';
export const GET_ROLLE_IDENTIFIER_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetRolleIdentifier nicht ausgeführt (evtl. Offline)';



export class GetRolleIdentifierAction implements Action {
    readonly type = GET_ROLLE_IDENTIFIER;
        constructor(public optPayload: any = null) {}
    }
export class GetRolleIdentifierAusfuehrenAction implements Action {
    readonly type = GET_ROLLE_IDENTIFIER_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetRolleIdentifierErfolgreichAction implements Action {
    readonly type = GET_ROLLE_IDENTIFIER_ERFOLGREICH;
        constructor(public payload: api.RolleIdentifier, public optPayload: any = null) {}
    }
export class GetRolleIdentifierFehlerAction implements Action {
    readonly type = GET_ROLLE_IDENTIFIER_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetRolleIdentifierNichtAusgefuehrtAction implements Action {
    readonly type = GET_ROLLE_IDENTIFIER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryRolleIdentifierActions =
    GetRolleIdentifierAction
    | GetRolleIdentifierAusfuehrenAction
    | GetRolleIdentifierErfolgreichAction
    | GetRolleIdentifierFehlerAction
    | GetRolleIdentifierNichtAusgefuehrtAction
    ;
