import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENTS_REPORT_XML = '[IncidentsModul] GetIncidentsReportXml laden';
export const GET_INCIDENTS_REPORT_XML_AUSFUEHREN = '[IncidentsModul] GetIncidentsReportXml ausführen';
export const GET_INCIDENTS_REPORT_XML_ERFOLGREICH = '[IncidentsModul] GetIncidentsReportXml laden erfolgreich';
export const GET_INCIDENTS_REPORT_XML_FEHLER = '[IncidentsModul] GetIncidentsReportXml Ladefehler';
export const GET_INCIDENTS_REPORT_XML_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentsReportXml nicht ausgeführt (evtl. Offline)';



export class GetIncidentsReportXmlAction implements Action {
    readonly type = GET_INCIDENTS_REPORT_XML;
        constructor(public incidentsReportRequest: api.IncidentsReportRequest, public optPayload: any = null) {}
    }
export class GetIncidentsReportXmlAusfuehrenAction implements Action {
    readonly type = GET_INCIDENTS_REPORT_XML_AUSFUEHREN;
        constructor(public incidentsReportRequest: api.IncidentsReportRequest, public optPayload: any = null) {}
    }
export class GetIncidentsReportXmlErfolgreichAction implements Action {
    readonly type = GET_INCIDENTS_REPORT_XML_ERFOLGREICH;
        constructor(public incidentsReportRequest: api.IncidentsReportRequest, public optPayload: any = null) {}
    }
export class GetIncidentsReportXmlFehlerAction implements Action {
    readonly type = GET_INCIDENTS_REPORT_XML_FEHLER;
        constructor(public payload: any, public incidentsReportRequest: api.IncidentsReportRequest, public optPayload: any = null) {}
    }
export class GetIncidentsReportXmlNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENTS_REPORT_XML_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentsReportXmlActions =
    GetIncidentsReportXmlAction
    | GetIncidentsReportXmlAusfuehrenAction
    | GetIncidentsReportXmlErfolgreichAction
    | GetIncidentsReportXmlFehlerAction
    | GetIncidentsReportXmlNichtAusgefuehrtAction
    ;
