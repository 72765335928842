import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_VERSCHIEBEN = '[VorlagenModul] PostAufgabeVerschieben laden';
export const POST_AUFGABE_VERSCHIEBEN_AUSFUEHREN = '[VorlagenModul] PostAufgabeVerschieben ausführen';
export const POST_AUFGABE_VERSCHIEBEN_ERFOLGREICH = '[VorlagenModul] PostAufgabeVerschieben laden erfolgreich';
export const POST_AUFGABE_VERSCHIEBEN_FEHLER = '[VorlagenModul] PostAufgabeVerschieben Ladefehler';
export const POST_AUFGABE_VERSCHIEBEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostAufgabeVerschieben nicht ausgeführt (evtl. Offline)';



export class PostAufgabeVerschiebenAction implements Action {
    readonly type = POST_AUFGABE_VERSCHIEBEN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeVerschiebenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_VERSCHIEBEN_AUSFUEHREN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeVerschiebenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_VERSCHIEBEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeVerschiebenFehlerAction implements Action {
    readonly type = POST_AUFGABE_VERSCHIEBEN_FEHLER;
        constructor(public payload: any, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeVerschiebenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_VERSCHIEBEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandAufgabeVerschiebenActions =
    PostAufgabeVerschiebenAction
    | PostAufgabeVerschiebenAusfuehrenAction
    | PostAufgabeVerschiebenErfolgreichAction
    | PostAufgabeVerschiebenFehlerAction
    | PostAufgabeVerschiebenNichtAusgefuehrtAction
    ;
