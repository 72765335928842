import * as api from '../../../clients/einlasskontrolle'; 

import {Action} from '@ngrx/store';

export const GET_STATISTIK_LISTE = '[EinlasskontrolleModul] GetStatistikListe laden';
export const GET_STATISTIK_LISTE_AUSFUEHREN = '[EinlasskontrolleModul] GetStatistikListe ausführen';
export const GET_STATISTIK_LISTE_ERFOLGREICH = '[EinlasskontrolleModul] GetStatistikListe laden erfolgreich';
export const GET_STATISTIK_LISTE_FEHLER = '[EinlasskontrolleModul] GetStatistikListe Ladefehler';
export const GET_STATISTIK_LISTE_NICHT_AUSGEFUEHRT = '[EinlasskontrolleModul] GetStatistikListe nicht ausgeführt (evtl. Offline)';



export class GetStatistikListeAction implements Action {
    readonly type = GET_STATISTIK_LISTE;
        constructor(public optPayload: any = null) {}
    }
export class GetStatistikListeAusfuehrenAction implements Action {
    readonly type = GET_STATISTIK_LISTE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetStatistikListeErfolgreichAction implements Action {
    readonly type = GET_STATISTIK_LISTE_ERFOLGREICH;
        constructor(public payload: Array<api.StatistikEntity>, public optPayload: any = null) {}
    }
export class GetStatistikListeFehlerAction implements Action {
    readonly type = GET_STATISTIK_LISTE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetStatistikListeNichtAusgefuehrtAction implements Action {
    readonly type = GET_STATISTIK_LISTE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type EinlasskontrolleQueryStatistikListeActions =
    GetStatistikListeAction
    | GetStatistikListeAusfuehrenAction
    | GetStatistikListeErfolgreichAction
    | GetStatistikListeFehlerAction
    | GetStatistikListeNichtAusgefuehrtAction
    ;
