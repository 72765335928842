import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_DOKUMENT = '[VorlagenModul] GetDokument laden';
export const GET_DOKUMENT_AUSFUEHREN = '[VorlagenModul] GetDokument ausführen';
export const GET_DOKUMENT_ERFOLGREICH = '[VorlagenModul] GetDokument laden erfolgreich';
export const GET_DOKUMENT_FEHLER = '[VorlagenModul] GetDokument Ladefehler';
export const GET_DOKUMENT_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetDokument nicht ausgeführt (evtl. Offline)';



export class GetDokumentAction implements Action {
    readonly type = GET_DOKUMENT;
        constructor( public dokument_id:string , public optPayload: any = null) {}
    }
export class GetDokumentAusfuehrenAction implements Action {
    readonly type = GET_DOKUMENT_AUSFUEHREN;
        constructor( public dokument_id:string , public optPayload: any = null) {}
    }
export class GetDokumentErfolgreichAction implements Action {
    readonly type = GET_DOKUMENT_ERFOLGREICH;
        constructor(public payload: api.Dokument,  public dokument_id:string , public optPayload: any = null) {}
    }
export class GetDokumentFehlerAction implements Action {
    readonly type = GET_DOKUMENT_FEHLER;
        constructor(public payload: any,  public dokument_id:string , public optPayload: any = null) {}
    }
export class GetDokumentNichtAusgefuehrtAction implements Action {
    readonly type = GET_DOKUMENT_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryDokumentActions =
    GetDokumentAction
    | GetDokumentAusfuehrenAction
    | GetDokumentErfolgreichAction
    | GetDokumentFehlerAction
    | GetDokumentNichtAusgefuehrtAction
    ;
