

import {Action} from '@ngrx/store';

export const GET_CHECKLISTE_AUFGABE_BILD_SCALED = '[VorlagenModul] GetChecklisteAufgabeBildScaled laden';
export const GET_CHECKLISTE_AUFGABE_BILD_SCALED_AUSFUEHREN = '[VorlagenModul] GetChecklisteAufgabeBildScaled ausführen';
export const GET_CHECKLISTE_AUFGABE_BILD_SCALED_ERFOLGREICH = '[VorlagenModul] GetChecklisteAufgabeBildScaled laden erfolgreich';
export const GET_CHECKLISTE_AUFGABE_BILD_SCALED_FEHLER = '[VorlagenModul] GetChecklisteAufgabeBildScaled Ladefehler';
export const GET_CHECKLISTE_AUFGABE_BILD_SCALED_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetChecklisteAufgabeBildScaled nicht ausgeführt (evtl. Offline)';



export class GetChecklisteAufgabeBildScaledAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_BILD_SCALED;
        constructor( public checkliste_id:string ,  public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabeBildScaledAusfuehrenAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_BILD_SCALED_AUSFUEHREN;
        constructor( public checkliste_id:string ,  public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabeBildScaledErfolgreichAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_BILD_SCALED_ERFOLGREICH;
        constructor( public checkliste_id:string ,  public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabeBildScaledFehlerAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_BILD_SCALED_FEHLER;
        constructor(public payload: any,  public checkliste_id:string ,  public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabeBildScaledNichtAusgefuehrtAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_BILD_SCALED_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryChecklisteAufgabeBildScaledActions =
    GetChecklisteAufgabeBildScaledAction
    | GetChecklisteAufgabeBildScaledAusfuehrenAction
    | GetChecklisteAufgabeBildScaledErfolgreichAction
    | GetChecklisteAufgabeBildScaledFehlerAction
    | GetChecklisteAufgabeBildScaledNichtAusgefuehrtAction
    ;
