import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.command.incident-uebernehmen';
import * as api from '../../../clients/incidents';


    @Injectable()
    export class IncidentsIncidentUebernehmenEffects {

        @Effect()
            IncidentUebernehmen$ = this.actions$.pipe(
                ofType(ac.POST_INCIDENT_UEBERNEHMEN),
                flatMap((x: ac.PostIncidentUebernehmenAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().postIncidentUebernehmen(x.incidentRequest).pipe(
                    map((result: any) => {
                        const nextAction = new ac.PostIncidentUebernehmenErfolgreichAction(result, x.incidentRequest,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.PostIncidentUebernehmenFehlerAction(error, x.incidentRequest,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.IncidentsCommandService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.IncidentsCommandService {
                if (this.service === null) {
                    this.service = this.injector.get(api.IncidentsCommandService);
                }
                return this.service;
            }
        
}
