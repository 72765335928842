import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFAUFGABE_HINZUFUEGEN = '[QschecksModul] PostPruefaufgabeHinzufuegen laden';
export const POST_PRUEFAUFGABE_HINZUFUEGEN_AUSFUEHREN = '[QschecksModul] PostPruefaufgabeHinzufuegen ausführen';
export const POST_PRUEFAUFGABE_HINZUFUEGEN_ERFOLGREICH = '[QschecksModul] PostPruefaufgabeHinzufuegen laden erfolgreich';
export const POST_PRUEFAUFGABE_HINZUFUEGEN_FEHLER = '[QschecksModul] PostPruefaufgabeHinzufuegen Ladefehler';
export const POST_PRUEFAUFGABE_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefaufgabeHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostPruefaufgabeHinzufuegenAction implements Action {
    readonly type = POST_PRUEFAUFGABE_HINZUFUEGEN;
        constructor(public pruefaufgabeModelRequest: api.PruefaufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFAUFGABE_HINZUFUEGEN_AUSFUEHREN;
        constructor(public pruefaufgabeModelRequest: api.PruefaufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_PRUEFAUFGABE_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefaufgabeModelRequest: api.PruefaufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeHinzufuegenFehlerAction implements Action {
    readonly type = POST_PRUEFAUFGABE_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public pruefaufgabeModelRequest: api.PruefaufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFAUFGABE_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefaufgabeHinzufuegenActions =
    PostPruefaufgabeHinzufuegenAction
    | PostPruefaufgabeHinzufuegenAusfuehrenAction
    | PostPruefaufgabeHinzufuegenErfolgreichAction
    | PostPruefaufgabeHinzufuegenFehlerAction
    | PostPruefaufgabeHinzufuegenNichtAusgefuehrtAction
    ;
