import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_MANDANTEN_CONSTANTS = '[ApiModul] GetMandantenConstants laden';
export const GET_MANDANTEN_CONSTANTS_AUSFUEHREN = '[ApiModul] GetMandantenConstants ausführen';
export const GET_MANDANTEN_CONSTANTS_ERFOLGREICH = '[ApiModul] GetMandantenConstants laden erfolgreich';
export const GET_MANDANTEN_CONSTANTS_FEHLER = '[ApiModul] GetMandantenConstants Ladefehler';
export const GET_MANDANTEN_CONSTANTS_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantenConstants nicht ausgeführt (evtl. Offline)';



export class GetMandantenConstantsAction implements Action {
    readonly type = GET_MANDANTEN_CONSTANTS;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantenConstantsAusfuehrenAction implements Action {
    readonly type = GET_MANDANTEN_CONSTANTS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantenConstantsErfolgreichAction implements Action {
    readonly type = GET_MANDANTEN_CONSTANTS_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetMandantenConstantsFehlerAction implements Action {
    readonly type = GET_MANDANTEN_CONSTANTS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantenConstantsNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANTEN_CONSTANTS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantenConstantsActions =
    GetMandantenConstantsAction
    | GetMandantenConstantsAusfuehrenAction
    | GetMandantenConstantsErfolgreichAction
    | GetMandantenConstantsFehlerAction
    | GetMandantenConstantsNichtAusgefuehrtAction
    ;
