import {Action, createFeatureSelector, createSelector} from '@ngrx/store';
import * as actions from './actions';

export const StoreWebsocketFeature = 'Websocket';

export interface WebsocketState {
    connected: boolean;
}

export const initialNotifyState: WebsocketState = {
    connected: false
};


export function websocketReducer(state = initialNotifyState, action: Action): WebsocketState {

    switch (action.type) {

        case   actions.WEBSOCKET_START_ACTION: {
            const a = <actions.WebsocketStartAction> action;

            localStorage.setItem('sopi_websocket_connect', 'true');

            return Object.assign({}, state, <WebsocketState> {
                connected: true
            });
        }

        case   actions.WEBSOCKET_STOP_ACTION: {
            const a = <actions.WebsocketStopAction> action;

            localStorage.setItem('sopi_websocket_connect', 'false');

            return Object.assign({}, state, <WebsocketState> {
                connected: false
            });
        }

        default: {
            return state;
        }
    }
}


export const getModuleState = createFeatureSelector<WebsocketState>(StoreWebsocketFeature);

export const getConnected = createSelector(getModuleState, (state: WebsocketState) => state.connected);
