import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_CHECKLISTE_AUFGABE_BILD_IDS = '[VorlagenModul] GetChecklisteAufgabeBildIds laden';
export const GET_CHECKLISTE_AUFGABE_BILD_IDS_AUSFUEHREN = '[VorlagenModul] GetChecklisteAufgabeBildIds ausführen';
export const GET_CHECKLISTE_AUFGABE_BILD_IDS_ERFOLGREICH = '[VorlagenModul] GetChecklisteAufgabeBildIds laden erfolgreich';
export const GET_CHECKLISTE_AUFGABE_BILD_IDS_FEHLER = '[VorlagenModul] GetChecklisteAufgabeBildIds Ladefehler';
export const GET_CHECKLISTE_AUFGABE_BILD_IDS_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetChecklisteAufgabeBildIds nicht ausgeführt (evtl. Offline)';



export class GetChecklisteAufgabeBildIdsAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_BILD_IDS;
        constructor( public checkliste_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabeBildIdsAusfuehrenAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_BILD_IDS_AUSFUEHREN;
        constructor( public checkliste_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabeBildIdsErfolgreichAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_BILD_IDS_ERFOLGREICH;
        constructor(public payload: api.AufgabeBildIds,  public checkliste_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabeBildIdsFehlerAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_BILD_IDS_FEHLER;
        constructor(public payload: any,  public checkliste_id:string ,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteAufgabeBildIdsNichtAusgefuehrtAction implements Action {
    readonly type = GET_CHECKLISTE_AUFGABE_BILD_IDS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryChecklisteAufgabeBildIdsActions =
    GetChecklisteAufgabeBildIdsAction
    | GetChecklisteAufgabeBildIdsAusfuehrenAction
    | GetChecklisteAufgabeBildIdsErfolgreichAction
    | GetChecklisteAufgabeBildIdsFehlerAction
    | GetChecklisteAufgabeBildIdsNichtAusgefuehrtAction
    ;
