import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { TokenDisable } from './constants';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _securityService: AuthService;

  private ignoreUrlsEndsWith: string[] = [
    'api/account/login',
    'api/account/login2',
    'api/account/refreshtoken'
  ];

  private ignoreUrlsStartsWith: string[] = [
    './assets'
  ];

  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.ignoreUrlsStartsWith.some(x => req.url.startsWith(x))) {
      console.log('http-client-auth.interceptor.ts :: ignore url, no interception startWith', req.url);
      return next.handle(req);
    }

    if (this.ignoreUrlsEndsWith.some(x => req.url.endsWith(x))) {
      console.log('http-client-auth.interceptor.ts :: ignore url, no interception endsWith', req.url);
      return next.handle(req);
    }

    // prüfen ob der token temporär deaktiviert ist
    const tokenDisabled = req.headers.has('Authorization') && req.headers.get('Authorization') === TokenDisable;

    if (tokenDisabled) {
      // original request ohne auth header
      return next.handle(req);
    }

    let requestToForward = req;

    if (this._securityService === undefined) {
      this._securityService = this.injector.get(AuthService);
    }

    if (this._securityService !== undefined) {
      const token = this._securityService.accessToken;

      if (token !== null && token !== "") {
        const tokenValue = "Bearer " + token;
        requestToForward = req.clone({
          setHeaders: { Authorization: tokenValue }
        });
      }
    } else {
      // tslint:disable-next-line:no-console
      console.debug("OidcSecurityService undefined: NO auth header!");
    }

    return next.handle(requestToForward);
  }
}
