import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_SCANNER_LICENSE = '[ApiModul] GetScannerLicense laden';
export const GET_SCANNER_LICENSE_AUSFUEHREN = '[ApiModul] GetScannerLicense ausführen';
export const GET_SCANNER_LICENSE_ERFOLGREICH = '[ApiModul] GetScannerLicense laden erfolgreich';
export const GET_SCANNER_LICENSE_FEHLER = '[ApiModul] GetScannerLicense Ladefehler';
export const GET_SCANNER_LICENSE_NICHT_AUSGEFUEHRT = '[ApiModul] GetScannerLicense nicht ausgeführt (evtl. Offline)';



export class GetScannerLicenseAction implements Action {
    readonly type = GET_SCANNER_LICENSE;
        constructor( public device_id:string ,  public app_id:string , public optPayload: any = null) {}
    }
export class GetScannerLicenseAusfuehrenAction implements Action {
    readonly type = GET_SCANNER_LICENSE_AUSFUEHREN;
        constructor( public device_id:string ,  public app_id:string , public optPayload: any = null) {}
    }
export class GetScannerLicenseErfolgreichAction implements Action {
    readonly type = GET_SCANNER_LICENSE_ERFOLGREICH;
        constructor(public payload: api.ScannerLicense,  public device_id:string ,  public app_id:string , public optPayload: any = null) {}
    }
export class GetScannerLicenseFehlerAction implements Action {
    readonly type = GET_SCANNER_LICENSE_FEHLER;
        constructor(public payload: any,  public device_id:string ,  public app_id:string , public optPayload: any = null) {}
    }
export class GetScannerLicenseNichtAusgefuehrtAction implements Action {
    readonly type = GET_SCANNER_LICENSE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryScannerLicenseActions =
    GetScannerLicenseAction
    | GetScannerLicenseAusfuehrenAction
    | GetScannerLicenseErfolgreichAction
    | GetScannerLicenseFehlerAction
    | GetScannerLicenseNichtAusgefuehrtAction
    ;
