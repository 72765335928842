import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_MEIN_DIENSTPLAN_NOTIFICATION = '[VeranstaltungenModul] GetMeinDienstplanNotification laden';
export const GET_MEIN_DIENSTPLAN_NOTIFICATION_AUSFUEHREN = '[VeranstaltungenModul] GetMeinDienstplanNotification ausführen';
export const GET_MEIN_DIENSTPLAN_NOTIFICATION_ERFOLGREICH = '[VeranstaltungenModul] GetMeinDienstplanNotification laden erfolgreich';
export const GET_MEIN_DIENSTPLAN_NOTIFICATION_FEHLER = '[VeranstaltungenModul] GetMeinDienstplanNotification Ladefehler';
export const GET_MEIN_DIENSTPLAN_NOTIFICATION_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetMeinDienstplanNotification nicht ausgeführt (evtl. Offline)';



export class GetMeinDienstplanNotificationAction implements Action {
    readonly type = GET_MEIN_DIENSTPLAN_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetMeinDienstplanNotificationAusfuehrenAction implements Action {
    readonly type = GET_MEIN_DIENSTPLAN_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMeinDienstplanNotificationErfolgreichAction implements Action {
    readonly type = GET_MEIN_DIENSTPLAN_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.MeinDienstplanNotification, public optPayload: any = null) {}
    }
export class GetMeinDienstplanNotificationFehlerAction implements Action {
    readonly type = GET_MEIN_DIENSTPLAN_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMeinDienstplanNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_MEIN_DIENSTPLAN_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryMeinDienstplanNotificationActions =
    GetMeinDienstplanNotificationAction
    | GetMeinDienstplanNotificationAusfuehrenAction
    | GetMeinDienstplanNotificationErfolgreichAction
    | GetMeinDienstplanNotificationFehlerAction
    | GetMeinDienstplanNotificationNichtAusgefuehrtAction
    ;
