import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_SMTP_MANDANT_OPTIONS = '[KommunikationModul] GetSmtpMandantOptions laden';
export const GET_SMTP_MANDANT_OPTIONS_AUSFUEHREN = '[KommunikationModul] GetSmtpMandantOptions ausführen';
export const GET_SMTP_MANDANT_OPTIONS_ERFOLGREICH = '[KommunikationModul] GetSmtpMandantOptions laden erfolgreich';
export const GET_SMTP_MANDANT_OPTIONS_FEHLER = '[KommunikationModul] GetSmtpMandantOptions Ladefehler';
export const GET_SMTP_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetSmtpMandantOptions nicht ausgeführt (evtl. Offline)';



export class GetSmtpMandantOptionsAction implements Action {
    readonly type = GET_SMTP_MANDANT_OPTIONS;
        constructor(public optPayload: any = null) {}
    }
export class GetSmtpMandantOptionsAusfuehrenAction implements Action {
    readonly type = GET_SMTP_MANDANT_OPTIONS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetSmtpMandantOptionsErfolgreichAction implements Action {
    readonly type = GET_SMTP_MANDANT_OPTIONS_ERFOLGREICH;
        constructor(public payload: api.SmtpMandantOptions, public optPayload: any = null) {}
    }
export class GetSmtpMandantOptionsFehlerAction implements Action {
    readonly type = GET_SMTP_MANDANT_OPTIONS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetSmtpMandantOptionsNichtAusgefuehrtAction implements Action {
    readonly type = GET_SMTP_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQuerySmtpMandantOptionsActions =
    GetSmtpMandantOptionsAction
    | GetSmtpMandantOptionsAusfuehrenAction
    | GetSmtpMandantOptionsErfolgreichAction
    | GetSmtpMandantOptionsFehlerAction
    | GetSmtpMandantOptionsNichtAusgefuehrtAction
    ;
