import * as api from '../../../clients/einlasskontrolle'; 

import {Action} from '@ngrx/store';

export const GET_EINLASSKONTROLLE_ENTITY_TYPES = '[EinlasskontrolleModul] GetEinlasskontrolleEntityTypes laden';
export const GET_EINLASSKONTROLLE_ENTITY_TYPES_AUSFUEHREN = '[EinlasskontrolleModul] GetEinlasskontrolleEntityTypes ausführen';
export const GET_EINLASSKONTROLLE_ENTITY_TYPES_ERFOLGREICH = '[EinlasskontrolleModul] GetEinlasskontrolleEntityTypes laden erfolgreich';
export const GET_EINLASSKONTROLLE_ENTITY_TYPES_FEHLER = '[EinlasskontrolleModul] GetEinlasskontrolleEntityTypes Ladefehler';
export const GET_EINLASSKONTROLLE_ENTITY_TYPES_NICHT_AUSGEFUEHRT = '[EinlasskontrolleModul] GetEinlasskontrolleEntityTypes nicht ausgeführt (evtl. Offline)';



export class GetEinlasskontrolleEntityTypesAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_ENTITY_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetEinlasskontrolleEntityTypesAusfuehrenAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_ENTITY_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetEinlasskontrolleEntityTypesErfolgreichAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_ENTITY_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.EntityTypeInfo>, public optPayload: any = null) {}
    }
export class GetEinlasskontrolleEntityTypesFehlerAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_ENTITY_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetEinlasskontrolleEntityTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_EINLASSKONTROLLE_ENTITY_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type EinlasskontrolleQueryEinlasskontrolleEntityTypesActions =
    GetEinlasskontrolleEntityTypesAction
    | GetEinlasskontrolleEntityTypesAusfuehrenAction
    | GetEinlasskontrolleEntityTypesErfolgreichAction
    | GetEinlasskontrolleEntityTypesFehlerAction
    | GetEinlasskontrolleEntityTypesNichtAusgefuehrtAction
    ;
