import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IonInput, ModalController, NavController, NavParams, Platform} from '@ionic/angular';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs/Observable';
import {getAktuelleVeranstaltung} from '../app-state/app-state.reducer';
import {DatenState} from '../app-state/daten.reducer';
import {PostIncidentAnlegenAction, PostIncidentTitelUndBeschreibungSetzenAction} from '../../../client-ngrx/incidents';
import {Veranstaltung} from '../../../clients/veranstaltungen';
import {Abteilung, IncidentBeschreibungRequest, IncidentDetail, IncidentManagementBenutzerZugriff, IncidentsAnzeigeMandantOptions, Rolle} from '../../../clients/incidents';
import {UuidService} from '@orga-app/providers/uuid.service';
import {getIncidentsMandantOptions} from '@orga-app/mandant/reducer';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {getAbteilungSelected, getDelegationRollen, getIncidentDetail, getIncidentDetailOriginal} from './incident.selectors';
import {takeUntil} from 'rxjs/operators';
import {IncidentSpeichernAction, SetzeIncidentAbteilungAction, SetzeIncidentBeschreibungAction, SetzeIncidentDelegationRolleAction, SetzeIncidentDetailIdAction, SetzeIncidentOrtAction, SetzeIncidentTitelAction, SetzeIncidentZentraleAction} from './incident.actions.ui';
import {NotificationService} from '@orga-app/providers/notification.service';
import * as apiVeranstaltungen from '../../../clients/veranstaltungen';
import {getZugriffIncidents} from '../../navi/navi.reducer';

/**
 * Generated class for the AufgabePage page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

@Component({
    selector: 'app-incident',
    templateUrl: './incident.page.html',
    styleUrls: ['./incident.page.scss'],
})
export class IncidentPage implements OnInit, OnDestroy {
    @Input() incidentId: string;
    @ViewChild('titelInput', {static: false}) titelInput: IonInput;

    public aktuelleVeranstaltung$: Observable<Veranstaltung>;
    public mandantOptions$: Observable<IncidentsAnzeigeMandantOptions>;

    incidentDetail$: Observable<IncidentDetail>;
    incidentDetailOriginal$: Observable<IncidentDetail>;

    abteilungSelected$: Observable<Abteilung>;
    delegationRollen$: Observable<Array<Rolle>>;

    zentraleLeitstelleTitel: string;

    zugriff$: Observable<IncidentManagementBenutzerZugriff>;


    private unsubscribe$ = new Subject();
    private aiName = 'IncidentPage';

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private appStore: Store<DatenState>,
                public platform: Platform,
                private modalCtrl: ModalController,
                private route: ActivatedRoute,
                private store: Store<DatenState>,
                public router: Router,
                private uuid: UuidService,
                private notificationService: NotificationService) {
        this.aktuelleVeranstaltung$ = this.appStore.pipe(select(getAktuelleVeranstaltung));
        this.mandantOptions$ = this.appStore.pipe(select(getIncidentsMandantOptions));

        this.mandantOptions$.subscribe(x => this.zentraleLeitstelleTitel = x.zentrale_text).unsubscribe();

        this.incidentDetail$ = this.store.pipe(select(getIncidentDetail));
        this.incidentDetailOriginal$ = this.store.pipe(select(getIncidentDetailOriginal));

        this.abteilungSelected$ = this.store.pipe(select(getAbteilungSelected));
        this.delegationRollen$ = this.store.pipe(select(getDelegationRollen));

        this.zugriff$ = this.store.pipe(select(getZugriffIncidents));
    }


    ngOnInit() {

        setTimeout(() => {
            if (this.titelInput !== null && this.titelInput !== undefined) {
                this.titelInput.setFocus();
            }
        }, 500);
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ionViewWillEnter() {
        this.viewWillEnterPage().then();
    }

    async viewWillEnterPage() {
        // console.log('IncidentId: ', this.incidentId);
        if (this.incidentId !== undefined && this.incidentId !== null) {
            this.store.dispatch(new SetzeIncidentDetailIdAction(this.incidentId));
        } else {
            this.store.dispatch(new SetzeIncidentDetailIdAction(''));
        }
    }

    ok(veranstaltung: Veranstaltung) {
        this.store.dispatch(new IncidentSpeichernAction());
        this.store.dispatch(new SetzeIncidentDetailIdAction(''));

        if (this.incidentId === undefined || this.incidentId === '') {

            this.modalCtrl.dismiss({
                result: true,
                message: 'Der Incident wurde an die ' + this.zentraleLeitstelleTitel + ' oder an die ausgewählte Abteilung übergeben.'
            });
        } else {
                // Incident Bearbeiten
                this.modalCtrl.dismiss({
                    result: true,
                    message: 'Der Incident wurde gespeichert.'
                });
        }
    }

    abbrechen() {
        this.modalCtrl.dismiss();
    }

    select($event) {
        if ($event != null && $event.target != null && typeof $event.target.select === 'function') {
            $event.target.select();
        }
    }

    back() {
        this.store.dispatch(new SetzeIncidentDetailIdAction(''));
    }

    setzeVerantwortungAbteilung(abteilung: Abteilung) {
        // console.log('Abteilung: ', abteilung);
        this.store.dispatch(new SetzeIncidentAbteilungAction(abteilung));
    }

    setzeVerantwortungZentral() {
        this.store.dispatch(new SetzeIncidentZentraleAction());
    }

    setzeDelegationRolle(rolle: Rolle) {
        this.store.dispatch(new SetzeIncidentDelegationRolleAction(rolle));
    }

    setzeBeschreibung($event: CustomEvent) {
        // console.log('SetzeBeschreibung: ', $event);
        this.store.dispatch(new SetzeIncidentBeschreibungAction($event.detail.target.value));
    }

    setzeTitel($event: CustomEvent) {
        // console.log('SetzeTitel: ', $event);
        this.store.dispatch(new SetzeIncidentTitelAction($event.detail.target.value));
    }

    setzeOrt($event: CustomEvent) {
        // console.log('SetzeOrt: ', $event);
        this.store.dispatch(new SetzeIncidentOrtAction($event.detail.target.value));
    }
}
