import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGS_TYPEN_AKTIV = '[VeranstaltungstypenModul] GetVeranstaltungsTypenAktiv laden';
export const GET_VERANSTALTUNGS_TYPEN_AKTIV_AUSFUEHREN = '[VeranstaltungstypenModul] GetVeranstaltungsTypenAktiv ausführen';
export const GET_VERANSTALTUNGS_TYPEN_AKTIV_ERFOLGREICH = '[VeranstaltungstypenModul] GetVeranstaltungsTypenAktiv laden erfolgreich';
export const GET_VERANSTALTUNGS_TYPEN_AKTIV_FEHLER = '[VeranstaltungstypenModul] GetVeranstaltungsTypenAktiv Ladefehler';
export const GET_VERANSTALTUNGS_TYPEN_AKTIV_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] GetVeranstaltungsTypenAktiv nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungsTypenAktivAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_AKTIV;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenAktivAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_AKTIV_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenAktivErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_AKTIV_ERFOLGREICH;
        constructor(public payload: Array<api.VeranstaltungsTyp>, public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenAktivFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_AKTIV_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenAktivNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_AKTIV_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenQueryVeranstaltungsTypenAktivActions =
    GetVeranstaltungsTypenAktivAction
    | GetVeranstaltungsTypenAktivAusfuehrenAction
    | GetVeranstaltungsTypenAktivErfolgreichAction
    | GetVeranstaltungsTypenAktivFehlerAction
    | GetVeranstaltungsTypenAktivNichtAusgefuehrtAction
    ;
