import {Action, ActionReducerMap} from '@ngrx/store';

import * as actionsUi from './veranstaltungstyp-auswahl.actions.ui';
import {SelektierteTypenAction} from './veranstaltungstyp-auswahl.actions.ui';
import {VeranstaltungsTyp} from '../../../clients/veranstaltungstypen';
import {GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN, GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ERFOLGREICH, GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_FEHLER, GetMitteilungsVorlageVeranstaltungsTypenErfolgreichAction} from '../../../client-ngrx/kommunikation';
import {GET_VERANSTALTUNGS_TYPEN_ADMIN, GET_VERANSTALTUNGS_TYPEN_ADMIN_ERFOLGREICH, GET_VERANSTALTUNGS_TYPEN_ADMIN_FEHLER, GetVeranstaltungsTypenAdminErfolgreichAction} from '../../../client-ngrx/veranstaltungen';
import {GET_VERANSTALTUNGS_TYPEN_AKTIV, GET_VERANSTALTUNGS_TYPEN_AKTIV_ERFOLGREICH, GET_VERANSTALTUNGS_TYPEN_AKTIV_FEHLER, GetVeranstaltungsTypenAktivAction, GetVeranstaltungsTypenAktivErfolgreichAction} from '../../../client-ngrx/veranstaltungstypen';

export interface ModuleState {
    page: PageState;
}

export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer
};

export interface PageState {
    veranstaltungsTypen: Array<VeranstaltungsTyp>;
    veranstaltungsTypenLaden: boolean;
    veranstaltungsTypenFehler: boolean;
    nichtAnzuzeigendeTypen: Array<string>;
    selektierteTypen: Array<string>;
}

export const initialPageState: PageState = {
    veranstaltungsTypen: [],
    veranstaltungsTypenLaden: false,
    veranstaltungsTypenFehler: false,
    nichtAnzuzeigendeTypen: [],
    selektierteTypen: []
};

export function pageReducer(state = initialPageState, action: Action): PageState {
    switch (action.type) {
        // Page State Reset
        case actionsUi.PAGE_STATE_RESET: {
            const a = <actionsUi.PageStateResetAction>action;

            const nichtAnzuzeigendeTypen = (a.nichtAnzuzeigendeTypenIds == null) ? [] : a.nichtAnzuzeigendeTypenIds;

            return Object.assign({}, state, <PageState>initialPageState, <PageState>{
                nichtAnzuzeigendeTypen
            });
        }

        // Veranstaltungstypen aus Kommunikation
        case GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_ERFOLGREICH: {
            const a = <GetMitteilungsVorlageVeranstaltungsTypenErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                veranstaltungsTypen: a.payload,
                veranstaltungsTypenLaden: false,
                veranstaltungsTypenFehler: false
            });
        }

        case GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN: {
            return Object.assign({}, state, <PageState>{
                veranstaltungsTypenLaden: true,
                veranstaltungsTypenFehler: false
            });
        }

        case GET_MITTEILUNGS_VORLAGE_VERANSTALTUNGS_TYPEN_FEHLER: {
            return Object.assign({}, state, <PageState>{
                veranstaltungsTypen: [],
                veranstaltungsTypenLaden: false,
                veranstaltungsTypenFehler: true
            });
        }

        // Veranstaltungstypen aus Veranstaltungen
        case GET_VERANSTALTUNGS_TYPEN_ADMIN_ERFOLGREICH: {
            const a = <GetVeranstaltungsTypenAdminErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                veranstaltungsTypen: a.payload,
                veranstaltungsTypenLaden: false,
                veranstaltungsTypenFehler: false
            });
        }

        case GET_VERANSTALTUNGS_TYPEN_ADMIN: {
            return Object.assign({}, state, <PageState>{
                veranstaltungsTypenLaden: true,
                veranstaltungsTypenFehler: false
            });
        }

        case GET_VERANSTALTUNGS_TYPEN_ADMIN_FEHLER: {
            return Object.assign({}, state, <PageState>{
                veranstaltungsTypen: [],
                veranstaltungsTypenLaden: false,
                veranstaltungsTypenFehler: true
            });
        }

        // Veranstaltungstypen aus Veranstaltungestypen
        case GET_VERANSTALTUNGS_TYPEN_AKTIV_ERFOLGREICH: {
            const a = <GetVeranstaltungsTypenAktivErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                veranstaltungsTypen: a.payload,
                veranstaltungsTypenLaden: false,
                veranstaltungsTypenFehler: false
            });
        }

        case GET_VERANSTALTUNGS_TYPEN_AKTIV: {
            return Object.assign({}, state, <PageState>{
                veranstaltungsTypenLaden: true,
                veranstaltungsTypenFehler: false
            });
        }

        case GET_VERANSTALTUNGS_TYPEN_AKTIV_FEHLER: {
            return Object.assign({}, state, <PageState>{
                veranstaltungsTypen: [],
                veranstaltungsTypenLaden: false,
                veranstaltungsTypenFehler: true
            });
        }

        case actionsUi.SELEKTIERTE_TYPEN: {
            const a = <SelektierteTypenAction>action;
            return Object.assign({}, state, <PageState>{
                selektierteTypen: a.payload
            });
        }

        default: {
            return state;
        }
    }
}

