import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_ROLLE_MITTEILUNGS_VORLAGE_LISTE = '[KommunikationModul] GetRolleMitteilungsVorlageListe laden';
export const GET_ROLLE_MITTEILUNGS_VORLAGE_LISTE_AUSFUEHREN = '[KommunikationModul] GetRolleMitteilungsVorlageListe ausführen';
export const GET_ROLLE_MITTEILUNGS_VORLAGE_LISTE_ERFOLGREICH = '[KommunikationModul] GetRolleMitteilungsVorlageListe laden erfolgreich';
export const GET_ROLLE_MITTEILUNGS_VORLAGE_LISTE_FEHLER = '[KommunikationModul] GetRolleMitteilungsVorlageListe Ladefehler';
export const GET_ROLLE_MITTEILUNGS_VORLAGE_LISTE_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetRolleMitteilungsVorlageListe nicht ausgeführt (evtl. Offline)';



export class GetRolleMitteilungsVorlageListeAction implements Action {
    readonly type = GET_ROLLE_MITTEILUNGS_VORLAGE_LISTE;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleMitteilungsVorlageListeAusfuehrenAction implements Action {
    readonly type = GET_ROLLE_MITTEILUNGS_VORLAGE_LISTE_AUSFUEHREN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleMitteilungsVorlageListeErfolgreichAction implements Action {
    readonly type = GET_ROLLE_MITTEILUNGS_VORLAGE_LISTE_ERFOLGREICH;
        constructor(public payload: Array<api.MitteilungsVorlage>,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleMitteilungsVorlageListeFehlerAction implements Action {
    readonly type = GET_ROLLE_MITTEILUNGS_VORLAGE_LISTE_FEHLER;
        constructor(public payload: any,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleMitteilungsVorlageListeNichtAusgefuehrtAction implements Action {
    readonly type = GET_ROLLE_MITTEILUNGS_VORLAGE_LISTE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryRolleMitteilungsVorlageListeActions =
    GetRolleMitteilungsVorlageListeAction
    | GetRolleMitteilungsVorlageListeAusfuehrenAction
    | GetRolleMitteilungsVorlageListeErfolgreichAction
    | GetRolleMitteilungsVorlageListeFehlerAction
    | GetRolleMitteilungsVorlageListeNichtAusgefuehrtAction
    ;
