import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {DividerGroup, ItemWrapper, ListInputItem} from './sopi-list.model';
import {IonSearchbar} from '@ionic/angular';
import {expandCollapsiblePanelAnimation} from '../core/animations';

@Component({
    selector: 'app-sopi-list',
    templateUrl: './sopi-list.component.html',
    styleUrls: ['./sopi-list.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    animations: [
        expandCollapsiblePanelAnimation
    ]
})
export class SopiListComponent implements OnInit, AfterViewInit {
    @ViewChild('suche', {static: false}) suche: IonSearchbar;

    // TEMPLATES
    @Input() headerTemplate: TemplateRef<any>;
    @Input() headerLabelTemplate: TemplateRef<any>;
    @Input() listHeaderTemplate: TemplateRef<any>;
    @Input() groupItemTemplate: TemplateRef<any>;
    @Input() listItemsTemplate: TemplateRef<any>;
    @Input() listItemsGroupedTemplate: TemplateRef<any>;
    @Input() listItemLabelTemplate: TemplateRef<any>;
    @Input() noDataTemplate: TemplateRef<any>;
    @Input() noGroupItemsTemplate: TemplateRef<any>;
    @Input() errorTemplate: TemplateRef<any>;
    @Input() singleSelectionFlatTemplate: TemplateRef<any>;

    // FUNCTIONS
    @Input() class = null;
    @Input() loading = false;
    @Input() error = false;
    @Input() setFocus = false;
    @Input() enableGroup = true;
    @Input() enableSingleSelection = false;
    @Input() enableFooterActions = true;
    @Input() enableExpandShrinkGroup = true;
    @Input() expandGroupsOnStart = true;
    @Input() enableEdit = true;
    @Input() enableRefresher = true;
    @Input() enableRefreshButton = true;
    @Input() disableSortGroups = false;
    @Input() disableSortItems = false;
    @Input() disableSearch = false;
    @Input() disableScroll = false;
    @Input() groupClass: string = null;
    @Input() itemClass: string = null;

    // DESIGN
    @Input() borderRadiusTopRight = false;
    @Input() borderRadiusTopLeft = false;
    @Input() borderRadiusBottom = false;
    @Input() borderRadiusBottomRight = false;
    @Input() borderRadiusBottomLeft = false;

    // OUTPUT
    @Output() outLastSelectedItem: any = new EventEmitter(true);
    @Output() outLastSelectedItemId: any = new EventEmitter(true);
    @Output() outSelectedItems: any = new EventEmitter(true);
    @Output() outSelectedItemIds: any = new EventEmitter(true);
    @Output() outRefresh: any = new EventEmitter(true);
    @Output() outReorder: EventEmitter<{ from: number, to: number }> = new EventEmitter(true);

    // INTERNAL VARIABLES
    sortedAndFilteredItems: Array<ListInputItem> = [];
    groupedItems: Array<DividerGroup> = [];
    items: Array<ItemWrapper> = [];
    selectedItem: ItemWrapper = null;
    searchString = '';
    collapsiblePanelOpen = 'open';
    allGroupsExpand = false;
    allGroupsShrinked = false;
    expandedGroupIds: Array<string> = [];
    firstDataRun = true;

    constructor() {
    }

    // DATA
    private _data: Array<ListInputItem>;

    get data(): Array<ListInputItem> {
        return this._data;
    }

    @Input()
    set data(data: Array<ListInputItem>) {
        this._data = data ? [...data] : [];

        if (this.selectedItemIds.length === 0 && (!this.enableExpandShrinkGroup || (this.enableExpandShrinkGroup && this.expandGroupsOnStart))) {
            this.expandedGroupIds = Array.from(new Set(data.map(x => x.groupId)));
        }

        this.refreshData();
    }

    // SELECTED ITEM IDs
    private _selectedItemIds: Array<string> = [];

    get selectedItemIds(): Array<string> {
        return this._selectedItemIds;
    }

    @Input()
    set selectedItemIds(selectedItemIds: Array<string>) {
        this._selectedItemIds = selectedItemIds ? [...selectedItemIds] : [];
        this.refreshData();
    }


    // REORDER
    private _reorderEnabled = false;

    get reorderEnabled(): boolean {
        return this._reorderEnabled;
    }

    @Input()
    set enableReorder(enableReorder: boolean) {
        this._reorderEnabled = enableReorder;
    }

    // SELECTED ITEMS
    private _selectedItemId: string = null;

    @Input()
    set selectedItemId(selectedItemId: string) {
        if (this._selectedItemId !== selectedItemId) {
            this._selectedItemId = selectedItemId;
            const item = this.data.find((x) => x.itemId === this._selectedItemId);
            if (item) {
                this.selectedItem = <ItemWrapper>{
                    id: item.itemId,
                    title: item.itemTitle,
                    selected: true,
                    item: item,
                    itemClass: item.itemClass,
                    cdkDragData: item.cdkDragData,
                };
            } else {
                // this.selectedItem = null;
                this.selectedItem = <ItemWrapper>{
                    id: selectedItemId,
                    title: null,
                    selected: true,
                    item: null
                };
            }

            this.refreshData();
        }
    }

    ngOnInit() {
        this.refreshData();
    }

    ngAfterViewInit() {
        if (this.setFocus) {
            setTimeout(function () {
                if (this.suche) {
                    this.suche.setFocus();
                }
            }.bind(this), 1500);
        }
    }

    async toggleCollapsiblePanel() {
        this.collapsiblePanelOpen = (this.collapsiblePanelOpen === 'open') ? 'closed' : 'open';
    }

    async expandAllGroups() {
        this.expandedGroupIds = this.groupedItems.map(group => group.id);
        this.refreshData();
    }

    async shrinkAllGroups() {
        this.expandedGroupIds = [];
        this.refreshData();
    }

    async expandGroup(id) {
        this.expandedGroupIds.push(id);
        this.refreshData();
    }

    async shrinkGroup(id) {
        this.expandedGroupIds = this.expandedGroupIds.filter((item) => item !== id);
        this.refreshData();
    }


    refreshData() {
        let data = this.data
            .filter(this.filterSearch.bind(this));

        if (!this.disableSortItems) {
            data = data.sort(this.sortItem.bind(this));
            // console.log('SORTIEREREI - EIN');
        }
        if (!this.disableSortGroups && this.enableGroup) {
            data = data.sort(this.sortGroup.bind(this));
            // console.log('SORTIEREREI - EIN in Gruppe');
        }

        this.sortedAndFilteredItems = data;

        if (this.enableGroup) {
            this.groupedItems = this.groupData(this.sortedAndFilteredItems);
        } else {
            this.items = this.sortedAndFilteredItems.map((item) => {
                return this.wrapToItemWrapper(item);
            });
        }
    }

    groupData(data: Array<ListInputItem>) {
        const groupedData: Array<DividerGroup> = [];
        data.forEach(item => {
            let div = groupedData.find(i => i.id === item.groupId);
            if (div == null) {

                div = <DividerGroup>{
                    id: item.groupId,
                    title: item.groupTitle,
                    group: item.group,
                    groupClass: item.groupClass,
                    cdkDropListConnectedTo: item.cdkDropListConnectedTo,
                    open: this.expandedGroupIds.includes(item.groupId),
                    items: [],
                };
                groupedData.push(div);
            }
            if (item.itemId) {
                div.items.push(this.wrapToItemWrapper(item));
            }
        });
        if (groupedData.length > 0) {
            this.firstDataRun = false;
        }
        return groupedData;
    }

    wrapToItemWrapper(item: ListInputItem) {
        return <ItemWrapper>{
            id: item.itemId,
            title: item.itemTitle,
            selected: this.selectedItemIds.includes(item.itemId),
            item: item.item,
            itemClass: item.itemClass,
            cdkDragData: item.cdkDragData,
            groupClass: item.groupClass,
            cdkDropListConnectedTo: item.cdkDropListConnectedTo
        };
    }

    filterSearch(item: ListInputItem) {
        return this.searchString === undefined || this.searchString === '' || item.itemTitle !== null && item.itemTitle.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1;
    }

    sortGroup(a: ListInputItem, b: ListInputItem) {
        if (a.groupTitle === null || b.groupTitle === null || a.groupTitle.toLowerCase() < b.groupTitle.toLowerCase()) {
            return -1;
        }
        if (a.groupTitle.toLowerCase() >= b.groupTitle.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    sortItem(a: ListInputItem, b: ListInputItem) {
        if (a.itemTitle === null || b.itemTitle === null || a.itemTitle.toLowerCase() < b.itemTitle.toLowerCase()) {
            return -1;
        }
        if (a.itemTitle.toLowerCase() >= b.itemTitle.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    trackByGroup(index, item: DividerGroup) {
        return item.id + item.title;
    }

    trackByItem(index, item: ItemWrapper) {
        return item.id + item.selected;
    }


    async search(value) {
        this.searchString = value;

        await this.refreshData();
    }

    async selectItem(item: ItemWrapper, emitChanges: boolean = true) {
        if (this.enableSingleSelection) {
            this.selectedItem = item;
            if (emitChanges) {
                await this.emitSelectedItem();
            }
        } else {
            if (this.selectedItemIds.includes(item.id)) {
                this.selectedItemIds = this.selectedItemIds.filter((id) => id !== item.id);
            } else {
                this.selectedItemIds.push(item.id);
            }
            if (emitChanges) {
                await this.emitSelectedItem();
                await this.emitSelectedItems();
                await this.refreshData();
            }
        }
    }

    async selectAll() {
        const selected = [];
        if (this.enableGroup) {
            this.groupedItems.forEach((group) => {
                group.items.forEach((item) => {
                    selected.push(item.id);
                });
            });
        } else {
            this.items.forEach((item) => {
                selected.push(item.id);
            });
        }
        this.selectedItemIds = selected;

        await this.emitSelectedItems();
        await this.refreshData();
    }

    async selectNone() {
        this.selectedItemIds = [];

        await this.emitSelectedItems();
        await this.refreshData();
    }

    async invertSelection() {
        if (this.enableGroup) {
            this.groupedItems.forEach((group) => {
                group.items.forEach((item) => {
                    this.selectItem(item, false);
                });
            });
        } else {
            this.items.forEach((item) => {
                this.selectItem(item, false);
            });
        }

        await this.emitSelectedItems();
        await this.refreshData();
    }

    // Emit data
    async emitSelectedItems() {
        await this.outSelectedItems.emit(this.data.filter((x) => this.selectedItemIds.findIndex((id) => id === x.itemId) !== -1).map(x => x.item));
        await this.outSelectedItemIds.emit([...this.selectedItemIds]);
    }

    async emitSelectedItem() {
        if (this.selectedItem) {
            await this.outLastSelectedItem.emit(this.selectedItem.item);
            await this.outLastSelectedItemId.emit(this.selectedItem.id);
        }
    }

    async refresher($event) {
        await this.refresh($event);

        setTimeout(() => {
            $event.target.complete();
        }, 0);
    }

    async refresh($event) {
        await this.outRefresh.emit($event);
    }

    async doReorder(ev: CustomEvent<any>) {
        if (!this.disableSortItems) {
            console.warn('SopiList: Achtung - Die automatische Sortierung ist nicht deaktiviert!');
        }
        await this.outReorder.emit({from: ev.detail.from, to: ev.detail.to});
        ev.detail.complete();
    }

    isEnabled(value) {
        return value === true;
    }
}
