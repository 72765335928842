

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_PRUEFBERICHT_XSD_TEMPLATE = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXsdTemplate laden';
export const GET_VERANSTALTUNG_PRUEFBERICHT_XSD_TEMPLATE_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXsdTemplate ausführen';
export const GET_VERANSTALTUNG_PRUEFBERICHT_XSD_TEMPLATE_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXsdTemplate laden erfolgreich';
export const GET_VERANSTALTUNG_PRUEFBERICHT_XSD_TEMPLATE_FEHLER = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXsdTemplate Ladefehler';
export const GET_VERANSTALTUNG_PRUEFBERICHT_XSD_TEMPLATE_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXsdTemplate nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungPruefberichtXsdTemplateAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XSD_TEMPLATE;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtXsdTemplateAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XSD_TEMPLATE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtXsdTemplateErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XSD_TEMPLATE_ERFOLGREICH;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtXsdTemplateFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XSD_TEMPLATE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtXsdTemplateNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XSD_TEMPLATE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungPruefberichtXsdTemplateActions =
    GetVeranstaltungPruefberichtXsdTemplateAction
    | GetVeranstaltungPruefberichtXsdTemplateAusfuehrenAction
    | GetVeranstaltungPruefberichtXsdTemplateErfolgreichAction
    | GetVeranstaltungPruefberichtXsdTemplateFehlerAction
    | GetVeranstaltungPruefberichtXsdTemplateNichtAusgefuehrtAction
    ;
