import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFOBJEKT_KOPIEREN = '[QschecksModul] PostPruefobjektKopieren laden';
export const POST_PRUEFOBJEKT_KOPIEREN_AUSFUEHREN = '[QschecksModul] PostPruefobjektKopieren ausführen';
export const POST_PRUEFOBJEKT_KOPIEREN_ERFOLGREICH = '[QschecksModul] PostPruefobjektKopieren laden erfolgreich';
export const POST_PRUEFOBJEKT_KOPIEREN_FEHLER = '[QschecksModul] PostPruefobjektKopieren Ladefehler';
export const POST_PRUEFOBJEKT_KOPIEREN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefobjektKopieren nicht ausgeführt (evtl. Offline)';



export class PostPruefobjektKopierenAction implements Action {
    readonly type = POST_PRUEFOBJEKT_KOPIEREN;
        constructor(public pruefobjektRequest: api.PruefobjektRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektKopierenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFOBJEKT_KOPIEREN_AUSFUEHREN;
        constructor(public pruefobjektRequest: api.PruefobjektRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektKopierenErfolgreichAction implements Action {
    readonly type = POST_PRUEFOBJEKT_KOPIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefobjektRequest: api.PruefobjektRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektKopierenFehlerAction implements Action {
    readonly type = POST_PRUEFOBJEKT_KOPIEREN_FEHLER;
        constructor(public payload: any, public pruefobjektRequest: api.PruefobjektRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektKopierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFOBJEKT_KOPIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefobjektKopierenActions =
    PostPruefobjektKopierenAction
    | PostPruefobjektKopierenAusfuehrenAction
    | PostPruefobjektKopierenErfolgreichAction
    | PostPruefobjektKopierenFehlerAction
    | PostPruefobjektKopierenNichtAusgefuehrtAction
    ;
