

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL = '[VorlagenModul] GetFunktionsbereichAufgabenBenutzerBerechtigungenExcel laden';
export const GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_AUSFUEHREN = '[VorlagenModul] GetFunktionsbereichAufgabenBenutzerBerechtigungenExcel ausführen';
export const GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_ERFOLGREICH = '[VorlagenModul] GetFunktionsbereichAufgabenBenutzerBerechtigungenExcel laden erfolgreich';
export const GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_FEHLER = '[VorlagenModul] GetFunktionsbereichAufgabenBenutzerBerechtigungenExcel Ladefehler';
export const GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetFunktionsbereichAufgabenBenutzerBerechtigungenExcel nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichAufgabenBenutzerBerechtigungenExcelAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAufgabenBenutzerBerechtigungenExcelAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_AUSFUEHREN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAufgabenBenutzerBerechtigungenExcelErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_ERFOLGREICH;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAufgabenBenutzerBerechtigungenExcelFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAufgabenBenutzerBerechtigungenExcelNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryFunktionsbereichAufgabenBenutzerBerechtigungenExcelActions =
    GetFunktionsbereichAufgabenBenutzerBerechtigungenExcelAction
    | GetFunktionsbereichAufgabenBenutzerBerechtigungenExcelAusfuehrenAction
    | GetFunktionsbereichAufgabenBenutzerBerechtigungenExcelErfolgreichAction
    | GetFunktionsbereichAufgabenBenutzerBerechtigungenExcelFehlerAction
    | GetFunktionsbereichAufgabenBenutzerBerechtigungenExcelNichtAusgefuehrtAction
    ;
