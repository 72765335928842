import { Injectable, InjectionToken } from '@angular/core';
import { IAzureAdConfig } from '@orga-app/env';

export enum AuthTypes {
    aad1 = "AzureAdV1",
    aad2 = "AzureAdV2",
    
}

export interface IMyAuthOptions {
    debug: boolean;
    loginRoute: string;
    redirectUri: string;
    azureAd: IAzureAdConfig;
    type: AuthTypes;
    redirectUriFromOrigin: boolean;
    tenantMappings: { [key: string]: string ; }
}

@Injectable({
	providedIn: "root"
})
export class MyAuthOptions implements IMyAuthOptions {
  debug: boolean;
  loginRoute: string;
  redirectUri: string;
  azureAd: IAzureAdConfig;
  type: AuthTypes;
  redirectUriFromOrigin: boolean;
  tenantMappings: { [key: string]: string ; }
}

export const MY_AUTH_CONFIG = new InjectionToken<IMyAuthOptions>('My Auth Configuration');