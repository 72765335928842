import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_DOKUMENTE = '[VeranstaltungenModul] GetVeranstaltungDokumente laden';
export const GET_VERANSTALTUNG_DOKUMENTE_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungDokumente ausführen';
export const GET_VERANSTALTUNG_DOKUMENTE_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungDokumente laden erfolgreich';
export const GET_VERANSTALTUNG_DOKUMENTE_FEHLER = '[VeranstaltungenModul] GetVeranstaltungDokumente Ladefehler';
export const GET_VERANSTALTUNG_DOKUMENTE_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungDokumente nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungDokumenteAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENTE;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumenteAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENTE_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumenteErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENTE_ERFOLGREICH;
        constructor(public payload: Array<api.Dokument>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumenteFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENTE_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumenteNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENTE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungDokumenteActions =
    GetVeranstaltungDokumenteAction
    | GetVeranstaltungDokumenteAusfuehrenAction
    | GetVeranstaltungDokumenteErfolgreichAction
    | GetVeranstaltungDokumenteFehlerAction
    | GetVeranstaltungDokumenteNichtAusgefuehrtAction
    ;
