import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_BENUTZER_TROUBLE_TICKETS_SICHTBARKEIT_ADMIN = '[BenutzerverwaltungModul] PostBenutzerTroubleTicketsSichtbarkeitAdmin laden';
export const POST_BENUTZER_TROUBLE_TICKETS_SICHTBARKEIT_ADMIN_AUSFUEHREN = '[BenutzerverwaltungModul] PostBenutzerTroubleTicketsSichtbarkeitAdmin ausführen';
export const POST_BENUTZER_TROUBLE_TICKETS_SICHTBARKEIT_ADMIN_ERFOLGREICH = '[BenutzerverwaltungModul] PostBenutzerTroubleTicketsSichtbarkeitAdmin laden erfolgreich';
export const POST_BENUTZER_TROUBLE_TICKETS_SICHTBARKEIT_ADMIN_FEHLER = '[BenutzerverwaltungModul] PostBenutzerTroubleTicketsSichtbarkeitAdmin Ladefehler';
export const POST_BENUTZER_TROUBLE_TICKETS_SICHTBARKEIT_ADMIN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostBenutzerTroubleTicketsSichtbarkeitAdmin nicht ausgeführt (evtl. Offline)';



export class PostBenutzerTroubleTicketsSichtbarkeitAdminAction implements Action {
    readonly type = POST_BENUTZER_TROUBLE_TICKETS_SICHTBARKEIT_ADMIN;
        constructor(public benutzerSichtbarkeitRequest: api.BenutzerSichtbarkeitRequest, public optPayload: any = null) {}
    }
export class PostBenutzerTroubleTicketsSichtbarkeitAdminAusfuehrenAction implements Action {
    readonly type = POST_BENUTZER_TROUBLE_TICKETS_SICHTBARKEIT_ADMIN_AUSFUEHREN;
        constructor(public benutzerSichtbarkeitRequest: api.BenutzerSichtbarkeitRequest, public optPayload: any = null) {}
    }
export class PostBenutzerTroubleTicketsSichtbarkeitAdminErfolgreichAction implements Action {
    readonly type = POST_BENUTZER_TROUBLE_TICKETS_SICHTBARKEIT_ADMIN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public benutzerSichtbarkeitRequest: api.BenutzerSichtbarkeitRequest, public optPayload: any = null) {}
    }
export class PostBenutzerTroubleTicketsSichtbarkeitAdminFehlerAction implements Action {
    readonly type = POST_BENUTZER_TROUBLE_TICKETS_SICHTBARKEIT_ADMIN_FEHLER;
        constructor(public payload: any, public benutzerSichtbarkeitRequest: api.BenutzerSichtbarkeitRequest, public optPayload: any = null) {}
    }
export class PostBenutzerTroubleTicketsSichtbarkeitAdminNichtAusgefuehrtAction implements Action {
    readonly type = POST_BENUTZER_TROUBLE_TICKETS_SICHTBARKEIT_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandBenutzerTroubleTicketsSichtbarkeitAdminActions =
    PostBenutzerTroubleTicketsSichtbarkeitAdminAction
    | PostBenutzerTroubleTicketsSichtbarkeitAdminAusfuehrenAction
    | PostBenutzerTroubleTicketsSichtbarkeitAdminErfolgreichAction
    | PostBenutzerTroubleTicketsSichtbarkeitAdminFehlerAction
    | PostBenutzerTroubleTicketsSichtbarkeitAdminNichtAusgefuehrtAction
    ;
