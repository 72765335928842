

import {Action} from '@ngrx/store';

export const GET_MANDANT_CONFIG_DOWNLOAD = '[ApiModul] GetMandantConfigDownload laden';
export const GET_MANDANT_CONFIG_DOWNLOAD_AUSFUEHREN = '[ApiModul] GetMandantConfigDownload ausführen';
export const GET_MANDANT_CONFIG_DOWNLOAD_ERFOLGREICH = '[ApiModul] GetMandantConfigDownload laden erfolgreich';
export const GET_MANDANT_CONFIG_DOWNLOAD_FEHLER = '[ApiModul] GetMandantConfigDownload Ladefehler';
export const GET_MANDANT_CONFIG_DOWNLOAD_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantConfigDownload nicht ausgeführt (evtl. Offline)';



export class GetMandantConfigDownloadAction implements Action {
    readonly type = GET_MANDANT_CONFIG_DOWNLOAD;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantConfigDownloadAusfuehrenAction implements Action {
    readonly type = GET_MANDANT_CONFIG_DOWNLOAD_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantConfigDownloadErfolgreichAction implements Action {
    readonly type = GET_MANDANT_CONFIG_DOWNLOAD_ERFOLGREICH;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantConfigDownloadFehlerAction implements Action {
    readonly type = GET_MANDANT_CONFIG_DOWNLOAD_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantConfigDownloadNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANT_CONFIG_DOWNLOAD_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantConfigDownloadActions =
    GetMandantConfigDownloadAction
    | GetMandantConfigDownloadAusfuehrenAction
    | GetMandantConfigDownloadErfolgreichAction
    | GetMandantConfigDownloadFehlerAction
    | GetMandantConfigDownloadNichtAusgefuehrtAction
    ;
