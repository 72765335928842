export namespace VeranstaltungenEntityTypes {
    export const AUFGABE_ENTITY = 'VER.AUFG';
    export const AUFGABE_ENTITY_PART_BEMERKUNG = 'BEMERKUNG';
    export const AUFGABE_ENTITY_PART_BENUTZER_BERECHTIGUNG = 'BENUTZER_BERECHTIGUNG';
    export const AUFGABE_ENTITY_PART_BILD = 'BILD';
    export const BV_ROLLE_ENTITY = 'VER.BVEXT.ROLLE';
    export const CHECKLISTE_ENTITY = 'VER.CHECKL';
    export const CHECKLISTE_ENTITY_PART_BERECHTIGUNG = 'BERECHTIGUNG';
    export const CHECKLISTE_ENTITY_PART_STATUS = 'STATUS';
    export const DOKUMENT_ENTITY = 'VER.DOK';
    export const DOKUMENT_ENTITY_PART_BERECHTIGUNG = 'BERECHTIGUNG';
    export const FEHLER_ENTITY = 'VER.FEHLR';
    export const FEHLER_ENTITY_PART_BILD = 'BILD';
    export const FEHLER_ENTITY_PART_BESCHREIBUNG = 'BESCHREIBUNG';
    export const FUNKPROTOKOLL_ENTITY = 'VER.FUNKPROT';
    export const FUNKPROTOKOLL_MELDEART_ENTITY = 'VER.FUNKPROT.MELDEART';
    export const FUNKTIONSBEREICH_ENTITY = 'VER.FB';
    export const FUNKTIONSBEREICH_ENTITY_PART_BERECHTIGUNG = 'BERECHTIGUNG';
    export const FUNKTIONSBEREICH_ENTITY_PART_STATUS = 'STATUS';
    export const ROLLE_ENTITY = 'VER.ROL';
    export const ROLLE_ENTITY_PART_BERECHTIGUNG = 'BERECHTIGUNG';
    export const ROLLE_ENTITY_PART_MITTEILUNG_OPTIONEN = 'MITTEILUNG';
    export const VERANSTALTUNG_ENTITY = 'VER.VERANST';
    export const VERANSTALTUNG_ENTITY_PART_BERECHTIGUNG = 'BERECHTIGUNG';
    export const VERANSTALTUNG_ENTITY_PART_STATUS = 'STATUS';
    export const VERANSTALTUNG_ENTITY_PART_INFO_KATEGORIE = 'INFOKATEGORIE';
    export const VERANSTALTUNG_ENTITY_PART_AKTION = 'AKTION';
    export const VERANSTALTUNG_ENTITY_PART_KOPF = 'KOPF';
    
}

