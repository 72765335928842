import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_PRUEFLAUF_STATUS = '[QschecksModul] GetVeranstaltungPrueflaufStatus laden';
export const GET_VERANSTALTUNG_PRUEFLAUF_STATUS_AUSFUEHREN = '[QschecksModul] GetVeranstaltungPrueflaufStatus ausführen';
export const GET_VERANSTALTUNG_PRUEFLAUF_STATUS_ERFOLGREICH = '[QschecksModul] GetVeranstaltungPrueflaufStatus laden erfolgreich';
export const GET_VERANSTALTUNG_PRUEFLAUF_STATUS_FEHLER = '[QschecksModul] GetVeranstaltungPrueflaufStatus Ladefehler';
export const GET_VERANSTALTUNG_PRUEFLAUF_STATUS_NICHT_AUSGEFUEHRT = '[QschecksModul] GetVeranstaltungPrueflaufStatus nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungPrueflaufStatusAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFLAUF_STATUS;
        constructor(public prueflaufStatusRequest: api.PrueflaufStatusRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungPrueflaufStatusAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFLAUF_STATUS_AUSFUEHREN;
        constructor(public prueflaufStatusRequest: api.PrueflaufStatusRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungPrueflaufStatusErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFLAUF_STATUS_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungPrueflaufStatus, public prueflaufStatusRequest: api.PrueflaufStatusRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungPrueflaufStatusFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFLAUF_STATUS_FEHLER;
        constructor(public payload: any, public prueflaufStatusRequest: api.PrueflaufStatusRequest, public optPayload: any = null) {}
    }
export class GetVeranstaltungPrueflaufStatusNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFLAUF_STATUS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandVeranstaltungPrueflaufStatusActions =
    GetVeranstaltungPrueflaufStatusAction
    | GetVeranstaltungPrueflaufStatusAusfuehrenAction
    | GetVeranstaltungPrueflaufStatusErfolgreichAction
    | GetVeranstaltungPrueflaufStatusFehlerAction
    | GetVeranstaltungPrueflaufStatusNichtAusgefuehrtAction
    ;
