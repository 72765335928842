import {Action, ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';

import * as actionsUi from './funktionsbereichsauswahl.actions.ui';
import { APP_RESET_ACTION } from '@orga-app/appstart/actions';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';
import {Funktionsbereich} from '../../../clients/veranstaltungen';
import {GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE, GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE_ERFOLGREICH, GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE_FEHLER, GetVeranstaltungsTypFunktionsbereicheErfolgreichAction} from '../../../client-ngrx/veranstaltungstypen';


function gruppierenUndFiltern(funktionsbereiche: Array<CheckboxWrapper>, state: PageState, filterString: string = '') {
    const gruppiert: Array<DividerGroup> = [];
    if (filterString == null) {
        filterString = '';
    }
    funktionsbereiche.filter(item => {
        return filterString === '' || item.titel.toLowerCase().indexOf(filterString.toLowerCase()) !== -1;
    }).filter(item => {
        const nichtAnzuzeigenderFunktionsbereich = state.nichtAnzuzeigendeFunktionsbereiche.find(i => {
            return i === item.funktionsbereich.funktionsbereich_id;
        });
        return nichtAnzuzeigenderFunktionsbereich == null;
    })
        .forEach(item => {
            let divTitle = '';

            if (item.funktionsbereich != null && item.funktionsbereich.titel != null) {
                divTitle = item.funktionsbereich.titel.substring(0, 1).toUpperCase();
            } else {
                divTitle = '';
            }
            let div = gruppiert.find(i => i.titel === divTitle);

            if (div == null) {
                div = <DividerGroup>{titel: divTitle, funktionsbereiche: []};
                gruppiert.push(div);
            }

            div.funktionsbereiche.push(item);
        });
    return gruppiert;
}


export interface DividerGroup {
    titel: string;
    funktionsbereiche: CheckboxWrapper[];
}

export interface CheckboxWrapper {
    titel: string;
    funktionsbereich: Funktionsbereich;
}


export interface ModuleState {
    page: PageState;
}


export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer
};


export interface PageState {
    funktionsbereiche: Array<CheckboxWrapper>;
    funktionsbereicheSelektiert: Array<ListInputItem>;
    nichtAnzuzeigendeFunktionsbereiche: Array<any>;
    uiFunktionsbereicheLadeanimation: boolean;
    uiFunktionsbereicheLadeFehler: boolean;
    uiAktuellerFilterString: string;
}

export const initialPageState: PageState = {
    funktionsbereiche: [],
    funktionsbereicheSelektiert: [],
    nichtAnzuzeigendeFunktionsbereiche: [],
    uiFunktionsbereicheLadeanimation: false,
    uiFunktionsbereicheLadeFehler: false,
    uiAktuellerFilterString: ''
};

export function pageReducer(state = initialPageState, action: Action): PageState {
    switch (action.type) {

        // APP RESET
        case APP_RESET_ACTION: {
            return Object.assign({}, state, <PageState>initialPageState);
        }

        // Page State Reset
        case actionsUi.PAGE_STATE_RESET: {
            const a = <actionsUi.PageStateResetAction>action;

            return Object.assign({}, state, <PageState>initialPageState);
        }

        case GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE_ERFOLGREICH: {
            const a = <GetVeranstaltungsTypFunktionsbereicheErfolgreichAction>action;

            // Benutzer wrappen
            const checkboxWrapped: CheckboxWrapper[] = a.payload.map(item => {
                return <CheckboxWrapper>{
                    titel: item.titel,
                    selected: false,
                    funktionsbereich: item
                };
            });

            return Object.assign({}, state, <PageState>{
                // uiIndex: neuerIndex,
                funktionsbereiche: checkboxWrapped,
                uiFunktionsbereicheLadeanimation: false
            });
        }

        case GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE: {
            return Object.assign({}, state, <PageState>{uiFunktionsbereicheLadeanimation: true});
        }

        case GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE_FEHLER: {
            return Object.assign({}, state, <PageState>{
                uiFunktionsbereicheLadeanimation: false,
                uiFunktionsbereicheLadeFehler: true
            });
        }

        case actionsUi.NICHT_ANZUZEIGENDE_FUNKTIONSBEREICHE_SETZEN: {
            const a = <actionsUi.NichtAnzuzeigendeFunktionsbereicheSetzenAction>action;

            let result = [];
            if (a.nichtAnzuzeigendeFunktionsbereiche !== null && a.nichtAnzuzeigendeFunktionsbereiche !== undefined) {
                result = a.nichtAnzuzeigendeFunktionsbereiche.map(x => x);
            }
            return Object.assign({}, state, <PageState>{nichtAnzuzeigendeFunktionsbereiche: result});
        }

        case actionsUi.FUNKTIONSBEREICHE_SETZEN: {
            const a = <actionsUi.FunktionsbereicheSetzenAction>action;

            return Object.assign({}, state, <PageState> {
                funktionsbereicheSelektiert: a.items
            });
        }

        default: {
            return state;
        }
    }
}


/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const getModuleState = createFeatureSelector<ModuleState>('FunktionsbereichsauswahlModal');

export const getPageState = createSelector(getModuleState, (state: ModuleState) => state.page);

export const getFunktionsbereiche = createSelector(getPageState, (state: PageState) => {
        const funktionsbereiche = [...state.funktionsbereiche];
        return funktionsbereiche.sort(function (a, b) {
            if (a.titel.toLowerCase() < b.titel.toLowerCase()) {
                return -1;
            }
            if (a.titel.toLowerCase() > b.titel.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }
);

export const getFunktionsbereicheGruppiert = createSelector(getPageState, (state: PageState) => {
    const res: Array<ListInputItem> = [];

    const gruppiert: Array<DividerGroup> = gruppierenUndFiltern(state.funktionsbereiche, state);
    gruppiert.forEach((gruppe) => {
        gruppe.funktionsbereiche.forEach(fb => {
            res.push(<ListInputItem>{
                groupId: gruppe.titel,
                groupTitle: gruppe.titel,
                itemId: fb.funktionsbereich.funktionsbereich_id,
                itemTitle: fb.titel,
                item: fb.funktionsbereich,
            });
        });
    });

    return res;
});

export const getUiFunktionsbereicheLadeanimation = createSelector(getPageState, (state: PageState) => state.uiFunktionsbereicheLadeanimation);

export const getUiFunktionsbereicheLadeFehler = createSelector(getPageState, (state: PageState) => state.uiFunktionsbereicheLadeFehler);

export const getFunktionsbereicheSelektiert = createSelector(getPageState, (state: PageState) => state.funktionsbereicheSelektiert);
