import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {ImpressumPage} from './impressum.page';
import {SopiComponentsModule} from '../shared/sopi-components/sopi-components.module';
import {SopiDirectivesModule} from '../../sopi/directives/sopi-directives.module';
import {TranslateModule} from '@ngx-translate/core';
import {SopiScrollModule} from '../../sopi/components/sopi-scroll/sopi-scroll.module';

const routes: Routes = [
    {
        path: '',
        component: ImpressumPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SopiScrollModule,
        RouterModule.forChild(routes),
        SopiComponentsModule.forRoot(),
        SopiDirectivesModule.forRoot(),
        TranslateModule,
    ],
    declarations: [ImpressumPage],
    entryComponents: [
        ImpressumPage
    ]
})

export class ImpressumPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: ImpressumPageModule,
        };
    }
}
