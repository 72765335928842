import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-veranstaltung-kopieren',
    templateUrl: './veranstaltung-kopieren.page.html'
})
export class VeranstaltungKopierenPage implements OnInit {
    @Input() titel: string;
    @Input() untertitel = '';
    @Input() hinweistext: Array<string> = [];
    @Input() abbrechenButton = '';
    @Input() okButton = '';
    @Input() iconName = 'warning';
    @Input() iconClass = 'meldungsicon warnung';
    @Input() abbrechenButtonClass = '';
    @Input() okButtonClass = '';
    private aiName = 'KopierenModal';

    constructor(private modalCtrl: ModalController) {
        // this.titel = this.navParams.get('titel');
        // this.untertitel = this.navParams.get('untertitel');
        // this.hinweistext = this.navParams.get('hinweistext');
        // this.abbrechenButton = this.navParams.get('abbrechenButton');
        // this.okButton = this.navParams.get('okButton');
        // this.iconName = (this.navParams.get('iconName') != null) ? this.navParams.get('iconName') : this.iconName;
        // this.iconClass = (this.navParams.get('iconClass') != null) ? this.navParams.get('iconClass') : this.iconClass;
        // this.abbrechenButtonClass = (this.navParams.get('abbrechenButtonClass') != null) ? this.navParams.get('abbrechenButtonClass') : this.abbrechenButtonClass;
        // this.okButtonClass = (this.navParams.get('okButtonClass') != null) ? this.navParams.get('okButtonClass') : this.okButtonClass;
    }

    ngOnInit() {
    }

    ionViewDidLoad() {
    }


    ok() {
        this.modalCtrl.dismiss('ok');
    }

    abbrechen() {
        this.modalCtrl.dismiss();
    }


}
