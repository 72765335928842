import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENTS_BENUTZER_ZUGRIFF = '[IncidentsModul] GetIncidentsBenutzerZugriff laden';
export const GET_INCIDENTS_BENUTZER_ZUGRIFF_AUSFUEHREN = '[IncidentsModul] GetIncidentsBenutzerZugriff ausführen';
export const GET_INCIDENTS_BENUTZER_ZUGRIFF_ERFOLGREICH = '[IncidentsModul] GetIncidentsBenutzerZugriff laden erfolgreich';
export const GET_INCIDENTS_BENUTZER_ZUGRIFF_FEHLER = '[IncidentsModul] GetIncidentsBenutzerZugriff Ladefehler';
export const GET_INCIDENTS_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentsBenutzerZugriff nicht ausgeführt (evtl. Offline)';



export class GetIncidentsBenutzerZugriffAction implements Action {
    readonly type = GET_INCIDENTS_BENUTZER_ZUGRIFF;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsBenutzerZugriffAusfuehrenAction implements Action {
    readonly type = GET_INCIDENTS_BENUTZER_ZUGRIFF_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsBenutzerZugriffErfolgreichAction implements Action {
    readonly type = GET_INCIDENTS_BENUTZER_ZUGRIFF_ERFOLGREICH;
        constructor(public payload: api.IncidentManagementBenutzerZugriff, public optPayload: any = null) {}
    }
export class GetIncidentsBenutzerZugriffFehlerAction implements Action {
    readonly type = GET_INCIDENTS_BENUTZER_ZUGRIFF_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetIncidentsBenutzerZugriffNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENTS_BENUTZER_ZUGRIFF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentsBenutzerZugriffActions =
    GetIncidentsBenutzerZugriffAction
    | GetIncidentsBenutzerZugriffAusfuehrenAction
    | GetIncidentsBenutzerZugriffErfolgreichAction
    | GetIncidentsBenutzerZugriffFehlerAction
    | GetIncidentsBenutzerZugriffNichtAusgefuehrtAction
    ;
