import {Action} from '@ngrx/store';

export const APP_RESET_ACTION = '[App] Reset App';

export const START_APP_ACTION = '[App] Start der App';

// APP RESET
export class AppResetAction implements Action {
    readonly type = APP_RESET_ACTION;

    constructor() {}
}


export class StartAppAction implements Action {
    readonly type = START_APP_ACTION;

    constructor(public returnUrl: string = null) {
    }
}

export type Actions =
    StartAppAction
    | AppResetAction
    ;
