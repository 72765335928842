import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_ROLLE_BENUTZER_ADMIN = '[VeranstaltungenModul] GetRolleBenutzerAdmin laden';
export const GET_ROLLE_BENUTZER_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetRolleBenutzerAdmin ausführen';
export const GET_ROLLE_BENUTZER_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetRolleBenutzerAdmin laden erfolgreich';
export const GET_ROLLE_BENUTZER_ADMIN_FEHLER = '[VeranstaltungenModul] GetRolleBenutzerAdmin Ladefehler';
export const GET_ROLLE_BENUTZER_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetRolleBenutzerAdmin nicht ausgeführt (evtl. Offline)';



export class GetRolleBenutzerAdminAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_ADMIN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleBenutzerAdminAusfuehrenAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_ADMIN_AUSFUEHREN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleBenutzerAdminErfolgreichAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.RolleBenutzer>,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleBenutzerAdminFehlerAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_ADMIN_FEHLER;
        constructor(public payload: any,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleBenutzerAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryRolleBenutzerAdminActions =
    GetRolleBenutzerAdminAction
    | GetRolleBenutzerAdminAusfuehrenAction
    | GetRolleBenutzerAdminErfolgreichAction
    | GetRolleBenutzerAdminFehlerAction
    | GetRolleBenutzerAdminNichtAusgefuehrtAction
    ;
