import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_ROLLE_BENUTZER_HINZUFUEGEN_MULTI = '[BenutzerverwaltungModul] PostRolleBenutzerHinzufuegenMulti laden';
export const POST_ROLLE_BENUTZER_HINZUFUEGEN_MULTI_AUSFUEHREN = '[BenutzerverwaltungModul] PostRolleBenutzerHinzufuegenMulti ausführen';
export const POST_ROLLE_BENUTZER_HINZUFUEGEN_MULTI_ERFOLGREICH = '[BenutzerverwaltungModul] PostRolleBenutzerHinzufuegenMulti laden erfolgreich';
export const POST_ROLLE_BENUTZER_HINZUFUEGEN_MULTI_FEHLER = '[BenutzerverwaltungModul] PostRolleBenutzerHinzufuegenMulti Ladefehler';
export const POST_ROLLE_BENUTZER_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostRolleBenutzerHinzufuegenMulti nicht ausgeführt (evtl. Offline)';



export class PostRolleBenutzerHinzufuegenMultiAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_HINZUFUEGEN_MULTI;
        constructor(public rolleBenutzerMultiRequest: api.RolleBenutzerMultiRequest, public optPayload: any = null) {}
    }
export class PostRolleBenutzerHinzufuegenMultiAusfuehrenAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_HINZUFUEGEN_MULTI_AUSFUEHREN;
        constructor(public rolleBenutzerMultiRequest: api.RolleBenutzerMultiRequest, public optPayload: any = null) {}
    }
export class PostRolleBenutzerHinzufuegenMultiErfolgreichAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_HINZUFUEGEN_MULTI_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public rolleBenutzerMultiRequest: api.RolleBenutzerMultiRequest, public optPayload: any = null) {}
    }
export class PostRolleBenutzerHinzufuegenMultiFehlerAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_HINZUFUEGEN_MULTI_FEHLER;
        constructor(public payload: any, public rolleBenutzerMultiRequest: api.RolleBenutzerMultiRequest, public optPayload: any = null) {}
    }
export class PostRolleBenutzerHinzufuegenMultiNichtAusgefuehrtAction implements Action {
    readonly type = POST_ROLLE_BENUTZER_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandRolleBenutzerHinzufuegenMultiActions =
    PostRolleBenutzerHinzufuegenMultiAction
    | PostRolleBenutzerHinzufuegenMultiAusfuehrenAction
    | PostRolleBenutzerHinzufuegenMultiErfolgreichAction
    | PostRolleBenutzerHinzufuegenMultiFehlerAction
    | PostRolleBenutzerHinzufuegenMultiNichtAusgefuehrtAction
    ;
