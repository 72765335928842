import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {BildVorschauComponent} from './bild-vorschau';
import {IonicModule} from '@ionic/angular';
import {CommonModule} from '@angular/common';
import {SopiComponentsModule} from '../../../app/shared/sopi-components/sopi-components.module';

@NgModule({
  declarations: [
    BildVorschauComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
    SopiComponentsModule.forRoot(),
  ],
  // imports: [
  //   IonicPageModule.forChild(BildVorschauComponent),
  // ],
  exports: [
    BildVorschauComponent
  ],
  entryComponents: [
    BildVorschauComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BildVorschauModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: BildVorschauModule
    };
  }
}
