import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNGS_TYP_AKTUALISIEREN = '[VeranstaltungstypenModul] PostVeranstaltungsTypAktualisieren laden';
export const POST_VERANSTALTUNGS_TYP_AKTUALISIEREN_AUSFUEHREN = '[VeranstaltungstypenModul] PostVeranstaltungsTypAktualisieren ausführen';
export const POST_VERANSTALTUNGS_TYP_AKTUALISIEREN_ERFOLGREICH = '[VeranstaltungstypenModul] PostVeranstaltungsTypAktualisieren laden erfolgreich';
export const POST_VERANSTALTUNGS_TYP_AKTUALISIEREN_FEHLER = '[VeranstaltungstypenModul] PostVeranstaltungsTypAktualisieren Ladefehler';
export const POST_VERANSTALTUNGS_TYP_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] PostVeranstaltungsTypAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungsTypAktualisierenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_AKTUALISIEREN;
        constructor(public veranstaltungsTypStammRequest: api.VeranstaltungsTypStammRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_AKTUALISIEREN_AUSFUEHREN;
        constructor(public veranstaltungsTypStammRequest: api.VeranstaltungsTypStammRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAktualisierenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungsTypStammRequest: api.VeranstaltungsTypStammRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAktualisierenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public veranstaltungsTypStammRequest: api.VeranstaltungsTypStammRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenCommandVeranstaltungsTypAktualisierenActions =
    PostVeranstaltungsTypAktualisierenAction
    | PostVeranstaltungsTypAktualisierenAusfuehrenAction
    | PostVeranstaltungsTypAktualisierenErfolgreichAction
    | PostVeranstaltungsTypAktualisierenFehlerAction
    | PostVeranstaltungsTypAktualisierenNichtAusgefuehrtAction
    ;
