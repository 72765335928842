

import {Action} from '@ngrx/store';

export const GET_MANDANT_ID = '[ApiModul] GetMandantId laden';
export const GET_MANDANT_ID_AUSFUEHREN = '[ApiModul] GetMandantId ausführen';
export const GET_MANDANT_ID_ERFOLGREICH = '[ApiModul] GetMandantId laden erfolgreich';
export const GET_MANDANT_ID_FEHLER = '[ApiModul] GetMandantId Ladefehler';
export const GET_MANDANT_ID_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantId nicht ausgeführt (evtl. Offline)';



export class GetMandantIdAction implements Action {
    readonly type = GET_MANDANT_ID;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantIdAusfuehrenAction implements Action {
    readonly type = GET_MANDANT_ID_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantIdErfolgreichAction implements Action {
    readonly type = GET_MANDANT_ID_ERFOLGREICH;
        constructor(public payload: string, public optPayload: any = null) {}
    }
export class GetMandantIdFehlerAction implements Action {
    readonly type = GET_MANDANT_ID_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantIdNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANT_ID_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantIdActions =
    GetMandantIdAction
    | GetMandantIdAusfuehrenAction
    | GetMandantIdErfolgreichAction
    | GetMandantIdFehlerAction
    | GetMandantIdNichtAusgefuehrtAction
    ;
