import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICH_CHECKLISTEN = '[VorlagenModul] GetFunktionsbereichChecklisten laden';
export const GET_FUNKTIONSBEREICH_CHECKLISTEN_AUSFUEHREN = '[VorlagenModul] GetFunktionsbereichChecklisten ausführen';
export const GET_FUNKTIONSBEREICH_CHECKLISTEN_ERFOLGREICH = '[VorlagenModul] GetFunktionsbereichChecklisten laden erfolgreich';
export const GET_FUNKTIONSBEREICH_CHECKLISTEN_FEHLER = '[VorlagenModul] GetFunktionsbereichChecklisten Ladefehler';
export const GET_FUNKTIONSBEREICH_CHECKLISTEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetFunktionsbereichChecklisten nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichChecklistenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichChecklistenAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_AUSFUEHREN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichChecklistenErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_ERFOLGREICH;
        constructor(public payload: Array<api.Checkliste>,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichChecklistenFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichChecklistenNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_CHECKLISTEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryFunktionsbereichChecklistenActions =
    GetFunktionsbereichChecklistenAction
    | GetFunktionsbereichChecklistenAusfuehrenAction
    | GetFunktionsbereichChecklistenErfolgreichAction
    | GetFunktionsbereichChecklistenFehlerAction
    | GetFunktionsbereichChecklistenNichtAusgefuehrtAction
    ;
