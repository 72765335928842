import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import * as actionsUi from './triggerauswahl.actions.ui';
import * as fromTriggerModal from './triggerauswahl.reducer';
import {IonSearchbar, ModalController, NavController, NavParams} from '@ionic/angular';
import {select, Store} from '@ngrx/store';
import { Trigger } from 'src/clients/displaysteuerung';
import {GetTriggerListAdminAction} from '../../../../client-ngrx/displaysteuerung';
import {ListInputItem} from '../../../shared/sopi-list/sopi-list.model';

@Component({
    selector: 'app-triggerauswahl',
    templateUrl: './triggerauswahl.page.html',
})

export class TriggerauswahlPage implements OnInit {
    @ViewChild('suche', {static: false}) suche: IonSearchbar;

    private aiName = 'TriggerauswahlModal';

    trigger$: Observable<ListInputItem[]>;
    triggerLaden$: Observable<boolean>;
    triggerFehler$: Observable<boolean>;
    selektierteTrigger$: Observable<Trigger[]>;
    titel = '';
    aktuell: Array<any>;

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private modalCtrl: ModalController,
                private store: Store<fromTriggerModal.PageState>) {
        this.store.dispatch(new actionsUi.PageStateResetAction());
        this.aktuell = this.navParams.get('aktuell');
        this.titel = this.navParams.get('titel');
    }

    ngOnInit() {
        this.trigger$ = this.store.pipe(select(fromTriggerModal.getTrigger()));
        this.triggerLaden$ = this.store.pipe(select(fromTriggerModal.getTriggerLaden()));
        this.triggerFehler$ = this.store.pipe(select(fromTriggerModal.getTriggerFehler()));
        this.selektierteTrigger$ = this.store.pipe(select(fromTriggerModal.getSelektierteTrigger()));

        this.store.dispatch(new actionsUi.NichtAnzuzeigendeTriggerSetzenAction(this.aktuell));
    }

    ionViewWillEnter() {
        this.viewWillEnterPage().then();
    }

    async viewWillEnterPage() {
        this.triggerLaden();

        setTimeout(() => {
            if (this.suche) {
                this.suche.setFocus();
            }
        }, 500);
    }

    async ok() {
        this.store.pipe(select(fromTriggerModal.getSelektierteTrigger())).subscribe(ausgewaehlte => {
            const data = {hinzugefuegte: ausgewaehlte, entfernte: []};
            this.modalCtrl.dismiss(data);
        }).unsubscribe();
    }

    async abbrechen() {
        await this.modalCtrl.dismiss();
    }

    async triggerLaden() {
        await this.store.dispatch(new GetTriggerListAdminAction());
    }

    async updateSelectedTrigger($event: Trigger[]) {
        await this.store.dispatch(new actionsUi.SelektierteTriggerAction($event));
    }
}
