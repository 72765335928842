import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';

// import * as fromLayout from './layout.reducer';
import {environment} from '../environments/environment';


/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface AppState {
    // layout: fromLayout.LayoutState;
    // routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<AppState> = {
    // layout: fromLayout.layoutReducer
    // routerReducer: fromRouter.routerReducer,
};


// console.log all actions
export function logger(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return (state: AppState, action: any): AppState => {
        // console.log('state', state);
        // console.log('action', action);

        return reducer(state, action);
    };
}


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
