export namespace VeranstaltungenNotificationtypes {
    export const AUFGABE_ERINNERUNG_NOTIFICATION = 'VER.AUFGABE.ERINNERUNG';
    export const AUFGABE_ESKALATION_NOTIFICATION = 'VER.AUFGABE.ESKALATION';
    export const AUFGABE_NICHT_OK_NOTIFICATION = 'VER.AUFGABE.NOK';
    export const CHECKLISTE_UPDATE_NOTIFICATION = 'UPDATE';
    export const FUNKTIONSBEREICH_OK_NOTIFICATION = 'VER.FB.OK';
    export const MEIN_DIENSTPLAN_NOTIFICATION = 'VER.DIENST';
    export const VERANSTALTUNG_INFO_KATEGORIE_NOTIFICATION = 'UPDATE';
    
}

