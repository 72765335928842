import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNGS_TYP_ENTFERNEN = '[VeranstaltungstypenModul] PostVeranstaltungsTypEntfernen laden';
export const POST_VERANSTALTUNGS_TYP_ENTFERNEN_AUSFUEHREN = '[VeranstaltungstypenModul] PostVeranstaltungsTypEntfernen ausführen';
export const POST_VERANSTALTUNGS_TYP_ENTFERNEN_ERFOLGREICH = '[VeranstaltungstypenModul] PostVeranstaltungsTypEntfernen laden erfolgreich';
export const POST_VERANSTALTUNGS_TYP_ENTFERNEN_FEHLER = '[VeranstaltungstypenModul] PostVeranstaltungsTypEntfernen Ladefehler';
export const POST_VERANSTALTUNGS_TYP_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] PostVeranstaltungsTypEntfernen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungsTypEntfernenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ENTFERNEN;
        constructor(public veranstaltungsTypIdRequest: api.VeranstaltungsTypIdRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypEntfernenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ENTFERNEN_AUSFUEHREN;
        constructor(public veranstaltungsTypIdRequest: api.VeranstaltungsTypIdRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypEntfernenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungsTypIdRequest: api.VeranstaltungsTypIdRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypEntfernenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ENTFERNEN_FEHLER;
        constructor(public payload: any, public veranstaltungsTypIdRequest: api.VeranstaltungsTypIdRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenCommandVeranstaltungsTypEntfernenActions =
    PostVeranstaltungsTypEntfernenAction
    | PostVeranstaltungsTypEntfernenAusfuehrenAction
    | PostVeranstaltungsTypEntfernenErfolgreichAction
    | PostVeranstaltungsTypEntfernenFehlerAction
    | PostVeranstaltungsTypEntfernenNichtAusgefuehrtAction
    ;
