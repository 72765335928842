import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_INFO_KATEGORIE_ERFASSEN = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieErfassen laden';
export const POST_VERANSTALTUNG_INFO_KATEGORIE_ERFASSEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieErfassen ausführen';
export const POST_VERANSTALTUNG_INFO_KATEGORIE_ERFASSEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieErfassen laden erfolgreich';
export const POST_VERANSTALTUNG_INFO_KATEGORIE_ERFASSEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieErfassen Ladefehler';
export const POST_VERANSTALTUNG_INFO_KATEGORIE_ERFASSEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungInfoKategorieErfassen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungInfoKategorieErfassenAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_ERFASSEN;
        constructor(public veranstaltungInfoKategorieErfassenRequest: api.VeranstaltungInfoKategorieErfassenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungInfoKategorieErfassenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_ERFASSEN_AUSFUEHREN;
        constructor(public veranstaltungInfoKategorieErfassenRequest: api.VeranstaltungInfoKategorieErfassenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungInfoKategorieErfassenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_ERFASSEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungInfoKategorieErfassenRequest: api.VeranstaltungInfoKategorieErfassenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungInfoKategorieErfassenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_ERFASSEN_FEHLER;
        constructor(public payload: any, public veranstaltungInfoKategorieErfassenRequest: api.VeranstaltungInfoKategorieErfassenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungInfoKategorieErfassenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_INFO_KATEGORIE_ERFASSEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungInfoKategorieErfassenActions =
    PostVeranstaltungInfoKategorieErfassenAction
    | PostVeranstaltungInfoKategorieErfassenAusfuehrenAction
    | PostVeranstaltungInfoKategorieErfassenErfolgreichAction
    | PostVeranstaltungInfoKategorieErfassenFehlerAction
    | PostVeranstaltungInfoKategorieErfassenNichtAusgefuehrtAction
    ;
