

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_PDF = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtPdf laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_PDF_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtPdf ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_PDF_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtPdf laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_PDF_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtPdf Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_PDF_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichPruefberichtPdf nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereichPruefberichtPdfAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_PDF;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichPruefberichtPdfAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_PDF_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichPruefberichtPdfErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_PDF_ERFOLGREICH;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichPruefberichtPdfFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_PDF_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichPruefberichtPdfNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_PRUEFBERICHT_PDF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereichPruefberichtPdfActions =
    GetVeranstaltungFunktionsbereichPruefberichtPdfAction
    | GetVeranstaltungFunktionsbereichPruefberichtPdfAusfuehrenAction
    | GetVeranstaltungFunktionsbereichPruefberichtPdfErfolgreichAction
    | GetVeranstaltungFunktionsbereichPruefberichtPdfFehlerAction
    | GetVeranstaltungFunktionsbereichPruefberichtPdfNichtAusgefuehrtAction
    ;
