import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_ROLLE_UMBENENNEN = '[BenutzerverwaltungModul] PostRolleUmbenennen laden';
export const POST_ROLLE_UMBENENNEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostRolleUmbenennen ausführen';
export const POST_ROLLE_UMBENENNEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostRolleUmbenennen laden erfolgreich';
export const POST_ROLLE_UMBENENNEN_FEHLER = '[BenutzerverwaltungModul] PostRolleUmbenennen Ladefehler';
export const POST_ROLLE_UMBENENNEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostRolleUmbenennen nicht ausgeführt (evtl. Offline)';



export class PostRolleUmbenennenAction implements Action {
    readonly type = POST_ROLLE_UMBENENNEN;
        constructor(public rolleUmbenennenRequest: api.RolleUmbenennenRequest, public optPayload: any = null) {}
    }
export class PostRolleUmbenennenAusfuehrenAction implements Action {
    readonly type = POST_ROLLE_UMBENENNEN_AUSFUEHREN;
        constructor(public rolleUmbenennenRequest: api.RolleUmbenennenRequest, public optPayload: any = null) {}
    }
export class PostRolleUmbenennenErfolgreichAction implements Action {
    readonly type = POST_ROLLE_UMBENENNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public rolleUmbenennenRequest: api.RolleUmbenennenRequest, public optPayload: any = null) {}
    }
export class PostRolleUmbenennenFehlerAction implements Action {
    readonly type = POST_ROLLE_UMBENENNEN_FEHLER;
        constructor(public payload: any, public rolleUmbenennenRequest: api.RolleUmbenennenRequest, public optPayload: any = null) {}
    }
export class PostRolleUmbenennenNichtAusgefuehrtAction implements Action {
    readonly type = POST_ROLLE_UMBENENNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandRolleUmbenennenActions =
    PostRolleUmbenennenAction
    | PostRolleUmbenennenAusfuehrenAction
    | PostRolleUmbenennenErfolgreichAction
    | PostRolleUmbenennenFehlerAction
    | PostRolleUmbenennenNichtAusgefuehrtAction
    ;
