import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheRollenAdminAlle laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheRollenAdminAlle ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheRollenAdminAlle laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheRollenAdminAlle Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheRollenAdminAlle nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereicheRollenAdminAlleAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheRollenAdminAlleAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheRollenAdminAlleErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichRollen>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheRollenAdminAlleFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheRollenAdminAlleNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereicheRollenAdminAlleActions =
    GetVeranstaltungFunktionsbereicheRollenAdminAlleAction
    | GetVeranstaltungFunktionsbereicheRollenAdminAlleAusfuehrenAction
    | GetVeranstaltungFunktionsbereicheRollenAdminAlleErfolgreichAction
    | GetVeranstaltungFunktionsbereicheRollenAdminAlleFehlerAction
    | GetVeranstaltungFunktionsbereicheRollenAdminAlleNichtAusgefuehrtAction
    ;
