import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sopi-dockpanel',
  templateUrl: './sopi-dockpanel.component.html',
  styleUrls: ['./sopi-dockpanel.component.scss'],
})
export class SopiDockpanelComponent implements OnInit {

  @Input() footer = true;

  constructor() { }

  ngOnInit() {}

}
