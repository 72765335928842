import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {JsonEditorPage} from './json-editor.page';
import {SopiScrollModule} from '../../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {NgJsonEditorModule} from 'ang-jsoneditor';

const routes: Routes = [
    {
        path: '',
        component: JsonEditorPage,
        data: { title: 'JsonEditor' }
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SopiScrollModule,
        RouterModule.forChild(routes),
        NgJsonEditorModule
    ],
    declarations: [JsonEditorPage],
    entryComponents: [
        JsonEditorPage
    ]
})

export class JsonEditorPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: JsonEditorPageModule,
        };
    }
}
