import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN = '[VorlagenModul] PostDokumentBearbeitenBerechtigungHinzufuegen laden';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_AUSFUEHREN = '[VorlagenModul] PostDokumentBearbeitenBerechtigungHinzufuegen ausführen';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_ERFOLGREICH = '[VorlagenModul] PostDokumentBearbeitenBerechtigungHinzufuegen laden erfolgreich';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_FEHLER = '[VorlagenModul] PostDokumentBearbeitenBerechtigungHinzufuegen Ladefehler';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentBearbeitenBerechtigungHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostDokumentBearbeitenBerechtigungHinzufuegenAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN;
        constructor(public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_AUSFUEHREN;
        constructor(public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentBearbeitenBerechtigungHinzufuegenActions =
    PostDokumentBearbeitenBerechtigungHinzufuegenAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenAusfuehrenAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenErfolgreichAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenFehlerAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenNichtAusgefuehrtAction
    ;
