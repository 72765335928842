import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_AKTIVIEREN = '[VorlagenModul] PostChecklisteVeranstaltungsTypenAktivieren laden';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_AKTIVIEREN_AUSFUEHREN = '[VorlagenModul] PostChecklisteVeranstaltungsTypenAktivieren ausführen';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_AKTIVIEREN_ERFOLGREICH = '[VorlagenModul] PostChecklisteVeranstaltungsTypenAktivieren laden erfolgreich';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_AKTIVIEREN_FEHLER = '[VorlagenModul] PostChecklisteVeranstaltungsTypenAktivieren Ladefehler';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_AKTIVIEREN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostChecklisteVeranstaltungsTypenAktivieren nicht ausgeführt (evtl. Offline)';



export class PostChecklisteVeranstaltungsTypenAktivierenAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_AKTIVIEREN;
        constructor(public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenAktivierenAusfuehrenAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_AKTIVIEREN_AUSFUEHREN;
        constructor(public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenAktivierenErfolgreichAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_AKTIVIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenAktivierenFehlerAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_AKTIVIEREN_FEHLER;
        constructor(public payload: any, public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenAktivierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_AKTIVIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandChecklisteVeranstaltungsTypenAktivierenActions =
    PostChecklisteVeranstaltungsTypenAktivierenAction
    | PostChecklisteVeranstaltungsTypenAktivierenAusfuehrenAction
    | PostChecklisteVeranstaltungsTypenAktivierenErfolgreichAction
    | PostChecklisteVeranstaltungsTypenAktivierenFehlerAction
    | PostChecklisteVeranstaltungsTypenAktivierenNichtAusgefuehrtAction
    ;
