import * as api from '../../../clients/einlasskontrolle'; 

import {Action} from '@ngrx/store';

export const GET_CONNECTION_TEST = '[EinlasskontrolleModul] GetConnectionTest laden';
export const GET_CONNECTION_TEST_AUSFUEHREN = '[EinlasskontrolleModul] GetConnectionTest ausführen';
export const GET_CONNECTION_TEST_ERFOLGREICH = '[EinlasskontrolleModul] GetConnectionTest laden erfolgreich';
export const GET_CONNECTION_TEST_FEHLER = '[EinlasskontrolleModul] GetConnectionTest Ladefehler';
export const GET_CONNECTION_TEST_NICHT_AUSGEFUEHRT = '[EinlasskontrolleModul] GetConnectionTest nicht ausgeführt (evtl. Offline)';



export class GetConnectionTestAction implements Action {
    readonly type = GET_CONNECTION_TEST;
        constructor(public optPayload: any = null) {}
    }
export class GetConnectionTestAusfuehrenAction implements Action {
    readonly type = GET_CONNECTION_TEST_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetConnectionTestErfolgreichAction implements Action {
    readonly type = GET_CONNECTION_TEST_ERFOLGREICH;
        constructor(public payload: api.BoolResponse, public optPayload: any = null) {}
    }
export class GetConnectionTestFehlerAction implements Action {
    readonly type = GET_CONNECTION_TEST_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetConnectionTestNichtAusgefuehrtAction implements Action {
    readonly type = GET_CONNECTION_TEST_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type EinlasskontrolleQueryConnectionTestActions =
    GetConnectionTestAction
    | GetConnectionTestAusfuehrenAction
    | GetConnectionTestErfolgreichAction
    | GetConnectionTestFehlerAction
    | GetConnectionTestNichtAusgefuehrtAction
    ;
