import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENT_DETAIL = '[IncidentsModul] GetIncidentDetail laden';
export const GET_INCIDENT_DETAIL_AUSFUEHREN = '[IncidentsModul] GetIncidentDetail ausführen';
export const GET_INCIDENT_DETAIL_ERFOLGREICH = '[IncidentsModul] GetIncidentDetail laden erfolgreich';
export const GET_INCIDENT_DETAIL_FEHLER = '[IncidentsModul] GetIncidentDetail Ladefehler';
export const GET_INCIDENT_DETAIL_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentDetail nicht ausgeführt (evtl. Offline)';



export class GetIncidentDetailAction implements Action {
    readonly type = GET_INCIDENT_DETAIL;
        constructor( public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentDetailAusfuehrenAction implements Action {
    readonly type = GET_INCIDENT_DETAIL_AUSFUEHREN;
        constructor( public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentDetailErfolgreichAction implements Action {
    readonly type = GET_INCIDENT_DETAIL_ERFOLGREICH;
        constructor(public payload: api.IncidentDetail,  public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentDetailFehlerAction implements Action {
    readonly type = GET_INCIDENT_DETAIL_FEHLER;
        constructor(public payload: any,  public incident_id:string , public optPayload: any = null) {}
    }
export class GetIncidentDetailNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENT_DETAIL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentDetailActions =
    GetIncidentDetailAction
    | GetIncidentDetailAusfuehrenAction
    | GetIncidentDetailErfolgreichAction
    | GetIncidentDetailFehlerAction
    | GetIncidentDetailNichtAusgefuehrtAction
    ;
