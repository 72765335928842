import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_DOKUMENTE_ADMIN = '[VeranstaltungenModul] GetVeranstaltungDokumenteAdmin laden';
export const GET_VERANSTALTUNG_DOKUMENTE_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungDokumenteAdmin ausführen';
export const GET_VERANSTALTUNG_DOKUMENTE_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungDokumenteAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_DOKUMENTE_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungDokumenteAdmin Ladefehler';
export const GET_VERANSTALTUNG_DOKUMENTE_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungDokumenteAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungDokumenteAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENTE_ADMIN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumenteAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENTE_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumenteAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENTE_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.Dokument>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumenteAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENTE_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDokumenteAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_DOKUMENTE_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungDokumenteAdminActions =
    GetVeranstaltungDokumenteAdminAction
    | GetVeranstaltungDokumenteAdminAusfuehrenAction
    | GetVeranstaltungDokumenteAdminErfolgreichAction
    | GetVeranstaltungDokumenteAdminFehlerAction
    | GetVeranstaltungDokumenteAdminNichtAusgefuehrtAction
    ;
