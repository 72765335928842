import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_VORLAGEN_ENTITY_TYPES = '[VorlagenModul] GetVorlagenEntityTypes laden';
export const GET_VORLAGEN_ENTITY_TYPES_AUSFUEHREN = '[VorlagenModul] GetVorlagenEntityTypes ausführen';
export const GET_VORLAGEN_ENTITY_TYPES_ERFOLGREICH = '[VorlagenModul] GetVorlagenEntityTypes laden erfolgreich';
export const GET_VORLAGEN_ENTITY_TYPES_FEHLER = '[VorlagenModul] GetVorlagenEntityTypes Ladefehler';
export const GET_VORLAGEN_ENTITY_TYPES_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetVorlagenEntityTypes nicht ausgeführt (evtl. Offline)';



export class GetVorlagenEntityTypesAction implements Action {
    readonly type = GET_VORLAGEN_ENTITY_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetVorlagenEntityTypesAusfuehrenAction implements Action {
    readonly type = GET_VORLAGEN_ENTITY_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVorlagenEntityTypesErfolgreichAction implements Action {
    readonly type = GET_VORLAGEN_ENTITY_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.EntityTypeInfo>, public optPayload: any = null) {}
    }
export class GetVorlagenEntityTypesFehlerAction implements Action {
    readonly type = GET_VORLAGEN_ENTITY_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVorlagenEntityTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_VORLAGEN_ENTITY_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryVorlagenEntityTypesActions =
    GetVorlagenEntityTypesAction
    | GetVorlagenEntityTypesAusfuehrenAction
    | GetVorlagenEntityTypesErfolgreichAction
    | GetVorlagenEntityTypesFehlerAction
    | GetVorlagenEntityTypesNichtAusgefuehrtAction
    ;
