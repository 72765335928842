import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FUNKPROTOKOLL_MELDEART_ENTFERNEN = '[VeranstaltungenModul] PostFunkprotokollMeldeartEntfernen laden';
export const POST_FUNKPROTOKOLL_MELDEART_ENTFERNEN_AUSFUEHREN = '[VeranstaltungenModul] PostFunkprotokollMeldeartEntfernen ausführen';
export const POST_FUNKPROTOKOLL_MELDEART_ENTFERNEN_ERFOLGREICH = '[VeranstaltungenModul] PostFunkprotokollMeldeartEntfernen laden erfolgreich';
export const POST_FUNKPROTOKOLL_MELDEART_ENTFERNEN_FEHLER = '[VeranstaltungenModul] PostFunkprotokollMeldeartEntfernen Ladefehler';
export const POST_FUNKPROTOKOLL_MELDEART_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFunkprotokollMeldeartEntfernen nicht ausgeführt (evtl. Offline)';



export class PostFunkprotokollMeldeartEntfernenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_ENTFERNEN;
        constructor(public funkprotokollMeldeartRequest: api.FunkprotokollMeldeartRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollMeldeartEntfernenAusfuehrenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_ENTFERNEN_AUSFUEHREN;
        constructor(public funkprotokollMeldeartRequest: api.FunkprotokollMeldeartRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollMeldeartEntfernenErfolgreichAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funkprotokollMeldeartRequest: api.FunkprotokollMeldeartRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollMeldeartEntfernenFehlerAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_ENTFERNEN_FEHLER;
        constructor(public payload: any, public funkprotokollMeldeartRequest: api.FunkprotokollMeldeartRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollMeldeartEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFunkprotokollMeldeartEntfernenActions =
    PostFunkprotokollMeldeartEntfernenAction
    | PostFunkprotokollMeldeartEntfernenAusfuehrenAction
    | PostFunkprotokollMeldeartEntfernenErfolgreichAction
    | PostFunkprotokollMeldeartEntfernenFehlerAction
    | PostFunkprotokollMeldeartEntfernenNichtAusgefuehrtAction
    ;
