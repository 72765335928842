import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/Observable';
import {Action, select, Store} from '@ngrx/store';
import * as actionsUi from './displaysteuerung.actions.ui';
import {filter, flatMap, map, withLatestFrom} from 'rxjs/operators';
import {PageState} from './displaysteuerung.reducer';
import {GetBereichDetailAdminAction, GetBereicheListeAdminAction, PostBereichEntfernenAction, PostBereichKopfdatenAktualisierenAction, PostBereichRolleEntfernenAction, PostBereichRolleHinzufuegenMultiAction, PostBereichTriggerAktualisierenAction, PostBereichTriggerAusloesenAction, PostBereichTriggerEntfernenAction, PostBereichTriggerHinzufuegenAction} from '../../../client-ngrx/displaysteuerung';
import {AktuellenBereichGesetztAction} from './displaysteuerung.actions.ui';
import {getBereichDetail, getSelectedBereich, getSelectedRolle, getSelectedTrigger} from './displaysteuerung.selectors';
import {BereichKopfdatenRequest, BereichRequest, BereichRolleMultiRequest, BereichRolleRequest, BereichTriggerDatenRequest, BereichTriggerRequest} from '../../../clients/displaysteuerung';
import {UuidService} from '@orga-app/providers/uuid.service';

@Injectable()
export class DisplaysteuerungEffectsUi {

    @Effect()
    pageReset$: Observable<Action> = this.actions$.pipe(
        ofType(actionsUi.PAGE_STATE_RESET)
        , map((x) => {
            return new GetBereicheListeAdminAction();
        })
    );

    @Effect()
    bereicheListeLaden$: Observable<Action> = this.actions$.pipe(
        ofType(actionsUi.BEREICHE_LISTE_LADEN)
        , map((x) => {
            return new GetBereicheListeAdminAction();
        })
    );

    @Effect()
    bereichAusgewaehlt$: Observable<Action> = this.actions$.pipe(
        ofType(actionsUi.AKTUELLEN_BEREICH_GESETZT)
        , map((x: AktuellenBereichGesetztAction) => {
            return new GetBereichDetailAdminAction(x.payload.bereich_id);
        })
    );

    @Effect()
    bereichEntfernen$: Observable<Action> = this.actions$.pipe(
        ofType<actionsUi.AktuellenBereichGesetztAction>(actionsUi.BEREICH_ENTFERNEN)
        , withLatestFrom(this.store$.pipe(select(getSelectedBereich)))
        , filter(([x, bereich]) => bereich !== null)
        , map(([x, bereich]) => {
            return new PostBereichEntfernenAction(<BereichRequest> {
                bereich_id: bereich.bereich_id,
                zeitpunkt_utc: new Date()
            });
        })
    );

    @Effect()
    kopfdatenSpeichern$: Observable<Action> = this.actions$.pipe(
        ofType<actionsUi.BereichKopfdatenSpeichernAction>(actionsUi.BEREICH_KOPFDATEN_SPEICHERN)
        , withLatestFrom(this.store$.pipe(select(getBereichDetail)))
        , filter(([x, detail]) => detail !== null)
        , map(([x, detail]) => {
            let bereichId = detail.kopfdaten.bereich_id;
            if (bereichId === '') {
                bereichId = this.uuid.generateUUID();
            }
            const request: BereichKopfdatenRequest = {
                bereich_id: bereichId,
                beschreibung: detail.kopfdaten.beschreibung,
                externe_url: detail.kopfdaten.externe_url,
                ist_externe_url: detail.kopfdaten.ist_externe_url,
                ist_trigger_liste: detail.kopfdaten.ist_trigger_liste,
                reihenfolge: detail.kopfdaten.reihenfolge,
                titel: detail.kopfdaten.titel,
                zusatz_beschreibung: detail.kopfdaten.zusatz_beschreibung,
                zeitpunkt_utc: (new Date())
            };
            return new PostBereichKopfdatenAktualisierenAction(request);
        })
    );

    @Effect()
    rollenSetzen$: Observable<Action> = this.actions$.pipe(
        ofType<actionsUi.BereichBerechtigungRollenSetzenAction>(actionsUi.BEREICH_BERECHTIGUNG_ROLLEN_SETZEN)
        , withLatestFrom(this.store$.pipe(select(getBereichDetail)))
        , filter(([x, detail]) => detail !== null)
        , map(([x, detail]) => {
            const request: BereichRolleMultiRequest = {
                bereich_id: detail.kopfdaten.bereich_id,
                rolle_ids: x.payload,
                zeitpunkt_utc: new Date()
            };
            return new PostBereichRolleHinzufuegenMultiAction(request);
        })
    );

    @Effect()
    rolleEntfernen$: Observable<Action> = this.actions$.pipe(
        ofType<actionsUi.BereichBerechtigungRolleEntfernenAction>(actionsUi.BEREICH_BERECHTIGUNG_ROLLE_ENTFERNEN)
        , withLatestFrom(this.store$.pipe(select(getBereichDetail)),
            this.store$.pipe(select(getSelectedRolle)))
        , filter(([x, detail, rolle]) => detail !== null && rolle !== null)
        , map(([x, detail, rolle]) => {
            const request: BereichRolleRequest = {
                bereich_id: detail.kopfdaten.bereich_id,
                rolle_id: rolle.rolle_id,
                zeitpunkt_utc: new Date()
            };
            return new PostBereichRolleEntfernenAction(request);
        })
    );

    @Effect()
    aktuellenTriggerIcon$: Observable<Action> = this.actions$.pipe(
        ofType<actionsUi.AktuellenTriggerIconGesetztAction>(actionsUi.AKTUELLEN_TRIGGER_ICON_GESETZT)
        , withLatestFrom(this.store$.pipe(select(getSelectedTrigger)),
            this.store$.pipe(select(getBereichDetail)))
        , filter(([x, trigger, detail]) => trigger !== null && detail !== null)
        , map(([x, trigger, detail]) => {
            const request: BereichTriggerDatenRequest = {
                trigger_id: trigger.trigger_id,
                bereich_id: detail.kopfdaten.bereich_id,
                icon: x.payload,
                reihenfolge: trigger.reihenfolge,
                anzeige_name: trigger.anzeige_name,
                zeitpunkt_utc: new Date()
            };
            return new PostBereichTriggerAktualisierenAction(request);
        })
    );

    @Effect()
    aktuellenBereichTriggerHinzufuegen$: Observable<Action> = this.actions$.pipe(
        ofType<actionsUi.BereichTriggerHinzufuegenAction>(actionsUi.BEREICH_TRIGGER_HINZUFUEGEN)
        , withLatestFrom(this.store$.pipe(select(getBereichDetail)))
        , filter(([x, detail]) => detail !== null)
        , flatMap(([x, detail]) => {
            const actions: Array<Action> = [];
            let i = 0;
            x.payload.forEach(trigger => {
                actions.push(new PostBereichTriggerHinzufuegenAction(<BereichTriggerDatenRequest>{
                    trigger_id: trigger.id,
                    bereich_id: detail.kopfdaten.bereich_id,
                    icon: 'play',
                    anzeige_name: trigger.name,
                    reihenfolge: detail.trigger.length + i,
                    zeitpunkt_utc: new Date()
                }));
                i = 1 + i;
            });
            return actions;
        })
    );

    @Effect()
    aktuellenBereichTriggerEntfernen$: Observable<Action> = this.actions$.pipe(
        ofType<actionsUi.BereichTriggerEntfernenAction>(actionsUi.BEREICH_TRIGGER_ENTFERNEN)
        , withLatestFrom(this.store$.pipe(select(getBereichDetail)),
            this.store$.pipe(select(getSelectedTrigger)))
        , filter(([x, detail, trigger]) => detail !== null && trigger !== null)
        , map(([x, detail, trigger]) => {
            return new PostBereichTriggerEntfernenAction(<BereichTriggerRequest>{
                trigger_id: trigger.trigger_id,
                bereich_id: detail.kopfdaten.bereich_id,
                zeitpunkt_utc: new Date()
            });
        })
    );

    @Effect()
    aktuellenBereichTriggerAusloesen$: Observable<Action> = this.actions$.pipe(
        ofType<actionsUi.AktuellenTriggerAusloesenAction>(actionsUi.AKTUELLEN_TRIGGER_AUSLOESEN)
        , withLatestFrom(this.store$.pipe(select(getBereichDetail)),
            this.store$.pipe(select(getSelectedTrigger)))
        , filter(([x, detail, trigger]) => detail !== null && trigger !== null)
        , map(([x, detail, trigger]) => {
            return new PostBereichTriggerAusloesenAction(<BereichTriggerRequest>{
                trigger_id: trigger.trigger_id,
                bereich_id: detail.kopfdaten.bereich_id,
                zeitpunkt_utc: new Date()
            });
        })
    );

    constructor(private actions$: Actions,
                private uuid: UuidService,
                private store$: Store<PageState>) {
    }
}
