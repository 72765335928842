/**
 * orga app | veranstaltungen
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Funkprotokoll } from '../model/funkprotokoll';
import { FunkprotokollFall } from '../model/funkprotokollFall';
import { FunkprotokollReportModel } from '../model/funkprotokollReportModel';
import { Meldeart } from '../model/meldeart';
import { Veranstaltung } from '../model/veranstaltung';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FunkprotokollQueryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetFunkprotokoll (Auth)
     * ORGA App Zugriff: * Veranstaltunh: Funkprotokoll Benutzer 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunkprotokoll(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<Funkprotokoll>;
    public getFunkprotokoll(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Funkprotokoll>>;
    public getFunkprotokoll(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Funkprotokoll>>;
    public getFunkprotokoll(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getFunkprotokoll.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Funkprotokoll>('get',`${this.basePath}/api/query/veranstaltungen/funkprotokoll/${encodeURIComponent(String(veranstaltung_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunkprotokollAktuelleVeranstaltungen (Auth)
     * ORGA App Zugriff: * Veranstaltunh: Funkprotokoll Benutzer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunkprotokollAktuelleVeranstaltungen(observe?: 'body', reportProgress?: boolean): Observable<Array<Veranstaltung>>;
    public getFunkprotokollAktuelleVeranstaltungen(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Veranstaltung>>>;
    public getFunkprotokollAktuelleVeranstaltungen(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Veranstaltung>>>;
    public getFunkprotokollAktuelleVeranstaltungen(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Veranstaltung>>('get',`${this.basePath}/api/query/veranstaltungen/funkprotokoll/veranstaltungen/aktuelle`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunkprotokollBeendeteVeranstaltungen (Auth)
     * ORGA App Zugriff: * Veranstaltunh: Funkprotokoll Benutzer 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunkprotokollBeendeteVeranstaltungen(observe?: 'body', reportProgress?: boolean): Observable<Array<Veranstaltung>>;
    public getFunkprotokollBeendeteVeranstaltungen(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Veranstaltung>>>;
    public getFunkprotokollBeendeteVeranstaltungen(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Veranstaltung>>>;
    public getFunkprotokollBeendeteVeranstaltungen(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Veranstaltung>>('get',`${this.basePath}/api/query/veranstaltungen/funkprotokoll/veranstaltungen/beendete`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunkprotokollExcel (Auth)
     * ORGA App Zugriff: * Veranstaltunh: Funkprotokoll Benutzer 
     * @param veranstaltung_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunkprotokollExcel(veranstaltung_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getFunkprotokollExcel(veranstaltung_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getFunkprotokollExcel(veranstaltung_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getFunkprotokollExcel(veranstaltung_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getFunkprotokollExcel.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/funkprotokoll/${encodeURIComponent(String(veranstaltung_id))}/excel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunkprotokollFall (Auth)
     * ORGA App Zugriff: * Veranstaltunh: Funkprotokoll Benutzer 
     * @param veranstaltung_id 
     * @param fall_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunkprotokollFall(veranstaltung_id: string, fall_id: string, observe?: 'body', reportProgress?: boolean): Observable<FunkprotokollFall>;
    public getFunkprotokollFall(veranstaltung_id: string, fall_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FunkprotokollFall>>;
    public getFunkprotokollFall(veranstaltung_id: string, fall_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FunkprotokollFall>>;
    public getFunkprotokollFall(veranstaltung_id: string, fall_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getFunkprotokollFall.');
        }

        if (fall_id === null || fall_id === undefined) {
            throw new Error('Required parameter fall_id was null or undefined when calling getFunkprotokollFall.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FunkprotokollFall>('get',`${this.basePath}/api/query/veranstaltungen/funkprotokoll/${encodeURIComponent(String(veranstaltung_id))}/${encodeURIComponent(String(fall_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunkprotokollMeldeartDetail (Auth)
     * 
     * @param meldeart_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunkprotokollMeldeartDetail(meldeart_id: string, observe?: 'body', reportProgress?: boolean): Observable<Meldeart>;
    public getFunkprotokollMeldeartDetail(meldeart_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Meldeart>>;
    public getFunkprotokollMeldeartDetail(meldeart_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Meldeart>>;
    public getFunkprotokollMeldeartDetail(meldeart_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meldeart_id === null || meldeart_id === undefined) {
            throw new Error('Required parameter meldeart_id was null or undefined when calling getFunkprotokollMeldeartDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Meldeart>('get',`${this.basePath}/api/query/veranstaltungen/funkprotokoll/meldearten/${encodeURIComponent(String(meldeart_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunkprotokollMeldeartListe (Auth)
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunkprotokollMeldeartListe(observe?: 'body', reportProgress?: boolean): Observable<Array<Meldeart>>;
    public getFunkprotokollMeldeartListe(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Meldeart>>>;
    public getFunkprotokollMeldeartListe(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Meldeart>>>;
    public getFunkprotokollMeldeartListe(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Meldeart>>('get',`${this.basePath}/api/query/veranstaltungen/funkprotokoll/meldearten`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunkprotokollReportData (Auth)
     * ORGA App Zugriff: * Veranstaltunh: Funkprotokoll Benutzer 
     * @param veranstaltung_id 
     * @param fall_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunkprotokollReportData(veranstaltung_id: string, fall_id?: string, observe?: 'body', reportProgress?: boolean): Observable<FunkprotokollReportModel>;
    public getFunkprotokollReportData(veranstaltung_id: string, fall_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FunkprotokollReportModel>>;
    public getFunkprotokollReportData(veranstaltung_id: string, fall_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FunkprotokollReportModel>>;
    public getFunkprotokollReportData(veranstaltung_id: string, fall_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getFunkprotokollReportData.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fall_id !== undefined && fall_id !== null) {
            queryParameters = queryParameters.set('fall_id', <any>fall_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FunkprotokollReportModel>('get',`${this.basePath}/api/query/veranstaltungen/funkprotokoll/${encodeURIComponent(String(veranstaltung_id))}/reportdata`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunkprotokollReportPdf (Auth)
     * ORGA App Zugriff: * Veranstaltunh: Funkprotokoll Benutzer 
     * @param veranstaltung_id 
     * @param reportVersion 
     * @param fall_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunkprotokollReportPdf(veranstaltung_id: string, reportVersion?: string, fall_id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getFunkprotokollReportPdf(veranstaltung_id: string, reportVersion?: string, fall_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getFunkprotokollReportPdf(veranstaltung_id: string, reportVersion?: string, fall_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getFunkprotokollReportPdf(veranstaltung_id: string, reportVersion?: string, fall_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getFunkprotokollReportPdf.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reportVersion !== undefined && reportVersion !== null) {
            queryParameters = queryParameters.set('reportVersion', <any>reportVersion);
        }
        if (fall_id !== undefined && fall_id !== null) {
            queryParameters = queryParameters.set('fall_id', <any>fall_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/funkprotokoll/${encodeURIComponent(String(veranstaltung_id))}/pdf`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunkprotokollReportXml (Auth)
     * ORGA App Zugriff: * Veranstaltunh: Funkprotokoll Benutzer 
     * @param veranstaltung_id 
     * @param fall_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunkprotokollReportXml(veranstaltung_id: string, fall_id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getFunkprotokollReportXml(veranstaltung_id: string, fall_id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getFunkprotokollReportXml(veranstaltung_id: string, fall_id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getFunkprotokollReportXml(veranstaltung_id: string, fall_id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (veranstaltung_id === null || veranstaltung_id === undefined) {
            throw new Error('Required parameter veranstaltung_id was null or undefined when calling getFunkprotokollReportXml.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fall_id !== undefined && fall_id !== null) {
            queryParameters = queryParameters.set('fall_id', <any>fall_id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/funkprotokoll/${encodeURIComponent(String(veranstaltung_id))}/reportxml`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunkprotokollXmlTemplate
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunkprotokollXmlTemplate(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getFunkprotokollXmlTemplate(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getFunkprotokollXmlTemplate(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getFunkprotokollXmlTemplate(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/funkprotokoll/xml`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunkprotokollXsdTemplate
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunkprotokollXsdTemplate(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getFunkprotokollXsdTemplate(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getFunkprotokollXsdTemplate(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getFunkprotokollXsdTemplate(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/funkprotokoll/schema`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
