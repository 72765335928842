import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNGS_TYP_FELD_AKTUALISIEREN = '[VeranstaltungstypenModul] PostVeranstaltungsTypFeldAktualisieren laden';
export const POST_VERANSTALTUNGS_TYP_FELD_AKTUALISIEREN_AUSFUEHREN = '[VeranstaltungstypenModul] PostVeranstaltungsTypFeldAktualisieren ausführen';
export const POST_VERANSTALTUNGS_TYP_FELD_AKTUALISIEREN_ERFOLGREICH = '[VeranstaltungstypenModul] PostVeranstaltungsTypFeldAktualisieren laden erfolgreich';
export const POST_VERANSTALTUNGS_TYP_FELD_AKTUALISIEREN_FEHLER = '[VeranstaltungstypenModul] PostVeranstaltungsTypFeldAktualisieren Ladefehler';
export const POST_VERANSTALTUNGS_TYP_FELD_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] PostVeranstaltungsTypFeldAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungsTypFeldAktualisierenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELD_AKTUALISIEREN;
        constructor(public veranstaltungsTypFeldRequest: api.VeranstaltungsTypFeldRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypFeldAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELD_AKTUALISIEREN_AUSFUEHREN;
        constructor(public veranstaltungsTypFeldRequest: api.VeranstaltungsTypFeldRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypFeldAktualisierenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELD_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungsTypFeldRequest: api.VeranstaltungsTypFeldRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypFeldAktualisierenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELD_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public veranstaltungsTypFeldRequest: api.VeranstaltungsTypFeldRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypFeldAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_FELD_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenCommandVeranstaltungsTypFeldAktualisierenActions =
    PostVeranstaltungsTypFeldAktualisierenAction
    | PostVeranstaltungsTypFeldAktualisierenAusfuehrenAction
    | PostVeranstaltungsTypFeldAktualisierenErfolgreichAction
    | PostVeranstaltungsTypFeldAktualisierenFehlerAction
    | PostVeranstaltungsTypFeldAktualisierenNichtAusgefuehrtAction
    ;
