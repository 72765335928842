

import {Action} from '@ngrx/store';

export const GET_DOKUMENT_DOWNLOAD = '[VorlagenModul] GetDokumentDownload laden';
export const GET_DOKUMENT_DOWNLOAD_AUSFUEHREN = '[VorlagenModul] GetDokumentDownload ausführen';
export const GET_DOKUMENT_DOWNLOAD_ERFOLGREICH = '[VorlagenModul] GetDokumentDownload laden erfolgreich';
export const GET_DOKUMENT_DOWNLOAD_FEHLER = '[VorlagenModul] GetDokumentDownload Ladefehler';
export const GET_DOKUMENT_DOWNLOAD_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetDokumentDownload nicht ausgeführt (evtl. Offline)';



export class GetDokumentDownloadAction implements Action {
    readonly type = GET_DOKUMENT_DOWNLOAD;
        constructor( public dokument_id:string , public optPayload: any = null) {}
    }
export class GetDokumentDownloadAusfuehrenAction implements Action {
    readonly type = GET_DOKUMENT_DOWNLOAD_AUSFUEHREN;
        constructor( public dokument_id:string , public optPayload: any = null) {}
    }
export class GetDokumentDownloadErfolgreichAction implements Action {
    readonly type = GET_DOKUMENT_DOWNLOAD_ERFOLGREICH;
        constructor( public dokument_id:string , public optPayload: any = null) {}
    }
export class GetDokumentDownloadFehlerAction implements Action {
    readonly type = GET_DOKUMENT_DOWNLOAD_FEHLER;
        constructor(public payload: any,  public dokument_id:string , public optPayload: any = null) {}
    }
export class GetDokumentDownloadNichtAusgefuehrtAction implements Action {
    readonly type = GET_DOKUMENT_DOWNLOAD_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryDokumentDownloadActions =
    GetDokumentDownloadAction
    | GetDokumentDownloadAusfuehrenAction
    | GetDokumentDownloadErfolgreichAction
    | GetDokumentDownloadFehlerAction
    | GetDokumentDownloadNichtAusgefuehrtAction
    ;
