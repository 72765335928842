import {Action} from '@ngrx/store';
import {MandantInfo} from '@orga-app/clients/api';

export const MANDANT_SETZEN = '[MandantModule] Mandant setzen';
// export const MANDANT_SETZEN_ERFOLGREICH = '[MandantModule] Mandant setzen erfolgreich';
// export const MANDANT_SETZEN_FEHLER = '[MandantModule] Mandant setzen fehler';

export const MANDANT_AUSGEWAEHLT_UI = '[MandantModule] Mandant in UI ausgewaehlt';


export class MandantSetzenAction implements Action {
    readonly type = MANDANT_SETZEN;

    constructor(public mandant: MandantInfo) {
    }
}

// export class MandantSetzenErfolgreichAction implements Action {
//     readonly type = MANDANT_SETZEN_ERFOLGREICH;

//     constructor(public mandant: MandantInfo) {}
// }

// export class MandantSetzenFehlerAction implements Action {
//     readonly type = MANDANT_SETZEN_FEHLER;

//     constructor() {}
// }


export class MandantAusgewaehltUiAction implements Action {
    readonly type = MANDANT_AUSGEWAEHLT_UI;

    constructor(public mandant: MandantInfo) {}
}


export type Actions =
    MandantSetzenAction
    // | MandantSetzenErfolgreichAction
    // | MandantSetzenFehlerAction  
    | MandantAusgewaehltUiAction
    ;
