import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_HINZUFUEGEN = '[VorlagenModul] PostAufgabenBenutzerBerechtigungenHinzufuegen laden';
export const POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_HINZUFUEGEN_AUSFUEHREN = '[VorlagenModul] PostAufgabenBenutzerBerechtigungenHinzufuegen ausführen';
export const POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_HINZUFUEGEN_ERFOLGREICH = '[VorlagenModul] PostAufgabenBenutzerBerechtigungenHinzufuegen laden erfolgreich';
export const POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_HINZUFUEGEN_FEHLER = '[VorlagenModul] PostAufgabenBenutzerBerechtigungenHinzufuegen Ladefehler';
export const POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostAufgabenBenutzerBerechtigungenHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostAufgabenBenutzerBerechtigungenHinzufuegenAction implements Action {
    readonly type = POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_HINZUFUEGEN;
        constructor(public aufgabenBenutzerIdsRequest: api.AufgabenBenutzerIdsRequest, public optPayload: any = null) {}
    }
export class PostAufgabenBenutzerBerechtigungenHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_HINZUFUEGEN_AUSFUEHREN;
        constructor(public aufgabenBenutzerIdsRequest: api.AufgabenBenutzerIdsRequest, public optPayload: any = null) {}
    }
export class PostAufgabenBenutzerBerechtigungenHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabenBenutzerIdsRequest: api.AufgabenBenutzerIdsRequest, public optPayload: any = null) {}
    }
export class PostAufgabenBenutzerBerechtigungenHinzufuegenFehlerAction implements Action {
    readonly type = POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public aufgabenBenutzerIdsRequest: api.AufgabenBenutzerIdsRequest, public optPayload: any = null) {}
    }
export class PostAufgabenBenutzerBerechtigungenHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABEN_BENUTZER_BERECHTIGUNGEN_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandAufgabenBenutzerBerechtigungenHinzufuegenActions =
    PostAufgabenBenutzerBerechtigungenHinzufuegenAction
    | PostAufgabenBenutzerBerechtigungenHinzufuegenAusfuehrenAction
    | PostAufgabenBenutzerBerechtigungenHinzufuegenErfolgreichAction
    | PostAufgabenBenutzerBerechtigungenHinzufuegenFehlerAction
    | PostAufgabenBenutzerBerechtigungenHinzufuegenNichtAusgefuehrtAction
    ;
