

import {Action} from '@ngrx/store';

export const GET_MANDANT_CONFIG = '[ApiModul] GetMandantConfig laden';
export const GET_MANDANT_CONFIG_AUSFUEHREN = '[ApiModul] GetMandantConfig ausführen';
export const GET_MANDANT_CONFIG_ERFOLGREICH = '[ApiModul] GetMandantConfig laden erfolgreich';
export const GET_MANDANT_CONFIG_FEHLER = '[ApiModul] GetMandantConfig Ladefehler';
export const GET_MANDANT_CONFIG_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantConfig nicht ausgeführt (evtl. Offline)';



export class GetMandantConfigAction implements Action {
    readonly type = GET_MANDANT_CONFIG;
        constructor( public keys:string , public optPayload: any = null) {}
    }
export class GetMandantConfigAusfuehrenAction implements Action {
    readonly type = GET_MANDANT_CONFIG_AUSFUEHREN;
        constructor( public keys:string , public optPayload: any = null) {}
    }
export class GetMandantConfigErfolgreichAction implements Action {
    readonly type = GET_MANDANT_CONFIG_ERFOLGREICH;
        constructor(public payload: any,  public keys:string , public optPayload: any = null) {}
    }
export class GetMandantConfigFehlerAction implements Action {
    readonly type = GET_MANDANT_CONFIG_FEHLER;
        constructor(public payload: any,  public keys:string , public optPayload: any = null) {}
    }
export class GetMandantConfigNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANT_CONFIG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantConfigActions =
    GetMandantConfigAction
    | GetMandantConfigAusfuehrenAction
    | GetMandantConfigErfolgreichAction
    | GetMandantConfigFehlerAction
    | GetMandantConfigNichtAusgefuehrtAction
    ;
