import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const POST_BEREICH_TRIGGER_HINZUFUEGEN = '[DisplaysteuerungModul] PostBereichTriggerHinzufuegen laden';
export const POST_BEREICH_TRIGGER_HINZUFUEGEN_AUSFUEHREN = '[DisplaysteuerungModul] PostBereichTriggerHinzufuegen ausführen';
export const POST_BEREICH_TRIGGER_HINZUFUEGEN_ERFOLGREICH = '[DisplaysteuerungModul] PostBereichTriggerHinzufuegen laden erfolgreich';
export const POST_BEREICH_TRIGGER_HINZUFUEGEN_FEHLER = '[DisplaysteuerungModul] PostBereichTriggerHinzufuegen Ladefehler';
export const POST_BEREICH_TRIGGER_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] PostBereichTriggerHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostBereichTriggerHinzufuegenAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_HINZUFUEGEN;
        constructor(public bereichTriggerDatenRequest: api.BereichTriggerDatenRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_HINZUFUEGEN_AUSFUEHREN;
        constructor(public bereichTriggerDatenRequest: api.BereichTriggerDatenRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public bereichTriggerDatenRequest: api.BereichTriggerDatenRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerHinzufuegenFehlerAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public bereichTriggerDatenRequest: api.BereichTriggerDatenRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungCommandBereichTriggerHinzufuegenActions =
    PostBereichTriggerHinzufuegenAction
    | PostBereichTriggerHinzufuegenAusfuehrenAction
    | PostBereichTriggerHinzufuegenErfolgreichAction
    | PostBereichTriggerHinzufuegenFehlerAction
    | PostBereichTriggerHinzufuegenNichtAusgefuehrtAction
    ;
