import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.command.bereich-trigger-ausloesen';
import * as api from '../../../clients/displaysteuerung';


    @Injectable()
    export class DisplaysteuerungBereichTriggerAusloesenEffects {

        @Effect()
            BereichTriggerAusloesen$ = this.actions$.pipe(
                ofType(ac.POST_BEREICH_TRIGGER_AUSLOESEN),
                flatMap((x: ac.PostBereichTriggerAusloesenAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().postBereichTriggerAusloesen(x.bereichTriggerRequest).pipe(
                    map((result: any) => {
                        const nextAction = new ac.PostBereichTriggerAusloesenErfolgreichAction(result, x.bereichTriggerRequest,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.PostBereichTriggerAusloesenFehlerAction(error, x.bereichTriggerRequest,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.DisplaysteuerungCommandService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.DisplaysteuerungCommandService {
                if (this.service === null) {
                    this.service = this.injector.get(api.DisplaysteuerungCommandService);
                }
                return this.service;
            }
        
}
