import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_HINZUFUEGEN = '[VorlagenModul] PostDokumentHinzufuegen laden';
export const POST_DOKUMENT_HINZUFUEGEN_AUSFUEHREN = '[VorlagenModul] PostDokumentHinzufuegen ausführen';
export const POST_DOKUMENT_HINZUFUEGEN_ERFOLGREICH = '[VorlagenModul] PostDokumentHinzufuegen laden erfolgreich';
export const POST_DOKUMENT_HINZUFUEGEN_FEHLER = '[VorlagenModul] PostDokumentHinzufuegen Ladefehler';
export const POST_DOKUMENT_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostDokumentHinzufuegenAction implements Action {
    readonly type = POST_DOKUMENT_HINZUFUEGEN;
        constructor(public dokumentHinzufuegenRequest: api.DokumentHinzufuegenRequest, public optPayload: any = null) {}
    }
export class PostDokumentHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_HINZUFUEGEN_AUSFUEHREN;
        constructor(public dokumentHinzufuegenRequest: api.DokumentHinzufuegenRequest, public optPayload: any = null) {}
    }
export class PostDokumentHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentHinzufuegenRequest: api.DokumentHinzufuegenRequest, public optPayload: any = null) {}
    }
export class PostDokumentHinzufuegenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public dokumentHinzufuegenRequest: api.DokumentHinzufuegenRequest, public optPayload: any = null) {}
    }
export class PostDokumentHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentHinzufuegenActions =
    PostDokumentHinzufuegenAction
    | PostDokumentHinzufuegenAusfuehrenAction
    | PostDokumentHinzufuegenErfolgreichAction
    | PostDokumentHinzufuegenFehlerAction
    | PostDokumentHinzufuegenNichtAusgefuehrtAction
    ;
