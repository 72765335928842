import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_KOMMUNIKATION_ENTITY_TYPES = '[KommunikationModul] GetKommunikationEntityTypes laden';
export const GET_KOMMUNIKATION_ENTITY_TYPES_AUSFUEHREN = '[KommunikationModul] GetKommunikationEntityTypes ausführen';
export const GET_KOMMUNIKATION_ENTITY_TYPES_ERFOLGREICH = '[KommunikationModul] GetKommunikationEntityTypes laden erfolgreich';
export const GET_KOMMUNIKATION_ENTITY_TYPES_FEHLER = '[KommunikationModul] GetKommunikationEntityTypes Ladefehler';
export const GET_KOMMUNIKATION_ENTITY_TYPES_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetKommunikationEntityTypes nicht ausgeführt (evtl. Offline)';



export class GetKommunikationEntityTypesAction implements Action {
    readonly type = GET_KOMMUNIKATION_ENTITY_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetKommunikationEntityTypesAusfuehrenAction implements Action {
    readonly type = GET_KOMMUNIKATION_ENTITY_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetKommunikationEntityTypesErfolgreichAction implements Action {
    readonly type = GET_KOMMUNIKATION_ENTITY_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.EntityTypeInfo>, public optPayload: any = null) {}
    }
export class GetKommunikationEntityTypesFehlerAction implements Action {
    readonly type = GET_KOMMUNIKATION_ENTITY_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetKommunikationEntityTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_KOMMUNIKATION_ENTITY_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryKommunikationEntityTypesActions =
    GetKommunikationEntityTypesAction
    | GetKommunikationEntityTypesAusfuehrenAction
    | GetKommunikationEntityTypesErfolgreichAction
    | GetKommunikationEntityTypesFehlerAction
    | GetKommunikationEntityTypesNichtAusgefuehrtAction
    ;
