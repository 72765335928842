import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_ADMIN_HINZUFUEGEN = '[BenutzerverwaltungModul] PostFunktionsbereichAdminHinzufuegen laden';
export const POST_FUNKTIONSBEREICH_ADMIN_HINZUFUEGEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostFunktionsbereichAdminHinzufuegen ausführen';
export const POST_FUNKTIONSBEREICH_ADMIN_HINZUFUEGEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostFunktionsbereichAdminHinzufuegen laden erfolgreich';
export const POST_FUNKTIONSBEREICH_ADMIN_HINZUFUEGEN_FEHLER = '[BenutzerverwaltungModul] PostFunktionsbereichAdminHinzufuegen Ladefehler';
export const POST_FUNKTIONSBEREICH_ADMIN_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostFunktionsbereichAdminHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichAdminHinzufuegenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ADMIN_HINZUFUEGEN;
        constructor(public funktionsbereichBenutzerRequest: api.FunktionsbereichBenutzerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAdminHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ADMIN_HINZUFUEGEN_AUSFUEHREN;
        constructor(public funktionsbereichBenutzerRequest: api.FunktionsbereichBenutzerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAdminHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ADMIN_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funktionsbereichBenutzerRequest: api.FunktionsbereichBenutzerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAdminHinzufuegenFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ADMIN_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public funktionsbereichBenutzerRequest: api.FunktionsbereichBenutzerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAdminHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ADMIN_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandFunktionsbereichAdminHinzufuegenActions =
    PostFunktionsbereichAdminHinzufuegenAction
    | PostFunktionsbereichAdminHinzufuegenAusfuehrenAction
    | PostFunktionsbereichAdminHinzufuegenErfolgreichAction
    | PostFunktionsbereichAdminHinzufuegenFehlerAction
    | PostFunktionsbereichAdminHinzufuegenNichtAusgefuehrtAction
    ;
