import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_CHECKLISTE_HINZUFUEGEN_MULTI = '[VeranstaltungenModul] PostChecklisteHinzufuegenMulti laden';
export const POST_CHECKLISTE_HINZUFUEGEN_MULTI_AUSFUEHREN = '[VeranstaltungenModul] PostChecklisteHinzufuegenMulti ausführen';
export const POST_CHECKLISTE_HINZUFUEGEN_MULTI_ERFOLGREICH = '[VeranstaltungenModul] PostChecklisteHinzufuegenMulti laden erfolgreich';
export const POST_CHECKLISTE_HINZUFUEGEN_MULTI_FEHLER = '[VeranstaltungenModul] PostChecklisteHinzufuegenMulti Ladefehler';
export const POST_CHECKLISTE_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostChecklisteHinzufuegenMulti nicht ausgeführt (evtl. Offline)';



export class PostChecklisteHinzufuegenMultiAction implements Action {
    readonly type = POST_CHECKLISTE_HINZUFUEGEN_MULTI;
        constructor(public checklisteHinzufuegenMultiRequest: api.ChecklisteHinzufuegenMultiRequest, public optPayload: any = null) {}
    }
export class PostChecklisteHinzufuegenMultiAusfuehrenAction implements Action {
    readonly type = POST_CHECKLISTE_HINZUFUEGEN_MULTI_AUSFUEHREN;
        constructor(public checklisteHinzufuegenMultiRequest: api.ChecklisteHinzufuegenMultiRequest, public optPayload: any = null) {}
    }
export class PostChecklisteHinzufuegenMultiErfolgreichAction implements Action {
    readonly type = POST_CHECKLISTE_HINZUFUEGEN_MULTI_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public checklisteHinzufuegenMultiRequest: api.ChecklisteHinzufuegenMultiRequest, public optPayload: any = null) {}
    }
export class PostChecklisteHinzufuegenMultiFehlerAction implements Action {
    readonly type = POST_CHECKLISTE_HINZUFUEGEN_MULTI_FEHLER;
        constructor(public payload: any, public checklisteHinzufuegenMultiRequest: api.ChecklisteHinzufuegenMultiRequest, public optPayload: any = null) {}
    }
export class PostChecklisteHinzufuegenMultiNichtAusgefuehrtAction implements Action {
    readonly type = POST_CHECKLISTE_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandChecklisteHinzufuegenMultiActions =
    PostChecklisteHinzufuegenMultiAction
    | PostChecklisteHinzufuegenMultiAusfuehrenAction
    | PostChecklisteHinzufuegenMultiErfolgreichAction
    | PostChecklisteHinzufuegenMultiFehlerAction
    | PostChecklisteHinzufuegenMultiNichtAusgefuehrtAction
    ;
