/**
 * orga app | kommunikation
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ConstantTypeInfo } from '../model/constantTypeInfo';
import { EntityTypeInfo } from '../model/entityTypeInfo';
import { MitteilungIdentifier } from '../model/mitteilungIdentifier';
import { MitteilungNotification } from '../model/mitteilungNotification';
import { NotificationMessageTypeInfo } from '../model/notificationMessageTypeInfo';
import { SmtpMandantOptions } from '../model/smtpMandantOptions';
import { TroubleTicketMandantOptions } from '../model/troubleTicketMandantOptions';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class KommunikationTemplatesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetKommunikationConfigTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKommunikationConfigTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<ConstantTypeInfo>>;
    public getKommunikationConfigTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ConstantTypeInfo>>>;
    public getKommunikationConfigTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ConstantTypeInfo>>>;
    public getKommunikationConfigTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ConstantTypeInfo>>('get',`${this.basePath}/api/kommunikation/templates/configtypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetKommunikationConstants
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKommunikationConstants(observe?: 'body', reportProgress?: boolean): Observable<Array<ConstantTypeInfo>>;
    public getKommunikationConstants(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ConstantTypeInfo>>>;
    public getKommunikationConstants(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ConstantTypeInfo>>>;
    public getKommunikationConstants(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ConstantTypeInfo>>('get',`${this.basePath}/api/kommunikation/templates/constants`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetKommunikationEntityTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKommunikationEntityTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<EntityTypeInfo>>;
    public getKommunikationEntityTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EntityTypeInfo>>>;
    public getKommunikationEntityTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EntityTypeInfo>>>;
    public getKommunikationEntityTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<EntityTypeInfo>>('get',`${this.basePath}/api/kommunikation/templates/entitytypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMitteilungIdentifier
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMitteilungIdentifier(observe?: 'body', reportProgress?: boolean): Observable<MitteilungIdentifier>;
    public getMitteilungIdentifier(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MitteilungIdentifier>>;
    public getMitteilungIdentifier(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MitteilungIdentifier>>;
    public getMitteilungIdentifier(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MitteilungIdentifier>('get',`${this.basePath}/api/kommunikation/templates/identifier/mitteilung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetMitteilungNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMitteilungNotification(observe?: 'body', reportProgress?: boolean): Observable<MitteilungNotification>;
    public getMitteilungNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MitteilungNotification>>;
    public getMitteilungNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MitteilungNotification>>;
    public getMitteilungNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MitteilungNotification>('get',`${this.basePath}/api/kommunikation/templates/notifications/mitteilung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetNotificationTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNotificationTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<NotificationMessageTypeInfo>>;
    public getNotificationTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotificationMessageTypeInfo>>>;
    public getNotificationTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotificationMessageTypeInfo>>>;
    public getNotificationTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<NotificationMessageTypeInfo>>('get',`${this.basePath}/api/kommunikation/templates/notificationtypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetSmtpMandantOptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSmtpMandantOptions(observe?: 'body', reportProgress?: boolean): Observable<SmtpMandantOptions>;
    public getSmtpMandantOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SmtpMandantOptions>>;
    public getSmtpMandantOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SmtpMandantOptions>>;
    public getSmtpMandantOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<SmtpMandantOptions>('get',`${this.basePath}/api/kommunikation/templates/config/smtp`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetTroubleTicketMandantOptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTroubleTicketMandantOptions(observe?: 'body', reportProgress?: boolean): Observable<TroubleTicketMandantOptions>;
    public getTroubleTicketMandantOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TroubleTicketMandantOptions>>;
    public getTroubleTicketMandantOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TroubleTicketMandantOptions>>;
    public getTroubleTicketMandantOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TroubleTicketMandantOptions>('get',`${this.basePath}/api/kommunikation/templates/config/troubleticket`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
