

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_PRUEFBERICHT_XML = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXml laden';
export const GET_VERANSTALTUNG_PRUEFBERICHT_XML_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXml ausführen';
export const GET_VERANSTALTUNG_PRUEFBERICHT_XML_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXml laden erfolgreich';
export const GET_VERANSTALTUNG_PRUEFBERICHT_XML_FEHLER = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXml Ladefehler';
export const GET_VERANSTALTUNG_PRUEFBERICHT_XML_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungPruefberichtXml nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungPruefberichtXmlAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XML;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtXmlAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XML_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtXmlErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XML_ERFOLGREICH;
        constructor(public payload: string,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtXmlFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XML_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefberichtXmlNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFBERICHT_XML_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungPruefberichtXmlActions =
    GetVeranstaltungPruefberichtXmlAction
    | GetVeranstaltungPruefberichtXmlAusfuehrenAction
    | GetVeranstaltungPruefberichtXmlErfolgreichAction
    | GetVeranstaltungPruefberichtXmlFehlerAction
    | GetVeranstaltungPruefberichtXmlNichtAusgefuehrtAction
    ;
