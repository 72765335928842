import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_AUFGABE_DETAIL = '[VeranstaltungenModul] GetAufgabeDetail laden';
export const GET_AUFGABE_DETAIL_AUSFUEHREN = '[VeranstaltungenModul] GetAufgabeDetail ausführen';
export const GET_AUFGABE_DETAIL_ERFOLGREICH = '[VeranstaltungenModul] GetAufgabeDetail laden erfolgreich';
export const GET_AUFGABE_DETAIL_FEHLER = '[VeranstaltungenModul] GetAufgabeDetail Ladefehler';
export const GET_AUFGABE_DETAIL_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetAufgabeDetail nicht ausgeführt (evtl. Offline)';



export class GetAufgabeDetailAction implements Action {
    readonly type = GET_AUFGABE_DETAIL;
        constructor( public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeDetailAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_DETAIL_AUSFUEHREN;
        constructor( public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeDetailErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_DETAIL_ERFOLGREICH;
        constructor(public payload: api.AufgabeDetail,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeDetailFehlerAction implements Action {
    readonly type = GET_AUFGABE_DETAIL_FEHLER;
        constructor(public payload: any,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeDetailNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_DETAIL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryAufgabeDetailActions =
    GetAufgabeDetailAction
    | GetAufgabeDetailAusfuehrenAction
    | GetAufgabeDetailErfolgreichAction
    | GetAufgabeDetailFehlerAction
    | GetAufgabeDetailNichtAusgefuehrtAction
    ;
