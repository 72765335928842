import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFLAUF_BILD_ENTFERNEN = '[QschecksModul] PostPrueflaufBildEntfernen laden';
export const POST_PRUEFLAUF_BILD_ENTFERNEN_AUSFUEHREN = '[QschecksModul] PostPrueflaufBildEntfernen ausführen';
export const POST_PRUEFLAUF_BILD_ENTFERNEN_ERFOLGREICH = '[QschecksModul] PostPrueflaufBildEntfernen laden erfolgreich';
export const POST_PRUEFLAUF_BILD_ENTFERNEN_FEHLER = '[QschecksModul] PostPrueflaufBildEntfernen Ladefehler';
export const POST_PRUEFLAUF_BILD_ENTFERNEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPrueflaufBildEntfernen nicht ausgeführt (evtl. Offline)';



export class PostPrueflaufBildEntfernenAction implements Action {
    readonly type = POST_PRUEFLAUF_BILD_ENTFERNEN;
        constructor(public prueflaufBildRequest: api.PrueflaufBildRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufBildEntfernenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFLAUF_BILD_ENTFERNEN_AUSFUEHREN;
        constructor(public prueflaufBildRequest: api.PrueflaufBildRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufBildEntfernenErfolgreichAction implements Action {
    readonly type = POST_PRUEFLAUF_BILD_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public prueflaufBildRequest: api.PrueflaufBildRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufBildEntfernenFehlerAction implements Action {
    readonly type = POST_PRUEFLAUF_BILD_ENTFERNEN_FEHLER;
        constructor(public payload: any, public prueflaufBildRequest: api.PrueflaufBildRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufBildEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFLAUF_BILD_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPrueflaufBildEntfernenActions =
    PostPrueflaufBildEntfernenAction
    | PostPrueflaufBildEntfernenAusfuehrenAction
    | PostPrueflaufBildEntfernenErfolgreichAction
    | PostPrueflaufBildEntfernenFehlerAction
    | PostPrueflaufBildEntfernenNichtAusgefuehrtAction
    ;
