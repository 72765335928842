import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-funktionsbereich-loeschen',
    templateUrl: './funktionsbereich-loeschen.page.html',
    styleUrls: ['./funktionsbereich-loeschen.page.scss'],
})
export class FunktionsbereichLoeschenPage implements OnInit {
    @Input() titel: string;

    private aiName = 'FunktionsbereichLoeschenModal';

    constructor(
        private modalCtrl: ModalController) {
    }

    ngOnInit() {
    }

    ionViewDidLoad() {
    }


    async ok() {
        await this.modalCtrl.dismiss('ok');
    }

    async abbrechen() {
        await this.modalCtrl.dismiss();
    }
}
