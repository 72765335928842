import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE = '[VeranstaltungstypenModul] GetVeranstaltungsTypFunktionsbereiche laden';
export const GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE_AUSFUEHREN = '[VeranstaltungstypenModul] GetVeranstaltungsTypFunktionsbereiche ausführen';
export const GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE_ERFOLGREICH = '[VeranstaltungstypenModul] GetVeranstaltungsTypFunktionsbereiche laden erfolgreich';
export const GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE_FEHLER = '[VeranstaltungstypenModul] GetVeranstaltungsTypFunktionsbereiche Ladefehler';
export const GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] GetVeranstaltungsTypFunktionsbereiche nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungsTypFunktionsbereicheAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE;
        constructor( public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypFunktionsbereicheAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE_AUSFUEHREN;
        constructor( public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypFunktionsbereicheErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE_ERFOLGREICH;
        constructor(public payload: Array<api.Funktionsbereich>,  public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypFunktionsbereicheFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE_FEHLER;
        constructor(public payload: any,  public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypFunktionsbereicheNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_FUNKTIONSBEREICHE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenQueryVeranstaltungsTypFunktionsbereicheActions =
    GetVeranstaltungsTypFunktionsbereicheAction
    | GetVeranstaltungsTypFunktionsbereicheAusfuehrenAction
    | GetVeranstaltungsTypFunktionsbereicheErfolgreichAction
    | GetVeranstaltungsTypFunktionsbereicheFehlerAction
    | GetVeranstaltungsTypFunktionsbereicheNichtAusgefuehrtAction
    ;
