import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const POST_MITTEILUNGS_VORLAGE_ENTFERNEN = '[KommunikationModul] PostMitteilungsVorlageEntfernen laden';
export const POST_MITTEILUNGS_VORLAGE_ENTFERNEN_AUSFUEHREN = '[KommunikationModul] PostMitteilungsVorlageEntfernen ausführen';
export const POST_MITTEILUNGS_VORLAGE_ENTFERNEN_ERFOLGREICH = '[KommunikationModul] PostMitteilungsVorlageEntfernen laden erfolgreich';
export const POST_MITTEILUNGS_VORLAGE_ENTFERNEN_FEHLER = '[KommunikationModul] PostMitteilungsVorlageEntfernen Ladefehler';
export const POST_MITTEILUNGS_VORLAGE_ENTFERNEN_NICHT_AUSGEFUEHRT = '[KommunikationModul] PostMitteilungsVorlageEntfernen nicht ausgeführt (evtl. Offline)';



export class PostMitteilungsVorlageEntfernenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_ENTFERNEN;
        constructor(public mitteilungsVorlageRequest: api.MitteilungsVorlageRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageEntfernenAusfuehrenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_ENTFERNEN_AUSFUEHREN;
        constructor(public mitteilungsVorlageRequest: api.MitteilungsVorlageRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageEntfernenErfolgreichAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public mitteilungsVorlageRequest: api.MitteilungsVorlageRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageEntfernenFehlerAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_ENTFERNEN_FEHLER;
        constructor(public payload: any, public mitteilungsVorlageRequest: api.MitteilungsVorlageRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationCommandMitteilungsVorlageEntfernenActions =
    PostMitteilungsVorlageEntfernenAction
    | PostMitteilungsVorlageEntfernenAusfuehrenAction
    | PostMitteilungsVorlageEntfernenErfolgreichAction
    | PostMitteilungsVorlageEntfernenFehlerAction
    | PostMitteilungsVorlageEntfernenNichtAusgefuehrtAction
    ;
