import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const POST_BEREICH_LOGO_AKTUALISIEREN = '[DisplaysteuerungModul] PostBereichLogoAktualisieren laden';
export const POST_BEREICH_LOGO_AKTUALISIEREN_AUSFUEHREN = '[DisplaysteuerungModul] PostBereichLogoAktualisieren ausführen';
export const POST_BEREICH_LOGO_AKTUALISIEREN_ERFOLGREICH = '[DisplaysteuerungModul] PostBereichLogoAktualisieren laden erfolgreich';
export const POST_BEREICH_LOGO_AKTUALISIEREN_FEHLER = '[DisplaysteuerungModul] PostBereichLogoAktualisieren Ladefehler';
export const POST_BEREICH_LOGO_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] PostBereichLogoAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostBereichLogoAktualisierenAction implements Action {
    readonly type = POST_BEREICH_LOGO_AKTUALISIEREN;
        constructor(public bereichLogoRequest: api.BereichLogoRequest, public optPayload: any = null) {}
    }
export class PostBereichLogoAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_BEREICH_LOGO_AKTUALISIEREN_AUSFUEHREN;
        constructor(public bereichLogoRequest: api.BereichLogoRequest, public optPayload: any = null) {}
    }
export class PostBereichLogoAktualisierenErfolgreichAction implements Action {
    readonly type = POST_BEREICH_LOGO_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public bereichLogoRequest: api.BereichLogoRequest, public optPayload: any = null) {}
    }
export class PostBereichLogoAktualisierenFehlerAction implements Action {
    readonly type = POST_BEREICH_LOGO_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public bereichLogoRequest: api.BereichLogoRequest, public optPayload: any = null) {}
    }
export class PostBereichLogoAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_BEREICH_LOGO_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungCommandBereichLogoAktualisierenActions =
    PostBereichLogoAktualisierenAction
    | PostBereichLogoAktualisierenAusfuehrenAction
    | PostBereichLogoAktualisierenErfolgreichAction
    | PostBereichLogoAktualisierenFehlerAction
    | PostBereichLogoAktualisierenNichtAusgefuehrtAction
    ;
