/**
 * orga app | veranstaltungen
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AufgabeBemerkung } from '../model/aufgabeBemerkung';
import { AufgabeBildIds } from '../model/aufgabeBildIds';
import { AufgabeDetail } from '../model/aufgabeDetail';
import { Fehler } from '../model/fehler';
import { FehlerBeschreibung } from '../model/fehlerBeschreibung';
import { FehlerBildIds } from '../model/fehlerBildIds';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AufgabenQueryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetAufgabeBemerkung (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param aufgabe_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeBemerkung(aufgabe_id: string, observe?: 'body', reportProgress?: boolean): Observable<AufgabeBemerkung>;
    public getAufgabeBemerkung(aufgabe_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AufgabeBemerkung>>;
    public getAufgabeBemerkung(aufgabe_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AufgabeBemerkung>>;
    public getAufgabeBemerkung(aufgabe_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (aufgabe_id === null || aufgabe_id === undefined) {
            throw new Error('Required parameter aufgabe_id was null or undefined when calling getAufgabeBemerkung.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AufgabeBemerkung>('get',`${this.basePath}/api/query/veranstaltungen/aufgaben/${encodeURIComponent(String(aufgabe_id))}/bemerkung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetAufgabeBild (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param aufgabe_id 
     * @param bild_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeBild(aufgabe_id: string, bild_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getAufgabeBild(aufgabe_id: string, bild_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getAufgabeBild(aufgabe_id: string, bild_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getAufgabeBild(aufgabe_id: string, bild_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (aufgabe_id === null || aufgabe_id === undefined) {
            throw new Error('Required parameter aufgabe_id was null or undefined when calling getAufgabeBild.');
        }

        if (bild_id === null || bild_id === undefined) {
            throw new Error('Required parameter bild_id was null or undefined when calling getAufgabeBild.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/aufgaben/${encodeURIComponent(String(aufgabe_id))}/bild/${encodeURIComponent(String(bild_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetAufgabeBildIds (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param aufgabe_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeBildIds(aufgabe_id: string, observe?: 'body', reportProgress?: boolean): Observable<AufgabeBildIds>;
    public getAufgabeBildIds(aufgabe_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AufgabeBildIds>>;
    public getAufgabeBildIds(aufgabe_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AufgabeBildIds>>;
    public getAufgabeBildIds(aufgabe_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (aufgabe_id === null || aufgabe_id === undefined) {
            throw new Error('Required parameter aufgabe_id was null or undefined when calling getAufgabeBildIds.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AufgabeBildIds>('get',`${this.basePath}/api/query/veranstaltungen/aufgaben/${encodeURIComponent(String(aufgabe_id))}/bild_ids`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetAufgabeBildScaled (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param aufgabe_id 
     * @param bild_id 
     * @param width 
     * @param height 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeBildScaled(aufgabe_id: string, bild_id: string, width: number, height: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getAufgabeBildScaled(aufgabe_id: string, bild_id: string, width: number, height: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getAufgabeBildScaled(aufgabe_id: string, bild_id: string, width: number, height: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getAufgabeBildScaled(aufgabe_id: string, bild_id: string, width: number, height: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (aufgabe_id === null || aufgabe_id === undefined) {
            throw new Error('Required parameter aufgabe_id was null or undefined when calling getAufgabeBildScaled.');
        }

        if (bild_id === null || bild_id === undefined) {
            throw new Error('Required parameter bild_id was null or undefined when calling getAufgabeBildScaled.');
        }

        if (width === null || width === undefined) {
            throw new Error('Required parameter width was null or undefined when calling getAufgabeBildScaled.');
        }

        if (height === null || height === undefined) {
            throw new Error('Required parameter height was null or undefined when calling getAufgabeBildScaled.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/aufgaben/${encodeURIComponent(String(aufgabe_id))}/bild/${encodeURIComponent(String(bild_id))}/scale/${encodeURIComponent(String(width))}/${encodeURIComponent(String(height))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetAufgabeDetail (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param aufgabe_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeDetail(aufgabe_id: string, observe?: 'body', reportProgress?: boolean): Observable<AufgabeDetail>;
    public getAufgabeDetail(aufgabe_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AufgabeDetail>>;
    public getAufgabeDetail(aufgabe_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AufgabeDetail>>;
    public getAufgabeDetail(aufgabe_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (aufgabe_id === null || aufgabe_id === undefined) {
            throw new Error('Required parameter aufgabe_id was null or undefined when calling getAufgabeDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AufgabeDetail>('get',`${this.basePath}/api/query/veranstaltungen/aufgaben/${encodeURIComponent(String(aufgabe_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetAufgabeFehler (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param aufgabe_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeFehler(aufgabe_id: string, observe?: 'body', reportProgress?: boolean): Observable<Fehler>;
    public getAufgabeFehler(aufgabe_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Fehler>>;
    public getAufgabeFehler(aufgabe_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Fehler>>;
    public getAufgabeFehler(aufgabe_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (aufgabe_id === null || aufgabe_id === undefined) {
            throw new Error('Required parameter aufgabe_id was null or undefined when calling getAufgabeFehler.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Fehler>('get',`${this.basePath}/api/query/veranstaltungen/aufgaben/${encodeURIComponent(String(aufgabe_id))}/fehler`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetAufgabeFehlerBeschreibung (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param aufgabe_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeFehlerBeschreibung(aufgabe_id: string, observe?: 'body', reportProgress?: boolean): Observable<FehlerBeschreibung>;
    public getAufgabeFehlerBeschreibung(aufgabe_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FehlerBeschreibung>>;
    public getAufgabeFehlerBeschreibung(aufgabe_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FehlerBeschreibung>>;
    public getAufgabeFehlerBeschreibung(aufgabe_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (aufgabe_id === null || aufgabe_id === undefined) {
            throw new Error('Required parameter aufgabe_id was null or undefined when calling getAufgabeFehlerBeschreibung.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FehlerBeschreibung>('get',`${this.basePath}/api/query/veranstaltungen/aufgaben/${encodeURIComponent(String(aufgabe_id))}/fehler/beschreibung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetAufgabeFehlerBild (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param aufgabe_id 
     * @param bild_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeFehlerBild(aufgabe_id: string, bild_id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getAufgabeFehlerBild(aufgabe_id: string, bild_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getAufgabeFehlerBild(aufgabe_id: string, bild_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getAufgabeFehlerBild(aufgabe_id: string, bild_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (aufgabe_id === null || aufgabe_id === undefined) {
            throw new Error('Required parameter aufgabe_id was null or undefined when calling getAufgabeFehlerBild.');
        }

        if (bild_id === null || bild_id === undefined) {
            throw new Error('Required parameter bild_id was null or undefined when calling getAufgabeFehlerBild.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/aufgaben/${encodeURIComponent(String(aufgabe_id))}/fehlerbild/${encodeURIComponent(String(bild_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetAufgabeFehlerBildIds (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param aufgabe_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeFehlerBildIds(aufgabe_id: string, observe?: 'body', reportProgress?: boolean): Observable<FehlerBildIds>;
    public getAufgabeFehlerBildIds(aufgabe_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FehlerBildIds>>;
    public getAufgabeFehlerBildIds(aufgabe_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FehlerBildIds>>;
    public getAufgabeFehlerBildIds(aufgabe_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (aufgabe_id === null || aufgabe_id === undefined) {
            throw new Error('Required parameter aufgabe_id was null or undefined when calling getAufgabeFehlerBildIds.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FehlerBildIds>('get',`${this.basePath}/api/query/veranstaltungen/aufgaben/${encodeURIComponent(String(aufgabe_id))}/fehler/bild_ids`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetAufgabeFehlerBildScaled (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param aufgabe_id 
     * @param bild_id 
     * @param width 
     * @param height 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAufgabeFehlerBildScaled(aufgabe_id: string, bild_id: string, width: number, height: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getAufgabeFehlerBildScaled(aufgabe_id: string, bild_id: string, width: number, height: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getAufgabeFehlerBildScaled(aufgabe_id: string, bild_id: string, width: number, height: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getAufgabeFehlerBildScaled(aufgabe_id: string, bild_id: string, width: number, height: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (aufgabe_id === null || aufgabe_id === undefined) {
            throw new Error('Required parameter aufgabe_id was null or undefined when calling getAufgabeFehlerBildScaled.');
        }

        if (bild_id === null || bild_id === undefined) {
            throw new Error('Required parameter bild_id was null or undefined when calling getAufgabeFehlerBildScaled.');
        }

        if (width === null || width === undefined) {
            throw new Error('Required parameter width was null or undefined when calling getAufgabeFehlerBildScaled.');
        }

        if (height === null || height === undefined) {
            throw new Error('Required parameter height was null or undefined when calling getAufgabeFehlerBildScaled.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/veranstaltungen/aufgaben/${encodeURIComponent(String(aufgabe_id))}/fehlerbild/${encodeURIComponent(String(bild_id))}/scale/${encodeURIComponent(String(width))}/${encodeURIComponent(String(height))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
