import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action, select, Store} from '@ngrx/store';
import {filter, map, withLatestFrom} from 'rxjs/operators';
import * as actionsUi from './rollenselektion.actions.ui';
import {PageState} from './rollenselektion.reducer';
import * as actionsVorlagen from '../../../client-ngrx/vorlagen';
import * as actionsVeranstaltungen from '../../../client-ngrx/veranstaltungen';
import * as actionsDisplaysteuerung from '../../../client-ngrx/displaysteuerung';
import * as actionsQsChecks from '../../../client-ngrx/qschecks';
import {Datenquelle} from './rollenselektion.model';
import {GetFunktionsbereicheRollenAlleAction} from '../../../client-ngrx/benutzerverwaltung';
import {getVeranstaltungsId} from './rollenselektion.selectors';


@Injectable()
export class RollenselektionPageUiEffects {

    @Effect()
    pageResetBenutzerverwaltung$: Observable<Action> = this.actions$.pipe(
        ofType(actionsUi.PAGE_STATE_RESET, actionsUi.REFRESH_DATA)
        , filter((x: actionsUi.PageStateResetAction | actionsUi.RefreshDataAction) => x.datenquelle === Datenquelle.Benutzerverwaltung)
        , map((x) => {
            // console.log('Effect Benutzerverwaltung', x.datenquelle);
            return new GetFunktionsbereicheRollenAlleAction();
        })
    );

    @Effect()
    pageResetVorlagen$: Observable<Action> = this.actions$.pipe(
        ofType(actionsUi.PAGE_STATE_RESET, actionsUi.REFRESH_DATA)
        , filter((x: actionsUi.PageStateResetAction | actionsUi.RefreshDataAction) => x.datenquelle === Datenquelle.Vorlagen)
        , map((x) => {
            // console.log('Effect Vorlagen', x.datenquelle);
            return new actionsVorlagen.GetFunktionsbereicheRollenAction();
        })
    );

    @Effect()
    pageResetDiplaysteuerung$: Observable<Action> = this.actions$.pipe(
        ofType(actionsUi.PAGE_STATE_RESET, actionsUi.REFRESH_DATA)
        , filter((x: actionsUi.PageStateResetAction | actionsUi.RefreshDataAction) => x.datenquelle === Datenquelle.Displaysteuerung)
        , map((x) => {
            // console.log('Effect Displaysteuerung', x.datenquelle);
            return new actionsDisplaysteuerung.GetFunktionsbereicheRollenAction();
        })
    );

    @Effect()
    pageResetVeranstaltung$: Observable<Action> = this.actions$.pipe(
        ofType(actionsUi.PAGE_STATE_RESET, actionsUi.REFRESH_DATA)
        , withLatestFrom(this.store.pipe(select(getVeranstaltungsId)))
        , filter(([x, veranstaltungsId]: [actionsUi.PageStateResetAction | actionsUi.RefreshDataAction, string]) => x.datenquelle === Datenquelle.Veranstaltung)
        , map(([x, veranstaltungsId]) => {
            // console.log('Effect Veranstaltung', x.datenquelle);
            return new actionsVeranstaltungen.GetVeranstaltungFunktionsbereicheRollenAdminAlleAction(veranstaltungsId);
        })
    );

    @Effect()
    pageResetQsChecks$: Observable<Action> = this.actions$.pipe(
        ofType(actionsUi.PAGE_STATE_RESET, actionsUi.REFRESH_DATA)
        , filter((x: actionsUi.PageStateResetAction | actionsUi.RefreshDataAction) => x.datenquelle === Datenquelle.QsChecks)
        , map((x) => {
            // console.log('Effect QsChecks', x.datenquelle);
            return new actionsQsChecks.GetFunktionsbereicheRollenAction();
        })
    );

    constructor(private actions$: Actions,
                private store: Store<PageState>) {
    }
}
