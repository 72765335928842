import {Action} from '@ngrx/store';
import {Aufgabe, Checkliste, Funktionsbereich} from '../../../../clients/veranstaltungen';


export const SETZE_FUNKTIONSBEREICH_CHECKLISTE_ACTION = '[AppState] Setze Funktionsbereich und Checkliste';
export const SETZE_AUFGABE_ACTION = '[AppState] Setze Aufgabe';


export class SetzeFunktionsbereichChecklisteAction implements Action {
    readonly type = SETZE_FUNKTIONSBEREICH_CHECKLISTE_ACTION;

    constructor(public funktionsbereich: Funktionsbereich, public checkliste: Checkliste) {}
}

export class SetzeAufgabeAction implements Action {
    readonly type = SETZE_AUFGABE_ACTION;

    constructor(public aufgabe: Aufgabe) {}
}


export type Actions =
   SetzeFunktionsbereichChecklisteAction
    | SetzeAufgabeAction
    ;
