import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_KOPIEREN = '[VeranstaltungenModul] PostVeranstaltungKopieren laden';
export const POST_VERANSTALTUNG_KOPIEREN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungKopieren ausführen';
export const POST_VERANSTALTUNG_KOPIEREN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungKopieren laden erfolgreich';
export const POST_VERANSTALTUNG_KOPIEREN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungKopieren Ladefehler';
export const POST_VERANSTALTUNG_KOPIEREN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungKopieren nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungKopierenAction implements Action {
    readonly type = POST_VERANSTALTUNG_KOPIEREN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungKopierenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_KOPIEREN_AUSFUEHREN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungKopierenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_KOPIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungKopierenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_KOPIEREN_FEHLER;
        constructor(public payload: any, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungKopierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_KOPIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungKopierenActions =
    PostVeranstaltungKopierenAction
    | PostVeranstaltungKopierenAusfuehrenAction
    | PostVeranstaltungKopierenErfolgreichAction
    | PostVeranstaltungKopierenFehlerAction
    | PostVeranstaltungKopierenNichtAusgefuehrtAction
    ;
