import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_ADMIN_ENTFERNEN = '[BenutzerverwaltungModul] PostFunktionsbereichAdminEntfernen laden';
export const POST_FUNKTIONSBEREICH_ADMIN_ENTFERNEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostFunktionsbereichAdminEntfernen ausführen';
export const POST_FUNKTIONSBEREICH_ADMIN_ENTFERNEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostFunktionsbereichAdminEntfernen laden erfolgreich';
export const POST_FUNKTIONSBEREICH_ADMIN_ENTFERNEN_FEHLER = '[BenutzerverwaltungModul] PostFunktionsbereichAdminEntfernen Ladefehler';
export const POST_FUNKTIONSBEREICH_ADMIN_ENTFERNEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostFunktionsbereichAdminEntfernen nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichAdminEntfernenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ADMIN_ENTFERNEN;
        constructor(public funktionsbereichBenutzerRequest: api.FunktionsbereichBenutzerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAdminEntfernenAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ADMIN_ENTFERNEN_AUSFUEHREN;
        constructor(public funktionsbereichBenutzerRequest: api.FunktionsbereichBenutzerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAdminEntfernenErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ADMIN_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funktionsbereichBenutzerRequest: api.FunktionsbereichBenutzerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAdminEntfernenFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ADMIN_ENTFERNEN_FEHLER;
        constructor(public payload: any, public funktionsbereichBenutzerRequest: api.FunktionsbereichBenutzerRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichAdminEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ADMIN_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandFunktionsbereichAdminEntfernenActions =
    PostFunktionsbereichAdminEntfernenAction
    | PostFunktionsbereichAdminEntfernenAusfuehrenAction
    | PostFunktionsbereichAdminEntfernenErfolgreichAction
    | PostFunktionsbereichAdminEntfernenFehlerAction
    | PostFunktionsbereichAdminEntfernenNichtAusgefuehrtAction
    ;
