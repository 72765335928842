import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import * as fromRollenselektionModal from './veranstaltungstyp-auswahl.reducer';
import {ModalController, NavController, NavParams} from '@ionic/angular';
import * as actionsUi from './veranstaltungstyp-auswahl.actions.ui';
import {select, Store} from '@ngrx/store';
import {
    getOneSelected,
    getSelektierteTypen, getSelektierteTypenItems,
    getVeranstaltungsTypenFehler, getVeranstaltungsTypenLaden, getVeranstaltungTypen
} from './veranstaltungstyp-auswahl.selectors';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';
import {DatenquelleVeranstaltungsTypen} from './veranstaltungstyp-auswahl.model';
import {VeranstaltungsTyp} from '../../../clients/veranstaltungstypen';


@Component({
    selector: 'app-veranstaltungstyp-auswahl',
    templateUrl: './veranstaltungstyp-auswahl.page.html'
})
export class VeranstaltungstypAuswahlPage implements OnInit {
    @Input() titel: string;
    @Input() untertitel: string;
    @Input() nichtAnzuzeigendeTypenIds: Array<string>;
    @Input() datenquelle: DatenquelleVeranstaltungsTypen;
    @Input() enableSingleSelection: boolean;

    aiName = 'VeranstaltungstypAuswahlPage';

    typen$: Observable<Array<ListInputItem>>;
    typenLaden$: Observable<boolean>;
    typenFehler$: Observable<boolean>;
    oneSelected$: Observable<boolean>;
    selektierteTypen$: Observable<Array<string>>;
    selektierteTypenItems$: Observable<Array<VeranstaltungsTyp>>;

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private modalCtrl: ModalController,
                private store: Store<fromRollenselektionModal.PageState>) {
    }

    ngOnInit() {
        this.typen$ = this.store.pipe(select(getVeranstaltungTypen));
        this.typenLaden$ = this.store.pipe(select(getVeranstaltungsTypenLaden));
        this.typenFehler$ = this.store.pipe(select(getVeranstaltungsTypenFehler));
        this.selektierteTypen$ = this.store.pipe(select(getSelektierteTypen));
        this.selektierteTypenItems$ = this.store.pipe(select(getSelektierteTypenItems));

        this.oneSelected$ = this.store.pipe(select(getOneSelected));
    }

    ionViewWillEnter() {
        this.viewWillEnterPage().then();
    }

    async viewWillEnterPage() {
        await this.store.dispatch(new actionsUi.PageStateResetAction(this.datenquelle, this.nichtAnzuzeigendeTypenIds));
    }


    async ok() {
        if (this.enableSingleSelection) {
            this.selektierteTypenItems$.subscribe(async (items) => {
                let data;
                if (items.length > 0) {
                    data = {ausgewaehlterTyp: items[0]};
                } else {
                    data = {ausgewaehlterTyp: null};
                }
                await this.modalCtrl.dismiss(data);
            }).unsubscribe();
        } else {
            this.selektierteTypen$.subscribe(async (items) => {
                const data = {ausgewaehlteTypen: items};
                await this.modalCtrl.dismiss(data);
            }).unsubscribe();
        }
    }

    async abbrechen() {
        await this.modalCtrl.dismiss();
    }

    async refreshData() {
        await this.store.dispatch(new actionsUi.RefreshDataAction(this.datenquelle));
    }

    async updateSelectedTypen($event: Array<string>) {
        await this.store.dispatch(new actionsUi.SelektierteTypenAction($event));
    }

    async updateSelectedTyp($event: string) {
        const rolle: Array<string> = [$event];
        await this.store.dispatch(new actionsUi.SelektierteTypenAction(rolle));
    }
}
