import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_INFO_KATEGORIE_ADMIN = '[VeranstaltungenModul] GetVeranstaltungInfoKategorieAdmin laden';
export const GET_VERANSTALTUNG_INFO_KATEGORIE_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungInfoKategorieAdmin ausführen';
export const GET_VERANSTALTUNG_INFO_KATEGORIE_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungInfoKategorieAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_INFO_KATEGORIE_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungInfoKategorieAdmin Ladefehler';
export const GET_VERANSTALTUNG_INFO_KATEGORIE_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungInfoKategorieAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungInfoKategorieAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_INFO_KATEGORIE_ADMIN;
        constructor( public veranstaltung_id:string ,  public kagetorieId:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungInfoKategorieAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_INFO_KATEGORIE_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public kagetorieId:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungInfoKategorieAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_INFO_KATEGORIE_ADMIN_ERFOLGREICH;
        constructor(public payload: api.InfoKategorie,  public veranstaltung_id:string ,  public kagetorieId:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungInfoKategorieAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_INFO_KATEGORIE_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public kagetorieId:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungInfoKategorieAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_INFO_KATEGORIE_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungInfoKategorieAdminActions =
    GetVeranstaltungInfoKategorieAdminAction
    | GetVeranstaltungInfoKategorieAdminAusfuehrenAction
    | GetVeranstaltungInfoKategorieAdminErfolgreichAction
    | GetVeranstaltungInfoKategorieAdminFehlerAction
    | GetVeranstaltungInfoKategorieAdminNichtAusgefuehrtAction
    ;
