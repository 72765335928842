import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const POST_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNGEN_SETZEN = '[DisplaysteuerungModul] PostDisplaysteuerungRolleBerechtigungenSetzen laden';
export const POST_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNGEN_SETZEN_AUSFUEHREN = '[DisplaysteuerungModul] PostDisplaysteuerungRolleBerechtigungenSetzen ausführen';
export const POST_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNGEN_SETZEN_ERFOLGREICH = '[DisplaysteuerungModul] PostDisplaysteuerungRolleBerechtigungenSetzen laden erfolgreich';
export const POST_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNGEN_SETZEN_FEHLER = '[DisplaysteuerungModul] PostDisplaysteuerungRolleBerechtigungenSetzen Ladefehler';
export const POST_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNGEN_SETZEN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] PostDisplaysteuerungRolleBerechtigungenSetzen nicht ausgeführt (evtl. Offline)';



export class PostDisplaysteuerungRolleBerechtigungenSetzenAction implements Action {
    readonly type = POST_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNGEN_SETZEN;
        constructor(public displaysteuerungRolleBerechtigungenRequest: api.DisplaysteuerungRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostDisplaysteuerungRolleBerechtigungenSetzenAusfuehrenAction implements Action {
    readonly type = POST_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNGEN_SETZEN_AUSFUEHREN;
        constructor(public displaysteuerungRolleBerechtigungenRequest: api.DisplaysteuerungRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostDisplaysteuerungRolleBerechtigungenSetzenErfolgreichAction implements Action {
    readonly type = POST_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNGEN_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public displaysteuerungRolleBerechtigungenRequest: api.DisplaysteuerungRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostDisplaysteuerungRolleBerechtigungenSetzenFehlerAction implements Action {
    readonly type = POST_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNGEN_SETZEN_FEHLER;
        constructor(public payload: any, public displaysteuerungRolleBerechtigungenRequest: api.DisplaysteuerungRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostDisplaysteuerungRolleBerechtigungenSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNGEN_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungCommandDisplaysteuerungRolleBerechtigungenSetzenActions =
    PostDisplaysteuerungRolleBerechtigungenSetzenAction
    | PostDisplaysteuerungRolleBerechtigungenSetzenAusfuehrenAction
    | PostDisplaysteuerungRolleBerechtigungenSetzenErfolgreichAction
    | PostDisplaysteuerungRolleBerechtigungenSetzenFehlerAction
    | PostDisplaysteuerungRolleBerechtigungenSetzenNichtAusgefuehrtAction
    ;
