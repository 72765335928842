import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_DOKUMENT_IDENTIFIER = '[VeranstaltungenModul] GetDokumentIdentifier laden';
export const GET_DOKUMENT_IDENTIFIER_AUSFUEHREN = '[VeranstaltungenModul] GetDokumentIdentifier ausführen';
export const GET_DOKUMENT_IDENTIFIER_ERFOLGREICH = '[VeranstaltungenModul] GetDokumentIdentifier laden erfolgreich';
export const GET_DOKUMENT_IDENTIFIER_FEHLER = '[VeranstaltungenModul] GetDokumentIdentifier Ladefehler';
export const GET_DOKUMENT_IDENTIFIER_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetDokumentIdentifier nicht ausgeführt (evtl. Offline)';



export class GetDokumentIdentifierAction implements Action {
    readonly type = GET_DOKUMENT_IDENTIFIER;
        constructor(public optPayload: any = null) {}
    }
export class GetDokumentIdentifierAusfuehrenAction implements Action {
    readonly type = GET_DOKUMENT_IDENTIFIER_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetDokumentIdentifierErfolgreichAction implements Action {
    readonly type = GET_DOKUMENT_IDENTIFIER_ERFOLGREICH;
        constructor(public payload: api.DokumentIdentifier, public optPayload: any = null) {}
    }
export class GetDokumentIdentifierFehlerAction implements Action {
    readonly type = GET_DOKUMENT_IDENTIFIER_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetDokumentIdentifierNichtAusgefuehrtAction implements Action {
    readonly type = GET_DOKUMENT_IDENTIFIER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryDokumentIdentifierActions =
    GetDokumentIdentifierAction
    | GetDokumentIdentifierAusfuehrenAction
    | GetDokumentIdentifierErfolgreichAction
    | GetDokumentIdentifierFehlerAction
    | GetDokumentIdentifierNichtAusgefuehrtAction
    ;
