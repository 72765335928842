import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungHinzufuegenMulti laden';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_AUSFUEHREN = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungHinzufuegenMulti ausführen';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_ERFOLGREICH = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungHinzufuegenMulti laden erfolgreich';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_FEHLER = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungHinzufuegenMulti Ladefehler';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostDokumentBearbeitenBerechtigungHinzufuegenMulti nicht ausgeführt (evtl. Offline)';



export class PostDokumentBearbeitenBerechtigungHinzufuegenMultiAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI;
        constructor(public dokumentBerechtigungMultiRequest: api.DokumentBerechtigungMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenMultiAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_AUSFUEHREN;
        constructor(public dokumentBerechtigungMultiRequest: api.DokumentBerechtigungMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenMultiErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungMultiRequest: api.DokumentBerechtigungMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenMultiFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungMultiRequest: api.DokumentBerechtigungMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenMultiNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandDokumentBearbeitenBerechtigungHinzufuegenMultiActions =
    PostDokumentBearbeitenBerechtigungHinzufuegenMultiAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenMultiAusfuehrenAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenMultiErfolgreichAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenMultiFehlerAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenMultiNichtAusgefuehrtAction
    ;
