import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN = '[VorlagenModul] GetFunktionsbereichAufgabenBenutzerBerechtigungen laden';
export const GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_AUSFUEHREN = '[VorlagenModul] GetFunktionsbereichAufgabenBenutzerBerechtigungen ausführen';
export const GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ERFOLGREICH = '[VorlagenModul] GetFunktionsbereichAufgabenBenutzerBerechtigungen laden erfolgreich';
export const GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_FEHLER = '[VorlagenModul] GetFunktionsbereichAufgabenBenutzerBerechtigungen Ladefehler';
export const GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetFunktionsbereichAufgabenBenutzerBerechtigungen nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichAufgabenBenutzerBerechtigungenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAufgabenBenutzerBerechtigungenAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_AUSFUEHREN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAufgabenBenutzerBerechtigungenErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_ERFOLGREICH;
        constructor(public payload: Array<api.ChecklisteAufgaben2>,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAufgabenBenutzerBerechtigungenFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAufgabenBenutzerBerechtigungenNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_AUFGABEN_BENUTZER_BERECHTIGUNGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryFunktionsbereichAufgabenBenutzerBerechtigungenActions =
    GetFunktionsbereichAufgabenBenutzerBerechtigungenAction
    | GetFunktionsbereichAufgabenBenutzerBerechtigungenAusfuehrenAction
    | GetFunktionsbereichAufgabenBenutzerBerechtigungenErfolgreichAction
    | GetFunktionsbereichAufgabenBenutzerBerechtigungenFehlerAction
    | GetFunktionsbereichAufgabenBenutzerBerechtigungenNichtAusgefuehrtAction
    ;
