import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENTS_ANZEIGE_MANDANT_OPTIONS = '[IncidentsModul] GetIncidentsAnzeigeMandantOptions laden';
export const GET_INCIDENTS_ANZEIGE_MANDANT_OPTIONS_AUSFUEHREN = '[IncidentsModul] GetIncidentsAnzeigeMandantOptions ausführen';
export const GET_INCIDENTS_ANZEIGE_MANDANT_OPTIONS_ERFOLGREICH = '[IncidentsModul] GetIncidentsAnzeigeMandantOptions laden erfolgreich';
export const GET_INCIDENTS_ANZEIGE_MANDANT_OPTIONS_FEHLER = '[IncidentsModul] GetIncidentsAnzeigeMandantOptions Ladefehler';
export const GET_INCIDENTS_ANZEIGE_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentsAnzeigeMandantOptions nicht ausgeführt (evtl. Offline)';



export class GetIncidentsAnzeigeMandantOptionsAction implements Action {
    readonly type = GET_INCIDENTS_ANZEIGE_MANDANT_OPTIONS;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsAnzeigeMandantOptionsAusfuehrenAction implements Action {
    readonly type = GET_INCIDENTS_ANZEIGE_MANDANT_OPTIONS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsAnzeigeMandantOptionsErfolgreichAction implements Action {
    readonly type = GET_INCIDENTS_ANZEIGE_MANDANT_OPTIONS_ERFOLGREICH;
        constructor(public payload: api.IncidentsAnzeigeMandantOptions, public optPayload: any = null) {}
    }
export class GetIncidentsAnzeigeMandantOptionsFehlerAction implements Action {
    readonly type = GET_INCIDENTS_ANZEIGE_MANDANT_OPTIONS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetIncidentsAnzeigeMandantOptionsNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENTS_ANZEIGE_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentsAnzeigeMandantOptionsActions =
    GetIncidentsAnzeigeMandantOptionsAction
    | GetIncidentsAnzeigeMandantOptionsAusfuehrenAction
    | GetIncidentsAnzeigeMandantOptionsErfolgreichAction
    | GetIncidentsAnzeigeMandantOptionsFehlerAction
    | GetIncidentsAnzeigeMandantOptionsNichtAusgefuehrtAction
    ;
