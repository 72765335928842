import {NgModule} from '@angular/core';
import {SafePipe} from './safe/safe.pipe';
import {SafeHtmlPipe} from './safe-html/safe-html.pipe';

@NgModule({
    declarations: [
        SafePipe,
        SafeHtmlPipe
    ],
    imports: [],
    exports: [
        SafePipe,
        SafeHtmlPipe
    ]
})
export class PipesModule {}
