import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_AKTION_ADMIN = '[VeranstaltungenModul] GetVeranstaltungAktionAdmin laden';
export const GET_VERANSTALTUNG_AKTION_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungAktionAdmin ausführen';
export const GET_VERANSTALTUNG_AKTION_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungAktionAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_AKTION_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungAktionAdmin Ladefehler';
export const GET_VERANSTALTUNG_AKTION_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungAktionAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungAktionAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_AKTION_ADMIN;
        constructor( public veranstaltung_id:string ,  public aktionId:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungAktionAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_AKTION_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public aktionId:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungAktionAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_AKTION_ADMIN_ERFOLGREICH;
        constructor(public payload: api.Aktion,  public veranstaltung_id:string ,  public aktionId:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungAktionAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_AKTION_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public aktionId:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungAktionAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_AKTION_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungAktionAdminActions =
    GetVeranstaltungAktionAdminAction
    | GetVeranstaltungAktionAdminAusfuehrenAction
    | GetVeranstaltungAktionAdminErfolgreichAction
    | GetVeranstaltungAktionAdminFehlerAction
    | GetVeranstaltungAktionAdminNichtAusgefuehrtAction
    ;
