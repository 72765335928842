import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const GET_BEREICHE_LISTE = '[DisplaysteuerungModul] GetBereicheListe laden';
export const GET_BEREICHE_LISTE_AUSFUEHREN = '[DisplaysteuerungModul] GetBereicheListe ausführen';
export const GET_BEREICHE_LISTE_ERFOLGREICH = '[DisplaysteuerungModul] GetBereicheListe laden erfolgreich';
export const GET_BEREICHE_LISTE_FEHLER = '[DisplaysteuerungModul] GetBereicheListe Ladefehler';
export const GET_BEREICHE_LISTE_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] GetBereicheListe nicht ausgeführt (evtl. Offline)';



export class GetBereicheListeAction implements Action {
    readonly type = GET_BEREICHE_LISTE;
        constructor(public optPayload: any = null) {}
    }
export class GetBereicheListeAusfuehrenAction implements Action {
    readonly type = GET_BEREICHE_LISTE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetBereicheListeErfolgreichAction implements Action {
    readonly type = GET_BEREICHE_LISTE_ERFOLGREICH;
        constructor(public payload: Array<api.BereichListItem>, public optPayload: any = null) {}
    }
export class GetBereicheListeFehlerAction implements Action {
    readonly type = GET_BEREICHE_LISTE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetBereicheListeNichtAusgefuehrtAction implements Action {
    readonly type = GET_BEREICHE_LISTE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungQueryBereicheListeActions =
    GetBereicheListeAction
    | GetBereicheListeAusfuehrenAction
    | GetBereicheListeErfolgreichAction
    | GetBereicheListeFehlerAction
    | GetBereicheListeNichtAusgefuehrtAction
    ;
