import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ABTEILUNG_PINNWAND = '[IncidentsModul] GetAbteilungPinnwand laden';
export const GET_ABTEILUNG_PINNWAND_AUSFUEHREN = '[IncidentsModul] GetAbteilungPinnwand ausführen';
export const GET_ABTEILUNG_PINNWAND_ERFOLGREICH = '[IncidentsModul] GetAbteilungPinnwand laden erfolgreich';
export const GET_ABTEILUNG_PINNWAND_FEHLER = '[IncidentsModul] GetAbteilungPinnwand Ladefehler';
export const GET_ABTEILUNG_PINNWAND_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetAbteilungPinnwand nicht ausgeführt (evtl. Offline)';



export class GetAbteilungPinnwandAction implements Action {
    readonly type = GET_ABTEILUNG_PINNWAND;
        constructor( public funktionsbereich_id:string ,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetAbteilungPinnwandAusfuehrenAction implements Action {
    readonly type = GET_ABTEILUNG_PINNWAND_AUSFUEHREN;
        constructor( public funktionsbereich_id:string ,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetAbteilungPinnwandErfolgreichAction implements Action {
    readonly type = GET_ABTEILUNG_PINNWAND_ERFOLGREICH;
        constructor(public payload: Array<api.IncidentKopf>,  public funktionsbereich_id:string ,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetAbteilungPinnwandFehlerAction implements Action {
    readonly type = GET_ABTEILUNG_PINNWAND_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string ,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetAbteilungPinnwandNichtAusgefuehrtAction implements Action {
    readonly type = GET_ABTEILUNG_PINNWAND_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryAbteilungPinnwandActions =
    GetAbteilungPinnwandAction
    | GetAbteilungPinnwandAusfuehrenAction
    | GetAbteilungPinnwandErfolgreichAction
    | GetAbteilungPinnwandFehlerAction
    | GetAbteilungPinnwandNichtAusgefuehrtAction
    ;
