import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {ActionReducer, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {globalFeatureName, reducers} from './app-state.reducer';
import {LocalStorageConfig, localStorageSync} from 'ngrx-store-localstorage';
import {BenutzerverwaltungBenutzerInfoEffects} from '../../../client-ngrx/benutzerverwaltung';
import {VeranstaltungenVeranstaltungBerechtigungEffects, VeranstaltungenVeranstaltungDeckblattEffects, VeranstaltungenVeranstaltungenAktuelleEffects, VeranstaltungenVeranstaltungMitteilungBerechtigungEffects} from '../../../client-ngrx/veranstaltungen';
import {VeranstaltungEffects} from './effects/veranstaltung.effects';
import {AppStateWsEffects} from './effects/app-state.ws.effects';


export function sessionStorage(reducer: ActionReducer<any>): ActionReducer<any> {

    const config: LocalStorageConfig = {
        keys: [
            // 'api',
            'daten'
        ],
        rehydrate: true,
        removeOnUndefined: true
    };

    return localStorageSync(config)(reducer);
}


@NgModule({
    imports: [
        CommonModule,
        IonicModule,

        StoreModule.forFeature(globalFeatureName, reducers, {metaReducers: [sessionStorage]}),

        EffectsModule.forFeature([
            AppStateWsEffects,
            VeranstaltungEffects,

            BenutzerverwaltungBenutzerInfoEffects,
            VeranstaltungenVeranstaltungenAktuelleEffects,
            VeranstaltungenVeranstaltungBerechtigungEffects,
            VeranstaltungenVeranstaltungDeckblattEffects,
            VeranstaltungenVeranstaltungMitteilungBerechtigungEffects
        ])
    ],
    declarations: [],
    providers: [
    ]
})
export class AppStateModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: AppStateModule
        };
    }
}
