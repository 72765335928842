import * as api from '../../../clients/einlasskontrolle'; 

import {Action} from '@ngrx/store';

export const POST_EINLASSKONTROLLE_ROLLE_BERECHTIGUNG_SETZEN = '[EinlasskontrolleModul] PostEinlasskontrolleRolleBerechtigungSetzen laden';
export const POST_EINLASSKONTROLLE_ROLLE_BERECHTIGUNG_SETZEN_AUSFUEHREN = '[EinlasskontrolleModul] PostEinlasskontrolleRolleBerechtigungSetzen ausführen';
export const POST_EINLASSKONTROLLE_ROLLE_BERECHTIGUNG_SETZEN_ERFOLGREICH = '[EinlasskontrolleModul] PostEinlasskontrolleRolleBerechtigungSetzen laden erfolgreich';
export const POST_EINLASSKONTROLLE_ROLLE_BERECHTIGUNG_SETZEN_FEHLER = '[EinlasskontrolleModul] PostEinlasskontrolleRolleBerechtigungSetzen Ladefehler';
export const POST_EINLASSKONTROLLE_ROLLE_BERECHTIGUNG_SETZEN_NICHT_AUSGEFUEHRT = '[EinlasskontrolleModul] PostEinlasskontrolleRolleBerechtigungSetzen nicht ausgeführt (evtl. Offline)';



export class PostEinlasskontrolleRolleBerechtigungSetzenAction implements Action {
    readonly type = POST_EINLASSKONTROLLE_ROLLE_BERECHTIGUNG_SETZEN;
        constructor(public einlasskontrolleRolleOptionenRequest: api.EinlasskontrolleRolleOptionenRequest, public optPayload: any = null) {}
    }
export class PostEinlasskontrolleRolleBerechtigungSetzenAusfuehrenAction implements Action {
    readonly type = POST_EINLASSKONTROLLE_ROLLE_BERECHTIGUNG_SETZEN_AUSFUEHREN;
        constructor(public einlasskontrolleRolleOptionenRequest: api.EinlasskontrolleRolleOptionenRequest, public optPayload: any = null) {}
    }
export class PostEinlasskontrolleRolleBerechtigungSetzenErfolgreichAction implements Action {
    readonly type = POST_EINLASSKONTROLLE_ROLLE_BERECHTIGUNG_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public einlasskontrolleRolleOptionenRequest: api.EinlasskontrolleRolleOptionenRequest, public optPayload: any = null) {}
    }
export class PostEinlasskontrolleRolleBerechtigungSetzenFehlerAction implements Action {
    readonly type = POST_EINLASSKONTROLLE_ROLLE_BERECHTIGUNG_SETZEN_FEHLER;
        constructor(public payload: any, public einlasskontrolleRolleOptionenRequest: api.EinlasskontrolleRolleOptionenRequest, public optPayload: any = null) {}
    }
export class PostEinlasskontrolleRolleBerechtigungSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_EINLASSKONTROLLE_ROLLE_BERECHTIGUNG_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type EinlasskontrolleCommandEinlasskontrolleRolleBerechtigungSetzenActions =
    PostEinlasskontrolleRolleBerechtigungSetzenAction
    | PostEinlasskontrolleRolleBerechtigungSetzenAusfuehrenAction
    | PostEinlasskontrolleRolleBerechtigungSetzenErfolgreichAction
    | PostEinlasskontrolleRolleBerechtigungSetzenFehlerAction
    | PostEinlasskontrolleRolleBerechtigungSetzenNichtAusgefuehrtAction
    ;
