import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.query.veranstaltungs-typen-entity-types';
import * as api from '../../../clients/veranstaltungstypen';


    @Injectable()
    export class VeranstaltungstypenVeranstaltungsTypenEntityTypesEffects {

        @Effect()
            VeranstaltungsTypenEntityTypes$ = this.actions$.pipe(
                ofType(ac.GET_VERANSTALTUNGS_TYPEN_ENTITY_TYPES),
                flatMap((x: ac.GetVeranstaltungsTypenEntityTypesAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().getVeranstaltungsTypenEntityTypes().pipe(
                    map((result: any) => {
                        const nextAction = new ac.GetVeranstaltungsTypenEntityTypesErfolgreichAction(result,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.GetVeranstaltungsTypenEntityTypesFehlerAction(error,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.VeranstaltungsTypenTemplatesService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.VeranstaltungsTypenTemplatesService {
                if (this.service === null) {
                    this.service = this.injector.get(api.VeranstaltungsTypenTemplatesService);
                }
                return this.service;
            }
        
}
