import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_ROLLE_ANLEGEN = '[BenutzerverwaltungModul] PostRolleAnlegen laden';
export const POST_ROLLE_ANLEGEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostRolleAnlegen ausführen';
export const POST_ROLLE_ANLEGEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostRolleAnlegen laden erfolgreich';
export const POST_ROLLE_ANLEGEN_FEHLER = '[BenutzerverwaltungModul] PostRolleAnlegen Ladefehler';
export const POST_ROLLE_ANLEGEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostRolleAnlegen nicht ausgeführt (evtl. Offline)';



export class PostRolleAnlegenAction implements Action {
    readonly type = POST_ROLLE_ANLEGEN;
        constructor(public rolleAnlegenRequest: api.RolleAnlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleAnlegenAusfuehrenAction implements Action {
    readonly type = POST_ROLLE_ANLEGEN_AUSFUEHREN;
        constructor(public rolleAnlegenRequest: api.RolleAnlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleAnlegenErfolgreichAction implements Action {
    readonly type = POST_ROLLE_ANLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public rolleAnlegenRequest: api.RolleAnlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleAnlegenFehlerAction implements Action {
    readonly type = POST_ROLLE_ANLEGEN_FEHLER;
        constructor(public payload: any, public rolleAnlegenRequest: api.RolleAnlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleAnlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_ROLLE_ANLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandRolleAnlegenActions =
    PostRolleAnlegenAction
    | PostRolleAnlegenAusfuehrenAction
    | PostRolleAnlegenErfolgreichAction
    | PostRolleAnlegenFehlerAction
    | PostRolleAnlegenNichtAusgefuehrtAction
    ;
