import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_MITTEILUNG_IDENTIFIER = '[KommunikationModul] GetMitteilungIdentifier laden';
export const GET_MITTEILUNG_IDENTIFIER_AUSFUEHREN = '[KommunikationModul] GetMitteilungIdentifier ausführen';
export const GET_MITTEILUNG_IDENTIFIER_ERFOLGREICH = '[KommunikationModul] GetMitteilungIdentifier laden erfolgreich';
export const GET_MITTEILUNG_IDENTIFIER_FEHLER = '[KommunikationModul] GetMitteilungIdentifier Ladefehler';
export const GET_MITTEILUNG_IDENTIFIER_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetMitteilungIdentifier nicht ausgeführt (evtl. Offline)';



export class GetMitteilungIdentifierAction implements Action {
    readonly type = GET_MITTEILUNG_IDENTIFIER;
        constructor(public optPayload: any = null) {}
    }
export class GetMitteilungIdentifierAusfuehrenAction implements Action {
    readonly type = GET_MITTEILUNG_IDENTIFIER_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMitteilungIdentifierErfolgreichAction implements Action {
    readonly type = GET_MITTEILUNG_IDENTIFIER_ERFOLGREICH;
        constructor(public payload: api.MitteilungIdentifier, public optPayload: any = null) {}
    }
export class GetMitteilungIdentifierFehlerAction implements Action {
    readonly type = GET_MITTEILUNG_IDENTIFIER_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMitteilungIdentifierNichtAusgefuehrtAction implements Action {
    readonly type = GET_MITTEILUNG_IDENTIFIER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryMitteilungIdentifierActions =
    GetMitteilungIdentifierAction
    | GetMitteilungIdentifierAusfuehrenAction
    | GetMitteilungIdentifierErfolgreichAction
    | GetMitteilungIdentifierFehlerAction
    | GetMitteilungIdentifierNichtAusgefuehrtAction
    ;
