import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_ENTFERNEN = '[VeranstaltungenModul] PostFunktionsbereichEntfernen laden';
export const POST_FUNKTIONSBEREICH_ENTFERNEN_AUSFUEHREN = '[VeranstaltungenModul] PostFunktionsbereichEntfernen ausführen';
export const POST_FUNKTIONSBEREICH_ENTFERNEN_ERFOLGREICH = '[VeranstaltungenModul] PostFunktionsbereichEntfernen laden erfolgreich';
export const POST_FUNKTIONSBEREICH_ENTFERNEN_FEHLER = '[VeranstaltungenModul] PostFunktionsbereichEntfernen Ladefehler';
export const POST_FUNKTIONSBEREICH_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFunktionsbereichEntfernen nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichEntfernenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ENTFERNEN;
        constructor(public funktionsbereichRequest: api.FunktionsbereichRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichEntfernenAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ENTFERNEN_AUSFUEHREN;
        constructor(public funktionsbereichRequest: api.FunktionsbereichRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichEntfernenErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funktionsbereichRequest: api.FunktionsbereichRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichEntfernenFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ENTFERNEN_FEHLER;
        constructor(public payload: any, public funktionsbereichRequest: api.FunktionsbereichRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFunktionsbereichEntfernenActions =
    PostFunktionsbereichEntfernenAction
    | PostFunktionsbereichEntfernenAusfuehrenAction
    | PostFunktionsbereichEntfernenErfolgreichAction
    | PostFunktionsbereichEntfernenFehlerAction
    | PostFunktionsbereichEntfernenNichtAusgefuehrtAction
    ;
