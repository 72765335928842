import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_AKTIVIEREN = '[VorlagenModul] PostDokumentAktivieren laden';
export const POST_DOKUMENT_AKTIVIEREN_AUSFUEHREN = '[VorlagenModul] PostDokumentAktivieren ausführen';
export const POST_DOKUMENT_AKTIVIEREN_ERFOLGREICH = '[VorlagenModul] PostDokumentAktivieren laden erfolgreich';
export const POST_DOKUMENT_AKTIVIEREN_FEHLER = '[VorlagenModul] PostDokumentAktivieren Ladefehler';
export const POST_DOKUMENT_AKTIVIEREN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentAktivieren nicht ausgeführt (evtl. Offline)';



export class PostDokumentAktivierenAction implements Action {
    readonly type = POST_DOKUMENT_AKTIVIEREN;
        constructor(public dokumentAktivierenRequest: api.DokumentAktivierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentAktivierenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_AKTIVIEREN_AUSFUEHREN;
        constructor(public dokumentAktivierenRequest: api.DokumentAktivierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentAktivierenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_AKTIVIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentAktivierenRequest: api.DokumentAktivierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentAktivierenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_AKTIVIEREN_FEHLER;
        constructor(public payload: any, public dokumentAktivierenRequest: api.DokumentAktivierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentAktivierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_AKTIVIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentAktivierenActions =
    PostDokumentAktivierenAction
    | PostDokumentAktivierenAusfuehrenAction
    | PostDokumentAktivierenErfolgreichAction
    | PostDokumentAktivierenFehlerAction
    | PostDokumentAktivierenNichtAusgefuehrtAction
    ;
