import {Action, ActionReducerMap} from '@ngrx/store';

import * as actionsUi from './qschecks-controlling-saison-filter.actions.ui';
import {GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING, GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING_ERFOLGREICH, GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING_FEHLER, GET_PRUEFPLAN_SAISON_FILTER, GET_PRUEFPLAN_SAISON_FILTER_ERFOLGREICH, GET_PRUEFPLAN_SAISON_FILTER_FEHLER, GetFunktionsbereichePruefplaeneControllingErfolgreichAction, GetPruefplanSaisonFilterErfolgreichAction} from '../../../../client-ngrx/qschecks';
import {FunktionsbereichPruefplaene, Pruefplan, SaisonFilterOption} from '../../../../clients/qschecks';
import {AKTUELLEN_PRUEFPLAN_SETZEN, AktuellenPruefplanSetzenAction} from './qschecks-controlling-saison-filter.actions.ui';

export interface ModuleState {
    page: PageState;
}

export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer
};

export interface PageState {
    fbPruefplaeneListe: Array<FunktionsbereichPruefplaene>;
    fbPruefplaeneListeLaden: boolean;
    fbPruefplaeneListeFehler: boolean;

    pruefplanSelected: Pruefplan;

    saisonFilterOptions: SaisonFilterOption;
    saisonFilterOptionsLaden: boolean;
    saisonFilterOptionsFehler: boolean;
}

export const initialPageState: PageState = {
    fbPruefplaeneListe: [],
    fbPruefplaeneListeLaden: false,
    fbPruefplaeneListeFehler: false,

    pruefplanSelected: null,

    saisonFilterOptions: null,
    saisonFilterOptionsLaden: false,
    saisonFilterOptionsFehler: false
};

export function pageReducer(state = initialPageState, action: Action): PageState {
    switch (action.type) {
        // Page State Reset
        case actionsUi.PAGE_STATE_RESET: {
            return Object.assign({}, state, <PageState>initialPageState);
        }

        /* FB-Prüfpläne */
        case GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING: {
            return Object.assign({}, state, <PageState>{
                fbPruefplaeneListeLaden: true,
                fbPruefplaeneListeFehler: false
            });
        }

        case GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING_ERFOLGREICH: {
            const a = <GetFunktionsbereichePruefplaeneControllingErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                fbPruefplaeneListe: a.payload,
                fbPruefplaeneListeLaden: false,
                fbPruefplaeneListeFehler: false,
            });
        }

        case GET_FUNKTIONSBEREICHE_PRUEFPLAENE_CONTROLLING_FEHLER: {
            return Object.assign({}, state, <PageState>{
                fbPruefplaeneListe: [],
                fbPruefplaeneListeLaden: false,
                fbPruefplaeneListeFehler: true,
            });
        }

        /* Saison-Filter */
        case GET_PRUEFPLAN_SAISON_FILTER: {
            return Object.assign({}, state, <PageState>{
                saisonFilterOptionsLaden: true,
                saisonFilterOptionsFehler: false
            });
        }

        case GET_PRUEFPLAN_SAISON_FILTER_ERFOLGREICH: {
            const a = <GetPruefplanSaisonFilterErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                saisonFilterOptions: a.payload,
                saisonFilterOptionsLaden: false,
                saisonFilterOptionsFehler: false,
            });
        }

        case GET_PRUEFPLAN_SAISON_FILTER_FEHLER: {
            return Object.assign({}, state, <PageState>{
                saisonFilterOptions: null,
                saisonFilterOptionsLaden: false,
                saisonFilterOptionsFehler: true,
            });
        }

        case AKTUELLEN_PRUEFPLAN_SETZEN: {
            const a = <AktuellenPruefplanSetzenAction>action;

            return Object.assign({}, state, <PageState>{
                pruefplanSelected: a.payload
            });
        }

        default: {
            return state;
        }
    }
}

