import {Action, ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import {LizenzMandantOptions, CustomUrlMandantOptions, MandantInfo, MandantStammOptions} from '@orga-app/clients/api';
import {
    GET_MANDANT_CLIENT_CONFIG,
    GET_MANDANT_CLIENT_CONFIG_ERFOLGREICH,
    GET_MANDANT_CLIENT_CONFIG_FEHLER,
    GET_MEINE_MANDANTEN,
    GET_MEINE_MANDANTEN_ERFOLGREICH,
    GET_MEINE_MANDANTEN_FEHLER,
    GetMandantClientConfigAction,
    GetMandantClientConfigErfolgreichAction,
    GetMandantClientConfigFehlerAction,
    GetMeineMandantenAction,
    GetMeineMandantenErfolgreichAction,
    GetMeineMandantenFehlerAction
} from '../../client-ngrx/api';
import {MANDANT_SETZEN, MandantSetzenAction} from './actions';
import {MandantenConfigTypes, IncidentsConfigTypes, KommunikationConfigTypes, PandemieConfigTypes, VeranstaltungConfigTypes, PandemieConstants, IncidentsConstants, EinlasskontrolleConstants, DisplaysteuerungConstants, QsChecksConstants, MandantenConstants} from '../../client-constants';
import {TroubleTicketMandantOptions} from '@orga-app/clients/kommunikation';
import VERANSTALTUNGEN_MANDANT_OPTIONS = VeranstaltungConfigTypes.VERANSTALTUNGEN_MANDANT_OPTIONS;
import TROUBLE_TICKET_MANDANT_OPTIONS = KommunikationConfigTypes.TROUBLE_TICKET_MANDANT_OPTIONS;
import SMTP_MANDANT_OPTIONS = KommunikationConfigTypes.SMTP_MANDANT_OPTIONS;
import AZURE_AD_MANDANT_OPTIONS = MandantenConfigTypes.AZURE_AD_MANDANT_OPTIONS;
import LIZENZ_MANDANT_OPTIONS = MandantenConfigTypes.LIZENZ_MANDANT_OPTIONS;
import MANDANT_STAMM_OPTIONS = MandantenConfigTypes.MANDANT_STAMM_OPTIONS;
import MANDANT_CUSTOM_URL_OPTIONS = MandantenConfigTypes.CUSTOM_URL_MANDANT_OPTIONS;
import {PandemieMandantOptions} from '@orga-app/clients/pandemie';
import {APP_RESET_ACTION} from '@orga-app/appstart/actions';
import {Benutzer} from 'src/clients/benutzerverwaltung';
import {GetBenutzerInfoErfolgreichAction, GET_BENUTZER_INFO_ERFOLGREICH} from 'src/client-ngrx/benutzerverwaltung';
import {IncidentsAnzeigeMandantOptions} from '../../clients/incidents';
import PANDEMIE_MANDANT_OPTIONS = PandemieConfigTypes.PANDEMIE_MANDANT_OPTIONS;
import INCIDENTS_ANZEIGE_MANDANT_OPTIONS = IncidentsConfigTypes.INCIDENTS_ANZEIGE_MANDANT_OPTIONS;
import PANDEMIE_LIZENZ_MODUL = PandemieConstants.PANDEMIE_LIZENZ_MODUL;
import INCIDENTS_LIZENZ_MODUL = IncidentsConstants.INCIDENTS_LIZENZ_MODUL;
import EINLASSKONTROLLE_LIZENZ_MODUL = EinlasskontrolleConstants.EINLASSKONTROLLE_LIZENZ_MODUL;
import DISPLAYSTEUERUNG_LIZENZ_MODUL = DisplaysteuerungConstants.DISPLAYSTEUERUNG_LIZENZ_MODUL;
import QS_CHECKS_LIZENZ_MODUL = QsChecksConstants.QS_CHECKS_LIZENZ_MODUL;
import {ZeitpunktTexte} from '../../clients/vorlagen';
import {GET_ZEITPUNKT_TEXTE, GET_ZEITPUNKT_TEXTE_ERFOLGREICH, GET_ZEITPUNKT_TEXTE_FEHLER, GetZeitpunktTexteErfolgreichAction} from '../../client-ngrx/vorlagen';
import BENUTZER_BERECHTIGUNGEN = MandantenConstants.BENUTZER_BERECHTIGUNGEN;

export const MandantFeature = 'mandant';


export interface ModuleState {
    mandant1: MandantState;
}

export const reducers: ActionReducerMap<ModuleState> = {
    mandant1: mandantReducer
};

export interface MandantState {
    mandanten: Array<MandantInfo>;
    mandantenLaden: boolean;
    mandantenFehler: boolean;

    mandant: MandantInfo;

    mandantClientConfig: any;
    mandantClientConfigLaden: boolean;
    mandantClientConfigFehler: boolean;

    benutzer: Benutzer;

    zeitpunktTexte: ZeitpunktTexte;
    zeitpunktTexteLaden: boolean;
    zeitpunktTexteFehler: boolean;
}

export const initialPageState: MandantState = {
    mandanten: [],
    mandantenLaden: false,
    mandantenFehler: false,

    mandant: null,

    mandantClientConfig: null,
    mandantClientConfigLaden: false,
    mandantClientConfigFehler: false,
    benutzer: null,

    zeitpunktTexte: null,
    zeitpunktTexteLaden: false,
    zeitpunktTexteFehler: false

};

export function mandantReducer(state = initialPageState, action: Action): MandantState {
    switch (action.type) {
        // APP RESET
        case APP_RESET_ACTION: {
            return Object.assign({}, state, initialPageState);
        }

        // Mandant setzen
        case MANDANT_SETZEN: {
            const a = <MandantSetzenAction>action;

            return Object.assign({}, state, <MandantState>{mandant: a.mandant});
        }

        // Meine Mandanten
        case GET_MEINE_MANDANTEN: {
            const a = <GetMeineMandantenAction>action;
            return Object.assign({}, state, <MandantState>{
                mandantenLaden: true
            });
        }
        case GET_MEINE_MANDANTEN_ERFOLGREICH: {
            const a = <GetMeineMandantenErfolgreichAction>action;

            return Object.assign({}, state, <MandantState>{
                mandanten: a.payload,
                mandantenLaden: false,
                mandantenFehler: false
            });
        }
        case GET_MEINE_MANDANTEN_FEHLER: {
            const a = <GetMeineMandantenFehlerAction>action;

            return Object.assign({}, state, <MandantState>{
                mandantenLaden: false,
                mandantenFehler: true
            });
        }

        // Mandant Connfig
        case GET_MANDANT_CLIENT_CONFIG: {
            const a = <GetMandantClientConfigAction>action;

            return Object.assign({}, state, <MandantState>{
                mandantClientConfigLaden: true
            });
        }

        case GET_MANDANT_CLIENT_CONFIG_ERFOLGREICH: {
            const a = <GetMandantClientConfigErfolgreichAction>action;

            return Object.assign({}, state, <MandantState>{
                mandantClientConfig: a.payload,
                mandantClientConfigLaden: false,
                mandantClientConfigFehler: false
            });
        }

        case GET_MANDANT_CLIENT_CONFIG_FEHLER: {
            const a = <GetMandantClientConfigFehlerAction>action;

            return Object.assign({}, state, <MandantState>{
                mandantClientConfig: a.optPayload,
                mandantClientConfigLaden: false,
                mandantClientConfigFehler: true
            });
        }

        // Zeitpunkt Texte
        case GET_ZEITPUNKT_TEXTE: {
            return Object.assign({}, state, <MandantState>{
                zeitpunktTexteLaden: true
            });
        }

        case GET_ZEITPUNKT_TEXTE_ERFOLGREICH: {
            const a = <GetZeitpunktTexteErfolgreichAction>action;

            return Object.assign({}, state, <MandantState>{
                zeitpunktTexte: a.payload,
                zeitpunktTexteLaden: false,
                zeitpunktTexteFehler: false
            });
        }

        case GET_ZEITPUNKT_TEXTE_FEHLER: {
            return Object.assign({}, state, <MandantState>{
                zeitpunktTexteLaden: false,
                zeitpunktTexteFehler: true
            });
        }


        case GET_BENUTZER_INFO_ERFOLGREICH: {
            const a = <GetBenutzerInfoErfolgreichAction>action;

            // console.log("GET_BENUTZER_INFO_ERFOLGREICH", a.payload);

            return Object.assign({}, state, <MandantState>{
                benutzer: a.payload
            });
        }

        default: {
            return state;
        }
    }
}


export const getModuleState = createFeatureSelector<ModuleState>(MandantFeature);

export const getPageState = createSelector(getModuleState, (state: ModuleState) => state.mandant1);

export const getMandanten = createSelector(getPageState, (state: MandantState) => state.mandanten);

export const getMandant = createSelector(getPageState, (state: MandantState) => state.mandant);


// ApiConfigTypes

export const getAzureAdMandantOptions = createSelector(getPageState, (state: MandantState) => {
    if (state.mandantClientConfig === null || state.mandantClientConfig[AZURE_AD_MANDANT_OPTIONS] === undefined) {
        return null;
    }

    return state.mandantClientConfig[AZURE_AD_MANDANT_OPTIONS];
});

export const getLizenzMandantOptions = createSelector(getPageState, (state: MandantState): LizenzMandantOptions => {
    if (state.mandantClientConfig === null || state.mandantClientConfig[LIZENZ_MANDANT_OPTIONS] === undefined) {
        return null;
    }

    return state.mandantClientConfig[LIZENZ_MANDANT_OPTIONS];
});

export const getIstPandemieLizenzAktiv = createSelector(getLizenzMandantOptions, (lizenz: LizenzMandantOptions): boolean => {
    // console.log('Lizenz: ', lizenz);
    if (lizenz === null || lizenz === undefined) {
        return false;
    }
    return lizenz.module.findIndex(x => x.name.toUpperCase() === PANDEMIE_LIZENZ_MODUL.toUpperCase()) > -1;
});


export const getIstAufgabenBerechtigungenLizenzAktiv = createSelector(getLizenzMandantOptions, (lizenz: LizenzMandantOptions): boolean => {
    // console.log('Lizenz: ', lizenz);
    if (lizenz === null || lizenz === undefined) {
        return false;
    }

    return lizenz.module.findIndex(x => x.name.toUpperCase() === BENUTZER_BERECHTIGUNGEN.toUpperCase()) > -1;
});
export const getPandemieMandantOptions = createSelector(getPageState, (state: MandantState): PandemieMandantOptions => {
    if (state.mandantClientConfig === null || state.mandantClientConfig[PANDEMIE_MANDANT_OPTIONS] === undefined) {
        return null;
    }

    return state.mandantClientConfig[PANDEMIE_MANDANT_OPTIONS];
});

export const getIstIncidentsLizenzAktiv = createSelector(getLizenzMandantOptions, (lizenz: LizenzMandantOptions): boolean => {
    // console.log('Lizenz: ', lizenz);
    if (lizenz === null || lizenz === undefined) {
        return false;
    }

    return lizenz.module.findIndex(x => x.name.toUpperCase() === INCIDENTS_LIZENZ_MODUL.toUpperCase()) > -1;
});

export const getIstEinlasskontrolleLizenzAktiv = createSelector(getLizenzMandantOptions, (lizenz: LizenzMandantOptions): boolean => {
    if (lizenz === null || lizenz === undefined) {
        return false;
    }

    return lizenz.module.findIndex(x => x.name.toUpperCase() === EINLASSKONTROLLE_LIZENZ_MODUL.toUpperCase()) > -1;
});

export const getIstDisplaysteuerungLizenzAktiv = createSelector(getLizenzMandantOptions, (lizenz: LizenzMandantOptions): boolean => {
    if (lizenz === null || lizenz === undefined) {
        return false;
    }

    return lizenz.module.findIndex(x => x.name.toUpperCase() === DISPLAYSTEUERUNG_LIZENZ_MODUL.toUpperCase()) > -1;
});

export const getIstQSChecksLizenzAktiv = createSelector(getLizenzMandantOptions, (lizenz: LizenzMandantOptions): boolean => {
    if (lizenz === null || lizenz === undefined) {
        return false;
    }

    return lizenz.module.findIndex(x => x.name.toUpperCase() === QS_CHECKS_LIZENZ_MODUL.toUpperCase()) > -1;
});

export const getIncidentsMandantOptions = createSelector(getPageState, (state: MandantState): IncidentsAnzeigeMandantOptions => {
    if (state.mandantClientConfig === null || state.mandantClientConfig[INCIDENTS_ANZEIGE_MANDANT_OPTIONS] === undefined) {
        return null;
    }

    return state.mandantClientConfig[INCIDENTS_ANZEIGE_MANDANT_OPTIONS];
});

export const getMandantStammOptions = createSelector(getPageState, (state: MandantState) => {
    if (state.mandantClientConfig === null || state.mandantClientConfig[MANDANT_STAMM_OPTIONS] === undefined) {
        return null;
    }

    return <MandantStammOptions>state.mandantClientConfig[MANDANT_STAMM_OPTIONS];
});

/*
export const getMandantVeranstaltungTypOptions = createSelector(getMandantVeranstaltungTypKonfigurationOptions, (options: VeranstaltungTypKonfigurationMandantOptions) => {
    if (options === null) {
        return null;
    }

    return <VeranstaltungTypDefinitionenMandantOptions> state.mandantClientConfig[VERANSTALTUNG_TYP_DEFINITION_OPTIONS];
});
*/


// VeranstaltungConfigTypes

export const getVeranstaltungenMandantOptions = createSelector(getPageState, (state: MandantState) => {
    if (state.mandantClientConfig === null || state.mandantClientConfig[VERANSTALTUNGEN_MANDANT_OPTIONS] === undefined) {
        return null;
    }

    return state.mandantClientConfig[VERANSTALTUNGEN_MANDANT_OPTIONS];
});


/*export const getVeranstaltungTypMitteilungenMandantOptions = createSelector(getPageState, (state: MandantState) => {
    if (state.mandantClientConfig === null || state.mandantClientConfig[VERANSTALTUNG_TYP_MITTEILUNGEN_OPTIONS] === undefined) {
        return null;
    }

    return <VeranstaltungTypMitteilungenMandantOptions> state.mandantClientConfig[VERANSTALTUNG_TYP_MITTEILUNGEN_OPTIONS];
});*/


// KommunikationConfigTypes

export const getSMTPMandantOptions = createSelector(getPageState, (state: MandantState) => {

    if (state.mandantClientConfig === null || state.mandantClientConfig[SMTP_MANDANT_OPTIONS] === undefined) {
        return null;
    }

    return state.mandantClientConfig[SMTP_MANDANT_OPTIONS];
});

export const getTroubleTicketMandantOptions = createSelector(getPageState, (state: MandantState) => {
    if (state.mandantClientConfig === null || state.mandantClientConfig[TROUBLE_TICKET_MANDANT_OPTIONS] === undefined) {
        return null;
    }

    return <TroubleTicketMandantOptions>state.mandantClientConfig[TROUBLE_TICKET_MANDANT_OPTIONS];
});

// Get Mandant Custom Url Option
export const getMandantCustomUrlOption = createSelector(getPageState, (state: MandantState) => {
    if (state.mandantClientConfig === null || state.mandantClientConfig[MANDANT_CUSTOM_URL_OPTIONS] === undefined) {
        return null;
    }

    return state.mandantClientConfig[MANDANT_CUSTOM_URL_OPTIONS];
});

// Get all infos for Interceptor
export const getInterceptorInfos = createSelector(getMandant, getMandantCustomUrlOption, (mandant: MandantInfo, mandantCustomUrlOptions: CustomUrlMandantOptions) => {
    return {
        mandant,
        mandantCustomUrlOptions
    };
});


export const getBenutzer = createSelector(getPageState, (state: MandantState) => state.benutzer);

export const getZeitpunktTexte = createSelector(getPageState, (state: MandantState) => state.zeitpunktTexte);
export const getZeitpunktTexteLaden = createSelector(getPageState, (state: MandantState) => state.zeitpunktTexteLaden);
export const getZeitpunktTexteFehler = createSelector(getPageState, (state: MandantState) => state.zeitpunktTexteFehler);
