import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGS_TYPEN = '[VeranstaltungstypenModul] GetVeranstaltungsTypen laden';
export const GET_VERANSTALTUNGS_TYPEN_AUSFUEHREN = '[VeranstaltungstypenModul] GetVeranstaltungsTypen ausführen';
export const GET_VERANSTALTUNGS_TYPEN_ERFOLGREICH = '[VeranstaltungstypenModul] GetVeranstaltungsTypen laden erfolgreich';
export const GET_VERANSTALTUNGS_TYPEN_FEHLER = '[VeranstaltungstypenModul] GetVeranstaltungsTypen Ladefehler';
export const GET_VERANSTALTUNGS_TYPEN_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] GetVeranstaltungsTypen nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungsTypenAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_ERFOLGREICH;
        constructor(public payload: Array<api.VeranstaltungsTyp>, public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenQueryVeranstaltungsTypenActions =
    GetVeranstaltungsTypenAction
    | GetVeranstaltungsTypenAusfuehrenAction
    | GetVeranstaltungsTypenErfolgreichAction
    | GetVeranstaltungsTypenFehlerAction
    | GetVeranstaltungsTypenNichtAusgefuehrtAction
    ;
