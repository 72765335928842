import {appConfigDev, aadDev, IEnvironment, mobileDev, versions} from '@orga-app/env';

export const environment = <IEnvironment>{
  production: false,
  version: versions.version,

  loginRoute: '/login',
  homeRoute: '/home',
  debug: true,
  testMode: true,
  appTitle: 'DEV',

  appConfig: appConfigDev,
  azureAd: aadDev,
  mobile: mobileDev
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
