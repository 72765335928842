import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_QS_CHECKS_ROLLE_BERECHTIGUNG = '[QschecksModul] GetQsChecksRolleBerechtigung laden';
export const GET_QS_CHECKS_ROLLE_BERECHTIGUNG_AUSFUEHREN = '[QschecksModul] GetQsChecksRolleBerechtigung ausführen';
export const GET_QS_CHECKS_ROLLE_BERECHTIGUNG_ERFOLGREICH = '[QschecksModul] GetQsChecksRolleBerechtigung laden erfolgreich';
export const GET_QS_CHECKS_ROLLE_BERECHTIGUNG_FEHLER = '[QschecksModul] GetQsChecksRolleBerechtigung Ladefehler';
export const GET_QS_CHECKS_ROLLE_BERECHTIGUNG_NICHT_AUSGEFUEHRT = '[QschecksModul] GetQsChecksRolleBerechtigung nicht ausgeführt (evtl. Offline)';



export class GetQsChecksRolleBerechtigungAction implements Action {
    readonly type = GET_QS_CHECKS_ROLLE_BERECHTIGUNG;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetQsChecksRolleBerechtigungAusfuehrenAction implements Action {
    readonly type = GET_QS_CHECKS_ROLLE_BERECHTIGUNG_AUSFUEHREN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetQsChecksRolleBerechtigungErfolgreichAction implements Action {
    readonly type = GET_QS_CHECKS_ROLLE_BERECHTIGUNG_ERFOLGREICH;
        constructor(public payload: api.QsChecksRolleBerechtigung,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetQsChecksRolleBerechtigungFehlerAction implements Action {
    readonly type = GET_QS_CHECKS_ROLLE_BERECHTIGUNG_FEHLER;
        constructor(public payload: any,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetQsChecksRolleBerechtigungNichtAusgefuehrtAction implements Action {
    readonly type = GET_QS_CHECKS_ROLLE_BERECHTIGUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryQsChecksRolleBerechtigungActions =
    GetQsChecksRolleBerechtigungAction
    | GetQsChecksRolleBerechtigungAusfuehrenAction
    | GetQsChecksRolleBerechtigungErfolgreichAction
    | GetQsChecksRolleBerechtigungFehlerAction
    | GetQsChecksRolleBerechtigungNichtAusgefuehrtAction
    ;
