import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGEN_BEENDETE_ADMIN = '[VeranstaltungenModul] GetVeranstaltungenBeendeteAdmin laden';
export const GET_VERANSTALTUNGEN_BEENDETE_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungenBeendeteAdmin ausführen';
export const GET_VERANSTALTUNGEN_BEENDETE_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungenBeendeteAdmin laden erfolgreich';
export const GET_VERANSTALTUNGEN_BEENDETE_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungenBeendeteAdmin Ladefehler';
export const GET_VERANSTALTUNGEN_BEENDETE_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungenBeendeteAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungenBeendeteAdminAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_BEENDETE_ADMIN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenBeendeteAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_BEENDETE_ADMIN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenBeendeteAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_BEENDETE_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.VeranstaltungForAdmin>, public optPayload: any = null) {}
    }
export class GetVeranstaltungenBeendeteAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_BEENDETE_ADMIN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungenBeendeteAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_BEENDETE_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungenBeendeteAdminActions =
    GetVeranstaltungenBeendeteAdminAction
    | GetVeranstaltungenBeendeteAdminAusfuehrenAction
    | GetVeranstaltungenBeendeteAdminErfolgreichAction
    | GetVeranstaltungenBeendeteAdminFehlerAction
    | GetVeranstaltungenBeendeteAdminNichtAusgefuehrtAction
    ;
