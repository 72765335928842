import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN = '[VeranstaltungenModul] GetVeranstaltungBenutzerListeAdmin laden';
export const GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungBenutzerListeAdmin ausführen';
export const GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungBenutzerListeAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungBenutzerListeAdmin Ladefehler';
export const GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungBenutzerListeAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungBenutzerListeAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBenutzerListeAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBenutzerListeAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.Benutzer>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBenutzerListeAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBenutzerListeAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungBenutzerListeAdminActions =
    GetVeranstaltungBenutzerListeAdminAction
    | GetVeranstaltungBenutzerListeAdminAusfuehrenAction
    | GetVeranstaltungBenutzerListeAdminErfolgreichAction
    | GetVeranstaltungBenutzerListeAdminFehlerAction
    | GetVeranstaltungBenutzerListeAdminNichtAusgefuehrtAction
    ;
