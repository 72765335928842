import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_ANLEGEN = '[IncidentsModul] PostIncidentAnlegen laden';
export const POST_INCIDENT_ANLEGEN_AUSFUEHREN = '[IncidentsModul] PostIncidentAnlegen ausführen';
export const POST_INCIDENT_ANLEGEN_ERFOLGREICH = '[IncidentsModul] PostIncidentAnlegen laden erfolgreich';
export const POST_INCIDENT_ANLEGEN_FEHLER = '[IncidentsModul] PostIncidentAnlegen Ladefehler';
export const POST_INCIDENT_ANLEGEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentAnlegen nicht ausgeführt (evtl. Offline)';



export class PostIncidentAnlegenAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN;
        constructor(public incidentBeschreibungRequest: api.IncidentBeschreibungRequest, public optPayload: any = null) {}
    }
export class PostIncidentAnlegenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN_AUSFUEHREN;
        constructor(public incidentBeschreibungRequest: api.IncidentBeschreibungRequest, public optPayload: any = null) {}
    }
export class PostIncidentAnlegenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentBeschreibungRequest: api.IncidentBeschreibungRequest, public optPayload: any = null) {}
    }
export class PostIncidentAnlegenFehlerAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN_FEHLER;
        constructor(public payload: any, public incidentBeschreibungRequest: api.IncidentBeschreibungRequest, public optPayload: any = null) {}
    }
export class PostIncidentAnlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentAnlegenActions =
    PostIncidentAnlegenAction
    | PostIncidentAnlegenAusfuehrenAction
    | PostIncidentAnlegenErfolgreichAction
    | PostIncidentAnlegenFehlerAction
    | PostIncidentAnlegenNichtAusgefuehrtAction
    ;
