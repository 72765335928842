import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const GET_DISPLAYSTEUERUNG_CONFIG_TYPES = '[DisplaysteuerungModul] GetDisplaysteuerungConfigTypes laden';
export const GET_DISPLAYSTEUERUNG_CONFIG_TYPES_AUSFUEHREN = '[DisplaysteuerungModul] GetDisplaysteuerungConfigTypes ausführen';
export const GET_DISPLAYSTEUERUNG_CONFIG_TYPES_ERFOLGREICH = '[DisplaysteuerungModul] GetDisplaysteuerungConfigTypes laden erfolgreich';
export const GET_DISPLAYSTEUERUNG_CONFIG_TYPES_FEHLER = '[DisplaysteuerungModul] GetDisplaysteuerungConfigTypes Ladefehler';
export const GET_DISPLAYSTEUERUNG_CONFIG_TYPES_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] GetDisplaysteuerungConfigTypes nicht ausgeführt (evtl. Offline)';



export class GetDisplaysteuerungConfigTypesAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_CONFIG_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetDisplaysteuerungConfigTypesAusfuehrenAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_CONFIG_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetDisplaysteuerungConfigTypesErfolgreichAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_CONFIG_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetDisplaysteuerungConfigTypesFehlerAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_CONFIG_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetDisplaysteuerungConfigTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_CONFIG_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungQueryDisplaysteuerungConfigTypesActions =
    GetDisplaysteuerungConfigTypesAction
    | GetDisplaysteuerungConfigTypesAusfuehrenAction
    | GetDisplaysteuerungConfigTypesErfolgreichAction
    | GetDisplaysteuerungConfigTypesFehlerAction
    | GetDisplaysteuerungConfigTypesNichtAusgefuehrtAction
    ;
