import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.command.veranstaltung-aufgabe-erinnerung-versenden';
import * as api from '../../../clients/veranstaltungen';


    @Injectable()
    export class VeranstaltungenVeranstaltungAufgabeErinnerungVersendenEffects {

        @Effect()
            VeranstaltungAufgabeErinnerungVersenden$ = this.actions$.pipe(
                ofType(ac.POST_VERANSTALTUNG_AUFGABE_ERINNERUNG_VERSENDEN),
                flatMap((x: ac.PostVeranstaltungAufgabeErinnerungVersendenAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().postVeranstaltungAufgabeErinnerungVersenden(x.veranstaltungRequest).pipe(
                    map((result: any) => {
                        const nextAction = new ac.PostVeranstaltungAufgabeErinnerungVersendenErfolgreichAction(result, x.veranstaltungRequest,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.PostVeranstaltungAufgabeErinnerungVersendenFehlerAction(error, x.veranstaltungRequest,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.VeranstaltungenCommandService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.VeranstaltungenCommandService {
                if (this.service === null) {
                    this.service = this.injector.get(api.VeranstaltungenCommandService);
                }
                return this.service;
            }
        
}
