/**
 * orga app | pandemie
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ConstantTypeInfo } from '../model/constantTypeInfo';
import { EntityTypeInfo } from '../model/entityTypeInfo';
import { PandemieMandantOptions } from '../model/pandemieMandantOptions';
import { PandemieVeranstaltungTypKonfigurationMandantOptions } from '../model/pandemieVeranstaltungTypKonfigurationMandantOptions';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PandemieTemplatesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetPandemieConfigTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPandemieConfigTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<ConstantTypeInfo>>;
    public getPandemieConfigTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ConstantTypeInfo>>>;
    public getPandemieConfigTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ConstantTypeInfo>>>;
    public getPandemieConfigTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ConstantTypeInfo>>('get',`${this.basePath}/api/pandemie/templates/configtypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPandemieConstants
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPandemieConstants(observe?: 'body', reportProgress?: boolean): Observable<Array<ConstantTypeInfo>>;
    public getPandemieConstants(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ConstantTypeInfo>>>;
    public getPandemieConstants(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ConstantTypeInfo>>>;
    public getPandemieConstants(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ConstantTypeInfo>>('get',`${this.basePath}/api/pandemie/templates/constants`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPandemieEntityTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPandemieEntityTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<EntityTypeInfo>>;
    public getPandemieEntityTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EntityTypeInfo>>>;
    public getPandemieEntityTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EntityTypeInfo>>>;
    public getPandemieEntityTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<EntityTypeInfo>>('get',`${this.basePath}/api/pandemie/templates/entitytypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPandemieMandantOptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPandemieMandantOptions(observe?: 'body', reportProgress?: boolean): Observable<PandemieMandantOptions>;
    public getPandemieMandantOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PandemieMandantOptions>>;
    public getPandemieMandantOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PandemieMandantOptions>>;
    public getPandemieMandantOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PandemieMandantOptions>('get',`${this.basePath}/api/pandemie/templates/config/pandemiemandantoptions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetPandemieVeranstaltungTypKonfigurationMandantOptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPandemieVeranstaltungTypKonfigurationMandantOptions(observe?: 'body', reportProgress?: boolean): Observable<PandemieVeranstaltungTypKonfigurationMandantOptions>;
    public getPandemieVeranstaltungTypKonfigurationMandantOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PandemieVeranstaltungTypKonfigurationMandantOptions>>;
    public getPandemieVeranstaltungTypKonfigurationMandantOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PandemieVeranstaltungTypKonfigurationMandantOptions>>;
    public getPandemieVeranstaltungTypKonfigurationMandantOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PandemieVeranstaltungTypKonfigurationMandantOptions>('get',`${this.basePath}/api/pandemie/templates/config/pandemieveranstaltungtypkonfigurationmandantoptions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
