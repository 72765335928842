/**
 * orga app | incidents
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ConstantTypeInfo } from '../model/constantTypeInfo';
import { EntityTypeInfo } from '../model/entityTypeInfo';
import { IncidentAbteilungNotification } from '../model/incidentAbteilungNotification';
import { IncidentDelegationNotification } from '../model/incidentDelegationNotification';
import { IncidentIdentifier } from '../model/incidentIdentifier';
import { IncidentZentraleNotification } from '../model/incidentZentraleNotification';
import { IncidentsAnzeigeMandantOptions } from '../model/incidentsAnzeigeMandantOptions';
import { NotificationMessageTypeInfo } from '../model/notificationMessageTypeInfo';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class IncidentsTemplatesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetIncidentAbteilungNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentAbteilungNotification(observe?: 'body', reportProgress?: boolean): Observable<IncidentAbteilungNotification>;
    public getIncidentAbteilungNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IncidentAbteilungNotification>>;
    public getIncidentAbteilungNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IncidentAbteilungNotification>>;
    public getIncidentAbteilungNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IncidentAbteilungNotification>('get',`${this.basePath}/api/incidents/templates/notifications/abteilung`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentDelegationNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentDelegationNotification(observe?: 'body', reportProgress?: boolean): Observable<IncidentDelegationNotification>;
    public getIncidentDelegationNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IncidentDelegationNotification>>;
    public getIncidentDelegationNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IncidentDelegationNotification>>;
    public getIncidentDelegationNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IncidentDelegationNotification>('get',`${this.basePath}/api/incidents/templates/notifications/delegation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentIdentifierTemplate
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentIdentifierTemplate(observe?: 'body', reportProgress?: boolean): Observable<IncidentIdentifier>;
    public getIncidentIdentifierTemplate(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IncidentIdentifier>>;
    public getIncidentIdentifierTemplate(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IncidentIdentifier>>;
    public getIncidentIdentifierTemplate(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IncidentIdentifier>('get',`${this.basePath}/api/incidents/templates/incidentidentifier`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentZentraleNotification
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentZentraleNotification(observe?: 'body', reportProgress?: boolean): Observable<IncidentZentraleNotification>;
    public getIncidentZentraleNotification(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IncidentZentraleNotification>>;
    public getIncidentZentraleNotification(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IncidentZentraleNotification>>;
    public getIncidentZentraleNotification(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IncidentZentraleNotification>('get',`${this.basePath}/api/incidents/templates/notifications/zentrale`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentsAnzeigeMandantOptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentsAnzeigeMandantOptions(observe?: 'body', reportProgress?: boolean): Observable<IncidentsAnzeigeMandantOptions>;
    public getIncidentsAnzeigeMandantOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IncidentsAnzeigeMandantOptions>>;
    public getIncidentsAnzeigeMandantOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IncidentsAnzeigeMandantOptions>>;
    public getIncidentsAnzeigeMandantOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<IncidentsAnzeigeMandantOptions>('get',`${this.basePath}/api/incidents/templates/config/incidentsanzeigemandantoptions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentsConfigTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentsConfigTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<ConstantTypeInfo>>;
    public getIncidentsConfigTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ConstantTypeInfo>>>;
    public getIncidentsConfigTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ConstantTypeInfo>>>;
    public getIncidentsConfigTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ConstantTypeInfo>>('get',`${this.basePath}/api/incidents/templates/configtypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentsConstants
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentsConstants(observe?: 'body', reportProgress?: boolean): Observable<Array<ConstantTypeInfo>>;
    public getIncidentsConstants(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ConstantTypeInfo>>>;
    public getIncidentsConstants(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ConstantTypeInfo>>>;
    public getIncidentsConstants(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ConstantTypeInfo>>('get',`${this.basePath}/api/incidents/templates/constants`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetIncidentsEntityTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIncidentsEntityTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<EntityTypeInfo>>;
    public getIncidentsEntityTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EntityTypeInfo>>>;
    public getIncidentsEntityTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EntityTypeInfo>>>;
    public getIncidentsEntityTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<EntityTypeInfo>>('get',`${this.basePath}/api/incidents/templates/entitytypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetNotificationTypes
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNotificationTypes(observe?: 'body', reportProgress?: boolean): Observable<Array<NotificationMessageTypeInfo>>;
    public getNotificationTypes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotificationMessageTypeInfo>>>;
    public getNotificationTypes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotificationMessageTypeInfo>>>;
    public getNotificationTypes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<NotificationMessageTypeInfo>>('get',`${this.basePath}/api/incidents/templates/notificationtypes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
