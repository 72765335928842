import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENTS_ROLLE_BERECHTIGUNGEN_SETZEN = '[IncidentsModul] PostIncidentsRolleBerechtigungenSetzen laden';
export const POST_INCIDENTS_ROLLE_BERECHTIGUNGEN_SETZEN_AUSFUEHREN = '[IncidentsModul] PostIncidentsRolleBerechtigungenSetzen ausführen';
export const POST_INCIDENTS_ROLLE_BERECHTIGUNGEN_SETZEN_ERFOLGREICH = '[IncidentsModul] PostIncidentsRolleBerechtigungenSetzen laden erfolgreich';
export const POST_INCIDENTS_ROLLE_BERECHTIGUNGEN_SETZEN_FEHLER = '[IncidentsModul] PostIncidentsRolleBerechtigungenSetzen Ladefehler';
export const POST_INCIDENTS_ROLLE_BERECHTIGUNGEN_SETZEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentsRolleBerechtigungenSetzen nicht ausgeführt (evtl. Offline)';



export class PostIncidentsRolleBerechtigungenSetzenAction implements Action {
    readonly type = POST_INCIDENTS_ROLLE_BERECHTIGUNGEN_SETZEN;
        constructor(public incidentsRolleBerechtigungenRequest: api.IncidentsRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostIncidentsRolleBerechtigungenSetzenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENTS_ROLLE_BERECHTIGUNGEN_SETZEN_AUSFUEHREN;
        constructor(public incidentsRolleBerechtigungenRequest: api.IncidentsRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostIncidentsRolleBerechtigungenSetzenErfolgreichAction implements Action {
    readonly type = POST_INCIDENTS_ROLLE_BERECHTIGUNGEN_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentsRolleBerechtigungenRequest: api.IncidentsRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostIncidentsRolleBerechtigungenSetzenFehlerAction implements Action {
    readonly type = POST_INCIDENTS_ROLLE_BERECHTIGUNGEN_SETZEN_FEHLER;
        constructor(public payload: any, public incidentsRolleBerechtigungenRequest: api.IncidentsRolleBerechtigungenRequest, public optPayload: any = null) {}
    }
export class PostIncidentsRolleBerechtigungenSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENTS_ROLLE_BERECHTIGUNGEN_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentsRolleBerechtigungenSetzenActions =
    PostIncidentsRolleBerechtigungenSetzenAction
    | PostIncidentsRolleBerechtigungenSetzenAusfuehrenAction
    | PostIncidentsRolleBerechtigungenSetzenErfolgreichAction
    | PostIncidentsRolleBerechtigungenSetzenFehlerAction
    | PostIncidentsRolleBerechtigungenSetzenNichtAusgefuehrtAction
    ;
