import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_CHECKLISTE_AKTUALISIEREN = '[VorlagenModul] PostChecklisteAktualisieren laden';
export const POST_CHECKLISTE_AKTUALISIEREN_AUSFUEHREN = '[VorlagenModul] PostChecklisteAktualisieren ausführen';
export const POST_CHECKLISTE_AKTUALISIEREN_ERFOLGREICH = '[VorlagenModul] PostChecklisteAktualisieren laden erfolgreich';
export const POST_CHECKLISTE_AKTUALISIEREN_FEHLER = '[VorlagenModul] PostChecklisteAktualisieren Ladefehler';
export const POST_CHECKLISTE_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostChecklisteAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostChecklisteAktualisierenAction implements Action {
    readonly type = POST_CHECKLISTE_AKTUALISIEREN;
        constructor(public checklisteModelRequest: api.ChecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostChecklisteAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_CHECKLISTE_AKTUALISIEREN_AUSFUEHREN;
        constructor(public checklisteModelRequest: api.ChecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostChecklisteAktualisierenErfolgreichAction implements Action {
    readonly type = POST_CHECKLISTE_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public checklisteModelRequest: api.ChecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostChecklisteAktualisierenFehlerAction implements Action {
    readonly type = POST_CHECKLISTE_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public checklisteModelRequest: api.ChecklisteModelRequest, public optPayload: any = null) {}
    }
export class PostChecklisteAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_CHECKLISTE_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandChecklisteAktualisierenActions =
    PostChecklisteAktualisierenAction
    | PostChecklisteAktualisierenAusfuehrenAction
    | PostChecklisteAktualisierenErfolgreichAction
    | PostChecklisteAktualisierenFehlerAction
    | PostChecklisteAktualisierenNichtAusgefuehrtAction
    ;
