import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {HinweisMeldungPage} from './hinweis-meldung.page';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {SopiComponentsModule} from '../sopi-components/sopi-components.module';

const routes: Routes = [
    {
        path: '',
        component: HinweisMeldungPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        SopiComponentsModule.forRoot(),
        SopiScrollModule
    ],
    declarations: [HinweisMeldungPage],
    entryComponents: [HinweisMeldungPage]
})
export class HinweisMeldungPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: HinweisMeldungPageModule,
            providers: []
        };
    }
}
