import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import * as fromReducer from './qschecks-controlling-saison-filter.reducer';
import {ModalController, NavController, NavParams} from '@ionic/angular';
import * as actionsUi from './qschecks-controlling-saison-filter.actions.ui';
import {select, Store} from '@ngrx/store';
import {Pruefplan, SaisonIdGruppe} from '../../../../clients/qschecks';
import {getFunktionsbereichePruefplaene, getFunktionsbereichePruefplaeneFehler, getFunktionsbereichePruefplaeneLaden, getPrueferrollenListe, getPruefplanSelected, getSaisonFilterOptionsFehler, getSaisonFilterOptionsLaden, getSaisonListe} from './qschecks-controlling-saison-filter.selectors';
import {ListInputItem} from '../../../shared/sopi-list/sopi-list.model';
import {QsFilter} from './qschecks-controlling-saison-filter.model';


@Component({
    selector: 'app-qschecks-controlling-saison-filter',
    templateUrl: './qschecks-controlling-saison-filter.page.html'
})
export class QschecksControllingSaisonFilterPage implements OnInit {
    aiName = 'QschecksControllingSaisonFilterPage';

    fbPruefplaeneListe$: Observable<Array<ListInputItem>>;
    fbPruefplaeneListeLaden$: Observable<boolean>;
    fbPruefplaeneListeFehler$: Observable<boolean>;

    pruefplanSelected$: Observable<Pruefplan>;

    prueferRollenListe$: Observable<Array<ListInputItem>>;
    saisonListe$: Observable<Array<ListInputItem>>;
    saisonOptionsLaden$: Observable<boolean>;
    saisonOptionsFehler$: Observable<boolean>;

    public rollenIds: Array<string>;
    public saison: SaisonIdGruppe;

    constructor(public navCtrl: NavController,
                private modalCtrl: ModalController,
                private store: Store<fromReducer.PageState>) {

    }

    ngOnInit() {
        this.fbPruefplaeneListe$ = this.store.pipe(select(getFunktionsbereichePruefplaene()));
        this.fbPruefplaeneListeLaden$ = this.store.pipe(select(getFunktionsbereichePruefplaeneLaden()));
        this.fbPruefplaeneListeFehler$ = this.store.pipe(select(getFunktionsbereichePruefplaeneFehler()));

        this.pruefplanSelected$ = this.store.pipe(select(getPruefplanSelected()));

        this.prueferRollenListe$ = this.store.pipe(select(getPrueferrollenListe()));
        this.saisonListe$ = this.store.pipe(select(getSaisonListe()));
        this.saisonOptionsLaden$ = this.store.pipe(select(getSaisonFilterOptionsLaden()));
        this.saisonOptionsFehler$ = this.store.pipe(select(getSaisonFilterOptionsFehler()));
    }

    ionViewWillEnter() {
        this.viewWillEnterPage().then();
    }

    async viewWillEnterPage() {
        await this.store.dispatch(new actionsUi.PageStateResetAction());
    }


    async ok(pruefplan: Pruefplan) {
        const data = <QsFilter> {
            pruefplanId: pruefplan.pruefplan_id,
            saisonIds: this.saison.saison_id_liste,
            rollenIds: this.rollenIds
        };
        await this.modalCtrl.dismiss(data);
    }

    async abbrechen() {
        await this.modalCtrl.dismiss();
    }

    async refreshData() {
        await this.store.dispatch(new actionsUi.RefreshDataAction());
    }

    pruefplanAuswaehlen(pruefplan: Pruefplan) {
        this.store.dispatch(new actionsUi.AktuellenPruefplanSetzenAction(pruefplan));
    }

    fbPruefplaeneLaden() {
        this.store.dispatch(new actionsUi.FunktionsbereichPruefplaeneLadenAction());
    }

    rollenAuswaehlen($event) {
        this.rollenIds = $event;
    }

    saisonAuswaehlen($event: SaisonIdGruppe) {
        this.saison = $event;
    }
}
