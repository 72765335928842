import {enableProdMode, LOCALE_ID} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

declare var extendConsole;

if (environment.production) {
    enableProdMode();
    if (window) {
        if (window) {
          extendConsole();
          console['enable']('debug', window['debuggMode']);
          console['enable']('log', window['logMode']);
          console['enable']('info', window['infoMode']);
          console['enable']('warn', window['warnMode']);
          console['enable']('error', window['errorMode']);
        }
    }
}


platformBrowserDynamic().bootstrapModule(AppModule, {providers: [{provide: LOCALE_ID, useValue: 'de-DE'}]})
    .catch(err => console.log(err));
