/**
 * orga app | qschecks
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MessageResponse } from '../model/messageResponse';
import { PrueflaufAufgabeAntwortRequest } from '../model/prueflaufAufgabeAntwortRequest';
import { PrueflaufAufgabeBemerkungRequest } from '../model/prueflaufAufgabeBemerkungRequest';
import { PrueflaufAufgabeBildRequest } from '../model/prueflaufAufgabeBildRequest';
import { PrueflaufBemerkungRequest } from '../model/prueflaufBemerkungRequest';
import { PrueflaufBildRequest } from '../model/prueflaufBildRequest';
import { PrueflaufRequest } from '../model/prueflaufRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PrueflaufCommandService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * PostAufgabeBemerkungSetzen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: qschecks * QsChecks - Prueflauf Berechtigung  | Pruefer | Controller 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAufgabeBemerkungSetzen(body?: PrueflaufAufgabeBemerkungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postAufgabeBemerkungSetzen(body?: PrueflaufAufgabeBemerkungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postAufgabeBemerkungSetzen(body?: PrueflaufAufgabeBemerkungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postAufgabeBemerkungSetzen(body?: PrueflaufAufgabeBemerkungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/prueflauf/aufgabe/bemerkung`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostAufgabeBildEntfernen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: qschecks * QsChecks - Prueflauf Berechtigung  | Pruefer | Controller 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAufgabeBildEntfernen(body?: PrueflaufAufgabeBildRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postAufgabeBildEntfernen(body?: PrueflaufAufgabeBildRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postAufgabeBildEntfernen(body?: PrueflaufAufgabeBildRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postAufgabeBildEntfernen(body?: PrueflaufAufgabeBildRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/prueflauf/aufgabe/bild/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostAufgabeBildHinzufuegen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: qschecks * QsChecks - Prueflauf Berechtigung  | Pruefer 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAufgabeBildHinzufuegen(body?: PrueflaufAufgabeBildRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postAufgabeBildHinzufuegen(body?: PrueflaufAufgabeBildRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postAufgabeBildHinzufuegen(body?: PrueflaufAufgabeBildRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postAufgabeBildHinzufuegen(body?: PrueflaufAufgabeBildRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/prueflauf/aufgabe/bild/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostAufgabePruefen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: qschecks * QsChecks - Prueflauf Berechtigung  | Pruefer 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAufgabePruefen(body?: PrueflaufAufgabeAntwortRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postAufgabePruefen(body?: PrueflaufAufgabeAntwortRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postAufgabePruefen(body?: PrueflaufAufgabeAntwortRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postAufgabePruefen(body?: PrueflaufAufgabeAntwortRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/prueflauf/aufgabe/pruefen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPrueflaufBemerkungSetzen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: qschecks * QsChecks - Prueflauf Berechtigung  | Pruefer 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPrueflaufBemerkungSetzen(body?: PrueflaufBemerkungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPrueflaufBemerkungSetzen(body?: PrueflaufBemerkungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPrueflaufBemerkungSetzen(body?: PrueflaufBemerkungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPrueflaufBemerkungSetzen(body?: PrueflaufBemerkungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/prueflauf/bemerkung`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPrueflaufBildEntfernen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: qschecks * QsChecks - Prueflauf Berechtigung  | Pruefer | Controller 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPrueflaufBildEntfernen(body?: PrueflaufBildRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPrueflaufBildEntfernen(body?: PrueflaufBildRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPrueflaufBildEntfernen(body?: PrueflaufBildRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPrueflaufBildEntfernen(body?: PrueflaufBildRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/prueflauf/bild/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPrueflaufBildHinzufuegen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: qschecks * QsChecks - Prueflauf Berechtigung  | Pruefer 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPrueflaufBildHinzufuegen(body?: PrueflaufBildRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPrueflaufBildHinzufuegen(body?: PrueflaufBildRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPrueflaufBildHinzufuegen(body?: PrueflaufBildRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPrueflaufBildHinzufuegen(body?: PrueflaufBildRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/prueflauf/bild/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostPrueflaufLoeschen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Lizenz: qschecks * QsChecks - Prueflauf Berechtigung  | Pruefer | Controller 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postPrueflaufLoeschen(body?: PrueflaufRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postPrueflaufLoeschen(body?: PrueflaufRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postPrueflaufLoeschen(body?: PrueflaufRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postPrueflaufLoeschen(body?: PrueflaufRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/qschecks/command/prueflauf/loeschen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
