import {Injectable, Injector} from '@angular/core';
import {HubConnectionState} from '@microsoft/signalr';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {WEBSOCKET_REFRESH_ACTION, WEBSOCKET_SET_MANDANT_ACTION, WEBSOCKET_SET_TOKEN_ACTION, WEBSOCKET_SET_TOKEN_AND_START_ACTION, WEBSOCKET_START_ACTION, WEBSOCKET_STOP_ACTION, WebsocketRefreshAction, WebsocketSetMandantAction, WebsocketSetTokenAction, WebsocketSetTokenAndStartAction, WebsocketStartAction, WebsocketStartErfolgreichAction, WebsocketStopAction} from './actions';
import {WebsocketService} from './websocket.service';


@Injectable()
export class WebsocketEffects {

    @Effect()
    websocketTokenAndStart$: Observable<Action> = this.actions$.pipe(
        ofType<WebsocketSetTokenAndStartAction>(WEBSOCKET_SET_TOKEN_AND_START_ACTION)
        , map((x: WebsocketSetTokenAndStartAction) => {
            console.debug('WebsocketEffects :: websocketTokenAndStart$');


            const ws = this.getWebsocketService();

            ws.stop().then(() => {
                ws.start().then(() => {
                });
            });

            return new WebsocketStartErfolgreichAction();
        })
    );

    @Effect()
    websocketRefresh$: Observable<Action> = this.actions$.pipe(
        ofType<WebsocketRefreshAction>(WEBSOCKET_REFRESH_ACTION)
        , map((x: WebsocketRefreshAction) => {
            console.debug('WebsocketEffects :: WebsocketRefreshAction$');

            const ws = this.getWebsocketService();

            if ((ws.connectionState === HubConnectionState.Disconnected)) {
                console.debug('WebsocketEffects :: WebsocketRefreshAction$: Changed Access Token');
                ws.stop().then(() => {
                    ws.start().then(() => {
                        if (x.mandant_id && x.mandant_id.length > 0) {
                            ws.registerMandant(x.mandant_id);
                        }
                    });
                });
            } else {
                console.debug('WebsocketEffects :: WebsocketRefreshAction$: Same Access Token - no change');

                if (ws.connectionState !== HubConnectionState.Connected) {
                    ws.stop().then(() => {
                        ws.start().then(() => {
                            if (x.mandant_id && x.mandant_id.length > 0) {
                                ws.registerMandant(x.mandant_id);
                            }
                        });
                    });
                }

                if (ws.mandantId !== x.mandant_id) {
                    console.debug('WebsocketEffects :: WebsocketRefreshAction$: Register Mandant');
                    ws.registerMandant(x.mandant_id);
                }

            }

            return new WebsocketStartErfolgreichAction();
        }));


    @Effect()
    websocketStart$: Observable<Action> = this.actions$.pipe(
        ofType<WebsocketStartAction>(WEBSOCKET_START_ACTION)
        , map((x) => {
            console.debug('WebsocketEffects :: websocketStart$');
            this.getWebsocketService().start();

            return new WebsocketStartErfolgreichAction();
        })
    );


    @Effect({dispatch: false})
    websocketStop$: Observable<Action> = this.actions$.pipe(
        ofType<WebsocketStopAction>(WEBSOCKET_STOP_ACTION)
        , tap((x) => {
            console.debug('WebsocketEffects :: websocketStop$');
            this.getWebsocketService().stop();
        })
    );

    // @Effect({dispatch: false})
    // websocketSetTokenAction$: Observable<Action> = this.actions$.pipe(
    //     ofType<WebsocketSetTokenAction>(WEBSOCKET_SET_TOKEN_ACTION)
    //     , tap((x: WebsocketSetTokenAction) => {
    //         console.debug('WebsocketEffects :: websocketSetTokenAction$');
    //         this.getWebsocketService().setAccessToken(x.token);
    //     })
    // );

    @Effect({dispatch: false})
    websocketSetMandantAction$: Observable<Action> = this.actions$.pipe(
        ofType<WebsocketSetMandantAction>(WEBSOCKET_SET_MANDANT_ACTION)
        , tap((x: WebsocketSetMandantAction) => {
            console.debug('WebsocketEffects :: WebsocketSetMandantAction$');

            this.getWebsocketService().registerMandant(x.mandant_id);
        })
    );


    private websocketService: WebsocketService = null;

    constructor(private actions$: Actions,
                private store: Store<any>,
                private injector: Injector
    ) {
    }

    private getWebsocketService(): WebsocketService {
        if (this.websocketService == null) {
            this.websocketService = this.injector.get(WebsocketService);
        }

        return this.websocketService;
    }
}
