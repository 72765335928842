import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const GET_NOTIFICATION_TYPES = '[KommunikationModul] GetNotificationTypes laden';
export const GET_NOTIFICATION_TYPES_AUSFUEHREN = '[KommunikationModul] GetNotificationTypes ausführen';
export const GET_NOTIFICATION_TYPES_ERFOLGREICH = '[KommunikationModul] GetNotificationTypes laden erfolgreich';
export const GET_NOTIFICATION_TYPES_FEHLER = '[KommunikationModul] GetNotificationTypes Ladefehler';
export const GET_NOTIFICATION_TYPES_NICHT_AUSGEFUEHRT = '[KommunikationModul] GetNotificationTypes nicht ausgeführt (evtl. Offline)';



export class GetNotificationTypesAction implements Action {
    readonly type = GET_NOTIFICATION_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetNotificationTypesAusfuehrenAction implements Action {
    readonly type = GET_NOTIFICATION_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetNotificationTypesErfolgreichAction implements Action {
    readonly type = GET_NOTIFICATION_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.NotificationMessageTypeInfo>, public optPayload: any = null) {}
    }
export class GetNotificationTypesFehlerAction implements Action {
    readonly type = GET_NOTIFICATION_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetNotificationTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_NOTIFICATION_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationQueryNotificationTypesActions =
    GetNotificationTypesAction
    | GetNotificationTypesAusfuehrenAction
    | GetNotificationTypesErfolgreichAction
    | GetNotificationTypesFehlerAction
    | GetNotificationTypesNichtAusgefuehrtAction
    ;
