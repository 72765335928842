import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_AUFGABE_ESKALATION_NOTIFICATION = '[VeranstaltungenModul] GetAufgabeEskalationNotification laden';
export const GET_AUFGABE_ESKALATION_NOTIFICATION_AUSFUEHREN = '[VeranstaltungenModul] GetAufgabeEskalationNotification ausführen';
export const GET_AUFGABE_ESKALATION_NOTIFICATION_ERFOLGREICH = '[VeranstaltungenModul] GetAufgabeEskalationNotification laden erfolgreich';
export const GET_AUFGABE_ESKALATION_NOTIFICATION_FEHLER = '[VeranstaltungenModul] GetAufgabeEskalationNotification Ladefehler';
export const GET_AUFGABE_ESKALATION_NOTIFICATION_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetAufgabeEskalationNotification nicht ausgeführt (evtl. Offline)';



export class GetAufgabeEskalationNotificationAction implements Action {
    readonly type = GET_AUFGABE_ESKALATION_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetAufgabeEskalationNotificationAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_ESKALATION_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetAufgabeEskalationNotificationErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_ESKALATION_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.AufgabeEskalationNotification, public optPayload: any = null) {}
    }
export class GetAufgabeEskalationNotificationFehlerAction implements Action {
    readonly type = GET_AUFGABE_ESKALATION_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetAufgabeEskalationNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_ESKALATION_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryAufgabeEskalationNotificationActions =
    GetAufgabeEskalationNotificationAction
    | GetAufgabeEskalationNotificationAusfuehrenAction
    | GetAufgabeEskalationNotificationErfolgreichAction
    | GetAufgabeEskalationNotificationFehlerAction
    | GetAufgabeEskalationNotificationNichtAusgefuehrtAction
    ;
