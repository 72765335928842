import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_KOPIEREN = '[VorlagenModul] PostAufgabeKopieren laden';
export const POST_AUFGABE_KOPIEREN_AUSFUEHREN = '[VorlagenModul] PostAufgabeKopieren ausführen';
export const POST_AUFGABE_KOPIEREN_ERFOLGREICH = '[VorlagenModul] PostAufgabeKopieren laden erfolgreich';
export const POST_AUFGABE_KOPIEREN_FEHLER = '[VorlagenModul] PostAufgabeKopieren Ladefehler';
export const POST_AUFGABE_KOPIEREN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostAufgabeKopieren nicht ausgeführt (evtl. Offline)';



export class PostAufgabeKopierenAction implements Action {
    readonly type = POST_AUFGABE_KOPIEREN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeKopierenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_KOPIEREN_AUSFUEHREN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeKopierenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_KOPIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeKopierenFehlerAction implements Action {
    readonly type = POST_AUFGABE_KOPIEREN_FEHLER;
        constructor(public payload: any, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeKopierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_KOPIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandAufgabeKopierenActions =
    PostAufgabeKopierenAction
    | PostAufgabeKopierenAusfuehrenAction
    | PostAufgabeKopierenErfolgreichAction
    | PostAufgabeKopierenFehlerAction
    | PostAufgabeKopierenNichtAusgefuehrtAction
    ;
