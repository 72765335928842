import {Action} from '@ngrx/store';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';

export const PAGE_STATE_RESET = '[FunktionsbereichsauswahlModal] Page State reset';

export const NICHT_ANZUZEIGENDE_FUNKTIONSBEREICHE_SETZEN = '[FunktionsbereichsauswahlModal] Funktionsbereich die aus der Auswahl entfernt werden sollen setzen';
export const FUNKTIONSBEREICHE_SETZEN = '[FunktionsbereichsauswahlModal] Funktionsbereich setzen';

// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;
}

// UI
export class NichtAnzuzeigendeFunktionsbereicheSetzenAction implements Action {
    readonly type = NICHT_ANZUZEIGENDE_FUNKTIONSBEREICHE_SETZEN;

    constructor(public nichtAnzuzeigendeFunktionsbereiche: Array<any>) {
    }
}

export class FunktionsbereicheSetzenAction implements Action {
    readonly type = FUNKTIONSBEREICHE_SETZEN;

    constructor(public items: Array<ListInputItem>) {
    }
}


/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
    NichtAnzuzeigendeFunktionsbereicheSetzenAction
    | FunktionsbereicheSetzenAction
    | PageStateResetAction
    ;
