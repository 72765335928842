import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'sopi-nav-button',
    templateUrl: './sopi-nav-button.component.html',
    styleUrls: ['./sopi-nav-button.component.scss'],
})
export class SopiNavButtonComponent implements OnInit {

    // VALUES

    // 'list' = Normaler Menü-Eintrag | 'special' = Funktions-Menü im unteren Teil
    @Input() mode = 'list';
    @Input() mainValue = 'Menupunkt';
    @Input() subValue: string = null;
    @Input() iconNameStart: string = null;
    @Input() iconNameEnd: string = null;

    constructor() {
    }

    ngOnInit() {
    }

}
