import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const POST_MITTEILUNGS_VORLAGE_AKTUALISIEREN = '[KommunikationModul] PostMitteilungsVorlageAktualisieren laden';
export const POST_MITTEILUNGS_VORLAGE_AKTUALISIEREN_AUSFUEHREN = '[KommunikationModul] PostMitteilungsVorlageAktualisieren ausführen';
export const POST_MITTEILUNGS_VORLAGE_AKTUALISIEREN_ERFOLGREICH = '[KommunikationModul] PostMitteilungsVorlageAktualisieren laden erfolgreich';
export const POST_MITTEILUNGS_VORLAGE_AKTUALISIEREN_FEHLER = '[KommunikationModul] PostMitteilungsVorlageAktualisieren Ladefehler';
export const POST_MITTEILUNGS_VORLAGE_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[KommunikationModul] PostMitteilungsVorlageAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostMitteilungsVorlageAktualisierenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_AKTUALISIEREN;
        constructor(public mitteilungsVorlageDatenRequest: api.MitteilungsVorlageDatenRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_AKTUALISIEREN_AUSFUEHREN;
        constructor(public mitteilungsVorlageDatenRequest: api.MitteilungsVorlageDatenRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageAktualisierenErfolgreichAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public mitteilungsVorlageDatenRequest: api.MitteilungsVorlageDatenRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageAktualisierenFehlerAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public mitteilungsVorlageDatenRequest: api.MitteilungsVorlageDatenRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationCommandMitteilungsVorlageAktualisierenActions =
    PostMitteilungsVorlageAktualisierenAction
    | PostMitteilungsVorlageAktualisierenAusfuehrenAction
    | PostMitteilungsVorlageAktualisierenErfolgreichAction
    | PostMitteilungsVorlageAktualisierenFehlerAction
    | PostMitteilungsVorlageAktualisierenNichtAusgefuehrtAction
    ;
