import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_AUFGABE_ERINNERUNG_VERSENDEN = '[VeranstaltungenModul] PostVeranstaltungAufgabeErinnerungVersenden laden';
export const POST_VERANSTALTUNG_AUFGABE_ERINNERUNG_VERSENDEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungAufgabeErinnerungVersenden ausführen';
export const POST_VERANSTALTUNG_AUFGABE_ERINNERUNG_VERSENDEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungAufgabeErinnerungVersenden laden erfolgreich';
export const POST_VERANSTALTUNG_AUFGABE_ERINNERUNG_VERSENDEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungAufgabeErinnerungVersenden Ladefehler';
export const POST_VERANSTALTUNG_AUFGABE_ERINNERUNG_VERSENDEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungAufgabeErinnerungVersenden nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungAufgabeErinnerungVersendenAction implements Action {
    readonly type = POST_VERANSTALTUNG_AUFGABE_ERINNERUNG_VERSENDEN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAufgabeErinnerungVersendenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_AUFGABE_ERINNERUNG_VERSENDEN_AUSFUEHREN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAufgabeErinnerungVersendenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_AUFGABE_ERINNERUNG_VERSENDEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAufgabeErinnerungVersendenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_AUFGABE_ERINNERUNG_VERSENDEN_FEHLER;
        constructor(public payload: any, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAufgabeErinnerungVersendenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_AUFGABE_ERINNERUNG_VERSENDEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungAufgabeErinnerungVersendenActions =
    PostVeranstaltungAufgabeErinnerungVersendenAction
    | PostVeranstaltungAufgabeErinnerungVersendenAusfuehrenAction
    | PostVeranstaltungAufgabeErinnerungVersendenErfolgreichAction
    | PostVeranstaltungAufgabeErinnerungVersendenFehlerAction
    | PostVeranstaltungAufgabeErinnerungVersendenNichtAusgefuehrtAction
    ;
