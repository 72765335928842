import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_LIZENZ_MANDANT_OPTIONS = '[ApiModul] GetLizenzMandantOptions laden';
export const GET_LIZENZ_MANDANT_OPTIONS_AUSFUEHREN = '[ApiModul] GetLizenzMandantOptions ausführen';
export const GET_LIZENZ_MANDANT_OPTIONS_ERFOLGREICH = '[ApiModul] GetLizenzMandantOptions laden erfolgreich';
export const GET_LIZENZ_MANDANT_OPTIONS_FEHLER = '[ApiModul] GetLizenzMandantOptions Ladefehler';
export const GET_LIZENZ_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT = '[ApiModul] GetLizenzMandantOptions nicht ausgeführt (evtl. Offline)';



export class GetLizenzMandantOptionsAction implements Action {
    readonly type = GET_LIZENZ_MANDANT_OPTIONS;
        constructor(public optPayload: any = null) {}
    }
export class GetLizenzMandantOptionsAusfuehrenAction implements Action {
    readonly type = GET_LIZENZ_MANDANT_OPTIONS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetLizenzMandantOptionsErfolgreichAction implements Action {
    readonly type = GET_LIZENZ_MANDANT_OPTIONS_ERFOLGREICH;
        constructor(public payload: api.LizenzMandantOptions, public optPayload: any = null) {}
    }
export class GetLizenzMandantOptionsFehlerAction implements Action {
    readonly type = GET_LIZENZ_MANDANT_OPTIONS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetLizenzMandantOptionsNichtAusgefuehrtAction implements Action {
    readonly type = GET_LIZENZ_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryLizenzMandantOptionsActions =
    GetLizenzMandantOptionsAction
    | GetLizenzMandantOptionsAusfuehrenAction
    | GetLizenzMandantOptionsErfolgreichAction
    | GetLizenzMandantOptionsFehlerAction
    | GetLizenzMandantOptionsNichtAusgefuehrtAction
    ;
