import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_ADMIN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheChecklistenAdmin laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheChecklistenAdmin ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheChecklistenAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheChecklistenAdmin Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheChecklistenAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereicheChecklistenAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_ADMIN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheChecklistenAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheChecklistenAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichChecklisten>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheChecklistenAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheChecklistenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereicheChecklistenAdminActions =
    GetVeranstaltungFunktionsbereicheChecklistenAdminAction
    | GetVeranstaltungFunktionsbereicheChecklistenAdminAusfuehrenAction
    | GetVeranstaltungFunktionsbereicheChecklistenAdminErfolgreichAction
    | GetVeranstaltungFunktionsbereicheChecklistenAdminFehlerAction
    | GetVeranstaltungFunktionsbereicheChecklistenAdminNichtAusgefuehrtAction
    ;
