

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL = '[VeranstaltungenModul] GetVeranstaltungAufgabenBenutzerBerechtigungenExcel laden';
export const GET_VERANSTALTUNG_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungAufgabenBenutzerBerechtigungenExcel ausführen';
export const GET_VERANSTALTUNG_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungAufgabenBenutzerBerechtigungenExcel laden erfolgreich';
export const GET_VERANSTALTUNG_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_FEHLER = '[VeranstaltungenModul] GetVeranstaltungAufgabenBenutzerBerechtigungenExcel Ladefehler';
export const GET_VERANSTALTUNG_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungAufgabenBenutzerBerechtigungenExcel nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungAufgabenBenutzerBerechtigungenExcelAction implements Action {
    readonly type = GET_VERANSTALTUNG_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungAufgabenBenutzerBerechtigungenExcelAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungAufgabenBenutzerBerechtigungenExcelErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_ERFOLGREICH;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungAufgabenBenutzerBerechtigungenExcelFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungAufgabenBenutzerBerechtigungenExcelNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_AUFGABEN_BENUTZER_BERECHTIGUNGEN_EXCEL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungAufgabenBenutzerBerechtigungenExcelActions =
    GetVeranstaltungAufgabenBenutzerBerechtigungenExcelAction
    | GetVeranstaltungAufgabenBenutzerBerechtigungenExcelAusfuehrenAction
    | GetVeranstaltungAufgabenBenutzerBerechtigungenExcelErfolgreichAction
    | GetVeranstaltungAufgabenBenutzerBerechtigungenExcelFehlerAction
    | GetVeranstaltungAufgabenBenutzerBerechtigungenExcelNichtAusgefuehrtAction
    ;
