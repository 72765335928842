import {Component, OnInit} from '@angular/core';
import {ModalController, NavController, NavParams} from '@ionic/angular';

@Component({
    selector: 'app-checkliste-entfernen',
    templateUrl: './checkliste-entfernen.page.html'
})
export class ChecklisteEntfernenPage implements OnInit {
    aiName = 'ChecklisteEntfernenModal';
    titel = '';

    constructor(public navCtrl: NavController,
                public navParams: NavParams,
                private modalCtrl: ModalController) {
        this.titel = this.navParams.get('titel');
    }

    ngOnInit() {
    }


    ok() {
        this.modalCtrl.dismiss('ok');
    }

    abbrechen() {
        this.modalCtrl.dismiss();
    }


}
