import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN = '[VorlagenModul] PostChecklisteVeranstaltungsTypenHinzufuegen laden';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_AUSFUEHREN = '[VorlagenModul] PostChecklisteVeranstaltungsTypenHinzufuegen ausführen';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_ERFOLGREICH = '[VorlagenModul] PostChecklisteVeranstaltungsTypenHinzufuegen laden erfolgreich';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_FEHLER = '[VorlagenModul] PostChecklisteVeranstaltungsTypenHinzufuegen Ladefehler';
export const POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostChecklisteVeranstaltungsTypenHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostChecklisteVeranstaltungsTypenHinzufuegenAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN;
        constructor(public checklisteVeranstaltungsTypMultiRequest: api.ChecklisteVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_AUSFUEHREN;
        constructor(public checklisteVeranstaltungsTypMultiRequest: api.ChecklisteVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public checklisteVeranstaltungsTypMultiRequest: api.ChecklisteVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenHinzufuegenFehlerAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public checklisteVeranstaltungsTypMultiRequest: api.ChecklisteVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostChecklisteVeranstaltungsTypenHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_CHECKLISTE_VERANSTALTUNGS_TYPEN_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandChecklisteVeranstaltungsTypenHinzufuegenActions =
    PostChecklisteVeranstaltungsTypenHinzufuegenAction
    | PostChecklisteVeranstaltungsTypenHinzufuegenAusfuehrenAction
    | PostChecklisteVeranstaltungsTypenHinzufuegenErfolgreichAction
    | PostChecklisteVeranstaltungsTypenHinzufuegenFehlerAction
    | PostChecklisteVeranstaltungsTypenHinzufuegenNichtAusgefuehrtAction
    ;
