import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_ROLLE_DIENSTPLAN_SICHTBARKEIT_FESTLEGEN = '[BenutzerverwaltungModul] PostRolleDienstplanSichtbarkeitFestlegen laden';
export const POST_ROLLE_DIENSTPLAN_SICHTBARKEIT_FESTLEGEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostRolleDienstplanSichtbarkeitFestlegen ausführen';
export const POST_ROLLE_DIENSTPLAN_SICHTBARKEIT_FESTLEGEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostRolleDienstplanSichtbarkeitFestlegen laden erfolgreich';
export const POST_ROLLE_DIENSTPLAN_SICHTBARKEIT_FESTLEGEN_FEHLER = '[BenutzerverwaltungModul] PostRolleDienstplanSichtbarkeitFestlegen Ladefehler';
export const POST_ROLLE_DIENSTPLAN_SICHTBARKEIT_FESTLEGEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostRolleDienstplanSichtbarkeitFestlegen nicht ausgeführt (evtl. Offline)';



export class PostRolleDienstplanSichtbarkeitFestlegenAction implements Action {
    readonly type = POST_ROLLE_DIENSTPLAN_SICHTBARKEIT_FESTLEGEN;
        constructor(public rolleDienstplanSichtbarkeitFestlegenRequest: api.RolleDienstplanSichtbarkeitFestlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleDienstplanSichtbarkeitFestlegenAusfuehrenAction implements Action {
    readonly type = POST_ROLLE_DIENSTPLAN_SICHTBARKEIT_FESTLEGEN_AUSFUEHREN;
        constructor(public rolleDienstplanSichtbarkeitFestlegenRequest: api.RolleDienstplanSichtbarkeitFestlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleDienstplanSichtbarkeitFestlegenErfolgreichAction implements Action {
    readonly type = POST_ROLLE_DIENSTPLAN_SICHTBARKEIT_FESTLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public rolleDienstplanSichtbarkeitFestlegenRequest: api.RolleDienstplanSichtbarkeitFestlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleDienstplanSichtbarkeitFestlegenFehlerAction implements Action {
    readonly type = POST_ROLLE_DIENSTPLAN_SICHTBARKEIT_FESTLEGEN_FEHLER;
        constructor(public payload: any, public rolleDienstplanSichtbarkeitFestlegenRequest: api.RolleDienstplanSichtbarkeitFestlegenRequest, public optPayload: any = null) {}
    }
export class PostRolleDienstplanSichtbarkeitFestlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_ROLLE_DIENSTPLAN_SICHTBARKEIT_FESTLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandRolleDienstplanSichtbarkeitFestlegenActions =
    PostRolleDienstplanSichtbarkeitFestlegenAction
    | PostRolleDienstplanSichtbarkeitFestlegenAusfuehrenAction
    | PostRolleDienstplanSichtbarkeitFestlegenErfolgreichAction
    | PostRolleDienstplanSichtbarkeitFestlegenFehlerAction
    | PostRolleDienstplanSichtbarkeitFestlegenNichtAusgefuehrtAction
    ;
