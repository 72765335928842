

import {Action} from '@ngrx/store';

export const GET_FUNKPROTOKOLL_REPORT_XML = '[VeranstaltungenModul] GetFunkprotokollReportXml laden';
export const GET_FUNKPROTOKOLL_REPORT_XML_AUSFUEHREN = '[VeranstaltungenModul] GetFunkprotokollReportXml ausführen';
export const GET_FUNKPROTOKOLL_REPORT_XML_ERFOLGREICH = '[VeranstaltungenModul] GetFunkprotokollReportXml laden erfolgreich';
export const GET_FUNKPROTOKOLL_REPORT_XML_FEHLER = '[VeranstaltungenModul] GetFunkprotokollReportXml Ladefehler';
export const GET_FUNKPROTOKOLL_REPORT_XML_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunkprotokollReportXml nicht ausgeführt (evtl. Offline)';



export class GetFunkprotokollReportXmlAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_XML;
        constructor( public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollReportXmlAusfuehrenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_XML_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollReportXmlErfolgreichAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_XML_ERFOLGREICH;
        constructor( public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollReportXmlFehlerAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_XML_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollReportXmlNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_XML_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunkprotokollReportXmlActions =
    GetFunkprotokollReportXmlAction
    | GetFunkprotokollReportXmlAusfuehrenAction
    | GetFunkprotokollReportXmlErfolgreichAction
    | GetFunkprotokollReportXmlFehlerAction
    | GetFunkprotokollReportXmlNichtAusgefuehrtAction
    ;
