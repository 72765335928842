import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_ROLLE_ABTEILUNG_SETZEN = '[IncidentsModul] PostRolleAbteilungSetzen laden';
export const POST_ROLLE_ABTEILUNG_SETZEN_AUSFUEHREN = '[IncidentsModul] PostRolleAbteilungSetzen ausführen';
export const POST_ROLLE_ABTEILUNG_SETZEN_ERFOLGREICH = '[IncidentsModul] PostRolleAbteilungSetzen laden erfolgreich';
export const POST_ROLLE_ABTEILUNG_SETZEN_FEHLER = '[IncidentsModul] PostRolleAbteilungSetzen Ladefehler';
export const POST_ROLLE_ABTEILUNG_SETZEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostRolleAbteilungSetzen nicht ausgeführt (evtl. Offline)';



export class PostRolleAbteilungSetzenAction implements Action {
    readonly type = POST_ROLLE_ABTEILUNG_SETZEN;
        constructor(public incidentsRolleOptionenRequest: api.IncidentsRolleOptionenRequest, public optPayload: any = null) {}
    }
export class PostRolleAbteilungSetzenAusfuehrenAction implements Action {
    readonly type = POST_ROLLE_ABTEILUNG_SETZEN_AUSFUEHREN;
        constructor(public incidentsRolleOptionenRequest: api.IncidentsRolleOptionenRequest, public optPayload: any = null) {}
    }
export class PostRolleAbteilungSetzenErfolgreichAction implements Action {
    readonly type = POST_ROLLE_ABTEILUNG_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentsRolleOptionenRequest: api.IncidentsRolleOptionenRequest, public optPayload: any = null) {}
    }
export class PostRolleAbteilungSetzenFehlerAction implements Action {
    readonly type = POST_ROLLE_ABTEILUNG_SETZEN_FEHLER;
        constructor(public payload: any, public incidentsRolleOptionenRequest: api.IncidentsRolleOptionenRequest, public optPayload: any = null) {}
    }
export class PostRolleAbteilungSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_ROLLE_ABTEILUNG_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandRolleAbteilungSetzenActions =
    PostRolleAbteilungSetzenAction
    | PostRolleAbteilungSetzenAusfuehrenAction
    | PostRolleAbteilungSetzenErfolgreichAction
    | PostRolleAbteilungSetzenFehlerAction
    | PostRolleAbteilungSetzenNichtAusgefuehrtAction
    ;
