import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_BENUTZER_EINSTELLUNGEN_ADMIN = '[BenutzerverwaltungModul] GetBenutzerEinstellungenAdmin laden';
export const GET_BENUTZER_EINSTELLUNGEN_ADMIN_AUSFUEHREN = '[BenutzerverwaltungModul] GetBenutzerEinstellungenAdmin ausführen';
export const GET_BENUTZER_EINSTELLUNGEN_ADMIN_ERFOLGREICH = '[BenutzerverwaltungModul] GetBenutzerEinstellungenAdmin laden erfolgreich';
export const GET_BENUTZER_EINSTELLUNGEN_ADMIN_FEHLER = '[BenutzerverwaltungModul] GetBenutzerEinstellungenAdmin Ladefehler';
export const GET_BENUTZER_EINSTELLUNGEN_ADMIN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetBenutzerEinstellungenAdmin nicht ausgeführt (evtl. Offline)';



export class GetBenutzerEinstellungenAdminAction implements Action {
    readonly type = GET_BENUTZER_EINSTELLUNGEN_ADMIN;
        constructor( public benutzer_id:string , public optPayload: any = null) {}
    }
export class GetBenutzerEinstellungenAdminAusfuehrenAction implements Action {
    readonly type = GET_BENUTZER_EINSTELLUNGEN_ADMIN_AUSFUEHREN;
        constructor( public benutzer_id:string , public optPayload: any = null) {}
    }
export class GetBenutzerEinstellungenAdminErfolgreichAction implements Action {
    readonly type = GET_BENUTZER_EINSTELLUNGEN_ADMIN_ERFOLGREICH;
        constructor(public payload: api.BenutzerEinstellungen,  public benutzer_id:string , public optPayload: any = null) {}
    }
export class GetBenutzerEinstellungenAdminFehlerAction implements Action {
    readonly type = GET_BENUTZER_EINSTELLUNGEN_ADMIN_FEHLER;
        constructor(public payload: any,  public benutzer_id:string , public optPayload: any = null) {}
    }
export class GetBenutzerEinstellungenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_BENUTZER_EINSTELLUNGEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryBenutzerEinstellungenAdminActions =
    GetBenutzerEinstellungenAdminAction
    | GetBenutzerEinstellungenAdminAusfuehrenAction
    | GetBenutzerEinstellungenAdminErfolgreichAction
    | GetBenutzerEinstellungenAdminFehlerAction
    | GetBenutzerEinstellungenAdminNichtAusgefuehrtAction
    ;
