import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {SettingsProvider} from './settings.service';
import {ActionReducer, StoreModule} from '@ngrx/store';
import {LocalStorageConfig, localStorageSync} from 'ngrx-store-localstorage';
import {reducers} from './reducer';
import {EffectsModule} from '@ngrx/effects';
import {SettingsEffects} from './effects';
import { THEME_URL, THEME_TYPE } from './variables';


export function sessionStorage(reducer: ActionReducer<any>): ActionReducer<any> {

  const config: LocalStorageConfig = {
    keys: [
      'settings'
    ],
    rehydrate: true,
    removeOnUndefined: true
  };

  return localStorageSync(config)(reducer);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('Settings', reducers, {metaReducers: [sessionStorage]}),
    EffectsModule.forFeature([
      SettingsEffects
    ])
  ],
  providers: [
    SettingsProvider
  ]
})
export class SettingsModule {
  public static forRoot(themeUrl: string, themeType: string): ModuleWithProviders {
    return {
      ngModule: SettingsModule,
      providers: [
          { provide: THEME_URL, useValue: themeUrl },
          { provide: THEME_TYPE, useValue: themeType }
        ]
    };
  }
}
