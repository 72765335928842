import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FUNKPROTOKOLL_MELDEART_ANLEGEN = '[VeranstaltungenModul] PostFunkprotokollMeldeartAnlegen laden';
export const POST_FUNKPROTOKOLL_MELDEART_ANLEGEN_AUSFUEHREN = '[VeranstaltungenModul] PostFunkprotokollMeldeartAnlegen ausführen';
export const POST_FUNKPROTOKOLL_MELDEART_ANLEGEN_ERFOLGREICH = '[VeranstaltungenModul] PostFunkprotokollMeldeartAnlegen laden erfolgreich';
export const POST_FUNKPROTOKOLL_MELDEART_ANLEGEN_FEHLER = '[VeranstaltungenModul] PostFunkprotokollMeldeartAnlegen Ladefehler';
export const POST_FUNKPROTOKOLL_MELDEART_ANLEGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFunkprotokollMeldeartAnlegen nicht ausgeführt (evtl. Offline)';



export class PostFunkprotokollMeldeartAnlegenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_ANLEGEN;
        constructor(public funkprotokollMeldeartDatenRequest: api.FunkprotokollMeldeartDatenRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollMeldeartAnlegenAusfuehrenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_ANLEGEN_AUSFUEHREN;
        constructor(public funkprotokollMeldeartDatenRequest: api.FunkprotokollMeldeartDatenRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollMeldeartAnlegenErfolgreichAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_ANLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funkprotokollMeldeartDatenRequest: api.FunkprotokollMeldeartDatenRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollMeldeartAnlegenFehlerAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_ANLEGEN_FEHLER;
        constructor(public payload: any, public funkprotokollMeldeartDatenRequest: api.FunkprotokollMeldeartDatenRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollMeldeartAnlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_MELDEART_ANLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFunkprotokollMeldeartAnlegenActions =
    PostFunkprotokollMeldeartAnlegenAction
    | PostFunkprotokollMeldeartAnlegenAusfuehrenAction
    | PostFunkprotokollMeldeartAnlegenErfolgreichAction
    | PostFunkprotokollMeldeartAnlegenFehlerAction
    | PostFunkprotokollMeldeartAnlegenNichtAusgefuehrtAction
    ;
