import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const POST_BEREICH_TRIGGER_AUSLOESEN = '[DisplaysteuerungModul] PostBereichTriggerAusloesen laden';
export const POST_BEREICH_TRIGGER_AUSLOESEN_AUSFUEHREN = '[DisplaysteuerungModul] PostBereichTriggerAusloesen ausführen';
export const POST_BEREICH_TRIGGER_AUSLOESEN_ERFOLGREICH = '[DisplaysteuerungModul] PostBereichTriggerAusloesen laden erfolgreich';
export const POST_BEREICH_TRIGGER_AUSLOESEN_FEHLER = '[DisplaysteuerungModul] PostBereichTriggerAusloesen Ladefehler';
export const POST_BEREICH_TRIGGER_AUSLOESEN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] PostBereichTriggerAusloesen nicht ausgeführt (evtl. Offline)';



export class PostBereichTriggerAusloesenAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_AUSLOESEN;
        constructor(public bereichTriggerRequest: api.BereichTriggerRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerAusloesenAusfuehrenAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_AUSLOESEN_AUSFUEHREN;
        constructor(public bereichTriggerRequest: api.BereichTriggerRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerAusloesenErfolgreichAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_AUSLOESEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public bereichTriggerRequest: api.BereichTriggerRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerAusloesenFehlerAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_AUSLOESEN_FEHLER;
        constructor(public payload: any, public bereichTriggerRequest: api.BereichTriggerRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerAusloesenNichtAusgefuehrtAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_AUSLOESEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungCommandBereichTriggerAusloesenActions =
    PostBereichTriggerAusloesenAction
    | PostBereichTriggerAusloesenAusfuehrenAction
    | PostBereichTriggerAusloesenErfolgreichAction
    | PostBereichTriggerAusloesenFehlerAction
    | PostBereichTriggerAusloesenNichtAusgefuehrtAction
    ;
