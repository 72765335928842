

import {Action} from '@ngrx/store';

export const GET_PRUEFLAUF_BILD = '[QschecksModul] GetPrueflaufBild laden';
export const GET_PRUEFLAUF_BILD_AUSFUEHREN = '[QschecksModul] GetPrueflaufBild ausführen';
export const GET_PRUEFLAUF_BILD_ERFOLGREICH = '[QschecksModul] GetPrueflaufBild laden erfolgreich';
export const GET_PRUEFLAUF_BILD_FEHLER = '[QschecksModul] GetPrueflaufBild Ladefehler';
export const GET_PRUEFLAUF_BILD_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPrueflaufBild nicht ausgeführt (evtl. Offline)';



export class GetPrueflaufBildAction implements Action {
    readonly type = GET_PRUEFLAUF_BILD;
        constructor( public prueflauf_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetPrueflaufBildAusfuehrenAction implements Action {
    readonly type = GET_PRUEFLAUF_BILD_AUSFUEHREN;
        constructor( public prueflauf_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetPrueflaufBildErfolgreichAction implements Action {
    readonly type = GET_PRUEFLAUF_BILD_ERFOLGREICH;
        constructor( public prueflauf_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetPrueflaufBildFehlerAction implements Action {
    readonly type = GET_PRUEFLAUF_BILD_FEHLER;
        constructor(public payload: any,  public prueflauf_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetPrueflaufBildNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFLAUF_BILD_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPrueflaufBildActions =
    GetPrueflaufBildAction
    | GetPrueflaufBildAusfuehrenAction
    | GetPrueflaufBildErfolgreichAction
    | GetPrueflaufBildFehlerAction
    | GetPrueflaufBildNichtAusgefuehrtAction
    ;
