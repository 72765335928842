import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_DETAIL_ADMIN = '[VeranstaltungenModul] GetVeranstaltungDetailAdmin laden';
export const GET_VERANSTALTUNG_DETAIL_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungDetailAdmin ausführen';
export const GET_VERANSTALTUNG_DETAIL_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungDetailAdmin laden erfolgreich';
export const GET_VERANSTALTUNG_DETAIL_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungDetailAdmin Ladefehler';
export const GET_VERANSTALTUNG_DETAIL_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungDetailAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungDetailAdminAction implements Action {
    readonly type = GET_VERANSTALTUNG_DETAIL_ADMIN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDetailAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_DETAIL_ADMIN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDetailAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_DETAIL_ADMIN_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungDetail,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDetailAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_DETAIL_ADMIN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungDetailAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_DETAIL_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungDetailAdminActions =
    GetVeranstaltungDetailAdminAction
    | GetVeranstaltungDetailAdminAusfuehrenAction
    | GetVeranstaltungDetailAdminErfolgreichAction
    | GetVeranstaltungDetailAdminFehlerAction
    | GetVeranstaltungDetailAdminNichtAusgefuehrtAction
    ;
