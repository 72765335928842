import {Action} from '@ngrx/store';
import {Benutzer} from '../../clients/api';

// export const AUTH_LOGIN = '[Auth] Login ausführen';
export const AUTH_LOGIN_ERFOLGREICH = '[Auth] Login erfolgreich';
export const AUTH_LOGIN_FEHLER = '[Auth] Login fehlgeschlagen';

export const AUTH_LOGOUT = '[Auth] Logout';
export const AUTH_LOGOUT_ERFOLGREICH = '[Auth] Logout erfolgreich';

export const AUTH_ERROR = '[Auth] Error';
export const AUTH_REFRESH_TOKEN = '[Auth] Refresh Token';
export const AUTH_REFRESH_TOKEN_ERFOLGREICH = '[Auth] Refresh Token Erfolgreich';

// export const AUTH_COPY_TOKEN = '[Auth] Copy Token';

// export class AuthLoginAction implements Action {
//     readonly type = AUTH_LOGIN;

//     constructor(public payload: api.LoginRequest) {
//     }
// }

export class AuthLoginErfolgreichAction implements Action {
    readonly type = AUTH_LOGIN_ERFOLGREICH;

    constructor(public payload: Benutzer, public tokenRefresh: boolean = false) {
    }

}

export function CreateAuthLoginErfolgreichAction(userId: string): AuthLoginErfolgreichAction {
    const ui = <Benutzer>{
        id: userId,
        upn: userId
    };
    return new AuthLoginErfolgreichAction(ui);
}


export class AuthLoginFehlgeschlagenAction implements Action {
    readonly type = AUTH_LOGIN_FEHLER;

    constructor(public error: string) {
    }
}

export class AuthLogoutAction implements Action {
    readonly type = AUTH_LOGOUT;
    payload = null;
    // constructor() {}
}

export class AuthLogoutErfolgreichAction implements Action {
    readonly type = AUTH_LOGOUT_ERFOLGREICH;
    payload = null;
    // constructor() {}
}


export class RefreshTokenAction implements Action {
    readonly type = AUTH_REFRESH_TOKEN;
    payload = null;
    // constructor() {}
}


export class RefreshTokenErfolgreichAction implements Action {
    readonly type = AUTH_REFRESH_TOKEN_ERFOLGREICH;
    payload = null;
    // constructor() {}
}


// export class CopyTokenAction implements Action {
//     readonly type = AUTH_COPY_TOKEN;
//     payload = null;
//     // constructor() {}
// }

export class AuthErrorAction implements Action {
    readonly type = AUTH_ERROR;

    constructor(public eventType: string, public error: string, public errorDescription: string) {
    }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
// AuthLoginAction
// |
    AuthLoginErfolgreichAction
    | AuthLoginFehlgeschlagenAction
    | AuthLogoutAction
    | AuthLogoutErfolgreichAction
    | RefreshTokenAction
    // | CopyTokenAction
    | AuthErrorAction
    | RefreshTokenErfolgreichAction
    ;
