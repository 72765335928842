import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Inject, Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {MyAuthOptions} from './variables';

@Injectable()
export class IsAuthenticatedGuard implements CanActivate {

    private _oauthService: AuthService = null;

    constructor(private injector: Injector, private _router: Router,
                private config: MyAuthOptions) {
        if (!config || !config.loginRoute) {
            throw new Error('Login route has not been configured.');
        }
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const result = this.getOAuthService().hasValidToken();
        console.debug('authGuard: hasvalidtoken: ', result);

        if (!result) {
            console.debug('authGuard: kein token:', this.config.loginRoute, state.url, state.root);

            if (state.root && state.root.fragment && state.root.fragment.length > 0) {
                if (state.root.fragment.indexOf('access_token') > -1) {
                    console.debug('authGuard: access token in state');
                    return true;
                }
            }
            // auth guard muss code flow durchlassen
            if (state.root && state.root.queryParamMap.has('code')) {
                console.debug('authGuard: code flow return in state');
                return true;
            }

            console.debug('authGuard: navigate to login route', this.config.loginRoute);
            this._router.navigate([this.config.loginRoute],
                {
                    preserveFragment: true
                });


            return false;
        }

        console.debug('authGuard: OK');
        return true;
    }

    private getOAuthService(): AuthService {
        if (this._oauthService === null) {
            this._oauthService = this.injector.get(AuthService);
        }
        return this._oauthService;
    }


    // public async canActivate(
    //   route: ActivatedRouteSnapshot,
    //   state: RouterStateSnapshot
    // ): Promise<boolean> {
    //   const result = this.getOAuthService().hasValidToken();
    //   console.debug("AUTHFLOW: guard2: hasvalidtoken: ", result);

    //   if (!result) {
    //     console.debug("AUTHFLOW: guard2: kein token:", this.config.loginRoute, state.url)
    //     // hash prüfung etc
    //     var b = await this.getOAuthService().initAuth();
    //     console.debug("AUTHFLOW: guard2: initAuth", b );
    //     if (b)
    //     {
    //       return true;
    //     }

    //     // if (state.root && state.root.fragment && state.root.fragment.length > 0) {
    //     //   if (state.root.fragment.indexOf("access_token") > 0) {
    //     //     console.debug("authGuard: access token in state");
    //     //     return true;
    //     //   }
    //     // }

    //     console.debug("AUTHFLOW: guard2: navigate to login route", this.config.loginRoute);
    //     this._router.navigate([this.config.loginRoute],
    //       {
    //         preserveFragment: true
    //       });


    //     return false;
    //   }

    //   console.debug("AUTHFLOW: guard2: OK");
    //   return true;
    // }
}


