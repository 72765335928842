import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFPLAN_ADMIN = '[QschecksModul] GetPruefplanAdmin laden';
export const GET_PRUEFPLAN_ADMIN_AUSFUEHREN = '[QschecksModul] GetPruefplanAdmin ausführen';
export const GET_PRUEFPLAN_ADMIN_ERFOLGREICH = '[QschecksModul] GetPruefplanAdmin laden erfolgreich';
export const GET_PRUEFPLAN_ADMIN_FEHLER = '[QschecksModul] GetPruefplanAdmin Ladefehler';
export const GET_PRUEFPLAN_ADMIN_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPruefplanAdmin nicht ausgeführt (evtl. Offline)';



export class GetPruefplanAdminAction implements Action {
    readonly type = GET_PRUEFPLAN_ADMIN;
        constructor( public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanAdminAusfuehrenAction implements Action {
    readonly type = GET_PRUEFPLAN_ADMIN_AUSFUEHREN;
        constructor( public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanAdminErfolgreichAction implements Action {
    readonly type = GET_PRUEFPLAN_ADMIN_ERFOLGREICH;
        constructor(public payload: api.PruefplanDetail,  public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanAdminFehlerAction implements Action {
    readonly type = GET_PRUEFPLAN_ADMIN_FEHLER;
        constructor(public payload: any,  public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFPLAN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPruefplanAdminActions =
    GetPruefplanAdminAction
    | GetPruefplanAdminAusfuehrenAction
    | GetPruefplanAdminErfolgreichAction
    | GetPruefplanAdminFehlerAction
    | GetPruefplanAdminNichtAusgefuehrtAction
    ;
