import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_AKTION_ERFASSEN = '[VeranstaltungenModul] PostVeranstaltungAktionErfassen laden';
export const POST_VERANSTALTUNG_AKTION_ERFASSEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungAktionErfassen ausführen';
export const POST_VERANSTALTUNG_AKTION_ERFASSEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungAktionErfassen laden erfolgreich';
export const POST_VERANSTALTUNG_AKTION_ERFASSEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungAktionErfassen Ladefehler';
export const POST_VERANSTALTUNG_AKTION_ERFASSEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungAktionErfassen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungAktionErfassenAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_ERFASSEN;
        constructor(public veranstaltungAktionErfassenRequest: api.VeranstaltungAktionErfassenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAktionErfassenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_ERFASSEN_AUSFUEHREN;
        constructor(public veranstaltungAktionErfassenRequest: api.VeranstaltungAktionErfassenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAktionErfassenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_ERFASSEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungAktionErfassenRequest: api.VeranstaltungAktionErfassenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAktionErfassenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_ERFASSEN_FEHLER;
        constructor(public payload: any, public veranstaltungAktionErfassenRequest: api.VeranstaltungAktionErfassenRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungAktionErfassenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_AKTION_ERFASSEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungAktionErfassenActions =
    PostVeranstaltungAktionErfassenAction
    | PostVeranstaltungAktionErfassenAusfuehrenAction
    | PostVeranstaltungAktionErfassenErfolgreichAction
    | PostVeranstaltungAktionErfassenFehlerAction
    | PostVeranstaltungAktionErfassenNichtAusgefuehrtAction
    ;
