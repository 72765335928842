import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFLAUF_LOESCHEN = '[QschecksModul] PostPrueflaufLoeschen laden';
export const POST_PRUEFLAUF_LOESCHEN_AUSFUEHREN = '[QschecksModul] PostPrueflaufLoeschen ausführen';
export const POST_PRUEFLAUF_LOESCHEN_ERFOLGREICH = '[QschecksModul] PostPrueflaufLoeschen laden erfolgreich';
export const POST_PRUEFLAUF_LOESCHEN_FEHLER = '[QschecksModul] PostPrueflaufLoeschen Ladefehler';
export const POST_PRUEFLAUF_LOESCHEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPrueflaufLoeschen nicht ausgeführt (evtl. Offline)';



export class PostPrueflaufLoeschenAction implements Action {
    readonly type = POST_PRUEFLAUF_LOESCHEN;
        constructor(public prueflaufRequest: api.PrueflaufRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufLoeschenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFLAUF_LOESCHEN_AUSFUEHREN;
        constructor(public prueflaufRequest: api.PrueflaufRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufLoeschenErfolgreichAction implements Action {
    readonly type = POST_PRUEFLAUF_LOESCHEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public prueflaufRequest: api.PrueflaufRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufLoeschenFehlerAction implements Action {
    readonly type = POST_PRUEFLAUF_LOESCHEN_FEHLER;
        constructor(public payload: any, public prueflaufRequest: api.PrueflaufRequest, public optPayload: any = null) {}
    }
export class PostPrueflaufLoeschenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFLAUF_LOESCHEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPrueflaufLoeschenActions =
    PostPrueflaufLoeschenAction
    | PostPrueflaufLoeschenAusfuehrenAction
    | PostPrueflaufLoeschenErfolgreichAction
    | PostPrueflaufLoeschenFehlerAction
    | PostPrueflaufLoeschenNichtAusgefuehrtAction
    ;
