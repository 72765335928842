import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_ENTFERNEN = '[KommunikationModul] PostMitteilungsVorlageEmpfaengerRollenEntfernen laden';
export const POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_ENTFERNEN_AUSFUEHREN = '[KommunikationModul] PostMitteilungsVorlageEmpfaengerRollenEntfernen ausführen';
export const POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_ENTFERNEN_ERFOLGREICH = '[KommunikationModul] PostMitteilungsVorlageEmpfaengerRollenEntfernen laden erfolgreich';
export const POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_ENTFERNEN_FEHLER = '[KommunikationModul] PostMitteilungsVorlageEmpfaengerRollenEntfernen Ladefehler';
export const POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_ENTFERNEN_NICHT_AUSGEFUEHRT = '[KommunikationModul] PostMitteilungsVorlageEmpfaengerRollenEntfernen nicht ausgeführt (evtl. Offline)';



export class PostMitteilungsVorlageEmpfaengerRollenEntfernenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_ENTFERNEN;
        constructor(public mitteilungsVorlageRolleMultiRequest: api.MitteilungsVorlageRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageEmpfaengerRollenEntfernenAusfuehrenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_ENTFERNEN_AUSFUEHREN;
        constructor(public mitteilungsVorlageRolleMultiRequest: api.MitteilungsVorlageRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageEmpfaengerRollenEntfernenErfolgreichAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public mitteilungsVorlageRolleMultiRequest: api.MitteilungsVorlageRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageEmpfaengerRollenEntfernenFehlerAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_ENTFERNEN_FEHLER;
        constructor(public payload: any, public mitteilungsVorlageRolleMultiRequest: api.MitteilungsVorlageRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageEmpfaengerRollenEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_EMPFAENGER_ROLLEN_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationCommandMitteilungsVorlageEmpfaengerRollenEntfernenActions =
    PostMitteilungsVorlageEmpfaengerRollenEntfernenAction
    | PostMitteilungsVorlageEmpfaengerRollenEntfernenAusfuehrenAction
    | PostMitteilungsVorlageEmpfaengerRollenEntfernenErfolgreichAction
    | PostMitteilungsVorlageEmpfaengerRollenEntfernenFehlerAction
    | PostMitteilungsVorlageEmpfaengerRollenEntfernenNichtAusgefuehrtAction
    ;
