import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGS_TYPEN_ADMIN = '[VeranstaltungenModul] GetVeranstaltungsTypenAdmin laden';
export const GET_VERANSTALTUNGS_TYPEN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungsTypenAdmin ausführen';
export const GET_VERANSTALTUNGS_TYPEN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungsTypenAdmin laden erfolgreich';
export const GET_VERANSTALTUNGS_TYPEN_ADMIN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungsTypenAdmin Ladefehler';
export const GET_VERANSTALTUNGS_TYPEN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungsTypenAdmin nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungsTypenAdminAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_ADMIN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenAdminAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_ADMIN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenAdminErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.VeranstaltungsTyp>, public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenAdminFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_ADMIN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungsTypenAdminActions =
    GetVeranstaltungsTypenAdminAction
    | GetVeranstaltungsTypenAdminAusfuehrenAction
    | GetVeranstaltungsTypenAdminErfolgreichAction
    | GetVeranstaltungsTypenAdminFehlerAction
    | GetVeranstaltungsTypenAdminNichtAusgefuehrtAction
    ;
