import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_UEBERNEHMEN = '[IncidentsModul] PostIncidentUebernehmen laden';
export const POST_INCIDENT_UEBERNEHMEN_AUSFUEHREN = '[IncidentsModul] PostIncidentUebernehmen ausführen';
export const POST_INCIDENT_UEBERNEHMEN_ERFOLGREICH = '[IncidentsModul] PostIncidentUebernehmen laden erfolgreich';
export const POST_INCIDENT_UEBERNEHMEN_FEHLER = '[IncidentsModul] PostIncidentUebernehmen Ladefehler';
export const POST_INCIDENT_UEBERNEHMEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentUebernehmen nicht ausgeführt (evtl. Offline)';



export class PostIncidentUebernehmenAction implements Action {
    readonly type = POST_INCIDENT_UEBERNEHMEN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentUebernehmenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_UEBERNEHMEN_AUSFUEHREN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentUebernehmenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_UEBERNEHMEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentUebernehmenFehlerAction implements Action {
    readonly type = POST_INCIDENT_UEBERNEHMEN_FEHLER;
        constructor(public payload: any, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentUebernehmenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_UEBERNEHMEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentUebernehmenActions =
    PostIncidentUebernehmenAction
    | PostIncidentUebernehmenAusfuehrenAction
    | PostIncidentUebernehmenErfolgreichAction
    | PostIncidentUebernehmenFehlerAction
    | PostIncidentUebernehmenNichtAusgefuehrtAction
    ;
