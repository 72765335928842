

import {Action} from '@ngrx/store';

export const GET_TIME_ZONES = '[ApiModul] GetTimeZones laden';
export const GET_TIME_ZONES_AUSFUEHREN = '[ApiModul] GetTimeZones ausführen';
export const GET_TIME_ZONES_ERFOLGREICH = '[ApiModul] GetTimeZones laden erfolgreich';
export const GET_TIME_ZONES_FEHLER = '[ApiModul] GetTimeZones Ladefehler';
export const GET_TIME_ZONES_NICHT_AUSGEFUEHRT = '[ApiModul] GetTimeZones nicht ausgeführt (evtl. Offline)';



export class GetTimeZonesAction implements Action {
    readonly type = GET_TIME_ZONES;
        constructor(public optPayload: any = null) {}
    }
export class GetTimeZonesAusfuehrenAction implements Action {
    readonly type = GET_TIME_ZONES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetTimeZonesErfolgreichAction implements Action {
    readonly type = GET_TIME_ZONES_ERFOLGREICH;
        constructor(public optPayload: any = null) {}
    }
export class GetTimeZonesFehlerAction implements Action {
    readonly type = GET_TIME_ZONES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetTimeZonesNichtAusgefuehrtAction implements Action {
    readonly type = GET_TIME_ZONES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryTimeZonesActions =
    GetTimeZonesAction
    | GetTimeZonesAusfuehrenAction
    | GetTimeZonesErfolgreichAction
    | GetTimeZonesFehlerAction
    | GetTimeZonesNichtAusgefuehrtAction
    ;
