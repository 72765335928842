import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_FUNKPROTOKOLL_AKTUELLE_VERANSTALTUNGEN = '[VeranstaltungenModul] GetFunkprotokollAktuelleVeranstaltungen laden';
export const GET_FUNKPROTOKOLL_AKTUELLE_VERANSTALTUNGEN_AUSFUEHREN = '[VeranstaltungenModul] GetFunkprotokollAktuelleVeranstaltungen ausführen';
export const GET_FUNKPROTOKOLL_AKTUELLE_VERANSTALTUNGEN_ERFOLGREICH = '[VeranstaltungenModul] GetFunkprotokollAktuelleVeranstaltungen laden erfolgreich';
export const GET_FUNKPROTOKOLL_AKTUELLE_VERANSTALTUNGEN_FEHLER = '[VeranstaltungenModul] GetFunkprotokollAktuelleVeranstaltungen Ladefehler';
export const GET_FUNKPROTOKOLL_AKTUELLE_VERANSTALTUNGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunkprotokollAktuelleVeranstaltungen nicht ausgeführt (evtl. Offline)';



export class GetFunkprotokollAktuelleVeranstaltungenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_AKTUELLE_VERANSTALTUNGEN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunkprotokollAktuelleVeranstaltungenAusfuehrenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_AKTUELLE_VERANSTALTUNGEN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunkprotokollAktuelleVeranstaltungenErfolgreichAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_AKTUELLE_VERANSTALTUNGEN_ERFOLGREICH;
        constructor(public payload: Array<api.Veranstaltung>, public optPayload: any = null) {}
    }
export class GetFunkprotokollAktuelleVeranstaltungenFehlerAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_AKTUELLE_VERANSTALTUNGEN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFunkprotokollAktuelleVeranstaltungenNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_AKTUELLE_VERANSTALTUNGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunkprotokollAktuelleVeranstaltungenActions =
    GetFunkprotokollAktuelleVeranstaltungenAction
    | GetFunkprotokollAktuelleVeranstaltungenAusfuehrenAction
    | GetFunkprotokollAktuelleVeranstaltungenErfolgreichAction
    | GetFunkprotokollAktuelleVeranstaltungenFehlerAction
    | GetFunkprotokollAktuelleVeranstaltungenNichtAusgefuehrtAction
    ;
