import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICH_ADMINS = '[BenutzerverwaltungModul] GetFunktionsbereichAdmins laden';
export const GET_FUNKTIONSBEREICH_ADMINS_AUSFUEHREN = '[BenutzerverwaltungModul] GetFunktionsbereichAdmins ausführen';
export const GET_FUNKTIONSBEREICH_ADMINS_ERFOLGREICH = '[BenutzerverwaltungModul] GetFunktionsbereichAdmins laden erfolgreich';
export const GET_FUNKTIONSBEREICH_ADMINS_FEHLER = '[BenutzerverwaltungModul] GetFunktionsbereichAdmins Ladefehler';
export const GET_FUNKTIONSBEREICH_ADMINS_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetFunktionsbereichAdmins nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichAdminsAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ADMINS;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAdminsAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ADMINS_AUSFUEHREN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAdminsErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ADMINS_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichAdminBenutzer>,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAdminsFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ADMINS_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichAdminsNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ADMINS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryFunktionsbereichAdminsActions =
    GetFunktionsbereichAdminsAction
    | GetFunktionsbereichAdminsAusfuehrenAction
    | GetFunktionsbereichAdminsErfolgreichAction
    | GetFunktionsbereichAdminsFehlerAction
    | GetFunktionsbereichAdminsNichtAusgefuehrtAction
    ;
