import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_ABGEBEN = '[IncidentsModul] PostIncidentAbgeben laden';
export const POST_INCIDENT_ABGEBEN_AUSFUEHREN = '[IncidentsModul] PostIncidentAbgeben ausführen';
export const POST_INCIDENT_ABGEBEN_ERFOLGREICH = '[IncidentsModul] PostIncidentAbgeben laden erfolgreich';
export const POST_INCIDENT_ABGEBEN_FEHLER = '[IncidentsModul] PostIncidentAbgeben Ladefehler';
export const POST_INCIDENT_ABGEBEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentAbgeben nicht ausgeführt (evtl. Offline)';



export class PostIncidentAbgebenAction implements Action {
    readonly type = POST_INCIDENT_ABGEBEN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentAbgebenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_ABGEBEN_AUSFUEHREN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentAbgebenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_ABGEBEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentAbgebenFehlerAction implements Action {
    readonly type = POST_INCIDENT_ABGEBEN_FEHLER;
        constructor(public payload: any, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentAbgebenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_ABGEBEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentAbgebenActions =
    PostIncidentAbgebenAction
    | PostIncidentAbgebenAusfuehrenAction
    | PostIncidentAbgebenErfolgreichAction
    | PostIncidentAbgebenFehlerAction
    | PostIncidentAbgebenNichtAusgefuehrtAction
    ;
