import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGEN_CONFIG_TYPES = '[VeranstaltungenModul] GetVeranstaltungenConfigTypes laden';
export const GET_VERANSTALTUNGEN_CONFIG_TYPES_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungenConfigTypes ausführen';
export const GET_VERANSTALTUNGEN_CONFIG_TYPES_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungenConfigTypes laden erfolgreich';
export const GET_VERANSTALTUNGEN_CONFIG_TYPES_FEHLER = '[VeranstaltungenModul] GetVeranstaltungenConfigTypes Ladefehler';
export const GET_VERANSTALTUNGEN_CONFIG_TYPES_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungenConfigTypes nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungenConfigTypesAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_CONFIG_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenConfigTypesAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_CONFIG_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenConfigTypesErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_CONFIG_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetVeranstaltungenConfigTypesFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_CONFIG_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungenConfigTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_CONFIG_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungenConfigTypesActions =
    GetVeranstaltungenConfigTypesAction
    | GetVeranstaltungenConfigTypesAusfuehrenAction
    | GetVeranstaltungenConfigTypesErfolgreichAction
    | GetVeranstaltungenConfigTypesFehlerAction
    | GetVeranstaltungenConfigTypesNichtAusgefuehrtAction
    ;
