import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'sopi-area',
    templateUrl: './sopi-area.component.html',
    styleUrls: ['./sopi-area.component.scss'],
})
export class SopiAreaComponent implements OnInit {

    // VALUES
    @Input() labelText: string = null;
    @Input() textValue: string = null;
    @Input() placeholderText: string = null;
    @Input() showLengthCounter = true;
    @Input() isReadOnly = false;
    @Input() maximumLength = 160;

    // OUTPUT
    @Output() outChanged: EventEmitter<string> = new EventEmitter(true);

    constructor() {
    }

    ngOnInit() {
    }

    textAreaChanged(event: any) {
        this.outChanged.emit(event);
    }
}
