import {Action} from '@ngrx/store';
import {Checkliste} from '../../../clients/vorlagen';


export const PAGE_STATE_RESET = '[FunktionsbereichsauswahlModal] Page State reset';

export const NICHT_ANZUZEIGENDE_CHECKLISTEN_SETZEN = '[ChecklistenauswahlModal] Checklisten die aus der Auswahl entfernt werden sollen setzen';
export const BETEILIGTE_FUNKTIONSBEREICHE_SETZEN = '[ChecklistenauswahlModal] Beteiligte Funktionsbereiche die in der Auswahl angezeigt werden sollen setzen';
export const SELEKTIERTE_CHECKLISTEN = '[ChecklistenauswahlModal] Selektierte Checklisten';


// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;
}

// UI
export class NichtAnzuzeigendeChecklistenSetzenAction implements Action {
    readonly type = NICHT_ANZUZEIGENDE_CHECKLISTEN_SETZEN;

    constructor(public nichtAnzuzeigendeChecklisten: Array<any>) {
    }
}

export class BeteiligteFunktionsbereicheSetzenAction implements Action {
    readonly type = BETEILIGTE_FUNKTIONSBEREICHE_SETZEN;

    constructor(public beteiligteFunktionsbereiche: Array<string>) {
    }
}

export class SelektierteChecklistenAction implements Action {
    readonly type = SELEKTIERTE_CHECKLISTEN;

    constructor(public payload: Array<Checkliste>) {
    }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
    NichtAnzuzeigendeChecklistenSetzenAction
    | BeteiligteFunktionsbereicheSetzenAction
    | PageStateResetAction
    | SelektierteChecklistenAction
    ;
