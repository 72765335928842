import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI = '[VorlagenModul] PostDokumentBearbeitenBerechtigungHinzufuegenMulti laden';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_AUSFUEHREN = '[VorlagenModul] PostDokumentBearbeitenBerechtigungHinzufuegenMulti ausführen';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_ERFOLGREICH = '[VorlagenModul] PostDokumentBearbeitenBerechtigungHinzufuegenMulti laden erfolgreich';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_FEHLER = '[VorlagenModul] PostDokumentBearbeitenBerechtigungHinzufuegenMulti Ladefehler';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentBearbeitenBerechtigungHinzufuegenMulti nicht ausgeführt (evtl. Offline)';



export class PostDokumentBearbeitenBerechtigungHinzufuegenMultiAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI;
        constructor(public dokumentBerechtigungRolleMultiRequest: api.DokumentBerechtigungRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenMultiAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_AUSFUEHREN;
        constructor(public dokumentBerechtigungRolleMultiRequest: api.DokumentBerechtigungRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenMultiErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungRolleMultiRequest: api.DokumentBerechtigungRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenMultiFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungRolleMultiRequest: api.DokumentBerechtigungRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungHinzufuegenMultiNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentBearbeitenBerechtigungHinzufuegenMultiActions =
    PostDokumentBearbeitenBerechtigungHinzufuegenMultiAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenMultiAusfuehrenAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenMultiErfolgreichAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenMultiFehlerAction
    | PostDokumentBearbeitenBerechtigungHinzufuegenMultiNichtAusgefuehrtAction
    ;
