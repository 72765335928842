import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_DEAKTIVIEREN = '[VeranstaltungenModul] PostAufgabeDeaktivieren laden';
export const POST_AUFGABE_DEAKTIVIEREN_AUSFUEHREN = '[VeranstaltungenModul] PostAufgabeDeaktivieren ausführen';
export const POST_AUFGABE_DEAKTIVIEREN_ERFOLGREICH = '[VeranstaltungenModul] PostAufgabeDeaktivieren laden erfolgreich';
export const POST_AUFGABE_DEAKTIVIEREN_FEHLER = '[VeranstaltungenModul] PostAufgabeDeaktivieren Ladefehler';
export const POST_AUFGABE_DEAKTIVIEREN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostAufgabeDeaktivieren nicht ausgeführt (evtl. Offline)';



export class PostAufgabeDeaktivierenAction implements Action {
    readonly type = POST_AUFGABE_DEAKTIVIEREN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeDeaktivierenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_DEAKTIVIEREN_AUSFUEHREN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeDeaktivierenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_DEAKTIVIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeDeaktivierenFehlerAction implements Action {
    readonly type = POST_AUFGABE_DEAKTIVIEREN_FEHLER;
        constructor(public payload: any, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeDeaktivierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_DEAKTIVIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandAufgabeDeaktivierenActions =
    PostAufgabeDeaktivierenAction
    | PostAufgabeDeaktivierenAusfuehrenAction
    | PostAufgabeDeaktivierenErfolgreichAction
    | PostAufgabeDeaktivierenFehlerAction
    | PostAufgabeDeaktivierenNichtAusgefuehrtAction
    ;
