import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_AUFGABE_IDENTIFIER = '[VorlagenModul] GetAufgabeIdentifier laden';
export const GET_AUFGABE_IDENTIFIER_AUSFUEHREN = '[VorlagenModul] GetAufgabeIdentifier ausführen';
export const GET_AUFGABE_IDENTIFIER_ERFOLGREICH = '[VorlagenModul] GetAufgabeIdentifier laden erfolgreich';
export const GET_AUFGABE_IDENTIFIER_FEHLER = '[VorlagenModul] GetAufgabeIdentifier Ladefehler';
export const GET_AUFGABE_IDENTIFIER_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetAufgabeIdentifier nicht ausgeführt (evtl. Offline)';



export class GetAufgabeIdentifierAction implements Action {
    readonly type = GET_AUFGABE_IDENTIFIER;
        constructor(public optPayload: any = null) {}
    }
export class GetAufgabeIdentifierAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_IDENTIFIER_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetAufgabeIdentifierErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_IDENTIFIER_ERFOLGREICH;
        constructor(public payload: api.AufgabeIdentifier, public optPayload: any = null) {}
    }
export class GetAufgabeIdentifierFehlerAction implements Action {
    readonly type = GET_AUFGABE_IDENTIFIER_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetAufgabeIdentifierNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_IDENTIFIER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryAufgabeIdentifierActions =
    GetAufgabeIdentifierAction
    | GetAufgabeIdentifierAusfuehrenAction
    | GetAufgabeIdentifierErfolgreichAction
    | GetAufgabeIdentifierFehlerAction
    | GetAufgabeIdentifierNichtAusgefuehrtAction
    ;
