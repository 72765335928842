

import {Action} from '@ngrx/store';

export const GET_PROTOKOLL_EXCEL = '[EinlasskontrolleModul] GetProtokollExcel laden';
export const GET_PROTOKOLL_EXCEL_AUSFUEHREN = '[EinlasskontrolleModul] GetProtokollExcel ausführen';
export const GET_PROTOKOLL_EXCEL_ERFOLGREICH = '[EinlasskontrolleModul] GetProtokollExcel laden erfolgreich';
export const GET_PROTOKOLL_EXCEL_FEHLER = '[EinlasskontrolleModul] GetProtokollExcel Ladefehler';
export const GET_PROTOKOLL_EXCEL_NICHT_AUSGEFUEHRT = '[EinlasskontrolleModul] GetProtokollExcel nicht ausgeführt (evtl. Offline)';



export class GetProtokollExcelAction implements Action {
    readonly type = GET_PROTOKOLL_EXCEL;
        constructor( public alte_daten_entfernen:boolean = false , public optPayload: any = null) {}
    }
export class GetProtokollExcelAusfuehrenAction implements Action {
    readonly type = GET_PROTOKOLL_EXCEL_AUSFUEHREN;
        constructor( public alte_daten_entfernen:boolean = false , public optPayload: any = null) {}
    }
export class GetProtokollExcelErfolgreichAction implements Action {
    readonly type = GET_PROTOKOLL_EXCEL_ERFOLGREICH;
        constructor( public alte_daten_entfernen:boolean = false , public optPayload: any = null) {}
    }
export class GetProtokollExcelFehlerAction implements Action {
    readonly type = GET_PROTOKOLL_EXCEL_FEHLER;
        constructor(public payload: any,  public alte_daten_entfernen:boolean = false , public optPayload: any = null) {}
    }
export class GetProtokollExcelNichtAusgefuehrtAction implements Action {
    readonly type = GET_PROTOKOLL_EXCEL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type EinlasskontrolleQueryProtokollExcelActions =
    GetProtokollExcelAction
    | GetProtokollExcelAusfuehrenAction
    | GetProtokollExcelErfolgreichAction
    | GetProtokollExcelFehlerAction
    | GetProtokollExcelNichtAusgefuehrtAction
    ;
