

import {Action} from '@ngrx/store';

export const GET_INCIDENTS_REPORTING_XML_TEMPLATE = '[IncidentsModul] GetIncidentsReportingXmlTemplate laden';
export const GET_INCIDENTS_REPORTING_XML_TEMPLATE_AUSFUEHREN = '[IncidentsModul] GetIncidentsReportingXmlTemplate ausführen';
export const GET_INCIDENTS_REPORTING_XML_TEMPLATE_ERFOLGREICH = '[IncidentsModul] GetIncidentsReportingXmlTemplate laden erfolgreich';
export const GET_INCIDENTS_REPORTING_XML_TEMPLATE_FEHLER = '[IncidentsModul] GetIncidentsReportingXmlTemplate Ladefehler';
export const GET_INCIDENTS_REPORTING_XML_TEMPLATE_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentsReportingXmlTemplate nicht ausgeführt (evtl. Offline)';



export class GetIncidentsReportingXmlTemplateAction implements Action {
    readonly type = GET_INCIDENTS_REPORTING_XML_TEMPLATE;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsReportingXmlTemplateAusfuehrenAction implements Action {
    readonly type = GET_INCIDENTS_REPORTING_XML_TEMPLATE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsReportingXmlTemplateErfolgreichAction implements Action {
    readonly type = GET_INCIDENTS_REPORTING_XML_TEMPLATE_ERFOLGREICH;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsReportingXmlTemplateFehlerAction implements Action {
    readonly type = GET_INCIDENTS_REPORTING_XML_TEMPLATE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetIncidentsReportingXmlTemplateNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENTS_REPORTING_XML_TEMPLATE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentsReportingXmlTemplateActions =
    GetIncidentsReportingXmlTemplateAction
    | GetIncidentsReportingXmlTemplateAusfuehrenAction
    | GetIncidentsReportingXmlTemplateErfolgreichAction
    | GetIncidentsReportingXmlTemplateFehlerAction
    | GetIncidentsReportingXmlTemplateNichtAusgefuehrtAction
    ;
