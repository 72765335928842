import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICHE_CHECKLISTEN = '[VorlagenModul] GetFunktionsbereicheChecklisten laden';
export const GET_FUNKTIONSBEREICHE_CHECKLISTEN_AUSFUEHREN = '[VorlagenModul] GetFunktionsbereicheChecklisten ausführen';
export const GET_FUNKTIONSBEREICHE_CHECKLISTEN_ERFOLGREICH = '[VorlagenModul] GetFunktionsbereicheChecklisten laden erfolgreich';
export const GET_FUNKTIONSBEREICHE_CHECKLISTEN_FEHLER = '[VorlagenModul] GetFunktionsbereicheChecklisten Ladefehler';
export const GET_FUNKTIONSBEREICHE_CHECKLISTEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetFunktionsbereicheChecklisten nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereicheChecklistenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_CHECKLISTEN;
        constructor( public typAdminCanShow:boolean , public optPayload: any = null) {}
    }
export class GetFunktionsbereicheChecklistenAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_CHECKLISTEN_AUSFUEHREN;
        constructor( public typAdminCanShow:boolean , public optPayload: any = null) {}
    }
export class GetFunktionsbereicheChecklistenErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_CHECKLISTEN_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichChecklisten>,  public typAdminCanShow:boolean , public optPayload: any = null) {}
    }
export class GetFunktionsbereicheChecklistenFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_CHECKLISTEN_FEHLER;
        constructor(public payload: any,  public typAdminCanShow:boolean , public optPayload: any = null) {}
    }
export class GetFunktionsbereicheChecklistenNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICHE_CHECKLISTEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryFunktionsbereicheChecklistenActions =
    GetFunktionsbereicheChecklistenAction
    | GetFunktionsbereicheChecklistenAusfuehrenAction
    | GetFunktionsbereicheChecklistenErfolgreichAction
    | GetFunktionsbereicheChecklistenFehlerAction
    | GetFunktionsbereicheChecklistenNichtAusgefuehrtAction
    ;
