import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENTS_REPORT_PDF = '[IncidentsModul] GetIncidentsReportPdf laden';
export const GET_INCIDENTS_REPORT_PDF_AUSFUEHREN = '[IncidentsModul] GetIncidentsReportPdf ausführen';
export const GET_INCIDENTS_REPORT_PDF_ERFOLGREICH = '[IncidentsModul] GetIncidentsReportPdf laden erfolgreich';
export const GET_INCIDENTS_REPORT_PDF_FEHLER = '[IncidentsModul] GetIncidentsReportPdf Ladefehler';
export const GET_INCIDENTS_REPORT_PDF_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentsReportPdf nicht ausgeführt (evtl. Offline)';



export class GetIncidentsReportPdfAction implements Action {
    readonly type = GET_INCIDENTS_REPORT_PDF;
        constructor(public incidentsReportRequest: api.IncidentsReportRequest, public optPayload: any = null) {}
    }
export class GetIncidentsReportPdfAusfuehrenAction implements Action {
    readonly type = GET_INCIDENTS_REPORT_PDF_AUSFUEHREN;
        constructor(public incidentsReportRequest: api.IncidentsReportRequest, public optPayload: any = null) {}
    }
export class GetIncidentsReportPdfErfolgreichAction implements Action {
    readonly type = GET_INCIDENTS_REPORT_PDF_ERFOLGREICH;
        constructor(public incidentsReportRequest: api.IncidentsReportRequest, public optPayload: any = null) {}
    }
export class GetIncidentsReportPdfFehlerAction implements Action {
    readonly type = GET_INCIDENTS_REPORT_PDF_FEHLER;
        constructor(public payload: any, public incidentsReportRequest: api.IncidentsReportRequest, public optPayload: any = null) {}
    }
export class GetIncidentsReportPdfNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENTS_REPORT_PDF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentsReportPdfActions =
    GetIncidentsReportPdfAction
    | GetIncidentsReportPdfAusfuehrenAction
    | GetIncidentsReportPdfErfolgreichAction
    | GetIncidentsReportPdfFehlerAction
    | GetIncidentsReportPdfNichtAusgefuehrtAction
    ;
