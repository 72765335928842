import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_BENUTZER_NUTZUNGSBEDINGUNGEN = '[BenutzerverwaltungModul] GetBenutzerNutzungsbedingungen laden';
export const GET_BENUTZER_NUTZUNGSBEDINGUNGEN_AUSFUEHREN = '[BenutzerverwaltungModul] GetBenutzerNutzungsbedingungen ausführen';
export const GET_BENUTZER_NUTZUNGSBEDINGUNGEN_ERFOLGREICH = '[BenutzerverwaltungModul] GetBenutzerNutzungsbedingungen laden erfolgreich';
export const GET_BENUTZER_NUTZUNGSBEDINGUNGEN_FEHLER = '[BenutzerverwaltungModul] GetBenutzerNutzungsbedingungen Ladefehler';
export const GET_BENUTZER_NUTZUNGSBEDINGUNGEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetBenutzerNutzungsbedingungen nicht ausgeführt (evtl. Offline)';



export class GetBenutzerNutzungsbedingungenAction implements Action {
    readonly type = GET_BENUTZER_NUTZUNGSBEDINGUNGEN;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerNutzungsbedingungenAusfuehrenAction implements Action {
    readonly type = GET_BENUTZER_NUTZUNGSBEDINGUNGEN_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerNutzungsbedingungenErfolgreichAction implements Action {
    readonly type = GET_BENUTZER_NUTZUNGSBEDINGUNGEN_ERFOLGREICH;
        constructor(public payload: api.BenutzerNutzungsBedingungen, public optPayload: any = null) {}
    }
export class GetBenutzerNutzungsbedingungenFehlerAction implements Action {
    readonly type = GET_BENUTZER_NUTZUNGSBEDINGUNGEN_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetBenutzerNutzungsbedingungenNichtAusgefuehrtAction implements Action {
    readonly type = GET_BENUTZER_NUTZUNGSBEDINGUNGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryBenutzerNutzungsbedingungenActions =
    GetBenutzerNutzungsbedingungenAction
    | GetBenutzerNutzungsbedingungenAusfuehrenAction
    | GetBenutzerNutzungsbedingungenErfolgreichAction
    | GetBenutzerNutzungsbedingungenFehlerAction
    | GetBenutzerNutzungsbedingungenNichtAusgefuehrtAction
    ;
