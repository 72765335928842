import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGS_TYPEN_CONSTANTS = '[VeranstaltungstypenModul] GetVeranstaltungsTypenConstants laden';
export const GET_VERANSTALTUNGS_TYPEN_CONSTANTS_AUSFUEHREN = '[VeranstaltungstypenModul] GetVeranstaltungsTypenConstants ausführen';
export const GET_VERANSTALTUNGS_TYPEN_CONSTANTS_ERFOLGREICH = '[VeranstaltungstypenModul] GetVeranstaltungsTypenConstants laden erfolgreich';
export const GET_VERANSTALTUNGS_TYPEN_CONSTANTS_FEHLER = '[VeranstaltungstypenModul] GetVeranstaltungsTypenConstants Ladefehler';
export const GET_VERANSTALTUNGS_TYPEN_CONSTANTS_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] GetVeranstaltungsTypenConstants nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungsTypenConstantsAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_CONSTANTS;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenConstantsAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_CONSTANTS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenConstantsErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_CONSTANTS_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenConstantsFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_CONSTANTS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenConstantsNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_CONSTANTS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenQueryVeranstaltungsTypenConstantsActions =
    GetVeranstaltungsTypenConstantsAction
    | GetVeranstaltungsTypenConstantsAusfuehrenAction
    | GetVeranstaltungsTypenConstantsErfolgreichAction
    | GetVeranstaltungsTypenConstantsFehlerAction
    | GetVeranstaltungsTypenConstantsNichtAusgefuehrtAction
    ;
