import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_FREIGEBEN = '[VeranstaltungenModul] PostFunktionsbereichFreigeben laden';
export const POST_FUNKTIONSBEREICH_FREIGEBEN_AUSFUEHREN = '[VeranstaltungenModul] PostFunktionsbereichFreigeben ausführen';
export const POST_FUNKTIONSBEREICH_FREIGEBEN_ERFOLGREICH = '[VeranstaltungenModul] PostFunktionsbereichFreigeben laden erfolgreich';
export const POST_FUNKTIONSBEREICH_FREIGEBEN_FEHLER = '[VeranstaltungenModul] PostFunktionsbereichFreigeben Ladefehler';
export const POST_FUNKTIONSBEREICH_FREIGEBEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFunktionsbereichFreigeben nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichFreigebenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGEBEN;
        constructor(public funktionsbereichFreigebenRequest: api.FunktionsbereichFreigebenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichFreigebenAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGEBEN_AUSFUEHREN;
        constructor(public funktionsbereichFreigebenRequest: api.FunktionsbereichFreigebenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichFreigebenErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGEBEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funktionsbereichFreigebenRequest: api.FunktionsbereichFreigebenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichFreigebenFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGEBEN_FEHLER;
        constructor(public payload: any, public funktionsbereichFreigebenRequest: api.FunktionsbereichFreigebenRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichFreigebenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGEBEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFunktionsbereichFreigebenActions =
    PostFunktionsbereichFreigebenAction
    | PostFunktionsbereichFreigebenAusfuehrenAction
    | PostFunktionsbereichFreigebenErfolgreichAction
    | PostFunktionsbereichFreigebenFehlerAction
    | PostFunktionsbereichFreigebenNichtAusgefuehrtAction
    ;
