import {CUSTOM_ELEMENTS_SCHEMA, NgModule, TemplateRef} from '@angular/core';
import {SopiScrollComponent} from './sopi-scroll.component';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [CommonModule],
    declarations: [SopiScrollComponent],
    exports: [SopiScrollComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SopiScrollModule {
}
