import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_REAKTIVIEREN = '[VeranstaltungenModul] PostVeranstaltungReaktivieren laden';
export const POST_VERANSTALTUNG_REAKTIVIEREN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungReaktivieren ausführen';
export const POST_VERANSTALTUNG_REAKTIVIEREN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungReaktivieren laden erfolgreich';
export const POST_VERANSTALTUNG_REAKTIVIEREN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungReaktivieren Ladefehler';
export const POST_VERANSTALTUNG_REAKTIVIEREN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungReaktivieren nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungReaktivierenAction implements Action {
    readonly type = POST_VERANSTALTUNG_REAKTIVIEREN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungReaktivierenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_REAKTIVIEREN_AUSFUEHREN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungReaktivierenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_REAKTIVIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungReaktivierenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_REAKTIVIEREN_FEHLER;
        constructor(public payload: any, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungReaktivierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_REAKTIVIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungReaktivierenActions =
    PostVeranstaltungReaktivierenAction
    | PostVeranstaltungReaktivierenAusfuehrenAction
    | PostVeranstaltungReaktivierenErfolgreichAction
    | PostVeranstaltungReaktivierenFehlerAction
    | PostVeranstaltungReaktivierenNichtAusgefuehrtAction
    ;
