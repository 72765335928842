import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNGS_TYP_ANLEGEN = '[VeranstaltungstypenModul] PostVeranstaltungsTypAnlegen laden';
export const POST_VERANSTALTUNGS_TYP_ANLEGEN_AUSFUEHREN = '[VeranstaltungstypenModul] PostVeranstaltungsTypAnlegen ausführen';
export const POST_VERANSTALTUNGS_TYP_ANLEGEN_ERFOLGREICH = '[VeranstaltungstypenModul] PostVeranstaltungsTypAnlegen laden erfolgreich';
export const POST_VERANSTALTUNGS_TYP_ANLEGEN_FEHLER = '[VeranstaltungstypenModul] PostVeranstaltungsTypAnlegen Ladefehler';
export const POST_VERANSTALTUNGS_TYP_ANLEGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] PostVeranstaltungsTypAnlegen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungsTypAnlegenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ANLEGEN;
        constructor(public veranstaltungsTypStammRequest: api.VeranstaltungsTypStammRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAnlegenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ANLEGEN_AUSFUEHREN;
        constructor(public veranstaltungsTypStammRequest: api.VeranstaltungsTypStammRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAnlegenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ANLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungsTypStammRequest: api.VeranstaltungsTypStammRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAnlegenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ANLEGEN_FEHLER;
        constructor(public payload: any, public veranstaltungsTypStammRequest: api.VeranstaltungsTypStammRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAnlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ANLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenCommandVeranstaltungsTypAnlegenActions =
    PostVeranstaltungsTypAnlegenAction
    | PostVeranstaltungsTypAnlegenAusfuehrenAction
    | PostVeranstaltungsTypAnlegenErfolgreichAction
    | PostVeranstaltungsTypAnlegenFehlerAction
    | PostVeranstaltungsTypAnlegenNichtAusgefuehrtAction
    ;
