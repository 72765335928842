import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_DELEGIEREN = '[IncidentsModul] PostIncidentDelegieren laden';
export const POST_INCIDENT_DELEGIEREN_AUSFUEHREN = '[IncidentsModul] PostIncidentDelegieren ausführen';
export const POST_INCIDENT_DELEGIEREN_ERFOLGREICH = '[IncidentsModul] PostIncidentDelegieren laden erfolgreich';
export const POST_INCIDENT_DELEGIEREN_FEHLER = '[IncidentsModul] PostIncidentDelegieren Ladefehler';
export const POST_INCIDENT_DELEGIEREN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentDelegieren nicht ausgeführt (evtl. Offline)';



export class PostIncidentDelegierenAction implements Action {
    readonly type = POST_INCIDENT_DELEGIEREN;
        constructor(public incidentDelegationRequest: api.IncidentDelegationRequest, public optPayload: any = null) {}
    }
export class PostIncidentDelegierenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_DELEGIEREN_AUSFUEHREN;
        constructor(public incidentDelegationRequest: api.IncidentDelegationRequest, public optPayload: any = null) {}
    }
export class PostIncidentDelegierenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_DELEGIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentDelegationRequest: api.IncidentDelegationRequest, public optPayload: any = null) {}
    }
export class PostIncidentDelegierenFehlerAction implements Action {
    readonly type = POST_INCIDENT_DELEGIEREN_FEHLER;
        constructor(public payload: any, public incidentDelegationRequest: api.IncidentDelegationRequest, public optPayload: any = null) {}
    }
export class PostIncidentDelegierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_DELEGIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentDelegierenActions =
    PostIncidentDelegierenAction
    | PostIncidentDelegierenAusfuehrenAction
    | PostIncidentDelegierenErfolgreichAction
    | PostIncidentDelegierenFehlerAction
    | PostIncidentDelegierenNichtAusgefuehrtAction
    ;
