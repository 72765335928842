export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '0.4309.478',
    name: 'orga-app-desktop-client',
    versionDate: '2024-11-04T07:59:36.535Z',
    description: 'ORGArena ORGA App',
    gitCommitHash: 'ga673fd5c',
    gitCommitDate: '2024-11-04T07:57:32.000Z',
    versionLong: '0.4309.478-ga673fd5c',
    gitTag: 'v1.48.1',
};
export default versions;
