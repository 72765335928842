import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN = '[VeranstaltungenModul] PostDokumentBerechtigungEntfernen laden';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_AUSFUEHREN = '[VeranstaltungenModul] PostDokumentBerechtigungEntfernen ausführen';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_ERFOLGREICH = '[VeranstaltungenModul] PostDokumentBerechtigungEntfernen laden erfolgreich';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_FEHLER = '[VeranstaltungenModul] PostDokumentBerechtigungEntfernen Ladefehler';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostDokumentBerechtigungEntfernen nicht ausgeführt (evtl. Offline)';



export class PostDokumentBerechtigungEntfernenAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN;
        constructor(public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_AUSFUEHREN;
        constructor(public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungRequest: api.DokumentBerechtigungRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandDokumentBerechtigungEntfernenActions =
    PostDokumentBerechtigungEntfernenAction
    | PostDokumentBerechtigungEntfernenAusfuehrenAction
    | PostDokumentBerechtigungEntfernenErfolgreichAction
    | PostDokumentBerechtigungEntfernenFehlerAction
    | PostDokumentBerechtigungEntfernenNichtAusgefuehrtAction
    ;
