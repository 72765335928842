import {Action} from '@ngrx/store';
import {BereichListItem, BereichTrigger, Rolle, Trigger} from '../../../clients/displaysteuerung';
import {FREIGABE_ROLLEN_SETZEN} from '../checklisten/checklisten.actions.ui';

export const PAGE_STATE_RESET = '[DisplaysteuerungPage] Page State reset';

export const BEREICHE_LISTE_LADEN = '[DiplaysteuerungPage] Bereiche Liste laden';
export const AKTUELLEN_BEREICH_GESETZT = '[DisplaysteuerungPage] Aktueller Bereich gesetzt';
export const BEREICH_HINZUFUEGEN =  '[DisplaysteuerungPage] Bereich hinzufügen';
export const BEREICH_ENTFERNEN =  '[DisplaysteuerungPage] Bereich entfernen';

export const BEREICH_TITEL_SETZEN = '[DisplaysteuerungPage] Bereich Titel setzen';
export const BEREICH_BESCHREIBUNG_SETZEN = '[DisplaysteuerungPage] Bereich Beschreibung setzen';
export const BEREICH_ZUSATZ_BESCHREIBUNG_SETZEN = '[DisplaysteuerungPage] Bereich Zusatz-Beschreibung setzen';

export const BEREICH_IST_TRIGGER_LISTE_SETZEN =  '[DisplaysteuerungPage] Bereich IstTriggerListe setzen';
export const BEREICH_IST_EXTERNE_URL_SETZEN =  '[DisplaysteuerungPage] Bereich IstExterneUrl setzen';
export const BEREICH_EXTERNE_URL_SETZEN =  '[DisplaysteuerungPage] Bereich ExterneUrl setzen';
export const BEREICH_EXTERNE_URL_TESTEN =  '[DisplaysteuerungPage] Bereich ExterneUrl testen';

export const BEREICH_BERECHTIGUNG_ROLLEN_SETZEN = '[DisplaysteuerungPage] Bereich Berechtigung Rollen setzen';
export const BEREICH_BERECHTIGUNG_ROLLE_ENTFERNEN = '[DisplaysteuerungPage] Bereich Berechtigung Rolle entfernen';
export const AKTUELLE_ROLLE_BERECHTIGUNG_SETZEN = '[DisplaysteuerungPage] Aktuelle Berechtigung Rolle setzen';

export const BEREICH_KOPFDATEN_SPEICHERN = '[DisplaysteuerungPage] Bereich Kopfdaten speichern';

export const BEREICH_TRIGGER_HINZUFUEGEN = '[DisplaysteuerungPage] Bereich Trigger hinzufügen';
export const BEREICH_TRIGGER_ENTFERNEN = '[DisplaysteuerungPage] Bereich Trigger entfernen';
export const AKTUELLEN_TRIGGER_GESETZT = '[DisplaysteuerungPage] Aktuellen Trigger gesetzt';
export const AKTUELLEN_TRIGGER_ICON_GESETZT = '[DisplaysteuerungPage] Aktuellen Trigger Icon gesetzt';

export const AKTUELLEN_TRIGGER_AUSLOESEN = '[DisplaysteuerungPage] Aktuellen Trigger auslösen';

// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;
}

export class BereicheListeLadenAction implements Action {
    readonly type = BEREICHE_LISTE_LADEN;
}

export class AktuellenBereichGesetztAction implements Action {
    readonly type = AKTUELLEN_BEREICH_GESETZT;

    constructor(public payload: BereichListItem) {
    }
}

export class BereichHinzufuegenAction implements Action {
    readonly type = BEREICH_HINZUFUEGEN;
}

export class BereichEntfernenAction implements Action {
    readonly type = BEREICH_ENTFERNEN;
}

export class BereichTitelSetzenAction implements Action {
    readonly type = BEREICH_TITEL_SETZEN;

    constructor(public payload: string) {
    }
}

export class BereichBeschreibungSetzenAction implements Action {
    readonly type = BEREICH_BESCHREIBUNG_SETZEN;

    constructor(public payload: string) {
    }
}

export class BereichZusatzBeschreibungSetzenAction implements Action {
    readonly type = BEREICH_ZUSATZ_BESCHREIBUNG_SETZEN;

    constructor(public payload: string) {
    }
}

export class BereichIstTriggerListeSetzenAction implements Action {
    readonly type = BEREICH_IST_TRIGGER_LISTE_SETZEN;

    constructor(public payload: boolean) {
    }
}

export class BereichIstExterneURLSetzenAction implements Action {
    readonly type = BEREICH_IST_EXTERNE_URL_SETZEN;

    constructor(public payload: boolean) {
    }
}

export class BereichExterneURLSetzenAction implements Action {
    readonly type = BEREICH_EXTERNE_URL_SETZEN;

    constructor(public payload: string) {
    }
}

export class BereichExterneURLTestenAction implements Action {
    readonly type = BEREICH_EXTERNE_URL_TESTEN;

    constructor() {
    }
}

export class BereichBerechtigungRollenSetzenAction implements Action {
    readonly type = BEREICH_BERECHTIGUNG_ROLLEN_SETZEN;

    constructor(public payload: Array<string>) {
    }
}

export class BereichBerechtigungRolleEntfernenAction implements Action {
    readonly type = BEREICH_BERECHTIGUNG_ROLLE_ENTFERNEN;

    constructor() {
    }
}

export class AktuelleRolleBerechtigungSetzenAction implements Action {
    readonly type = AKTUELLE_ROLLE_BERECHTIGUNG_SETZEN;

    constructor(public payload: Rolle) {
    }
}


export class BereichKopfdatenSpeichernAction implements Action {
    readonly type = BEREICH_KOPFDATEN_SPEICHERN;
}



export class BereichTriggerHinzufuegenAction implements Action {
    readonly type = BEREICH_TRIGGER_HINZUFUEGEN;

    constructor(public payload: Array<Trigger>) {
    }
}

export class BereichTriggerEntfernenAction implements Action {
    readonly type = BEREICH_TRIGGER_ENTFERNEN;
}

export class AktuellenTriggerGesetztAction implements Action {
    readonly type = AKTUELLEN_TRIGGER_GESETZT;

    constructor(public payload: BereichTrigger) {
    }
}

export class AktuellenTriggerIconGesetztAction implements Action {
    readonly type = AKTUELLEN_TRIGGER_ICON_GESETZT;

    constructor(public payload: string) {
    }
}

export class AktuellenTriggerAusloesenAction implements Action {
    readonly type = AKTUELLEN_TRIGGER_AUSLOESEN;

    constructor() {
    }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
    BereicheListeLadenAction
    | AktuellenBereichGesetztAction
    | BereichHinzufuegenAction
    | BereichEntfernenAction
    | AktuellenTriggerGesetztAction
    | AktuellenTriggerIconGesetztAction
    | BereichTitelSetzenAction
    | BereichBeschreibungSetzenAction
    | BereichZusatzBeschreibungSetzenAction
    | BereichIstTriggerListeSetzenAction
    | BereichIstExterneURLSetzenAction
    | BereichExterneURLSetzenAction
    | BereichExterneURLTestenAction
    | BereichBerechtigungRollenSetzenAction
    | BereichBerechtigungRolleEntfernenAction
    | AktuelleRolleBerechtigungSetzenAction
    | BereichKopfdatenSpeichernAction
    | BereichTriggerHinzufuegenAction
    | BereichTriggerEntfernenAction
    | AktuellenTriggerAusloesenAction
    | PageStateResetAction
    ;


