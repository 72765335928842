import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const POST_BEREICH_ENTFERNEN = '[DisplaysteuerungModul] PostBereichEntfernen laden';
export const POST_BEREICH_ENTFERNEN_AUSFUEHREN = '[DisplaysteuerungModul] PostBereichEntfernen ausführen';
export const POST_BEREICH_ENTFERNEN_ERFOLGREICH = '[DisplaysteuerungModul] PostBereichEntfernen laden erfolgreich';
export const POST_BEREICH_ENTFERNEN_FEHLER = '[DisplaysteuerungModul] PostBereichEntfernen Ladefehler';
export const POST_BEREICH_ENTFERNEN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] PostBereichEntfernen nicht ausgeführt (evtl. Offline)';



export class PostBereichEntfernenAction implements Action {
    readonly type = POST_BEREICH_ENTFERNEN;
        constructor(public bereichRequest: api.BereichRequest, public optPayload: any = null) {}
    }
export class PostBereichEntfernenAusfuehrenAction implements Action {
    readonly type = POST_BEREICH_ENTFERNEN_AUSFUEHREN;
        constructor(public bereichRequest: api.BereichRequest, public optPayload: any = null) {}
    }
export class PostBereichEntfernenErfolgreichAction implements Action {
    readonly type = POST_BEREICH_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public bereichRequest: api.BereichRequest, public optPayload: any = null) {}
    }
export class PostBereichEntfernenFehlerAction implements Action {
    readonly type = POST_BEREICH_ENTFERNEN_FEHLER;
        constructor(public payload: any, public bereichRequest: api.BereichRequest, public optPayload: any = null) {}
    }
export class PostBereichEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_BEREICH_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungCommandBereichEntfernenActions =
    PostBereichEntfernenAction
    | PostBereichEntfernenAusfuehrenAction
    | PostBereichEntfernenErfolgreichAction
    | PostBereichEntfernenFehlerAction
    | PostBereichEntfernenNichtAusgefuehrtAction
    ;
