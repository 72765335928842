import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.query.bereiche-liste';
import * as api from '../../../clients/displaysteuerung';


    @Injectable()
    export class DisplaysteuerungBereicheListeEffects {

        @Effect()
            BereicheListe$ = this.actions$.pipe(
                ofType(ac.GET_BEREICHE_LISTE),
                flatMap((x: ac.GetBereicheListeAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().getBereicheListe().pipe(
                    map((result: any) => {
                        const nextAction = new ac.GetBereicheListeErfolgreichAction(result,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.GetBereicheListeFehlerAction(error,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.DisplaysteuerungQueryService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.DisplaysteuerungQueryService {
                if (this.service === null) {
                    this.service = this.injector.get(api.DisplaysteuerungQueryService);
                }
                return this.service;
            }
        
}
