import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.command.dokument-veranstaltungs-typen-entfernen';
import * as api from '../../../clients/vorlagen';


    @Injectable()
    export class VorlagenDokumentVeranstaltungsTypenEntfernenEffects {

        @Effect()
            DokumentVeranstaltungsTypenEntfernen$ = this.actions$.pipe(
                ofType(ac.POST_DOKUMENT_VERANSTALTUNGS_TYPEN_ENTFERNEN),
                flatMap((x: ac.PostDokumentVeranstaltungsTypenEntfernenAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().postDokumentVeranstaltungsTypenEntfernen(x.dokumentVeranstaltungsTypMultiRequest).pipe(
                    map((result: any) => {
                        const nextAction = new ac.PostDokumentVeranstaltungsTypenEntfernenErfolgreichAction(result, x.dokumentVeranstaltungsTypMultiRequest,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.PostDokumentVeranstaltungsTypenEntfernenFehlerAction(error, x.dokumentVeranstaltungsTypMultiRequest,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.DokumenteCommandService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.DokumenteCommandService {
                if (this.service === null) {
                    this.service = this.injector.get(api.DokumenteCommandService);
                }
                return this.service;
            }
        
}
