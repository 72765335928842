import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_CHECKLISTE_ENTFERNEN = '[VeranstaltungenModul] PostChecklisteEntfernen laden';
export const POST_CHECKLISTE_ENTFERNEN_AUSFUEHREN = '[VeranstaltungenModul] PostChecklisteEntfernen ausführen';
export const POST_CHECKLISTE_ENTFERNEN_ERFOLGREICH = '[VeranstaltungenModul] PostChecklisteEntfernen laden erfolgreich';
export const POST_CHECKLISTE_ENTFERNEN_FEHLER = '[VeranstaltungenModul] PostChecklisteEntfernen Ladefehler';
export const POST_CHECKLISTE_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostChecklisteEntfernen nicht ausgeführt (evtl. Offline)';



export class PostChecklisteEntfernenAction implements Action {
    readonly type = POST_CHECKLISTE_ENTFERNEN;
        constructor(public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteEntfernenAusfuehrenAction implements Action {
    readonly type = POST_CHECKLISTE_ENTFERNEN_AUSFUEHREN;
        constructor(public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteEntfernenErfolgreichAction implements Action {
    readonly type = POST_CHECKLISTE_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteEntfernenFehlerAction implements Action {
    readonly type = POST_CHECKLISTE_ENTFERNEN_FEHLER;
        constructor(public payload: any, public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_CHECKLISTE_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandChecklisteEntfernenActions =
    PostChecklisteEntfernenAction
    | PostChecklisteEntfernenAusfuehrenAction
    | PostChecklisteEntfernenErfolgreichAction
    | PostChecklisteEntfernenFehlerAction
    | PostChecklisteEntfernenNichtAusgefuehrtAction
    ;
