import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_ABSCHLIESSEN = '[IncidentsModul] PostIncidentAbschliessen laden';
export const POST_INCIDENT_ABSCHLIESSEN_AUSFUEHREN = '[IncidentsModul] PostIncidentAbschliessen ausführen';
export const POST_INCIDENT_ABSCHLIESSEN_ERFOLGREICH = '[IncidentsModul] PostIncidentAbschliessen laden erfolgreich';
export const POST_INCIDENT_ABSCHLIESSEN_FEHLER = '[IncidentsModul] PostIncidentAbschliessen Ladefehler';
export const POST_INCIDENT_ABSCHLIESSEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentAbschliessen nicht ausgeführt (evtl. Offline)';



export class PostIncidentAbschliessenAction implements Action {
    readonly type = POST_INCIDENT_ABSCHLIESSEN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentAbschliessenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_ABSCHLIESSEN_AUSFUEHREN;
        constructor(public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentAbschliessenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_ABSCHLIESSEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentAbschliessenFehlerAction implements Action {
    readonly type = POST_INCIDENT_ABSCHLIESSEN_FEHLER;
        constructor(public payload: any, public incidentRequest: api.IncidentRequest, public optPayload: any = null) {}
    }
export class PostIncidentAbschliessenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_ABSCHLIESSEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentAbschliessenActions =
    PostIncidentAbschliessenAction
    | PostIncidentAbschliessenAusfuehrenAction
    | PostIncidentAbschliessenErfolgreichAction
    | PostIncidentAbschliessenFehlerAction
    | PostIncidentAbschliessenNichtAusgefuehrtAction
    ;
