import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_AUFGABE_FEHLER_BILD_IDS = '[VeranstaltungenModul] GetAufgabeFehlerBildIds laden';
export const GET_AUFGABE_FEHLER_BILD_IDS_AUSFUEHREN = '[VeranstaltungenModul] GetAufgabeFehlerBildIds ausführen';
export const GET_AUFGABE_FEHLER_BILD_IDS_ERFOLGREICH = '[VeranstaltungenModul] GetAufgabeFehlerBildIds laden erfolgreich';
export const GET_AUFGABE_FEHLER_BILD_IDS_FEHLER = '[VeranstaltungenModul] GetAufgabeFehlerBildIds Ladefehler';
export const GET_AUFGABE_FEHLER_BILD_IDS_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetAufgabeFehlerBildIds nicht ausgeführt (evtl. Offline)';



export class GetAufgabeFehlerBildIdsAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_IDS;
        constructor( public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBildIdsAusfuehrenAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_IDS_AUSFUEHREN;
        constructor( public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBildIdsErfolgreichAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_IDS_ERFOLGREICH;
        constructor(public payload: api.FehlerBildIds,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBildIdsFehlerAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_IDS_FEHLER;
        constructor(public payload: any,  public aufgabe_id:string , public optPayload: any = null) {}
    }
export class GetAufgabeFehlerBildIdsNichtAusgefuehrtAction implements Action {
    readonly type = GET_AUFGABE_FEHLER_BILD_IDS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryAufgabeFehlerBildIdsActions =
    GetAufgabeFehlerBildIdsAction
    | GetAufgabeFehlerBildIdsAusfuehrenAction
    | GetAufgabeFehlerBildIdsErfolgreichAction
    | GetAufgabeFehlerBildIdsFehlerAction
    | GetAufgabeFehlerBildIdsNichtAusgefuehrtAction
    ;
