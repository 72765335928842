import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'sopi-fab',
    templateUrl: './sopi-fab.component.html',
    styleUrls: ['./sopi-fab.component.scss'],
})
export class SopiFabComponent implements OnInit {

    // VALUES
    @Input() widthValue: string = null;
    @Input() heightValue: string = null;
    @Input() colorValue: string = null;
    @Input() iconSvgValue: string = null;

    // SCHALTER
    @Input() istDisabled = false;
    @Input() istSelektiert = false;

    constructor() {
    }

    ngOnInit() {
    }

}
