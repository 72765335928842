import {Injectable} from '@angular/core';
import * as websocketActions from './websocket.actions';
import {
    WS_AUFGABE_ERINNERUNG,
    WS_AUFGABE_ESKALATION,
    WS_AUFGABE_NICHT_OK,
    WS_COMMAND_FAILED,
    WS_COMMAND_SUCCEEDED,
    WS_FUNKTIONSBEREICH_OK,
    WS_INCIDENT_ABTEILUNG,
    WS_INCIDENT_DELEGATION,
    WS_INCIDENT_ZENTRALE,
    WS_MITTEILUNG,
    WsAufgabeErinnerungAction,
    WsAufgabeEskalationAction,
    WsAufgabeNichtOkAction,
    WsCommandFailedAction,
    WsCommandSucceededAction,
    WsFunktionsbereichOkAction,
    WsIncidentManagementAbteilungAction,
    WsIncidentManagementDelegationAction,
    WsIncidentManagementZentraleAction,
    WsMitteilungAction
} from './websocket.actions';
import * as fromAuthModule from '@orga-app/auth/reducer';
import * as fromAppModule from './operativ/app-state/app-state.reducer';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {filter, map, tap, withLatestFrom} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {NotificationService} from '@orga-app/providers/notification.service';
import {getBenutzer} from './operativ/app-state/app-state.reducer';
import {getIsLoggedIn} from '@orga-app/auth/reducer';

@Injectable()
export class WebsocketEffects {

    @Effect({dispatch: false})
    commandSuccedded$ = this.actions$.pipe(
        ofType(WS_COMMAND_SUCCEEDED),
        map(x => <WsCommandSucceededAction> x),
        filter(x => x.message != null),
        filter(x => x.message !== undefined),
        filter(x => x.message.user_id != null),
        withLatestFrom(this.storeApp.pipe(select(getBenutzer)), this.storeAuth.pipe(select(getIsLoggedIn))),
        filter(([x, benutzer, isLoggedIn]) => isLoggedIn && x.message.user_id === benutzer.benutzer_id),
        map(([x, benutzer, isLoggedIn]) => x),
        tap((x: WsCommandSucceededAction) => {
            if (!environment.production) {
                this.notificationService.showMessage('Daten erfolgreich verarbeitet (Diese Nachricht ist nur im Debug-Modus sichtbar!)');
            }
        })
    );


    @Effect({dispatch: false})
    commandFailed$ = this.actions$.pipe(
        ofType(WS_COMMAND_FAILED),
        map(x => <WsCommandFailedAction> x),
        filter(x => x.message != null),
        filter(x => x.message !== undefined),
        filter(x => x.message.user_id != null),
        withLatestFrom(this.storeApp.pipe(select(getBenutzer)), this.storeAuth.pipe(select(getIsLoggedIn))),
        filter(([x, benutzer, isLoggedIn]) => isLoggedIn && x.message.user_id === benutzer.benutzer_id),
        map(([x, benutzer, isLoggedIn]) => x),
        tap((x: WsCommandFailedAction) => {
            this.notificationService.showError(x.message.error_text);
        })
    );


    @Effect({dispatch: false})
    aufgabeErinnerungMessage$ = this.actions$.pipe(
        ofType(WS_AUFGABE_ERINNERUNG),
        map(x => <WsAufgabeErinnerungAction> x),
        filter(x => x.message != null),
        filter(x => x.message !== undefined),
        withLatestFrom(this.storeAuth.pipe(select(getIsLoggedIn))),
        filter(([x, isLoggedIn]) => isLoggedIn),
        map(([x, storeState]) => x),
        tap((x: websocketActions.WsAufgabeErinnerungAction) => {
            const titel = x.message.aufgabe_titel;
            const untertitel = x.message.funktionsbereich_titel + ' | ' + x.message.checkliste_titel;
            const message = 'Diese Aufgabe steht zur Bearbeitung an.';
            this.notificationService.showAlertInfo(titel, untertitel, message);

        })
    );

    @Effect({dispatch: false})
    aufgabeEskalationMessage$ = this.actions$.pipe(
        ofType(WS_AUFGABE_ESKALATION),
        map(x => <WsAufgabeEskalationAction> x),
        filter(x => x.message != null),
        filter(x => x.message !== undefined),
        withLatestFrom(this.storeAuth.pipe(select(getIsLoggedIn))),
        filter(([x, isLoggedIn]) => isLoggedIn),
        map(([x]) => x),
        tap((x: WsAufgabeEskalationAction) => {
            const titel = x.message.aufgabe_titel;
            const untertitel = x.message.funktionsbereich_titel + ' | ' + x.message.checkliste_titel;
            // const xx = Date.parse(x.message.eskalation_zeitpunkt_utc.timeStampUtc.toString());
            const xx = Date.parse(x.message.eskalation_zeitpunkt_utc.toString());
            const d = new Date();
            d.setTime(xx);

            let prefix = '';
            if (d.getMinutes().toString().length === 1) {
               prefix = '0';
            }
            const message = 'Diese Aufgabe sollte seit ' + d.getHours().toString() + ':' + prefix + d.getMinutes().toString() + 'Uhr erledigt sein.';

            this.notificationService.showAlertError(titel, untertitel, message);

        })
    );


    @Effect({dispatch: false})
    funktionsbereichOkMessage$ = this.actions$.pipe(
        ofType(WS_FUNKTIONSBEREICH_OK),
        map(x => <WsFunktionsbereichOkAction> x),
        filter(x => x.message != null),
        filter(x => x.message !== undefined),     
        withLatestFrom(this.storeAuth.pipe(select(getIsLoggedIn))),
        filter(([x, isLoggedIn]) => isLoggedIn),
        map(([x]) => x),
        tap((x: WsFunktionsbereichOkAction) => {
            const titel = x.message.funktionsbereich_titel;
            const untertitel = x.message.veranstaltung_titel + ' Startklar !!!';

            const message = 'Der Funktionsbereich ' + x.message.funktionsbereich_titel + ' ist startklar!!';
            this.notificationService.showAlertInfo(titel, untertitel, message);
        })
    );


    @Effect({dispatch: false})
    mitteilungMessage$ = this.actions$.pipe(
        ofType(WS_MITTEILUNG),
        map(x => <WsMitteilungAction> x),
        filter(x => x.message != null),
        filter(x => x.message !== undefined),
        withLatestFrom(this.storeAuth.pipe(select(getIsLoggedIn))),
        filter(([x, isLoggedIn]) => isLoggedIn),
        map(([x]) => x),
        tap((x: WsMitteilungAction) => {
            // TODO V3
            // const titel = x.message.userName;
            const titel = x.message.absender;
            const untertitel = x.message.betreff;

            const message = x.message.text;
            this.notificationService.showAlertInfo(titel, untertitel, message);
        })
    );


    @Effect({dispatch: false})
    aufgabeNichtOkMessage$ = this.actions$.pipe(
        ofType(WS_AUFGABE_NICHT_OK),
        map(x => <WsAufgabeNichtOkAction> x),
        filter(x => x.message != null),
        filter(x => x.message !== undefined),
        withLatestFrom(this.storeAuth.pipe(select(getIsLoggedIn))),
        filter(([x, isLoggedIn]) => isLoggedIn),
        map(([x]) => x),
        tap((x: WsAufgabeNichtOkAction) => {
            const titel = 'NOK: ' + x.message.aufgabe_titel;
            const untertitel = x.message.funktionsbereich_titel + ' | ' + x.message.checkliste_titel;

            const message = 'Die Aufgabe ' + x.message.aufgabe_titel + ' wurde durch ' + x.message.absender + ' nicht Ok gemeldet';
            this.notificationService.showAlertError(titel, untertitel, message);
        })
    );

    @Effect({dispatch: false})
    incidentZentrale$ = this.actions$.pipe(
        ofType(WS_INCIDENT_ZENTRALE),
        map(x => <WsIncidentManagementZentraleAction> x),
        filter(x => x.message != null),
        filter(x => x.message !== undefined),
        withLatestFrom(this.storeAuth.pipe(select(getIsLoggedIn))),
        filter(([x, isLoggedIn]) => isLoggedIn),
        map(([x]) => x),
        tap((x: WsIncidentManagementZentraleAction) => {
            const titel = 'Incident Management';
            // const untertitel = x.message.text;
            // const message = 'Der Incident Aufgabe ' + x.message.aufgabe_titel + ' wurde durch ' + x.message.absender + ' nicht Ok gemeldet';
            this.notificationService.showAlertError(titel, x.message.titel,x.message.text);
        })
    );

    @Effect({dispatch: false})
    incidentAbteilung$ = this.actions$.pipe(
        ofType(WS_INCIDENT_ABTEILUNG),
        map(x => <WsIncidentManagementAbteilungAction> x),
        filter(x => x.message != null),
        filter(x => x.message !== undefined),
        withLatestFrom(this.storeAuth.pipe(select(getIsLoggedIn))),
        filter(([x, isLoggedIn]) => isLoggedIn),
        map(([x]) => x),
        tap((x: WsIncidentManagementAbteilungAction) => {
            const titel = 'Incident Management';
            // const untertitel = x.message.text;
            // const message = 'Der Incident Aufgabe ' + x.message.aufgabe_titel + ' wurde durch ' + x.message.absender + ' nicht Ok gemeldet';
            this.notificationService.showAlertError(titel, x.message.titel,x.message.text);
        })
    );
    @Effect({dispatch: false})
    incidentDelegation$ = this.actions$.pipe(
        ofType(WS_INCIDENT_DELEGATION),
        map(x => <WsIncidentManagementDelegationAction> x),
        filter(x => x.message != null),
        filter(x => x.message !== undefined),
        withLatestFrom(this.storeAuth.pipe(select(getIsLoggedIn))),
        filter(([x, isLoggedIn]) => isLoggedIn),
        map(([x]) => x),
        tap((x: WsIncidentManagementDelegationAction) => {
            const titel = 'Incident Management';
            // const untertitel = x.message.text;
            // const message = 'Der Incident Aufgabe ' + x.message.aufgabe_titel + ' wurde durch ' + x.message.absender + ' nicht Ok gemeldet';
            this.notificationService.showAlertError(titel, x.message.titel,x.message.text);
        })
    );


    constructor(private actions$: Actions,
                private storeAuth: Store<fromAuthModule.ModuleState>,
                private storeApp: Store<fromAppModule.GlobalAppState>,
                private notificationService: NotificationService) {
    }
}
