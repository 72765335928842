import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_HINZUFUEGEN = '[BenutzerverwaltungModul] PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegen laden';
export const POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_HINZUFUEGEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegen ausführen';
export const POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_HINZUFUEGEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegen laden erfolgreich';
export const POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_HINZUFUEGEN_FEHLER = '[BenutzerverwaltungModul] PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegen Ladefehler';
export const POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_HINZUFUEGEN;
        constructor(public rolleRequest: api.RolleRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_HINZUFUEGEN_AUSFUEHREN;
        constructor(public rolleRequest: api.RolleRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public rolleRequest: api.RolleRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegenFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public rolleRequest: api.RolleRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandFunktionsbereichFreigabeBerechtigungRolleHinzufuegenActions =
    PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegenAction
    | PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegenAusfuehrenAction
    | PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegenErfolgreichAction
    | PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegenFehlerAction
    | PostFunktionsbereichFreigabeBerechtigungRolleHinzufuegenNichtAusgefuehrtAction
    ;
