

import {Action} from '@ngrx/store';

export const GET_FUNKPROTOKOLL_EXCEL = '[VeranstaltungenModul] GetFunkprotokollExcel laden';
export const GET_FUNKPROTOKOLL_EXCEL_AUSFUEHREN = '[VeranstaltungenModul] GetFunkprotokollExcel ausführen';
export const GET_FUNKPROTOKOLL_EXCEL_ERFOLGREICH = '[VeranstaltungenModul] GetFunkprotokollExcel laden erfolgreich';
export const GET_FUNKPROTOKOLL_EXCEL_FEHLER = '[VeranstaltungenModul] GetFunkprotokollExcel Ladefehler';
export const GET_FUNKPROTOKOLL_EXCEL_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunkprotokollExcel nicht ausgeführt (evtl. Offline)';



export class GetFunkprotokollExcelAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_EXCEL;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollExcelAusfuehrenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_EXCEL_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollExcelErfolgreichAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_EXCEL_ERFOLGREICH;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollExcelFehlerAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_EXCEL_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollExcelNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_EXCEL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunkprotokollExcelActions =
    GetFunkprotokollExcelAction
    | GetFunkprotokollExcelAusfuehrenAction
    | GetFunkprotokollExcelErfolgreichAction
    | GetFunkprotokollExcelFehlerAction
    | GetFunkprotokollExcelNichtAusgefuehrtAction
    ;
