

import {Action} from '@ngrx/store';

export const GET_FUNKPROTOKOLL_REPORT_PDF = '[VeranstaltungenModul] GetFunkprotokollReportPdf laden';
export const GET_FUNKPROTOKOLL_REPORT_PDF_AUSFUEHREN = '[VeranstaltungenModul] GetFunkprotokollReportPdf ausführen';
export const GET_FUNKPROTOKOLL_REPORT_PDF_ERFOLGREICH = '[VeranstaltungenModul] GetFunkprotokollReportPdf laden erfolgreich';
export const GET_FUNKPROTOKOLL_REPORT_PDF_FEHLER = '[VeranstaltungenModul] GetFunkprotokollReportPdf Ladefehler';
export const GET_FUNKPROTOKOLL_REPORT_PDF_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunkprotokollReportPdf nicht ausgeführt (evtl. Offline)';



export class GetFunkprotokollReportPdfAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_PDF;
        constructor( public veranstaltung_id:string ,  public reportVersion:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollReportPdfAusfuehrenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_PDF_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public reportVersion:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollReportPdfErfolgreichAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_PDF_ERFOLGREICH;
        constructor( public veranstaltung_id:string ,  public reportVersion:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollReportPdfFehlerAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_PDF_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public reportVersion:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetFunkprotokollReportPdfNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_REPORT_PDF_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunkprotokollReportPdfActions =
    GetFunkprotokollReportPdfAction
    | GetFunkprotokollReportPdfAusfuehrenAction
    | GetFunkprotokollReportPdfErfolgreichAction
    | GetFunkprotokollReportPdfFehlerAction
    | GetFunkprotokollReportPdfNichtAusgefuehrtAction
    ;
