import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_BEMERKUNG_ERFASSEN = '[VeranstaltungenModul] PostAufgabeBemerkungErfassen laden';
export const POST_AUFGABE_BEMERKUNG_ERFASSEN_AUSFUEHREN = '[VeranstaltungenModul] PostAufgabeBemerkungErfassen ausführen';
export const POST_AUFGABE_BEMERKUNG_ERFASSEN_ERFOLGREICH = '[VeranstaltungenModul] PostAufgabeBemerkungErfassen laden erfolgreich';
export const POST_AUFGABE_BEMERKUNG_ERFASSEN_FEHLER = '[VeranstaltungenModul] PostAufgabeBemerkungErfassen Ladefehler';
export const POST_AUFGABE_BEMERKUNG_ERFASSEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostAufgabeBemerkungErfassen nicht ausgeführt (evtl. Offline)';



export class PostAufgabeBemerkungErfassenAction implements Action {
    readonly type = POST_AUFGABE_BEMERKUNG_ERFASSEN;
        constructor(public aufgabeBemerkungRequest: api.AufgabeBemerkungRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBemerkungErfassenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_BEMERKUNG_ERFASSEN_AUSFUEHREN;
        constructor(public aufgabeBemerkungRequest: api.AufgabeBemerkungRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBemerkungErfassenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_BEMERKUNG_ERFASSEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeBemerkungRequest: api.AufgabeBemerkungRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBemerkungErfassenFehlerAction implements Action {
    readonly type = POST_AUFGABE_BEMERKUNG_ERFASSEN_FEHLER;
        constructor(public payload: any, public aufgabeBemerkungRequest: api.AufgabeBemerkungRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBemerkungErfassenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_BEMERKUNG_ERFASSEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandAufgabeBemerkungErfassenActions =
    PostAufgabeBemerkungErfassenAction
    | PostAufgabeBemerkungErfassenAusfuehrenAction
    | PostAufgabeBemerkungErfassenErfolgreichAction
    | PostAufgabeBemerkungErfassenFehlerAction
    | PostAufgabeBemerkungErfassenNichtAusgefuehrtAction
    ;
