/**
 * orga app | kontaktverwaltung
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FunktionsbereichKontakte } from '../model/funktionsbereichKontakte';
import { KontaktAktiv } from '../model/kontaktAktiv';
import { KontaktDetail } from '../model/kontaktDetail';
import { KontaktExtern } from '../model/kontaktExtern';
import { KontaktTelefon } from '../model/kontaktTelefon';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class KontakteQueryService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetFunktionsbereichKontakte (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereichKontakte(observe?: 'body', reportProgress?: boolean): Observable<Array<FunktionsbereichKontakte>>;
    public getFunktionsbereichKontakte(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FunktionsbereichKontakte>>>;
    public getFunktionsbereichKontakte(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FunktionsbereichKontakte>>>;
    public getFunktionsbereichKontakte(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FunktionsbereichKontakte>>('get',`${this.basePath}/api/query/kontaktverwaltung/kontakte/funktionsbereiche/kontakte`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunktionsbereichKontakteExcel (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereichKontakteExcel(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getFunktionsbereichKontakteExcel(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getFunktionsbereichKontakteExcel(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getFunktionsbereichKontakteExcel(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/kontaktverwaltung/kontakte/funktionsbereiche/kontakte/excel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetFunktionsbereichKontaktePdf (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFunktionsbereichKontaktePdf(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getFunktionsbereichKontaktePdf(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getFunktionsbereichKontaktePdf(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getFunktionsbereichKontaktePdf(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/query/kontaktverwaltung/kontakte/funktionsbereiche/kontakte/pdf`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetKontaktAktiv (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param kontakt_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKontaktAktiv(kontakt_id: string, observe?: 'body', reportProgress?: boolean): Observable<KontaktAktiv>;
    public getKontaktAktiv(kontakt_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KontaktAktiv>>;
    public getKontaktAktiv(kontakt_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KontaktAktiv>>;
    public getKontaktAktiv(kontakt_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kontakt_id === null || kontakt_id === undefined) {
            throw new Error('Required parameter kontakt_id was null or undefined when calling getKontaktAktiv.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<KontaktAktiv>('get',`${this.basePath}/api/query/kontaktverwaltung/kontakte/${encodeURIComponent(String(kontakt_id))}/aktiv`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetKontaktDetail (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param kontakt_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKontaktDetail(kontakt_id: string, observe?: 'body', reportProgress?: boolean): Observable<KontaktDetail>;
    public getKontaktDetail(kontakt_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KontaktDetail>>;
    public getKontaktDetail(kontakt_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KontaktDetail>>;
    public getKontaktDetail(kontakt_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kontakt_id === null || kontakt_id === undefined) {
            throw new Error('Required parameter kontakt_id was null or undefined when calling getKontaktDetail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<KontaktDetail>('get',`${this.basePath}/api/query/kontaktverwaltung/kontakte/${encodeURIComponent(String(kontakt_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetKontaktExtern (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param kontakt_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKontaktExtern(kontakt_id: string, observe?: 'body', reportProgress?: boolean): Observable<KontaktExtern>;
    public getKontaktExtern(kontakt_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<KontaktExtern>>;
    public getKontaktExtern(kontakt_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<KontaktExtern>>;
    public getKontaktExtern(kontakt_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kontakt_id === null || kontakt_id === undefined) {
            throw new Error('Required parameter kontakt_id was null or undefined when calling getKontaktExtern.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<KontaktExtern>('get',`${this.basePath}/api/query/kontaktverwaltung/kontakte/${encodeURIComponent(String(kontakt_id))}/extern`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetKontaktTelefone (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param kontakt_id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getKontaktTelefone(kontakt_id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<KontaktTelefon>>;
    public getKontaktTelefone(kontakt_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<KontaktTelefon>>>;
    public getKontaktTelefone(kontakt_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<KontaktTelefon>>>;
    public getKontaktTelefone(kontakt_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (kontakt_id === null || kontakt_id === undefined) {
            throw new Error('Required parameter kontakt_id was null or undefined when calling getKontaktTelefone.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<KontaktTelefon>>('get',`${this.basePath}/api/query/kontaktverwaltung/kontakte/${encodeURIComponent(String(kontakt_id))}/telefone`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
