import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_CHECKLISTE_FREIGEBEN = '[VeranstaltungenModul] PostChecklisteFreigeben laden';
export const POST_CHECKLISTE_FREIGEBEN_AUSFUEHREN = '[VeranstaltungenModul] PostChecklisteFreigeben ausführen';
export const POST_CHECKLISTE_FREIGEBEN_ERFOLGREICH = '[VeranstaltungenModul] PostChecklisteFreigeben laden erfolgreich';
export const POST_CHECKLISTE_FREIGEBEN_FEHLER = '[VeranstaltungenModul] PostChecklisteFreigeben Ladefehler';
export const POST_CHECKLISTE_FREIGEBEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostChecklisteFreigeben nicht ausgeführt (evtl. Offline)';



export class PostChecklisteFreigebenAction implements Action {
    readonly type = POST_CHECKLISTE_FREIGEBEN;
        constructor(public checklisteFreigebenRequest: api.ChecklisteFreigebenRequest, public optPayload: any = null) {}
    }
export class PostChecklisteFreigebenAusfuehrenAction implements Action {
    readonly type = POST_CHECKLISTE_FREIGEBEN_AUSFUEHREN;
        constructor(public checklisteFreigebenRequest: api.ChecklisteFreigebenRequest, public optPayload: any = null) {}
    }
export class PostChecklisteFreigebenErfolgreichAction implements Action {
    readonly type = POST_CHECKLISTE_FREIGEBEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public checklisteFreigebenRequest: api.ChecklisteFreigebenRequest, public optPayload: any = null) {}
    }
export class PostChecklisteFreigebenFehlerAction implements Action {
    readonly type = POST_CHECKLISTE_FREIGEBEN_FEHLER;
        constructor(public payload: any, public checklisteFreigebenRequest: api.ChecklisteFreigebenRequest, public optPayload: any = null) {}
    }
export class PostChecklisteFreigebenNichtAusgefuehrtAction implements Action {
    readonly type = POST_CHECKLISTE_FREIGEBEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandChecklisteFreigebenActions =
    PostChecklisteFreigebenAction
    | PostChecklisteFreigebenAusfuehrenAction
    | PostChecklisteFreigebenErfolgreichAction
    | PostChecklisteFreigebenFehlerAction
    | PostChecklisteFreigebenNichtAusgefuehrtAction
    ;
