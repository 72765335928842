import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_MANDANT_NOTIFICATION_TYPES = '[ApiModul] GetMandantNotificationTypes laden';
export const GET_MANDANT_NOTIFICATION_TYPES_AUSFUEHREN = '[ApiModul] GetMandantNotificationTypes ausführen';
export const GET_MANDANT_NOTIFICATION_TYPES_ERFOLGREICH = '[ApiModul] GetMandantNotificationTypes laden erfolgreich';
export const GET_MANDANT_NOTIFICATION_TYPES_FEHLER = '[ApiModul] GetMandantNotificationTypes Ladefehler';
export const GET_MANDANT_NOTIFICATION_TYPES_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantNotificationTypes nicht ausgeführt (evtl. Offline)';



export class GetMandantNotificationTypesAction implements Action {
    readonly type = GET_MANDANT_NOTIFICATION_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantNotificationTypesAusfuehrenAction implements Action {
    readonly type = GET_MANDANT_NOTIFICATION_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantNotificationTypesErfolgreichAction implements Action {
    readonly type = GET_MANDANT_NOTIFICATION_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.NotificationMessageTypeInfo>, public optPayload: any = null) {}
    }
export class GetMandantNotificationTypesFehlerAction implements Action {
    readonly type = GET_MANDANT_NOTIFICATION_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantNotificationTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANT_NOTIFICATION_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantNotificationTypesActions =
    GetMandantNotificationTypesAction
    | GetMandantNotificationTypesAusfuehrenAction
    | GetMandantNotificationTypesErfolgreichAction
    | GetMandantNotificationTypesFehlerAction
    | GetMandantNotificationTypesNichtAusgefuehrtAction
    ;
