import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { getInterceptorInfos, MandantState } from './reducer';
import { first, flatMap } from 'rxjs/operators';
import { CustomUrlMandantOptions, MandantInfo } from '@orga-app/clients/api';
import { API_URL } from './variables';


@Injectable()
export class HttpClientMandantInterceptor implements HttpInterceptor {
  private excludedUrls = [
    '/account/login2', // Login für DEV
    '/mandanten/meine'
  ];

  constructor(private store: Store<MandantState>, @Inject(API_URL) public apiUrl: string) {

  }

  addMandant(req: HttpRequest<any>, mandantId: string): HttpRequest<any> {
    return req.clone({ setHeaders: { MandantId: mandantId } });
  }

  changeBaseUrl(req: HttpRequest<any>, url: string): HttpRequest<any> {
    console.debug('HttpClientMandantInterceptor: Changing Base Url to:', url);

    const res = this.excludedUrls.filter(exUrl => req.url.endsWith(exUrl));
    if (res.length === 0) {
      const reqUrl = req.url.replace(this.apiUrl, url);
      return req.clone({
        url: reqUrl
      });
    }

    return req;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.store.pipe(
      select(getInterceptorInfos),
      first(),
      flatMap((interceptorInfos: { mandant: MandantInfo, mandantCustomUrlOptions: CustomUrlMandantOptions }) => {
        let mandantReq = (!!interceptorInfos && !!interceptorInfos.mandant) ? this.addMandant(req, interceptorInfos.mandant.mandant_id) : req;
        mandantReq = (!!interceptorInfos && !!interceptorInfos.mandantCustomUrlOptions && !!interceptorInfos.mandantCustomUrlOptions.api_url) ? this.changeBaseUrl(mandantReq, interceptorInfos.mandantCustomUrlOptions.api_url) : mandantReq;
        return next.handle(mandantReq);
      }),
    );
  }

}
