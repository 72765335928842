import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_CHECKLISTE_KOPIEREN = '[VorlagenModul] PostChecklisteKopieren laden';
export const POST_CHECKLISTE_KOPIEREN_AUSFUEHREN = '[VorlagenModul] PostChecklisteKopieren ausführen';
export const POST_CHECKLISTE_KOPIEREN_ERFOLGREICH = '[VorlagenModul] PostChecklisteKopieren laden erfolgreich';
export const POST_CHECKLISTE_KOPIEREN_FEHLER = '[VorlagenModul] PostChecklisteKopieren Ladefehler';
export const POST_CHECKLISTE_KOPIEREN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostChecklisteKopieren nicht ausgeführt (evtl. Offline)';



export class PostChecklisteKopierenAction implements Action {
    readonly type = POST_CHECKLISTE_KOPIEREN;
        constructor(public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteKopierenAusfuehrenAction implements Action {
    readonly type = POST_CHECKLISTE_KOPIEREN_AUSFUEHREN;
        constructor(public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteKopierenErfolgreichAction implements Action {
    readonly type = POST_CHECKLISTE_KOPIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteKopierenFehlerAction implements Action {
    readonly type = POST_CHECKLISTE_KOPIEREN_FEHLER;
        constructor(public payload: any, public checklisteRequest: api.ChecklisteRequest, public optPayload: any = null) {}
    }
export class PostChecklisteKopierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_CHECKLISTE_KOPIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandChecklisteKopierenActions =
    PostChecklisteKopierenAction
    | PostChecklisteKopierenAusfuehrenAction
    | PostChecklisteKopierenErfolgreichAction
    | PostChecklisteKopierenFehlerAction
    | PostChecklisteKopierenNichtAusgefuehrtAction
    ;
