import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {ModalDokumentLoeschenPage} from './modal-dokument-loeschen.page';
import {SopiScrollModule} from '../../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {SopiComponentsModule} from '../../../shared/sopi-components/sopi-components.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        SopiScrollModule,
        SopiComponentsModule.forRoot(),
    ],
    declarations: [ModalDokumentLoeschenPage],
    entryComponents: [ModalDokumentLoeschenPage],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalDokumentLoeschenPageModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: ModalDokumentLoeschenPageModule
        };
    }
}
