import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dayOfWeek'})
export class DayOfWeekPipe implements PipeTransform {

    transform(value: number): string {
        const day = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
        return day[value];
    }
}
