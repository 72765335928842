import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import {
    NAVI_BENUTZER_ZUGRIFFE_LADEN,
    NaviBenutzerZugriffeLadenAction,
    NaviBoundedContextZugriffSetzenAction
} from './navi.actions';
import {GET_VERANSTALTUNG_BENUTZER_ZUGRIFF_ERFOLGREICH, GetVeranstaltungBenutzerZugriffAction, GetVeranstaltungBenutzerZugriffErfolgreichAction} from '../../client-ngrx/veranstaltungen';
import {GET_VORLAGEN_BENUTZER_ZUGRIFF_ERFOLGREICH, GetVorlagenBenutzerZugriffAction, GetVorlagenBenutzerZugriffErfolgreichAction} from '../../client-ngrx/vorlagen';
import {GET_INCIDENTS_BENUTZER_ZUGRIFF_ERFOLGREICH, GetIncidentsBenutzerZugriffAction, GetIncidentsBenutzerZugriffErfolgreichAction} from '../../client-ngrx/incidents';
import {GET_DISPLAYSTEUERUNG_BENUTZER_ZUGRIFF_ERFOLGREICH, GetDisplaysteuerungBenutzerZugriffAction, GetDisplaysteuerungBenutzerZugriffErfolgreichAction} from '../../client-ngrx/displaysteuerung';
import {GET_EINLASSKONTROLLE_BENUTZER_ZUGRIFF_ERFOLGREICH, GetEinlasskontrolleBenutzerZugriffAction, GetEinlasskontrolleBenutzerZugriffErfolgreichAction} from '../../client-ngrx/einlasskontrolle';
import {GET_QS_CHECKS_BENUTZER_ZUGRIFF_ERFOLGREICH, GetQsChecksBenutzerZugriffAction, GetQsChecksBenutzerZugriffErfolgreichAction} from '../../client-ngrx/qschecks';
import {GET_BENUTZERVERWALTUNG_BENUTZER_ZUGRIFF_ERFOLGREICH, GetBenutzerverwaltungBenutzerZugriffAction, GetBenutzerverwaltungBenutzerZugriffErfolgreichAction} from '../../client-ngrx/benutzerverwaltung';


@Injectable()
export class NaviEffects {

    // Zugriffe für die Boundet Contexte laden
    @Effect()
    NaviBenutzerZugriffeLadenAction$: Observable<Action> = this.actions$.pipe(
        ofType<NaviBenutzerZugriffeLadenAction>(NAVI_BENUTZER_ZUGRIFFE_LADEN)
        , flatMap((x) => {
            console.debug('NaviEffects :: NaviBenutzerZugriffeLadenAction$');
            return [
                new GetVeranstaltungBenutzerZugriffAction(),
                new GetBenutzerverwaltungBenutzerZugriffAction(),
                new GetVorlagenBenutzerZugriffAction(),
                new GetIncidentsBenutzerZugriffAction(),
                new GetDisplaysteuerungBenutzerZugriffAction(),
                new GetEinlasskontrolleBenutzerZugriffAction(),
                new GetQsChecksBenutzerZugriffAction()
            ];
        })
    );

    // Benutzer Zugriffe auswerten
    @Effect()
    BenutzerverwaltungGetBenutzerZugriffErfolgreichAction$: Observable<Action> = this.actions$.pipe(
        ofType<GetBenutzerverwaltungBenutzerZugriffErfolgreichAction>(GET_BENUTZERVERWALTUNG_BENUTZER_ZUGRIFF_ERFOLGREICH)
        , flatMap((x: GetBenutzerverwaltungBenutzerZugriffErfolgreichAction) => {
            console.debug('NaviEffects :: BenutzerverwaltungGetBenutzerZugriffErfolgreichAction$');
            return [
                new NaviBoundedContextZugriffSetzenAction('BENUTZERVERWALTUNG', x.payload),
            ];
        })
    );

    @Effect()
    VorlagenGetBenutzerZugriffErfolgreichAction$: Observable<Action> = this.actions$.pipe(
        ofType<GetVorlagenBenutzerZugriffErfolgreichAction>(GET_VORLAGEN_BENUTZER_ZUGRIFF_ERFOLGREICH)
        , flatMap((x: GetVorlagenBenutzerZugriffErfolgreichAction) => {
            console.debug('NaviEffects :: VorlagenGetBenutzerZugriffErfolgreichAction$');
            return [
                new NaviBoundedContextZugriffSetzenAction('VORLAGEN', x.payload)
            ];
        })
    );


    @Effect()
    VeranstaltungenGetBenutzerZugriffErfolgreichAction$: Observable<Action> = this.actions$.pipe(
        ofType<GetVeranstaltungBenutzerZugriffErfolgreichAction>(GET_VERANSTALTUNG_BENUTZER_ZUGRIFF_ERFOLGREICH)
        , map((x: GetVeranstaltungBenutzerZugriffErfolgreichAction) => {
            console.debug('NaviEffects :: VeranstaltungenGetBenutzerZugriffErfolgreichAction$');
            return new NaviBoundedContextZugriffSetzenAction('VERANSTALTUNGEN', x.payload);
        })
    );

    @Effect()
    IncidentsGetBenutzerZugriffErfolgreichAction$: Observable<Action> = this.actions$.pipe(
        ofType<GetIncidentsBenutzerZugriffErfolgreichAction>(GET_INCIDENTS_BENUTZER_ZUGRIFF_ERFOLGREICH)
        , map((x: GetIncidentsBenutzerZugriffErfolgreichAction) => {
            console.debug('NaviEffects :: IncidentsGetIncidentsBenutzerZugriffErfolgreichAction$');
            return new NaviBoundedContextZugriffSetzenAction('INCIDENTS', x.payload);
        })
    );

    @Effect()
    DisplaysteuerungGetBenutzerZugriffErfolgreichAction$: Observable<Action> = this.actions$.pipe(
        ofType<GetDisplaysteuerungBenutzerZugriffErfolgreichAction>(GET_DISPLAYSTEUERUNG_BENUTZER_ZUGRIFF_ERFOLGREICH)
        , map((x: GetDisplaysteuerungBenutzerZugriffErfolgreichAction) => {
            console.debug('NaviEffects :: DisplaysteuerungGetBenutzerZugriffErfolgreichAction$');
            return new NaviBoundedContextZugriffSetzenAction('DISPLAYSTEUERUNG', x.payload);
        })
    );

    @Effect()
    einlasskontrolleGetBenutzerZugriffErfolgreichAction$: Observable<Action> = this.actions$.pipe(
        ofType<GetEinlasskontrolleBenutzerZugriffErfolgreichAction>(GET_EINLASSKONTROLLE_BENUTZER_ZUGRIFF_ERFOLGREICH)
        , map((x: GetEinlasskontrolleBenutzerZugriffErfolgreichAction) => {
            console.debug('NaviEffects :: einlasskontrolleGetBenutzerZugriffErfolgreichAction$');
            return new NaviBoundedContextZugriffSetzenAction('EINLASSKONTROLLE', x.payload);
        })
    );

    @Effect()
    qsChecksGetBenutzerZugriffErfolgreichAction$: Observable<Action> = this.actions$.pipe(
        ofType<GetQsChecksBenutzerZugriffErfolgreichAction>(GET_QS_CHECKS_BENUTZER_ZUGRIFF_ERFOLGREICH)
        , map((x: GetQsChecksBenutzerZugriffErfolgreichAction) => {
            console.debug('NaviEffects :: qsChecksGetBenutzerZugriffErfolgreichAction$');
            return new NaviBoundedContextZugriffSetzenAction('QSCHECKS', x.payload);
        })
    );

    constructor(private actions$: Actions) {
    }
}
