import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_BENUTZER_PUSH_NOTIFICATION = '[BenutzerverwaltungModul] GetBenutzerPushNotification laden';
export const GET_BENUTZER_PUSH_NOTIFICATION_AUSFUEHREN = '[BenutzerverwaltungModul] GetBenutzerPushNotification ausführen';
export const GET_BENUTZER_PUSH_NOTIFICATION_ERFOLGREICH = '[BenutzerverwaltungModul] GetBenutzerPushNotification laden erfolgreich';
export const GET_BENUTZER_PUSH_NOTIFICATION_FEHLER = '[BenutzerverwaltungModul] GetBenutzerPushNotification Ladefehler';
export const GET_BENUTZER_PUSH_NOTIFICATION_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetBenutzerPushNotification nicht ausgeführt (evtl. Offline)';



export class GetBenutzerPushNotificationAction implements Action {
    readonly type = GET_BENUTZER_PUSH_NOTIFICATION;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerPushNotificationAusfuehrenAction implements Action {
    readonly type = GET_BENUTZER_PUSH_NOTIFICATION_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetBenutzerPushNotificationErfolgreichAction implements Action {
    readonly type = GET_BENUTZER_PUSH_NOTIFICATION_ERFOLGREICH;
        constructor(public payload: api.BenutzerPushNotification, public optPayload: any = null) {}
    }
export class GetBenutzerPushNotificationFehlerAction implements Action {
    readonly type = GET_BENUTZER_PUSH_NOTIFICATION_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetBenutzerPushNotificationNichtAusgefuehrtAction implements Action {
    readonly type = GET_BENUTZER_PUSH_NOTIFICATION_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryBenutzerPushNotificationActions =
    GetBenutzerPushNotificationAction
    | GetBenutzerPushNotificationAusfuehrenAction
    | GetBenutzerPushNotificationErfolgreichAction
    | GetBenutzerPushNotificationFehlerAction
    | GetBenutzerPushNotificationNichtAusgefuehrtAction
    ;
