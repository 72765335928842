import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_CHECKLISTE_LESE_ROLLEN_ADMIN = '[VeranstaltungenModul] GetChecklisteLeseRollenAdmin laden';
export const GET_CHECKLISTE_LESE_ROLLEN_ADMIN_AUSFUEHREN = '[VeranstaltungenModul] GetChecklisteLeseRollenAdmin ausführen';
export const GET_CHECKLISTE_LESE_ROLLEN_ADMIN_ERFOLGREICH = '[VeranstaltungenModul] GetChecklisteLeseRollenAdmin laden erfolgreich';
export const GET_CHECKLISTE_LESE_ROLLEN_ADMIN_FEHLER = '[VeranstaltungenModul] GetChecklisteLeseRollenAdmin Ladefehler';
export const GET_CHECKLISTE_LESE_ROLLEN_ADMIN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetChecklisteLeseRollenAdmin nicht ausgeführt (evtl. Offline)';



export class GetChecklisteLeseRollenAdminAction implements Action {
    readonly type = GET_CHECKLISTE_LESE_ROLLEN_ADMIN;
        constructor( public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteLeseRollenAdminAusfuehrenAction implements Action {
    readonly type = GET_CHECKLISTE_LESE_ROLLEN_ADMIN_AUSFUEHREN;
        constructor( public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteLeseRollenAdminErfolgreichAction implements Action {
    readonly type = GET_CHECKLISTE_LESE_ROLLEN_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.FunktionsbereichRolle>,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteLeseRollenAdminFehlerAction implements Action {
    readonly type = GET_CHECKLISTE_LESE_ROLLEN_ADMIN_FEHLER;
        constructor(public payload: any,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetChecklisteLeseRollenAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_CHECKLISTE_LESE_ROLLEN_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryChecklisteLeseRollenAdminActions =
    GetChecklisteLeseRollenAdminAction
    | GetChecklisteLeseRollenAdminAusfuehrenAction
    | GetChecklisteLeseRollenAdminErfolgreichAction
    | GetChecklisteLeseRollenAdminFehlerAction
    | GetChecklisteLeseRollenAdminNichtAusgefuehrtAction
    ;
