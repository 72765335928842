import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_ENTFERNEN = '[BenutzerverwaltungModul] PostFunktionsbereichFreigabeBerechtigungRolleEntfernen laden';
export const POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_ENTFERNEN_AUSFUEHREN = '[BenutzerverwaltungModul] PostFunktionsbereichFreigabeBerechtigungRolleEntfernen ausführen';
export const POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_ENTFERNEN_ERFOLGREICH = '[BenutzerverwaltungModul] PostFunktionsbereichFreigabeBerechtigungRolleEntfernen laden erfolgreich';
export const POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_ENTFERNEN_FEHLER = '[BenutzerverwaltungModul] PostFunktionsbereichFreigabeBerechtigungRolleEntfernen Ladefehler';
export const POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_ENTFERNEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] PostFunktionsbereichFreigabeBerechtigungRolleEntfernen nicht ausgeführt (evtl. Offline)';



export class PostFunktionsbereichFreigabeBerechtigungRolleEntfernenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_ENTFERNEN;
        constructor(public rolleRequest: api.RolleRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichFreigabeBerechtigungRolleEntfernenAusfuehrenAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_ENTFERNEN_AUSFUEHREN;
        constructor(public rolleRequest: api.RolleRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichFreigabeBerechtigungRolleEntfernenErfolgreichAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public rolleRequest: api.RolleRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichFreigabeBerechtigungRolleEntfernenFehlerAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_ENTFERNEN_FEHLER;
        constructor(public payload: any, public rolleRequest: api.RolleRequest, public optPayload: any = null) {}
    }
export class PostFunktionsbereichFreigabeBerechtigungRolleEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKTIONSBEREICH_FREIGABE_BERECHTIGUNG_ROLLE_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungCommandFunktionsbereichFreigabeBerechtigungRolleEntfernenActions =
    PostFunktionsbereichFreigabeBerechtigungRolleEntfernenAction
    | PostFunktionsbereichFreigabeBerechtigungRolleEntfernenAusfuehrenAction
    | PostFunktionsbereichFreigabeBerechtigungRolleEntfernenErfolgreichAction
    | PostFunktionsbereichFreigabeBerechtigungRolleEntfernenFehlerAction
    | PostFunktionsbereichFreigabeBerechtigungRolleEntfernenNichtAusgefuehrtAction
    ;
