import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_BILD_HINZUFUEGEN = '[VeranstaltungenModul] PostAufgabeBildHinzufuegen laden';
export const POST_AUFGABE_BILD_HINZUFUEGEN_AUSFUEHREN = '[VeranstaltungenModul] PostAufgabeBildHinzufuegen ausführen';
export const POST_AUFGABE_BILD_HINZUFUEGEN_ERFOLGREICH = '[VeranstaltungenModul] PostAufgabeBildHinzufuegen laden erfolgreich';
export const POST_AUFGABE_BILD_HINZUFUEGEN_FEHLER = '[VeranstaltungenModul] PostAufgabeBildHinzufuegen Ladefehler';
export const POST_AUFGABE_BILD_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostAufgabeBildHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostAufgabeBildHinzufuegenAction implements Action {
    readonly type = POST_AUFGABE_BILD_HINZUFUEGEN;
        constructor(public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_BILD_HINZUFUEGEN_AUSFUEHREN;
        constructor(public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_BILD_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildHinzufuegenFehlerAction implements Action {
    readonly type = POST_AUFGABE_BILD_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public aufgabeBildRequest: api.AufgabeBildRequest, public optPayload: any = null) {}
    }
export class PostAufgabeBildHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_BILD_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandAufgabeBildHinzufuegenActions =
    PostAufgabeBildHinzufuegenAction
    | PostAufgabeBildHinzufuegenAusfuehrenAction
    | PostAufgabeBildHinzufuegenErfolgreichAction
    | PostAufgabeBildHinzufuegenFehlerAction
    | PostAufgabeBildHinzufuegenNichtAusgefuehrtAction
    ;
