import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const POST_BEREICH_ROLLE_HINZUFUEGEN_MULTI = '[DisplaysteuerungModul] PostBereichRolleHinzufuegenMulti laden';
export const POST_BEREICH_ROLLE_HINZUFUEGEN_MULTI_AUSFUEHREN = '[DisplaysteuerungModul] PostBereichRolleHinzufuegenMulti ausführen';
export const POST_BEREICH_ROLLE_HINZUFUEGEN_MULTI_ERFOLGREICH = '[DisplaysteuerungModul] PostBereichRolleHinzufuegenMulti laden erfolgreich';
export const POST_BEREICH_ROLLE_HINZUFUEGEN_MULTI_FEHLER = '[DisplaysteuerungModul] PostBereichRolleHinzufuegenMulti Ladefehler';
export const POST_BEREICH_ROLLE_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] PostBereichRolleHinzufuegenMulti nicht ausgeführt (evtl. Offline)';



export class PostBereichRolleHinzufuegenMultiAction implements Action {
    readonly type = POST_BEREICH_ROLLE_HINZUFUEGEN_MULTI;
        constructor(public bereichRolleMultiRequest: api.BereichRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostBereichRolleHinzufuegenMultiAusfuehrenAction implements Action {
    readonly type = POST_BEREICH_ROLLE_HINZUFUEGEN_MULTI_AUSFUEHREN;
        constructor(public bereichRolleMultiRequest: api.BereichRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostBereichRolleHinzufuegenMultiErfolgreichAction implements Action {
    readonly type = POST_BEREICH_ROLLE_HINZUFUEGEN_MULTI_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public bereichRolleMultiRequest: api.BereichRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostBereichRolleHinzufuegenMultiFehlerAction implements Action {
    readonly type = POST_BEREICH_ROLLE_HINZUFUEGEN_MULTI_FEHLER;
        constructor(public payload: any, public bereichRolleMultiRequest: api.BereichRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostBereichRolleHinzufuegenMultiNichtAusgefuehrtAction implements Action {
    readonly type = POST_BEREICH_ROLLE_HINZUFUEGEN_MULTI_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungCommandBereichRolleHinzufuegenMultiActions =
    PostBereichRolleHinzufuegenMultiAction
    | PostBereichRolleHinzufuegenMultiAusfuehrenAction
    | PostBereichRolleHinzufuegenMultiErfolgreichAction
    | PostBereichRolleHinzufuegenMultiFehlerAction
    | PostBereichRolleHinzufuegenMultiNichtAusgefuehrtAction
    ;
