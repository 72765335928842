import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const POST_BEREICH_ANLEGEN = '[DisplaysteuerungModul] PostBereichAnlegen laden';
export const POST_BEREICH_ANLEGEN_AUSFUEHREN = '[DisplaysteuerungModul] PostBereichAnlegen ausführen';
export const POST_BEREICH_ANLEGEN_ERFOLGREICH = '[DisplaysteuerungModul] PostBereichAnlegen laden erfolgreich';
export const POST_BEREICH_ANLEGEN_FEHLER = '[DisplaysteuerungModul] PostBereichAnlegen Ladefehler';
export const POST_BEREICH_ANLEGEN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] PostBereichAnlegen nicht ausgeführt (evtl. Offline)';



export class PostBereichAnlegenAction implements Action {
    readonly type = POST_BEREICH_ANLEGEN;
        constructor(public bereichRequest: api.BereichRequest, public optPayload: any = null) {}
    }
export class PostBereichAnlegenAusfuehrenAction implements Action {
    readonly type = POST_BEREICH_ANLEGEN_AUSFUEHREN;
        constructor(public bereichRequest: api.BereichRequest, public optPayload: any = null) {}
    }
export class PostBereichAnlegenErfolgreichAction implements Action {
    readonly type = POST_BEREICH_ANLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public bereichRequest: api.BereichRequest, public optPayload: any = null) {}
    }
export class PostBereichAnlegenFehlerAction implements Action {
    readonly type = POST_BEREICH_ANLEGEN_FEHLER;
        constructor(public payload: any, public bereichRequest: api.BereichRequest, public optPayload: any = null) {}
    }
export class PostBereichAnlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_BEREICH_ANLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungCommandBereichAnlegenActions =
    PostBereichAnlegenAction
    | PostBereichAnlegenAusfuehrenAction
    | PostBereichAnlegenErfolgreichAction
    | PostBereichAnlegenFehlerAction
    | PostBereichAnlegenNichtAusgefuehrtAction
    ;
