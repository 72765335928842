import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_BEENDEN = '[VeranstaltungenModul] PostVeranstaltungBeenden laden';
export const POST_VERANSTALTUNG_BEENDEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungBeenden ausführen';
export const POST_VERANSTALTUNG_BEENDEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungBeenden laden erfolgreich';
export const POST_VERANSTALTUNG_BEENDEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungBeenden Ladefehler';
export const POST_VERANSTALTUNG_BEENDEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungBeenden nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungBeendenAction implements Action {
    readonly type = POST_VERANSTALTUNG_BEENDEN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungBeendenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_BEENDEN_AUSFUEHREN;
        constructor(public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungBeendenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_BEENDEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungBeendenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_BEENDEN_FEHLER;
        constructor(public payload: any, public veranstaltungRequest: api.VeranstaltungRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungBeendenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_BEENDEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungBeendenActions =
    PostVeranstaltungBeendenAction
    | PostVeranstaltungBeendenAusfuehrenAction
    | PostVeranstaltungBeendenErfolgreichAction
    | PostVeranstaltungBeendenFehlerAction
    | PostVeranstaltungBeendenNichtAusgefuehrtAction
    ;
