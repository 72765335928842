import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_ENTFERNEN = '[VorlagenModul] PostDokumentVeranstaltungsTypenEntfernen laden';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_ENTFERNEN_AUSFUEHREN = '[VorlagenModul] PostDokumentVeranstaltungsTypenEntfernen ausführen';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_ENTFERNEN_ERFOLGREICH = '[VorlagenModul] PostDokumentVeranstaltungsTypenEntfernen laden erfolgreich';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_ENTFERNEN_FEHLER = '[VorlagenModul] PostDokumentVeranstaltungsTypenEntfernen Ladefehler';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentVeranstaltungsTypenEntfernen nicht ausgeführt (evtl. Offline)';



export class PostDokumentVeranstaltungsTypenEntfernenAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_ENTFERNEN;
        constructor(public dokumentVeranstaltungsTypMultiRequest: api.DokumentVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenEntfernenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_ENTFERNEN_AUSFUEHREN;
        constructor(public dokumentVeranstaltungsTypMultiRequest: api.DokumentVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenEntfernenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentVeranstaltungsTypMultiRequest: api.DokumentVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenEntfernenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_ENTFERNEN_FEHLER;
        constructor(public payload: any, public dokumentVeranstaltungsTypMultiRequest: api.DokumentVeranstaltungsTypMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentVeranstaltungsTypenEntfernenActions =
    PostDokumentVeranstaltungsTypenEntfernenAction
    | PostDokumentVeranstaltungsTypenEntfernenAusfuehrenAction
    | PostDokumentVeranstaltungsTypenEntfernenErfolgreichAction
    | PostDokumentVeranstaltungsTypenEntfernenFehlerAction
    | PostDokumentVeranstaltungsTypenEntfernenNichtAusgefuehrtAction
    ;
