import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI = '[VorlagenModul] PostDokumentBerechtigungEntfernenMulti laden';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_AUSFUEHREN = '[VorlagenModul] PostDokumentBerechtigungEntfernenMulti ausführen';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_ERFOLGREICH = '[VorlagenModul] PostDokumentBerechtigungEntfernenMulti laden erfolgreich';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_FEHLER = '[VorlagenModul] PostDokumentBerechtigungEntfernenMulti Ladefehler';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentBerechtigungEntfernenMulti nicht ausgeführt (evtl. Offline)';



export class PostDokumentBerechtigungEntfernenMultiAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI;
        constructor(public dokumentBerechtigungRolleMultiRequest: api.DokumentBerechtigungRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenMultiAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_AUSFUEHREN;
        constructor(public dokumentBerechtigungRolleMultiRequest: api.DokumentBerechtigungRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenMultiErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungRolleMultiRequest: api.DokumentBerechtigungRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenMultiFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungRolleMultiRequest: api.DokumentBerechtigungRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenMultiNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_MULTI_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentBerechtigungEntfernenMultiActions =
    PostDokumentBerechtigungEntfernenMultiAction
    | PostDokumentBerechtigungEntfernenMultiAusfuehrenAction
    | PostDokumentBerechtigungEntfernenMultiErfolgreichAction
    | PostDokumentBerechtigungEntfernenMultiFehlerAction
    | PostDokumentBerechtigungEntfernenMultiNichtAusgefuehrtAction
    ;
