import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_FUNKPROTOKOLL_MELDEART_LISTE = '[VeranstaltungenModul] GetFunkprotokollMeldeartListe laden';
export const GET_FUNKPROTOKOLL_MELDEART_LISTE_AUSFUEHREN = '[VeranstaltungenModul] GetFunkprotokollMeldeartListe ausführen';
export const GET_FUNKPROTOKOLL_MELDEART_LISTE_ERFOLGREICH = '[VeranstaltungenModul] GetFunkprotokollMeldeartListe laden erfolgreich';
export const GET_FUNKPROTOKOLL_MELDEART_LISTE_FEHLER = '[VeranstaltungenModul] GetFunkprotokollMeldeartListe Ladefehler';
export const GET_FUNKPROTOKOLL_MELDEART_LISTE_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetFunkprotokollMeldeartListe nicht ausgeführt (evtl. Offline)';



export class GetFunkprotokollMeldeartListeAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_MELDEART_LISTE;
        constructor(public optPayload: any = null) {}
    }
export class GetFunkprotokollMeldeartListeAusfuehrenAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_MELDEART_LISTE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetFunkprotokollMeldeartListeErfolgreichAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_MELDEART_LISTE_ERFOLGREICH;
        constructor(public payload: Array<api.Meldeart>, public optPayload: any = null) {}
    }
export class GetFunkprotokollMeldeartListeFehlerAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_MELDEART_LISTE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetFunkprotokollMeldeartListeNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKPROTOKOLL_MELDEART_LISTE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryFunkprotokollMeldeartListeActions =
    GetFunkprotokollMeldeartListeAction
    | GetFunkprotokollMeldeartListeAusfuehrenAction
    | GetFunkprotokollMeldeartListeErfolgreichAction
    | GetFunkprotokollMeldeartListeFehlerAction
    | GetFunkprotokollMeldeartListeNichtAusgefuehrtAction
    ;
