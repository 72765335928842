import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_BERECHTIGUNG = '[VeranstaltungenModul] GetVeranstaltungBerechtigung laden';
export const GET_VERANSTALTUNG_BERECHTIGUNG_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungBerechtigung ausführen';
export const GET_VERANSTALTUNG_BERECHTIGUNG_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungBerechtigung laden erfolgreich';
export const GET_VERANSTALTUNG_BERECHTIGUNG_FEHLER = '[VeranstaltungenModul] GetVeranstaltungBerechtigung Ladefehler';
export const GET_VERANSTALTUNG_BERECHTIGUNG_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungBerechtigung nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungBerechtigungAction implements Action {
    readonly type = GET_VERANSTALTUNG_BERECHTIGUNG;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBerechtigungAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_BERECHTIGUNG_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBerechtigungErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_BERECHTIGUNG_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungBerechtigung,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBerechtigungFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_BERECHTIGUNG_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungBerechtigungNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_BERECHTIGUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungBerechtigungActions =
    GetVeranstaltungBerechtigungAction
    | GetVeranstaltungBerechtigungAusfuehrenAction
    | GetVeranstaltungBerechtigungErfolgreichAction
    | GetVeranstaltungBerechtigungFehlerAction
    | GetVeranstaltungBerechtigungNichtAusgefuehrtAction
    ;
