import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_VORLAGEN_CONSTANTS = '[VorlagenModul] GetVorlagenConstants laden';
export const GET_VORLAGEN_CONSTANTS_AUSFUEHREN = '[VorlagenModul] GetVorlagenConstants ausführen';
export const GET_VORLAGEN_CONSTANTS_ERFOLGREICH = '[VorlagenModul] GetVorlagenConstants laden erfolgreich';
export const GET_VORLAGEN_CONSTANTS_FEHLER = '[VorlagenModul] GetVorlagenConstants Ladefehler';
export const GET_VORLAGEN_CONSTANTS_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetVorlagenConstants nicht ausgeführt (evtl. Offline)';



export class GetVorlagenConstantsAction implements Action {
    readonly type = GET_VORLAGEN_CONSTANTS;
        constructor(public optPayload: any = null) {}
    }
export class GetVorlagenConstantsAusfuehrenAction implements Action {
    readonly type = GET_VORLAGEN_CONSTANTS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVorlagenConstantsErfolgreichAction implements Action {
    readonly type = GET_VORLAGEN_CONSTANTS_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetVorlagenConstantsFehlerAction implements Action {
    readonly type = GET_VORLAGEN_CONSTANTS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVorlagenConstantsNichtAusgefuehrtAction implements Action {
    readonly type = GET_VORLAGEN_CONSTANTS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryVorlagenConstantsActions =
    GetVorlagenConstantsAction
    | GetVorlagenConstantsAusfuehrenAction
    | GetVorlagenConstantsErfolgreichAction
    | GetVorlagenConstantsFehlerAction
    | GetVorlagenConstantsNichtAusgefuehrtAction
    ;
