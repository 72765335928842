import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFOBJEKT_AKTUALISIEREN = '[QschecksModul] PostPruefobjektAktualisieren laden';
export const POST_PRUEFOBJEKT_AKTUALISIEREN_AUSFUEHREN = '[QschecksModul] PostPruefobjektAktualisieren ausführen';
export const POST_PRUEFOBJEKT_AKTUALISIEREN_ERFOLGREICH = '[QschecksModul] PostPruefobjektAktualisieren laden erfolgreich';
export const POST_PRUEFOBJEKT_AKTUALISIEREN_FEHLER = '[QschecksModul] PostPruefobjektAktualisieren Ladefehler';
export const POST_PRUEFOBJEKT_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefobjektAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostPruefobjektAktualisierenAction implements Action {
    readonly type = POST_PRUEFOBJEKT_AKTUALISIEREN;
        constructor(public pruefobjektModelRequest: api.PruefobjektModelRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFOBJEKT_AKTUALISIEREN_AUSFUEHREN;
        constructor(public pruefobjektModelRequest: api.PruefobjektModelRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektAktualisierenErfolgreichAction implements Action {
    readonly type = POST_PRUEFOBJEKT_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefobjektModelRequest: api.PruefobjektModelRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektAktualisierenFehlerAction implements Action {
    readonly type = POST_PRUEFOBJEKT_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public pruefobjektModelRequest: api.PruefobjektModelRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFOBJEKT_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefobjektAktualisierenActions =
    PostPruefobjektAktualisierenAction
    | PostPruefobjektAktualisierenAusfuehrenAction
    | PostPruefobjektAktualisierenErfolgreichAction
    | PostPruefobjektAktualisierenFehlerAction
    | PostPruefobjektAktualisierenNichtAusgefuehrtAction
    ;
