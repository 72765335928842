import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_DATA = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtData laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_DATA_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtData ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_DATA_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtData laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_DATA_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtData Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_DATA_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtData nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereichChecklistePruefberichtDataAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_DATA;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichChecklistePruefberichtDataAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_DATA_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichChecklistePruefberichtDataErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_DATA_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungPruefberichtReportModel,  public veranstaltung_id:string ,  public funktionsbereich_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichChecklistePruefberichtDataFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_DATA_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public funktionsbereich_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichChecklistePruefberichtDataNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_DATA_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereichChecklistePruefberichtDataActions =
    GetVeranstaltungFunktionsbereichChecklistePruefberichtDataAction
    | GetVeranstaltungFunktionsbereichChecklistePruefberichtDataAusfuehrenAction
    | GetVeranstaltungFunktionsbereichChecklistePruefberichtDataErfolgreichAction
    | GetVeranstaltungFunktionsbereichChecklistePruefberichtDataFehlerAction
    | GetVeranstaltungFunktionsbereichChecklistePruefberichtDataNichtAusgefuehrtAction
    ;
