import {Injectable, Injector, Inject} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {filter, map, tap, withLatestFrom} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {getMandant} from '@orga-app/mandant/reducer';
import {MandantInfo} from '../clients/api';
import {AuthService, MyAuthOptions} from '@orga-app/auth';
import {AUTH_LOGIN_ERFOLGREICH} from '@orga-app/auth/actions';
import {MANDANT_SETZEN} from '@orga-app/mandant/actions';
import {getIsLoggedIn} from '@orga-app/auth/reducer';
import {MandantAuswahlRoute} from '@orga-app/mandant/variables';


@Injectable()
export class AppEffectsDesktopClient {

    @Effect({dispatch: false})
    afterLoginOderAfterMandant$ = this.actions$.pipe(
        ofType(AUTH_LOGIN_ERFOLGREICH, MANDANT_SETZEN),

        filter(() => this.getAuthService().hasValidToken()),

        withLatestFrom(this.store.pipe(select(getIsLoggedIn))),
        filter(([x, isLoggedIn]) => isLoggedIn),
        map(([x, isLoggedIn]) => x),

        withLatestFrom(this.store.pipe(select(getMandant))),
        filter(([x, mandant]: [any, MandantInfo]) => mandant !== null),

        tap(([x, mandant]: [any, MandantInfo]) => {

                // console.debug('AppEffects ::afterLoginOderAfterMandant, zu home navigieren ');
                // console.debug('aktuelle route:', this.route, this.router.routerState);
                const url = window.location.href; // this.router.routerState.snapshot.url;

               // console.debug('aktuelle url:', url);

                if (url.includes(this.authConfig.loginRoute)) {
                    console.debug('AppEffects ::afterLoginOderAfterMandant: navigate to home');
                    this.router.navigate(['/home']);
                }
            }
        )
    );


    @Effect({dispatch: false})
    afterLoginOhneMandant$ = this.actions$.pipe(
        ofType(AUTH_LOGIN_ERFOLGREICH),

        filter(() => this.getAuthService().hasValidToken()),

        withLatestFrom(this.store.pipe(select(getIsLoggedIn))),
        filter(([x, isLoggedIn]) => isLoggedIn),
        map(([x, isLoggedIn]) => x),

        withLatestFrom(this.store.pipe(select(getMandant))),
        filter(([x, mandant]: [any, MandantInfo]) => mandant === null),

        tap(([x, mandant]: [any, MandantInfo]) => {

            console.debug('AppEffects ::afterLoginOhneMandant, zu mandanten wechsel navigieren ');
            this.router.navigate([this.mandantAuswahlRoute, {
                returnTo: '/home' // danach zu home
            }]);
        })
    );


    constructor(private actions$: Actions,
                public router: Router,
                public route: ActivatedRoute,
                private store: Store<any>,
                private injector: Injector,
                @Inject(MandantAuswahlRoute) private mandantAuswahlRoute: string,
                private authConfig: MyAuthOptions) {
    }

    private _authService: AuthService = null;

    private getAuthService(): AuthService {
        if (this._authService === null) {
            this._authService = this.injector.get(AuthService);
        }
        return this._authService;
    }


}
