import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_ANLEGEN = '[VorlagenModul] PostAufgabeAnlegen laden';
export const POST_AUFGABE_ANLEGEN_AUSFUEHREN = '[VorlagenModul] PostAufgabeAnlegen ausführen';
export const POST_AUFGABE_ANLEGEN_ERFOLGREICH = '[VorlagenModul] PostAufgabeAnlegen laden erfolgreich';
export const POST_AUFGABE_ANLEGEN_FEHLER = '[VorlagenModul] PostAufgabeAnlegen Ladefehler';
export const POST_AUFGABE_ANLEGEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostAufgabeAnlegen nicht ausgeführt (evtl. Offline)';



export class PostAufgabeAnlegenAction implements Action {
    readonly type = POST_AUFGABE_ANLEGEN;
        constructor(public aufgabeModelRequest: api.AufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostAufgabeAnlegenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_ANLEGEN_AUSFUEHREN;
        constructor(public aufgabeModelRequest: api.AufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostAufgabeAnlegenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_ANLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeModelRequest: api.AufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostAufgabeAnlegenFehlerAction implements Action {
    readonly type = POST_AUFGABE_ANLEGEN_FEHLER;
        constructor(public payload: any, public aufgabeModelRequest: api.AufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostAufgabeAnlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_ANLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandAufgabeAnlegenActions =
    PostAufgabeAnlegenAction
    | PostAufgabeAnlegenAusfuehrenAction
    | PostAufgabeAnlegenErfolgreichAction
    | PostAufgabeAnlegenFehlerAction
    | PostAufgabeAnlegenNichtAusgefuehrtAction
    ;
