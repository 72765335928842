import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.command.pruefplan-aktualisieren';
import * as api from '../../../clients/qschecks';


    @Injectable()
    export class QschecksPruefplanAktualisierenEffects {

        @Effect()
            PruefplanAktualisieren$ = this.actions$.pipe(
                ofType(ac.POST_PRUEFPLAN_AKTUALISIEREN),
                flatMap((x: ac.PostPruefplanAktualisierenAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().postPruefplanAktualisieren(x.pruefplanModelRequest).pipe(
                    map((result: any) => {
                        const nextAction = new ac.PostPruefplanAktualisierenErfolgreichAction(result, x.pruefplanModelRequest,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.PostPruefplanAktualisierenFehlerAction(error, x.pruefplanModelRequest,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.AdminCommandService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.AdminCommandService {
                if (this.service === null) {
                    this.service = this.injector.get(api.AdminCommandService);
                }
                return this.service;
            }
        
}
