import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_ERINNERUNG_VERSENDEN = '[VeranstaltungenModul] PostAufgabeErinnerungVersenden laden';
export const POST_AUFGABE_ERINNERUNG_VERSENDEN_AUSFUEHREN = '[VeranstaltungenModul] PostAufgabeErinnerungVersenden ausführen';
export const POST_AUFGABE_ERINNERUNG_VERSENDEN_ERFOLGREICH = '[VeranstaltungenModul] PostAufgabeErinnerungVersenden laden erfolgreich';
export const POST_AUFGABE_ERINNERUNG_VERSENDEN_FEHLER = '[VeranstaltungenModul] PostAufgabeErinnerungVersenden Ladefehler';
export const POST_AUFGABE_ERINNERUNG_VERSENDEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostAufgabeErinnerungVersenden nicht ausgeführt (evtl. Offline)';



export class PostAufgabeErinnerungVersendenAction implements Action {
    readonly type = POST_AUFGABE_ERINNERUNG_VERSENDEN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeErinnerungVersendenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_ERINNERUNG_VERSENDEN_AUSFUEHREN;
        constructor(public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeErinnerungVersendenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_ERINNERUNG_VERSENDEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeErinnerungVersendenFehlerAction implements Action {
    readonly type = POST_AUFGABE_ERINNERUNG_VERSENDEN_FEHLER;
        constructor(public payload: any, public aufgabeRequest: api.AufgabeRequest, public optPayload: any = null) {}
    }
export class PostAufgabeErinnerungVersendenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_ERINNERUNG_VERSENDEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandAufgabeErinnerungVersendenActions =
    PostAufgabeErinnerungVersendenAction
    | PostAufgabeErinnerungVersendenAusfuehrenAction
    | PostAufgabeErinnerungVersendenErfolgreichAction
    | PostAufgabeErinnerungVersendenFehlerAction
    | PostAufgabeErinnerungVersendenNichtAusgefuehrtAction
    ;
