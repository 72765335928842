import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action, select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {filter, flatMap, map, withLatestFrom} from 'rxjs/operators';
import {
    GetVeranstaltungDeckblattAction,
    GetVeranstaltungenAktuelleAction, GetVeranstaltungMitteilungBerechtigungAction,
    GetVeranstaltungStatusAction
} from '../../../../client-ngrx/veranstaltungen';
import {Veranstaltung} from '../../../../clients/veranstaltungen';
import {getAktuelleVeranstaltung} from '../app-state.reducer';
import {
    AKTUELLE_VERANSTALTUNG_SETZEN,
    AktuelleVeranstaltungSetzenAction,
    PAGE_DATEN_LADEN,
    PageLoadDataAction
} from '../actions/veranstaltung.actions';
import { getAuthState, AuthState } from '@orga-app/auth/reducer';


@Injectable()
export class VeranstaltungEffects {

    @Effect()
    aktuelleVeranstaltungenLaden$: Observable<Action> = this.actions$.pipe(
        ofType(PAGE_DATEN_LADEN),
        withLatestFrom(this.store$.pipe(select(getAuthState))),
        filter(([x, authState]: [PageLoadDataAction, AuthState]) => authState != null),
        filter(([x, authState]: [PageLoadDataAction, AuthState]) => authState.isLoggedIn),
        withLatestFrom(this.store$.pipe(select(getAktuelleVeranstaltung))),
        filter(([[x, authState], aktuelleVeranstaltung]: [[PageLoadDataAction, AuthState], Veranstaltung]) => aktuelleVeranstaltung !== null),
        filter(([[x, authState], aktuelleVeranstaltung]: [[PageLoadDataAction, AuthState], Veranstaltung]) => aktuelleVeranstaltung !== null),
        flatMap(([[x, authState], aktuelleVeranstaltung]: [[PageLoadDataAction, AuthState], Veranstaltung]) => {
                return [
                    new GetVeranstaltungDeckblattAction(aktuelleVeranstaltung.veranstaltung_id),
                    new GetVeranstaltungStatusAction(aktuelleVeranstaltung.veranstaltung_id),
                    new GetVeranstaltungMitteilungBerechtigungAction(aktuelleVeranstaltung.veranstaltung_id),
                ];
            }
        )
    );

    @Effect()
    deckblattLaden$: Observable<Action> = this.actions$.pipe(
        ofType(PAGE_DATEN_LADEN),
        withLatestFrom(this.store$.pipe(select(getAuthState))),
        filter(([x, authState]: [Veranstaltung, AuthState]) => authState != null),
        filter(([x, authState]: [Veranstaltung, AuthState]) => authState.isLoggedIn),
        map(() => {
            return new GetVeranstaltungenAktuelleAction();
        }));

    @Effect()
    aktuelleVeranstaltungSetzen$: Observable<Action> = this.actions$.pipe(
        ofType(AKTUELLE_VERANSTALTUNG_SETZEN),
        map((x: AktuelleVeranstaltungSetzenAction) => x),
        filter(x => x.payload != null),
        map(x => x.payload),
        filter(x => x.veranstaltung_id != null),
        flatMap(x => {
                return [
                    new GetVeranstaltungDeckblattAction(x.veranstaltung_id),
                    new GetVeranstaltungStatusAction(x.veranstaltung_id),
                    new GetVeranstaltungMitteilungBerechtigungAction(x.veranstaltung_id)
                ];
            }
        )
    );


    constructor(private actions$: Actions,
                private store$: Store<any>) {
    }
}
