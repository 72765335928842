import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, IHttpConnectionOptions, LogLevel} from '@microsoft/signalr';
import {Store} from '@ngrx/store';
import {WebsocketReconnectedAction, WebsocketStartErfolgreichAction, WebsocketTelemetryEventAction} from './actions';
import {WS_PATH} from './variables';
import {OAuthService} from 'angular-oauth2-oidc';

export interface HandlerRegistration {
    on: string;
    subject: BehaviorSubject<any>;
}

@Injectable({providedIn: 'root'})
export class WebsocketService {
    public wsPath: string;
    public started$: Observable<boolean>;
    public isReconnecting$: Observable<boolean>;
    public connectionFails$: Observable<number>;
    // private accessToken: string;

    private isReconnecting: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private hubConnection: HubConnection;
    private started: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private regs: Array<HandlerRegistration> = Array();
    private userStopped: boolean;
    private connectionId: string;
    private connectionFails: BehaviorSubject<number> = new BehaviorSubject(0);

    private isStartedBackingField: boolean;

    private mandantIdBackingField: string;

    public get mandantId(): string {
        return this.mandantIdBackingField;
    }

    public get connectionState(): HubConnectionState {

        if (this.hubConnection) {
            return this.hubConnection.state;
        }

        return HubConnectionState.Disconnected;
    }

    public registerMandant(mandantId: string) {
        console.debug('Websocket :: registerMandant', mandantId);

        this.mandantIdBackingField = mandantId;
        this.sendMessage('RegisterMandant', mandantId)
            .then((b) => {
                console.debug('mandant registered mandant - ', b);
            });
    }

    public get isStarted(): boolean {
        return this.isStartedBackingField;
    }

    constructor(@Inject(WS_PATH) path: string,
                private store: Store<any>,
                private oauthService: OAuthService) {
        console.debug('Websocket :: constructor');

        this.started$ = this.started.asObservable();
        this.connectionFails$ = this.connectionFails.asObservable();
        this.isReconnecting$ = this.isReconnecting.asObservable();
        this.wsPath = path;
    }


    public getHubConnection(): HubConnection {
        console.debug('Websocket :: getHubConnection', this.hubConnection);
        return this.hubConnection;
    }

    intWS() {
        console.log('Websocket :: initWS');

        if (this.wsPath !== undefined) {
            const options = <IHttpConnectionOptions>{
                // transport: HttpTransportType.WebSockets || HttpTransportType.LongPolling
                
            };

            // const ixAuth = (this.wsPath !== undefined) ? this.wsPath.indexOf('auth') : -1;
            // if (ixAuth > -1) {
            this.store.dispatch(new WebsocketTelemetryEventAction('WebsocketService', {
                    file: 'WebsocketService',
                    function: 'intWS',
                    type: 'Log',
                    description: 'Creating SignalR HubConnection with token.',
                    connectionState: (this.hubConnection) ? this.hubConnection.state : null,
                    connectionId: this.connectionId,
                    fehler: null
                }
            ));
            // console.debug('Websocket :: intWS :: accessTokenFactory', this.getAccessToken());
            options.accessTokenFactory = () => this.oauthService.getAccessToken();
            // }

            this.hubConnection = new HubConnectionBuilder()
                .withUrl(this.wsPath, options)
                .configureLogging(LogLevel.Information)                
                // .withAutomaticReconnect([0, 2000, 3500, 5000])
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: retryContext => {

                        if (retryContext.elapsedMilliseconds < 60000) {
                            this.isReconnecting.next(true);
                            return 3000;
                        }
                        return 10000;
                        
                        //  else {
                        //     // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                        //     this.isReconnecting.next(false);
                        //     return null;
                        // }
                    }
                })
                .build();

            this.hubConnection.onreconnecting((error) => {
                console.debug('Websocket :: onreconnecting');
                this.started.next(false);
                this.isStartedBackingField = false;
                this.isReconnecting.next(true);
                console.assert(this.hubConnection.state === HubConnectionState.Reconnecting);

                this.store.dispatch(new WebsocketTelemetryEventAction('WebsocketService', {
                        file: 'WebsocketService',
                        function: 'reconnecting',
                        type: 'Log',
                        description: 'SignalR Reconnecting HubConnection.',
                        connectionState: (this.hubConnection) ? this.hubConnection.state : null,
                        connectionId: this.connectionId,
                        fehler: null
                    }
                ));
            });

            this.hubConnection.onreconnected((connectionId: string) => {
                    console.debug('Websocket :: reconnect', connectionId);

                    this.isReconnecting.next(false);
                    this.started.next(true);
                    this.isStartedBackingField = true;
                    this.connectionId = connectionId;

                    this.store.dispatch(new WebsocketReconnectedAction());

                    if (this.mandantId) {
                        this.registerMandant(this.mandantId);
                    }

                    this.store.dispatch(new WebsocketTelemetryEventAction('WebsocketService', {
                        file: 'WebsocketService',
                        function: 'reconnected',
                        type: 'Log',
                        description: 'SignalR HubConnection erfolgreich Reconnected.',
                        connectionState: (this.hubConnection) ? this.hubConnection.state : null,
                        connectionId: this.connectionId,
                        fehler: null
                    }));
                }
            );


            // Wenn mehr als 4 Versuchen fehlgeschlagen sind
            this.hubConnection.onclose((e: Error) => {
                console.debug('Websocket close');

                this.started.next(false);
                this.isStartedBackingField = false;

                // TB: 2021-04-12
                // if (!this.userStopped) {
                //   console.warn('WebsocketService:: hub connected ohne zutun des benutzers, starte erneut', e);
                //   this.store.dispatch(new WebsocketTelemetryEventAction('WebsocketService', {
                //     file: 'WebsocketService',
                //     function: 'onclose',
                //     type: 'Log',
                //     description: 'SignalR HubConnection wurde nach 4 fehlgeschlagenen Versuchen gestoppt. Neuer Start wird inziniert.',
                //     connectionState: (this.hubConnection) ? this.hubConnection.state : null,
                //     connectionId: this.connectionId,
                //     fehler: JSON.stringify(e)
                //   }
                //   ));
                //   this.store.dispatch(new WebsocketStartAction());
                // } else {
                //   console.debug('Websocket close :: userStopped');
                // }

            });


        } else {
            console.debug('KEIN WS PATH GESETZT');
            this.store.dispatch(new WebsocketTelemetryEventAction('WebsocketService', {
                    file: 'WebsocketService',
                    function: 'kein WS Path',
                    type: 'Log',
                    description: 'SignalR HubConnection konnte nicht gestartet werden, da kein WS PATH gesetzt ist.',
                    connectionState: (this.hubConnection) ? this.hubConnection.state : null,
                    connectionId: this.connectionId,
                    fehler: null
                }
            ));
        }
    }

    async delay(ms: number) {
        await new Promise(resolve => setTimeout(() => resolve(), ms)).then(() => console.log('fired'));
    }

    public async stop() {
        console.log('Websocket :: stop');

        if (this.hubConnection && this.hubConnection !== undefined && this.hubConnection.state) {
            try {
                console.debug('Websocket :: hubConnection.state', this.hubConnection.state);

                let i = 0;
                while (
                    (this.hubConnection && this.hubConnection !== undefined && this.hubConnection.state) &&
                    ((this.hubConnection.state === HubConnectionState.Connecting ||
                        this.hubConnection.state === HubConnectionState.Disconnecting))
                    && i < 5
                    ) {
                    console.debug('Websocket :: stop, wait X', i);
                    await this.delay(200);
                    i++;
                }

                if (this.hubConnection && this.hubConnection !== undefined && this.hubConnection.state && this.hubConnection.state === HubConnectionState.Connected) {
                    console.debug('Websocket :: doing stop');
                    await this.hubConnection.stop();
                    this.hubConnection = undefined;
                    this.userStopped = true;
                    this.started.next(false);
                    this.isStartedBackingField = false;
                }
            } catch (err) {
                console.warn(err);
            }
        }
    }


    public async sendMessage(methodName: string, ...args: any[]) {
        console.debug('Websocket :: sendMessage', methodName, args);
        if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
            console.debug('Websocket :: sendMessage , doSend');

            if (!args) {
                await this.hubConnection.send(methodName);
                return true;
            }
            if (args.length === 0) {
                await this.hubConnection.send(methodName);
                return true;
            }
            if (args.length === 1) {
                await this.hubConnection.send(methodName, args[0]);
                return true;
            }
            if (args.length === 2) {
                await this.hubConnection.send(methodName, args[0], args[1]);
                return true;
            }
            if (args.length === 3) {
                await this.hubConnection.send(methodName, args[0], args[1], args[2]);
                return true;
            }
        }

        return false;
    }


    public async start() {
        console.log('Websocket :: start');

        // if (this.hubConnection === undefined || this.hubConnection === null) {
        this.intWS();
        // }
        // else {
        //   console.debug('Websocket :: start : return in existing hubconnection');
        //   return;
        // }


        if (this.hubConnection === undefined || this.hubConnection === null) {
            return;
        }

        this.userStopped = false;

        console.debug('Websocket :: hubConnection.state', this.hubConnection.state);

        if (this.hubConnection.state !== HubConnectionState.Disconnected) {
            console.debug('Websocket :: hubConnection.state is not disconnected');
            return;
        }

        try {
            await this.hubConnection.start();
            this.registerConnectionHandler(this.hubConnection);

            this.isStartedBackingField = true;
            this.started.next(true);

            console.debug('Websocket :: started');
            this.store.dispatch(new WebsocketStartErfolgreichAction());
            this.store.dispatch(new WebsocketTelemetryEventAction('WebsocketService', {
                    file: 'WebsocketService',
                    function: 'startAsync',
                    type: 'Succeeded',
                    description: 'SignalR HubConnection Verbindung konnte hergestellt werden.',
                    connectionState: (this.hubConnection) ? this.hubConnection.state : null,
                    connectionId: this.connectionId,
                    fehler: null
                }
            ));
        } catch (err) {
            console.log(err);
            this.isStartedBackingField = false;
            this.started.next(false);
        }

        console.debug('Websocket :: start finished');
    }

    public register(on: string, subject: BehaviorSubject<any>) {
        const reg = <HandlerRegistration>{
            on,
            subject
        };

        this.regs.push(reg);
    }

    private registerConnectionHandler(con: HubConnection) {
        this.regs.forEach(x => {
            con.on(x.on, (msg) => {
                x.subject.next(msg);
            });
        });
    }
}
