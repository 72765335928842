import {ChangeDetectorRef, Injectable, Injector, NgZone} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs/Observable';
import {Action, select, Store} from '@ngrx/store';
import {filter, flatMap, map, tap, withLatestFrom} from 'rxjs/operators';
import {AppResetAction} from '@orga-app/appstart/actions';
import {AUTH_LOGIN_ERFOLGREICH, AUTH_LOGOUT, AuthLogoutAction, AuthErrorAction, AUTH_ERROR} from '@orga-app/auth/actions';
import {ActivatedRoute, Router} from '@angular/router';
import {WebsocketStopAction, WebsocketRefreshAction} from '@orga-app/websocket/actions';
import {getMandant} from '@orga-app/mandant/reducer';
import {NaviBenutzerZugriffeLadenAction, NaviSetzenAction} from './navi/navi.actions';
import {pagesDef} from './navi.pages';
import {GetBenutzerInfoAction} from '../client-ngrx/benutzerverwaltung';
import {AuthService} from '@orga-app/auth';
import {MandantAusgewaehltUiAction, MANDANT_AUSGEWAEHLT_UI, MANDANT_SETZEN} from '@orga-app/mandant/actions';
import {getIsLoggedIn} from '@orga-app/auth/reducer';
import {NotificationService} from '@orga-app/providers/notification.service';
import {MandantInfo} from 'src/clients/api';


@Injectable()
export class AppEffects {

    // @Effect()
    // wsSetTokenAndStart$: Observable<Action> = this.actions$.pipe(
    //     ofType(AUTH_LOGIN_ERFOLGREICH),
    //     filter(() => this.getAuthService().hasValidToken()),
    //     map(() => {
    //         return new WebsocketSetTokenAndStartAction(this.getAuthService().accessToken);
    //     })
    // );

    // @Effect()
    // wsSetMandant$ = this.actions$.pipe(
    //     ofType(WEBSOCKET_START_ERFOLGREICH_ACTION, WEBSOCKET_RECONNECTED_ACTION, MANDANT_SETZEN),
    //     filter(() => this.getAuthService().hasValidToken()),
    //     withLatestFrom(this.store.pipe(select(getMandant))),
    //     filter(([x, mandant]: [any, MandantInfo]) => mandant !== null),
    //     map(([x, mandant]) => {
    //         console.debug('STARTING :: AppEffects :: wsSetMandant$ :: RegisterMandant');

    //         return new WebsocketSetMandantAction(mandant.mandant_id);
    //     })
    // );

    // // TB: das wird doch im mandanten guard gemacht???
    // @Effect({ dispatch: false })
    // startAppAction$ = this.actions$.pipe(
    //   ofType(AUTH_LOGIN_ERFOLGREICH), //START_APP_ACTION
    //   filter(() => this.getAuthService().hasValidToken()),

    //   withLatestFrom(this.store.pipe(select(getIsLoggedIn))),
    //   filter(([x, isLoggedIn]) => isLoggedIn),
    //   map(([x, isLoggedIn]) => x),

    //   withLatestFrom(this.store.pipe(select(getMandant))),
    //   // filter(([x, mandant]: [any, MandantInfo]) => mandant === null),

    //   tap(([x, mandant]: [any, MandantInfo]) => {
    //     console.debug('AppEffects :: startAppAction$ :: KEIN MANDANT');

    //     if (mandant === null) {

    //       this.router.navigate(['/mandanten-auswahl'], {
    //         //   queryParams: (window.location.pathname !== '/login' && window.location.pathname !== '/') ? { returnUrl: this.router.url.split('?')[0] } : {},
    //         relativeTo: this.route
    //       });

    //     }
    //   })
    // );

    /*@Effect()
    afterMandantSetzen$ = this.actions$.pipe(
        ofType(MANDANT_SETZEN),
        filter(() => this.getAuthService().hasValidToken()),

        withLatestFrom(this.store.pipe(select(getIsLoggedIn))),
        filter(([x, isLoggedIn]) => isLoggedIn),
        map(([x, isLoggedIn]) => x),

        withLatestFrom(this.store.pipe(select(getMandant))),
        filter(([x, mandant]: [any, MandantInfo]) => mandant !== null),
        flatMap(([x, mandant]: [any, MandantInfo]) => {
                console.debug('AppEffects :: MANDANT_SETZEN und Logged in');
                return [
                    new AppResetAction()
                ];
            }
        )
    );*/

    @Effect()
    afterLoginOderMandantSetzen$ = this.actions$.pipe(
        ofType(AUTH_LOGIN_ERFOLGREICH, MANDANT_SETZEN),
        filter(() => this.getAuthService().hasValidToken()),

        withLatestFrom(this.store.pipe(select(getIsLoggedIn))),
        filter(([x, isLoggedIn]) => isLoggedIn),
        map(([x, isLoggedIn]) => x),

        withLatestFrom(this.store.pipe(select(getMandant))),
        filter(([x, mandant]: [any, MandantInfo]) => mandant !== null),
        flatMap(([x, mandant]: [any, MandantInfo]) => {

                console.debug('AppEffects ::AUTH_LOGIN_ERFOLGREICH, MANDANT_SETZEN mit Mandant');
                return [
                    new NaviSetzenAction(pagesDef),
                    new NaviBenutzerZugriffeLadenAction(),
                    new GetBenutzerInfoAction(),
                    new WebsocketRefreshAction(mandant.mandant_id, this.getAuthService().accessToken)
                ];

            }
        )
    );



    // @Effect({ dispatch: false })
    // MandantAusgewahltinUIAction$ = this.actions$.pipe(
    //   ofType(MANDANT_AUSGEWAEHLT_UI),
    //   filter(() => this.getAuthService().hasValidToken()),
    //   withLatestFrom(this.store.pipe(select(getMandant))),
    //   filter(([x, mandant]: [MandantAusgewaehltUiAction, MandantInfo]) => mandant !== null),
    //   tap((x) => {

    //     console.debug('AppEffects :: MANDANT_AUSGEWAEHLT_UI');
    //     //TB: einfach zum basis route navigieren und vom routing module leiten lassen
    //     this.router.navigate(['/home']);

    //     // const defaultPage = '/veranstaltungen';
    //     // // Query Parameter auswerten, ob es eine returnUrl gibt
    //     // const urlParams = new URLSearchParams(window.location.search);
    //     // let url = null;
    //     // if (urlParams.has('returnUrl')) {
    //     //   url = urlParams.get('returnUrl');
    //     // }

    //     // if (url === '/login' || url === '/' || '/mandanten-auswahl') {
    //     //   url = defaultPage;
    //     // }

    //     // // wenn es keine returnUrl gab, dann ist hier die url noch null und es wird die angefragte Seite aufgerufen
    //     // // Wenn dies allerdings die LoginPage ist oder einfach nur root, muss an den default weitergeleitet werden,
    //     // // da der user sonst auf der Login Seite hängen bleibt
    //     // if (url === null && (window.location.pathname === '/login' || (window.location.pathname === '/'))) {
    //     //   url = defaultPage;
    //     // }

    //     // // console.debug('AppEffects :: StartAppOderMandantSetzenErfolgreichAction$ :: redirect...');
    //     // console.debug('AppEffects :: StartAppOderMandantSetzenErfolgreichAction$ :: redirect', url);
    //     // if (url) {
    //     //   this.router.navigate([url], {
    //     //     //  queryParams: (window.location.pathname !== '/login' && window.location.pathname !== '/') ? { returnUrl: this.router.url.split('?')[0] } : {},
    //     //     relativeTo: this.route
    //     //   });
    //     //}
    //   })
    // );


    @Effect({dispatch: true})
    logout$: Observable<Action> = this.actions$.pipe(
        ofType<AuthLogoutAction>(AUTH_LOGOUT),
        flatMap(() => {
                console.debug('AppEffects :: Logout');

                this.getAuthService().logout();

                this.router.navigate(['/login'], {
                    relativeTo: this.route
                });

                return [
                    new WebsocketStopAction(),
                    new AppResetAction()
                ];

            }
        )
    );

    @Effect({dispatch: true})
    authError$: Observable<Action> = this.actions$.pipe(
        ofType<AuthErrorAction>(AUTH_ERROR),
        flatMap(() => {
                console.debug('AppEffects :: AuthError');

                return [
                    new WebsocketStopAction()
                ];
            }
        )
    );


    // TB: Dieses hier ist drin, da das renderin ein problem macht.
    @Effect({dispatch: false})
    rerenderAfterAction$: Observable<Action> = this.actions$.pipe(
        filter(a => {
              const x = a.type.toLowerCase();
              return x.includes('get')  && (a.type.includes('erfolgreich') || a.type.includes('fehler'));
         }),
        tap((a) => {
                // console.debug('rerenderAfterAction :: ', a.type);

                // dummy angular operation für rerendering
                this.zone.run(() => {

                });

            }
        )
    );

    // @Effect({dispatch: false})
    // authErrorNotification$ = this.actions$.pipe(
    //     ofType<AuthErrorAction>(AUTH_ERROR),
    //     map(x => <AuthErrorAction>x),
    //     tap((x: AuthErrorAction) => {
    //         // mögliche event types =
    //         // 'session_terminated', 'session_error', 'silent_refresh_timeout', 'token_error'
    //
    //         let titel = 'Bitte erneut anmelden.';
    //
    //         //  if (x.eventType === 'session_terminated') {
    //         //    titel = 'Die Sitzung wurde serverseitig beendet, bitte erneut anmelden.';
    //         //  }
    //         //
    //         //  if (x.eventType === 'session_error') {
    //         //    titel = 'Sitzung fehlerhaft, bitte erneut anmelden.';
    //         //  }
    //         //
    //         //  if (x.eventType === 'token_error') {
    //         //    titel = 'Anmelde-Token fehlerhaft, bitte erneut anmelden.';
    //         //  }
    //         //
    //         //  if (x.eventType === 'silent_refresh_timeout') {
    //         //    titel = 'Es ist aus Sicherheitsgründen wieder an der Zeit Benutzername und Kennwort anzugeben.';
    //         //  }
    //         //
    //         // if (x.error === 'interaction_required') {
    //         //
    //         // }
    //
    //         //titel = 'Message aus app.effects: ' + x.eventType + ' | ' + x.error + ' | ' + x.errorDescription;
    //
    //
    //       //  this.notificationService.showMessage(titel);
    //     })
    // );

    constructor(private actions$: Actions,
                public router: Router,
                public route: ActivatedRoute,
                private store: Store<any>,
                private injector: Injector,
                private notificationService: NotificationService,
                private zone: NgZone) {
    }

    private _authService: AuthService = null;

    private getAuthService(): AuthService {
        if (this._authService === null) {
            this._authService = this.injector.get(AuthService);
        }
        return this._authService;
    }


}
