import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGEN_AKTUELLE = '[VeranstaltungenModul] GetVeranstaltungenAktuelle laden';
export const GET_VERANSTALTUNGEN_AKTUELLE_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungenAktuelle ausführen';
export const GET_VERANSTALTUNGEN_AKTUELLE_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungenAktuelle laden erfolgreich';
export const GET_VERANSTALTUNGEN_AKTUELLE_FEHLER = '[VeranstaltungenModul] GetVeranstaltungenAktuelle Ladefehler';
export const GET_VERANSTALTUNGEN_AKTUELLE_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungenAktuelle nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungenAktuelleAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_AKTUELLE;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenAktuelleAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_AKTUELLE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenAktuelleErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_AKTUELLE_ERFOLGREICH;
        constructor(public payload: Array<api.Veranstaltung>, public optPayload: any = null) {}
    }
export class GetVeranstaltungenAktuelleFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_AKTUELLE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungenAktuelleNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_AKTUELLE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungenAktuelleActions =
    GetVeranstaltungenAktuelleAction
    | GetVeranstaltungenAktuelleAusfuehrenAction
    | GetVeranstaltungenAktuelleErfolgreichAction
    | GetVeranstaltungenAktuelleFehlerAction
    | GetVeranstaltungenAktuelleNichtAusgefuehrtAction
    ;
