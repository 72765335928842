import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNGS_TYP_ADMIN_ROLLE_ENTFERNEN = '[VeranstaltungstypenModul] PostVeranstaltungsTypAdminRolleEntfernen laden';
export const POST_VERANSTALTUNGS_TYP_ADMIN_ROLLE_ENTFERNEN_AUSFUEHREN = '[VeranstaltungstypenModul] PostVeranstaltungsTypAdminRolleEntfernen ausführen';
export const POST_VERANSTALTUNGS_TYP_ADMIN_ROLLE_ENTFERNEN_ERFOLGREICH = '[VeranstaltungstypenModul] PostVeranstaltungsTypAdminRolleEntfernen laden erfolgreich';
export const POST_VERANSTALTUNGS_TYP_ADMIN_ROLLE_ENTFERNEN_FEHLER = '[VeranstaltungstypenModul] PostVeranstaltungsTypAdminRolleEntfernen Ladefehler';
export const POST_VERANSTALTUNGS_TYP_ADMIN_ROLLE_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] PostVeranstaltungsTypAdminRolleEntfernen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungsTypAdminRolleEntfernenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ADMIN_ROLLE_ENTFERNEN;
        constructor(public veranstaltungsTypRolleRequest: api.VeranstaltungsTypRolleRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAdminRolleEntfernenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ADMIN_ROLLE_ENTFERNEN_AUSFUEHREN;
        constructor(public veranstaltungsTypRolleRequest: api.VeranstaltungsTypRolleRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAdminRolleEntfernenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ADMIN_ROLLE_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungsTypRolleRequest: api.VeranstaltungsTypRolleRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAdminRolleEntfernenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ADMIN_ROLLE_ENTFERNEN_FEHLER;
        constructor(public payload: any, public veranstaltungsTypRolleRequest: api.VeranstaltungsTypRolleRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungsTypAdminRolleEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNGS_TYP_ADMIN_ROLLE_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenCommandVeranstaltungsTypAdminRolleEntfernenActions =
    PostVeranstaltungsTypAdminRolleEntfernenAction
    | PostVeranstaltungsTypAdminRolleEntfernenAusfuehrenAction
    | PostVeranstaltungsTypAdminRolleEntfernenErfolgreichAction
    | PostVeranstaltungsTypAdminRolleEntfernenFehlerAction
    | PostVeranstaltungsTypAdminRolleEntfernenNichtAusgefuehrtAction
    ;
