import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_PRUEFPLAN_PRUEFOBJEKTE_ADMIN = '[QschecksModul] GetPruefplanPruefobjekteAdmin laden';
export const GET_PRUEFPLAN_PRUEFOBJEKTE_ADMIN_AUSFUEHREN = '[QschecksModul] GetPruefplanPruefobjekteAdmin ausführen';
export const GET_PRUEFPLAN_PRUEFOBJEKTE_ADMIN_ERFOLGREICH = '[QschecksModul] GetPruefplanPruefobjekteAdmin laden erfolgreich';
export const GET_PRUEFPLAN_PRUEFOBJEKTE_ADMIN_FEHLER = '[QschecksModul] GetPruefplanPruefobjekteAdmin Ladefehler';
export const GET_PRUEFPLAN_PRUEFOBJEKTE_ADMIN_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPruefplanPruefobjekteAdmin nicht ausgeführt (evtl. Offline)';



export class GetPruefplanPruefobjekteAdminAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFOBJEKTE_ADMIN;
        constructor( public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefobjekteAdminAusfuehrenAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFOBJEKTE_ADMIN_AUSFUEHREN;
        constructor( public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefobjekteAdminErfolgreichAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFOBJEKTE_ADMIN_ERFOLGREICH;
        constructor(public payload: Array<api.Pruefobjekt>,  public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefobjekteAdminFehlerAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFOBJEKTE_ADMIN_FEHLER;
        constructor(public payload: any,  public pruefplan_id:string , public optPayload: any = null) {}
    }
export class GetPruefplanPruefobjekteAdminNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFPLAN_PRUEFOBJEKTE_ADMIN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPruefplanPruefobjekteAdminActions =
    GetPruefplanPruefobjekteAdminAction
    | GetPruefplanPruefobjekteAdminAusfuehrenAction
    | GetPruefplanPruefobjekteAdminErfolgreichAction
    | GetPruefplanPruefobjekteAdminFehlerAction
    | GetPruefplanPruefobjekteAdminNichtAusgefuehrtAction
    ;
