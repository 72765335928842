import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_ABTEILUNG_EINGANG = '[IncidentsModul] GetAbteilungEingang laden';
export const GET_ABTEILUNG_EINGANG_AUSFUEHREN = '[IncidentsModul] GetAbteilungEingang ausführen';
export const GET_ABTEILUNG_EINGANG_ERFOLGREICH = '[IncidentsModul] GetAbteilungEingang laden erfolgreich';
export const GET_ABTEILUNG_EINGANG_FEHLER = '[IncidentsModul] GetAbteilungEingang Ladefehler';
export const GET_ABTEILUNG_EINGANG_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetAbteilungEingang nicht ausgeführt (evtl. Offline)';



export class GetAbteilungEingangAction implements Action {
    readonly type = GET_ABTEILUNG_EINGANG;
        constructor( public funktionsbereich_id:string ,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetAbteilungEingangAusfuehrenAction implements Action {
    readonly type = GET_ABTEILUNG_EINGANG_AUSFUEHREN;
        constructor( public funktionsbereich_id:string ,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetAbteilungEingangErfolgreichAction implements Action {
    readonly type = GET_ABTEILUNG_EINGANG_ERFOLGREICH;
        constructor(public payload: Array<api.IncidentKopf>,  public funktionsbereich_id:string ,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetAbteilungEingangFehlerAction implements Action {
    readonly type = GET_ABTEILUNG_EINGANG_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string ,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetAbteilungEingangNichtAusgefuehrtAction implements Action {
    readonly type = GET_ABTEILUNG_EINGANG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryAbteilungEingangActions =
    GetAbteilungEingangAction
    | GetAbteilungEingangAusfuehrenAction
    | GetAbteilungEingangErfolgreichAction
    | GetAbteilungEingangFehlerAction
    | GetAbteilungEingangNichtAusgefuehrtAction
    ;
