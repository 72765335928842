import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_UPLOAD_AKTUALISIEREN = '[VeranstaltungenModul] PostDokumentUploadAktualisieren laden';
export const POST_DOKUMENT_UPLOAD_AKTUALISIEREN_AUSFUEHREN = '[VeranstaltungenModul] PostDokumentUploadAktualisieren ausführen';
export const POST_DOKUMENT_UPLOAD_AKTUALISIEREN_ERFOLGREICH = '[VeranstaltungenModul] PostDokumentUploadAktualisieren laden erfolgreich';
export const POST_DOKUMENT_UPLOAD_AKTUALISIEREN_FEHLER = '[VeranstaltungenModul] PostDokumentUploadAktualisieren Ladefehler';
export const POST_DOKUMENT_UPLOAD_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostDokumentUploadAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostDokumentUploadAktualisierenAction implements Action {
    readonly type = POST_DOKUMENT_UPLOAD_AKTUALISIEREN;
        constructor(public dokumentUploadAktualisierenRequest: api.DokumentUploadAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentUploadAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_UPLOAD_AKTUALISIEREN_AUSFUEHREN;
        constructor(public dokumentUploadAktualisierenRequest: api.DokumentUploadAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentUploadAktualisierenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_UPLOAD_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentUploadAktualisierenRequest: api.DokumentUploadAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentUploadAktualisierenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_UPLOAD_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public dokumentUploadAktualisierenRequest: api.DokumentUploadAktualisierenRequest, public optPayload: any = null) {}
    }
export class PostDokumentUploadAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_UPLOAD_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandDokumentUploadAktualisierenActions =
    PostDokumentUploadAktualisierenAction
    | PostDokumentUploadAktualisierenAusfuehrenAction
    | PostDokumentUploadAktualisierenErfolgreichAction
    | PostDokumentUploadAktualisierenFehlerAction
    | PostDokumentUploadAktualisierenNichtAusgefuehrtAction
    ;
