import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFOBJEKT_ENTFERNEN = '[QschecksModul] PostPruefobjektEntfernen laden';
export const POST_PRUEFOBJEKT_ENTFERNEN_AUSFUEHREN = '[QschecksModul] PostPruefobjektEntfernen ausführen';
export const POST_PRUEFOBJEKT_ENTFERNEN_ERFOLGREICH = '[QschecksModul] PostPruefobjektEntfernen laden erfolgreich';
export const POST_PRUEFOBJEKT_ENTFERNEN_FEHLER = '[QschecksModul] PostPruefobjektEntfernen Ladefehler';
export const POST_PRUEFOBJEKT_ENTFERNEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefobjektEntfernen nicht ausgeführt (evtl. Offline)';



export class PostPruefobjektEntfernenAction implements Action {
    readonly type = POST_PRUEFOBJEKT_ENTFERNEN;
        constructor(public pruefobjektRequest: api.PruefobjektRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektEntfernenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFOBJEKT_ENTFERNEN_AUSFUEHREN;
        constructor(public pruefobjektRequest: api.PruefobjektRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektEntfernenErfolgreichAction implements Action {
    readonly type = POST_PRUEFOBJEKT_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefobjektRequest: api.PruefobjektRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektEntfernenFehlerAction implements Action {
    readonly type = POST_PRUEFOBJEKT_ENTFERNEN_FEHLER;
        constructor(public payload: any, public pruefobjektRequest: api.PruefobjektRequest, public optPayload: any = null) {}
    }
export class PostPruefobjektEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFOBJEKT_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefobjektEntfernenActions =
    PostPruefobjektEntfernenAction
    | PostPruefobjektEntfernenAusfuehrenAction
    | PostPruefobjektEntfernenErfolgreichAction
    | PostPruefobjektEntfernenFehlerAction
    | PostPruefobjektEntfernenNichtAusgefuehrtAction
    ;
