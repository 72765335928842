

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_LOGO_SCALED = '[VeranstaltungenModul] GetVeranstaltungLogoScaled laden';
export const GET_VERANSTALTUNG_LOGO_SCALED_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungLogoScaled ausführen';
export const GET_VERANSTALTUNG_LOGO_SCALED_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungLogoScaled laden erfolgreich';
export const GET_VERANSTALTUNG_LOGO_SCALED_FEHLER = '[VeranstaltungenModul] GetVeranstaltungLogoScaled Ladefehler';
export const GET_VERANSTALTUNG_LOGO_SCALED_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungLogoScaled nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungLogoScaledAction implements Action {
    readonly type = GET_VERANSTALTUNG_LOGO_SCALED;
        constructor( public veranstaltung_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetVeranstaltungLogoScaledAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_LOGO_SCALED_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetVeranstaltungLogoScaledErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_LOGO_SCALED_ERFOLGREICH;
        constructor( public veranstaltung_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetVeranstaltungLogoScaledFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_LOGO_SCALED_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetVeranstaltungLogoScaledNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_LOGO_SCALED_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungLogoScaledActions =
    GetVeranstaltungLogoScaledAction
    | GetVeranstaltungLogoScaledAusfuehrenAction
    | GetVeranstaltungLogoScaledErfolgreichAction
    | GetVeranstaltungLogoScaledFehlerAction
    | GetVeranstaltungLogoScaledNichtAusgefuehrtAction
    ;
