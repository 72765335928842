import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENTS_CONSTANTS = '[IncidentsModul] GetIncidentsConstants laden';
export const GET_INCIDENTS_CONSTANTS_AUSFUEHREN = '[IncidentsModul] GetIncidentsConstants ausführen';
export const GET_INCIDENTS_CONSTANTS_ERFOLGREICH = '[IncidentsModul] GetIncidentsConstants laden erfolgreich';
export const GET_INCIDENTS_CONSTANTS_FEHLER = '[IncidentsModul] GetIncidentsConstants Ladefehler';
export const GET_INCIDENTS_CONSTANTS_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentsConstants nicht ausgeführt (evtl. Offline)';



export class GetIncidentsConstantsAction implements Action {
    readonly type = GET_INCIDENTS_CONSTANTS;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsConstantsAusfuehrenAction implements Action {
    readonly type = GET_INCIDENTS_CONSTANTS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetIncidentsConstantsErfolgreichAction implements Action {
    readonly type = GET_INCIDENTS_CONSTANTS_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetIncidentsConstantsFehlerAction implements Action {
    readonly type = GET_INCIDENTS_CONSTANTS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetIncidentsConstantsNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENTS_CONSTANTS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentsConstantsActions =
    GetIncidentsConstantsAction
    | GetIncidentsConstantsAusfuehrenAction
    | GetIncidentsConstantsErfolgreichAction
    | GetIncidentsConstantsFehlerAction
    | GetIncidentsConstantsNichtAusgefuehrtAction
    ;
