

import {Action} from '@ngrx/store';

export const GET_PRUEFLAUF_EXCEL = '[QschecksModul] GetPrueflaufExcel laden';
export const GET_PRUEFLAUF_EXCEL_AUSFUEHREN = '[QschecksModul] GetPrueflaufExcel ausführen';
export const GET_PRUEFLAUF_EXCEL_ERFOLGREICH = '[QschecksModul] GetPrueflaufExcel laden erfolgreich';
export const GET_PRUEFLAUF_EXCEL_FEHLER = '[QschecksModul] GetPrueflaufExcel Ladefehler';
export const GET_PRUEFLAUF_EXCEL_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPrueflaufExcel nicht ausgeführt (evtl. Offline)';



export class GetPrueflaufExcelAction implements Action {
    readonly type = GET_PRUEFLAUF_EXCEL;
        constructor( public prueflauf_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufExcelAusfuehrenAction implements Action {
    readonly type = GET_PRUEFLAUF_EXCEL_AUSFUEHREN;
        constructor( public prueflauf_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufExcelErfolgreichAction implements Action {
    readonly type = GET_PRUEFLAUF_EXCEL_ERFOLGREICH;
        constructor( public prueflauf_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufExcelFehlerAction implements Action {
    readonly type = GET_PRUEFLAUF_EXCEL_FEHLER;
        constructor(public payload: any,  public prueflauf_id:string , public optPayload: any = null) {}
    }
export class GetPrueflaufExcelNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFLAUF_EXCEL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPrueflaufExcelActions =
    GetPrueflaufExcelAction
    | GetPrueflaufExcelAusfuehrenAction
    | GetPrueflaufExcelErfolgreichAction
    | GetPrueflaufExcelFehlerAction
    | GetPrueflaufExcelNichtAusgefuehrtAction
    ;
