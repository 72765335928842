import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFPLAN_ROLLEN_CONTROLLER_HINZUFUEGEN = '[QschecksModul] PostPruefplanRollenControllerHinzufuegen laden';
export const POST_PRUEFPLAN_ROLLEN_CONTROLLER_HINZUFUEGEN_AUSFUEHREN = '[QschecksModul] PostPruefplanRollenControllerHinzufuegen ausführen';
export const POST_PRUEFPLAN_ROLLEN_CONTROLLER_HINZUFUEGEN_ERFOLGREICH = '[QschecksModul] PostPruefplanRollenControllerHinzufuegen laden erfolgreich';
export const POST_PRUEFPLAN_ROLLEN_CONTROLLER_HINZUFUEGEN_FEHLER = '[QschecksModul] PostPruefplanRollenControllerHinzufuegen Ladefehler';
export const POST_PRUEFPLAN_ROLLEN_CONTROLLER_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefplanRollenControllerHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostPruefplanRollenControllerHinzufuegenAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_CONTROLLER_HINZUFUEGEN;
        constructor(public pruefplanRolleMultiRequest: api.PruefplanRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRollenControllerHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_CONTROLLER_HINZUFUEGEN_AUSFUEHREN;
        constructor(public pruefplanRolleMultiRequest: api.PruefplanRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRollenControllerHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_CONTROLLER_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefplanRolleMultiRequest: api.PruefplanRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRollenControllerHinzufuegenFehlerAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_CONTROLLER_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public pruefplanRolleMultiRequest: api.PruefplanRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRollenControllerHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_CONTROLLER_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefplanRollenControllerHinzufuegenActions =
    PostPruefplanRollenControllerHinzufuegenAction
    | PostPruefplanRollenControllerHinzufuegenAusfuehrenAction
    | PostPruefplanRollenControllerHinzufuegenErfolgreichAction
    | PostPruefplanRollenControllerHinzufuegenFehlerAction
    | PostPruefplanRollenControllerHinzufuegenNichtAusgefuehrtAction
    ;
