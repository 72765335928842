import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_MY_NOTIFICATION_MESSAGE = '[ApiModul] GetMyNotificationMessage laden';
export const GET_MY_NOTIFICATION_MESSAGE_AUSFUEHREN = '[ApiModul] GetMyNotificationMessage ausführen';
export const GET_MY_NOTIFICATION_MESSAGE_ERFOLGREICH = '[ApiModul] GetMyNotificationMessage laden erfolgreich';
export const GET_MY_NOTIFICATION_MESSAGE_FEHLER = '[ApiModul] GetMyNotificationMessage Ladefehler';
export const GET_MY_NOTIFICATION_MESSAGE_NICHT_AUSGEFUEHRT = '[ApiModul] GetMyNotificationMessage nicht ausgeführt (evtl. Offline)';



export class GetMyNotificationMessageAction implements Action {
    readonly type = GET_MY_NOTIFICATION_MESSAGE;
        constructor(public optPayload: any = null) {}
    }
export class GetMyNotificationMessageAusfuehrenAction implements Action {
    readonly type = GET_MY_NOTIFICATION_MESSAGE_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMyNotificationMessageErfolgreichAction implements Action {
    readonly type = GET_MY_NOTIFICATION_MESSAGE_ERFOLGREICH;
        constructor(public payload: api.MyNotificationMessage, public optPayload: any = null) {}
    }
export class GetMyNotificationMessageFehlerAction implements Action {
    readonly type = GET_MY_NOTIFICATION_MESSAGE_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMyNotificationMessageNichtAusgefuehrtAction implements Action {
    readonly type = GET_MY_NOTIFICATION_MESSAGE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMyNotificationMessageActions =
    GetMyNotificationMessageAction
    | GetMyNotificationMessageAusfuehrenAction
    | GetMyNotificationMessageErfolgreichAction
    | GetMyNotificationMessageFehlerAction
    | GetMyNotificationMessageNichtAusgefuehrtAction
    ;
