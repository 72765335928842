import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN = '[VorlagenModul] PostDokumentBerechtigungEntfernen laden';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_AUSFUEHREN = '[VorlagenModul] PostDokumentBerechtigungEntfernen ausführen';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_ERFOLGREICH = '[VorlagenModul] PostDokumentBerechtigungEntfernen laden erfolgreich';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_FEHLER = '[VorlagenModul] PostDokumentBerechtigungEntfernen Ladefehler';
export const POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentBerechtigungEntfernen nicht ausgeführt (evtl. Offline)';



export class PostDokumentBerechtigungEntfernenAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN;
        constructor(public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_AUSFUEHREN;
        constructor(public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentBerechtigungEntfernenActions =
    PostDokumentBerechtigungEntfernenAction
    | PostDokumentBerechtigungEntfernenAusfuehrenAction
    | PostDokumentBerechtigungEntfernenErfolgreichAction
    | PostDokumentBerechtigungEntfernenFehlerAction
    | PostDokumentBerechtigungEntfernenNichtAusgefuehrtAction
    ;
