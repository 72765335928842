import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGS_TYPEN_ENTITY_TYPES = '[VeranstaltungstypenModul] GetVeranstaltungsTypenEntityTypes laden';
export const GET_VERANSTALTUNGS_TYPEN_ENTITY_TYPES_AUSFUEHREN = '[VeranstaltungstypenModul] GetVeranstaltungsTypenEntityTypes ausführen';
export const GET_VERANSTALTUNGS_TYPEN_ENTITY_TYPES_ERFOLGREICH = '[VeranstaltungstypenModul] GetVeranstaltungsTypenEntityTypes laden erfolgreich';
export const GET_VERANSTALTUNGS_TYPEN_ENTITY_TYPES_FEHLER = '[VeranstaltungstypenModul] GetVeranstaltungsTypenEntityTypes Ladefehler';
export const GET_VERANSTALTUNGS_TYPEN_ENTITY_TYPES_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] GetVeranstaltungsTypenEntityTypes nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungsTypenEntityTypesAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_ENTITY_TYPES;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenEntityTypesAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_ENTITY_TYPES_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenEntityTypesErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_ENTITY_TYPES_ERFOLGREICH;
        constructor(public payload: Array<api.EntityTypeInfo>, public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenEntityTypesFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_ENTITY_TYPES_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypenEntityTypesNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYPEN_ENTITY_TYPES_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenQueryVeranstaltungsTypenEntityTypesActions =
    GetVeranstaltungsTypenEntityTypesAction
    | GetVeranstaltungsTypenEntityTypesAusfuehrenAction
    | GetVeranstaltungsTypenEntityTypesErfolgreichAction
    | GetVeranstaltungsTypenEntityTypesFehlerAction
    | GetVeranstaltungsTypenEntityTypesNichtAusgefuehrtAction
    ;
