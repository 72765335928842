import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG = '[VeranstaltungenModul] GetVeranstaltungMitteilungBerechtigung laden';
export const GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungMitteilungBerechtigung ausführen';
export const GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungMitteilungBerechtigung laden erfolgreich';
export const GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_FEHLER = '[VeranstaltungenModul] GetVeranstaltungMitteilungBerechtigung Ladefehler';
export const GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungMitteilungBerechtigung nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungMitteilungBerechtigungAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungMitteilungBerechtigungAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungMitteilungBerechtigungErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungMitteilungBerechtigung,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungMitteilungBerechtigungFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungMitteilungBerechtigungNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_MITTEILUNG_BERECHTIGUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungMitteilungBerechtigungActions =
    GetVeranstaltungMitteilungBerechtigungAction
    | GetVeranstaltungMitteilungBerechtigungAusfuehrenAction
    | GetVeranstaltungMitteilungBerechtigungErfolgreichAction
    | GetVeranstaltungMitteilungBerechtigungFehlerAction
    | GetVeranstaltungMitteilungBerechtigungNichtAusgefuehrtAction
    ;
