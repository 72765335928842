import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const GET_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNG = '[DisplaysteuerungModul] GetDisplaysteuerungRolleBerechtigung laden';
export const GET_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNG_AUSFUEHREN = '[DisplaysteuerungModul] GetDisplaysteuerungRolleBerechtigung ausführen';
export const GET_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNG_ERFOLGREICH = '[DisplaysteuerungModul] GetDisplaysteuerungRolleBerechtigung laden erfolgreich';
export const GET_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNG_FEHLER = '[DisplaysteuerungModul] GetDisplaysteuerungRolleBerechtigung Ladefehler';
export const GET_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNG_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] GetDisplaysteuerungRolleBerechtigung nicht ausgeführt (evtl. Offline)';



export class GetDisplaysteuerungRolleBerechtigungAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNG;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetDisplaysteuerungRolleBerechtigungAusfuehrenAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNG_AUSFUEHREN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetDisplaysteuerungRolleBerechtigungErfolgreichAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNG_ERFOLGREICH;
        constructor(public payload: api.DisplaysteuerungRolleBerechtigung,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetDisplaysteuerungRolleBerechtigungFehlerAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNG_FEHLER;
        constructor(public payload: any,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetDisplaysteuerungRolleBerechtigungNichtAusgefuehrtAction implements Action {
    readonly type = GET_DISPLAYSTEUERUNG_ROLLE_BERECHTIGUNG_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungQueryDisplaysteuerungRolleBerechtigungActions =
    GetDisplaysteuerungRolleBerechtigungAction
    | GetDisplaysteuerungRolleBerechtigungAusfuehrenAction
    | GetDisplaysteuerungRolleBerechtigungErfolgreichAction
    | GetDisplaysteuerungRolleBerechtigungFehlerAction
    | GetDisplaysteuerungRolleBerechtigungNichtAusgefuehrtAction
    ;
