import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_FUNKTIONSBEREICH_ROLLEN = '[BenutzerverwaltungModul] GetFunktionsbereichRollen laden';
export const GET_FUNKTIONSBEREICH_ROLLEN_AUSFUEHREN = '[BenutzerverwaltungModul] GetFunktionsbereichRollen ausführen';
export const GET_FUNKTIONSBEREICH_ROLLEN_ERFOLGREICH = '[BenutzerverwaltungModul] GetFunktionsbereichRollen laden erfolgreich';
export const GET_FUNKTIONSBEREICH_ROLLEN_FEHLER = '[BenutzerverwaltungModul] GetFunktionsbereichRollen Ladefehler';
export const GET_FUNKTIONSBEREICH_ROLLEN_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetFunktionsbereichRollen nicht ausgeführt (evtl. Offline)';



export class GetFunktionsbereichRollenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ROLLEN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichRollenAusfuehrenAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ROLLEN_AUSFUEHREN;
        constructor( public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichRollenErfolgreichAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ROLLEN_ERFOLGREICH;
        constructor(public payload: Array<api.Rolle>,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichRollenFehlerAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ROLLEN_FEHLER;
        constructor(public payload: any,  public funktionsbereich_id:string , public optPayload: any = null) {}
    }
export class GetFunktionsbereichRollenNichtAusgefuehrtAction implements Action {
    readonly type = GET_FUNKTIONSBEREICH_ROLLEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryFunktionsbereichRollenActions =
    GetFunktionsbereichRollenAction
    | GetFunktionsbereichRollenAusfuehrenAction
    | GetFunktionsbereichRollenErfolgreichAction
    | GetFunktionsbereichRollenFehlerAction
    | GetFunktionsbereichRollenNichtAusgefuehrtAction
    ;
