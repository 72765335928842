import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_MELDEART_IDENTIFIER = '[VeranstaltungenModul] GetMeldeartIdentifier laden';
export const GET_MELDEART_IDENTIFIER_AUSFUEHREN = '[VeranstaltungenModul] GetMeldeartIdentifier ausführen';
export const GET_MELDEART_IDENTIFIER_ERFOLGREICH = '[VeranstaltungenModul] GetMeldeartIdentifier laden erfolgreich';
export const GET_MELDEART_IDENTIFIER_FEHLER = '[VeranstaltungenModul] GetMeldeartIdentifier Ladefehler';
export const GET_MELDEART_IDENTIFIER_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetMeldeartIdentifier nicht ausgeführt (evtl. Offline)';



export class GetMeldeartIdentifierAction implements Action {
    readonly type = GET_MELDEART_IDENTIFIER;
        constructor(public optPayload: any = null) {}
    }
export class GetMeldeartIdentifierAusfuehrenAction implements Action {
    readonly type = GET_MELDEART_IDENTIFIER_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMeldeartIdentifierErfolgreichAction implements Action {
    readonly type = GET_MELDEART_IDENTIFIER_ERFOLGREICH;
        constructor(public payload: api.MeldeartIdentifier, public optPayload: any = null) {}
    }
export class GetMeldeartIdentifierFehlerAction implements Action {
    readonly type = GET_MELDEART_IDENTIFIER_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMeldeartIdentifierNichtAusgefuehrtAction implements Action {
    readonly type = GET_MELDEART_IDENTIFIER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryMeldeartIdentifierActions =
    GetMeldeartIdentifierAction
    | GetMeldeartIdentifierAusfuehrenAction
    | GetMeldeartIdentifierErfolgreichAction
    | GetMeldeartIdentifierFehlerAction
    | GetMeldeartIdentifierNichtAusgefuehrtAction
    ;
