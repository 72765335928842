import * as api from '../../../clients/benutzerverwaltung'; 

import {Action} from '@ngrx/store';

export const GET_ROLLE_BENUTZER = '[BenutzerverwaltungModul] GetRolleBenutzer laden';
export const GET_ROLLE_BENUTZER_AUSFUEHREN = '[BenutzerverwaltungModul] GetRolleBenutzer ausführen';
export const GET_ROLLE_BENUTZER_ERFOLGREICH = '[BenutzerverwaltungModul] GetRolleBenutzer laden erfolgreich';
export const GET_ROLLE_BENUTZER_FEHLER = '[BenutzerverwaltungModul] GetRolleBenutzer Ladefehler';
export const GET_ROLLE_BENUTZER_NICHT_AUSGEFUEHRT = '[BenutzerverwaltungModul] GetRolleBenutzer nicht ausgeführt (evtl. Offline)';



export class GetRolleBenutzerAction implements Action {
    readonly type = GET_ROLLE_BENUTZER;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleBenutzerAusfuehrenAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_AUSFUEHREN;
        constructor( public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleBenutzerErfolgreichAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_ERFOLGREICH;
        constructor(public payload: Array<api.RolleBenutzer>,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleBenutzerFehlerAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_FEHLER;
        constructor(public payload: any,  public rolle_id:string , public optPayload: any = null) {}
    }
export class GetRolleBenutzerNichtAusgefuehrtAction implements Action {
    readonly type = GET_ROLLE_BENUTZER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type BenutzerverwaltungQueryRolleBenutzerActions =
    GetRolleBenutzerAction
    | GetRolleBenutzerAusfuehrenAction
    | GetRolleBenutzerErfolgreichAction
    | GetRolleBenutzerFehlerAction
    | GetRolleBenutzerNichtAusgefuehrtAction
    ;
