import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_PRUEFPLAN_ROLLEN_LISTE = '[QschecksModul] GetVeranstaltungPruefplanRollenListe laden';
export const GET_VERANSTALTUNG_PRUEFPLAN_ROLLEN_LISTE_AUSFUEHREN = '[QschecksModul] GetVeranstaltungPruefplanRollenListe ausführen';
export const GET_VERANSTALTUNG_PRUEFPLAN_ROLLEN_LISTE_ERFOLGREICH = '[QschecksModul] GetVeranstaltungPruefplanRollenListe laden erfolgreich';
export const GET_VERANSTALTUNG_PRUEFPLAN_ROLLEN_LISTE_FEHLER = '[QschecksModul] GetVeranstaltungPruefplanRollenListe Ladefehler';
export const GET_VERANSTALTUNG_PRUEFPLAN_ROLLEN_LISTE_NICHT_AUSGEFUEHRT = '[QschecksModul] GetVeranstaltungPruefplanRollenListe nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungPruefplanRollenListeAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFPLAN_ROLLEN_LISTE;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefplanRollenListeAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFPLAN_ROLLEN_LISTE_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefplanRollenListeErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFPLAN_ROLLEN_LISTE_ERFOLGREICH;
        constructor(public payload: api.VeranstaltungPruefplanRollenListe,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefplanRollenListeFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFPLAN_ROLLEN_LISTE_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungPruefplanRollenListeNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_PRUEFPLAN_ROLLEN_LISTE_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryVeranstaltungPruefplanRollenListeActions =
    GetVeranstaltungPruefplanRollenListeAction
    | GetVeranstaltungPruefplanRollenListeAusfuehrenAction
    | GetVeranstaltungPruefplanRollenListeErfolgreichAction
    | GetVeranstaltungPruefplanRollenListeFehlerAction
    | GetVeranstaltungPruefplanRollenListeNichtAusgefuehrtAction
    ;
