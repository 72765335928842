import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const POST_ROLLE_MITTEILUNG_OPTIONEN_FESTLEGEN = '[KommunikationModul] PostRolleMitteilungOptionenFestlegen laden';
export const POST_ROLLE_MITTEILUNG_OPTIONEN_FESTLEGEN_AUSFUEHREN = '[KommunikationModul] PostRolleMitteilungOptionenFestlegen ausführen';
export const POST_ROLLE_MITTEILUNG_OPTIONEN_FESTLEGEN_ERFOLGREICH = '[KommunikationModul] PostRolleMitteilungOptionenFestlegen laden erfolgreich';
export const POST_ROLLE_MITTEILUNG_OPTIONEN_FESTLEGEN_FEHLER = '[KommunikationModul] PostRolleMitteilungOptionenFestlegen Ladefehler';
export const POST_ROLLE_MITTEILUNG_OPTIONEN_FESTLEGEN_NICHT_AUSGEFUEHRT = '[KommunikationModul] PostRolleMitteilungOptionenFestlegen nicht ausgeführt (evtl. Offline)';



export class PostRolleMitteilungOptionenFestlegenAction implements Action {
    readonly type = POST_ROLLE_MITTEILUNG_OPTIONEN_FESTLEGEN;
        constructor(public kommunikationRolleMitteilungOptionenRequest: api.KommunikationRolleMitteilungOptionenRequest, public optPayload: any = null) {}
    }
export class PostRolleMitteilungOptionenFestlegenAusfuehrenAction implements Action {
    readonly type = POST_ROLLE_MITTEILUNG_OPTIONEN_FESTLEGEN_AUSFUEHREN;
        constructor(public kommunikationRolleMitteilungOptionenRequest: api.KommunikationRolleMitteilungOptionenRequest, public optPayload: any = null) {}
    }
export class PostRolleMitteilungOptionenFestlegenErfolgreichAction implements Action {
    readonly type = POST_ROLLE_MITTEILUNG_OPTIONEN_FESTLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public kommunikationRolleMitteilungOptionenRequest: api.KommunikationRolleMitteilungOptionenRequest, public optPayload: any = null) {}
    }
export class PostRolleMitteilungOptionenFestlegenFehlerAction implements Action {
    readonly type = POST_ROLLE_MITTEILUNG_OPTIONEN_FESTLEGEN_FEHLER;
        constructor(public payload: any, public kommunikationRolleMitteilungOptionenRequest: api.KommunikationRolleMitteilungOptionenRequest, public optPayload: any = null) {}
    }
export class PostRolleMitteilungOptionenFestlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_ROLLE_MITTEILUNG_OPTIONEN_FESTLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationCommandRolleMitteilungOptionenFestlegenActions =
    PostRolleMitteilungOptionenFestlegenAction
    | PostRolleMitteilungOptionenFestlegenAusfuehrenAction
    | PostRolleMitteilungOptionenFestlegenErfolgreichAction
    | PostRolleMitteilungOptionenFestlegenFehlerAction
    | PostRolleMitteilungOptionenFestlegenNichtAusgefuehrtAction
    ;
