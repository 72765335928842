import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const GET_INCIDENT_BY_FUNKPROTOKOLL_FALL = '[IncidentsModul] GetIncidentByFunkprotokollFall laden';
export const GET_INCIDENT_BY_FUNKPROTOKOLL_FALL_AUSFUEHREN = '[IncidentsModul] GetIncidentByFunkprotokollFall ausführen';
export const GET_INCIDENT_BY_FUNKPROTOKOLL_FALL_ERFOLGREICH = '[IncidentsModul] GetIncidentByFunkprotokollFall laden erfolgreich';
export const GET_INCIDENT_BY_FUNKPROTOKOLL_FALL_FEHLER = '[IncidentsModul] GetIncidentByFunkprotokollFall Ladefehler';
export const GET_INCIDENT_BY_FUNKPROTOKOLL_FALL_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentByFunkprotokollFall nicht ausgeführt (evtl. Offline)';



export class GetIncidentByFunkprotokollFallAction implements Action {
    readonly type = GET_INCIDENT_BY_FUNKPROTOKOLL_FALL;
        constructor( public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetIncidentByFunkprotokollFallAusfuehrenAction implements Action {
    readonly type = GET_INCIDENT_BY_FUNKPROTOKOLL_FALL_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetIncidentByFunkprotokollFallErfolgreichAction implements Action {
    readonly type = GET_INCIDENT_BY_FUNKPROTOKOLL_FALL_ERFOLGREICH;
        constructor(public payload: api.IncidentKopf,  public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetIncidentByFunkprotokollFallFehlerAction implements Action {
    readonly type = GET_INCIDENT_BY_FUNKPROTOKOLL_FALL_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public fall_id:string , public optPayload: any = null) {}
    }
export class GetIncidentByFunkprotokollFallNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENT_BY_FUNKPROTOKOLL_FALL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentByFunkprotokollFallActions =
    GetIncidentByFunkprotokollFallAction
    | GetIncidentByFunkprotokollFallAusfuehrenAction
    | GetIncidentByFunkprotokollFallErfolgreichAction
    | GetIncidentByFunkprotokollFallFehlerAction
    | GetIncidentByFunkprotokollFallNichtAusgefuehrtAction
    ;
