import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_VERANSTALTUNG_BEOBACHTER_FESTLEGEN = '[VeranstaltungenModul] PostVeranstaltungBeobachterFestlegen laden';
export const POST_VERANSTALTUNG_BEOBACHTER_FESTLEGEN_AUSFUEHREN = '[VeranstaltungenModul] PostVeranstaltungBeobachterFestlegen ausführen';
export const POST_VERANSTALTUNG_BEOBACHTER_FESTLEGEN_ERFOLGREICH = '[VeranstaltungenModul] PostVeranstaltungBeobachterFestlegen laden erfolgreich';
export const POST_VERANSTALTUNG_BEOBACHTER_FESTLEGEN_FEHLER = '[VeranstaltungenModul] PostVeranstaltungBeobachterFestlegen Ladefehler';
export const POST_VERANSTALTUNG_BEOBACHTER_FESTLEGEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostVeranstaltungBeobachterFestlegen nicht ausgeführt (evtl. Offline)';



export class PostVeranstaltungBeobachterFestlegenAction implements Action {
    readonly type = POST_VERANSTALTUNG_BEOBACHTER_FESTLEGEN;
        constructor(public veranstaltungRolleRequest: api.VeranstaltungRolleRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungBeobachterFestlegenAusfuehrenAction implements Action {
    readonly type = POST_VERANSTALTUNG_BEOBACHTER_FESTLEGEN_AUSFUEHREN;
        constructor(public veranstaltungRolleRequest: api.VeranstaltungRolleRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungBeobachterFestlegenErfolgreichAction implements Action {
    readonly type = POST_VERANSTALTUNG_BEOBACHTER_FESTLEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public veranstaltungRolleRequest: api.VeranstaltungRolleRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungBeobachterFestlegenFehlerAction implements Action {
    readonly type = POST_VERANSTALTUNG_BEOBACHTER_FESTLEGEN_FEHLER;
        constructor(public payload: any, public veranstaltungRolleRequest: api.VeranstaltungRolleRequest, public optPayload: any = null) {}
    }
export class PostVeranstaltungBeobachterFestlegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_VERANSTALTUNG_BEOBACHTER_FESTLEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandVeranstaltungBeobachterFestlegenActions =
    PostVeranstaltungBeobachterFestlegenAction
    | PostVeranstaltungBeobachterFestlegenAusfuehrenAction
    | PostVeranstaltungBeobachterFestlegenErfolgreichAction
    | PostVeranstaltungBeobachterFestlegenFehlerAction
    | PostVeranstaltungBeobachterFestlegenNichtAusgefuehrtAction
    ;
