import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_TITEL_UND_BESCHREIBUNG_SETZEN = '[IncidentsModul] PostIncidentTitelUndBeschreibungSetzen laden';
export const POST_INCIDENT_TITEL_UND_BESCHREIBUNG_SETZEN_AUSFUEHREN = '[IncidentsModul] PostIncidentTitelUndBeschreibungSetzen ausführen';
export const POST_INCIDENT_TITEL_UND_BESCHREIBUNG_SETZEN_ERFOLGREICH = '[IncidentsModul] PostIncidentTitelUndBeschreibungSetzen laden erfolgreich';
export const POST_INCIDENT_TITEL_UND_BESCHREIBUNG_SETZEN_FEHLER = '[IncidentsModul] PostIncidentTitelUndBeschreibungSetzen Ladefehler';
export const POST_INCIDENT_TITEL_UND_BESCHREIBUNG_SETZEN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentTitelUndBeschreibungSetzen nicht ausgeführt (evtl. Offline)';



export class PostIncidentTitelUndBeschreibungSetzenAction implements Action {
    readonly type = POST_INCIDENT_TITEL_UND_BESCHREIBUNG_SETZEN;
        constructor(public incidentBeschreibungRequest: api.IncidentBeschreibungRequest, public optPayload: any = null) {}
    }
export class PostIncidentTitelUndBeschreibungSetzenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_TITEL_UND_BESCHREIBUNG_SETZEN_AUSFUEHREN;
        constructor(public incidentBeschreibungRequest: api.IncidentBeschreibungRequest, public optPayload: any = null) {}
    }
export class PostIncidentTitelUndBeschreibungSetzenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_TITEL_UND_BESCHREIBUNG_SETZEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentBeschreibungRequest: api.IncidentBeschreibungRequest, public optPayload: any = null) {}
    }
export class PostIncidentTitelUndBeschreibungSetzenFehlerAction implements Action {
    readonly type = POST_INCIDENT_TITEL_UND_BESCHREIBUNG_SETZEN_FEHLER;
        constructor(public payload: any, public incidentBeschreibungRequest: api.IncidentBeschreibungRequest, public optPayload: any = null) {}
    }
export class PostIncidentTitelUndBeschreibungSetzenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_TITEL_UND_BESCHREIBUNG_SETZEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentTitelUndBeschreibungSetzenActions =
    PostIncidentTitelUndBeschreibungSetzenAction
    | PostIncidentTitelUndBeschreibungSetzenAusfuehrenAction
    | PostIncidentTitelUndBeschreibungSetzenErfolgreichAction
    | PostIncidentTitelUndBeschreibungSetzenFehlerAction
    | PostIncidentTitelUndBeschreibungSetzenNichtAusgefuehrtAction
    ;
