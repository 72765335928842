import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFAUFGABE_KOPIEREN = '[QschecksModul] PostPruefaufgabeKopieren laden';
export const POST_PRUEFAUFGABE_KOPIEREN_AUSFUEHREN = '[QschecksModul] PostPruefaufgabeKopieren ausführen';
export const POST_PRUEFAUFGABE_KOPIEREN_ERFOLGREICH = '[QschecksModul] PostPruefaufgabeKopieren laden erfolgreich';
export const POST_PRUEFAUFGABE_KOPIEREN_FEHLER = '[QschecksModul] PostPruefaufgabeKopieren Ladefehler';
export const POST_PRUEFAUFGABE_KOPIEREN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefaufgabeKopieren nicht ausgeführt (evtl. Offline)';



export class PostPruefaufgabeKopierenAction implements Action {
    readonly type = POST_PRUEFAUFGABE_KOPIEREN;
        constructor(public pruefaufgabeRequest: api.PruefaufgabeRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeKopierenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFAUFGABE_KOPIEREN_AUSFUEHREN;
        constructor(public pruefaufgabeRequest: api.PruefaufgabeRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeKopierenErfolgreichAction implements Action {
    readonly type = POST_PRUEFAUFGABE_KOPIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefaufgabeRequest: api.PruefaufgabeRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeKopierenFehlerAction implements Action {
    readonly type = POST_PRUEFAUFGABE_KOPIEREN_FEHLER;
        constructor(public payload: any, public pruefaufgabeRequest: api.PruefaufgabeRequest, public optPayload: any = null) {}
    }
export class PostPruefaufgabeKopierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFAUFGABE_KOPIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefaufgabeKopierenActions =
    PostPruefaufgabeKopierenAction
    | PostPruefaufgabeKopierenAusfuehrenAction
    | PostPruefaufgabeKopierenErfolgreichAction
    | PostPruefaufgabeKopierenFehlerAction
    | PostPruefaufgabeKopierenNichtAusgefuehrtAction
    ;
