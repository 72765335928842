import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ModuleState, PageState} from './veranstaltungstyp-auswahl.reducer';
import {ListInputItem} from '../../shared/sopi-list/sopi-list.model';
import {VeranstaltungsTyp} from '../../../clients/veranstaltungen';

export const getModuleState = createFeatureSelector<ModuleState>('VeranstaltungstypAuswahlPage');

export const getPageState = createSelector(getModuleState, (state: ModuleState) => state.page);

export const getNichtAnzuzeigendeTypen = createSelector(getPageState, (state: PageState) => state.nichtAnzuzeigendeTypen);

export const getVeranstaltungTypen = createSelector(getPageState, getNichtAnzuzeigendeTypen,
        (state: PageState, nichtAnzuzeigendeTypen: Array<string>) => {
            const res: Array<ListInputItem> = [];
            state.veranstaltungsTypen.forEach((typ) => {
                res.push(<ListInputItem>{
                    groupId: '',
                    groupTitle: '',
                    itemId: typ.veranstaltungstyp_id,
                    itemTitle: typ.bezeichnung,
                    item: typ,
                });
            });

            const filteredRes = res.filter(item => {
                const nichtAnzuzeigendeTypenId = nichtAnzuzeigendeTypen.filter(i => {
                    return i === item.itemId;
                });
                return nichtAnzuzeigendeTypenId.length === 0;
            });

            return filteredRes;
        }
);


export const getVeranstaltungsTypenLaden = createSelector(getPageState, (state: PageState) => state.veranstaltungsTypenLaden);

export const getVeranstaltungsTypenFehler = createSelector(getPageState, (state: PageState) => state.veranstaltungsTypenFehler);

export const getSelektierteTypen = createSelector(getPageState, (state: PageState) => state.selektierteTypen);

export const getSelektierteTypenItems = createSelector(getPageState, (state: PageState): Array<VeranstaltungsTyp> => {
        const ret: Array<VeranstaltungsTyp> = [];
        state.veranstaltungsTypen.forEach(typ => {
            if (state.selektierteTypen.findIndex(x => x === typ.veranstaltungstyp_id) > -1) {
                ret.push(typ);
            }
        });
        return ret;
    }
);

export const getOneSelected = createSelector(getSelektierteTypen, (selektierteTypen: Array<string>): boolean => {
        return selektierteTypen.length > 0;
    }
);
