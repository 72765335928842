import {Injectable, Injector} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, flatMap, map} from 'rxjs/operators';

import * as ac from './actions.query.kommunikation-benutzer-zugriff';
import * as api from '../../../clients/kommunikation';


    @Injectable()
    export class KommunikationKommunikationBenutzerZugriffEffects {

        @Effect()
            KommunikationBenutzerZugriff$ = this.actions$.pipe(
                ofType(ac.GET_KOMMUNIKATION_BENUTZER_ZUGRIFF),
                flatMap((x: ac.GetKommunikationBenutzerZugriffAction) => {
                const optPayload = (x !== undefined && x !== null && x.optPayload !== undefined) ? x.optPayload : null;
                return this.getService().getKommunikationBenutzerZugriff().pipe(
                    map((result: any) => {
                        const nextAction = new ac.GetKommunikationBenutzerZugriffErfolgreichAction(result,  optPayload);
                        return nextAction;
                    }),
                    catchError((error: any) => {
                        const nextAction = new ac.GetKommunikationBenutzerZugriffFehlerAction(error,  optPayload);
                        return of(nextAction);
                    })
                );
            })
        );

        private service: api.KommunikationQueryService = null;

        constructor(
            private actions$: Actions,
            private injector: Injector) {
        }

        private getService(): api.KommunikationQueryService {
                if (this.service === null) {
                    this.service = this.injector.get(api.KommunikationQueryService);
                }
                return this.service;
            }
        
}
