import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_AUFGABE_AKTUALISIEREN = '[VorlagenModul] PostAufgabeAktualisieren laden';
export const POST_AUFGABE_AKTUALISIEREN_AUSFUEHREN = '[VorlagenModul] PostAufgabeAktualisieren ausführen';
export const POST_AUFGABE_AKTUALISIEREN_ERFOLGREICH = '[VorlagenModul] PostAufgabeAktualisieren laden erfolgreich';
export const POST_AUFGABE_AKTUALISIEREN_FEHLER = '[VorlagenModul] PostAufgabeAktualisieren Ladefehler';
export const POST_AUFGABE_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostAufgabeAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostAufgabeAktualisierenAction implements Action {
    readonly type = POST_AUFGABE_AKTUALISIEREN;
        constructor(public aufgabeModelRequest: api.AufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostAufgabeAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_AUFGABE_AKTUALISIEREN_AUSFUEHREN;
        constructor(public aufgabeModelRequest: api.AufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostAufgabeAktualisierenErfolgreichAction implements Action {
    readonly type = POST_AUFGABE_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public aufgabeModelRequest: api.AufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostAufgabeAktualisierenFehlerAction implements Action {
    readonly type = POST_AUFGABE_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public aufgabeModelRequest: api.AufgabeModelRequest, public optPayload: any = null) {}
    }
export class PostAufgabeAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_AUFGABE_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandAufgabeAktualisierenActions =
    PostAufgabeAktualisierenAction
    | PostAufgabeAktualisierenAusfuehrenAction
    | PostAufgabeAktualisierenErfolgreichAction
    | PostAufgabeAktualisierenFehlerAction
    | PostAufgabeAktualisierenNichtAusgefuehrtAction
    ;
