

import {Action} from '@ngrx/store';

export const GET_INCIDENT_BILD = '[IncidentsModul] GetIncidentBild laden';
export const GET_INCIDENT_BILD_AUSFUEHREN = '[IncidentsModul] GetIncidentBild ausführen';
export const GET_INCIDENT_BILD_ERFOLGREICH = '[IncidentsModul] GetIncidentBild laden erfolgreich';
export const GET_INCIDENT_BILD_FEHLER = '[IncidentsModul] GetIncidentBild Ladefehler';
export const GET_INCIDENT_BILD_NICHT_AUSGEFUEHRT = '[IncidentsModul] GetIncidentBild nicht ausgeführt (evtl. Offline)';



export class GetIncidentBildAction implements Action {
    readonly type = GET_INCIDENT_BILD;
        constructor( public incident_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetIncidentBildAusfuehrenAction implements Action {
    readonly type = GET_INCIDENT_BILD_AUSFUEHREN;
        constructor( public incident_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetIncidentBildErfolgreichAction implements Action {
    readonly type = GET_INCIDENT_BILD_ERFOLGREICH;
        constructor( public incident_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetIncidentBildFehlerAction implements Action {
    readonly type = GET_INCIDENT_BILD_FEHLER;
        constructor(public payload: any,  public incident_id:string ,  public bild_id:string , public optPayload: any = null) {}
    }
export class GetIncidentBildNichtAusgefuehrtAction implements Action {
    readonly type = GET_INCIDENT_BILD_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsQueryIncidentBildActions =
    GetIncidentBildAction
    | GetIncidentBildAusfuehrenAction
    | GetIncidentBildErfolgreichAction
    | GetIncidentBildFehlerAction
    | GetIncidentBildNichtAusgefuehrtAction
    ;
