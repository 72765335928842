import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'sopi-image-icon',
    templateUrl: './sopi-image-icon.component.html',
    styleUrls: ['./sopi-image-icon.component.scss'],
})
export class SopiImageIconComponent implements OnInit {

    // VALUES
    @Input() mainValue: string = null;
    @Input() size: string = null;
    @Input() isErrorImage = false;
    @Input() iconHasImage = false;
    @Input() iconHasImages = false;

    constructor() {
    }

    ngOnInit() {
    }

}
