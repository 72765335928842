import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const GET_VORLAGEN_MANDANT_OPTIONS = '[VorlagenModul] GetVorlagenMandantOptions laden';
export const GET_VORLAGEN_MANDANT_OPTIONS_AUSFUEHREN = '[VorlagenModul] GetVorlagenMandantOptions ausführen';
export const GET_VORLAGEN_MANDANT_OPTIONS_ERFOLGREICH = '[VorlagenModul] GetVorlagenMandantOptions laden erfolgreich';
export const GET_VORLAGEN_MANDANT_OPTIONS_FEHLER = '[VorlagenModul] GetVorlagenMandantOptions Ladefehler';
export const GET_VORLAGEN_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT = '[VorlagenModul] GetVorlagenMandantOptions nicht ausgeführt (evtl. Offline)';



export class GetVorlagenMandantOptionsAction implements Action {
    readonly type = GET_VORLAGEN_MANDANT_OPTIONS;
        constructor(public optPayload: any = null) {}
    }
export class GetVorlagenMandantOptionsAusfuehrenAction implements Action {
    readonly type = GET_VORLAGEN_MANDANT_OPTIONS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVorlagenMandantOptionsErfolgreichAction implements Action {
    readonly type = GET_VORLAGEN_MANDANT_OPTIONS_ERFOLGREICH;
        constructor(public payload: api.VorlagenMandantOptions, public optPayload: any = null) {}
    }
export class GetVorlagenMandantOptionsFehlerAction implements Action {
    readonly type = GET_VORLAGEN_MANDANT_OPTIONS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVorlagenMandantOptionsNichtAusgefuehrtAction implements Action {
    readonly type = GET_VORLAGEN_MANDANT_OPTIONS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenQueryVorlagenMandantOptionsActions =
    GetVorlagenMandantOptionsAction
    | GetVorlagenMandantOptionsAusfuehrenAction
    | GetVorlagenMandantOptionsErfolgreichAction
    | GetVorlagenMandantOptionsFehlerAction
    | GetVorlagenMandantOptionsNichtAusgefuehrtAction
    ;
