import {Action, ActionReducerMap} from '@ngrx/store';

import * as actionsUi from './rollenselektion.actions.ui';
import {SelektierteRollenAction} from './rollenselektion.actions.ui';
import {
    GET_FUNKTIONSBEREICHE_ROLLEN_ALLE,
    GET_FUNKTIONSBEREICHE_ROLLEN_ALLE_ERFOLGREICH,
    GET_FUNKTIONSBEREICHE_ROLLEN_ALLE_FEHLER,
    GetFunktionsbereicheRollenAlleErfolgreichAction,
} from '../../../client-ngrx/benutzerverwaltung';
import {Datenquelle} from './rollenselektion.model';
import {
    GET_FUNKTIONSBEREICHE_ROLLEN,
    GET_FUNKTIONSBEREICHE_ROLLEN_ERFOLGREICH,
    GET_FUNKTIONSBEREICHE_ROLLEN_FEHLER,
    GetFunktionsbereicheRollenErfolgreichAction
} from '../../../client-ngrx/vorlagen';
import {Checkliste} from '../../../clients/vorlagen';
import {Funktionsbereich, FunktionsbereichRollen} from '../../../clients/benutzerverwaltung';
import {
    GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE,
    GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE_ERFOLGREICH,
    GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE_FEHLER,
    GetVeranstaltungFunktionsbereicheRollenAdminAlleErfolgreichAction
} from '../../../client-ngrx/veranstaltungen';

import * as actionsDisplaysteuerung from '../../../client-ngrx/displaysteuerung';
import * as actionsQsChecks from '../../../client-ngrx/qschecks';

export interface ModuleState {
    page: PageState;
}

export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer
};

export interface PageState {
    funktionsbereicheRollen: Array<FunktionsbereichRollen>;
    nichtAnzuzeigendeRollen: Array<string>;
    aktuelleCheckliste: Checkliste;
    aktuellerFunktionsbereich: Funktionsbereich;
    uiRollenLadeanimation: boolean;
    uiBenutzerLadeFehler: boolean;
    uiAlleAnzeigen: boolean;
    uiDatenquelle: Datenquelle;
    uiVeranstaltungsId: string;
    uiSelektierteRollen: Array<string>;
}

export const initialPageState: PageState = {
    funktionsbereicheRollen: [],
    nichtAnzuzeigendeRollen: [],
    aktuelleCheckliste: null,
    aktuellerFunktionsbereich: null,
    uiRollenLadeanimation: false,
    uiBenutzerLadeFehler: false,
    uiAlleAnzeigen: false,
    uiDatenquelle: Datenquelle.Benutzerverwaltung,
    uiVeranstaltungsId: null,
    uiSelektierteRollen: [],
};

export function pageReducer(state = initialPageState, action: Action): PageState {
    switch (action.type) {
        // Page State Reset
        case actionsUi.PAGE_STATE_RESET: {
            const a = <actionsUi.PageStateResetAction>action;

            const nichtAnzuzeigendeRollen = (a.nichtAnzuzeigendeRollenIds == null) ? [] : a.nichtAnzuzeigendeRollenIds;

            return Object.assign({}, state, <PageState>initialPageState, <PageState>{
                uiDatenquelle: a.datenquelle,
                nichtAnzuzeigendeRollen,
                aktuelleCheckliste: a.checkliste,
                aktuellerFunktionsbereich: a.funktionsbereich,
                uiAlleAnzeigen: a.alleAnzeigen,
                uiVeranstaltungsId: a.veranstaltungsId
            });
        }

        // Rollen aus Benutzerverwaltung
        case GET_FUNKTIONSBEREICHE_ROLLEN_ALLE_ERFOLGREICH: {
            const a = <GetFunktionsbereicheRollenAlleErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                funktionsbereicheRollen: a.payload,
                uiRollenLadeanimation: false
            });
        }

        case GET_FUNKTIONSBEREICHE_ROLLEN_ALLE: {
            return Object.assign({}, state, <PageState>{uiRollenLadeanimation: true});
        }

        case GET_FUNKTIONSBEREICHE_ROLLEN_ALLE_FEHLER: {
            return Object.assign({}, state, <PageState>{
                funktionsbereicheRollen: [],
                uiRollenLadeanimation: false,
                uiBenutzerLadeFehler: true
            });
        }

        // Rollen aus Vorlagen
        case GET_FUNKTIONSBEREICHE_ROLLEN_ERFOLGREICH: {
            const a = <GetFunktionsbereicheRollenErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                funktionsbereicheRollen: a.payload,
                uiRollenLadeanimation: false
            });
        }

        case GET_FUNKTIONSBEREICHE_ROLLEN: {
            return Object.assign({}, state, <PageState>{uiRollenLadeanimation: true});
        }

        case GET_FUNKTIONSBEREICHE_ROLLEN_FEHLER: {
            return Object.assign({}, state, <PageState>{
                funktionsbereicheRollen: [],
                uiRollenLadeanimation: false,
                uiBenutzerLadeFehler: true
            });
        }

        // Rollen aus Veranstaltung
        case GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE_ERFOLGREICH: {
            const a = <GetVeranstaltungFunktionsbereicheRollenAdminAlleErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                funktionsbereicheRollen: a.payload,
                uiRollenLadeanimation: false
            });
        }

        case GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE: {
            return Object.assign({}, state, <PageState>{uiRollenLadeanimation: true});
        }

        case GET_VERANSTALTUNG_FUNKTIONSBEREICHE_ROLLEN_ADMIN_ALLE_FEHLER: {
            return Object.assign({}, state, <PageState>{
                funktionsbereicheRollen: [],
                uiRollenLadeanimation: false,
                uiBenutzerLadeFehler: true
            });
        }

        // Rollen aus Displaysteuerung
        case actionsDisplaysteuerung.GET_FUNKTIONSBEREICHE_ROLLEN_ERFOLGREICH: {
            const a = <actionsDisplaysteuerung.GetFunktionsbereicheRollenErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                funktionsbereicheRollen: a.payload,
                uiRollenLadeanimation: false
            });
        }

        case actionsDisplaysteuerung.GET_FUNKTIONSBEREICHE_ROLLEN: {
            return Object.assign({}, state, <PageState>{uiRollenLadeanimation: true});
        }

        case actionsDisplaysteuerung.GET_FUNKTIONSBEREICHE_ROLLEN_FEHLER: {
            return Object.assign({}, state, <PageState>{
                funktionsbereicheRollen: [],
                uiRollenLadeanimation: false,
                uiBenutzerLadeFehler: true
            });
        }

        // Rollen aus Displaysteuerung
        case actionsQsChecks.GET_FUNKTIONSBEREICHE_ROLLEN_ERFOLGREICH: {
            const a = <actionsQsChecks.GetFunktionsbereicheRollenErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                funktionsbereicheRollen: a.payload,
                uiRollenLadeanimation: false
            });
        }

        case actionsQsChecks.GET_FUNKTIONSBEREICHE_ROLLEN: {
            return Object.assign({}, state, <PageState>{uiRollenLadeanimation: true});
        }

        case actionsQsChecks.GET_FUNKTIONSBEREICHE_ROLLEN_FEHLER: {
            return Object.assign({}, state, <PageState>{
                funktionsbereicheRollen: [],
                uiRollenLadeanimation: false,
                uiBenutzerLadeFehler: true
            });
        }

        case actionsUi.SELEKTIERTE_ROLLEN: {
            const a = <SelektierteRollenAction>action;
            return Object.assign({}, state, <PageState>{uiSelektierteRollen: a.payload});
        }

        case actionsUi.ALLE_ANZEIGEN_SETZEN: {
            const a = <actionsUi.AlleAnzeigenSetzenAction>action;

            return Object.assign({}, state, <PageState>{
                uiAlleAnzeigen: a.payload,
            });
        }

        default: {
            return state;
        }
    }
}

