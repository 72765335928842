import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_MELDUNG_AKTUALISIEREN = '[IncidentsModul] PostIncidentMeldungAktualisieren laden';
export const POST_INCIDENT_MELDUNG_AKTUALISIEREN_AUSFUEHREN = '[IncidentsModul] PostIncidentMeldungAktualisieren ausführen';
export const POST_INCIDENT_MELDUNG_AKTUALISIEREN_ERFOLGREICH = '[IncidentsModul] PostIncidentMeldungAktualisieren laden erfolgreich';
export const POST_INCIDENT_MELDUNG_AKTUALISIEREN_FEHLER = '[IncidentsModul] PostIncidentMeldungAktualisieren Ladefehler';
export const POST_INCIDENT_MELDUNG_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentMeldungAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostIncidentMeldungAktualisierenAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_AKTUALISIEREN;
        constructor(public incidentMeldungRequest: api.IncidentMeldungRequest, public optPayload: any = null) {}
    }
export class PostIncidentMeldungAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_AKTUALISIEREN_AUSFUEHREN;
        constructor(public incidentMeldungRequest: api.IncidentMeldungRequest, public optPayload: any = null) {}
    }
export class PostIncidentMeldungAktualisierenErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentMeldungRequest: api.IncidentMeldungRequest, public optPayload: any = null) {}
    }
export class PostIncidentMeldungAktualisierenFehlerAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public incidentMeldungRequest: api.IncidentMeldungRequest, public optPayload: any = null) {}
    }
export class PostIncidentMeldungAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_MELDUNG_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentMeldungAktualisierenActions =
    PostIncidentMeldungAktualisierenAction
    | PostIncidentMeldungAktualisierenAusfuehrenAction
    | PostIncidentMeldungAktualisierenErfolgreichAction
    | PostIncidentMeldungAktualisierenFehlerAction
    | PostIncidentMeldungAktualisierenNichtAusgefuehrtAction
    ;
