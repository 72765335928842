import {Action, createFeatureSelector, createSelector} from '@ngrx/store';
import {MenuPageDefiniton, MenuPageGroup} from './navi.model';


import {NAVI_BOUNDED_CONTEXT_ZUGRIFF_SETZEN, NAVI_SETZEN, NaviBoundedContextZugriffSetzenAction, NaviSetzenAction} from './navi.actions';
import {getIsLoggedIn} from '@orga-app/auth/reducer';
import {APP_RESET_ACTION} from '@orga-app/appstart/actions';
import {getIncidentsMandantOptions} from '@orga-app/mandant/reducer';
import {IncidentManagementBenutzerZugriff, IncidentsAnzeigeMandantOptions} from '../../clients/incidents';
import {VeranstaltungenBenutzerZugriff} from '../../clients/veranstaltungen';
import {VorlagenBenutzerZugriff} from '../../clients/vorlagen';
import {BenutzerverwaltungBenutzerZugriff} from '../../clients/benutzerverwaltung';
import {DisplaysteuerungBenutzerZugriff} from '../../clients/displaysteuerung';
import {EinlasskontrolleBenutzerZugriff} from '../../clients/einlasskontrolle';
import {QsChecksBenutzerZugriff} from '../../clients/qschecks';

export const StoreNaviFeature = 'Navi';

export interface NaviState {
    pages: Array<MenuPageDefiniton>;
    zugriff: { [key: string]: VeranstaltungenBenutzerZugriff | BenutzerverwaltungBenutzerZugriff | VorlagenBenutzerZugriff | EinlasskontrolleBenutzerZugriff };
}

export const initialNaviState: NaviState = {
    pages: [],
    zugriff: {}
};


export function naviReducer(state = initialNaviState, action: Action): NaviState {

    switch (action.type) {
        case APP_RESET_ACTION: {
            return Object.assign({}, state, initialNaviState);
        }

        case NAVI_SETZEN: {
            const x = <NaviSetzenAction>action;

            return Object.assign({}, state, {
                pages: x.pages
            });
        }


        case NAVI_BOUNDED_CONTEXT_ZUGRIFF_SETZEN: {
            const x = <NaviBoundedContextZugriffSetzenAction>action;
            const zugriff = Object.assign({}, state.zugriff);
            zugriff[x.boundedContect] = x.zugriff;

            return Object.assign({}, state, {
                zugriff
            });
        }

        default: {
            return state;
        }
    }
}


export const getNaviState = createFeatureSelector<NaviState>(StoreNaviFeature);

// DATA
export const getPagesAll = createSelector(getNaviState, (state: NaviState) => state.pages);
export const getZugriffVeranstaltungen = createSelector(getNaviState, (state: NaviState) => {
    if (state.zugriff['VERANSTALTUNGEN'] !== undefined) {
        return <VeranstaltungenBenutzerZugriff>state.zugriff['VERANSTALTUNGEN'];
    }
    return null;
});
export const getZugriffVorlagen = createSelector(getNaviState, (state: NaviState) => {
    if (state.zugriff['VORLAGEN'] !== undefined) {
        return <VorlagenBenutzerZugriff>state.zugriff['VORLAGEN'];
    }
    return null;
});
export const getZugriffBenutzerverwaltung = createSelector(getNaviState, (state: NaviState) => {
    if (state.zugriff['BENUTZERVERWALTUNG'] !== undefined) {
        return <BenutzerverwaltungBenutzerZugriff>state.zugriff['BENUTZERVERWALTUNG'];
    }
    return null;
});
export const getZugriffIncidents = createSelector(getNaviState, (state: NaviState) => {
    if (state.zugriff['INCIDENTS'] !== undefined) {
        return <IncidentManagementBenutzerZugriff>state.zugriff['INCIDENTS'];
    }
    return null;
});

export const getZugriffDisplaysteuerung = createSelector(getNaviState, (state: NaviState) => {
    if (state.zugriff['DISPLAYSTEUERUNG'] !== undefined) {
        return <DisplaysteuerungBenutzerZugriff>state.zugriff['DISPLAYSTEUERUNG'];
    }
    return null;
});

export const getZugriffEinlasskontrolle = createSelector(getNaviState, (state: NaviState) => {
    if (state.zugriff['EINLASSKONTROLLE'] !== undefined) {
        return <EinlasskontrolleBenutzerZugriff>state.zugriff['EINLASSKONTROLLE'];
    }
    return null;
});

export const getZugriffQSChecks = createSelector(getNaviState, (state: NaviState) => {
    if (state.zugriff['QSCHECKS'] !== undefined) {
        return <QsChecksBenutzerZugriff>state.zugriff['QSCHECKS'];
    }
    return null;
});

export const getPages = createSelector(getPagesAll, getZugriffVeranstaltungen, getZugriffVorlagen, getZugriffBenutzerverwaltung, getZugriffIncidents,
    getIsLoggedIn, getIncidentsMandantOptions, getNaviState,
    (pages: MenuPageDefiniton[], zugriffVeranstaltungen: VeranstaltungenBenutzerZugriff, zugriffVorlagen: VorlagenBenutzerZugriff,
     zugriffBenutzerverwaltung: BenutzerverwaltungBenutzerZugriff, zugriffIncidents: IncidentManagementBenutzerZugriff, isLoggedIn: boolean,
     incidentsMandantOptions: IncidentsAnzeigeMandantOptions, naviState: NaviState) => {
    if (pages.length === 0) {
        return [];
    }

    pages = pages
        .filter(x => x.active)
        .filter(x => !x.showOnlyWhenLoggedIn || x.showOnlyWhenLoggedIn === isLoggedIn)
        .filter(x => !x.hideWhenLoggedIn || x.hideWhenLoggedIn !== isLoggedIn);

    // Incidents-Menü einfügen
    if (zugriffBenutzerverwaltung && zugriffBenutzerverwaltung.incidentmanagement_sichtbar && zugriffIncidents !== null && zugriffIncidents.ist_aktiv !== null && zugriffIncidents.ist_aktiv) {
        // console.log('IncidentsZugriff: ', zugriffIncidents);
        // Zentrale
        if (zugriffIncidents.hat_zentrale_leitstand) {
            let titelLeitstelle = 'Leitstelle';
            if (incidentsMandantOptions != null) {
                titelLeitstelle = incidentsMandantOptions.zentrale_text;
            }
            pages.push({
                title: titelLeitstelle,
                componentName: 'IncidentLeitzentralePage',
                path: '/incidents-zentrale',
                showOnlyWhenLoggedIn: true,
                dev: false,
                hideWhenLoggedIn: false,
                mustAdmin: false,
                active: true,
                key: null,
                group: 'Incident Management'
            });
        }
        // Abteilungen
        if (zugriffIncidents.hat_abteilung_leitstand) {
            let titelAbteilungen = 'Abteilungen';
            if (incidentsMandantOptions != null) {
                titelAbteilungen = incidentsMandantOptions.abteilung_text;
            }
            zugriffIncidents.leitstand_abteilungen.forEach(x => {
                pages.push({
                    title: x.bezeichnung,
                    componentName: 'IncidentAbteilungsleitstellePage',
                    path: '/incidents-abteilung/' + x.funktionsbereich_id,
                    showOnlyWhenLoggedIn: true,
                    dev: false,
                    hideWhenLoggedIn: false,
                    mustAdmin: false,
                    active: true,
                    key: null,
                    group: titelAbteilungen
                });
            });
        }
    }

        // Wenn Incidents-Lizenz nicht aktiv oder über die Benutzereinstellungen nicht sichtbar ist, werden auch die Menüpunkt nicht angezeigt
        if (zugriffBenutzerverwaltung && !zugriffBenutzerverwaltung.incidentmanagement_sichtbar) {
            pages = pages.filter(x => x.key !== 'INCIDENTS');
        }

        let displaysteuerungZugriff: DisplaysteuerungBenutzerZugriff = null;
        if (naviState.zugriff['DISPLAYSTEUERUNG'] !== undefined) {
            displaysteuerungZugriff = <DisplaysteuerungBenutzerZugriff>naviState.zugriff['DISPLAYSTEUERUNG'];
        }

        // Wenn Displaysteuerung-Lizenz nicht aktiv oder über die Benutzereinstellungen nicht sichtbar ist, werden auch die Menüpunkt nicht angezeigt
        if (displaysteuerungZugriff === null || displaysteuerungZugriff === undefined || (!displaysteuerungZugriff.darf_verwalten)) {
            pages = pages.filter(x => x.key !== 'DISPLAYSTEUERUNG');
        }

        let qsChecksZugriff: QsChecksBenutzerZugriff = null;
        if (naviState.zugriff['QSCHECKS'] !== undefined) {
            qsChecksZugriff = <QsChecksBenutzerZugriff>naviState.zugriff['QSCHECKS'];
        }
        if (qsChecksZugriff === null || qsChecksZugriff === undefined || (!qsChecksZugriff.ist_aktiv) || (!qsChecksZugriff.ist_qs_admin)) {
            pages = pages.filter(x => x.key !== 'QSCHECKS');
        }

        if (qsChecksZugriff === null || qsChecksZugriff === undefined || (!qsChecksZugriff.ist_aktiv) || (!qsChecksZugriff.ist_qs_controller)) {
            pages = pages.filter(x => x.key !== 'QSCHECKSCONTROLLING');
        }


    // VERANSTALTUNGEN
    // ist_backend_admin
    // ist_funktionsbereich_admin
    // ist_funktionsbereich_freigeber
    if (
        zugriffVeranstaltungen !== null &&
        (
            (zugriffVeranstaltungen.ist_backend_admin !== null && zugriffVeranstaltungen.ist_backend_admin) ||
            (zugriffVeranstaltungen.ist_funktionsbereich_admin !== null && zugriffVeranstaltungen.ist_funktionsbereich_admin) ||
            (zugriffVeranstaltungen.ist_veranstaltungsleiter !== null && zugriffVeranstaltungen.ist_veranstaltungsleiter) ||
            (zugriffVeranstaltungen.ist_veranstaltungstyp_admin !== null && zugriffVeranstaltungen.ist_veranstaltungstyp_admin)
        )
    ) {
        // NUTZER DARF SEHEN
    } else {
        pages = pages.filter(x => x.key !== 'VERANSTALTUNGEN');
    }

    // VERANSTALTUNGEN_FUNKPROTOKOLL
    // ist_backend_admin
    // ist_funktionsbereich_admin
    // ist_funktionsbereich_freigeber
    if (
        zugriffVeranstaltungen !== null &&
        (
            (zugriffVeranstaltungen.ist_funkprotokoll_benutzer !== null && zugriffVeranstaltungen.ist_funkprotokoll_benutzer)
        )
    ) {
        // NUTZER DARF SEHEN
    } else {
        pages = pages.filter(x => x.key !== 'VERANSTALTUNGEN_FUNKPROTOKOLL');
    }

    // VORLAGEN_CHECKLISTEN
    // darf_checklisten_verwalten
    if (
        zugriffVorlagen !== null &&
        (
            (zugriffVorlagen.darf_checklisten_verwalten !== null && zugriffVorlagen.darf_checklisten_verwalten)
        )
    ) {
        // NUTZER DARF SEHEN
    } else {
        pages = pages.filter(x => x.key !== 'VORLAGEN_CHECKLISTEN');
    }

    // Filtern nach AUFGABEN_BERECHTIGUNGEN
    if (zugriffVorlagen === null || zugriffVorlagen.darf_aufgaben_benutzer_berechtigungen_verwalten === false) {
        pages = pages.filter(x => x.key !== 'AUFGABEN_BERECHTIGUNGEN');
    }

    // VORLAGEN_DOKUMENTE
    // darf_dokumente_verwalten
    if (
        zugriffVorlagen !== null &&
        (
            (zugriffVorlagen.darf_dokumente_verwalten !== null && zugriffVorlagen.darf_dokumente_verwalten)
        )
    ) {
        // NUTZER DARF SEHEN
    } else {
        pages = pages.filter(x => x.key !== 'VORLAGEN_DOKUMENTE');
    }


    // BENUTZERVERWALTUNG
    // ist_backend_admin
    // ist_funktionsbereich_admin
    if (
        zugriffBenutzerverwaltung !== null &&
        (
            (zugriffBenutzerverwaltung.ist_admin !== null && zugriffBenutzerverwaltung.ist_admin) ||
            (zugriffBenutzerverwaltung.ist_funktionsbereich_admin !== null && zugriffBenutzerverwaltung.ist_funktionsbereich_admin)
        )
    ) {
        // NUTZER DARF SEHEN
    } else {
        pages = pages.filter(x => x.key !== 'BENUTZERVERWALTUNG');
    }



     // Pages gruppieren
    const pageGroups: Array<MenuPageGroup> = [];

    const groups: Array<string> = [];
    pages.forEach(page => {
        if (groups.filter(x => x === page.group).length === 0) {
            groups.push(page.group);
        }
    });

    groups.forEach(group => {
        pageGroups.push({
            group: group,
            items: pages.filter(page => page.group === group)
        });
    });

    return pageGroups;
});
