import * as api from '../../../clients/displaysteuerung'; 

import {Action} from '@ngrx/store';

export const POST_BEREICH_TRIGGER_AKTUALISIEREN = '[DisplaysteuerungModul] PostBereichTriggerAktualisieren laden';
export const POST_BEREICH_TRIGGER_AKTUALISIEREN_AUSFUEHREN = '[DisplaysteuerungModul] PostBereichTriggerAktualisieren ausführen';
export const POST_BEREICH_TRIGGER_AKTUALISIEREN_ERFOLGREICH = '[DisplaysteuerungModul] PostBereichTriggerAktualisieren laden erfolgreich';
export const POST_BEREICH_TRIGGER_AKTUALISIEREN_FEHLER = '[DisplaysteuerungModul] PostBereichTriggerAktualisieren Ladefehler';
export const POST_BEREICH_TRIGGER_AKTUALISIEREN_NICHT_AUSGEFUEHRT = '[DisplaysteuerungModul] PostBereichTriggerAktualisieren nicht ausgeführt (evtl. Offline)';



export class PostBereichTriggerAktualisierenAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_AKTUALISIEREN;
        constructor(public bereichTriggerDatenRequest: api.BereichTriggerDatenRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerAktualisierenAusfuehrenAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_AKTUALISIEREN_AUSFUEHREN;
        constructor(public bereichTriggerDatenRequest: api.BereichTriggerDatenRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerAktualisierenErfolgreichAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_AKTUALISIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public bereichTriggerDatenRequest: api.BereichTriggerDatenRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerAktualisierenFehlerAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_AKTUALISIEREN_FEHLER;
        constructor(public payload: any, public bereichTriggerDatenRequest: api.BereichTriggerDatenRequest, public optPayload: any = null) {}
    }
export class PostBereichTriggerAktualisierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_BEREICH_TRIGGER_AKTUALISIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type DisplaysteuerungCommandBereichTriggerAktualisierenActions =
    PostBereichTriggerAktualisierenAction
    | PostBereichTriggerAktualisierenAusfuehrenAction
    | PostBereichTriggerAktualisierenErfolgreichAction
    | PostBereichTriggerAktualisierenFehlerAction
    | PostBereichTriggerAktualisierenNichtAusgefuehrtAction
    ;
