import {Action, ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import {Aufgabe, AufgabeDetail} from '../../../clients/veranstaltungen';
import {
    GET_AUFGABE_DETAIL_ERFOLGREICH,
    GetAufgabeDetailErfolgreichAction,
    POST_FEHLERBESCHREIBUNG_HINZUFUEGEN,
    POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_ERFOLGREICH,
    POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_FEHLER,
    PostFehlerbeschreibungHinzufuegenAction,
    PostFehlerbeschreibungHinzufuegenErfolgreichAction,
    PostFehlerbeschreibungHinzufuegenFehlerAction
} from '../../../client-ngrx/veranstaltungen';
import {
    AKTUELLE_AUFGABE_SETZEN,
    AktuelleAufgabeSetzenAction,
    PAGE_STATE_RESET,
    PageStateResetAction
} from './aufgabebemerkung.actions.ui';
import { APP_RESET_ACTION } from '@orga-app/appstart/actions';


export interface ModuleState {
    page: PageState;
}

export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer
};

export interface PageState {
    aktuelleAufgabe: Aufgabe;
    aufgabeDetail: AufgabeDetail;
    aufgabebemerkungGemeldet: boolean;
    uiZeigeAufgabebemerkungMeldenLaden: boolean;
    uiZeigeAufgabebemerkungMeldenLadefehler: boolean;
}

export const initialPageState: PageState = {
    aktuelleAufgabe: null,
    aufgabeDetail: null,
    aufgabebemerkungGemeldet: false,
    uiZeigeAufgabebemerkungMeldenLaden: false,
    uiZeigeAufgabebemerkungMeldenLadefehler: false,
};

export function pageReducer(state = initialPageState, action: Action): PageState {
    switch (action.type) {
        // APP RESET
        case APP_RESET_ACTION: {
            return Object.assign({}, state, <PageState>initialPageState);
        }

        // Page State Reset
        case PAGE_STATE_RESET: {
            const a = <PageStateResetAction>action;

            return Object.assign({}, state, <PageState>initialPageState);
        }

        /* OK MELDEN */
        case POST_FEHLERBESCHREIBUNG_HINZUFUEGEN: {
            const a = <PostFehlerbeschreibungHinzufuegenAction>action;

            return Object.assign({}, state, <PageState>{
                aufgabebemerkungGemeldet: false,
                uiZeigeAufgabebemerkungMeldenLaden: true,
                uiZeigeAufgabebemerkungMeldenLadefehler: false
            });
        }

        case POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_ERFOLGREICH: {
            const a = <PostFehlerbeschreibungHinzufuegenErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                aufgabebemerkungGemeldet: true,
                uiZeigeAufgabebemerkungMeldenLaden: false,
                uiZeigeAufgabebemerkungMeldenLadefehler: false
            });
        }

        case POST_FEHLERBESCHREIBUNG_HINZUFUEGEN_FEHLER: {
            const a = <PostFehlerbeschreibungHinzufuegenFehlerAction>action;

            return Object.assign({}, state, <PageState>{
                aufgabebemerkungGemeldet: false,
                uiZeigeAufgabebemerkungMeldenLaden: false,
                uiZeigeAufgabebemerkungMeldenLadefehler: true
            });
        }


        case GET_AUFGABE_DETAIL_ERFOLGREICH: {
            const a = <GetAufgabeDetailErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                aufgabeDetail: a.payload
            });
        }

        case AKTUELLE_AUFGABE_SETZEN: {
            const a = <AktuelleAufgabeSetzenAction>action;

            // console.log('Setze aktuelle Aufgabe: ', a);

            return Object.assign({}, state, <PageState>{
                aktuelleAufgabe: a.aufgabe
            });
        }

        default: {
            return state;
        }
    }
}


/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const getModuleState = createFeatureSelector<ModuleState>('AufgabebemerkungPage');

export const getPageState = createSelector(getModuleState, (state: ModuleState) => state.page);

// DATA
export const getAktuelleAufgabe = createSelector(getPageState, (state: PageState) => state.aktuelleAufgabe);
export const getAufgabeDetail = createSelector(getPageState, (state: PageState) => state.aufgabeDetail);
export const getAufgabebemerkungGemeldet = createSelector(getPageState, (state: PageState) => state.aufgabebemerkungGemeldet);
export const getUiZeigeAufgabebemerkungMeldenLaden = createSelector(getPageState, (state: PageState) => state.uiZeigeAufgabebemerkungMeldenLaden);
export const getUiZeigeAufgabebemerkungMeldenLadefehler = createSelector(getPageState, (state: PageState) => state.uiZeigeAufgabebemerkungMeldenLadefehler);

