import {Action, ActionReducerMap} from '@ngrx/store';

import * as actionsUi from './aufgaben-berechtigungen-modal.actions.ui';
import {SelektierteBenutzerAction} from './aufgaben-berechtigungen-modal.actions.ui';
import {Benutzer} from '../../../../clients/vorlagen';
import {GET_BENUTZER_LISTE, GET_BENUTZER_LISTE_ERFOLGREICH, GET_BENUTZER_LISTE_FEHLER, GetBenutzerListeErfolgreichAction} from '../../../../client-ngrx/benutzerverwaltung';

import * as api from '../../../../clients/veranstaltungen';
import {GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN, GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN_ERFOLGREICH, GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN_FEHLER, GetVeranstaltungBenutzerListeAdminErfolgreichAction} from '../../../../client-ngrx/veranstaltungen';

export interface ModuleState {
    page: PageState;
}

export const reducers: ActionReducerMap<ModuleState> = {
    page: pageReducer
};

export interface PageState {
    benutzer: Array<Benutzer> | Array<api.Benutzer>;
    benutzerLaden: boolean;
    benutzerFehler: boolean;
    selectedBenutzer: Array<string>;
}

export const initialPageState: PageState = {
    benutzer: [],
    benutzerLaden: false,
    benutzerFehler: false,
    selectedBenutzer: [],
};

export function pageReducer(state = initialPageState, action: Action): PageState {
    switch (action.type) {

        // Page State Reset
        case actionsUi.PAGE_STATE_RESET: {
            const a = <actionsUi.PageStateResetAction>action;

            return Object.assign({}, state, <PageState>initialPageState);
        }

        case GET_BENUTZER_LISTE_ERFOLGREICH: {
            const a = <GetBenutzerListeErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                benutzer: a.payload,
                benutzerLaden: false,
                benutzerFehler: false
            });
        }

        case GET_BENUTZER_LISTE: {
            return Object.assign({}, state, <PageState>{
                benutzerLaden: true
            });
        }

        case GET_BENUTZER_LISTE_FEHLER: {
            return Object.assign({}, state, <PageState>{
                benutzer: [],
                benutzerLaden: false,
                benutzerFehler: true
            });
        }

        case GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN_ERFOLGREICH: {
            const a = <GetVeranstaltungBenutzerListeAdminErfolgreichAction>action;

            return Object.assign({}, state, <PageState>{
                benutzer: a.payload,
                benutzerLaden: false,
                benutzerFehler: false
            });
        }

        case GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN: {
            return Object.assign({}, state, <PageState>{
                benutzerLaden: true
            });
        }

        case GET_VERANSTALTUNG_BENUTZER_LISTE_ADMIN_FEHLER: {
            return Object.assign({}, state, <PageState>{
                benutzer: [],
                benutzerLaden: false,
                benutzerFehler: true
            });
        }

        case actionsUi.SELEKTIERTE_BENUTZER: {
            const a = <SelektierteBenutzerAction>action;
            return Object.assign({}, state, <PageState>{
                selectedBenutzer: a.payload
            });
        }

        default: {
            return state;
        }
    }
}


