import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_MEINE_MANDANTEN = '[ApiModul] GetMeineMandanten laden';
export const GET_MEINE_MANDANTEN_AUSFUEHREN = '[ApiModul] GetMeineMandanten ausführen';
export const GET_MEINE_MANDANTEN_ERFOLGREICH = '[ApiModul] GetMeineMandanten laden erfolgreich';
export const GET_MEINE_MANDANTEN_FEHLER = '[ApiModul] GetMeineMandanten Ladefehler';
export const GET_MEINE_MANDANTEN_NICHT_AUSGEFUEHRT = '[ApiModul] GetMeineMandanten nicht ausgeführt (evtl. Offline)';



export class GetMeineMandantenAction implements Action {
    readonly type = GET_MEINE_MANDANTEN;
        constructor( public logos:boolean ,  public pWidth:number ,  public pHheight:number , public optPayload: any = null) {}
    }
export class GetMeineMandantenAusfuehrenAction implements Action {
    readonly type = GET_MEINE_MANDANTEN_AUSFUEHREN;
        constructor( public logos:boolean ,  public pWidth:number ,  public pHheight:number , public optPayload: any = null) {}
    }
export class GetMeineMandantenErfolgreichAction implements Action {
    readonly type = GET_MEINE_MANDANTEN_ERFOLGREICH;
        constructor(public payload: Array<api.MandantInfo>,  public logos:boolean ,  public pWidth:number ,  public pHheight:number , public optPayload: any = null) {}
    }
export class GetMeineMandantenFehlerAction implements Action {
    readonly type = GET_MEINE_MANDANTEN_FEHLER;
        constructor(public payload: any,  public logos:boolean ,  public pWidth:number ,  public pHheight:number , public optPayload: any = null) {}
    }
export class GetMeineMandantenNichtAusgefuehrtAction implements Action {
    readonly type = GET_MEINE_MANDANTEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMeineMandantenActions =
    GetMeineMandantenAction
    | GetMeineMandantenAusfuehrenAction
    | GetMeineMandantenErfolgreichAction
    | GetMeineMandantenFehlerAction
    | GetMeineMandantenNichtAusgefuehrtAction
    ;
