import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN = '[VorlagenModul] PostDokumentBerechtigungHinzufuegen laden';
export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_AUSFUEHREN = '[VorlagenModul] PostDokumentBerechtigungHinzufuegen ausführen';
export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_ERFOLGREICH = '[VorlagenModul] PostDokumentBerechtigungHinzufuegen laden erfolgreich';
export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_FEHLER = '[VorlagenModul] PostDokumentBerechtigungHinzufuegen Ladefehler';
export const POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentBerechtigungHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostDokumentBerechtigungHinzufuegenAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN;
        constructor(public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_AUSFUEHREN;
        constructor(public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungHinzufuegenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBerechtigungHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BERECHTIGUNG_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentBerechtigungHinzufuegenActions =
    PostDokumentBerechtigungHinzufuegenAction
    | PostDokumentBerechtigungHinzufuegenAusfuehrenAction
    | PostDokumentBerechtigungHinzufuegenErfolgreichAction
    | PostDokumentBerechtigungHinzufuegenFehlerAction
    | PostDokumentBerechtigungHinzufuegenNichtAusgefuehrtAction
    ;
