import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheChecklisten laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheChecklisten ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheChecklisten laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheChecklisten Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereicheChecklisten nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereicheChecklistenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheChecklistenAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheChecklistenErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_ERFOLGREICH;
        constructor(public payload: Array<api.PhasenVeranstaltungFunktionsbereiche>,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheChecklistenFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereicheChecklistenNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICHE_CHECKLISTEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereicheChecklistenActions =
    GetVeranstaltungFunktionsbereicheChecklistenAction
    | GetVeranstaltungFunktionsbereicheChecklistenAusfuehrenAction
    | GetVeranstaltungFunktionsbereicheChecklistenErfolgreichAction
    | GetVeranstaltungFunktionsbereicheChecklistenFehlerAction
    | GetVeranstaltungFunktionsbereicheChecklistenNichtAusgefuehrtAction
    ;
