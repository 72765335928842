import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_ENTFERNEN = '[VeranstaltungenModul] PostDokumentEntfernen laden';
export const POST_DOKUMENT_ENTFERNEN_AUSFUEHREN = '[VeranstaltungenModul] PostDokumentEntfernen ausführen';
export const POST_DOKUMENT_ENTFERNEN_ERFOLGREICH = '[VeranstaltungenModul] PostDokumentEntfernen laden erfolgreich';
export const POST_DOKUMENT_ENTFERNEN_FEHLER = '[VeranstaltungenModul] PostDokumentEntfernen Ladefehler';
export const POST_DOKUMENT_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostDokumentEntfernen nicht ausgeführt (evtl. Offline)';



export class PostDokumentEntfernenAction implements Action {
    readonly type = POST_DOKUMENT_ENTFERNEN;
        constructor(public dokumentEntfernenRequest: api.DokumentEntfernenRequest, public optPayload: any = null) {}
    }
export class PostDokumentEntfernenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_ENTFERNEN_AUSFUEHREN;
        constructor(public dokumentEntfernenRequest: api.DokumentEntfernenRequest, public optPayload: any = null) {}
    }
export class PostDokumentEntfernenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentEntfernenRequest: api.DokumentEntfernenRequest, public optPayload: any = null) {}
    }
export class PostDokumentEntfernenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_ENTFERNEN_FEHLER;
        constructor(public payload: any, public dokumentEntfernenRequest: api.DokumentEntfernenRequest, public optPayload: any = null) {}
    }
export class PostDokumentEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandDokumentEntfernenActions =
    PostDokumentEntfernenAction
    | PostDokumentEntfernenAusfuehrenAction
    | PostDokumentEntfernenErfolgreichAction
    | PostDokumentEntfernenFehlerAction
    | PostDokumentEntfernenNichtAusgefuehrtAction
    ;
