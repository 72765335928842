import {Action} from '@ngrx/store';

export const PAGE_STATE_RESET = '[BenutzerauswahlModal] Page State reset';
export const NICHT_ANZUZEIGENDE_BENUTZER_SETZEN = '[BenutzerauswahlModal] Benutzer die aus der Auswahl entfernt werden sollen setzen';
export const BENUTZER_AKTIVIERT = '[BenutzerauswahlModal] Benutzer aktiviert';
export const BENUTZER_DEAKTIVIERT = '[BenutzerauswahlModal] Benutzer deaktiviert';
export const AUSWAHL_UMKEHREN = '[BenutzerauswahlModal] Alle Funktionsbereich Auswahl umkehren';
export const SELEKTIERTE_BENUTZER = '[BenutzerauswahlModal] Selektierte Benutzer IDs';

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;
}

// UI
export class NichtAnzuzeigendeBenutzerSetzenAction implements Action {
    readonly type = NICHT_ANZUZEIGENDE_BENUTZER_SETZEN;

    constructor(public nichtAnzuzeigendeBenutzer: Array<any>) {
    }
}

export class SelektierteBenutzerAction implements Action {
    readonly type = SELEKTIERTE_BENUTZER;

    //
    constructor(public payload: Array<string>) {
    }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
    NichtAnzuzeigendeBenutzerSetzenAction
    | PageStateResetAction
    | SelektierteBenutzerAction
    ;
