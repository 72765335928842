

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_LOGO = '[VeranstaltungenModul] GetVeranstaltungLogo laden';
export const GET_VERANSTALTUNG_LOGO_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungLogo ausführen';
export const GET_VERANSTALTUNG_LOGO_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungLogo laden erfolgreich';
export const GET_VERANSTALTUNG_LOGO_FEHLER = '[VeranstaltungenModul] GetVeranstaltungLogo Ladefehler';
export const GET_VERANSTALTUNG_LOGO_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungLogo nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungLogoAction implements Action {
    readonly type = GET_VERANSTALTUNG_LOGO;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungLogoAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_LOGO_AUSFUEHREN;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungLogoErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_LOGO_ERFOLGREICH;
        constructor( public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungLogoFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_LOGO_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungLogoNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_LOGO_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungLogoActions =
    GetVeranstaltungLogoAction
    | GetVeranstaltungLogoAusfuehrenAction
    | GetVeranstaltungLogoErfolgreichAction
    | GetVeranstaltungLogoFehlerAction
    | GetVeranstaltungLogoNichtAusgefuehrtAction
    ;
