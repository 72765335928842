import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const POST_PRUEFPLAN_ROLLEN_PRUEFER_HINZUFUEGEN = '[QschecksModul] PostPruefplanRollenPrueferHinzufuegen laden';
export const POST_PRUEFPLAN_ROLLEN_PRUEFER_HINZUFUEGEN_AUSFUEHREN = '[QschecksModul] PostPruefplanRollenPrueferHinzufuegen ausführen';
export const POST_PRUEFPLAN_ROLLEN_PRUEFER_HINZUFUEGEN_ERFOLGREICH = '[QschecksModul] PostPruefplanRollenPrueferHinzufuegen laden erfolgreich';
export const POST_PRUEFPLAN_ROLLEN_PRUEFER_HINZUFUEGEN_FEHLER = '[QschecksModul] PostPruefplanRollenPrueferHinzufuegen Ladefehler';
export const POST_PRUEFPLAN_ROLLEN_PRUEFER_HINZUFUEGEN_NICHT_AUSGEFUEHRT = '[QschecksModul] PostPruefplanRollenPrueferHinzufuegen nicht ausgeführt (evtl. Offline)';



export class PostPruefplanRollenPrueferHinzufuegenAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_PRUEFER_HINZUFUEGEN;
        constructor(public pruefplanRolleMultiRequest: api.PruefplanRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRollenPrueferHinzufuegenAusfuehrenAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_PRUEFER_HINZUFUEGEN_AUSFUEHREN;
        constructor(public pruefplanRolleMultiRequest: api.PruefplanRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRollenPrueferHinzufuegenErfolgreichAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_PRUEFER_HINZUFUEGEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public pruefplanRolleMultiRequest: api.PruefplanRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRollenPrueferHinzufuegenFehlerAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_PRUEFER_HINZUFUEGEN_FEHLER;
        constructor(public payload: any, public pruefplanRolleMultiRequest: api.PruefplanRolleMultiRequest, public optPayload: any = null) {}
    }
export class PostPruefplanRollenPrueferHinzufuegenNichtAusgefuehrtAction implements Action {
    readonly type = POST_PRUEFPLAN_ROLLEN_PRUEFER_HINZUFUEGEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksCommandPruefplanRollenPrueferHinzufuegenActions =
    PostPruefplanRollenPrueferHinzufuegenAction
    | PostPruefplanRollenPrueferHinzufuegenAusfuehrenAction
    | PostPruefplanRollenPrueferHinzufuegenErfolgreichAction
    | PostPruefplanRollenPrueferHinzufuegenFehlerAction
    | PostPruefplanRollenPrueferHinzufuegenNichtAusgefuehrtAction
    ;
