import {CUSTOM_ELEMENTS_SCHEMA, Injectable, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DateLocale, DaysOfWeek, Md2Module, Months} from 'angular-md2';
import {SopiListComponent} from '../sopi-list/sopi-list.component';
import {SopiScrollModule} from '../../../sopi/components/sopi-scroll/sopi-scroll.module';
import {IonicModule} from '@ionic/angular';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {SopiInfoboxComponent} from '../sopi-infobox/sopi-infobox.component';
import {SopiDockpanelComponent} from '../sopi-dockpanel/sopi-dockpanel.component';
import {SopiDockpanelBottomComponent} from '../sopi-dockpanel-bottom/sopi-dockpanel-bottom.component';
import {SopiDockpanelTopComponent} from '../sopi-dockpanel-top/sopi-dockpanel-top.component';
import {SopiDockpanelContentComponent} from '../sopi-dockpanel-content/sopi-dockpanel-content.component';
import {SopiFooterComponent} from '../sopi-footer/sopi-footer.component';
import {DayOfWeekPipe} from '../core/day-of-week.pipe';
import {SopiInnerItemComponent} from '../sopi-inner-item/sopi-inner-item.component';
import {EventSelectionButtonComponent} from '../event-selection-button/event-selection-button.component';
import {PinboardPinComponent} from '../pinboard-pin/pinboard-pin.component';
import {SystemMeldungComponent} from '../system-meldung/system-meldung.component';
import {SopiNavButtonComponent} from '../sopi-nav-button/sopi-nav-button.component';
import {SopiStatusBubbleComponent} from '../sopi-status-bubble/sopi-status-bubble.component';
import {SopiAreaComponent} from '../sopi-area/sopi-area.component';
import {SopiBadgeComponent} from '../sopi-badge/sopi-badge.component';
import {SopiFabComponent} from '../sopi-fab/sopi-fab.component';
import {SopiImageIconComponent} from '../sopi-image-icon/sopi-image-icon.component';
import {MarkdownModule} from 'ngx-markdown';
import {PipesModule} from '../../../sopi/pipes/pipes.module';


@Injectable()
export class MyDateLocale extends DateLocale {
    daysOfWeek = <DaysOfWeek>{
        long: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        short: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        narrow: ['S', 'M', 'D', 'M', 'D', 'F', 'S']
    };
    months = <Months>{
        long: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September',
            'Oktober', 'November', 'Dezember'],
        short: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        narrow: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
    };

    constructor() {
        super();
        this.firstDayOfWeek = 1;
    }
}

@NgModule({
    declarations: [
        SopiListComponent,
        SopiInnerItemComponent,
        SopiInfoboxComponent,
        SopiDockpanelComponent,
        SopiDockpanelTopComponent,
        SopiDockpanelContentComponent,
        SopiDockpanelBottomComponent,
        SopiFooterComponent,
        SopiNavButtonComponent,
        SopiAreaComponent,
        SopiBadgeComponent,
        SopiFabComponent,
        DayOfWeekPipe,
        EventSelectionButtonComponent,
        PinboardPinComponent,
        SystemMeldungComponent,
        SopiStatusBubbleComponent,
        SopiImageIconComponent
    ],
    entryComponents: [],
    imports: [
        CommonModule,
        SopiScrollModule,
        Md2Module,
        IonicModule,
        DragDropModule,
        MarkdownModule,
        PipesModule
    ],
    exports: [
        CommonModule,
        Md2Module,
        SopiListComponent,
        SopiInnerItemComponent,
        SopiInfoboxComponent,
        SopiDockpanelComponent,
        SopiDockpanelTopComponent,
        SopiDockpanelContentComponent,
        SopiDockpanelBottomComponent,
        SopiFooterComponent,
        SopiNavButtonComponent,
        SopiAreaComponent,
        SopiBadgeComponent,
        SopiFabComponent,
        DayOfWeekPipe,
        EventSelectionButtonComponent,
        PinboardPinComponent,
        SystemMeldungComponent,
        SopiStatusBubbleComponent,
        SopiImageIconComponent
    ],
    providers: [
        {provide: DateLocale, useClass: MyDateLocale}
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SopiComponentsModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: SopiComponentsModule,
            providers: [
                {provide: DateLocale, useClass: MyDateLocale}
            ]
        };
    }
}
