import * as api from '../../../clients/kommunikation'; 

import {Action} from '@ngrx/store';

export const POST_MITTEILUNGS_VORLAGE_KOPIEREN = '[KommunikationModul] PostMitteilungsVorlageKopieren laden';
export const POST_MITTEILUNGS_VORLAGE_KOPIEREN_AUSFUEHREN = '[KommunikationModul] PostMitteilungsVorlageKopieren ausführen';
export const POST_MITTEILUNGS_VORLAGE_KOPIEREN_ERFOLGREICH = '[KommunikationModul] PostMitteilungsVorlageKopieren laden erfolgreich';
export const POST_MITTEILUNGS_VORLAGE_KOPIEREN_FEHLER = '[KommunikationModul] PostMitteilungsVorlageKopieren Ladefehler';
export const POST_MITTEILUNGS_VORLAGE_KOPIEREN_NICHT_AUSGEFUEHRT = '[KommunikationModul] PostMitteilungsVorlageKopieren nicht ausgeführt (evtl. Offline)';



export class PostMitteilungsVorlageKopierenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_KOPIEREN;
        constructor(public mitteilungsVorlageRequest: api.MitteilungsVorlageRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageKopierenAusfuehrenAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_KOPIEREN_AUSFUEHREN;
        constructor(public mitteilungsVorlageRequest: api.MitteilungsVorlageRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageKopierenErfolgreichAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_KOPIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public mitteilungsVorlageRequest: api.MitteilungsVorlageRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageKopierenFehlerAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_KOPIEREN_FEHLER;
        constructor(public payload: any, public mitteilungsVorlageRequest: api.MitteilungsVorlageRequest, public optPayload: any = null) {}
    }
export class PostMitteilungsVorlageKopierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_MITTEILUNGS_VORLAGE_KOPIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type KommunikationCommandMitteilungsVorlageKopierenActions =
    PostMitteilungsVorlageKopierenAction
    | PostMitteilungsVorlageKopierenAusfuehrenAction
    | PostMitteilungsVorlageKopierenErfolgreichAction
    | PostMitteilungsVorlageKopierenFehlerAction
    | PostMitteilungsVorlageKopierenNichtAusgefuehrtAction
    ;
