

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_XML = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtXml laden';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_XML_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtXml ausführen';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_XML_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtXml laden erfolgreich';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_XML_FEHLER = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtXml Ladefehler';
export const GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_XML_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungFunktionsbereichChecklistePruefberichtXml nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungFunktionsbereichChecklistePruefberichtXmlAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_XML;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichChecklistePruefberichtXmlAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_XML_AUSFUEHREN;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichChecklistePruefberichtXmlErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_XML_ERFOLGREICH;
        constructor( public veranstaltung_id:string ,  public funktionsbereich_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichChecklistePruefberichtXmlFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_XML_FEHLER;
        constructor(public payload: any,  public veranstaltung_id:string ,  public funktionsbereich_id:string ,  public checkliste_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungFunktionsbereichChecklistePruefberichtXmlNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNG_FUNKTIONSBEREICH_CHECKLISTE_PRUEFBERICHT_XML_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungFunktionsbereichChecklistePruefberichtXmlActions =
    GetVeranstaltungFunktionsbereichChecklistePruefberichtXmlAction
    | GetVeranstaltungFunktionsbereichChecklistePruefberichtXmlAusfuehrenAction
    | GetVeranstaltungFunktionsbereichChecklistePruefberichtXmlErfolgreichAction
    | GetVeranstaltungFunktionsbereichChecklistePruefberichtXmlFehlerAction
    | GetVeranstaltungFunktionsbereichChecklistePruefberichtXmlNichtAusgefuehrtAction
    ;
