import {Injectable} from '@angular/core';
import {AlertController, ToastController} from '@ionic/angular';

@Injectable()
export class NotificationService {

    constructor(public toastCtrl: ToastController,
                public alertCtrl: AlertController) {

    }

    public async showMessage(text: string = null) {
        if (text != null && text !== '') {
            const toast = await this.toastCtrl.create({
                message: text,
                duration: 3000,
                cssClass: 'messageToast'
            });
            await toast.present();
        } else {
            console.warn('Es wurde kein Text für den Toast übergeben');
        }
    }

    public async showMessageWithOk(text: string = null) {
        if (text != null && text !== '') {
            const toast = await this.toastCtrl.create({
                message: text,
                // showCloseButton: true,
                // closeButtonText: 'OK',
                buttons: [
                    { text: 'OK', role: 'close' }
                ],
                cssClass: 'messageToast'
            });
            await toast.present();
        } else {
            console.warn('Es wurde kein Text für den Toast übergeben');
        }
    }

    public async showInfo(text: string = null) {
        if (text != null && text !== '') {
            const toast = await this.toastCtrl.create({
                message: text,
                duration: 3000,
                cssClass: 'infoToast'
            });
            await toast.present();
        } else {
            console.warn('Es wurde kein Text für den Toast übergeben');
        }
    }

    public async showError(text: string = null) {
        if (text != null && text !== '') {
            const toast = await this.toastCtrl.create({
                message: text,
                // showCloseButton: true,
                // closeButtonText: 'OK',
                buttons: [
                    { text: 'OK', role: 'close' }
                ],
                // duration: 3000,
                cssClass: 'errorToast'
            });
            await toast.present();
        } else {
            console.warn('Es wurde kein Text für den Toast übergeben');
        }
    }


    public async showAlertInfo(titel: string = null, untertitel: string = null, message: string = null) {
        console.log('showAlertInfo', titel, untertitel, message);
        if (message != null && message !== '') {
            const prompt = await this.alertCtrl.create({
                header: titel,
                subHeader: untertitel,
                message,
                cssClass: 'alert-info',
                buttons: [
                    {
                        text: 'OK',
                        handler: data => {

                        }
                    }
                ]
            });
            await prompt.present();
        } else {
            console.warn('Es wurde kein Text für den Alert übergeben');
        }
    }

    public async showAlertWarnung(titel: string = null, untertitel: string = null, message: string = null) {
        if (message != null && message !== '') {
            const prompt = await this.alertCtrl.create({
                header: titel,
                subHeader: untertitel,
                message,
                cssClass: 'alert-warnung',
                buttons: [
                    {
                        text: 'OK',
                        handler: data => {

                        }
                    }
                ]
            });
            await prompt.present();
        } else {
            console.warn('Es wurde kein Text für den Alert übergeben');
        }
    }

    public async showAlertError(titel: string = null, untertitel: string = null, message: string = null) {
        if (message != null && message !== '') {
            const prompt = await this.alertCtrl.create({
                header: titel,
                subHeader: untertitel,
                message,
                cssClass: 'alert-error',
                buttons: [
                    {
                        text: 'OK',
                        handler: data => {

                        }
                    }
                ]
            });
            await prompt.present();
        } else {
            console.warn('Es wurde kein Text für den Alert übergeben');
        }
    }

}
