import {Action} from '@ngrx/store';

export const PAGE_STATE_RESET = '[AufgabenBerechtigungenModal] Page State reset';
export const SELEKTIERTE_BENUTZER = '[AufgabenBerechtigungenModal] Selektierte Benutzer IDs';

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

// PAGE STATE RESET
export class PageStateResetAction implements Action {
    readonly type = PAGE_STATE_RESET;

    constructor(public veranstaltungsId: string) {
    }
}

export class SelektierteBenutzerAction implements Action {
    readonly type = SELEKTIERTE_BENUTZER;

    //
    constructor(public payload: Array<string>) {
    }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
    PageStateResetAction
    | SelektierteBenutzerAction
    ;
