

import {Action} from '@ngrx/store';

export const GET_PRUEFLAUF_AUFGABE_BILD = '[QschecksModul] GetPrueflaufAufgabeBild laden';
export const GET_PRUEFLAUF_AUFGABE_BILD_AUSFUEHREN = '[QschecksModul] GetPrueflaufAufgabeBild ausführen';
export const GET_PRUEFLAUF_AUFGABE_BILD_ERFOLGREICH = '[QschecksModul] GetPrueflaufAufgabeBild laden erfolgreich';
export const GET_PRUEFLAUF_AUFGABE_BILD_FEHLER = '[QschecksModul] GetPrueflaufAufgabeBild Ladefehler';
export const GET_PRUEFLAUF_AUFGABE_BILD_NICHT_AUSGEFUEHRT = '[QschecksModul] GetPrueflaufAufgabeBild nicht ausgeführt (evtl. Offline)';



export class GetPrueflaufAufgabeBildAction implements Action {
    readonly type = GET_PRUEFLAUF_AUFGABE_BILD;
        constructor( public prueflauf_id:string ,  public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetPrueflaufAufgabeBildAusfuehrenAction implements Action {
    readonly type = GET_PRUEFLAUF_AUFGABE_BILD_AUSFUEHREN;
        constructor( public prueflauf_id:string ,  public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetPrueflaufAufgabeBildErfolgreichAction implements Action {
    readonly type = GET_PRUEFLAUF_AUFGABE_BILD_ERFOLGREICH;
        constructor( public prueflauf_id:string ,  public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetPrueflaufAufgabeBildFehlerAction implements Action {
    readonly type = GET_PRUEFLAUF_AUFGABE_BILD_FEHLER;
        constructor(public payload: any,  public prueflauf_id:string ,  public aufgabe_id:string ,  public bild_id:string ,  public width:number ,  public height:number , public optPayload: any = null) {}
    }
export class GetPrueflaufAufgabeBildNichtAusgefuehrtAction implements Action {
    readonly type = GET_PRUEFLAUF_AUFGABE_BILD_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryPrueflaufAufgabeBildActions =
    GetPrueflaufAufgabeBildAction
    | GetPrueflaufAufgabeBildAusfuehrenAction
    | GetPrueflaufAufgabeBildErfolgreichAction
    | GetPrueflaufAufgabeBildFehlerAction
    | GetPrueflaufAufgabeBildNichtAusgefuehrtAction
    ;
