import {MenuPageDefiniton} from './navi/navi.model';

export const pagesDef: Array<MenuPageDefiniton> = [
    {
        title: 'Login',
        // component: LoginPage,
        componentName: 'LoginPage',
        path: '/login',
        showOnlyWhenLoggedIn: false,
        dev: false,
        hideWhenLoggedIn: true,
        mustAdmin: false,
        active: true,
        key: null,
        group: 'Verwaltung'
    },
    {
        title: 'Funktionsbereiche und Rollen',
        // component: FunktionsbereicheRollenPage,
        componentName: 'FunktionsbereicheRollenPage',
        path: '/funktionsbereiche-rollen',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'BENUTZERVERWALTUNG',
        group: 'Verwaltung'
    },
    {
        title: 'Benutzer und Rollen',
        // component: BenutzerRollenPage,
        componentName: 'BenutzerRollenPage',
        path: '/benutzer-rollen',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'BENUTZERVERWALTUNG',
        group: 'Verwaltung'
    },
    {
        title: 'Checklisten und Aufgaben',
        // component: KontaktePage,
        componentName: 'ChecklistenPage',
        path: '/checklisten',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'VORLAGEN_CHECKLISTEN',
        group: 'Verwaltung'
    },
    {
        title: 'Aufgaben Berechtigungen',
        // component: KontaktePage,
        componentName: 'AufgabenBerechtigungenHomePage',
        path: '/aufgaben-berechtigungen',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'AUFGABEN_BERECHTIGUNGEN',
        group: 'Verwaltung'
    },
    {
        title: 'Allgemeine Dokumente',
        // component: DokumentVerwaltungPage,
        componentName: 'DokumentverwaltungPage',
        path: '/dokumentverwaltung',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'VORLAGEN_DOKUMENTE',
        group: 'Verwaltung'
    },
    {
        title: 'Displaysteuerung',
        componentName: 'DisplaysteuerungPage',
        path: '/displaysteuerung',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'DISPLAYSTEUERUNG',
        group: 'Verwaltung'
    },
    {
        title: 'Kontakte',
        componentName: 'KontaktePage',
        path: '/kontaktverwaltung',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'KONTAKTE',
        group: 'Verwaltung'
    },
    {
        title: 'Veranstaltungen',
        // subtitle: 'test subtitle',
        // component: VeranstaltungenPage,
        componentName: 'VeranstaltungenPage',
        path: '/veranstaltungen',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'VERANSTALTUNGEN',
        group: 'Verwaltung'
    },
   /* {
        title: 'Veranstaltungen2',
        componentName: 'Veranstaltungen2Page',
        path: '/veranstaltungen2',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'VERANSTALTUNGEN2',
        group: 'Verwaltung'
    },*/
    {
        title: 'Veranstaltung',
        componentName: 'VeranstaltungMainPage',
        path: '/veranstaltung',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'VERANSTALTUNG',
        group: 'Operativ'
    },
    {
        title: 'QS-Checks',
        componentName: 'QsChecksMainPage',
        path: '/qschecks',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'QSCHECKS',
        group: 'Verwaltung'
    },
    {
        title: 'Funk Protokollierung',
        // component: VeranstaltungenPage,
        componentName: 'FunkprotokollierungPage',
        path: '/funkprotokollierung',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'VERANSTALTUNGEN_FUNKPROTOKOLL',
        group: 'Operativ'
    },
    {
        title: 'Meine Aufgaben',
        componentName: 'MeineAufgabenPage',
        path: '/incidents-meine-aufgaben',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'INCIDENTS',
        group: 'Incident Management'
    },
    {
        title: 'QS-Checks Auswertungen',
        componentName: 'QsChecksControllingHomePage',
        path: '/qschecks-controlling',
        showOnlyWhenLoggedIn: true,
        dev: false,
        hideWhenLoggedIn: false,
        mustAdmin: false,
        active: true,
        key: 'QSCHECKSCONTROLLING',
        group: 'Controlling'
    }
];
