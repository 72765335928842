import * as api from '../../../clients/incidents'; 

import {Action} from '@ngrx/store';

export const POST_INCIDENT_ANLEGEN_AUS_FUNKPROTOKOLL = '[IncidentsModul] PostIncidentAnlegenAusFunkprotokoll laden';
export const POST_INCIDENT_ANLEGEN_AUS_FUNKPROTOKOLL_AUSFUEHREN = '[IncidentsModul] PostIncidentAnlegenAusFunkprotokoll ausführen';
export const POST_INCIDENT_ANLEGEN_AUS_FUNKPROTOKOLL_ERFOLGREICH = '[IncidentsModul] PostIncidentAnlegenAusFunkprotokoll laden erfolgreich';
export const POST_INCIDENT_ANLEGEN_AUS_FUNKPROTOKOLL_FEHLER = '[IncidentsModul] PostIncidentAnlegenAusFunkprotokoll Ladefehler';
export const POST_INCIDENT_ANLEGEN_AUS_FUNKPROTOKOLL_NICHT_AUSGEFUEHRT = '[IncidentsModul] PostIncidentAnlegenAusFunkprotokoll nicht ausgeführt (evtl. Offline)';



export class PostIncidentAnlegenAusFunkprotokollAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN_AUS_FUNKPROTOKOLL;
        constructor(public incidentFunkprotokollRequest: api.IncidentFunkprotokollRequest, public optPayload: any = null) {}
    }
export class PostIncidentAnlegenAusFunkprotokollAusfuehrenAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN_AUS_FUNKPROTOKOLL_AUSFUEHREN;
        constructor(public incidentFunkprotokollRequest: api.IncidentFunkprotokollRequest, public optPayload: any = null) {}
    }
export class PostIncidentAnlegenAusFunkprotokollErfolgreichAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN_AUS_FUNKPROTOKOLL_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public incidentFunkprotokollRequest: api.IncidentFunkprotokollRequest, public optPayload: any = null) {}
    }
export class PostIncidentAnlegenAusFunkprotokollFehlerAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN_AUS_FUNKPROTOKOLL_FEHLER;
        constructor(public payload: any, public incidentFunkprotokollRequest: api.IncidentFunkprotokollRequest, public optPayload: any = null) {}
    }
export class PostIncidentAnlegenAusFunkprotokollNichtAusgefuehrtAction implements Action {
    readonly type = POST_INCIDENT_ANLEGEN_AUS_FUNKPROTOKOLL_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type IncidentsCommandIncidentAnlegenAusFunkprotokollActions =
    PostIncidentAnlegenAusFunkprotokollAction
    | PostIncidentAnlegenAusFunkprotokollAusfuehrenAction
    | PostIncidentAnlegenAusFunkprotokollErfolgreichAction
    | PostIncidentAnlegenAusFunkprotokollFehlerAction
    | PostIncidentAnlegenAusFunkprotokollNichtAusgefuehrtAction
    ;
