import * as api from '../../../clients/veranstaltungstypen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGS_TYP_FELDER = '[VeranstaltungstypenModul] GetVeranstaltungsTypFelder laden';
export const GET_VERANSTALTUNGS_TYP_FELDER_AUSFUEHREN = '[VeranstaltungstypenModul] GetVeranstaltungsTypFelder ausführen';
export const GET_VERANSTALTUNGS_TYP_FELDER_ERFOLGREICH = '[VeranstaltungstypenModul] GetVeranstaltungsTypFelder laden erfolgreich';
export const GET_VERANSTALTUNGS_TYP_FELDER_FEHLER = '[VeranstaltungstypenModul] GetVeranstaltungsTypFelder Ladefehler';
export const GET_VERANSTALTUNGS_TYP_FELDER_NICHT_AUSGEFUEHRT = '[VeranstaltungstypenModul] GetVeranstaltungsTypFelder nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungsTypFelderAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_FELDER;
        constructor( public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypFelderAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_FELDER_AUSFUEHREN;
        constructor( public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypFelderErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_FELDER_ERFOLGREICH;
        constructor(public payload: Array<api.VeranstaltungsTypFeld>,  public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypFelderFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_FELDER_FEHLER;
        constructor(public payload: any,  public veranstaltungstyp_id:string , public optPayload: any = null) {}
    }
export class GetVeranstaltungsTypFelderNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGS_TYP_FELDER_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungstypenQueryVeranstaltungsTypFelderActions =
    GetVeranstaltungsTypFelderAction
    | GetVeranstaltungsTypFelderAusfuehrenAction
    | GetVeranstaltungsTypFelderErfolgreichAction
    | GetVeranstaltungsTypFelderFehlerAction
    | GetVeranstaltungsTypFelderNichtAusgefuehrtAction
    ;
