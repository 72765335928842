/**
 * orga app | veranstaltungen
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AufgabeModelRequest } from '../model/aufgabeModelRequest';
import { AufgabeRequest } from '../model/aufgabeRequest';
import { AufgabenBenutzerIdsRequest } from '../model/aufgabenBenutzerIdsRequest';
import { ChecklisteHinzufuegenMultiRequest } from '../model/checklisteHinzufuegenMultiRequest';
import { ChecklisteRequest } from '../model/checklisteRequest';
import { MessageResponse } from '../model/messageResponse';
import { VeranstaltungAktionEntfernenRequest } from '../model/veranstaltungAktionEntfernenRequest';
import { VeranstaltungAktionErfassenRequest } from '../model/veranstaltungAktionErfassenRequest';
import { VeranstaltungAktionReihenfolgeSetzenMultiRequest } from '../model/veranstaltungAktionReihenfolgeSetzenMultiRequest';
import { VeranstaltungAnlegenRequest } from '../model/veranstaltungAnlegenRequest';
import { VeranstaltungInfoKategorieEntfernenRequest } from '../model/veranstaltungInfoKategorieEntfernenRequest';
import { VeranstaltungInfoKategorieErfassenRequest } from '../model/veranstaltungInfoKategorieErfassenRequest';
import { VeranstaltungInfoKategorieReihenfolgeSetzenMultiRequest } from '../model/veranstaltungInfoKategorieReihenfolgeSetzenMultiRequest';
import { VeranstaltungKopfdatenAktualisierenRequest } from '../model/veranstaltungKopfdatenAktualisierenRequest';
import { VeranstaltungLogoAktualisierenRequest } from '../model/veranstaltungLogoAktualisierenRequest';
import { VeranstaltungRequest } from '../model/veranstaltungRequest';
import { VeranstaltungRolleRequest } from '../model/veranstaltungRolleRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AdminCommandService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * PostAufgabeAktivieren (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAufgabeAktivieren(body?: AufgabeRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postAufgabeAktivieren(body?: AufgabeRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postAufgabeAktivieren(body?: AufgabeRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postAufgabeAktivieren(body?: AufgabeRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/aufgaben/aktivieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostAufgabeAktualisieren (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAufgabeAktualisieren(body?: AufgabeModelRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postAufgabeAktualisieren(body?: AufgabeModelRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postAufgabeAktualisieren(body?: AufgabeModelRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postAufgabeAktualisieren(body?: AufgabeModelRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/aufgaben/aktualisieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostAufgabeDeaktivieren (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAufgabeDeaktivieren(body?: AufgabeRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postAufgabeDeaktivieren(body?: AufgabeRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postAufgabeDeaktivieren(body?: AufgabeRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postAufgabeDeaktivieren(body?: AufgabeRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/aufgaben/deaktivieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostAufgabenBenutzerBerechtigungenEntfernen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAufgabenBenutzerBerechtigungenEntfernen(body?: AufgabenBenutzerIdsRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postAufgabenBenutzerBerechtigungenEntfernen(body?: AufgabenBenutzerIdsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postAufgabenBenutzerBerechtigungenEntfernen(body?: AufgabenBenutzerIdsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postAufgabenBenutzerBerechtigungenEntfernen(body?: AufgabenBenutzerIdsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/aufgaben/berechtigungen/benutzer/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostAufgabenBenutzerBerechtigungenHinzufuegen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAufgabenBenutzerBerechtigungenHinzufuegen(body?: AufgabenBenutzerIdsRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postAufgabenBenutzerBerechtigungenHinzufuegen(body?: AufgabenBenutzerIdsRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postAufgabenBenutzerBerechtigungenHinzufuegen(body?: AufgabenBenutzerIdsRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postAufgabenBenutzerBerechtigungenHinzufuegen(body?: AufgabenBenutzerIdsRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/aufgaben/berechtigungen/benutzer/hinzufuegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostChecklisteEntfernen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postChecklisteEntfernen(body?: ChecklisteRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postChecklisteEntfernen(body?: ChecklisteRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postChecklisteEntfernen(body?: ChecklisteRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postChecklisteEntfernen(body?: ChecklisteRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/checklisten/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostChecklisteHinzufuegenMulti (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postChecklisteHinzufuegenMulti(body?: ChecklisteHinzufuegenMultiRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postChecklisteHinzufuegenMulti(body?: ChecklisteHinzufuegenMultiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postChecklisteHinzufuegenMulti(body?: ChecklisteHinzufuegenMultiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postChecklisteHinzufuegenMulti(body?: ChecklisteHinzufuegenMultiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/checklisten/hinzufuegen/multi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungAktionEntfernen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungAktionEntfernen(body?: VeranstaltungAktionEntfernenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungAktionEntfernen(body?: VeranstaltungAktionEntfernenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungAktionEntfernen(body?: VeranstaltungAktionEntfernenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungAktionEntfernen(body?: VeranstaltungAktionEntfernenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/aktion/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungAktionErfassen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungAktionErfassen(body?: VeranstaltungAktionErfassenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungAktionErfassen(body?: VeranstaltungAktionErfassenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungAktionErfassen(body?: VeranstaltungAktionErfassenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungAktionErfassen(body?: VeranstaltungAktionErfassenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/aktion/erfassen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungAktionReihenfolgeSetzen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungAktionReihenfolgeSetzen(body?: VeranstaltungAktionReihenfolgeSetzenMultiRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungAktionReihenfolgeSetzen(body?: VeranstaltungAktionReihenfolgeSetzenMultiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungAktionReihenfolgeSetzen(body?: VeranstaltungAktionReihenfolgeSetzenMultiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungAktionReihenfolgeSetzen(body?: VeranstaltungAktionReihenfolgeSetzenMultiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/aktion/reihenfolge`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungAnlegen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Mandant: Admin oder VeranstaltungsTyp Admin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungAnlegen(body?: VeranstaltungAnlegenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungAnlegen(body?: VeranstaltungAnlegenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungAnlegen(body?: VeranstaltungAnlegenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungAnlegen(body?: VeranstaltungAnlegenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/anlegen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungBeenden (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Admin oder Veranstaltungsleiter 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungBeenden(body?: VeranstaltungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungBeenden(body?: VeranstaltungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungBeenden(body?: VeranstaltungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungBeenden(body?: VeranstaltungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/beenden`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungBeobachterFestlegen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Admin oder Veranstaltungsleiter 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungBeobachterFestlegen(body?: VeranstaltungRolleRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungBeobachterFestlegen(body?: VeranstaltungRolleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungBeobachterFestlegen(body?: VeranstaltungRolleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungBeobachterFestlegen(body?: VeranstaltungRolleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/rolle/beobachter`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungInfoKategorieEntfernen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungInfoKategorieEntfernen(body?: VeranstaltungInfoKategorieEntfernenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungInfoKategorieEntfernen(body?: VeranstaltungInfoKategorieEntfernenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungInfoKategorieEntfernen(body?: VeranstaltungInfoKategorieEntfernenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungInfoKategorieEntfernen(body?: VeranstaltungInfoKategorieEntfernenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/infokategorie/entfernen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungInfoKategorieErfassen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungInfoKategorieErfassen(body?: VeranstaltungInfoKategorieErfassenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungInfoKategorieErfassen(body?: VeranstaltungInfoKategorieErfassenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungInfoKategorieErfassen(body?: VeranstaltungInfoKategorieErfassenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungInfoKategorieErfassen(body?: VeranstaltungInfoKategorieErfassenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/infokategorie/erfassen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungInfoKategorieReihenfolgeSetzen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungInfoKategorieReihenfolgeSetzen(body?: VeranstaltungInfoKategorieReihenfolgeSetzenMultiRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungInfoKategorieReihenfolgeSetzen(body?: VeranstaltungInfoKategorieReihenfolgeSetzenMultiRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungInfoKategorieReihenfolgeSetzen(body?: VeranstaltungInfoKategorieReihenfolgeSetzenMultiRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungInfoKategorieReihenfolgeSetzen(body?: VeranstaltungInfoKategorieReihenfolgeSetzenMultiRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/infokategorie/reihenfolge`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungKopfdatenAktualisieren (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Backend Admin oder Veranstaltungsleiter oder AnyFunktionsbereichs Admin oder VerTypAdmin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungKopfdatenAktualisieren(body?: VeranstaltungKopfdatenAktualisierenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungKopfdatenAktualisieren(body?: VeranstaltungKopfdatenAktualisierenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungKopfdatenAktualisieren(body?: VeranstaltungKopfdatenAktualisierenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungKopfdatenAktualisieren(body?: VeranstaltungKopfdatenAktualisierenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/kopfdaten`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungKopieren (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Admin oder Typ-Admin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungKopieren(body?: VeranstaltungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungKopieren(body?: VeranstaltungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungKopieren(body?: VeranstaltungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungKopieren(body?: VeranstaltungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/kopieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungLoeschen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Admin oder Typ-Admin 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungLoeschen(body?: VeranstaltungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungLoeschen(body?: VeranstaltungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungLoeschen(body?: VeranstaltungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungLoeschen(body?: VeranstaltungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/loeschen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungLogoAktualisieren (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Admin oder Veranstaltungsleiter 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungLogoAktualisieren(body?: VeranstaltungLogoAktualisierenRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungLogoAktualisieren(body?: VeranstaltungLogoAktualisierenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungLogoAktualisieren(body?: VeranstaltungLogoAktualisierenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungLogoAktualisieren(body?: VeranstaltungLogoAktualisierenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/logo`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungNichtVeroeffentlichen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Admin oder Veranstaltungsleiter 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungNichtVeroeffentlichen(body?: VeranstaltungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungNichtVeroeffentlichen(body?: VeranstaltungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungNichtVeroeffentlichen(body?: VeranstaltungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungNichtVeroeffentlichen(body?: VeranstaltungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/nichtveroeffentlichen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungReaktivieren (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Admin oder Veranstaltungsleiter 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungReaktivieren(body?: VeranstaltungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungReaktivieren(body?: VeranstaltungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungReaktivieren(body?: VeranstaltungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungReaktivieren(body?: VeranstaltungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/reaktivieren`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungVeranstaltungleiterFestlegen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Admin oder Veranstaltungsleiter 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungVeranstaltungleiterFestlegen(body?: VeranstaltungRolleRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungVeranstaltungleiterFestlegen(body?: VeranstaltungRolleRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungVeranstaltungleiterFestlegen(body?: VeranstaltungRolleRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungVeranstaltungleiterFestlegen(body?: VeranstaltungRolleRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/rolle/veranstaltungsleiter`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * PostVeranstaltungVeroeffentlichen (Auth)
     * ORGA App Zugriff: * Mandant: ValidUser * Veranstaltung: Admin oder Veranstaltungsleiter 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postVeranstaltungVeroeffentlichen(body?: VeranstaltungRequest, observe?: 'body', reportProgress?: boolean): Observable<MessageResponse>;
    public postVeranstaltungVeroeffentlichen(body?: VeranstaltungRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessageResponse>>;
    public postVeranstaltungVeroeffentlichen(body?: VeranstaltungRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessageResponse>>;
    public postVeranstaltungVeroeffentlichen(body?: VeranstaltungRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<MessageResponse>('post',`${this.basePath}/api/command/veranstaltungen/admin/veroeffentlichen`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
