import * as api from '../../../clients/qschecks'; 

import {Action} from '@ngrx/store';

export const GET_QS_CHECKS_CONSTANTS = '[QschecksModul] GetQsChecksConstants laden';
export const GET_QS_CHECKS_CONSTANTS_AUSFUEHREN = '[QschecksModul] GetQsChecksConstants ausführen';
export const GET_QS_CHECKS_CONSTANTS_ERFOLGREICH = '[QschecksModul] GetQsChecksConstants laden erfolgreich';
export const GET_QS_CHECKS_CONSTANTS_FEHLER = '[QschecksModul] GetQsChecksConstants Ladefehler';
export const GET_QS_CHECKS_CONSTANTS_NICHT_AUSGEFUEHRT = '[QschecksModul] GetQsChecksConstants nicht ausgeführt (evtl. Offline)';



export class GetQsChecksConstantsAction implements Action {
    readonly type = GET_QS_CHECKS_CONSTANTS;
        constructor(public optPayload: any = null) {}
    }
export class GetQsChecksConstantsAusfuehrenAction implements Action {
    readonly type = GET_QS_CHECKS_CONSTANTS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetQsChecksConstantsErfolgreichAction implements Action {
    readonly type = GET_QS_CHECKS_CONSTANTS_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetQsChecksConstantsFehlerAction implements Action {
    readonly type = GET_QS_CHECKS_CONSTANTS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetQsChecksConstantsNichtAusgefuehrtAction implements Action {
    readonly type = GET_QS_CHECKS_CONSTANTS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type QschecksQueryQsChecksConstantsActions =
    GetQsChecksConstantsAction
    | GetQsChecksConstantsAusfuehrenAction
    | GetQsChecksConstantsErfolgreichAction
    | GetQsChecksConstantsFehlerAction
    | GetQsChecksConstantsNichtAusgefuehrtAction
    ;
