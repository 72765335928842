import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_FUNKPROTOKOLL_FALL_ABSCHLIESSEN = '[VeranstaltungenModul] PostFunkprotokollFallAbschliessen laden';
export const POST_FUNKPROTOKOLL_FALL_ABSCHLIESSEN_AUSFUEHREN = '[VeranstaltungenModul] PostFunkprotokollFallAbschliessen ausführen';
export const POST_FUNKPROTOKOLL_FALL_ABSCHLIESSEN_ERFOLGREICH = '[VeranstaltungenModul] PostFunkprotokollFallAbschliessen laden erfolgreich';
export const POST_FUNKPROTOKOLL_FALL_ABSCHLIESSEN_FEHLER = '[VeranstaltungenModul] PostFunkprotokollFallAbschliessen Ladefehler';
export const POST_FUNKPROTOKOLL_FALL_ABSCHLIESSEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostFunkprotokollFallAbschliessen nicht ausgeführt (evtl. Offline)';



export class PostFunkprotokollFallAbschliessenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ABSCHLIESSEN;
        constructor(public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallAbschliessenAusfuehrenAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ABSCHLIESSEN_AUSFUEHREN;
        constructor(public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallAbschliessenErfolgreichAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ABSCHLIESSEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallAbschliessenFehlerAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ABSCHLIESSEN_FEHLER;
        constructor(public payload: any, public funkprotokollFallRequest: api.FunkprotokollFallRequest, public optPayload: any = null) {}
    }
export class PostFunkprotokollFallAbschliessenNichtAusgefuehrtAction implements Action {
    readonly type = POST_FUNKPROTOKOLL_FALL_ABSCHLIESSEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandFunkprotokollFallAbschliessenActions =
    PostFunkprotokollFallAbschliessenAction
    | PostFunkprotokollFallAbschliessenAusfuehrenAction
    | PostFunkprotokollFallAbschliessenErfolgreichAction
    | PostFunkprotokollFallAbschliessenFehlerAction
    | PostFunkprotokollFallAbschliessenNichtAusgefuehrtAction
    ;
