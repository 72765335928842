import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const GET_VERANSTALTUNGEN_CONSTANTS = '[VeranstaltungenModul] GetVeranstaltungenConstants laden';
export const GET_VERANSTALTUNGEN_CONSTANTS_AUSFUEHREN = '[VeranstaltungenModul] GetVeranstaltungenConstants ausführen';
export const GET_VERANSTALTUNGEN_CONSTANTS_ERFOLGREICH = '[VeranstaltungenModul] GetVeranstaltungenConstants laden erfolgreich';
export const GET_VERANSTALTUNGEN_CONSTANTS_FEHLER = '[VeranstaltungenModul] GetVeranstaltungenConstants Ladefehler';
export const GET_VERANSTALTUNGEN_CONSTANTS_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] GetVeranstaltungenConstants nicht ausgeführt (evtl. Offline)';



export class GetVeranstaltungenConstantsAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_CONSTANTS;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenConstantsAusfuehrenAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_CONSTANTS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetVeranstaltungenConstantsErfolgreichAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_CONSTANTS_ERFOLGREICH;
        constructor(public payload: Array<api.ConstantTypeInfo>, public optPayload: any = null) {}
    }
export class GetVeranstaltungenConstantsFehlerAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_CONSTANTS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetVeranstaltungenConstantsNichtAusgefuehrtAction implements Action {
    readonly type = GET_VERANSTALTUNGEN_CONSTANTS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenQueryVeranstaltungenConstantsActions =
    GetVeranstaltungenConstantsAction
    | GetVeranstaltungenConstantsAusfuehrenAction
    | GetVeranstaltungenConstantsErfolgreichAction
    | GetVeranstaltungenConstantsFehlerAction
    | GetVeranstaltungenConstantsNichtAusgefuehrtAction
    ;
