import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN = '[VorlagenModul] PostDokumentBearbeitenBerechtigungEntfernen laden';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_AUSFUEHREN = '[VorlagenModul] PostDokumentBearbeitenBerechtigungEntfernen ausführen';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_ERFOLGREICH = '[VorlagenModul] PostDokumentBearbeitenBerechtigungEntfernen laden erfolgreich';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_FEHLER = '[VorlagenModul] PostDokumentBearbeitenBerechtigungEntfernen Ladefehler';
export const POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentBearbeitenBerechtigungEntfernen nicht ausgeführt (evtl. Offline)';



export class PostDokumentBearbeitenBerechtigungEntfernenAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN;
        constructor(public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungEntfernenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_AUSFUEHREN;
        constructor(public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungEntfernenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungEntfernenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_FEHLER;
        constructor(public payload: any, public dokumentBerechtigungRolleRequest: api.DokumentBerechtigungRolleRequest, public optPayload: any = null) {}
    }
export class PostDokumentBearbeitenBerechtigungEntfernenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_BEARBEITEN_BERECHTIGUNG_ENTFERNEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentBearbeitenBerechtigungEntfernenActions =
    PostDokumentBearbeitenBerechtigungEntfernenAction
    | PostDokumentBearbeitenBerechtigungEntfernenAusfuehrenAction
    | PostDokumentBearbeitenBerechtigungEntfernenErfolgreichAction
    | PostDokumentBearbeitenBerechtigungEntfernenFehlerAction
    | PostDokumentBearbeitenBerechtigungEntfernenNichtAusgefuehrtAction
    ;
