import * as api from '../../../clients/vorlagen'; 

import {Action} from '@ngrx/store';

export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN = '[VorlagenModul] PostDokumentVeranstaltungsTypenDeaktivieren laden';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_AUSFUEHREN = '[VorlagenModul] PostDokumentVeranstaltungsTypenDeaktivieren ausführen';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_ERFOLGREICH = '[VorlagenModul] PostDokumentVeranstaltungsTypenDeaktivieren laden erfolgreich';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_FEHLER = '[VorlagenModul] PostDokumentVeranstaltungsTypenDeaktivieren Ladefehler';
export const POST_DOKUMENT_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_NICHT_AUSGEFUEHRT = '[VorlagenModul] PostDokumentVeranstaltungsTypenDeaktivieren nicht ausgeführt (evtl. Offline)';



export class PostDokumentVeranstaltungsTypenDeaktivierenAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN;
        constructor(public dokumentVeranstaltungsTypenRequest: api.DokumentVeranstaltungsTypenRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenDeaktivierenAusfuehrenAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_AUSFUEHREN;
        constructor(public dokumentVeranstaltungsTypenRequest: api.DokumentVeranstaltungsTypenRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenDeaktivierenErfolgreichAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public dokumentVeranstaltungsTypenRequest: api.DokumentVeranstaltungsTypenRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenDeaktivierenFehlerAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_FEHLER;
        constructor(public payload: any, public dokumentVeranstaltungsTypenRequest: api.DokumentVeranstaltungsTypenRequest, public optPayload: any = null) {}
    }
export class PostDokumentVeranstaltungsTypenDeaktivierenNichtAusgefuehrtAction implements Action {
    readonly type = POST_DOKUMENT_VERANSTALTUNGS_TYPEN_DEAKTIVIEREN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VorlagenCommandDokumentVeranstaltungsTypenDeaktivierenActions =
    PostDokumentVeranstaltungsTypenDeaktivierenAction
    | PostDokumentVeranstaltungsTypenDeaktivierenAusfuehrenAction
    | PostDokumentVeranstaltungsTypenDeaktivierenErfolgreichAction
    | PostDokumentVeranstaltungsTypenDeaktivierenFehlerAction
    | PostDokumentVeranstaltungsTypenDeaktivierenNichtAusgefuehrtAction
    ;
