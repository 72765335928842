import * as api from '../../../clients/veranstaltungen'; 

import {Action} from '@ngrx/store';

export const POST_BENUTZER_VOM_DIENST_BEFREIEN = '[VeranstaltungenModul] PostBenutzerVomDienstBefreien laden';
export const POST_BENUTZER_VOM_DIENST_BEFREIEN_AUSFUEHREN = '[VeranstaltungenModul] PostBenutzerVomDienstBefreien ausführen';
export const POST_BENUTZER_VOM_DIENST_BEFREIEN_ERFOLGREICH = '[VeranstaltungenModul] PostBenutzerVomDienstBefreien laden erfolgreich';
export const POST_BENUTZER_VOM_DIENST_BEFREIEN_FEHLER = '[VeranstaltungenModul] PostBenutzerVomDienstBefreien Ladefehler';
export const POST_BENUTZER_VOM_DIENST_BEFREIEN_NICHT_AUSGEFUEHRT = '[VeranstaltungenModul] PostBenutzerVomDienstBefreien nicht ausgeführt (evtl. Offline)';



export class PostBenutzerVomDienstBefreienAction implements Action {
    readonly type = POST_BENUTZER_VOM_DIENST_BEFREIEN;
        constructor(public benutzerVomDienstBefreienRequest: api.BenutzerVomDienstBefreienRequest, public optPayload: any = null) {}
    }
export class PostBenutzerVomDienstBefreienAusfuehrenAction implements Action {
    readonly type = POST_BENUTZER_VOM_DIENST_BEFREIEN_AUSFUEHREN;
        constructor(public benutzerVomDienstBefreienRequest: api.BenutzerVomDienstBefreienRequest, public optPayload: any = null) {}
    }
export class PostBenutzerVomDienstBefreienErfolgreichAction implements Action {
    readonly type = POST_BENUTZER_VOM_DIENST_BEFREIEN_ERFOLGREICH;
        constructor(public payload: api.MessageResponse, public benutzerVomDienstBefreienRequest: api.BenutzerVomDienstBefreienRequest, public optPayload: any = null) {}
    }
export class PostBenutzerVomDienstBefreienFehlerAction implements Action {
    readonly type = POST_BENUTZER_VOM_DIENST_BEFREIEN_FEHLER;
        constructor(public payload: any, public benutzerVomDienstBefreienRequest: api.BenutzerVomDienstBefreienRequest, public optPayload: any = null) {}
    }
export class PostBenutzerVomDienstBefreienNichtAusgefuehrtAction implements Action {
    readonly type = POST_BENUTZER_VOM_DIENST_BEFREIEN_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type VeranstaltungenCommandBenutzerVomDienstBefreienActions =
    PostBenutzerVomDienstBefreienAction
    | PostBenutzerVomDienstBefreienAusfuehrenAction
    | PostBenutzerVomDienstBefreienErfolgreichAction
    | PostBenutzerVomDienstBefreienFehlerAction
    | PostBenutzerVomDienstBefreienNichtAusgefuehrtAction
    ;
