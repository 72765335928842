import * as api from '../../../clients/api'; 

import {Action} from '@ngrx/store';

export const GET_MANDANT_CUSTOM_URL_OPTIONS = '[ApiModul] GetMandantCustomUrlOptions laden';
export const GET_MANDANT_CUSTOM_URL_OPTIONS_AUSFUEHREN = '[ApiModul] GetMandantCustomUrlOptions ausführen';
export const GET_MANDANT_CUSTOM_URL_OPTIONS_ERFOLGREICH = '[ApiModul] GetMandantCustomUrlOptions laden erfolgreich';
export const GET_MANDANT_CUSTOM_URL_OPTIONS_FEHLER = '[ApiModul] GetMandantCustomUrlOptions Ladefehler';
export const GET_MANDANT_CUSTOM_URL_OPTIONS_NICHT_AUSGEFUEHRT = '[ApiModul] GetMandantCustomUrlOptions nicht ausgeführt (evtl. Offline)';



export class GetMandantCustomUrlOptionsAction implements Action {
    readonly type = GET_MANDANT_CUSTOM_URL_OPTIONS;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantCustomUrlOptionsAusfuehrenAction implements Action {
    readonly type = GET_MANDANT_CUSTOM_URL_OPTIONS_AUSFUEHREN;
        constructor(public optPayload: any = null) {}
    }
export class GetMandantCustomUrlOptionsErfolgreichAction implements Action {
    readonly type = GET_MANDANT_CUSTOM_URL_OPTIONS_ERFOLGREICH;
        constructor(public payload: api.CustomUrlMandantOptions, public optPayload: any = null) {}
    }
export class GetMandantCustomUrlOptionsFehlerAction implements Action {
    readonly type = GET_MANDANT_CUSTOM_URL_OPTIONS_FEHLER;
        constructor(public payload: any, public optPayload: any = null) {}
    }
export class GetMandantCustomUrlOptionsNichtAusgefuehrtAction implements Action {
    readonly type = GET_MANDANT_CUSTOM_URL_OPTIONS_NICHT_AUSGEFUEHRT;
        constructor(public optPayload: any = null) {}
    }
    

export type ApiQueryMandantCustomUrlOptionsActions =
    GetMandantCustomUrlOptionsAction
    | GetMandantCustomUrlOptionsAusfuehrenAction
    | GetMandantCustomUrlOptionsErfolgreichAction
    | GetMandantCustomUrlOptionsFehlerAction
    | GetMandantCustomUrlOptionsNichtAusgefuehrtAction
    ;
